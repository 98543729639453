import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News310325.jpg"

const News310325 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            28.03.2025 г. в Москве прошло заседание Экспертного совета федерального центра профессионального развития специалистов системы профилактики ФГБНУ «Институт изучения детства, семьи и воспитания».
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            В качестве заместителя председателя Экспертного совета в работе заседания приняла участие Артамонова Елена Геннадьевна, заместитель директора по развитию системы профилактики девиантного поведения ФГБУ «Центр защиты прав и интересов детей».
            <p></p>
            В ходе заседания были определены меры по организационно-методическому сопровождению деятельности региональных ресурсных центров и утвержден план работы Экспертного совета на 2025 год.
            <p></p>
        </div>
        <ToNews />
    </div>
}


export default News310325;