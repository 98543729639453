import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const ManagingConc = () => {


    return <>
        <div className={s.newHeader}>
            <h2>
                IV. Управление реализацией Концепции
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Ответственными за реализацию Концепции являются Министерство просвещения Российской Федерации,
                    Министерство науки и высшего образования Российской Федерации, Министерство внутренних дел
                    Российской Федерации, Федеральная служба исполнения наказаний, Министерство здравоохранения
                    Российской Федерации, Министерство труда и социальной защиты Российской Федерации, Федеральное
                    агентство по делам молодежи, Фонд поддержки детей, находящихся в трудной жизненной ситуации, и
                    органы исполнительной власти субъектов Российской Федерации.</p>
                <p>Эффективность и степень достижения ожидаемых результатов Концепции оценивается на основе данных
                    постоянного мониторинга, проводимого Федеральным государственным бюджетным научным учреждением
                    «Центр защиты прав и интересов детей».</p>
                <p>Реализация Концепции осуществляется заинтересованными федеральными органами исполнительной власти и
                    органами исполнительной власти субъектов Российской Федерации.</p>

                <p style={{fontSize: "22px"}}><strong>Результаты реализации Концепции 2024 год</strong></p>
                <p><a target="_blank"
                    href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_пункт_31_Концепция_профилактики_2024.pdf">
                        Аналитическая информация_пункт 31_Концепция профилактики_2024
                </a></p>
                <p style={{fontSize: "22px"}}><strong>Результаты реализации Концепции 2023 год</strong></p>
                <p><a target="_blank"
                    href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_по_организации_и_обеспечению_мониторинга_реализа_uVAJKuG.pdf">Аналитическая
                    информация по организации и обеспечению мониторинга реализации Концепции (пункт 31)

                </a></p>
                <p><a target="_blank"
                    href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_п_32.pdf">Аналитическая
                    информация по итогам мониторинга эффективности реализации региональных программ по профилактике
                    безнадзорности и правонарушений несовершеннолетних и планов их реализации в 2023 году (пункт 32)
                </a></p>
                <p><a target="_blank"
                    href="https://fcprc.ru/media/media/behavior/Инфографика_пункт_32_29_07_2024_1_версия_4.pptx">Инфографические
                    материалы по итогам мониторинга эффективности реализации региональных программ по профилактике
                    безнадзорности и правонарушений несовершеннолетних и планов их реализации в 2023 году (пункт 32)
                </a></p>
                <p style={{fontSize: "22px"}}><strong>Результаты реализации Концепции 2022 год</strong></p>
                <p><a target="_blank" href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya_punkt-31.pdf">Аналитическая
                    информация по организации и обеспечению мониторинга
                    реализации Концепции (пункт 31)
                </a></p>
                <p><a target="_blank" href="https://fcprc.ru/media/media/behavior/Аналитическая_информация_итог_Департамент.pdf">Аналитическая
                    информация по итогам мониторинга эффективности реализации региональных программ по профилактике
                    безнадзорности и правонарушений несовершеннолетних и планов их реализации (пункт 32)

                </a></p>
                <p><a target="_blank" href="https://fcprc.ru/media/media/behavior/Инфографика_пункт_32_forOnZy.pdf">Инфографические
                    материалы по итогам мониторинга эффективности реализации региональных программ по профилактике
                    безнадзорности и правонарушений несовершеннолетних и планов их реализации в субъектах Российской
                    Федерации в 2022 году


                </a></p>
                <p style={{fontSize: "22px"}}><strong>Результаты реализации Концепции 2021 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-__31.pdf">Информационно-аналитические
                    материалы по организации и обеспечению мониторинга реализации Концепции в 2021 году (п. 31)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya.pdf">Аналитическая
                    информация по организации и обеспечению в 2021 году мониторинга эффективности реализации
                    региональных программ по профилактике безнадзорности и правонарушений несовершеннолетних и планов их
                    реализации (п. 32)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya_punkt-32.pdf">Инфографические
                    материалы по организации и обеспечению в 2021 году эффективности реализации региональных программ по
                    профилактике безнадзорности и правонарушений несовершеннолетних (п. 32)

                </a></p>
            </div>
            <div style={{marginTop: "-57px"}}>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default ManagingConc
