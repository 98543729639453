import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News140225.jpg"
import img1 from "../../assets/News/News140225_1.jpg"

const News140225 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Круглый стол в Общественной палате «Медиация в социальной сфере: требования к квалификации специалистов и
            возможные механизмы саморегулирования»
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            4 февраля в Общественной Палате Российской Федерации состоялся круглый стол «Медиация в социальной сфере:
            требования к квалификации специалистов и возможные механизмы саморегулирования», организованный рабочей
            группой по системному развитию медиации и медиативных технологий при Комиссии ОП РФ по общественной
            экспертизе законопроектов и иных нормативных актов.
            <p></p>
            На мероприятии обсуждались критерии единых профессиональных стандартов медиации в социальной сфере и новая
            модель российской медиации.
            <p></p>
            В 2024 году Министерство юстиции Российской Федерации объявило о подготовке проекта Федерального закона «О
            внесении изменений в Федеральный закон “Об альтернативной процедуре урегулирования споров с участием
            посредника (процедуре медиации)”». Предложения по совершенствованию нормативного регулирования медиации в
            России включат в себя:
            <ul>
                <li>разработку единых профессиональных стандартов для медиаторов;</li>
                <li>установление общих этических норм для всех медиаторов;</li>
                <li>создание механизма саморегулирования института медиации, который будет включать в себя оценку
                    квалификации
                    медиаторов как обязательное требование для осуществления практики.
                </li>
            </ul>
            Директор автономной некоммерческой организации по оказанию социальных услуг гражданам «Уральский центр
            медиации», член Экспертного совета Минпросвещения России по развитию служб медиации и (или) примирения Ольга
            Павловна Махнева в своем выступлении на тему «Компетенции и преференции медиатора, работающего в социальной
            сфере» подчеркнула важность глубокого знания медиатором законодательства в его сфере деятельности.
            <p></p>
            Участники мероприятия также обсудили:
            <ul>
                <li>технологические, правовые и организационные особенности медиативной практики в социальной сфере;
                </li>
                <li>объем знаний, необходимых практикующему в социальной сфере медиатору;</li>
                <li>возможные подходы к оценке квалификации медиаторов;</li>
                <li>возможные механизмы саморегулирования института медиации.</li>
            </ul>
            В круглом столе приняла участие аналитик Центра защиты прав и интересов детей Жодзишская Лидия Николаевна.
            Запись прямой трансляции круглого стола <a style={{fontWeight: "bold"}}
                                                       href="https://www.oprf.ru/live_stream/3104">https://www.oprf.ru/live_stream/3104</a>
            <p></p>

        </div>
        <div>
            <img src={img1}/>
        </div>
        <ToNews/>
    </div>
}


export default News140225;