import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News030225.jpg"

const News030225 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <p></p>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            Директор ФГБУ «Центр защиты прав и интересов детей» <b>Борис Борисович Гусев</b> за многолетний добросовестный труд
            и многочисленные заслуги в сфере образования награжден ведомственной наградой Министерства просвещения
            Российской Федерации – <b>«Почетный работник сферы образования Российской Федерации»</b>.
            <p></p>
            <b>Вехи профессионального пути Бориса Борисовича Гусева</b><br/>
            Глубокие разносторонние познания, подтвержденные не только высшим образованием и ученой степенью кандидата
            педагогических наук, но и богатым, многогранным профессиональным опытом работы, приоритет гуманитарных
            ориентиров и морально-нравственных ценностей – безусловные ключевые характеристики Бориса Борисовича Гусева.
            Эти высоко профессиональные и достойные личностные качества определяли вектор его деятельности, как в работе
            с детьми «группы риска» – делинквентными подростками – в начале трудового пути, так и позже во всех сферах
            деятельности. В том числе, в ФГБУ «Центр защиты прав и интересов детей» (далее – Центр).
            <p></p>
            С начала своей работы в Центре в 2021 году Борис Борисович проявлял глубоко заинтересованный, действенный,
            нестандартный и конструктивный подход к решению всех задач, поставленных перед руководимой им организацией,
            достигая при этом блестящих результатов как организатор, руководитель и наставник.
            <p></p>
            За годы работы в ФГБУ «Центр защиты прав и интересов детей» под руководством Гусева Б.Б. обеспечено
            организационно-техническое сопровождение заседаний Правительственной комиссии по делам несовершеннолетних и
            защите их прав, а также исполнение поручений и протокольных решений.
            <p></p>
            В рамках исполнения поручений Министра просвещения Российской Федерации Кравцова С.С. (2022 год),
            Председателя Правительственной комиссии по делам несовершеннолетних и защите их прав Голиковой Т.А. (2023
            год) под руководством Б.Б. Гусева были проведены масштабные исследования: «Изучение мотивов проявления
            девиантного поведения обучающимися, причин, условий, способствующих формированию такого поведения, а также
            мер, принимаемых по предупреждению и профилактике девиантного поведения обучающихся» и «Анализ эффективности
            реализуемых концепций, комплексов мер, планов мероприятий по профилактике безнадзорности и правонарушений
            несовершеннолетних, суицидов, социально-негативных явлений в детской и молодежной среде, иных документов,
            утвержденных Правительством Российской Федерации и Правительственной комиссией по делам несовершеннолетних и
            защите их прав (в том числе в разрезе субъектов Российской Федерации)», включая Всероссийский опрос
            «Молодежь современной России: мой взгляд на риски и возможности».
            <p></p>
            Под руководством и при непосредственном участии Гусева Б.Б. также были организованы и проведены высоко
            значимые мероприятия в сфере образования, в том числе всероссийские и с международным участием форумы,
            конференции, совещания, конкурсы. Так, было проведено более 30 всероссийских массовых мероприятий в сфере
            защиты детства, профилактики девиантного поведения несовершеннолетних и обеспечения их физического и
            психологического благополучия, а также по вопросам опеки и попечительства в отношении несовершеннолетних
            граждан, в том числе: Всероссийская конференция по вопросам реализации Десятилетия детства (2022-2024 годы);
            Всероссийское совещание по вопросам организации деятельности комиссий по делам несовершеннолетних и защите
            их прав (2022-2024 годы); Всероссийское совещание по вопросам деятельности специальных учебно-воспитательных
            учреждений (2021-2024 годы); Всероссийская научно-практическая конференция (практикум) по актуальным
            вопросам профилактики девиантного поведения несовершеннолетних (2021-2024 годы); Всероссийский конкурс
            региональных моделей системы профилактики безнадзорности и правонарушений несовершеннолетних (2023-2024
            годы), Всероссийский конкурс социальной рекламы «Стиль жизни-здоровье!» (2022-2024 годы), Всероссийское
            совещание школьных служб медиации и примирения (2023 год); Развивающая программа для юных
            медиаторов-волонтеров на базе всероссийского детского центра «Орленок» (2022-2024 годы); Всероссийской Съезд
            руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей (2022-2024 годы);
            Всероссийское совещание руководителей органов опеки и попечительства (2022-2024 годы); Всероссийский конкурс
            в сфере профессиональной деятельности специалистов органов опеки и попечительства в отношении
            несовершеннолетних граждан «На стороне ребенка» (2022, 2024 годы) и другие.
            <p></p>
            На протяжении трех лет (2022-2024 годы) под руководством Б.Б. Гусева продуктивно развивается созданный на
            базе Центра по инициативе Уполномоченного при Президенте Российской Федерации по правам ребенка М.А.
            Львовой-Беловой и в соответствии с Распоряжением Президента Российской Федерации В.В. Путина от 15 июля 2022
            года № 234-рп Федеральный центр развития программ социализации подростков (Федеральный подростковый центр)
            для обеспечения образовательных, экспертно-методических и координирующих функций по работе с подростками
            «группы риска». В рамках реализации деятельности Федерального подросткового центра решаются такие ключевые
            задачи как: методическое обеспечение работы сети «низкопороговых» подростковых центров в субъектах
            Российской Федерации; обучение, повышение квалификации специалистов, работающих с детьми и подростками, в
            том числе «группы риска»; содействие развитию уличной социальной работы с подростками (аутрич-работа), а
            также социальной работы в сети Интернет (кибер-педагогика, кибер-психология); методическое обеспечение
            работы с детьми, находящимися в конфликте с законом, попавших под влияние деструктивных субкультур и др.;
            содействие интеграции в социум детей, переживших опыт боевых действий (дети-беженцы из Сирии, Донецкой и
            Луганской Народных Республик, Херсонской и Запорожской областей) и др.
            <p></p>
            Гусев Борис Борисович является членом межведомственных рабочих групп: при Министерстве просвещения
            Российской Федерации по криминализации подростковой среды, по вопросам профилактики сексуального насилия в
            отношении детей при Правительственной комиссии по делам несовершеннолетних и защите их прав, Экспертного
            совета по развитию служб медиации и примирения в образовательных организациях при Министерстве просвещения
            Российской Федерации и др.
            <p></p>
            Также за годы работы в Центре под непосредственным руководством Бориса Борисовича Гусева, под редакцией или
            в соавторстве было разработано более 20 методических рекомендаций, информационно-аналитических,
            инфографических материалов, пособий и иных материалов для специалистов сферы профилактики, родителей
            несовершеннолетних обучающихся, а также самих детей и подростков.
            <p></p>
            В дополнение к вышесказанному важно отметить глубочайшую личную заинтересованность Бориса Борисовича Гусева,
            принципиальность и высочайший профессионализм в решении задач любой сложности, сочетающиеся с тонким умением
            и готовностью всегда оказать поддержку сотрудникам вверенного ему коллектива, безукоризненной человеческой
            порядочностью и неравнодушным отношением к своему делу – во благо детства.
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News030225;