export const navArrayFifth = [
    {
        "col1": "Алтайский край",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Алтайского края",
        "col4": "Алтайский край, г. Барнаул, пр. Ленина, 7 / minstroy@alregn.ru / (3852) 566301"
    },
    {
        "col1": "Амурская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Амурской области",
        "col4": "675000, Амурская область,\nг. Благовещенск,\nул. Шимановского, 8/ julikks@mszn.amurobl.ru/ \n+7(4162) 990-276"
    },
    {
        "col1": "Архангельская область",
        "col2": 1,
        "col3": "Департамент по вопросам семьи, опеки и попечительства городского округа  «Город Архангельск»",
        "col4": "163000, г. Архангельск, пл. Ленина, д. 5 Телефон: 8 (8182) 60-74-39 , Факс: 8 (8182) 60-74-24, dzsp@arhcity.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства администрации городского округа Архангельской области «Город Коряжма»",
        "col4": "165651, Архангельская область, \nг. Коряжма, ул. Ленина, д. 29,Телефон/факс: \n8 (818-50)3-03-08, 3-49-57, opeka1@koradm.ru\n\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства управления по социальным вопросам администрации городского округа Архангельской области «Котлас»",
        "col4": "165300, Архангельская область, г. Котлас, пл. Советов, д. 3,Телефон: 8(81837) 2-06-39, 2-05-57, oop50481@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 4,
        "col3": "Орган опеки и попечительства Муниципального учреждения «Управление образования и социальной сферы администрации Мирного»",
        "col4": "164170, Архангельская область, г. Мирный,     ул. Ленина, д. 33, Телефон, факс: 8(81834) 5-04-20, opekamirnyi@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства управления социальной политики администрации городского округа Архангельской области «Город Новодвинск»",
        "col4": "164900, Архангельская область, г. Новодвинск, ул. Фронтовых бригад, д. 6, корп. 1, Телефон: 8(81852) 5-12-97, а.volkova@novadmin.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства над несовершеннолетними Управления социального развития, опеки и попечительства городского округа Архангельской области «Северодвинск»",
        "col4": "164507, Архангельская область,г. Северодвинск, ул. Ломоносова, д. 41а,Телефон: 8(8184) 56-98-16, opekadeti@adm.severodvinsk.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Вельского муниципального района",
        "col4": "165150, Архангельская область, Вельский район, г. Вельск, ул. Гагарина, д. 37, Телефон/Факс: 8(81836) 6-40-59,opeka@velskmo.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства Управления образования администрации Верхнетоемского муниципального округа Архангельской области ",
        "col4": "165500, Архангельская область, Верхнетоемский район, с. Верхняя Тойма, ул. Кировская, д. 6, Телефон, факс: 8(81854) 3-18-37, opekavt@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства Управления образования и культуры администрации Вилегодского муниципального округа Архангельской области",
        "col4": "165680, Архангельская область, Вилегодский район, с. Ильинско-Подомское, ул. Советская, д. 34, Телефон, факс: 8(81843) 4-14-63, vilopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства администрации Виноградовского муниципального округа Архангельской области",
        "col4": "164570, Архангельская область, Виноградовский район,п. Березник, ул. П. Виноградова, д. 83-А, Телефон, факс: 8(81831)      2-19-33, opeka@vmoao.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства администрации Каргопольского муниципального округа Архангельской области",
        "col4": "164110, Архангельская область, Каргопольский район, г. Каргополь, ул. Победы, д. 12, Телефон, факс: 8(81841)2-26-30,                      2-18-45, kargopeka@mail.ru\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Коношский муниципальный район»",
        "col4": "164010, Архангельская область, Коношский район, п. Коноша, ул. Советская, д. 76, Телефон, факс: 8 (81858)2-21-63, 8(81858)2-37-01, opeka.konosha@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства Управления по социальной политике администрации Котласского муниципального округа Архангельской области",
        "col4": "165300, Архангельская область,  г. Котлас, пл. Советов, д. 9, Телефон: 8(81837) 2-17-20                     Факс: 8 (81837) 2-11-51, opekakr@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Красноборского муниципального округа Архангельской области",
        "col4": "165430, Архангельская область, Красноборский район, с. Красноборск, ул. Гагарина, д. 7-А, Телефон, факс: 8(81840) 3-15-57, oopkras@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 15,
        "col3": "Территориальный отдел по опеке и попечительству администрации муниципального образования «Ленский муниципальный район»",
        "col4": "165780, Архангельская область, Ленский район, с. Яренск, ул. Братьев Покровских, д. 19, Телефон, факс: 8(81859) 5-35-71, lenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства Управления образования администрации Лешуконского муниципального округа Архангельской области",
        "col4": "164670, Архангельская область, Лешуконский район, с. Лешуконское, ул. Красных Партизан,  д. 12, Телефон, факс: 8(81833) 3-00-13, leschopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства управления образования администрации Мезенского муниципального округа Архангельской области",
        "col4": "164750, Архангельская область, Мезенский район, г. Мезень, пр. Советский, д. 51, Телефон, факс: 8(81848) 9-16-89, mezenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Няндомского муниципального округа Архангельской области",
        "col4": "164200, Архангельская область, Няндомский район, г. Няндома,  ул. 60 лет Октября, д. 13, Телефон, факс: 8(818-38)6-21-68, nanopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Онежский муниципальный район»",
        "col4": "164840, Архангельская область, Онежский район, г. Онега, ул. Шаревского, д. 6, Телефон: 8 (81839) 7-03-68\nФакс: 8 (818-39) 7-04-21, opeka@onegaland.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 20,
        "col3": "Отдел по социальным вопросам, опеки и попечительства администрации Пинежского муниципального округа Архангельской области",
        "col4": "164600, Архангельская область, Пинежский район, с. Карпогоры, ул. Победы, д. 10-Б, Телефон, факс: 8 (81856)2-17-28, 2-13-11, pinopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации Плесецкого муниципального округа Архангельской области",
        "col4": "164262, Архангельская область, Плесецкий район, п. Плесецк, ул. Ленина, д. 33, Телефон: 8 (81832)7-35-94, 7-40-83     Факс: 8 (818-32) 7-28-98, oop-amoples@plesadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 22,
        "col3": "Отдел по опеке и попечительству администрации Приморского муниципального округа Архангельской области",
        "col4": "163002, г. Архангельск, пр. Ломоносова, д. 30, Телефон, факс :8(8182) 68-01-39, opeka@primadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства администрации Устьянского муниципального округа Архангельской области",
        "col4": "165210, Архангельская область, Устьянский район, рп. Октябрьский, ул. Комсомольская,  д. 7, Телефон, факс: 8 (818-55)5-11-41, ustopekun@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних управления образования администрации Холмогорского муниципального округа Архангельской области",
        "col4": "164530, Архангельская область, Холмогорский район, с. Холмогоры, пл. Морозова, д. 10, Телефон: 8(818-30) 3-34-99, opeka-holmogory@rambler.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Шенкурского муниципального округа Архангельской области",
        "col4": "165160, Архангельская область, Шенкурский район, г. Шенкурск, ул. Детгородок, д. 8, Телефон, факс: 8(818-51) 4-04-52, shenkopeka@mail.ru"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 1,
        "col3": "Министерство социального развития и труда Астраханской области ",
        "col4": "почтовый адрес: 414000, г. Астрахань, \nул. Бакинская, д.147; адрес электронной почты: pens@astrobl.ru; Тел. (8512) 52-49-07; 51-35-89, 51-35-92\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 1,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся \nбез попечения родителей, и лиц из их числа комитета социальной политики администрации Белгородского района \n\n",
        "col4": " 308501, Белгородская область, Белгородский район, \nп. Дубовое, ул. Лунная, д. 4а, usznbr@mail.ru, \n(4722) 25-70-66\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 2,
        "col3": "Администрация Борисовского района",
        "col4": "309340, Белгородская область, Борисовский район, п. Борисовка,            пл. Ушакова, д. 2, \nbor-gkh@mail.ru,             (47246)5-13-36  \n"
    },
    {
        "col1": "Белгородская область",
        "col2": 3,
        "col3": "Управление социальной защиты населения администрации Вейделевского района",
        "col4": "309720, Белгородская область, Вейделевский район, п.Вейделевка, ул. Мира, д.14,  uszn@ve.belregion, (47237) 5-56-73\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 4,
        "col3": "Администрация муниципального района «Волоконовский район»",
        "col4": "309650, Белгородская область, Волоконовский район, п. Волоконовка, ул. Ленина, д.60,\nokcvol90@yandex.ru\n(47235)5-03-31\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 5,
        "col3": "Администрация Ивнянского района",
        "col4": "309110, Белгородская область, Ивнянский район, п. Ивня, ул. Ленина, д. 20, \nfilshina_ev@iv.belregion.ru,\n(47243) 5-57-08\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 6,
        "col3": "Районная межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309210, Белгородская область, Корочанский район,\nг. Короча, пл. Васильева, д. 13  opeka_kor@mail.ru,  \n(47231)5-54-76\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 7,
        "col3": "Межведомственная комиссия при администрации муниципального района «Красненский район» ",
        "col4": "309870, Белгородская область, Красненский район, с.Красное, ул. Подгорная, д.4, (47262) 5-27-97, 5-20-88, ovchinnikova_mr@kr.belregion.ru, uksigkh@kr.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 8,
        "col3": "Жилищная комиссия при администрации Красногвардейского района Белгородской области",
        "col4": "309504, Белгородская область, Красногвардейский район, г. Бирюч, пл. Соборная, д. 1,\nu_gkh@bk.ru, (47247) 3-37-44, 3-10-62\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 9,
        "col3": "Администрация муниципального района «Краснояружский район»",
        "col4": "309420, Белгородская область, Краснояружский район, п.Красная\nЯруга, ул. Центральная, д. 14, (47263) 46-9-14,  yaruga@ky.belregion.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 10,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "Белгородская область, Прохоровский район,\nп. Прохоровка, \nул. Советская, 57а, \nuszn@pr.belregion.ru, \n(47242) 2-16-77\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 11,
        "col3": "Администрация муниципального района «Ракитянский район»",
        "col4": "309310, Белгородская область, Ракитянский район,\nп. Ракитное, пл. Советская, д.2,        (47245) 5-55-16, arakitnoe@ra.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 12,
        "col3": "Районная межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309740, Белгородская область,Ровеньский район, п.Ровеньки, ул. Шевченко, д.8, \nrov_oszn@mail.ru,      (47238)5-52-90\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 13,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без родительского попечения, и лиц из их числа",
        "col4": "309560, Белгородская область,  Чернянский район,\nп. Чернянка, пл. Октябрьская, д. 6, uszn@ch.belregion.ru, \n(47232) 5-51-65\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 14,
        "col3": "Жилищная комиссия при администрации Алексеевского муниципального округа",
        "col4": "309850, Белгородская область, Алексеевский муниципальный округ, г. Алексеевка, пл. Победы, д.73, (47234) 3-17- 42, 3-40-76, fadeeva-64@mail.ru, ryabtseva_sb@al.belregion.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 15,
        "col3": "Управление муниципальной\nсобственности и земельных\nресурсов администрации\nВалуйского муниципального\nокруга",
        "col4": "309996, Белгородская область, Валуйский муниципальный округ,\nг. Валуйки, пл. Красная, д. 1, munsobstv@va.belregion.ru,\n(47236) 3-14-33, 3-05-45"
    },
    {
        "col1": "Белгородская область",
        "col2": 16,
        "col3": "Управление  по строительству, транспорту, ЖКХ и ТЭК администрации Грайворонского муниципального округа",
        "col4": "309370, Белгородская область, Грайворонский район, г. Грайворон, ул. Комсомольская, д. 21,\npolina.davydenko.71@mail.ru, (47261)4-52-96\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 17,
        "col3": "Администрация Новооскольского муниципального округа",
        "col4": "309640, Белгородская область, Новооскольский муниципальный округ,\nг.Новый Оскол, ул. 1 Мая, д.2 noskol@no.belregion.ru\n(47233)4-83-23"
    },
    {
        "col1": "Белгородская область",
        "col2": 18,
        "col3": "Администрация Шебекинского муниципального округа",
        "col4": "309290, Белгородская область, Шебекинский муниципальный округ, г.Шебекино, пл. Центральная, д. 2,          (47248) 3-29-22, admshebekino@sh.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 19,
        "col3": "Комиссия по рассмотрению вопросов признания граждан нуждающимися в улучшении жилищных условий и предоставления жилых помещений в Яковлевском муниципапьном округе",
        "col4": "309070, Белгородская область, Яковлевский муниципальный округ, г.Строитель, ул. Ленина, д.2, zhilishchnyyotdel@mail.ru\n(47244) 6-93-64\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 20,
        "col3": "Жилищное управление администрации города\nБелгорода\n",
        "col4": "308007, Белгородская область, г. Белгород, ул. Некрасова, д. 9/15, (4722) 26-84-92, 26-84-95, gil_otdel@mail.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 21,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа\n",
        "col4": "309186, Белгородская область, Губкинский городской округ,\nг. Губкин, ул. Победы, д. 3, socpolitika@yandex.ru,\n(47241) 5-25-69\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 22,
        "col3": "Межведомственная комиссия по решению жилищных вопросов детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа",
        "col4": "309500, Белгородская область, Старооскольский городской округ,\nг. Старый Оскол, мкр. Интернациональный, д. 15, \n(4725) 24-91-63, uszn@so.belregion.ru\n"
    },
    {
        "col1": "Брянская область",
        "col2": 1,
        "col3": "Брянская городская администрация",
        "col4": "241050, Брянская область, г. Брянск,                 пр-т Ленина, д. 35, goradm@bga32.ru,  8(4832) 66-40-44"
    },
    {
        "col1": "Брянская область",
        "col2": 2,
        "col3": "Клинцовская городская администрация",
        "col4": "243140, Брянская область, г. Клинцы,             ул. Октябрьская, д. 42, klintsi@mail.ru, 8(48336) 4-03-28"
    },
    {
        "col1": "Брянская область",
        "col2": 3,
        "col3": "Новозыбковская городская администрация",
        "col4": "243020, Брянская область, г. Новозыбков,        пл. Октябрьской Революции, д. 2, novozibkovadm@mail.ru, 8(48343)5-69-46"
    },
    {
        "col1": "Брянская область",
        "col2": 4,
        "col3": "Администрация города Сельцо ",
        "col4": "241550, Брянская область, г. Сельцо,              ул. Горького, д. 14, selco32@mail.ru, 8(4832)97-44-17"
    },
    {
        "col1": "Брянская область",
        "col2": 5,
        "col3": "Администрация Стародубского муниципального округа",
        "col4": "243240, Брянская область, г. Стародуб,           ул. Свердлова, д. 4, stadmo2008@yandex.ru, 8(48348) 2-24-22"
    },
    {
        "col1": "Брянская область",
        "col2": 6,
        "col3": "Администрация города Фокино",
        "col4": "242610, Брянская область, г. Фокино,              ул. Ленина, д. 13, g_fokino@mail.ru, 8(483333)4-79-60"
    },
    {
        "col1": "Брянская область",
        "col2": 7,
        "col3": "Администрация Брасовского муниципального района",
        "col4": "242300, Брянская область, Брасовский район,    п. Локоть, пр-т Ленина, д. 2. edds.bras@yandex.ru, 8(48354) 9-11-42"
    },
    {
        "col1": "Брянская область",
        "col2": 8,
        "col3": "Админситрация Брянского муниципального района",
        "col4": "241525, Брянская область, Брянский район,      с. Глинищево, ул. П.М. Яшенина, д. 9, admbr@mail.ru, 8(4832)94-16-70"
    },
    {
        "col1": "Брянская область",
        "col2": 9,
        "col3": "Администрация Выгоничского муниципального  района",
        "col4": "243361, Брянская область, Выгоничский район, п. Выгоничи, ул. Ленина, д. 51, adminwr@mail.ru, 8(48341)2-14-52"
    },
    {
        "col1": "Брянская область",
        "col2": 10,
        "col3": "Администрация Гордеевского муниципального  района",
        "col4": "243650, Брянская область, Гордеевский район, с. Гордеевка, ул. Победы, д. 10, admorgotdel@mail.ru, 8(48340) 2-14-46"
    },
    {
        "col1": "Брянская область",
        "col2": 11,
        "col3": "Администрация Дубровского муниципального района ",
        "col4": "242750, Брянская область, Дубровский район, п. Дубровка, ул. Победы, д. 18, dbr-orgotdel@yandex.ru, 8(48332)9-15-25"
    },
    {
        "col1": "Брянская область",
        "col2": 12,
        "col3": "Администрация Дятьковского муниципального района",
        "col4": "242600, Брянская область, Дятьковский район, г. Дятьково, д. 141-а, radmdtk@mail.ru, 8(48333) 3-22-03"
    },
    {
        "col1": "Брянская область",
        "col2": 13,
        "col3": "Администрация Жирятинского муниципального района",
        "col4": "242030, Брянская область, Жирятинский район, с. Жирятино, ул. Мира, д. 10, adm@juratino.ru, 8 (48344) 3-06-06"
    },
    {
        "col1": "Брянская область",
        "col2": 14,
        "col3": "Администрация Жуковского муниципального округа",
        "col4": "242700, Брянская область, Жуковский муниципальный округ, г. Жуковка,                 ул. Октябрьская, д. 1, adm@zh32.ru, 8 (48334) 3-26-71"
    },
    {
        "col1": "Брянская область",
        "col2": 15,
        "col3": "Администрация Злынковского муниципального района",
        "col4": "243600, Брянская область, Злынковский район, г. Злынка, пл. Свободы, д. 35, RAZLYNKA2@MAIL.RU, 8 (48358) 2-10-31"
    },
    {
        "col1": "Брянская область",
        "col2": 16,
        "col3": "Администрация Карачевского муниципального района",
        "col4": "242500, Брянская область, Карачевский район, г. Карачев, ул. Советская, д. 64, inbox@karadmin.ru, 8(48335) 2-33-91"
    },
    {
        "col1": "Брянская область",
        "col2": 17,
        "col3": "Администрация Клетнянского муниципального района",
        "col4": "242820, Брянская область, Клетнянский район, п. Клетня, ул. Ленина, д. 92, klet-2007@yandex.ru, 8(48338) 9-15-61"
    },
    {
        "col1": "Брянская область",
        "col2": 18,
        "col3": "Администрация Климовского муниципального района",
        "col4": "243040, Брянская область, Климовский район, пгт. Климово, пл. Ленина, д. 1, kladm@inbox.ru, 8 (48347) 2-13-38"
    },
    {
        "col1": "Брянская область",
        "col2": 19,
        "col3": "Администрация Клинцовского муниципального района",
        "col4": "243140, Брянская область, г. Клинцы,              ул. Октябрьская, д. 42, priymnay@mail.ru, 8(48336) 4-05-12"
    },
    {
        "col1": "Брянская область",
        "col2": 20,
        "col3": "Администрация Комаричского муниципального района",
        "col4": "242400, Брянская область, Комаричский район, п. Комаричи, ул. Советская, д. 21, adminkom@mail.ru, 8(48355) 9-15-98"
    },
    {
        "col1": "Брянская область",
        "col2": 21,
        "col3": "Администрация Красногорского муниципального района",
        "col4": "243160, Брянская область, Красногорский район, пгт. Красная Гора, ул. Первомайская,    д. 6, krgadm@yandex.ru, 8(48346) 9-17-76"
    },
    {
        "col1": "Брянская область",
        "col2": 22,
        "col3": "Администрация Мглинского муниципального района",
        "col4": "243220, Брянская область, Мглинский район,   г. Мглин, пл. Советская, д. 6, mgladm@mail.ru, 8 (48339) 2-13-45"
    },
    {
        "col1": "Брянская область",
        "col2": 23,
        "col3": "Администрация Навлинского муниципального района",
        "col4": "242130, Брянская область, Навлинский район, п. Навля, ул. Красных Партизан, д. 21, priem-nvl@yandex.ru, 8 (48342) 2-26-91"
    },
    {
        "col1": "Брянская область",
        "col2": 24,
        "col3": "Администрация Погарского муниципального района",
        "col4": "243550, Брянская область, Погарский район, пгт. Погар, ул. Ленина, д.1, admin@pogaradm.ru, 8(48342) 2-12-80"
    },
    {
        "col1": "Брянская область",
        "col2": 25,
        "col3": "Администрация Почепского муниципального района",
        "col4": "243400, Брянская область, Почепский район,    г. Почеп, пл. Октябрьская, д. 3а, orgotdel74@mail.ru, 8(48345) 3-02-52"
    },
    {
        "col1": "Брянская область",
        "col2": 26,
        "col3": "Администрация Рогнединского муниципального района",
        "col4": "242770, Брянская область, Рогнединский район, п. Рогнедино, ул. Ленина, д. 29, admrrognedino@mail.ru, 8(48331) 2-13-53"
    },
    {
        "col1": "Брянская область",
        "col2": 27,
        "col3": "Администрация Севского муниципального района",
        "col4": "242440, Брянская область, Севский район,         г. Севск, ул. Розы Люксембург, д. 50, admsevsk@mail.ru, 8(48356) 9-14-33"
    },
    {
        "col1": "Брянская область",
        "col2": 28,
        "col3": "Администрация Суземского муниципального района",
        "col4": "242190, Брянская область, Суземский район,    п. Суземка, пл. Ленина, д. 1, info@adminsuzemka.ru, 8(48353) 2-18-34"
    },
    {
        "col1": "Брянская область",
        "col2": 29,
        "col3": "Администрация Суражского муниципального района",
        "col4": "243500, Брянская область, Суражский район,   г. Сураж, ул. Ленина, д. 40, 84833021434@mail.ru, 8(48330) 2-14-34"
    },
    {
        "col1": "Брянская область",
        "col2": 30,
        "col3": "Администрация Трубчевскогоского муниципального района",
        "col4": "242221, Брянская область, Трубчевский район, г. Трубчевск, ул. Брянская, д. 59,  raisovet-trubchevsk@yandex.ru, 8(48352) 2-22-81"
    },
    {
        "col1": "Брянская область",
        "col2": 31,
        "col3": "Администрация Унечского муниципального района",
        "col4": "243300, Брянская область, Унечский район,      г. Унеча, пл. Ленина, д. 1, unradm@mail.ru, 8 (48351) 2-10-32"
    },
    {
        "col1": "Владимирская область",
        "col2": 1,
        "col3": "Министерство образования Владимирской области",
        "col4": "600000, г. Владимир, ул. Комсомольская, д. 18\n(4922) 77-76-34 info@obrazovanie33.ru "
    },
    {
        "col1": "Волгоградская область",
        "col2": 1,
        "col3": "Комитет строительства Волгоградской области",
        "col4": "400066, Волгоград, ул.им.Скосырева, д.7, телефоны: (8442) 30-83-00, 30-83-57, 30-84-39, 30-84-49, oblstroy@volganet.ru"
    },
    {
        "col1": "Вологодская область",
        "col2": 1,
        "col3": "Министерство строительства Вологодской области",
        "col4": "160000, г. Вологда, ул. Герцена, д. 27, MorozovaOM@depstroy.gov35.ru, \n(8172) 23-00-55 (доб. 1254)"
    },
    {
        "col1": "Воронежская область",
        "col2": 1,
        "col3": "Министерство социальной защиты Воронежской области",
        "col4": "394006, Воронежская обл.,\nг. Воронеж, ул. Ворошилова, д. 14 8(473)277-18-17,\nsocial@govvrn.ru"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Еврейская автономная область",
        "col2": 1,
        "col3": "Департамент строительства и жилищно-коммунального хозяйства правительства Еврейской автономной области",
        "col4": "679000, Еврейская автономная область, г. Биробиджан, проспект 60 лет СССР, д. 18, grad@post.eao.ru, 8 (42622) 2 18 45"
    },
    {
        "col1": "Забайкальский край",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты населения Забайкальского края",
        "col4": "672000, Забайкальский край, г. Чита, ул. Курнатовского, д. 7,  pochta@minsz.e-zab.ru (только для служебной корреспонденции), 8(3022) 35 50 85 приемная"
    },
    {
        "col1": "Ивановская область",
        "col2": 1,
        "col3": "Администрация города Иванова",
        "col4": "153000, г. Иваново, \nпл. Революции, д. 6, \nтел. 8 (4932) 59-46-48, office@ivgoradm.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 2,
        "col3": "Администрация городского округа Вичуга",
        "col4": "155331, Ивановская область, \nг. Вичуга, ул. 50 лет Октября, д. 15,\nтел. 8 (49354) 2-10-11, \nadminvich@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 3,
        "col3": "Администрация городского округа Кинешма",
        "col4": "155800, г. Кинешма, \nул. им. Фрунзе, д. 4\nтел. 8 (49331) 5-30-50,\nmail@admkineshma.ru, admkineshma@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 4,
        "col3": "Администрация городского округа Кохма",
        "col4": "153510, Ивановская область, \nг. Кохма, ул. Советская, д. 23,\nтел. 8 (4932) 55-20-16, \nadm@kohma37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 5,
        "col3": "Администрация городского округа Тейково",
        "col4": "155040, Ивановская область,\nг. Тейково, пл. Ленина, д. 4, \nтел. 8 (49343) 4-02-01,\nadmin_tei@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 6,
        "col3": "Администрация городского округа Шуя",
        "col4": "155900, Ивановская область, \nг. Шуя, ул. Советская, д. 48,\nтел. 8 (49351) 4-12-05, \nokrugshuya@ivreg.ru, admin@okrugshuya.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 7,
        "col3": "Администрация Верхнеландеховского муниципального района",
        "col4": "155210, Ивановская область, \nп. Верхний Ландех, \nул. Первомайская, д. 3,\nтел. 8 (49349) 2-14-22,\ninfo@vlandeh-admin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 8,
        "col3": "Администрация Вичугского муниципального района",
        "col4": "155331, Ивановская область,\nг. Вичуга, переулок Широкий, д. 4, \nтел. 8 (49354) 2-44-54,\nadmvmr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 9,
        "col3": "Администрация Гаврилово-Посадского муниципального района",
        "col4": "155000, Ивановская область, г. Гаврилов Посад, ул. Розы Люксембург, д. 3,\nтел. 8 (49355) 2-12-60,\nadm_gp37@ivreg.ru,\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 10,
        "col3": "Администрация Заволжского муниципального района",
        "col4": "155410, Ивановская область, г. Заволжск, ул. Мира, д. 7,\nтел. 8 (49333) 6-00-49,\n37zavadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 11,
        "col3": "Администрация Ивановского муниципального района",
        "col4": "153008, г. Иваново, ул. Постышева, д. 46,\nтел. 8 (4932) 30-03-96,\nadm.ivrn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 12,
        "col3": "Администрация Ильинского муниципального района",
        "col4": "155060, Ивановская область, п. Ильинское-Хованское, ул. Советская, д. 2,\nтел. 8 (49353) 2-12-04,\nilin@admilinskoe.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 13,
        "col3": "Администрация Кинешемского муниципального района",
        "col4": "155800, г. Кинешма, ул. им. Ленина, д. 12,\nтел. 8 (49331) 5-51-05,\nroot@mrkineshma.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 14,
        "col3": "Администрация Комсомольского муниципального района",
        "col4": "155150, Ивановская область, \nг. Комсомольск, ул. 50 лет ВЛКСМ, д. 2,\nтел. 8 (49352) 2-11-78,\nadmin.komsomolsk@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 15,
        "col3": "Администрация Лежневского муниципального района",
        "col4": "155120, Ивановская область, п. Лежнево, \nул. Октябрьская, д. 32, \nтел. 8 (49357) 2-12-04, \ninfo@lezhnevo.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 16,
        "col3": "Администрация Лухского муниципального района",
        "col4": "155270, п. Лух, \nул. Октябрьская, д. 4\nтел. 8 (49344) 2-13-45,\ninfo.luh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 17,
        "col3": "Администрация Палехского муниципального района",
        "col4": "155620, Ивановская область, п. Палех, \nул. Ленина, д. 1,\nтел. 8 (49334) 2-14-42,\npaleh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 18,
        "col3": "Администрация Пестяковского муниципального района",
        "col4": "155650 Ивановская область, пос. Пестяки, ул. Ленина,\n д. 4,\nтел. 8 (49346) 2-10-15,\npestyaki@pestyaki.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 19,
        "col3": "Администрация Приволжского муниципального района",
        "col4": "155550, Ивановская область, \nг. Приволжск, пер. Коминтерновский, \nд. 2,\nтел. 8 (49339) 2-19-71,\nreception@privadmin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 20,
        "col3": "Администрация Пучежского муниципального района",
        "col4": "155360, Ивановская область, г. Пучеж, \nул. Ленина, д. 27,\nтел. 8 (49345) 2-12-01,\npuchezh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 21,
        "col3": "Администрация Родниковского муниципального района",
        "col4": "155250, Ивановская область, г. Родники, ул. Советская, \nд. 8,\nтел. 8 (49336) 2-17-57,\npost@rodniki-37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 22,
        "col3": "Администрация Савинского муниципального района",
        "col4": "155710, Ивановская область, п. Савино, ул. Первомайская, д. 22,\nтел. 8 (49356) 9-12-04,\nadm-savino@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 23,
        "col3": "Администрация Тейковского муниципального района",
        "col4": "155040, Ивановская область, г. Тейково, ул. Октябрьская, д. 2А,\nтел. 8 (49343) 2-26-05,\npriem.teikovo.raion@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 24,
        "col3": "Администрация Фурмановского муниципального района",
        "col4": "155520, г. Фурманов, \nул. Социалистическая, д. 15,\nтел. 8 (49341) 2-17-66,\nfurmanov@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 25,
        "col3": "Администрация Шуйского муниципального района",
        "col4": "155900, Ивановская область, г. Шуя, \nпл. Ленина, д. 7,\nтел. 8 (49351) 3-26-34,\nadm-shr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 26,
        "col3": "Администрация Южского муниципального района",
        "col4": "155630, Ивановская область, г. Южа, \nул. Пушкина, д. 1, \nтел. 8 (49347) 2-12-04,\nyuzhaadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 27,
        "col3": "Администрация Юрьевецкого муниципального района",
        "col4": "155453, Ивановская область, г. Юрьевец, ул. Советская, д. 37,\nтел. 8 (49337) 2-16-03,\nyurevets@ivreg.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 1,
        "col3": "Министерство имущественных отношений Иркутской области",
        "col4": "Почтовый адрес: 664011, Российская Федерация,\n г. Иркутск, ул. Ленина, 1А\nМесто нахождения: 664007, Российская Федерация, г. Иркутск, ул. К. Либкнехта, 47\nАдрес электронной почты:imus@govirk.ru номер телефона: (3952)259898"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 1,
        "col3": "Кабардино-Балкарская Республика, г. Нальчик,  ул. Кешокова, д. 43, 360051/minobrsc@kbr.ru, soczachita07@mail.ru/8(8662) 42-03-98",
        "col4": "Кабардино-Балкарская Республика, г. Нальчик,  ул. Кешокова, д. 43, 360051/minobrsc@kbr.ru, soczachita07@mail.ru/8(8662) 42-03-98"
    },
    {
        "col1": "Калининградская область",
        "col2": 1,
        "col3": "Областное государственное казенное учреждение Калининградской области «Центр социальной поддержки населения»",
        "col4": "а/я 5002, г. Калининград, 236035, center-social@soc.gov39.ru, 8 (4012) 604-768"
    },
    {
        "col1": "Калужская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Калужской области ",
        "col4": "\n248016 г. Калуга, ул. Пролетарская, д. 111, mintrud.admoblkaluga.ru, 719-140, 719-176"
    },
    {
        "col1": "Камчатский край",
        "col2": 1,
        "col3": "Управление коммунального хозяйства\nи жилищного фонда администрации\nПетропавловск-Камчатского\nгородского округа",
        "col4": "683003, Камчатский край,\nг. Петропавловск-Камчатский,\nул. Ленинградская, д. 74/1\nтел.: +7 (4152) 302-520\nфакс: +7 (4152) 302-521\nЭл. почта: ukhgf@pkgo.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 2,
        "col3": "Администрация Елизовского муниципального района",
        "col4": "г. Елизово, Камчатский край,\nул. Ленина, д. 10\nтел.: +7 (41531) 6-16-42\nЭл. почта: glavaadm@elizovomr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 3,
        "col3": "Администрация Вилючинского городского округа",
        "col4": "684090, Камчатский край,\nг. Вилючинск, ул. Победы, д. 1,\nтел. +7 (41535) 3-22-06,\nЭл. почта: avgo@viladm.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 4,
        "col3": "Комитет по управлению муниципальным имущество и экономической политики Администрации Тигильского муниципального округа Камчатского края",
        "col4": "688600, Камчатский край,\nТигильский район, с. Тигиль,\nул. Партизанская, д. 17,\nтел.: +7 (41537) 2-11-92,\nЭл. почта: tigilcom@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 5,
        "col3": "Администрация Соболевского муниципального района",
        "col4": "684200 Камчатский край,\n с. Соболево, ул. Советская,23,\nтел.: +7 (41536) 3-23-01\nЭл. почта: sobolevomr@sobolevomr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 6,
        "col3": "Комитет по управлению муниципальным имуществом администрации Быстринского района",
        "col4": "684350 Камчатский край, Быстринский район с. Эссо\nул. Терешковой 1,\nтел.: +7 (41542) 2-13-14\nЭл. почта:kumi-esso@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 7,
        "col3": "Администрация Усть-Камчатского муниципального округа",
        "col4": "684415 Камчатский край,\nп. Усть-Камчатск, Усть-Камчатский район,\nул. 60 лет Октября, 24,\nтел.: +7 (41534) 2-08-44\nЭл. почта:secretar@ustkam.iks.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 8,
        "col3": "Администрация Алеутского муниципального округа",
        "col4": "684500, Камчатский край, Алеутский район, с. Никольское, ул. 50 лет Октября, д. 13,\nтел.: +7 (41547) 2-22-92\nЭл. почта: admamrk@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 9,
        "col3": "Комитет по управлению\nмуниципальным имуществом\nАдминистрации\nУсть-Большерецкого\nмуниципального района",
        "col4": "684100, Камчатский край,\nс. Усть-Большерецк,\nул. Октябрьская, д.14,\nтел.: +7 (41532) 2-12-01\nЭл. почта: kumi@ubmr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 10,
        "col3": "Администрация Мильковского муниципального округа Камчатского края",
        "col4": "684300, Камчатский край,\nМильковский муниципальный округ,с. Мильково,\nул. Победы, д. 8\nтел.: +7 (41533) 2-13-53\nфакс: +7 (41533) 2-26-80\nЭл. почта: adm-milk@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 11,
        "col3": "Комитет по управлению\nимуществом администрации\nгородского округа «поселок\nПалана»",
        "col4": "688000 Камчатский край,\nТигильский район, пгт. Палана,\nул. Обухова, д. 6,\nтел.: +7 (41543) 32-220\nЭл. почта: adm@palana.org"
    },
    {
        "col1": "Камчатский край",
        "col2": 12,
        "col3": "Администрация Пенжинского муниципального района",
        "col4": "688850 Камчатский край,\nс. Каменское, Пенжинский район, ул. Ленина, д. 12,\nтел.: +7 (41546) 6-10-86\nЭл. почта: penadm@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 13,
        "col3": "Администрация Олюторского муниципального района",
        "col4": "688800 Камчатский край,\nс. Тиличики, Олюторский район, ул. Молодежная, д. 10,\nтел.: +7 (41544) 5-29-31,\nЭл. почта: oladm_zavoz@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 14,
        "col3": "Администрация Карагинского муниципального района",
        "col4": "688700, Камчатский край, Карагинский район, п. Оссора,\nул. Советская, д. 37\nтел.: +7 (41545) 41-344\nЭл. почта: akmr@karaginskiy.ru"
    },
    {
        "col1": "Карачаево - Черкесская Республика",
        "col2": 1,
        "col3": "Министерство имущественных и земельных отношений Карачаево-Черкесской Республики",
        "col4": "369004, Карачаево-Черкесская Республика, город Черкесск, Кавказская ул., д. 19\n 8 878 228 17 36"
    },
    {
        "col1": "Кемеровская область",
        "col2": 1,
        "col3": "Управление по жилищной политике администрации Анжеро-Судженского городского округа",
        "col4": "652470 г. Анжеро-Судженск, ул. Ленина, д.6, 8(38453)6-21-10, skv@anzhero.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 2,
        "col3": "Отдел по учету и распределению жилой площади администрации Беловского городского округа",
        "col4": "652600 г.Белово, ул.Советская, д.21, 8(38452)2-08-08, home3@belovo42.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 3,
        "col3": "Отдел жилищных отношений  МКУ «Центр жилищных и земельно-имущественных отношений» Березовского городского округа",
        "col4": "652425 г.Берёзовский, пр. Ленина, д. 39а, 8(38445)3-02-70, ogo-42@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 4,
        "col3": "Отдел по учету и распределению жилья МКУ \"Управление муниципальным имуществом Калтанского городского округа\"",
        "col4": "652740 г. Калтан ул. Калинина, 44, 8(38472)3-40-92, kumi-kaltan@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 5,
        "col3": "Комитет по жилищным вопросам администрации г.Кемерово",
        "col4": "650991 г. Кемерово, пр. Советский, д. 54, 8(3842)58-40-76, kgv@kemerovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 6,
        "col3": "Управление городского развития  Киселевского городского округа",
        "col4": "652700 г. Киселевск, ул. Унжакова, д. 8а, 8(38464)2-20-27, ugrkgo@gmail.com"
    },
    {
        "col1": "Кемеровская область",
        "col2": 7,
        "col3": "Отдел учета, распределения и приватизации муниципального жилищного фонда муниципального казенного учреждения «Комитет по жилищным вопросам» города Междуреченска",
        "col4": "652877 г. Междуреченск, ул. Кузнецкая, д. 31, 8(38475)6-19-32, privatizaciy@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 8,
        "col3": "Отдел по жилищным вопросам администрации Мысковского городского округа",
        "col4": "652840 г. Мыски, ул. Серафимовича, д. 4, 8(38474)2-19-91, myski_gil.otdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 9,
        "col3": "Управление по учету и приватизации жилых помещений администрации города Новокузнецка",
        "col4": "654080 г. Новокузнецк, ул. Кирова, д. 71, 8(3843)52-91-34, upr_priv_priem@admnkz.info"
    },
    {
        "col1": "Кемеровская область",
        "col2": 10,
        "col3": "Отдел по учету и распределению жилья администрации Осинниковского городского округа",
        "col4": "652811 г. Осинники, ул. Советская, д. 17, 8(38471)4-39-55, admo-gil@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 11,
        "col3": "Отдел по учету, распределению и приватизации жилья администрации Прокопьевского городского округа",
        "col4": "653000 г. Прокопьевск, ул. Артема, д. 11, 8(3846)61-20-61, flt_prkp@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 12,
        "col3": "Управление жилищным коммунальным и дорожным хозяйством администрации Тайгинского городского округа",
        "col4": "652401 г.Тайга, ул. 40 лет Октября, д. 23, 8(38448)2-17-19, uzhkidh@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 13,
        "col3": "Жилищный отдел администрации города Юрги",
        "col4": "652050 г. Юрга, пр. Победы, д. 13, 8(38451)4-69-28, gilo@yurga.org"
    },
    {
        "col1": "Кемеровская область",
        "col2": 14,
        "col3": " Комитета по управлению муниципальным имуществом администрации Беловского муниципального округа",
        "col4": "652600 г. Белово, ул. Ленина, д. 10, 8(38452)2-69-80, kumi-zh@belovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 15,
        "col3": "Отдел по управлению жилищной сферой администрации Гурьевского муниципального округа",
        "col4": "652780 г. Гурьевск, ул. Коммунистическая, д. 21, 8(38463)5-07-65, gur_114@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 16,
        "col3": "Отдел жизнеобеспечения администрации Ижморского муниципального округа",
        "col4": "652120 пгт. Ижморский, ул. Ленинская, д. 63, 8(38459)2-33-89, adm-ijmorka-rn@ako.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 17,
        "col3": "Управление учета и распределение жилья администрации Кемеровского муниципального округа",
        "col4": "650010 г. Кемерово, ул. Совхозная,1 а, 8(3842)75-68-95, uurj_kmr@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 18,
        "col3": "Отдел по жилищным вопросам администрации Крапивинского муниципального округа",
        "col4": "652440 пгт. Крапивинский, ул. Юбилейная, д. 15, 8(38446)22-2-56, seryapina.2011@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 19,
        "col3": "Отдел по учету, распределению, приватизации и обмену жилья администрации Ленинск -Кузнецкого городского округа",
        "col4": "652500 г. Ленинск-Кузнецкий, пр.Кирова, 56, 8(38456)3-20-50, oj.lk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 20,
        "col3": "Жилищный отдел администрации Мариинского муниципального округа",
        "col4": "652150 г. Мариинск, ул. Ленина, д. 40, 8(38443)5-22-25, zhilotdel.mariinsk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 21,
        "col3": "Жилищный сектор отдела капитального строительства администрации Новокузнецкого муниципального округа",
        "col4": "654041 г. Новокузнецк, ул. Сеченова, д. 25, 8(3843)32-08-16, zhilotdel2009@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 22,
        "col3": "Жилищный отдел администрации Прокопьевского муниципального округа",
        "col4": "653039 г.Прокопьевск, пр.Гагарина 1В, 8(3846)62-01-62, otdel@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 23,
        "col3": "Жилищный отдел управления по жизнеобеспечению и строительству администрации Промышленновского муниципального округа",
        "col4": "652380 пгт. Промышленная, ул. Коммунистическая, д. 23а, 8(38442)7-46-21, prom_zhilotdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 24,
        "col3": "Отдел  по учету и распределению жилья администрации Таштагольского муниципального района",
        "col4": "652992 г. Таштагол, ул. Ленина, д. 60, 8(38473)2-31-92, giltash_atr@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 25,
        "col3": "Отдел по правовым вопросам и жилищной политике администрации Тисульского муниципального округа",
        "col4": "652210 пгт. Тисуль, ул. Ленина, д. 53, 8(38447)2-32-45, jilotdel@tisul.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 26,
        "col3": "Комитет по муниципальному имуществу администрации Топкинского муниципального округа",
        "col4": "652300 г. Топки, ул. Луначарского, д. 26, 8(38454)4-66-02, o.8ulanova@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 27,
        "col3": "Жилищный отдел управления по жизнеобеспечению и территориальному развитию Тяжинского муниципального округа",
        "col4": "652240 пгт. Тяжинский, ул. Советская, д.2, 8(38449)2-75-58, vorosh-na12@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 28,
        "col3": "Управление жизнеобеспечения администрации Чебулинского муниципального  округа",
        "col4": "652270 пгт.Верх-Чебула, ул. Мира, д. 16, 8(38444)2-11-77, ssw2010@rambler.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 29,
        "col3": "Жилищный отдел администрации Юргинского муниципального округа",
        "col4": "652057 г. Юрга, ул. Машиностроителей, д.37, 8(38451)4-19-92, jil@yurgregion.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 30,
        "col3": "Отдел жилищно-коммунального хозяйства и строительства администрации Яйского муниципального округа",
        "col4": "652100 пгт. Яя, ул. Советская, д. 17, 8(38441)2-11-87, Adm-gkh-yaya@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 31,
        "col3": "Муниципальное казённое учреждение «Управление имущественных отношений администрации Яшкинского муниципального округа»",
        "col4": "652010 пгт. Яшкино, ул. Суворова, д. 8, 8(38455)2-16-77, kumi_yashkiho@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 1,
        "col3": "Администрация Арбажского муниципального округа",
        "col4": "ул. Пионерская, д. 2, пгт Арбаж, Кировская область, 612180, (833330) 2-12-32, admarbazh@mail.ru; (833330) 2-15-57, opeka.arbazh@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 2,
        "col3": "Администрации Афанасьевского муниципального округа Кировской области",
        "col4": "613060 Кировская область, Афанасьевский район, пгт Афанасьево, ул.Красных Партизан, д.5, admafan@kirovreg.ru 883331 2-19-51"
    },
    {
        "col1": "Кировская область",
        "col2": 3,
        "col3": "Администрация Белохолуницкого муниципального района",
        "col4": "613200 Кировская область г.Белая Холуница ул.Глазырина, д.6/bh_opeka@mail.ru/88336442427"
    },
    {
        "col1": "Кировская область",
        "col2": 4,
        "col3": "Администрация Богородского муниципального округа",
        "col4": "612470, Кировская область, Богородский район, пгт Богородское, ул. Советская, д. 43, 883321251, admbogo@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 5,
        "col3": "Администрация Верхнекамского муниципального округа",
        "col4": "612820 Кировская область, г. Кирс, ул. Кирова, д. 16 883339 2-33-72 imushavr@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 6,
        "col3": "Администрация Верхошижемского муниципального образования",
        "col4": "613310, Кировская область, пгт Верхошижемье, ул. Комсомольская, д. 2, 89123374172, opekashishma@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 7,
        "col3": "Администрация Вятскополянского муниципального района Кировской области",
        "col4": "612964, Кировская область, г.Вятские Поляны, ул.Гагарина, д.28, admvyatp@kirovreg.ru, 89005294510"
    },
    {
        "col1": "Кировская область",
        "col2": 8,
        "col3": "Администрация муниципального образования Даровской муниципальный район Кировскойобласти",
        "col4": "612140, Кировская область, Даровской район, пгт Даровской, ул. Набережная, д. 8, 88333621906, admdaro@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 9,
        "col3": "Администрация Зуевского района Кировской области",
        "col4": "612412, Кировская область г. Зуевка, ул. Опалева, д. 65, 8 (83337) 25461, azr@zrko.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 10,
        "col3": "Администрация Кикнурского муниципального округа",
        "col4": "612300, Кировская область, Кикнурский район, пгт Кикнур, ул. Советская, д. 36, kiknur-opeka@mail.ru, (83341)5-14-79"
    },
    {
        "col1": "Кировская область",
        "col2": 11,
        "col3": "Администрация Кильмезского муниципального района",
        "col4": "613570 Кировская область, пгт Кильмезь, ул.Советская, д.79, opekakilmez2017@yandex.ru (83338)22036"
    },
    {
        "col1": "Кировская область",
        "col2": 12,
        "col3": "Администрация Кирово-Чепецкого района",
        "col4": "613040 Кировская область, ул. Перовмайская, д. 6, 8(83361)49150, mailbox@admkchr.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 13,
        "col3": "Администрация Котельничского района ",
        "col4": "612600, Кировская область, г. Котельнич, ул. Карла Маркса, д. 16, kotelnich_rayon@mail.ru 8(83342)4-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 14,
        "col3": "Администрация Куменского района",
        "col4": "613400 Кировская область, Куменский район, ул. Кирова, д.11, 8 (83343)2-12-50, adm@kumensky.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 15,
        "col3": "Администрация Лебяжского муницильного округа",
        "col4": "613500, Кировская область, Лебяжский район, пгт Лебяжье, ул.Комсомольская, д.5, leb_opeka@bk.ru, 88334420942"
    },
    {
        "col1": "Кировская область",
        "col2": 16,
        "col3": "Администрация муниципального образования Лузский муниципальный округ Кировской области",
        "col4": "613980, Кировская область, г. Луза, ул. Ленина, д. 35, admluza@kirovreg.ru, 883346-5-18-04"
    },
    {
        "col1": "Кировская область",
        "col2": 17,
        "col3": "Администрация Малмыжского района",
        "col4": "612920, Кировская область, г. Малмыж, ул. Чернышевского, д. 2а opekamalm@yandex.ru (83347) 2-28-18"
    },
    {
        "col1": "Кировская область",
        "col2": 18,
        "col3": "Администрация Мурашинского муниципального округа",
        "col4": "613711, Кировская область, г. Мураши, ул. Карла Маркса, д. 28, opeka_murashi@mail.ru, 8(83348)2-28-86"
    },
    {
        "col1": "Кировская область",
        "col2": 19,
        "col3": "администрация Нагорского района",
        "col4": "613260, Кировская область пгт. Нагорск ул. Леушина д. 21 (83349) 2-16-70 admnago@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 20,
        "col3": "Администрация Немского муниципального округа ",
        "col4": "613470 Кировская область ул. Советская д. 36 т. 8(83350)2-22-85. opeka.nema@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 21,
        "col3": "Администрация Нолинского района",
        "col4": "613440, Кировская область, г.Нолинск, ул.Спартака, д.36, opekanolinsk@mail.ru, 88336821180"
    },
    {
        "col1": "Кировская область",
        "col2": 22,
        "col3": "Администрация Омутнинского района",
        "col4": "612740, Кировская область, г. Омутнинск, ул. Комсомольская, д. 9, admomut@kirovreg.ru, 88335221251"
    },
    {
        "col1": "Кировская область",
        "col2": 23,
        "col3": "Управление образования администрации Опаринского МО",
        "col4": "613810, Кировская область, пгт. Опарино, ул. Первомайская, 14 opeka.oparino@yandex.ru (83353) 2-11-02"
    },
    {
        "col1": "Кировская область",
        "col2": 24,
        "col3": "Администрация Орловского района",
        "col4": "612270, Кировская обл, Г.Орлов, ул. Ст.Халтурина, д. 18 orlovopeka@mail.ru (83365) 2-16-45"
    },
    {
        "col1": "Кировская область",
        "col2": 25,
        "col3": "Администрация Пижанского муниципального округа ",
        "col4": "613380, Кировская область, Пижанский район, пгт. Пижанка, ул. Труда, 25 opp4325@yandex.ru 8(83355)22172"
    },
    {
        "col1": "Кировская область",
        "col2": 26,
        "col3": "Администрация Подосиновского района Кировской области",
        "col4": "613930, Кировская область, Подосиновский район, пгт Подосиновец,ул. Советская д. 77, podopeka@yandex.ru, 8(83351) 2-16-54"
    },
    {
        "col1": "Кировская область",
        "col2": 27,
        "col3": "Администрация Санчурского Муниципального округа",
        "col4": "Кировская область, Санчурский район, пгт. Санчурск, ул. Р. Люксембург, д.6А, 612370 тел. 883357 2-13-21 , admsanch@kiroveg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 28,
        "col3": "Администрация Свечинского муниципального округа",
        "col4": "612040, Кировская область, пгт Свеча, ул. Октябрьская, д.20, Тел: 8(83358)2-23-35, Эл.п.: sv_opeka@mail.ru, admsvec@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 29,
        "col3": "Администрация Слободского муниципального района",
        "col4": "613150Кировская область г. Слободской ул. Советская д.86 тел 88336241252 admslob@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 30,
        "col3": "Администрация Советского муниципального района, ",
        "col4": "613340,Кировская область, Советский район, г. Советск, ул. Кирова,д.5 (83375)2"
    },
    {
        "col1": "Кировская область",
        "col2": 31,
        "col3": "Администрация Сунского муниципального района ",
        "col4": "612450, Кировская область, Сунский район, пгт Суна, ул.Большевиков, д.1, 8(83369)33694 sunaopeka@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 32,
        "col3": "Администрация Тужинского муниципального района",
        "col4": "612200, Кировская область, Тужинский район, пгт Тужа, ул. Горького, д. 5, ruotuzha43@yandex.ru, 8(83340)2-16-81"
    },
    {
        "col1": "Кировская область",
        "col2": 33,
        "col3": "администрация Унинского муниципального округа",
        "col4": "612540 Кировская область, Унинский район, пгт.Уни, ул.Ленина, д.17 admunin@kirovreg.ru 8833592-14- 65"
    },
    {
        "col1": "Кировская область",
        "col2": 34,
        "col3": "Администрация Уржумского муниципального района",
        "col4": "  613530, г.Уржум, ул.Рокина, д. 13, 9536724559, ruo.opeka.@ mаil.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 35,
        "col3": "Администрация Фаленского муниципального округа",
        "col4": "612500, Кировская область, Фаленский район, пгт Фаленки, ул. Свободы,д 65, 8833322-11-30 kd81123@yandex.ru, 89536922268 opekafalenki@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 36,
        "col3": "Администрация Шабалинского муниципального райна",
        "col4": "612020 Кировская область, Шабалинский район, пгт. Ленинское, ул. Советская д. 33 . shabal_admin@mail.ru (83345) 20158"
    },
    {
        "col1": "Кировская область",
        "col2": 37,
        "col3": "администрация Юрьянского района",
        "col4": "613600 Кировская область, Юрьянский район, пгт Юрья, ул. Ленина, 46, admjurja@kirovred.ru 883366 2-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 38,
        "col3": "Администрация Яранского района",
        "col4": "г.Яранск, ул.Кирова, д.10 612260, 883367-2-06-59 opeka-yaransk@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 39,
        "col3": "Администрация ЗАТО Первомайский",
        "col4": "613648,ЗАТО Первомайский ул. Волкова, 14 , admzatopervomaysk@mail.ru, 8(833266)2-42-31"
    },
    {
        "col1": "Кировская область",
        "col2": 40,
        "col3": "Администрация города Вятские Поляны",
        "col4": "612964, Кировская область, г. Вятские Поляны, ул. Гагарина 28а 8(83334) 61162 opekavp@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 41,
        "col3": "Администрация муниципального образования городской округ \"Город Кирово-Чепецк\" Кировской области",
        "col4": "613040, Кировская область, г. Кирово-Чепецк, ул. Первомайская, д. 6, 8(83361) 4-50-50 admcher@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 42,
        "col3": "Управление образования города Котельнича администрации городского округа города Котельнича",
        "col4": "612600, Кировская область, г. Котельнич, ул.Ленина, 26,               8 (83342) 4-15-72, yokotel@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 43,
        "col3": "Администрация города Слободского",
        "col4": "613150, г. Слободской, ул. Советская, д.86, тел. 8(8332) 25-53-49, slob-adm@rambler.ru."
    },
    {
        "col1": "Кировская область",
        "col2": 44,
        "col3": "Администрация города Кирова",
        "col4": "610000, Кировская область, г. Киров, ул. Воровского, д. 39, opeka@admkirov.ru, 8(8332)54-64-12, 54-00-42, 57-94-67, 54-09-68, 54-77-48"
    },
    {
        "col1": "Кировская область",
        "col2": 45,
        "col3": "Администрация Оричевского района",
        "col4": "612080, Кировская область, Оричевский район, пгт Оричи, ул. К. Маркса, д. 12 ( 883354)2-11-75, adm@orichi-rayon.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 1,
        "col3": "администрация Антроповского муниципального округа Костромской области",
        "col4": "157260, Костромская область, Антроповский район, п. Антропово, ул.Октябрьская, д. 12, (49430) 3-52-03, antropovo@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 2,
        "col3": "администрация Буйского муниципального района Костромской области ",
        "col4": "157000, Костромская область, пл.Революции, 13, (49435) 4-51-40, bui@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 3,
        "col3": "администрация Вохомского муниципального района Костромской области ",
        "col4": "157760, Костромская область, Вохомский район, п. Вохма, ул.Советская, 39, (49450) 2-13-34, vohma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 4,
        "col3": "администрация Галичского муниципального района Костромской области ",
        "col4": "157201, Костромская область, Галичский район, г. Галич, пл.Революции, 23а, (49437) 2-21-34, galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 5,
        "col3": "администрация Кадыйского муниципального округа Костромской области ",
        "col4": "157980, Костромская область, Кадыйский район, п.Кадый, ул.Центральная, 3, (49442) 3-40-08, kadiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 6,
        "col3": "администрация Кологривского муниципального округа Костромской области ",
        "col4": "157400, Костромская область, Кологривский район, г. Кологрив, ул. Набережная р.Киченки, 13, (49443)5-27-49, kologriv@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 7,
        "col3": "администрация Костромского муниципального района Костромской области ",
        "col4": "156961, Костромская область, г. Кострома, ул. М. Новикова, 7, (4942) 55-02-02, kosrn@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 8,
        "col3": "администрация Красносельского муниципального района Костромской области ",
        "col4": "157940, Костромская область, п. Красное-на-Волге, Красная пл., 15, (49432) 2-19-46, krasnoe@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 9,
        "col3": "администрация Макарьевского муниципального округа Костромской области ",
        "col4": "157460, Костромская область, Макарьевский район, г. Макарьев, пл. Революции, 8, (49445) 55-1-31 makarev@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 10,
        "col3": "администрация Мантуровского муниципального округа Костромской области ",
        "col4": "157300, Костромская область, Мантуровский район, г. Мантурово, ул.Центральная, д.5, (49446) 2-04-50 gorod_manturovo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 11,
        "col3": "администрация Межевского муниципального округа Костромской области  ",
        "col4": "157420, Костромская область, Межевской район, с. Георгиевское, ул. Октябрьская, 39, (49447) 5-23-19 mezha@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 12,
        "col3": "администрация города Нерехта и Нерехтского муниципального района Костромской области                        ",
        "col4": "157800, Костромская область, Нерехтский район, г. Нерехта, ул.Победы, 1,  (49431) 7-62-03 nerehta@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 13,
        "col3": "администрация Нейского муниципального округа Костромской области ",
        "col4": "157330, Костромская область, Нейский район, г. Нея, ул.Соловьева, 6, (49444) 3-19-14 neya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 14,
        "col3": "администрация Октябрьского муниципального округа Костромской области ",
        "col4": "157780, Костромская область, октябрьский район, с. Боговарово, ул. Победы, 37, (49451)2-11-86, oktyabrskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 15,
        "col3": "администрация Островского муниципального округа Костромской области ",
        "col4": "157900, Костромская область, Островский район, п. Островское, ул. Советская, 56, (49438) 2-72-95, ostrovskiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 16,
        "col3": "администрация Павинского муниципального округа Костромской области ",
        "col4": "157650, Костромская область, Павинский район, с. Павино, ул.Октябрьская, 15, (49439) 2-11-63  pavino@ kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 17,
        "col3": "администрация Парфеньевского муниципального округа Костромской области",
        "col4": "157270, Костромская область, Парфеньевский район, с.Парфеньево, ул. Маркова, 17,  (49440) 2-41-52, parfenyevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 18,
        "col3": "администрация Поназыревского муниципального округа Костромской области ",
        "col4": "157580, Костромская область, Поназыревский район, п. Поназырево, ул. Свободы, 1, (49448)2-11-67, ponazyrevo@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 19,
        "col3": "администрация Пыщугского муниципального округа Костромской области ",
        "col4": "157630, Костромская область, Пыщугский район, с. Пыщуг, ул.Чкалова, 6, (49452) 2-77-81, pyshchug@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 20,
        "col3": "администрация Солигаличского муниципального округа Костромской области ",
        "col4": "157170, Костромская область, Солигаличский район, г. Солигалич, ул. Коммунистическая, 1, (49436)5-12-74 soligalich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 21,
        "col3": "администрация Судиславского муниципального района Костромской области ",
        "col4": "157860, Костромская область, Судиславский район, п. Судиславль, ул. Советская, 2, (49433) 9-84-76 sudislavl@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 22,
        "col3": "администрация Сусанинского муниципального района Костромской области ",
        "col4": "157080, Костромская область, Сусанинский район, ул. Ленина, 2а, (49434) 9-03-33 susanino@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 23,
        "col3": "администрация Чухломского муниципального района",
        "col4": "157130, Костромская область, Чухломский район, г. Чухлома, пл.Революции, 11,  (49441) 2-12-13, chuhloma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 24,
        "col3": "администрация Шарьинского муниципального района Костромской области ",
        "col4": "157500, Костромская область, Шарьинский район, г. Шарья, ул. Октябрьская, 21, (49449) 5-03-50, sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 25,
        "col3": "администрация городского округа город Кострома Костромской области ",
        "col4": "156000, Костромская область, г. Кострома, ул. Советская, 1, (4942) 31-39-32, 31-53-41, gorod_kostroma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 26,
        "col3": "администрация городского округа город Буй Костромской области",
        "col4": "157000, Костромская область, г. Буй, пл.Революции, 12,  (49435) 4-45-03, доб.118,   gorod_buy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 27,
        "col3": "администрация городского округа город Волгореченск Костромской области",
        "col4": "156901, Костромская область, г. Волгореченск, ул. Имени 50-летия Ленинского Комсомола, 4,  (49453) 5-25-02, gorod_volgorechensk@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 28,
        "col3": "администрация городского округа город Галич Костромской области ",
        "col4": "157201, Костромская область, г. Галич, пл. Революции, 23а, (49437) 2-17-20, gorod_galich@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 29,
        "col3": "администрация городского округа город Шарья Костромской области",
        "col4": "157500, Костромская область, г. Шарья, ул. Октябрьская, 21, (49449) 5-89-32 gorod_sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 1,
        "col3": "Управление по вопросам семьи и детства администрации муниципального образования город Армавир",
        "col4": "352900, г.Армавир, ул.Розы Люксембург, дом 146, раб./ф 8(86137)3-25-23, 3-78-73,  4-20-77, 4-95-44, 3-47-38    e-mail: arm_sem_wed@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 2,
        "col3": "Отдел по учету граждан в качестве нуждающихся в жилых помещениях правового управления администрации муниципального образования город-курорт Геленджик",
        "col4": "353460, г. Геленджик, ул. Революционная, д. 1, каб. 229, 232, 8 (86141) 2-08-56, 8 (86141) 2-08-31, pul@gelendzhik.org"
    },
    {
        "col1": "Краснодарский край",
        "col2": 3,
        "col3": "Управление по жилищным вопросам администрации муниципального образования город Краснодар ",
        "col4": "350015, г. Краснодар, ул. Кузнечная, 6, 8 (861) 218-92-41, housing@krd.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 4,
        "col3": "Управление городского хозяйства администрации муниципального образования город Новороссийск",
        "col4": "353900, г. Новороссийск, ул. Рубина, 25, 8 (861-7) 61-18-93, ugh_novoros@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 5,
        "col3": "Департамент городского хозяйства администрации муниципального образования городской округ город-курорт Сочи Краснодарского края",
        "col4": "354000, г.-к. Сочи, Островского, 35/11, каб.5 и 12, 8 (862) 266-06-06 (доб. 1750), dgh@sochiadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 6,
        "col3": "Управление имущественных отношений администрации муниципального образования город-курорт Анапа",
        "col4": "353440, г. Анапа Пушкина, д. 28, 8 (861-33) 5-29-68                                          8 (918) 939-02-29, go@anapa.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 7,
        "col3": "Отдел по вопросам семьи и детства администрации муниципального образования город Горячий Ключ",
        "col4": "353290, г. Горячий Ключ, ул. Ленина 191, раб.тел. 8(86159) 4-22-09, 3-78-09, e-mail: ovsd_gk@mail.ru   "
    },
    {
        "col1": "Краснодарский край",
        "col2": 8,
        "col3": "Отдел топливно-энергетического комплекса, жилищно-коммунального хозяйства, транспорта и связи администрации муниципального образования Ленинградский район",
        "col4": "353740 ст. Ленинградская, ул. Чернышевского, д.179, 8 (861-45) 3-85-60, otdelgkh-len@mail.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 9,
        "col3": "Отдел по вопросам семьи и детства администрации муниципального образования Приморско-Ахтарский район",
        "col4": "353861, г. Приморско-Ахтарск,  ул. 50 лет Октября, 92 , раб.тел. 8(86143) 3-08-82, 3-09-66, e-mail: ahtsid@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 10,
        "col3": "Управление ЖКХ и ТЭК администрации муниципального образования Туапсинский район",
        "col4": "352800 г. Туапсе, ул. Свободы, д. 3, 7 861 672-15-01, +7 861 672-58-44, +7 918 335-96-69,  okops@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 11,
        "col3": "Управление муниципальной собственности администрации муниципального образования Абинский район",
        "col4": "353320, г. Абинск, ул. Кубанская, д. 18, 8(861)504-35-70, diok.ms01@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 12,
        "col3": "Управление имущественных отношений администрации муниципального образования Апшеронский район",
        "col4": "352690, г. Апшеронск, ул. Коммунистическая, 17, 8(86152)27519, apsheronsk@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 13,
        "col3": " Отдел опеки и попечительства администрации муниципального образования Белоглинский район",
        "col4": "353000, с. Белая Глина, ул. Красная, д. 160, каб. 305, 8 (86154) 7-34-75,  osdbg@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 14,
        "col3": "Управление имущественных отношений администрации муниципального образования Белореченский район",
        "col4": "352630, г.Белореченск, \nул.Ленина, 66, 8 (861-55) 2-29-45, beluio2@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 15,
        "col3": "Отдел имущественных отношений администрации муниципального образования Брюховецкий район",
        "col4": "352750, ст-ца Брюховецкая ул Красная, д. 211, 8 (861-56) 3-20-56,\nbrukhovezk@mo.krasnodar.ru,\nms04@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 16,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Выселковский район",
        "col4": "353100, ст. Выселки, \nул. Ленина, 37, 8 -861 57- 7- 58- 75 ms05@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 17,
        "col3": "Отдел топливно-энергетического клмплекса и жилищно-коммунального хозяйства управления топливно-энергетического комплекса, жилищно-коммунального хозяйства и транспорта администрации муниципального образования Гулькевичский район",
        "col4": " 352190, г. Гулькевичи, ул. Советская, 14, 8 (861-60) 5-18-16, 8 (918) 951-26-16, otdel.zhkh@mogulk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 18,
        "col3": "администрация муниципального образования Динской район",
        "col4": "353200 ст-ца Динская, ул. Красная, 55, 8-(86162) 6-17-02, dinskaya@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 19,
        "col3": "Управление жилищно-коммунального хозяйства и капитального строительства администрации муниципального образования Ейский район",
        "col4": "353680, г. Ейск, ул. Победы, д. 108, 8(86132)2-32-24, gkh@yeiskraion.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 20,
        "col3": "Управление имущественных отношений администрации муниципального образования Кавказский район",
        "col4": "352380, г. Кропоткин, пер. Братский/ул. Гоголя, д. 18/44, 8 +7 (86138) 6-26-23 +7 (86138) 6-41-23 uio.kavkazraion@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 21,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Калининский район",
        "col4": "353780, ст. Калининская, ул. Ленина, 147, раб.тел. 8(86163)21-4-88,  22-1-90, 22-0-47  e-mail: kalinaovsd@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 22,
        "col3": "Управление имущественных отношений администрации муниципального образования Каневской район",
        "col4": "353730 ст. Каневская,                                                                    ул. Вокзальная, 32, 8-861-64-7-57-17 io@kanevskadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 23,
        "col3": "Управление земельных и имущественных отношений администрациимуниципального образования Кореновский район",
        "col4": "353180 г. Кореновск,                                                                    ул. Красная, 41 8-861-42-4-20-78  uzio.mokorenovsk@admkor.ru   "
    },
    {
        "col1": "Краснодарский край",
        "col2": 24,
        "col3": "Управление муниципальной собственностью администрации муниципального образования Красноармейский район",
        "col4": "353800, станица Полтавская, ул. Красная, д. 122, +7 (86165) 3-32-11, +7 (86165) 3-25-30, +7 (86165) 3-23-30, komitetums@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 25,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Крыловский район",
        "col4": "352080,ст-ца Крыловская, ул. Орджоникидзе, 43, тел. раб. 8(86161)35-7-19 e-mail: semyakril@rambler.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 26,
        "col3": "Управление опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Крымский район",
        "col4": "353389, г. Крымск, ул. Вишневая, д. 2, 8(86131) 2-05-10, 2-16-11, 2-17-11, 2-17-10, krymsk_usd@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 27,
        "col3": "Управление имущественных отношений администрации муниципального образования Курганинский район",
        "col4": " 352430, г. Курганинск, ул. Ленина, 27, +7 861 472-44-54, +7 616 226-18-05, +7 861 472-10-67, kurg_adm@mail.ru, dinsk_zem@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 28,
        "col3": "Управление муниципальным имуществом администрации муниципального образования Кущевский район",
        "col4": " 352030, ст-ца Кущевская, пер. им. Б.Е.Москвича, д.67, +7 (86168) 5-70-98, kushzakaz@yandex.ru\nms17@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 29,
        "col3": "Управление имущественных отношений администрации муниципального образования Лабинский район",
        "col4": "352500, г. Лабинск, ул. К.Маркса, д. 160, +7 (86169) 3-11-33  k40labadmin@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 30,
        "col3": "Управление имущественных и зенмельных отношений администрации муниципального образования Мостовский район",
        "col4": "352570, пгт Мостовской, ул. Горького , д. 140, 8-861-92-5-13 42  ms20@diok.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 31,
        "col3": "Управление имущественных отношений администрации муниципального образования Новокубанский район",
        "col4": "352240 г. Новокубанск,                                      ул.Первомайская, 151, +7 (86195) 4‒15‒78, yuonr@mo.krasnodar.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 32,
        "col3": "Управление имущественных и земельных отношений администрации муниципального образования Новопокровский район",
        "col4": "353020 ст. Новопокровская ул.Ленина, 101, 8-861-49 7-31-97 ms22@novopokrovskaya.com    "
    },
    {
        "col1": "Краснодарский край",
        "col2": 33,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Отрадненский район",
        "col4": "352292, ст. Отрадная, ул. Первомайская. д.20, раб.факс 8(86144) 3-36-98, 3-35-98 e-mail: detstvo-2008@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 34,
        "col3": "Управление жилищно-коммунального хозяйства администрации муниципального образования Павловский район",
        "col4": "352040 ст. Павловская, ул.Пушкина, д. 260  8-861-91 3-20-22, pavlovskaya-gkh@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 35,
        "col3": "Управление имущественных отношений администрации муниципального образования Северский район",
        "col4": "353240, ст. Северская, ул. Петровского, д. 6 +7 (38-23) 77-38-29,  uio@uio.seversknet.ru, seversk-uio@gov70.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 36,
        "col3": "Управление жизнеобеспечения, транспорта и связи администрации муниципального образования Славянский район",
        "col4": "353560, Славянск-на-Кубани, , Крупская ул., 217 а, 8 (861-46) 3-20-41, slav_dorhoz@mail.ru slavgkh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 37,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации  муниципального образования Староминский район  ",
        "col4": "353600, ст. Староминская, ул. Петренко, д.5,  раб. тел. (86153) 4-31-16,  факс  5-71-46 e-mail: semya-detstvo@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 38,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Тбилисский район",
        "col4": "352360 ст. Тбилисская                                              ул. Первомайская, 17  8-8615-83-19-43 otdelumi@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 39,
        "col3": "Управление опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Темрюкский район",
        "col4": "353500, г.Темрюк, ул.Ленина, 63, раб. тел. 8 (86148) 5-29-71, e-mail:opeka-temruk@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 40,
        "col3": "Отдел земельных и имущественных отношений администрации муниципального образования Тимашевский район",
        "col4": "352700, г.Тимашевск, ул.Пионерская, 90а 8(86130) 4-20-81 tim-imusch@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 41,
        "col3": "Управление муниципальных ресурсов администрации муниципального образования Тихорецкий район",
        "col4": "Управление муниципальных ресурсов администрации муниципального образования Тихорецкий район"
    },
    {
        "col1": "Краснодарский край",
        "col2": 42,
        "col3": "Отдел по вопросам имущественных отношений и развития инвестиций администрации муниципального образования Успенский район",
        "col4": "352450 с.Успенское, ул. Калинина, 76  8-861-40 5-52-31 uspimyh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 43,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Усть-Лабинский район",
        "col4": "352330, г. Усть-Лабинск, ул. Ленина, 38   раб. 8(86135) 5-17-52, 4-07-52, факс8(86135) 5-17-61 e-mail: oogrebenukova@adminustlabinsk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 44,
        "col3": "Отдел по распоряжению муниципальным   имуществом  администрации муниципального образования  Щербиновский район",
        "col4": "Отдел по распоряжению муниципальным   имуществом  администрации муниципального образования  Щербиновский район"
    },
    {
        "col1": "Красноярский край",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Красноярского края",
        "col4": "660075, Красноярский край, г. Красноярск, ул.Заводская, д.14\npriem@krasminstroy.ru, 8 (391) 211-05-39, 8 (391) 211-12-55. Министерство в этой части, только принимает и пересылает по компетенции в ОМСУ заявление (в системе РГПУ), т.к решение о сокращении срока действия договора найма принимают непосредственно органы местного самоуправления"
    },
    {
        "col1": "Курганская область",
        "col2": 1,
        "col3": "Администрация города Кургана",
        "col4": "640000, г. Курган, пл.им. В.И. Ленина,1, тел. (3522) 42-88-01; mail@kurgan-city.ru ; 45t00902@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 2,
        "col3": "Администрация города Шадринска",
        "col4": "641884, Курганская область, г. Шадринск, ул. Свердлова, д.59, тел. (35253) 63212; public@shadrinsk-city.ru; 45t02602@kurganobl.ru 26"
    },
    {
        "col1": "Курганская область",
        "col2": 3,
        "col3": "Администрация Альменевского муниципального округа Курганской области ",
        "col4": "641130, Курганская область, с. Альменево, пл. Комсомола, 1, тел. (35242) 9-87-41; 45t00102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 4,
        "col3": "Администрация Белозерского муниципального округа Курганской области ",
        "col4": "641330, Курганская область, с.Белозерское, ул. К.Маркса, д.16, тел.(35232) 2-90-70; belozeradm@mail.ru; 45t00202@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 5,
        "col3": "Администрация Варгашинского муниципального округа Курганской области",
        "col4": "641230, область Курганская, район Варгашинский, р.п.Варгаши, улица Чкалова, д. 22, тел. (35233) 2-21-55;  45t00302@kurganobl.ru  "
    },
    {
        "col1": "Курганская область",
        "col2": 6,
        "col3": "Администрация Далматовского муниципального округа Курганской области",
        "col4": "641730,  Курганская обл., Далматовский р-он, г. Далматово, ул. Советская, 187, тел. (35252) 3-81-30; radm@dalmatovo.ru; 45t00402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 7,
        "col3": "Администрация  Звериноголовского муниципального округа Курганской области",
        "col4": "641480, Курганская область, с. Звериноголовское, ул. Чапаева, 41, тел. (35240) 2-15-05; 45t00502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 8,
        "col3": "Администрация Каргапольского муниципального округа Курганской области",
        "col4": "641920, Курганская область, р.п. Каргаполье, ул. Калинина, д.35, тел. (35256) 2-16-30;  45t00602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 9,
        "col3": "Администрация Катайского района муниципального округа Курганской области",
        "col4": "641700, Курганская область, г.Катайск, ул. Ленина, д.200, тел. (35251)21545; katadmin@mail.ru ; 45t00702@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 10,
        "col3": "Администрация Кетовского муниципального округа Курганской области",
        "col4": "641310, Курганская область, с.Кетово, ул. Космонавтов, д.39, тел. (35231) 23541; AdmKetr@mail.ru; 45t00802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 11,
        "col3": "Администрация Куртамышского муниципального округа Курганской области",
        "col4": "641430, Курганская область, г. Куртамыш, ул. XХII партсъезда, д.40, тел. (35249)2-18-10; kurtamysh-gorod@yandex.ru; 45t01002@kurganobl.ru 10"
    },
    {
        "col1": "Курганская область",
        "col2": 12,
        "col3": "Администрация Лебяжьевского муниципального округа Курганской области",
        "col4": "641500, Курганская область, р.п. Лебяжье, ул. Пушкина, д.14, тел. (35237) 9-08-78; 45t01102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 13,
        "col3": "Администрация Макушинского муниципального округа Курганской области",
        "col4": "641600, Курганская область, г. Макушино, ул. Ленина, д.85, тел. (35236) 2-06-42; 45t01202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 14,
        "col3": "Администрация Мишкинского муниципального округа Курганской области",
        "col4": "641040, Курганская область, р.п. Мишкино, ул. Ленина, д.30, тел. (35247)2-28-01; 45t01302@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 15,
        "col3": "Администрация Мокроусовского муниципального округа Курганской области",
        "col4": "641530, Курганская область, с. Мокроусово, ул. Советская, д.31, тел. (35234)9-77-41; admmokr@mail.ru; 45t01402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 16,
        "col3": "Администрация Петуховского муниципального округа Курганской области",
        "col4": "641640, Курганская область, г. Петухово, ул. К. Маркса, 27, тел. (35235) 3-84-98; admpr@bk.ru; 45t01502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 17,
        "col3": "Администрация Половинского муниципального округа Курганской области",
        "col4": "641780, Курганская область, с. Половинное, ул. Победы, д.9, тел.(35238) 9-15-33; 45t01602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 18,
        "col3": "Администрация Притобольного муниципального округа Курганской области",
        "col4": "641400, Курганская область, с. Глядянское, ул.Красноармейская, д.19, (3522) 42-89-80 доб. 224;  45t01702@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 19,
        "col3": "Администрация Сафакулевского муниципального округа Курганской области",
        "col4": "641080, Курганская область, с. Сафакулево, ул. Куйбышева, д.35; тел. (35243) 2-93-71; safakulevo@bk.ru; 45t01802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 20,
        "col3": "Администрация Целинного муниципального округа Курганской области",
        "col4": "641150, Курганская область, с. Целинное, ул. Советская, д.66, тел. (35241)2-10-43;  45t01902@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 21,
        "col3": "Администрация Частоозерского муниципального округа Курганской области ",
        "col4": "641570, Курганская область, с. Частоозерское, ул. Октябрьская, д.126, (3522) 42-84-45; 45t02002@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 22,
        "col3": "Администрация Шадринского муниципального округа Курганской области",
        "col4": "641870, Курганская область, г. Шадринск ул. Р. Люксембург, д.10, тел. (35253) 7-62-43; 45t02102@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 23,
        "col3": "Администрация Шатровского муниципального округа Курганской области",
        "col4": "641960, Курганская область, с. Шатрово, ул. Федосеева, д.53, тел. (35257) 9-13-98; 45t02202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 24,
        "col3": "Администрация Шумихинского муниципального округа Курганской области",
        "col4": "641100, Курганская область, г. Шумиха, ул. Кирова, д.12, тел. (35245)2-11-67; 45t02302@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 25,
        "col3": "Администрация Щучанского муниципального округа Курганской области",
        "col4": "641010 Курганская область, г. Щучье, ул. Победы, 1, тел. (35244) 3-69-67; 45t02402@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 26,
        "col3": "Администрация Юргамышского муниципального округа Курганской области",
        "col4": "641200 Курганская область, р.п. Юргамыш, ул. Ленина, 43, тел.(35248) 9-26-91; 45t02502@kurganobl.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 1,
        "col3": "Министерство социального социального обеспечения, материнства и детства Курской области",
        "col4": "305007, г. Курск, ул. Моковская, д. 2-г, тел.: +7 (4712) 400-300 факс +7 (4712) 35-75-23 e-mail: kco@rkursk.ru; "
    },
    {
        "col1": "Курская область\n",
        "col2": 2,
        "col3": "Администрация Беловского района Курской области",
        "col4": "307910 Курская область, Беловский р-он, сл.Белая, Советская площадь, 1, тел.: 8(47149)2-11-40, e-mail: oop.belaya@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 3,
        "col3": "Администрация Большесолдатского района Курской области ",
        "col4": "305007, г. Курск, ул. Красная площадь, д.6, подъезд 3, каб. 247; тел.: 8(4712) 51-09-89, e-mail: petina6464@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 4,
        "col3": "Отдел опеки и попечительства Администрации Глушковского района Курской области \n",
        "col4": "305000 г. Курск, ул. Ендовищенская 8а, тел.:8(4712)44-62-20, e-mail:opeka-gl.307450@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 5,
        "col3": "Администрация Горшеченского района Курской области \n",
        "col4": "306800, Курская обл., Горшеченский р-н, рп. Горшечное, ул. Кирова, д. 18, тел. 8(47133)2-13-75, e-mail: oszn_gr@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 6,
        "col3": "Управление  образования, опеки и попечительства Администрации Дмитриевского района Курской области\n",
        "col4": "307500 г. Дмитриев, ул. Ленина, д. 79, тел. 8(47150) 2-23-06, e-mail: opeka_46@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 7,
        "col3": "Администрация Железногорского района Курской области \n",
        "col4": "301170, г Железногорск, ул. Ленина, д.52, каб. 124. тел., факс 8 (47148)3-50-48, e-mail: 35048@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 8,
        "col3": "Отдел образования, опеки и попечительства Администрации Золотухинского района Курской области \n",
        "col4": "306020 Золотухинский район, Курская область, Золотухинский район, п. Золотухино, ул. пер. Лесной, д. 5, тел.: 8(47151)2-13-68, e-mail:  opekazolotuhino@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 9,
        "col3": "Муниципальное образование «Касторенский муниципальный район Курской области» \n",
        "col4": "306700, Курская область, Касторенский район, п.Касторное, ул. 50 лет октября, д.6, тел.8(47157)2-19-52, e-mail: otdelpoopeke46@.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 10,
        "col3": "Администрация Конышевского района Курской области \n",
        "col4": "307620, Курская область, п. Конышевка, ул.Ленина, д.19, тел. 8(47156)2-13-55, e-mail:opeka4609@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 11,
        "col3": "Отдел опеки и попечительства управления по образованию, опеки и попечительству администрации Кореневского района \n",
        "col4": "305044, г. Курск, ул. Союзная, 29а,\nтел.: 8(4712) 34-28-98, e-mail: korenevo-opeka@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 12,
        "col3": "Администрация Курского района Курской области ",
        "col4": "305001, г. Курск, ул. Белинского, 21, тел. 8(4712)54-66-09, otdopeki.kurskrn@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 13,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 14,
        "col3": "Администрация Льговского района Курской области \n",
        "col4": "307750, Курская область, г. Льгов, ул. Красная Площадь, д. 4 б, тел.: 8(47140)2-13-31, opeka-lgov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 15,
        "col3": "Администрация Медвенского района Курской области \n",
        "col4": "307030, Курская область, пос.Медвенка, ул.Советская, д.20, тел.: 8(47146)4-19-76, e-mail: medwenka@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 16,
        "col3": "\nАдминистрация Мантуровского района Курской области \n",
        "col4": "307000, Курская область, Мантуровский район, с. Мантурово, ул. Ленина, д.13 тел.: 8(4715) 521286,\ne-mail: opeka@smanturovo.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 17,
        "col3": "Администрация Обоянского района Курской области\n \n",
        "col4": "306230 Курская область, г. Обоянь, ул. Шмидта, д.6, 8(47141)2-22–55, e-mail oboyan-opeka@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 18,
        "col3": "Администрация Октябрьского района Курской области \n",
        "col4": "307200, Курская область,Октябрьский район, п. Прямицыно, ул. Октябрьская, 134, тел.: 8(47142) 2-13-36, 2-13-07,2-11-57, 2-16-31, e-mail:opekaoct@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 19,
        "col3": "Администрация Пристенского района Курской области \n",
        "col4": "306200, Курская область, Пристенский район, п. Пристень, ул. Ленина, дом 5, тел.: 8(47134)2-10-51 факс  e-mail: opekapristen@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 20,
        "col3": "Отдел по опеке и попечительству Администрации Поныровского района Курской области \n\n",
        "col4": "306000, Курская область, Поныровский район, п. Поныри, ул. Ленина, д. 14, \nтел.: 8(47135)2-11-07, \ne-mail: ponyri.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 21,
        "col3": "Администрация Рыльского района Курской области \n",
        "col4": "307370, Курская область, г. Рыльск, ул. К. Либкнехта, д. 23А тел. 8(47152)2-19-98, e-mail: opeka.rylsk@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 22,
        "col3": "Отдел опеки и попечительства Администрации Советского района Курской области )\n",
        "col4": "306600 Курская область, Советский район, п. Кшенский, ул. Пролетарская, д.45, тел.: 8(4712) 2-22-32, факс 8(4712)2-22-32, e-mail: opeka.sovr@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 23,
        "col3": "Администрация Солнцевского района Курской области\n",
        "col4": " 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 44, тел.: 8(47154 2-22-36, e-mail: adm4622@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 24,
        "col3": "Управление образования Администрации Суджанского района Курской области \n",
        "col4": "305004, г. Курск, ул. Садовая, д.31,3-этаж, каб.39, тел.: 8-951-339-92-30, e-mail: sudjanskruo23@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 25,
        "col3": "Отдел по опеке и попечительству Администрации Тимского района Курской области \n",
        "col4": "307060, Курская обл., Тимский район, п. Тим, ул. Кирова 51, тел.: 8(47153)2-37-39, e-mail:opeka.admtim@rkursk.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 26,
        "col3": "Отдел по опеке и попечительству Управления образования Администрации Фатежского района Курской области \n",
        "col4": "307100, Курская область, Фатежский район, г. Фатеж, ул. Урицкого, 45, тел.: 8(47144)2-13-90, e-mail:opekafafezh89@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 27,
        "col3": "Администрация Хомутовского района Курской области \n",
        "col4": "307540, Курская область, п. Хомутовка, ул. Память Ильича, д.85, тел: 8(471-37)2-15-06, e-mail: opeka.hom@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 28,
        "col3": "Отдел по опеке и попечительству Администрации Черемисиновского района Курского района\n",
        "col4": "306440, п. Черемисиново, ул. Советская, д. 4, тел.: 8(47159)2-10-75, e-mail: opeka.tcheremisinovo@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 29,
        "col3": "Администрация Щигровского района Курской области \n",
        "col4": "306530 Курская область, г. Щигры, ул. Октябрьская, д. 35; тел.: 8 (47145) 4-16-48, e-mail: shigri-opeca@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 30,
        "col3": "\nКомитет социальной защиты населения города Курска \n",
        "col4": "305007, г. Курск, ул. Пигорева, д. 2/17, тел.: (4712)70-70-35, e-mail: komsoz@kurskadmin.ru (почта отдела komitetsoc_det@mail.ru)"
    },
    {
        "col1": "Курская область\n",
        "col2": 31,
        "col3": "Администрация города Железногорска \n",
        "col4": "307170 Курская область, г. Железногорск, ул. Ленина, д. 52, тел. 8(47148)7-64-37, e-mail: zhel.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 32,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района Курской области \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, тел.: 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 33,
        "col3": "Администрация города Льгова Курской области\n\n",
        "col4": "307750 Курская область город Льгов, ул. Красная площадь, д.13\nТел. 8(74140)2-30-13, e-mail: shigri-opeca@mail.ru, admlov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 34,
        "col3": "Отдел по опеке и попечительству администрации города Щигры",
        "col4": " 306530, Курская область, г. Щигры, ул. Спортивная,1 8(47145)4-97-27, opeka-shigry@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 1,
        "col3": "администрация Бокситогорского муниципального района Ленинградской области",
        "col4": " адрес: 187650, Ленинградская область, г. Бокситогорск, ул. Социалистическая, д.9, телефон: 8(81366) 211-31,электронная почта: adm@boksitogorsk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 2,
        "col3": "комитет образования администрации Волосовского муниципального района Ленинградской области.",
        "col4": " Адрес: 188410, Ленинградская область, г. Волосово, ул. Краснофлотская, дом 6, телефон: 8(81373)22114, электронная почта:  volosovoopeka@inbox.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 3,
        "col3": "управление по опеке и попечительству администрации Волховского муниципального района Ленинградской области",
        "col4": " адрес: 187406 Ленинградская область, г. Волхов, пр. Державина, д. 60, телефон: 8(81363)72705, электронная почта: opekavolhov@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 4,
        "col3": "комитет по опеке и попечительству администрации Всеволожского муниципального района Ленинградской области",
        "col4": "адрес: 188640, Ленинградская область, г. Всеволожск, Всеволожский проспект д. 12, телефон:  8(81370)20020, 8(81370)31741, электронная почта: vsevopeka@mail.ru, people@vsevreg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 5,
        "col3": "управление опеки и попечительства администрации  муниципального образования \"Выборгский район\" Ленинградской области",
        "col4": "адрес:188800, Ленинградская область, \nг. Выборг, ул. Красноармейская д. 16, телефон: 8(81378)26353, \n 8(81378)20078, электронная почта: opeka.vbg@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 6,
        "col3": "администрация Гатчинского муниципального округа Ленинградской области",
        "col4": " адрес: 188300, Ленинградская область, г. Гатчина, ул. Карла Маркса, д.44, телефон: 8(81371)93100; 8 (81371) 95309, электронная почта: info@gmolo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации МО Кингисеппский муниципальный район» Ленинградская область",
        "col4": " адрес: 188480, Ленинградская область, г. Кингисепп, проспект Карла Маркса, д.2а, телефон: 8(81375)4-88-40, электронная почта: adm@kingisepplo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 8,
        "col3": "администрация Киришского муниципального района Ленинградской области",
        "col4": " адрес: 187110, Ленинградская область, г. Кириши, улица Советская, дом 20, телефон: 8(81368) 609-00, электронная почта: admkir@admkir.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 9,
        "col3": "администрация Кировского муниципального района Ленинградской области",
        "col4": " адрес: 187342, Ленинградская область, Кировский район, г. Кировск, ул. Новая, д. 1, телефон: 8(813-62) 28-196, электронная почта: adm_kmr@kirovsk-reg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 10,
        "col3": "сектор опеки и попечительства отдела образования Администрации Лодейнопольского муниципального района Ленинградской области",
        "col4": "адрес: 187700, 187700, Ленинградская область, г. Лодейное Поле, пр. Ленина, д.16, телефон: 8(81364)39511, 8(81364)39822, lodpole-opeka@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 11,
        "col3": "отдел по опеке и попечительству администрации Ломоносовского муниципального района Ленинградской области",
        "col4": " адрес: 198412, г. Ломоносов, ул. Владимирская, дом 19/15, телефон: 8(81242)30030, электронная почта: lmn-reg@lomonosovlo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 12,
        "col3": "администрация Лужского муниципального района Ленинградской области",
        "col4": " адрес: 188230, Ленинградская область, г. Луга, пр. Кирова, д. 73, телефон: 8(81372) 2-23-06, Электронная почта: admin@adm.luga.ru , admluga@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 13,
        "col3": "администрация Подпорожского муниципального района Ленинградской области",
        "col4": " адрес: 187780, Ленинградская область, г. Подпорожье, пр. Ленина, д. 3, телефон: 8(81365)59003, электронная почта: reception@podadm.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 14,
        "col3": "администрация Приозерского муниципального района Ленинградской области",
        "col4": " адрес: 188760, Ленинградская область,  г. Приозерск, ул. Ленина, д.10, телефон: 8(81379)36973, Электронная почта: info@admpriozersk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 15,
        "col3": "администрация Сланцевского муниципального района Ленинградской области",
        "col4": " адрес: 188560, Ленинградская область, г. Сланцы, пер. Почтовый, д. 3, телефон: 8(81374)23273, Электронная почта: slanmo@slanmo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 16,
        "col3": "администрация Сосновоборского городского округа Ленинградской области",
        "col4": " адрес: 188540, Ленинградская область, г. Сосновый Бор, ул. Ленинградская, 46, телефон: 8(81369)26222, электронная почта: admsb@sbor.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 17,
        "col3": "комитет социальной защиты населения администрации Тихвинского района  (отдел опеки и попечительства)",
        "col4": " адрес: 187553, Ленинградская область, город Тихвин, 1 микрорайон, дом 2, телефон:  8(81367) 56250, 8(81367) 70294, электронная почта opeka.tikhvin@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 18,
        "col3": "комитет образования администрации Тосненского муниципального района Ленинградской области",
        "col4": "адрес: 187000, Ленинградская область,г. Тосно, ул. Советская, д. 10а, телефон: 8(81361)28305, электронная почта Opeka1960@yandex.ru"
    },
    {
        "col1": "Липецкая область",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства Липецкой области",
        "col4": "398001, г. Липецк,         \nул. Советская, д. 3\ne-mail: mhcs@admlr.lipetsk.ru\n +7(4742) 22-20-61"
    },
    {
        "col1": "Луганская Народная Республика",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Магаданская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства департамента образования мэрии города Магадана",
        "col4": "г. Магадан, \nул. Горького, д. 19А\nopeka@magadangorod.ru\n62-06-62\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства Администрации Ольского муниципального округа Магаданской области",
        "col4": "685910, Магаданская область. Ольский район, поселок Ола, площадь Ленина дом № 4, opeka@ola49.ru, olaopeka@mail.ru , 8 (41341)25618"
    },
    {
        "col1": "Магаданская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства\nадминистрации\nОмсукчанского\nмуниципального округа",
        "col4": "6868410, п. Омсукчан, ул. Ленина, д. 19, info@omsukchan-adm.ru, 8(41346)91681"
    },
    {
        "col1": "Магаданская область",
        "col2": 4,
        "col3": "Администрация Среднеканского муниципального округа Магаданской области (Управление экономики и развития)",
        "col4": "Магаданская область, Среднеканский район, поселок Сеймчан, ул. Ленина, дом 9. amosred@mail.ru    8-413-47-9-42-59 (приемная), 8-413-47-9-44-46 (экономика)"
    },
    {
        "col1": "Магаданская область",
        "col2": 5,
        "col3": "Отдел  опеки и попечительства управления образования\nадминистрации\nСеверо-Эвенского муниципального округа",
        "col4": "686430, Магаданская область, п. Эвенск, ул. Курилова, д. 16, 8 (413-48) 2-23-74, uoobrazevensk@mail.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 6,
        "col3": "Администрация Сусуманского муниципального округа Магаданской области",
        "col4": "686314, Магаданская область. Сусуманский район, г. Сусуман, д. 17 ssmadm@mail.ru, 8(41345)22025"
    },
    {
        "col1": "Магаданская область",
        "col2": 7,
        "col3": "Управление образования администрации Тенькинского муниципального округа Магаданской области",
        "col4": "ул. Мира, д. 11, п. Усть-Омчуг, Тенькинский район, Магаданская область, 686050,\nтел. 8(41344)3-04-44, \n E-mail: Obrazovanietenka@49gov.ru\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 8,
        "col3": "Администрации Хасынского муниципального округа Магаданской области \nОтдел опеки и попечительства\n",
        "col4": "686110 Магаданская область, Хасынский район, п. Палатка, ул. Ленина, д.76 -mail: priem_hasyn@49gov.ru\n84134292774\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства   управления образования администрации Ягоднинского муниципального округа Магаданской области",
        "col4": "686230, поселок Ягодное, Ягоднинский район, Магаданская область, ул. Школьная, д. 9 \nе-mail: yagodnoeopeka@mail.ru \nтел. (8 413 43) 2-24-57\n"
    },
    {
        "col1": "Москва",
        "col2": 1,
        "col3": "находится на стадии согласования",
        "col4": "-"
    },
    {
        "col1": "Московская область",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Мурманская область",
        "col2": 1,
        "col3": "Администрация города Апатиты",
        "col4": "пл. Ленина., д. 1, г. Апатиты, Мурманская область, 184209, (8 81555)  6 02 36, opeka-deti@apatity-city.ru  "
    },
    {
        "col1": "Мурманская область",
        "col2": 2,
        "col3": "Администрация ЗАТО Александровск",
        "col4": "ул. Флотская, д. 9, ЗАТО Александровск, Мурманская область, 184682, (881539) 5 23 22, ZarenkovaLV@zato-a.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 3,
        "col3": "Администрация ЗАТО пос. Видяево",
        "col4": "ул. Центральная, д. 8, ЗАТО Видяево, Мурманская область, 184372, (881553) 5 66 74, opeka@zatovid.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 4,
        "col3": "Администрация ЗАТО город Заозерск",
        "col4": "ул. Генерала Чумаченко, д. 4,                   г. Заозерск, Мурманская область, 184310,\nopekazaozersk@mail.ru,\n8(81556) 3 15 12"
    },
    {
        "col1": "Мурманская область",
        "col2": 5,
        "col3": "Администрация Кандалакшского района",
        "col4": "ул. Первомайская, д. 34, г. Кандалакша, Мурманская область, 184040, (8 815 33) 9 24 00, 9 30 11 \nopeka-kanda@yandex.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 6,
        "col3": "Администрация города Кировска",
        "col4": "пр. Ленина, д. 16, г. Кировск, Мурманская область, 184250, (881531) 5 52 75, gnevysheva.nv@gov.kirovsk.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 7,
        "col3": "Администрация Ковдорского муниципального округа",
        "col4": "пл. Ленина., д. 1, г. Ковдор, Мурманская область, 184141, (8 81535) 5 02 42 доб. 227, i.iosifova@kovadm.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 8,
        "col3": "Администрация Кольского района",
        "col4": "Советский пр., д. 50, г. Кола, Мурманская область, 184381, (81553) 3 61 82, opeka_deti@akolr.gov-murman.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 9,
        "col3": "Администрация Ловозерского района",
        "col4": "ул. Советская, д. 10, с. Ловозеро, Мурманская область, 184592, (8 815 38) 40 111, opeca-lov@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 10,
        "col3": "Администрация города Мончегорска",
        "col4": "ул. Железнодорожников, д. 6, г. Мончегорск, Мурманская область, 184511,  (881536) 3 18 90, opd@edumonch.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 11,
        "col3": "Администрация города Мурманска",
        "col4": "ул. Комсомольская, д. 10, г. Мурманск, 183036, (8 8152)  45-06-24, 45-61-71, kio@citymurmansk.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 12,
        "col3": "Администрация муниципального округа город Оленегорск с подведомственной территорией",
        "col4": "ул. Мира, д. 38, г. Оленегорск, Мурманская область, 184530, (8 815 52) 51 000, opeka-olen@admol.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 13,
        "col3": "Администрации ЗАТО г. Островной",
        "col4": "пл. Жертв Интервенции, д. 1,                     г. Островной, Мурманская обл. 184640,  (881558) 5 00 12, admzato@zato-ostrov.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 14,
        "col3": "Администрация Печенгского муниципального округа",
        "col4": "ул. Победы, д. 1, п.г.т. Никель, Печенгский район, Мурманская область, 184421, \nтелефон (81554) 5-19-40, 5-05-72;  obrpechenga@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 15,
        "col3": "Администрация города Полярные Зори",
        "col4": "ул. Ломоносова, д. 4, г. Полярные Зори, Мурманская область, 184230, (881532) 7 12 24, opeka@pz-city.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 16,
        "col3": "Администрация ЗАТО г. Североморск ",
        "col4": "пр. Советский, д. 50, г. Кола, Мурманская область, 184381, (8 81553)  3 61 82, 3 34 29, opeka-deti@akolr.gov-murman.ru   "
    },
    {
        "col1": "Мурманская область",
        "col2": 17,
        "col3": "Администрация Терского района",
        "col4": "пгт. Умба Терского района, Мурманская область, 184703, (8 81555)  6-71-12, ter.obrazovanie@mail.ru "
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
        "col4": "ул. Смидовича, д.25, г. Нарьян-Мар, Ненецкий автономный округ, 166000; e-mail: m, тел. 8(81853) 2-13-68edsoc@adm-nao.ru"
    },
    {
        "col1": "Нижегородская область",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального округа",
        "col4": " ул. Ленина, д.28, р.п. Ардатов, 607130 arono@mts-nn.ru        8(83179)5-02-56        \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 2,
        "col3": "Администрация Балахнинского муниципального округа",
        "col4": " ул. Лесопильная, дом 24, г. Балахна, 606403, opeka@adm.bal.nnov.ru 8(83144)68299 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 3,
        "col3": "Администрация Богородского муниципального округа ",
        "col4": " ул. Ленина, д. 206, г. Богородск, 607600, opekabog@yandex.ru 8(83170)23871        "
    },
    {
        "col1": "Нижегородская область",
        "col2": 4,
        "col3": "Администрация Большеболдинского муниципального округа ",
        "col4": " ул. Пушкинская, д.144, с. Б.Болдино, 607940, opekaboldino@yandex.ru 8(83138)2-26-21"
    },
    {
        "col1": "Нижегородская область",
        "col2": 5,
        "col3": "Администрация Большемурашкинского муниципального округа",
        "col4": "ул. Свободы, д. 86, р.п. Б.Мурашкино, 606360 detimolkdn@mail.ru 8(83167)5-11-32"
    },
    {
        "col1": "Нижегородская область",
        "col2": 6,
        "col3": "Администрация городского округа г. Бор",
        "col4": " ул. Ленина, д. 97, г. Бор, Нижегородская область, 606440, opekabor@yandex.ru 8(831)5991478"
    },
    {
        "col1": "Нижегородская область",
        "col2": 7,
        "col3": "Администрация Бутурлинского муниципального округа",
        "col4": " ул. Ленина, д. 106, п.г.т. Бутурлино, 607440, opeka-buturlino@mail.ru 8(831)7251813"
    },
    {
        "col1": "Нижегородская область",
        "col2": 8,
        "col3": "Администрация\nВадского муниципального округа",
        "col4": " ул. 1 Мая, д. 41, с. Вад, 606380, opeka-vad@yandex.ru 8(831)4043042\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 9,
        "col3": "Администрация Варнавинского муниципального округа ",
        "col4": "пл. Советская, д.2, р.п. Варнавино, 606760, ruo_varnavino@mail.ru        8 (831) 583 52 44      "
    },
    {
        "col1": "Нижегородская область",
        "col2": 10,
        "col3": "Администрации Вачского муниципального округа",
        "col4": " ул.Советская, дом 26б, р.п. Вача, 606150, vachaopeka52@yandex.ru 8(831)7361977"
    },
    {
        "col1": "Нижегородская область",
        "col2": 11,
        "col3": "Администрация Ветлужского муниципального округа ",
        "col4": " ул.Ленина, дом 42, г.Ветлуга, 606860, vetl-opeka@mail.ru 8(83150)235-04"
    },
    {
        "col1": "Нижегородская область",
        "col2": 12,
        "col3": "Администрация Вознесенского муниципального округа",
        "col4": " ул. Советская, д. 33, р.п. Вознесенское, 607340, paramonova-opeka@yandex.ru 8(83178)631-88 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 13,
        "col3": "Администрация Володарского муниципального округа",
        "col4": " ул. Клубная, дом 4, г. Володарск, 606070, opeka-volodarsk@mail.ru 8(831)3649202\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 14,
        "col3": "Администрация городского округа Воротынский",
        "col4": " пл. Советская, дом 6, р.п. Воротынец, 606260 opeka@adm.vrt.nnov.ru 8(831)6420151"
    },
    {
        "col1": "Нижегородская область",
        "col2": 15,
        "col3": "Администрация Воскресенского муниципального округа",
        "col4": " ул. Ленина, д.105, р.п. Воскресенское, 606730 opeka-vosk@yandex.ru 8(83163)922-85\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 16,
        "col3": "Администрация городского округа г. Выкса ",
        "col4": "г.Выкса, Красная площадь, дом 1, 607060, uo@vyksa-okrug.ru 8(831)7734244 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 17,
        "col3": "Администрация Гагинского муниципального округа ",
        "col4": "ул. Ленина, д. 44, с. Гагино, 607870, opekagag@yandex.ru 8(831)9521472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 18,
        "col3": "Администрация Городецкого муниципального округа",
        "col4": "пл. Пролетарская, д. 30, г. Городец, 606500, gorodec_opeka@mail.ru 8(8316) 9-1766  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 19,
        "col3": "Администрация Дальнеконстантиновского муниципального округа",
        "col4": "ул. Советская, д. 103, р.п. Дальнее Константиново, 606310, opekadk@yandex.ru \n8(831)6852218"
    },
    {
        "col1": "Нижегородская область",
        "col2": 20,
        "col3": "Администрация Дивеевского муниципального округа ",
        "col4": "ул.Октябрьская, д.10, с.Дивеево, 607320, roo.adm.diveevo@mail.ru 8(83134)45460\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 21,
        "col3": "Администрация Княгининского муниципального округа",
        "col4": "ул.Свободы, д.45, г. Княгинино, 606340, rukavisnikova.tatyana@mail.ru 8(831)6641046\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 22,
        "col3": "Администрация Ковернинского муниципального округа",
        "col4": "ул. Карла Маркса, д. 4, р.п. Ковернино, 606570, roo@adm.kvr.nnov.ru 8(831)5722568\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 23,
        "col3": "Администрация Краснобаковского муниципального округа",
        "col4": " ул. Свободы, д. 104, р.п. Красные Баки, 606710, opeka-krbaki@mailru 8(831)5622531\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 24,
        "col3": "Администрация Краснооктябрьского муниципального округа",
        "col4": "ул. Кооперативная, дом 39, село Уразовка, 607530, obkroktyabr@mail.ru  8(831) 265 38 13 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 25,
        "col3": "Администрация Кстовского района",
        "col4": "пл. Ленина, дом 4, г. Кстово, 607650, opeka.kctadm@mail.ru 8(831)4539214\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Кулебаки",
        "col4": "ул. Воровского, д. 43а, г. Кулебаки, 607010, opeka_kulebaki@bk.ru 8(831)7653062\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 27,
        "col3": "Администрация Лукояновского муниципального округа",
        "col4": " ул. Коммуны, д. 38, г. Лукоянов, Нижегородской области, 607800, luk-opeka@yandex.ru 8(831)9642153"
    },
    {
        "col1": "Нижегородская область",
        "col2": 28,
        "col3": "Администрация Лысковского муниципального округа ",
        "col4": "ул. Ленина, д. 23, г. Лысково, 606210, lysopeka@mail.ru 8(831)495-38-83   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 29,
        "col3": "Администрация городского округа Навашинский",
        "col4": "  ул. 1 Мая, д. 6, г. Навашино, 607102, opekanavashino@yandex.ru 8(831)7557367\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 30,
        "col3": "Администрация Павловского муниципального округа ",
        "col4": "ул.Ленина, д.27, г.Павлово, 606100, detiopekapavlovo@mail.ru 8(831)7121595\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 31,
        "col3": "Администрация городского округа г. Первомайск",
        "col4": "ул. Ульянова, дом 2, г. Первомайск, 607760, perv_rono@mail.ru 8(831)3921472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 32,
        "col3": "Администрация городского округа Перевозский",
        "col4": "пр. Советский, д. 8, г.Перевоз, 607400, opekapvz@yandex.ru 8(831)485-28-41   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 33,
        "col3": "Администрация Пильнинского муниципального округа ",
        "col4": "ул. Урицкого, д. 16, р.п. Пильна, 607490, uomps@mail.ru 8(831)9251539  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 34,
        "col3": "Администрации Починковского муниципального округа",
        "col4": " \nпл.Ленина, д.9, с.Починки, 607910, karpaeva.lena@mail.ru 8(831)9750043\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 35,
        "col3": "Администрация городского округа Семеновский",
        "col4": "\nул.1-е Мая, д.1, г. Семенов, 606650, kdn@semenov.nnov.ru 8(83162)5-27-97\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 36,
        "col3": "Администрация\nСергачского муниципального округа",
        "col4": " \nул. Советская, дом 30, г. Сергач, 607510, opeka-grishina@mail.ru 8(831)9152911\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 37,
        "col3": "Администрация Сеченовского муниципального округа ",
        "col4": "\nпл. Советская, д. 4, с. Сеченово, 607580,  komolova-anna@mail.ru 8(831)93-5-24-08\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 38,
        "col3": "Администрация городского округа Сокольский",
        "col4": "\nул. Набережная, д. 9, п. Сокольское, 606670, detstvo.sokolskoe@mail.ru 8(831)372-07-73 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 39,
        "col3": "Администрация Сосновского муниципального округа",
        "col4": " \nул. Ленина, д. 27, р.п. Сосновское, д.27, 606170, opekasosn@yandex.ru 8(83174) 2-63-27\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 40,
        "col3": "Администрация Спасского муниципального округа",
        "col4": "\nпл. Революции, д. 71, с. Спасское, 606280, spasopeka@yandex.ru 8(831)652-60-15\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 41,
        "col3": "Администрация Тонкинского муниципального округа ",
        "col4": " \nул. Ленина, д.1, р.п. Тонкино, 606970, roo_tonkino2002@mail.ru   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 42,
        "col3": "Администрация Тоншаевского муниципального округа",
        "col4": " \nул. Свердлова, дом 2а, р.п. Тоншаево, 606950, ton_opeka@rambler.ru 8(831)512-22-95\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 43,
        "col3": "Администрация Уренского муниципального округа",
        "col4": " \nул. Советская, д. 26, г. Урень, 606800, uren.ruo@mail.ru    8(831)542-11-63\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 44,
        "col3": "Администрации городского округа г. Чкаловск",
        "col4": "\nпл. Комсомольская, д.2,    г. Чкаловск, 606540,  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 45,
        "col3": "Администрация\nШарангского муниципального округа",
        "col4": " \nул. Свободы, дом 2, пгт. Шаранга, 606840, roo_shar2003@mail.ru 8(831)5521763           \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 46,
        "col3": "Администрация\nШатковского муниципального округа",
        "col4": " \nул. Федеративная, д.4, р.п. Шатки, 607700, opeka0803@mail.ru 8(831)904-31-34\n \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 47,
        "col3": "Администрация городского округа г. Шахунья Нижегородской области ",
        "col4": "\nпл. Советская, д. 1, г. Шахунья, 606910, opekashah.52@mail.ru 8(831)5226655 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 48,
        "col3": "Администрация городского округа г. Арзамас",
        "col4": " \nул. Советская, дом 10, г. Арзамас 607220, arzopeka@rambler.ru 8(831)4775738 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 49,
        "col3": "Администрация г. Дзержинска",
        "col4": "пл. Ленина, д. 5, г. Дзержинск, 606032, opeka-dz@mail.ru 8(831)3397212\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 50,
        "col3": "Администрации г. Саров ",
        "col4": "ул. Гагарина, дом 6, г. Саров, 607188,nud@adm-sarov.ru 8(831)3099124\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 51,
        "col3": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)",
        "col4": "ул. Большая Покровская, д.15, г. Н.Новгород, 603005, muhanova@admgor.nnov.ru 8(831)435-69-83  \n"
    },
    {
        "col1": "Новгородская область",
        "col2": 1,
        "col3": "Отдел строительства, архитектуры и жилищно-коммунального хозяйства Администрации Батецкого муниципального округа",
        "col4": "Новгородская область, п.Батецкий, ул.Советская, д.39а, 175000; адрес электронной почты:gkh@batetsky.ru; 8(81661)22401 (доб.219)"
    },
    {
        "col1": "Новгородская область",
        "col2": 2,
        "col3": " Отдел по жилищным вопросам комитета жилищно-коммунального, дорожного хозяйства, строительства, транспорта и охраны окружающей среды Администрации Боровичского муниципального района",
        "col4": "Новгородская область, г.Боровичи, ул.Коммунарная, д.48, 174411; адрес электронной почты:komobr@okuladm.ru; 8(81664)91213"
    },
    {
        "col1": "Новгородская область",
        "col2": 3,
        "col3": "Комитет жилищно-коммунального и дорожного хозяйства Администрации  Валдайского муниципального района",
        "col4": " Новгородская область, г.Валдай, пр.Комсомольский, д.19/21, 175400, адрес электронной почты: admin@valdayadm.ru;  8(81666)22516"
    },
    {
        "col1": "Новгородская область",
        "col2": 4,
        "col3": "Администрация Волотовского муниципального округа",
        "col4": "Новгородская область, п.Волот, ул.Комсомольская, д.38, 175100; адрес электронной почты: adm.volot@mail.ru; 8(81662)61325"
    },
    {
        "col1": "Новгородская область",
        "col2": 5,
        "col3": "Администрация  Демянского муниципального округа",
        "col4": "Новгородская область, п.Демянск, ул.Ленина, д.7, 175310, адрес электронной почты:priemnaya@dem-admin.ru; 8(81651)44012 (доб.6400)"
    },
    {
        "col1": "Новгородская область",
        "col2": 6,
        "col3": "Комитет по имущественным и земельным отношениям Администрации Крестецкого муниципального округа",
        "col4": "Новгородская область, п.Крестцы, Советская площадь, д.1, 175460; адрес электронной почты: kumi@adm-krestcy.ru; 8(81659)54505"
    },
    {
        "col1": "Новгородская область",
        "col2": 7,
        "col3": "Администрация Любытинского муниципального района",
        "col4": "Новгородская область, п.Любытино, ул.Советов, д.29, 174760; адрес электронной почты: admin_lub@mail.ru ; 8(81668)62310 (доб.6619)"
    },
    {
        "col1": "Новгородская область",
        "col2": 8,
        "col3": "Администрация Маловишерского муниципального района",
        "col4": " Новгородская область, г.Малая Вишера, ул.Володарского, д.14,174260; адрес электронной почты: mv.otdeljkh@mail.ru; 8(81660)33548"
    },
    {
        "col1": "Новгородская область",
        "col2": 9,
        "col3": "Отдел ЖКХ, дорожного хозяйства и транспорта Администрации Маревского муниципального округа",
        "col4": "Новгородская область, с. Марёво, ул. Советов, д. 27, адрес электронной почты: jkh.marevo@yandex.ru; 8(81663)21365 (доб.6826)"
    },
    {
        "col1": "Новгородская область",
        "col2": 10,
        "col3": "Администрация Мошенского муниципального округа",
        "col4": "Новгородская область, Мошенской муниципальный округ, с. Мошенское, ул.Советская, д.5; адрес электронной почты:mosh-adm@yandex.ru;8(81653)61827"
    },
    {
        "col1": "Новгородская область",
        "col2": 11,
        "col3": "Комитет жилищно-коммунального  хозяйства и дорожной деятельности  Администрации Окуловского муниципального района",
        "col4": "Новгородская область, г.Окуловка, ул.Кирова, д.6, 174350; адрес электронной почты: 22kabokuladm@mail.ru; 8(81657)22058; 8(81657)21331"
    },
    {
        "col1": "Новгородская область",
        "col2": 12,
        "col3": "Администрация Новгородского муниципального района (комитет по управлению муниципальным имуществом Администрации Новгородского муниципального района)",
        "col4": "г.Великий Новгород, ул.Большая Московская, д.60,173004, адрес электронной почты:AdmNovRay@yandex.ru, 8(8162)943612"
    },
    {
        "col1": "Новгородская область",
        "col2": 13,
        "col3": "Администрация Парфинского муниципального района",
        "col4": "Новгородская область, Парфинский муниципальный  район, п.Парфино, ул.К.Маркса,д.60, 175130; адрес электронной почты:parvrono@yandex.ru; 8(81650)6-30-42"
    },
    {
        "col1": "Новгородская область",
        "col2": 14,
        "col3": "Администрация Пестовского муниципального района",
        "col4": "Новгородская область, Пестовский муниципальный округ, г.Пестово, ул.Советская, д.10, 174510; адрес электронной почты: opeka.pestovo@yandex.ru; 8(81669)51634"
    },
    {
        "col1": "Новгородская область",
        "col2": 15,
        "col3": "Администрация Поддорского муниципального района",
        "col4": "Новгородская область, Поддорский муниципальный район, с.Поддорье, ул.Полевая, д.17, 175260; адрес электронной почты:obr@admpoddore.ru, 8(81658)71692"
    },
    {
        "col1": "Новгородская область",
        "col2": 16,
        "col3": "Администрация Солецкого муниципального района",
        "col4": "Новгородская область, г.Сольцы, пл.Победы, д.3, 175040; адрес электронной почты: soleco@adminsoltcy.ru; 8(81655)30727"
    },
    {
        "col1": "Новгородская область",
        "col2": 17,
        "col3": "Комитет образования Администрации Старорусского муниципального района",
        "col4": "Новгородская область, г.Старая Русса, ул.Санкт-Петербургская, д.1/1,175202; адрес электронной почты: komitet.russa@mail.ru; 8(81652)22343"
    },
    {
        "col1": "Новгородская область",
        "col2": 18,
        "col3": "Администрация Хвойнинского муниципального округа",
        "col4": "Новгородская область, Хвойнинский муниципальный округ, п.Хвойная, ул.Советская, д.4, 174580; адрес электронной почты: ko5317@yandex.ru, 8(81667)50395"
    },
    {
        "col1": "Новгородская область",
        "col2": 19,
        "col3": "Отдел имущественных отношений и земельных вопросов Администрации Холмского муниципального района",
        "col4": "Новгородская область, Холмский муниципальный округ, г.Холм, пл.Победы, д.2, 175270; адрес электронной почты:adminholm@mail.ru, 8(81654)59697"
    },
    {
        "col1": "Новгородская область",
        "col2": 20,
        "col3": "Администрация Чудовского муниципального района ",
        "col4": "Новгородская область, г.Чудово, ул.Некрасова, д.24а, 174210, адрес электронной почты: komobr@adminchudovo.ru, 8(81665)54858"
    },
    {
        "col1": "Новгородская область",
        "col2": 21,
        "col3": "Администрация Шимского муниципального района",
        "col4": "Новгородская область, Шимский муниципальный район, п.Шимск, ул.Новгородская, д.21,174150; адрес электронной почты:isveshenie@mail.ru; 8(81656)54636"
    },
    {
        "col1": "Новгородская область",
        "col2": 22,
        "col3": "Администрация Великого Новгорода ( управление по жилищным вопросам и социальной поддержке граждан Администрации Великого Новгорода)",
        "col4": "Новгородская область, г.Великий Новгород, ул.Стратилатовская,д.3; адрес электронной почты: shse@adm.nov.ru, 8(8162)778239"
    },
    {
        "col1": "Новосибирская область",
        "col2": 1,
        "col3": "администрация Баганского района Новосибирской области ",
        "col4": "Новосибирская область, Баганский район, с. Баган ул.М. Горького д.28                                   8-383-53-22-383"
    },
    {
        "col1": "Новосибирская область",
        "col2": 2,
        "col3": "администрация Барабинского района Новосибирской области",
        "col4": "632334, Новосибирская область, г. Барабинск, ул. Ульяновская, 66; эл. почта: glv_admbar@nso.ru; тел.:  8-383-61-258-08"
    },
    {
        "col1": "Новосибирская область",
        "col2": 3,
        "col3": "администрация Болотнинского района Новосибирской области",
        "col4": "633340, Новосибирская область, Болотнинский район, город Болотное, улица Советская, 9, тел. 8-383-49-22-730, 8-383-49-22-136, bolotnoe-adm@yandex.ru, maer_en@nso.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 4,
        "col3": "Администрация Венгеровского района Новосибирской области",
        "col4": "632241, Новосибирская область, Венгеровский район, с.Венгерово, ул.Ленина, д.68/       pradmvengr@yandex.ru/           (383 69) 21-592"
    },
    {
        "col1": "Новосибирская область",
        "col2": 5,
        "col3": "Администрация Доволенского района Новосибирской  области",
        "col4": "8-383-54-20-508, opeka-dovol@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 6,
        "col3": "администрация Здвинского района Новосибирской области",
        "col4": "632951, Новосибирская область, Здвинский район, с. Здвинск, ул. Мира, д.13, admn-zdv@yandex.ru,    8 383 63 21 278"
    },
    {
        "col1": "Новосибирская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Искитимского района Новосибимрской области",
        "col4": "633209, Новосибирская область, г. Искитим, ул. Пушкина, д. 57а, эл. почта : opeka_29@mail.ru? тел. 8 (38343) 4-22-60"
    },
    {
        "col1": "Новосибирская область",
        "col2": 8,
        "col3": "Администрация Карасукского муниципального округа Новосибирской области",
        "col4": "632868, НСО, г. Карасук, ул. Октябрьская, дом 39, E-mail: radm-karasuk@nso.ru. Тел. 8(383)55 33-135, 8(383)55 32-256"
    },
    {
        "col1": "Новосибирская область",
        "col2": 9,
        "col3": "администрация Каргатского района Новосибирской области",
        "col4": "632402 Новосибирская обьласть, Каргатский район,город Каргат, улица Советская, дом 122 тел.83836523326, электронная почта - anyusheva_mv@nso.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 10,
        "col3": "Администрация Колыванского района Новосибирской области ",
        "col4": "633161, Новосибирская область, Колыванский район, р.п. Колывань, ул. Ленина, д. 79, тел. 8 383 52 51 284,        эл. почта koluvan-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 11,
        "col3": "администрация Коченевского районаНовосибирской области",
        "col4": "632640, Новосибирская область, р.п. Коченево,          ул. Октябрьская, 43/kochadm@nso.ru/            8(383) 5125406"
    },
    {
        "col1": "Новосибирская область",
        "col2": 12,
        "col3": "Администрация Кочковского района Новосибирской области",
        "col4": "632491, Новосибирская область, Кочковский район, с. Кочки, ул. Революционная, 11, admkck@mail.ru, kochkiopeka@mail.ru, 8(383)56-22-300"
    },
    {
        "col1": "Новосибирская область",
        "col2": 13,
        "col3": "администрация Краснозерского районаНовосибирской области ",
        "col4": "Новосибирская область, р.п.Краснозерское, ул.Чкалова 5.632902. 83835742398"
    },
    {
        "col1": "Новосибирская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Куйбышевского муниципального района Новосибирской области",
        "col4": "632380,г. Куйбышев, ул. Куйбышева, 12             Тел.8(383 62)51-462              e-mai:      kainsk_opeka@mai.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 15,
        "col3": "Администрация Купинского района Новосибирской области",
        "col4": "632735, Новосибирская область, Купинский район, г.Купино, ул. Железнодорожная, 42-а ( отдел опеки и попечительства) Тел: 8-383-58-20-997; opekakup@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства  администрации Кыштовского района Новосибирской области",
        "col4": "632270 ,Новосибирская область,Кыштовский район,с.Кыштовка,ул.Ленина,д.38\\opeka-kyshtovka@yandex.ru\\83837121 174"
    },
    {
        "col1": "Новосибирская область",
        "col2": 17,
        "col3": "администрация Маслянинского муниципального округа Новосибирской области",
        "col4": "633564, Новосибирская область, Маслянинский район, р.п. Маслянино, улица Коммунистическая, дом 1а,  8-383-47-21-418   admmsl@yandex.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 18,
        "col3": "Администрация Мошковского района Новосибирской области",
        "col4": "633131, Новосибирская область, Мошковский район, р.п. Мошково, ул. Советская д. 9                                                                тел. 838321266, электронная почта: oopadm@rambler.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 19,
        "col3": "Администрация Новосибирского района Новосибирской области",
        "col4": "г.Новосибирск, ул.Фабричная, д.55, 6 этаж, оф.602, 20opeka13@mail.ru, 373-45-87"
    },
    {
        "col1": "Новосибирская область",
        "col2": 20,
        "col3": "Администрация Ордынского района Новосибирской области",
        "col4": "633261, Новосибирская область, Ордынский район, р.п. Ордынское, пр. Революции, 17 /adm-ord@mail.ru/8-383-59-23-309"
    },
    {
        "col1": "Новосибирская область",
        "col2": 21,
        "col3": "Администрация Северного района Новосибирской области",
        "col4": "632080, Новосибирская обл., Северный р-н, с. Северное, ул Ленина, д.14 тел: 8(38360)21818 sevadmn@yandex.ru  "
    },
    {
        "col1": "Новосибирская область",
        "col2": 22,
        "col3": "администрация Сузунского районаНовосибирской области",
        "col4": "Новосибирская область, Сузунский район, р.п. Сузун, ул. Ленина, 51                           adm@suzunadm.ru              (38346)22550"
    },
    {
        "col1": "Новосибирская область",
        "col2": 23,
        "col3": "отдел опеки и попечительства администрации Татарского муниципального округа Новосибирской области",
        "col4": "632122, Новосибирская область, г. Татарск, ул. Ленина, 56, opekatat@mail.ru, 89383 64) 2-62-54"
    },
    {
        "col1": "Новосибирская область",
        "col2": 24,
        "col3": "администрация Тогучинского района Новосибирской области",
        "col4": "633456, Новосибирская область, г. Тогучин, ул. Садовая, д.9/bortv@nso.ru/8-383 40 24874"
    },
    {
        "col1": "Новосибирская область",
        "col2": 25,
        "col3": "администрация Убинского района Новосибирской области",
        "col4": "632520 Новосибирская область, Убинский район, с. Убинское, ул. Ленина, 23 тел. 8 (38366) 21-527                            8 (38366) 21-131  ubinadm@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 26,
        "col3": "администрация Усть-Таркского района Новосибирской области",
        "col4": "632160, Новосибирская область, Усть-Таркский район, с. Усть-Тарка, ул. Иванова, 7;   ustopeka@nso.ru/83837223574 (ООиП)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 27,
        "col3": "Администрация Чановского района Новосибирской области",
        "col4": "632201, НСО,р.п.Чаны, ул.Советская, д.118\nт.21-185, факс 21-657\nchany-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 28,
        "col3": "Администрация Черепановского района Новосибирской области",
        "col4": "633520, Новосибирская область, Черепановский район,г.Черепаново, ул.Партизанская, 12,  Email:orgotd@nso.ru,       т.8(383 45)21368,                      8(383 45)22206,                          8(383 45)24538"
    },
    {
        "col1": "Новосибирская область",
        "col2": 29,
        "col3": "Администрация Чистоозерного района \nНовосибирской области ",
        "col4": "632720, Новосибирская область,\nр.п. Чистоозерное, ул. Покрышкина, 11\nopekachst@yandex.ru, i.krasovsckaja2013@yandex.ru\n8 383 68 91 735, 8 383 68 91 331   "
    },
    {
        "col1": "Новосибирская область",
        "col2": 30,
        "col3": "администрация Чулымского района",
        "col4": "632551,Новосибирская область, Чулымский район, г. Чулым, ул. Чулымская, 43 (chladm@nso.ru. Тел.83835021656)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 31,
        "col3": "Админитсрация города Бердска",
        "col4": "633010, Новосибирская область, г.Бердск, ул. М.Горького, 7 oopberdsk@nso.ru  8(38341)29297"
    },
    {
        "col1": "Новосибирская область",
        "col2": 32,
        "col3": "Администрация города Искитима Новосибирской области",
        "col4": " \n633209, Новосибирская область, город Искитим, ул.Пушкина, 51.\npri_iskadm@mail.ru\n83834324566\n"
    },
    {
        "col1": "Новосибирская область",
        "col2": 33,
        "col3": "Администрация рабочего поселка Кольцово",
        "col4": "рабочий поселок Кольцово, проспект Никольский, \n здание 1, офис 400\nНовосибирская область, 630559\nтелефон: 336-15-93, \nE-mail: ooip@kolcovo.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 34,
        "col3": "Администрация города Оби Новосибирской области",
        "col4": "633102, Новосибирская область, ул. Авиационная, д.12, т. 8(38373)56110, email: adm_nso@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 35,
        "col3": "мэрия города Новосибирска ",
        "col4": "г. Новосибирск, Красный проспект, 34 каб. 443 . Тел. 227-45-02, 227-45-44. Abatashyrova@admnsk.ru; Nfomina@admnsk.ru"
    },
    {
        "col1": "Омская область\n",
        "col2": 1,
        "col3": "Министерство имущественных отношений Омской области",
        "col4": "Орджоникидзе ул., д. 5,\nг. Омск, 644043,\nе-mail: post@mio.omskportal.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 1,
        "col3": "Министерство образования Оренбургской области",
        "col4": "460000, г. Оренбург, ул. Володарского, дом 11, \n8 (3532) 500-840 (доб.762), unt@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 2,
        "col3": "Абдулинский муниципальный округ:",
        "col4": " Оренбургская область, г. Абдулино, ул. Коммунистическая, 276, 8 (35355) 2-52-47, ab@mail.orb.ru. "
    },
    {
        "col1": "Оренбургская область",
        "col2": 3,
        "col3": "город Бугуруслан: ",
        "col4": "Оренбургская обл., г. Бугуруслан, ул. Ленинградская, 47, 8 (35352) 3-33-69, bg@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 4,
        "col3": "город Бузулук: ",
        "col4": "Оренбургская область, г. Бузулук, ул.Ленина, 10, 8 (35342) 3-51-35, adm@buzuluk-town.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 5,
        "col3": " Гайский муниципальный округ: ",
        "col4": "Оренбургская область, г. Гай, ул. Ленина, 41, 8 (35362) 4-20-33. goradm@esoo.ru, gy@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 6,
        "col3": " Кувандыкский муниципальный округ",
        "col4": " Оренбургская область, г. Кувандык, ул. Оренбургская, 20, 8 (35361) 2-37-13, 2-22-20, ku@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 7,
        "col3": " город Медногорск:",
        "col4": " Оренбургская область, г. Медногорск, ул. Советская, 37, 8 (35379) 3-26-86, mo@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 8,
        "col3": "город Новотроицк: ",
        "col4": "Оренбургская область, г. Новотроицк, ул. Советская, 80, 8 (35376) 2-01-01, adm-nvk@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 9,
        "col3": " город Оренбург: ",
        "col4": "Оренбургская область, г. Оренбург, ул.Советская, 60, 8 (3532) 98-70-10, general@admin.orenburg.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 10,
        "col3": "город Орск:",
        "col4": " Оренбургская область, г. Орск, проспект Ленина, 29, 8 (3537) 25-30-90, uprava@orsk-adm.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 11,
        "col3": "Соль-Илецкий муниципальный округ",
        "col4": ": Оренбургская область, г.Соль-Илецк, ул. Карла Маркса, 6, 8 (35336) 2-32-32, si@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 12,
        "col3": "Сорочинский муниципальный округ:",
        "col4": " г. Сорочинск, ул.Советская, 1, 8 (35346) 4-11-51, admsor@esoo.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 13,
        "col3": " Ясненский муниципальный округ: ",
        "col4": "Оренбургская область, г. Ясный, ул. Ленина, 17, 8 (35368) 2-13-00, ys@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 14,
        "col3": "Адамовский район:",
        "col4": " Оренбургская область, Адамовский район, п.Адамовка, ул. Советская, 81, 8 (35365) 2-13-38, ad@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 15,
        "col3": "Акбулакский район:",
        "col4": " Оренбургская область, п. Акбулак, ул. Комсомольская, 15, 8 (35335) 2-11-40, ak@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 16,
        "col3": "Александровский район: ",
        "col4": "Оренбургская область, р-н Александровский, с. Александровка, ул. Мичурина, 49, 8 (35359) 2-11-02, al@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 17,
        "col3": "Асекеевский район: ",
        "col4": "Оренбургская область, с. Асекеево, ул. Чапаева, 28, 8 (35351) 2-00-83,  as@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 18,
        "col3": "Беляевский район:",
        "col4": " Оренбургская область, Беляевский район, с.Беляевка, ул. Советская, 46Б, 8 (35334) 2-17-25, be@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 19,
        "col3": "Бугурусланский район: ",
        "col4": "Оренбургская область, г. Бугуруслан, ул.Московская, 52а, 8 (35352) 2-39-16, bu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 20,
        "col3": "Бузулукский район: ",
        "col4": "Оренбургская область, г. Бузулук, ул. Ленина, 10, 8 (35342) 7-42-00, bz@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 21,
        "col3": "Грачевский район:",
        "col4": " Оренбургская область, с. Грачёвка, ул.Майская, 22, 8 (35344) 2-10-60, ge@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 22,
        "col3": " Домбаровский район: ",
        "col4": "Оренбургская область, Домбаровский район, п. Домбаровский, ул. 40 лет Октября, 20, 8 (35367) 2-24-52, dm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 23,
        "col3": "Илекский район: ",
        "col4": "Оренбургская область, Илекский район, с. Илек, ул. Октябрьская, 23, 8 (35337) 2-14-64, il@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 24,
        "col3": " Кваркенский район: ",
        "col4": "Оренбургская область, Кваркенский район, с. Кваркено, ул. 1-я Целинная, 18, 8 (35364) 2-17-63, kv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 25,
        "col3": " Красногвардейский район: ",
        "col4": "Оренбургская область, Красногвардейский район, с.Плешаново, ул.Мира, 5, 8 (35345) 3-14-44, 3-00-45, ko@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 26,
        "col3": "Курманаевский район: ",
        "col4": "Оренбургская область, с. Курманаевка, пл. имени В. И.Ленина, 1, 8 (35341) 2-13-91, km@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 27,
        "col3": " Матвеевский район:",
        "col4": " Оренбургская обл., с. Матвеевка, ул. Комсомольская, 18, 8 (35356) 2-10-66, ma@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 28,
        "col3": "Новоорский район:",
        "col4": " Оренбургская  область, Новоорский район, п. Новоорск, ул. Рабочая, 1, 8 (35363) 7-70-01, 7-70-02, no@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 29,
        "col3": " Новосергиевский район: ",
        "col4": "Оренбургская область, п. Новосергиевка, ул. Краснопартизанская, 20, 8 (35339) 2-39-39, ns@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 30,
        "col3": " Октябрьский район: ",
        "col4": "Оренбургская область, с. Октябрьское, ул. Луначарского, 45, 8 (35330) 2-14-34, ok@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 31,
        "col3": " Оренбургский район:",
        "col4": " Оренбургская область, г. Оренбург, ул.Ст. Разина, 211, 8 (3532) 56-12-21, 77-28-91, or@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 32,
        "col3": " Первомайский район: ",
        "col4": "Оренбургская область, Первомайский район, п. Первомайский, ул. Советская, 33а, 8 (35348) 4-13-40, 4-13-42, pm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 33,
        "col3": "Переволоцкий район:",
        "col4": " Оренбургская область, п. Переволоцкий, ул. Ленинская, 76, 8 (35338) 32-2-79, pr@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 34,
        "col3": "Пономаревский район: ",
        "col4": "Оренбургская область, Пономарёвский район, с. Пономарёвка, ул. Советская, 32, 8 (35357) 21-4-50, 21-3-34, pn@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 35,
        "col3": " Сакмарский район: ",
        "col4": "Оренбургская область, с. Сакмара, ул. Советская, 25, 8 (35331) 2-11-30, 2-17-77, sk@mail.orb.ru,"
    },
    {
        "col1": "Оренбургская область",
        "col2": 36,
        "col3": "Саракташский район:",
        "col4": " Оренбургская область, п. Саракташ, ул.Вокзальная, 12, 8 (35333) 6-02-50, sa@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 37,
        "col3": " Светлинский район:",
        "col4": " Оренбургская обл., Светлинский р- н, п. Светлый, ул. Советская, 22, 8 (35366) 2-14-55, 2-13-56, sv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 38,
        "col3": " Северный район:",
        "col4": " Оренбургская область, с. Северное, ул. Советская, 24, 8 (35354) 2-17-75, 2-12-00, se@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 39,
        "col3": " Ташлинский район: ",
        "col4": "Оренбургская область, с. Ташла, ул. Довженко, 46, 8 (35347) 2-14-82, 2-13-09, tl@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 40,
        "col3": "Тоцкий район:",
        "col4": " Оренбургская область, с. Тоцкое, ул. Красная Площадь, 1, 8 (35349) 2-14-34, to@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 41,
        "col3": "Тюльганский район:",
        "col4": " Оренбургская область, Тюльганский район, п.Тюльган, ул. Ленина, 23, 8 (35332) 2-11-34, tu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 42,
        "col3": "Шарлыкский район:",
        "col4": " Оренбургская область, с. Шарлык, ул.Советская, 40, 8 (35358) 2-14-50, sl@mail.orb.ru."
    },
    {
        "col1": "Орловская область",
        "col2": 1,
        "col3": "Администрация города Орла",
        "col4": "302028, г. Орёл, ул. Пролетарская Гора, д. 1,                  8 (4862) 43-33-12, info@orel-adm.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 2,
        "col3": "Администрация города Ливны",
        "col4": "303850, Орловская область, г. Ливны, ул. Ленина, д. 7,                      8 (48677)-7-19-20, livny@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 3,
        "col3": "Администрация города Мценска",
        "col4": "303030, Орловская область, г. Мценск, пл.Ленина, д.1,                     8 (48646) 2-17-70, mcensk@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 4,
        "col3": "Администрация Болховского района",
        "col4": "303140, Орловская область, г. Болхов, ул. Ленина, 2а, 8 (48640) 2-43-41, bolhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 5,
        "col3": "Администрация Верховского района",
        "col4": "303720, Орловская область, п.Верховье,  ул. 7 Ноября, д. 6, 8 (48676) 2-30-40, verhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 6,
        "col3": "Администрация Глазуновского района",
        "col4": "303340, Орловская область,\n п. Глазуновка, ул. Ленина, д. 120, 8 (48675) 2-12-09, glazunr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 7,
        "col3": "Администрация Дмитровского района",
        "col4": "303240, Орловская область, г.Дмитровск, ул. Советская. 84-а,  8 (48649) 2-13-52, dmitr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 8,
        "col3": "Администрация Должанского района",
        "col4": "303760, Орловская область, пгт.Долгое, ул.Октябрьская д.6, 8 (48672) 2-11-83, dolzhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 9,
        "col3": "Администрация Залегощенского района",
        "col4": "303560, Орловская область, пгт Залегощь, ул. М. Горького, д. 20,  8 (48648) 2-11-49, zalegr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 10,
        "col3": "Администрация Знаменского района",
        "col4": "303100, Орловская область, Знаменский район, с. Знаменское, ул. Ленина, д. 33-а, 8 (48662) 2-13-19, znamr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 11,
        "col3": "Администрация Колпнянского района",
        "col4": "303410, Орловская область, Колпнянский район, п.г.т. Колпна, ул. Пионерская, 2, 8 (48674) 2-17-71, kolpnr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 12,
        "col3": "Администрация Корсаковского района",
        "col4": "303580, Орловская область, Корсаковский район, с. Корсаково,ул. Советская, д.31, 8 (48667) 2-14-39, korsakr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 13,
        "col3": "Администрация  Краснозоренского района",
        "col4": "303650, Орловская область, Краснозоренский район, п. Красная Заря, ул. Ленина, 1,8 (48663) 2-14-91, krasnozr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 14,
        "col3": "Администрация  Кромского района",
        "col4": "303200, Орловская обл., Кромской р-н, пгт. Кромы, пл. Освобождения, д. 1,  8 (48643) 2-29-04, kromr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 15,
        "col3": "Администрация Ливенского района",
        "col4": "303857, Орловская область, г. Ливны,  ул. Курская, 14, 8 (48677) 2-16-94, livr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 16,
        "col3": "Администрация Малоархангельского района",
        "col4": "303370, Орловская область, г. Малоархангельск, ул. К.Маркса, 78, 8 (48679) 2-30-30, maloarhr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 17,
        "col3": "Администрация Мценского района",
        "col4": "303030, Орловская область\nг.Мценск пл.Ленина , д. 1, 8 (48646) 2-58-06, mcenskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 18,
        "col3": "Администрация Новодеревеньковского района",
        "col4": "303620, Орловская область, п. Хомутово, пл. Ленина, 1, 8 (48678) 2-13-50, novoderevr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 19,
        "col3": "Администрация Новосильского района",
        "col4": "303500, Орловская область, г. Новосиль, ул. Карла Маркса, д.16, 8 (48673) 2-12-52, admnovosil@yandex.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 20,
        "col3": "Администрация Орловского муниципального округа",
        "col4": "302040, г. Орёл, ул. Полярная, д. 12, 8 (4862) 41-52-67, orlr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 21,
        "col3": "Администрация Покровского района",
        "col4": "303170, Орловская область,\nпгт. Покровское, ул. 50 лет Октября, д.6, 8 (48664) 2-11-70, pokrovskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 22,
        "col3": "Администрация Свердловского района",
        "col4": "303320, Орловская область, пос. Змиёвка, ул. Ленина, д. 48, 8 (48645) 2-22-77, sverdlovskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 23,
        "col3": "Администрация Сосковского района",
        "col4": "303980, Орловская область, Сосковский район, с. Сосково, ул. Советская, д. 29,  8 (48665) 2-11-28,  soskovr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 24,
        "col3": "Администрация Троснянского района",
        "col4": "303450, Орловская область, Троснянский района, село Тросна, ул. Ленина, д. 4, 8 (48666) 2-15-59, trosnr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 25,
        "col3": "Администрация Урицкого района",
        "col4": "303900, Орловская область, п. Нарышкино, ул. Ленина, д. 104, 8 (48647) 2-04-42, uradmin@mail.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 26,
        "col3": "Администрация Хотынецкого  района",
        "col4": "303930, Орловская область, Хотынецкий район, пгт. Хотынец, ул. Ленина, д.40, 8 (48642)2-13-32, otynecr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 27,
        "col3": "Администрация Шаблыкинского района",
        "col4": "303260, Орловская область, Шаблыкинский район, пгт. Шаблыкино, ул. Ленина, д. 21, 8 (48644) 2-13-79, shablr@adm.orel.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 1,
        "col3": "Администрация города Пензы",
        "col4": "город Пенза, площадь Маршала Жукова, 4. e-mail:goradm@sura.ru, 68-22-72"
    },
    {
        "col1": "Пензенская область",
        "col2": 2,
        "col3": "Администрация города Заречного Пензенской области",
        "col4": "Пензенская область, город Заречный, пр. 30-летия Победы, д. 27, e-mail: adm@zarechny.zato.ru, тел.: 8(8412) 652953"
    },
    {
        "col1": "Пензенская область",
        "col2": 3,
        "col3": "Администрация города Кузнецка",
        "col4": "Пензенская область, город Кузнецк, ул. Ленина, д.191,e-mail:kuzg_adm@sura.ru, 884157(30694)"
    },
    {
        "col1": "Пензенская область",
        "col2": 4,
        "col3": "Администрация Башмаковского района Пензенской области",
        "col4": "Пензенская область, р.п. Башмаково, ул. Советская, 17, baschm_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 5,
        "col3": "Администрация Бековского района Пензенской области",
        "col4": "Пензенская область, р.п. Беково, ул. Первомайская, д. 3а, E-mail: bekov_adm@sura.ru, тел.(8-841-41) 2-15-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 6,
        "col3": "Управление социальной защиты населения администрации Белинского района Пензенской области",
        "col4": "Пензенская область, г. Белинский, ул. Белинская, д.3, e-mail: uszn.belinsk@yandex.ru, тел.: 8(84153)20454"
    },
    {
        "col1": "Пензенская область",
        "col2": 7,
        "col3": "Администрация Бессоновского района Пензенской области",
        "col4": "Пензенская область, с. Бессоновка, ул. Коммунистическая, д. 2, e-mail: besson_adm@mail.ru,тел.: 8(84140) 28640"
    },
    {
        "col1": "Пензенская область",
        "col2": 8,
        "col3": "администрация Вадинского района Пензенской области",
        "col4": "442170 Пензенская область, Вадинский район, с. Вадинск, ул. Площадь Ленина, д.16. vadinsk_adm@sura.ru, 8(84142)21169"
    },
    {
        "col1": "Пензенская область",
        "col2": 9,
        "col3": "администрация Городищенского района",
        "col4": "г.Городище,ул.Комсомольская,д.40 .gorodis_adm@sura.ru?88415832260"
    },
    {
        "col1": "Пензенская область",
        "col2": 10,
        "col3": "Управление социальной защиты населения администрации Земетчинского района Пензенской облати",
        "col4": "Пензенская область, р.п. Земетчино, пл. Победы, 4, soc_zemet@mail. ru 84155(23158)"
    },
    {
        "col1": "Пензенская область",
        "col2": 11,
        "col3": "Администрация Иссинского района Пензенской области",
        "col4": "Иссинский р-он,р.п. Исса, ул. Черокманова 21, 88414421543"
    },
    {
        "col1": "Пензенская область",
        "col2": 12,
        "col3": "Управление образования администрации Каменского района Пензенской области",
        "col4": "Пензенская область, Каменский район г. Каменка, ул. Гражданская, д.33, E-mail: kam_roo@sura.ru, тел.: 8(84156) 5-24-25)"
    },
    {
        "col1": "Пензенская область",
        "col2": 13,
        "col3": "Администрация Камешкирского района",
        "col4": "442450 Пензенская область, Камешкирский район, с. Русский Камешкир, ул. Радищева, д. 15/ kamesh_adm@sura_ru/ 8(84145) 21478"
    },
    {
        "col1": "Пензенская область",
        "col2": 14,
        "col3": "Администрация Колышлейского района Пензенской области",
        "col4": "Пензенская область, Колышлейский район, р.п. Колышлей, ул. Московская, д.20, E-mail: kolish_adm@sura.ru, тел.: (84146) 2-13-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 15,
        "col3": "Управление социальной защиты населения администрации Кузнецкого района Пензенской области",
        "col4": "Пензенская область, г. Кузнецк, ул. Комсомольская, д. 51, soc-kuzrn@yandex.ru, тел.: 8(84157)3-00-69"
    },
    {
        "col1": "Пензенская область",
        "col2": 16,
        "col3": "Администрация Лопатинского района",
        "col4": "8(84148)25557 lopatin_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 17,
        "col3": "Администрация Лунинского района Пензенской области",
        "col4": "Пензенская область Лунинский район р.п. Лунино ул. Юбилейная д. 39, (841-61)3-13-90, luninadm@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 18,
        "col3": "Администрация Малосердобинского района",
        "col4": "442800, Пензенская область, Малосердобинский район, село Малая Сердоба, Ленинская ул., д.42\n88416226377"
    },
    {
        "col1": "Пензенская область",
        "col2": 19,
        "col3": "Управление социальной защиты населения администрации Мокшанского района",
        "col4": "442370, Пензенская область, Мокшанский район, р.п. Мокшан, ул.Поцелуева, д.8,socmokshan@rambler.ru 8(84150)2-23-58"
    },
    {
        "col1": "Пензенская область",
        "col2": 20,
        "col3": "Управление социальной защиты населения и охраны труда администрации Наровчатского района",
        "col4": "с. Наровчат, ул. М.Горького 38 А, soz_narov@mail.ru, 88416321485"
    },
    {
        "col1": "Пензенская область",
        "col2": 21,
        "col3": "Администрация Неверкинского района",
        "col4": "Пензенская область Неверкинский район с. Неверкино ул. Куйбышева, 9, эл.почта: neverk_adm@sura.ru, т. 8(84164)20249"
    },
    {
        "col1": "Пензенская область",
        "col2": 22,
        "col3": "Администрация Нижнеломовского района Пензенской области",
        "col4": "Пензенская область, Нижнеломовский район, г. Нижний Ломов, ул. Розы Люксембург, д.4, E-mail: n.lomovadm@yandex.ru, тел.: (84154)4-47-68"
    },
    {
        "col1": "Пензенская область",
        "col2": 23,
        "col3": "Администрация Никольского района",
        "col4": "442680, Пензенская область, Никольский район, г.Никольск, ул.Московская, д.2, nikadm@nikrn.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 24,
        "col3": "Администрация Пачелмского района",
        "col4": "Пензенская область, р.п.Пачелма, ул.Драгунова, д8,pachelm_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 25,
        "col3": "Управление социальной защиты населения администрации Пензенского района Пензенской области",
        "col4": "442400, Пензенская область, Пензенский район, с. Кондоль, ул. Осипова, д. 46, тел.: (884147)55036, uszn_kondol@mail.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 26,
        "col3": "Администрация Сердобского района Пензенской области",
        "col4": "Пензенская область, Сердобский район, г.Сердобск, ул.Ленина, д.90, E-mail: serdr_adm@sura.ru, тел.: (84167) 2-35-42"
    },
    {
        "col1": "Пензенская область",
        "col2": 27,
        "col3": "Управление социальной защиты и охраны труда администрации Сосновоборского района Пензенской области",
        "col4": "Пензенская область, р.п. Сосновоборск, ул. Комсомольская, д. 52А, Тел. (84168)2-01-35, sosnovoborsckiiraion.opeka@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 28,
        "col3": "Администрация Спасского района Пензенской области",
        "col4": "Пензенская область, г.Спасск, Советская площадь, д.36 spassk-ray-adm@yandex.ru 8-84151-31231"
    },
    {
        "col1": "Пензенская область",
        "col2": 29,
        "col3": "Администрация Тамалинского района",
        "col4": "р.п.Тамала ул Советская дом 20 tamala_adm@sura.ru 88416921430"
    },
    {
        "col1": "Пензенская область",
        "col2": 30,
        "col3": "Администрация Шемышейского района Пензенской области",
        "col4": "442430 Пензенская область, Шемышейский район, р.п. Шемышейка, ул. Ленина, д.47.,т.: (8-841-59) 2-17-80. Е-mail: shem_adm@sura.ru442430 Пензенская область, Шемышейский район, р.п. Шемышейка, ул. Ленина, д.47.,т.: (8-841-59) 2-17-80. Е-mail: shem_adm@sura.ru"
    },
    {
        "col1": "Пермский край",
        "col2": 1,
        "col3": "Министерство труда\nи социального развития Пермского края",
        "col4": "ул. Ленина, д. 51, г. Пермь, Пермский край, 614000\nEmail: avdubrovin@social.permkrai.ru\nтел.: 8 (342) 240 46 70, 8 (342) 240 46 64"
    },
    {
        "col1": "Приморский край",
        "col2": 1,
        "col3": "Министерство труда и социальной политики Приморского края",
        "col4": "692337, Приморский край, г. Арсеньев, ул. Ленинская, 8 (каб.213) zhilotdel@ars.town 8(42361) 3-75-538;\n(42361) 3-75-54"
    },
    {
        "col1": "Псковская область",
        "col2": 1,
        "col3": "Территориальный отдел г. Великие Луки",
        "col4": "180000, г. Псков, ул. Вокзальная, д. 12\npskov.opc@socal.pskov.ru\n(8112) 72-01-05"
    },
    {
        "col1": "Псковская область",
        "col2": 2,
        "col3": "Территориальный отдел Бежаницкого района",
        "col4": "182840, р.п. Бежаницы, ул. Комсомольская, д. 1\nbezhanicy@social.pskov.ru\n(81141) 2-19-82"
    },
    {
        "col1": "Псковская область",
        "col2": 3,
        "col3": "Территориальный отдел Гдовского района",
        "col4": "181600, г. Гдов, ул. Ленина, д. 2/9 \ngdov@social.pskov.ru\n(81131) 2-12-77"
    },
    {
        "col1": "Псковская область",
        "col2": 4,
        "col3": "Территориальный отдел Дедовичского района",
        "col4": "182710, р.п. Дедовичи, пл. Советов, д. 7\ndedovichi@social.pskov.ru\n(81136) 9-34-01 (факс), 9-30-77"
    },
    {
        "col1": "Псковская область",
        "col2": 5,
        "col3": "Территориальный отдел Дновского района",
        "col4": "182670, г. Дно, ул. К.Маркса, д. 16\ndno@social.pskov.ru\n(81135) 2-58-56 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 6,
        "col3": "Территориальный отдел Красногородского района",
        "col4": "182370, р.п. Красногородск, ул. Школьная, д. 3\nkrasnogorodsk@social.pskov.ru\n(81137) 2-12-42 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 7,
        "col3": "Территориальный отдел Куньинского района",
        "col4": "182010, р.п. Кунья, ул. Советская, д. 17\nkunja@social.pskov.ru\n(81149) 2-18-94 (факс), 2-22-41"
    },
    {
        "col1": "Псковская область",
        "col2": 8,
        "col3": "Территориальный отдел Локнянского района",
        "col4": "182900, р.п. Локня, ул. Первомайская, д. 3\nloknja@social.pskov.ru\n(81139) 2-22-69, 2-18-71 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 9,
        "col3": "Территориальный отдел Невельского района",
        "col4": "182510, г. Невель, пл. Карла Маркса, д. 1\nnevel@social.pskov.ru\n(81151) 2-12-98 (факс), 2-15-21"
    },
    {
        "col1": "Псковская область",
        "col2": 10,
        "col3": "Территориальный отдел Новосокольнического района",
        "col4": "182200, г. Новосокольники, ул. Тихмянова, д. 25\nnovosokolniki@social.pskov.ru\n(81144) 2-23-33 (факс), 2-22-44"
    },
    {
        "col1": "Псковская область",
        "col2": 11,
        "col3": "Территориальный отдел Опочецкого района",
        "col4": "182330, г. Опочка, ул. Ленина, д. 11/17\nopochka@social.pskov.ru\n(81138) 2-25-16, 2-10-53"
    },
    {
        "col1": "Псковская область",
        "col2": 12,
        "col3": "Территориальный отдел Островского района",
        "col4": "181350, г. Остров, ул. 25 Октября, д. 31\nostrov@social.pskov.ru\n(81152) 3-29-60 (факс), 3-13-21"
    },
    {
        "col1": "Псковская область",
        "col2": 13,
        "col3": "Территориальный отдел Палкинского района",
        "col4": "182270, р.п. Палкино, ул. Островского, д. 23\npalkino@social.pskov.ru\n(81145) 2-18-51"
    },
    {
        "col1": "Псковская область",
        "col2": 14,
        "col3": "Территориальный отдел\nПечорского района",
        "col4": "181500, г. Печоры, ул. Свободы, д. 34\npechory@social.pskov.ru\n(81148) 2-17-17, 2-29-49 (факс)"
    },
    {
        "col1": "Псковская область",
        "col2": 15,
        "col3": "Территориальный отдел Плюсского района",
        "col4": "181000, р.п. Плюсса, ул. Комсомольская, д. 1а\npljussa@social.pskov.ru\n(81133) 2-17-67 (факс), 2-23-34"
    },
    {
        "col1": "Псковская область",
        "col2": 16,
        "col3": "Территориальный отдел Порховского района",
        "col4": "182620, г. Порхов, пр. Ленина, д. 14\nporhov@social.pskov.ru\n(81134) 2-46-09 (факс), 2-46-06"
    },
    {
        "col1": "Псковская область",
        "col2": 17,
        "col3": "Территориальный отдел Псковского района",
        "col4": "180006, г. Псков, ул. Школьная, д. 26\npskovrajon@social.pskov.ru\n(8112) 72-07-55, 72-07-60 (факс), 72-06-85"
    },
    {
        "col1": "Псковская область",
        "col2": 18,
        "col3": "Территориальный отдел Пустошкинского района",
        "col4": "182300, г. Пустошка, ул. Первомайская, д. 21\npustoshka@social.pskov.ru\n(81142) 2-14-81 (факс), 2-12-53"
    },
    {
        "col1": "Псковская область",
        "col2": 19,
        "col3": "Территориальный отдел Пушкиногорского района",
        "col4": "181370, р.п. Пушкинские Горы, ул. Ленина, д. 6\npushgory@social.pskov.ru\n(81146) 2-20-94 (факс), 2-13-05"
    },
    {
        "col1": "Псковская область",
        "col2": 20,
        "col3": "Территориальный отдел Пыталовского района",
        "col4": "181410, г. Пыталово, ул. Красноармейская, д. 37\npytalovo@social.pskov.ru\n(81147) 2-21-54"
    },
    {
        "col1": "Псковская область",
        "col2": 21,
        "col3": "Территориальный отдел Себежского района",
        "col4": "182250, г. Себеж, ул. 7 Ноября, д. 2\nsebezh@social.pskov.ru\n(81140) 2-15-26, 2-22-48\n"
    },
    {
        "col1": "Псковская область",
        "col2": 22,
        "col3": "Территориальный отдел Струго-Красненского района",
        "col4": "181110, р.п. Струги Красные, ул. Советская, д. 7а\nstrugikrasnye@social.pskov.ru\n(81132) 5-15-94 (факс), 5-19-39"
    },
    {
        "col1": "Псковская область",
        "col2": 23,
        "col3": "Территориальный отдел Усвятского района",
        "col4": "182570, р.п. Усвяты, ул. К.Маркса, д. 20\nusvjaty@social.pskov.ru\n(81150) 2-16-74, 2-16-30\n"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Адыгея",
        "col4": "Республика Адыгея, \nг. Майкоп, \nул. Советская, 176, deti_mintrud@adygheya.gov.ru, 8(8772)52-18-86"
    },
    {
        "col1": "Республика Алтай",
        "col2": 1,
        "col3": "Министерство труда, социального развития и занятости населения Республики Алтай",
        "col4": "Республика Алтай, г. Горно - Алтайск, ул. Северная, 10, mintrud@altaigov.ru, 8(38822)47732"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 1,
        "col3": "Министерство семьи, труда и социальной защиты населения Республики Башкортостан",
        "col4": "450008 Республика Башкортостан, г. Уфа,  ул. Пушкина , д. 95,   mintrud@bashkortostan.ru                    \n т. +7 347 21815 44"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Республики Бурятия",
        "col4": "Республика Бурятия, г. Улан-Удэ, ул. Гагарина, д. 10, 670034/  mszn@govrb.ru /89503802453"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 2,
        "col3": "Администрация Железнодорожного района г. Улан-Удэ",
        "col4": "670002, Республика Бурятия, г. Улан-Удэ, ул. Октябрьская, 2/ agdr@ulan-ude-eg.ru/ (8-3012) 44-85-25\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 3,
        "col3": "Администрация  Октябрьского района г. Улан-Удэ, ",
        "col4": "670047, Республика Бурятия, г. Улан-Удэ, ул. Павлова, 1/ aоr@ulan-ude-eg.ru/ (8-3012) 37-02-24\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 4,
        "col3": "Администрация Советского района г. Улан-Удэ",
        "col4": " 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ 670000, Республика Бурятия, г. Улан-Удэ, ул. Советская, 23/ asr@ulan-ude-eg.ru/ (8-3012) 21-56-46\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 5,
        "col3": "Администрация Баргузинского района",
        "col4": " 671610, Республика Бурятия, Баргузинский район, п. Баргузин, ул. Дзержинского, 24/ admbrg@icm.buryatia.ru/ (8-231) (8-30131) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 6,
        "col3": "Администрация Баунтовского района",
        "col4": " 671510, Республика Бурятия, Баунтовский район, c. Багдарин, ул. Ленина, 22/ admbnt@icm.buryatia.ru/ (8-253) (8-30153) 4-13-22\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 7,
        "col3": "Администрация Бичурского района",
        "col4": " 671360, Республика Бурятия, Бичурский район, с. Бичура, ул. Советская, 43/ admbich@icm.buryatia.ru/ (8-233) (8-30133) 5-15-18\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 8,
        "col3": "Администрация Джидинского района",
        "col4": " 671920, Республика Бурятия, Джидинский район, с. Петропавловка, ул. Терешковой, 8/ admdzd@icm.buryatia.ru/ (8-234) (8-30134) 9-14-20\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 9,
        "col3": "Администрация Еравнинского района",
        "col4": " 672430, Республика Бурятия, Еравнинский район, с. Сосново-Озерское, ул. Первомайская, 113/ admeravna@icm.buryatia.ru/  (8-235) (8-30135) 2-14-45\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 10,
        "col3": "Администрация Заиграевского район",
        "col4": "Заиграевского района 671310, Республика Бурятия, Заиграевский район, п. Заиграево, ул. Октябрьская, 4/ admzgr@icm.buryatia.ru/ (8-236) (8-30136) 4-22-40\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 11,
        "col3": "Администрация Закаменского района",
        "col4": "671930, Республика Бурятия, Закаменский район, г. Закаменск, ул. Ленина, 17/ admzakam@icm.buryatia.ru/ (8-237) (8-30137) 4-44-53\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 12,
        "col3": "Администрация Иволгинского района",
        "col4": " 670045, Республика Бурятия, Иволгинский район, п. Иволгинск, ул. Ленина, 18а / admivl@icm.buryatia.ru/ (8-240) (8-30140) 2-13-51\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 13,
        "col3": "Администрация Кабанского района",
        "col4": "\n 671200, Республика Бурятия, Кабанский район, с. Кабанск, ул. Кирова, 10/ admkab@icm.buryatia.ru/ (8-238) (8-30138) 4-15-36\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 14,
        "col3": "Администрация Кижингинского района",
        "col4": " 671450, Республика Бурятия, Кижингинский район, с. Кижинга, ул. Коммунистическая, 14/ admkzn@icm.buryatia.ru/ (8-241) (8-30141) 3-26-47  "
    },
    {
        "col1": "Республика Бурятия",
        "col2": 15,
        "col3": "Администрация Курумканского района",
        "col4": " 671613, Республика Бурятия, Курумканский район, с. Курумкан, ул. Булдакова, 13/ admkrm@icm.buryatia.ru/ (8-249) (8-30149) 4-15-15\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 16,
        "col3": "Администрация Кяхтинского района",
        "col4": " 671840, Республика Бурятия, Кяхтинский район, г. Кяхта, ул. Ленина, 33/ admkht@icm.buryatia.ru/ (8-242) (8-30142) 9-14-78\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 17,
        "col3": "Администрация Муйского района",
        "col4": " 671517, Республика Бурятия, Муйский район, п. Таксимо, ул. Советская, 10/ admmsk@icm.buryatia.ru/ (8-239) (8-30139) 5-54-33\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 18,
        "col3": "Администрация Мухоршибирского района",
        "col4": " 671340, Республика Бурятия, Мухоршибирский район, с. Мухоршибирь, ул. Доржиева, 38/ admmhr@icm.buryatia.ru/ (8-243) (8-30143) 2-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 19,
        "col3": "Администрация Окинского района ",
        "col4": "671030, Республика Бурятия, Окинский район, с. Орлик, ул. Советская, 12/ admoka@icm.buryatia.ru  / (8-250) (8-30150) 5-11-64\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 20,
        "col3": "Администрация Прибайкальского района ",
        "col4": "671260, Республика Бурятия, Прибайкальский район, с. Турунтаево, ул. Комарова, 14/ admprb@icm.buryatia.ru/ (8-244) (8-30144) 5-11-63\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 21,
        "col3": "Администрация г. Северобайкальска",
        "col4": " 671701, Республика Бурятия, г. Северобайкальск, пр. Ленинградский, 7/ admsevbk@icm.buryatia.ru/  (8-239) (8-30139) 2-23-19\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 22,
        "col3": "Администрация Северобайкальского района ",
        "col4": "671710, Республика Бурятия, Северобайкальский район, п. Нижнеангарск, ул. Ленина, 119/ admnrd@icm.buryatia.ru/ (8-230) (8-30130) 4-74-48\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 23,
        "col3": "Администрация Селенгинского района",
        "col4": " 671160, Республика Бурятия, Селенгинский район, г. Гусиноозерск, ул. Пушкина, 12/ admsel@icm.buryatia.ru / (8-245) (8-30145) 4-12-81\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 24,
        "col3": "Администрация Тарбагатайского района",
        "col4": " 671112, Республика Бурятия, Тарбагатайский район, с. Тарбагатай, ул. Школьная, 1/ admtrb@icm.buryatia.ru/ (8-246) (8-30146) 5-53-67\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 25,
        "col3": "Администрация Тункинского района",
        "col4": " 670820, Республика Бурятия, Тункинский район, с. Кырен, ул. Ленина, 107/ admtnk@icm.buryatia.ru / (8-247) (8-30147) 9-15-39\n"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 26,
        "col3": "Адмиистрайия Хоринского района",
        "col4": " 671410, Республика Бурятия, Хоринский район, с. Хоринск, ул. Первомайская, 41/ admhrn@icm.buryatia.ru/ (8-248) (8-30148) 2-21-63  "
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 1,
        "col3": "Агульский район",
        "col4": "Адрес: Республика Дагестан, Агульский район, с. Тпиг;\nagul-mo@mail.ru; +7 (243) 2-21-10"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 2,
        "col3": "Акушинский район",
        "col4": " Республика Дагестан, Акушинский район, с.Акуша, ул. А.Акушинского, дом 1;\namo-akusha@mail.ru +7 (87260) 21340"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 3,
        "col3": "Ахвахский район",
        "col4": "Республика Дагестан, Ахвахский район, с. Карата.\nadm_ahvah05@mail.ru +7 (87250) 2-21-82"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 4,
        "col3": "Ахтынский район",
        "col4": "368730, Республика Дагестан, Ахтынский район, с. Ахты, ул. Гаджи Ахтынского, д. 3; ahtirayon@e-dag.ru +7 (8722) 55-75-98,  +7 (8722) 55-75-97."
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 5,
        "col3": "Бабаюртовский район",
        "col4": "368060, РД, Бабаюртовский район, с. Бабаюрт, ул. Ленина, 29;\nbabaurt-rayon@yandex.ru  8 87247 2-19-88"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 6,
        "col3": "Ботлихский район",
        "col4": "368970, Республика Дагестан, Ботлихский район, с. Ботлих\nbotlihrayon@e-dag.ru +7 (87271) 2-20-59"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 7,
        "col3": "Буйнакский район",
        "col4": "368220, г. Буйнакск, ул. Ленина, 61;\nbuynakskrayon@e-dag.ru +7 (87237) 2-93-93"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 8,
        "col3": "Гергебельский район",
        "col4": "368250, Россия, Республика Дагестан, Гергебильский район, с. Гергебиль, ул. Наиба Идриса, д. №7;\nmogergebil@yandex.ru +7 (87255) 23-2-01"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 9,
        "col3": "Гумбетовский район",
        "col4": "368930, Республика Дагестан, Гумбетовский район, с. Мехельта, ул. Центральная, д. 1; m.o.gumbet@mail.ru +7 782 722-62-30  +7 872 722-32-43"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 10,
        "col3": "Гунибский район",
        "col4": "368340, Республика Дагестан, Гунибский район, село Гуниб, пл. имени Имама Шамиля, д. 1; mo_gunib@mail.ru +7 (872) 582-22-45"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 11,
        "col3": "Дахадаевский район",
        "col4": "368570,  Республика Дагестан, Дахадаевский район, село Уркарах ул. А. Алиева, 9;\ndahadaevrayon@e-dag.ru +7 (87254) 2-45-08"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 12,
        "col3": "Дербентский район",
        "col4": "Республика Дагестан, г. Дербент, ул. Гагарина, 23; derbentrayon@e-dag.ru +7 (903) 482-99-90,  +7 (87240) 4-31-75"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 13,
        "col3": "Докузпаринский район",
        "col4": "368750, Республика Дагестан, Докузпаринский район, село Усухчай, ул. Х.Д. Заманова, д. 2; oit_dokuzpara@mail.ru +7 (8722) 55-11-31"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 14,
        "col3": "Казбековский район",
        "col4": "Республика Дагестан, Казбековский район, с. Дылым, ул. Имама Шамиля, 43; adminkazbek@mail.ru +7 (8722) 55-48-76"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 15,
        "col3": "Кайтагский район",
        "col4": "368590, Республика Дагестан, Кайтагский район, с. Маджалис, ул. Надречная, д. 11; mokaitag@yandex.ru (87238) 47-3-70"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 16,
        "col3": "Карабудахкентский район ",
        "col4": "368530, Республика Дагестан, Карабудахкентский район, с. Карабудахкент, ул. Казиева (Советская), 2; karrayon@e-dag.ru  +7 (87232) 2-23-00"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 17,
        "col3": "Каякентский район",
        "col4": "368560, Республика Дагестан, Каякентский район, c. Новокаякент, ул. Джабраиловой, 36; kkentrayon@e-dag.ru +7 872 482-13-90"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 18,
        "col3": "Кизилюртовский район",
        "col4": "368120, Республика Дагестан, г. Кизилюрт, ул. Гагарина, д. 52; kizilyurt-rn@mail.ru +7 (87234) 2-21-14 +7 (928) 047-50-50"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 19,
        "col3": "Кизлярский район",
        "col4": "368830, Республика Дагестан, город Кизляр, Советская ул., 17; kizray@mail.ru +7 (87239) 2-24-36"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 20,
        "col3": "Кумторкалинский район",
        "col4": " Республика Дагестан, Кумторкалинский район, с. Коркмаскала, ул. Ленина 13; kumtorkala_raionu@mail.ru +7 (7241)55-14-20"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 21,
        "col3": "Кулинский район",
        "col4": "368390, Республика Дагестан, Кулинский район, село Вачи; kulirayon@mail.ru +7 (7268) 55-17-70"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 22,
        "col3": "Курахский район",
        "col4": "368180, РД, Курахский район, с. Курах, ул. Назаралиева, 1а; kurah.rayon@mail.ru  +7 (8722) 55-06-53,  +7 (8262) 24-1-36"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 23,
        "col3": "Лакский район",
        "col4": "Республика Дагестан, Лакский район, село Кумух, улица Сурхайхана, дом 21;  lakrayon@e-dag.ru +7 (267)2-42-72"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 24,
        "col3": "Левашинский район",
        "col4": "Республика Дагестан, Левашинский район, село Леваши, улица Ленина, 12; mo-levrayon@mail.ru +7 (87252) 2-13-20"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 25,
        "col3": "Магарамкентский район",
        "col4": "Республика Дагестан, Магарамкентский район, село Магарамкент, улица Гагарина, 2; mkentrayon@e-dag.ru +7 (8722) 55-18-27"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 26,
        "col3": "Новолакский район",
        "col4": "368160, Республика Дагестан, Новолакский район, село Новолакское, улица Мирзоева, 1; adm_novolac@mail.ru +7 (8242) 2-15-00 "
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 27,
        "col3": "Ногайский район",
        "col4": "368850, РД, Ногайский район, село Терекли Мектеб, ул. Карла Маркса, 15; nogayrayon@e-dag.ru  +7 (928) 590-41-35"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 28,
        "col3": "Рутульский район",
        "col4": "Республика Дагестан, Рутульский район, с. Рутул, ул. Центральная, д. 593; pressa@mr-rutul.ru +7 (872) 642 35 08 7 (989) 664-29-79"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 29,
        "col3": "Сергокалинский район",
        "col4": "Республика Дагестан, Сергокалинский район, с. Сергокала, ул. 317 cтр. Дивизии д.9; sergokalarayon@e-dag.ru +7 (87230) 2-33-40"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 30,
        "col3": "Сулейман-стальский район",
        "col4": "368760, Республика Дагестан, с. Касумкент, ул. Ленина, 26; sstalskrayon@e-dag.ru +7 (87236) 3-41-76"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 31,
        "col3": "Табасаранский район",
        "col4": " 368650, Республика Дагестан, Табасаранский район, с. Хучни, ул. Османова, 32; tabasaranrayon@e-dag.ru +7 (872) 492-41-64"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 32,
        "col3": "Тарумовский район",
        "col4": "Республика Дагестан, Тарумовский район, ул. Советская, №19; tarumrayon@e-dag.ru +7 (7261) 3-10-20,  +7 (7261)3-10-12"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 33,
        "col3": "Тляратинский район",
        "col4": "368 420, Республика Дагестан, Тляратинский район, с. Тлярата;  tlyaratarayon@e-dag.ru +7 (8265) 342-34,  +7 (8265) 342-05"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 34,
        "col3": "Унцукульский район",
        "col4": "368948, Республика Дагестан, п. Шамилькала, ул. М. Дахадаева, 3; mo_uncuk_raion@mail.ru 8 (8722) 55-62-79"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 35,
        "col3": "Хасавюртовский район",
        "col4": "368040, Республика Дагестан, Хасавюртовский район, село Эндирей, ул. Махачкалинское шоссе, зд. 25А; hasavurtrayon@e-dag.ru +7 (87231) 5-20-95"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 36,
        "col3": "Хивский район",
        "col4": "Республика Дагестан, Хивский район, с. Хив, ул. Советская, 13; \nmo-khivskiy@yandex.ru +7 (244) 2-22-33"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 37,
        "col3": "Хунзахский район",
        "col4": "Республика Дагестан, Хунзахский район, с. Хунзах, ул. М. Алиханова, 39; info@khunzakh.ru +7 (8722) 255-30-06"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 38,
        "col3": "Цумадинский район",
        "col4": "368900, Республика Дагестан, Цумадинский район, с. Агвали, ул. К. Абакарова, д. 95/2; tsumadarayon@e-dag.ru +7 (87273) 2-52-39"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 39,
        "col3": "Цунтинский район ",
        "col4": "368412, Республика Дагестан, Цунтинский район, село Кидеро, улица Н. Гаджиева, дом 33, корпус а; mo_cunta@mail.ru +7 (8722) 55-06-25"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 40,
        "col3": "Чародинский район",
        "col4": "Республика Дагестан, Чародинский район, село Цуриб,ул. Им.Шамиля, 1; charodarayon@e-dag.ru +7 (8722) 55-36-27"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 41,
        "col3": "Шамильский район",
        "col4": "368430, Республика Дагестан, Шамильский район, село Хебда, ул. Имама Шамиля, дом 50; shamilrayon@e-dag.ru +7 (87259) 2-22-96"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 42,
        "col3": "Бежтинский участок",
        "col4": "368410, Республика Дагестан, Цунтинский район, село Бежта, ул. Ленина, д. 1, с. 1, к. 1; bezhtinskiy@e-dag.ru +7 (8722) 55-23-01"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 43,
        "col3": "г. Махачкала",
        "col4": "367012, пл. Ленина, 2, г. Махачкала; info@mkala.ru +7 (8722) 67-21-34"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 44,
        "col3": "г. Дербент",
        "col4": "368600, Республика Дагестан, г. Дербент, пл. Свободы, 2; derbent@e-dag.ru +7 (87240) 4-26-66"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 45,
        "col3": "г. Буйнакск",
        "col4": "368220, Республика Дагестан, г. Буйнакск, ул. Х.Мусаясула, 9; derbent@e-dag.ru +7 (87240) 4-26-66"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 46,
        "col3": "г. Хасавюрт",
        "col4": "368000, Республика Дагестан г. Хасавюрт ул. Ирчи-Казака 39; xacavurt@mail.ru +7 (8722) 700-112"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 47,
        "col3": "г. Каспийск",
        "col4": "368300, РФ, Республика Дагестан, г. Каспийск, ул. Орджоникидзе, 12; kasp.info@yandex.ru +7 (7246) 5-10-00"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 48,
        "col3": "г. Кизляр",
        "col4": "368830, Республика Дагестан, г. Кизляр, ул. Советская, д. 17; it@mo-kizlyar.ru +7 (87239) 2-24-36,  +7 (87239) 2-19-22"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 49,
        "col3": "г. Кизилюрт",
        "col4": "368120, Республика Дагестан, город Кизилюрт, ул. Гагарина, 40б; mo.gor.kiz@mail.ru (87234) 2-12-34"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 50,
        "col3": "г. Избербаш",
        "col4": "368500, г. Избербаш, проспект Ленина, д. 2; izberbash@e-dag.ru +7 (87245) 2-49-13"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 51,
        "col3": "г. Южно-Сухокумск",
        "col4": "Республика Дагестан, Южно-Сухокумск, ул. Пионерская, 13; goyuzhno-suxokumsk@mail.ru +7 (87276) 2-10-10"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 52,
        "col3": "г. Дагестанские Огни",
        "col4": "368670, Республика Дагестан, город Дагестанские Огни, ул. Козленко, стр. 1; dagogniadmin@mail.ru  +7 (87275) 5-31-13"
    },
    {
        "col1": "Республика Ингушетия",
        "col2": 1,
        "col3": "Министерство образования и науки",
        "col4": "dopotde@mail.ru; 8(8732) 22-17-93"
    },
    {
        "col1": "Республика Калмыкия",
        "col2": 1,
        "col3": "-",
        "col4": "-"
    },
    {
        "col1": "Республика Карелия",
        "col2": 1,
        "col3": "Администрация Беломорского муниципального округа",
        "col4": "186500, г. Беломорск, ул. Ленинская, д. 9; (81437) 5-10-50 (приемная), e-mail: belkaradm@belomorsk-mo.ru; (81437) 5-15-84, e-mail: belopeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/belomorsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 2,
        "col3": "Администрация Калевальского национального муниципального района",
        "col4": "186910, Республика Карелия, пгт. Калевала, ул. Советская, д. 11; (81454) 4-11-05 (приемная); (81454) 4-18-77; e-mail: kalevadm@onego.ru; anna.kenieva@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/kaleval a/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 3,
        "col3": "Администрация Кемского муниципального района",
        "col4": "186610, г. Кемь, просп. Пролетарский, д. 30; (81458) 7-04-33 (приемная); e-mail: admikem@mail.ru; 8-964-317-80-17, (81458) 2-20-03, e-mail: soc.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/kem/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 4,
        "col3": "Администрация Кондопожского муниципального района",
        "col4": "186220, г. Кондопога, пл. Ленина, д. 1; (81451) 7-94-52 (приемная); e-mail: kondadm@kmr10.ru; (81451) 7-99-79, 8-964-317-83-58; paukova@kmr10.ru; https://gov.karelia.ru/power/local_govern ment/kondopoga/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 5,
        "col3": "Администрация Костомукшского городского округа",
        "col4": "186931, г. Костомукша ул. Строителей, д. 5; (81459) 5-10-10 (приемная); e-mail: adm-kos@msu.kostomuksha-rk.ru; 8-911-660-89-59, 8-911-660-15-68, 8-911-430-25-52 e-mail: opeka2kgo@list.ru; https://gov.karelia.ru/power/local_gov ernment/kostomuksha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 6,
        "col3": "Администрация Лахденпохского муниципального района",
        "col4": "186730, г. Лахденпохья, ул. Советская, д.7-А; (81450) 4-54-98 (приемная), e-mail: amcylah@onego.ru; (81450) 46-3-92;’ e-mail: bratchikova-mn@lah-mr.ru; https://gov.karelia.ru/power/local_gov ernment/lah/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 7,
        "col3": "Администрация Лоухского муниципального района",
        "col4": "186660, п. Лоухи, ул. Советская, д. 27; (81439) 5-10-17 (приемная); e-mail: louhiadm@yandex.ru; (81439) 5-17-43, e-mail: opeca.louhi@mail.ru; https://gov.karelia.ru/power/local_gov ernment/louhiadm/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 8,
        "col3": "Администрация Медвежьегорского муниципального района",
        "col4": "186300, г. Медвежьегорск ул. Кирова, д. 7; (81434) 5-15-55 (приемная); e-mail: medgora.msu@onego.ru (81434) 5-26-80, 8-902-772-70-15, e-mail: medgora.opeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/medgora/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 9,
        "col3": "Администрация Муезерского муниципального района",
        "col4": "186960, п. Муезерский, ул. Октябрьская, д. 28; (81455) 3-36-30 (приемная); e-mail: mueadmin@inbox.ru; 8-921-526-60-80, (81455) 338-49, e-mail: mue.opeka@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/muezersky/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 10,
        "col3": "Администрация Олонецкого национального муниципального района",
        "col4": "186000, г. Олонец, ул. Свирских дивизий, д. 1; (81436) 4-15-06; e-mail: administr@onego.ru; 8-964-317-81-16; e-mail: olonopeka@mail.ru; https://gov.karelia.ru/power/local_gov ernment/olon/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 11,
        "col3": "Администрация Петрозаводского городского округа",
        "col4": "185001, г. Петрозаводск, просп. Ленина, д. 2; (8142) 71-33-00 (приемная), e-mail: adm@petrozavodsk-mo.ru; (8142) 71-35-52, 71-34-37, 71-34-38, 71-34-89; e-mail: opeka@petrozavodsk-mo.ru; https://gov.karelia.ru/power/local_gov ernment/petrozavodsk/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 12,
        "col3": "Администрация Питкярантского муниципального округа",
        "col4": "186810, г. Питкяранта, ул. Ленина, д. 43; (81433) 4-11-53 (приемная); e-mail: pitkaranta@onego.ru; 8-964-317-50-95; e-mail: opeka.apmr@yandex.ru; https://gov.karelia.ru/power/local_gov ernment/pit/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 13,
        "col3": "Администрация Прионежского муниципального района",
        "col4": "185005, г. Петрозаводск, ул. Правды, д. 14, (8142) 57-81-68 (приемная), e-mail: adm@prionego.ru; 8-902-770-15-04, 8-902-770-12-45; e-mail: ivanova@prionego.ru; https://gov.karelia.ru/power/local_gov ernment/prione go/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 14,
        "col3": "Администрация Пряжинского национального муниципального района",
        "col4": "186601, п. Пряжа, ул. Советская, д. 61; (81456) 3-12-08 (приемная); e-mail: priagad@onego.ru; (81456) 3-21-86, e-mail: opekapr@bk.ru; https://gov.karelia.ru/power/local_gov ernment/pryazha/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 15,
        "col3": "Администрация Пудожского муниципального района",
        "col4": "186150, г. Пудож, ул. Ленина, д. 90; (81452) 5-17-33 (приемная); e-mail: pudogadm@pudogadm.ru; (81452) 5-10-31, e-mail: opeka.karelia@yandex.ru https://gov.karelia.ru/power/local_gov ernment/pudog/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 16,
        "col3": "Администрация Сегежского муниципального округа",
        "col4": "186420, г. Сегежа, ул. Ленина, д. 9-А; (81431) 4-24-24 (приемная), e-mail: ud1@segadmin.onego.ru; (81431) 4-25-74, e-mail: opeka-seg@yandex.ru https://gov.karelia.ru/power/local_gov ernment/seg/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 17,
        "col3": "Администрация Сортавальского муниципального округа",
        "col4": "186750, г. Сортавала, ул. Кирова, д. 11; (81430) 4-53-42 (приемная), e-mail: sort_org_otd@onego.ru; (81430) 4-81-85, e-mail: opeka.sortavala@mail.ru https://gov.karelia.ru/power/local_gov ernment/ sortavala/"
    },
    {
        "col1": "Республика Карелия",
        "col2": 18,
        "col3": "Администрация Суоярвского муниципального округа",
        "col4": "186870, г. Суоярви, ул. Шельшакова, д. 6; (81457) 5-14-50 (приемная), e-mail: suodistrict@onego.ru; (81457) 5-18-17, 8-964-317-83-16, e-mail: opeka suo@mail.ru; https://gov.karelia.ru/power/local_gov ernment/ suoj arvi/"
    },
    {
        "col1": "Республика Коми",
        "col2": 1,
        "col3": "Муниципальное образование городского округа «Сыктывкар»",
        "col4": "ул. Орджоникидзе, д. 11,\n г. Сыктывкар,\n Республика Коми, 167000\nAntonovskaya-MV@sykt.rkomi.ru,\n8 (8212) 215607 "
    },
    {
        "col1": "Республика Коми",
        "col2": 2,
        "col3": "Муниципальный округ «Ухта» Республики Коми",
        "col4": "проезд Пионергорский, д. 2, г. Ухта,  Республика Коми, 169300\nkumiuht@mail.ru,\n8 (8216) 746341"
    },
    {
        "col1": "Республика Коми",
        "col2": 3,
        "col3": "Муниципальный округ «Воркута»",
        "col4": "пл. Центральная, д.7,\n г. Воркута, Республика Коми, 169900\njilotdelpechora@mail.ru,\n8 (82142) 72327 "
    },
    {
        "col1": "Республика Коми",
        "col2": 4,
        "col3": "Муниципальный округ «Инта» Республики Коми",
        "col4": "ул. Горького, д. 16, г. Инта,  Республика Коми, 169840, \nna.docis@inta.rkomi.ru,\n8 (82145) 62941"
    },
    {
        "col1": "Республика Коми",
        "col2": 5,
        "col3": "Муниципальное образование муниципального района «Печора»",
        "col4": "Печорский проспект, д. 46,\n г. Печора,  Республика Коми,169600,\njilotdelpechora@mail.ru,\n8 (82142) 72327                                     8 (82142) 73813"
    },
    {
        "col1": "Республика Коми",
        "col2": 6,
        "col3": "Муниципальный округ «Усинск» Республики Коми",
        "col4": "ул. Ленина, д. 13, г. Усинск, Республика Коми, 169710,\ni.s.kuzmina@usinsk.rkomi.ru,\n8 (82144) 28130 (доп. 136)\n"
    },
    {
        "col1": "Республика Коми",
        "col2": 7,
        "col3": "Муниципальный округ «Вуктыл» Республики Коми",
        "col4": " ул. Комсомольская д. 14, \nг. Вуктыл, Республика Коми, 169570,\nsocialpolitika@mail.ru,\n8 (82146) 22262 (доб.26)"
    },
    {
        "col1": "Республика Коми",
        "col2": 8,
        "col3": "Муниципальное образование муниципального района  «Сосногорск»",
        "col4": "ул. Куратова, д.2, г. Сосногорск, Республика Коми,   169500,\nkui.sosn@mail.ru,\n8 (82149) 54471"
    },
    {
        "col1": "Республика Коми",
        "col2": 9,
        "col3": "Муниципальный район Сыктывдинский Республики Коми",
        "col4": "ул. Домны Каликовой, д.62, село Выльгорт, Республика Коми,  168220, Сыктывдинский район, \ne.i.lytkina@syktyvdin.rkomi.ru,\n8 (82130) 72118"
    },
    {
        "col1": "Республика Коми",
        "col2": 10,
        "col3": "Муниципальное образование муниципального района «Сысольский»",
        "col4": "ул. Советская, д. 35, с. Визинга, Сысольский район,Республика Коми,  168100,\ngp@sysola.rkomi.ru,\n8 (82131) 91596 "
    },
    {
        "col1": "Республика Коми",
        "col2": 11,
        "col3": "Муниципальный район «Койгородский»",
        "col4": "Мира ул., д.7, с. Койгородок, Республика Коми,168170,\nremizova.valentinka@mail.ru,\n8 (82132) 91630"
    },
    {
        "col1": "Республика Коми",
        "col2": 12,
        "col3": "Муниципальный район \"Прилузский\" Республики Коми",
        "col4": "ул. Мира, д. 76, с. Объячево, Прилузский район,  Республика Коми,  168130,\nm.y.tugolukova@priluzie.rkomi.ru,\n8 (82133) 22561  "
    },
    {
        "col1": "Республика Коми",
        "col2": 13,
        "col3": "Муниципальный район «Корткеросский» Республики Коми",
        "col4": "ул. Советская, д. 225, село Корткерос, Корткеросский район, Республика Коми,   168020,\ngkhkortkeros@mail.ru,\n8(82136) 92495"
    },
    {
        "col1": "Республика Коми",
        "col2": 14,
        "col3": "Муниципальное образование муниципального района «Усть-Куломский»",
        "col4": "ул. Советская, д. 37, с. Усть-Кулом, Усть-Куломский район,Республика Коми,  168060, \nzhil.otdel@ust-kulom.rkomi.ru,\n8(82137) 94901"
    },
    {
        "col1": "Республика Коми",
        "col2": 15,
        "col3": "Муниципальное образование муниципального района «Троицко-Печорский»",
        "col4": "ул. Ленина, д. 2,  пгт Троицко-Печорск, Республика Коми, 169420,\notdel.zemel1@mail.ru,\n8 (82138) 97509 "
    },
    {
        "col1": "Республика Коми",
        "col2": 16,
        "col3": "Муниципальное образование муниципального района «Усть-Вымский»",
        "col4": "ул. Центральная, д. 112,\n  с. Айкино,Усть-Вымский р-н,   Республика Коми, 169040,                   ust-vym-adm@mail.ru\nadm.upravleniezhilishnoi@yandex.ru,\n8 (82134) 28213"
    },
    {
        "col1": "Республика Коми",
        "col2": 17,
        "col3": "Муниципальный округ «Княжпогостский»",
        "col4": "ул. Дзержинского, д.81,\n г. Емва,Республика Коми, 168200,\nstarodubcevaan@mail.ru,\n8 (82139) 23165"
    },
    {
        "col1": "Республика Коми",
        "col2": 18,
        "col3": "Муниципальное образование муниципального района «Удорский»",
        "col4": "ул. Центральная, д. 144, с. Кослан,Удорский район, Республика Коми,169240,\nzv@udora.rkomi.ru,\n8 (82135) 33034"
    },
    {
        "col1": "Республика Коми",
        "col2": 19,
        "col3": "Муниципальное образование муниципального района «Ижемский»",
        "col4": "ул. Советская, д. 45, с. Ижма,Ижемский район, Республика Коми,169460, \njkhiks@mail.ru,\n8 (82140) 94107 доб. 105  "
    },
    {
        "col1": "Республика Коми",
        "col2": 20,
        "col3": "Муниципальное образование муниципального района «Усть-Цилемский»",
        "col4": "ул. Новый квартал, д.11а, с. Усть-Цильма, Республика Коми, 169480,\nadmin@ust-cilma.rkomi.ru (резерв e.n.ostashova@ust-cilma.rkomi.ru) ,\n8 (82141) 91255"
    },
    {
        "col1": "Республика Крым",
        "col2": 1,
        "col3": "Министерство образования, науки и молодежи Республики Крым",
        "col4": "295000, Республика Крым, г. Симферополь, пер. Совнаркомовский, 3\nedu@monm.rk.gov.ru\n+7(3652) 27-52-32 "
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 1,
        "col3": "Администрация городского округа «Город Йошкар-Ола»",
        "col4": "424001, г. Йошкар-Ола, Ленинский проспект, 27 / admiola@i-ola.ru / (8362) 41-40-37"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 2,
        "col3": " Администрация городского округа \"Город Волжск\"",
        "col4": "425000, г. Волжск, Коммунистическая ул., д.1 / volzhsk.adm@mari-el.gov.ru / (83631) 6-22-58"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 3,
        "col3": "Администрация городского округа «Город Козьмодемьянск»",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / kozm.adm@mari-el.gov.ru / (83632) 7-12-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 4,
        "col3": "Администрация Волжского муниципального района",
        "col4": "425000, г. Волжск, ул. Советская, д. 29 / admrvolzsk@mail.ru / (83631) 6-35-75"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 5,
        "col3": "Администрация Горномарийского муниципального района",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / gorn.adm@mari-el.gov.ru; adm-gorn2006@yandex.ru / (83632) 7-11-53"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 6,
        "col3": "Администрация Звениговского муниципального района",
        "col4": "425060, г. Звенигово, ул. Ленина, д. 39 / zven.adm@mari-el.gov.ru / (83645) 7-17-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 7,
        "col3": "Килемарский муниципальный округ Республики Марий Эл",
        "col4": "425270, Килемарский район, пгт Килемары, ул. Садовая 55 / adm-kilemary@yandex.ru / (83643) 2-14-35"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 8,
        "col3": "Администрация Куженерского муниципального района",
        "col4": "425550, п. Куженер, ул. Кирова 14 / kuzhen.adm@mari-el.gov.ru / (83637) 9-15-80"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 9,
        "col3": "Администрация Мари-Турекского муниципального района",
        "col4": "425500, п. Мари-Турек, ул.Парковая, д. 7 / mturek.adm@mari-el.gov.ru / (83634) 9-71-00"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 10,
        "col3": "Администрация Медведевского муниципального района",
        "col4": "425200, Медведевский район, пгт Медведево, Советская ул., д.20 / MED_ADM@MAIL.RU / (8362) 58-28-50"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 11,
        "col3": "Администрация Моркинского муниципального района",
        "col4": "425120, пос. Морки, ул.Советская, 14 / morki.adm@mari-el.gov.ru / (83635) 9-16-12"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 12,
        "col3": "Администрация Новоторъяльского муниципального района",
        "col4": "425430, п. Новый Торъял, ул. Культуры, д.33 / admtoryal@mail.ru / (83636) 9-15-51"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 13,
        "col3": "Администрация Оршанского муниципального района",
        "col4": "425250, пгт. Оршанка, ул. Советская, 109 / orshan.adm@mari-el.gov.ru / (83641) 2-34-41"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 14,
        "col3": "Администрация Параньгинского муниципального района",
        "col4": "425570, Параньгинский район, пгт Параньга, ул. Новая, д. 4 / paranga.adm@mari-el.gov.ru / (83639)4-72-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 15,
        "col3": "Администрация Сернурского муниципального района",
        "col4": "425450, Сернурский район, пгт. Сернур, ул. Комсомольская, д. 10 / sernur12_adm@mail.ru / (83633) 9-73-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 16,
        "col3": "Администрация Советского муниципального района",
        "col4": "425400, пгт. Советский, ул. Свердлова, д. 8 / sovetsk_adm@mail.ru / (83638) 9-43-15"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 17,
        "col3": "Администрация Юринского муниципального района",
        "col4": "425370, Юринский район, пгт.Юрино, ул. Красная площадь, д.23 / yurino.adm@mari-el.gov.ru / (83644) 3-22-24"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального района",
        "col4": "тел. 8(834)31-32-193 , эл.адрес: zhilkomardt1@mail.ru, почтовый адрес: Республика Мордовия, г. Ардатов, Комсомольская, 121;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 2,
        "col3": "Администрация Атюрьевского муниципального район",
        "col4": "тел. 8(83454)2-13-46, эл. адрес: admaturevo@aturevo.e-mordovia.ru, почтовый адрес: Республика Мордовия, Атюрьевский район, с. Атюрьево, пер. Парковый, д. 1;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 3,
        "col3": "Администрация Атяшевского муниципального района",
        "col4": "тел. 8(834)34-2-30-57, эл.адрес: uoat@e-mordovia.ru, почтовый адрес: 431800, Республика Мордовия, р.п. Атяшево, ул. Центральная, д.8;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 4,
        "col3": "Администрация Больше-Березниковского муниципального район",
        "col4": "тел. 8 (83436) 2-30-16, эл.адрес: bberezadm1@bberezniki.e-mordovia.ru, почтовый адрес: 431750, Республика Мордовия, Большие Березники, ул. Московская, д. 25;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 5,
        "col3": "Администрация Больше-Игнатовского муниципального района",
        "col4": "тел. 8(834)42- 2-14-80, эл.адрес: TrifonovaEl@bignatovo.e-mordovia.ru; эл. адрес: otdel.arkh@yandex.ru, почтовый адрес: 431670, Республика Мордовия, Большеигнатовский район, с. Большое Игнатово, ул. Советская, д. 40;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 6,
        "col3": "Администрация Дубенского муниципального района",
        "col4": "тел. 8(834)47-2-18-89, эл.адрес: dub-opeka2e-mordovia@mail.ru, stroidubur@mail.ru, почтовый адрес: 431770, Республика Мордовия, Дубенский район, с. Дубенки, ул. Денисова, д. 4;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 7,
        "col3": "Администрация Ельниковского муниципального района",
        "col4": "тел. 8(834)44-2-25-67, эл.адрес: opeka.eln@elniki.e-mordovia.ru, почтовый адрес: 431370, Республика Мордовия, Ельниковский район, с. Ельники, ул. Ленина, д. 18;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 8,
        "col3": "Администрация Зубово-Полянского муниципального района",
        "col4": "тел. 8(834)58-2-10-96, эл. адрес: jurist_adm_zbp@mail.ru, почтовый адрес: 431110, Республика Мордовия, Зубово-Полянский район, р.п. Зубова Поляна, ул. Новикова Прибоя, 4;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 9,
        "col3": "Администрация Инсарского муниципального района",
        "col4": "тел.  8(834)49-2-10-06, эл.адрес: insar.opeka@e-mordovia.ru, почтовый адрес: 431430, Республика Мордовия, г. Инсар, ул. Гагарина, д. 28;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 10,
        "col3": "Администрация Ичалковского муниципального района",
        "col4": "тел. 8(834)33-01-86, эл. адрес: ich_administration@ichalki.e-mordovia.ru, почтовый адрес: Республика Мордовия, Ичалковский район, с. Кемля, ул. Советская, д. 62;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 11,
        "col3": "Администрация Кадошкинского муниципального района",
        "col4": "тел.  8(834)48-2-35-59, эл.адрес: kadoshkinouo@e-mordovia.ru, почтовый адрес: 431900, Республика Мордовия, Кадошкинский район, п. Кадошкино, ул. Заводская, д. 33;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 12,
        "col3": "Администрация Ковылкинского муниципального района",
        "col4": "тел. 8(834)53-2-25-83, эл.адрес: opeka.kov@e-mordovia.ru, почтовый адрес: Республика Мордовия, г. Ковылкино, ул. Большевистская, д. 23;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 13,
        "col3": "Администрация Кочкуровский муниципального района",
        "col4": "тел.  8(834)39-2-11-88, эл.адрес opekakmr@kochkurovo.e-mordovia.ru, почтовый адрес: 431580, Республика Мордовия, Кочкуровский район, с. Кочкурово, ул. Советская, д. 16;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 14,
        "col3": "Администрация Краснослободского района",
        "col4": "тел. 8(834)43-2-13-10, эл.адрес vasjutochkinva@krasnoslobodsk.e-mordovia.ru, почтовый адрес: 431260, Республика Мордовия, г. Краснослободск, ул. Интернациональная, д. 64;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 15,
        "col3": "Администрация Лямбирского муниципального района",
        "col4": "тел. 8 (834-41) 2-90-98, эл. адрес: opekalyambir@e-mordovia.ru, , эл. адрес: lyambirzhkx@mail.ru, почтовый адрес: 431510, Республика Мордовия, Лямбирский район, с. Лямбирь, ул. Ленина, 11;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 16,
        "col3": "Администрация Ромодановского муниципального район",
        "col4": "тел. 8(834)38-2-07-23, эл.адрес: voevoda@e-mordovia.ru, почтовый адрес: 431600 Республика Мордовия, Ромодановский район, п. Ромоданово, ул. Ленина, д. 138;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 17,
        "col3": "Администрация Рузаевского муниципального района",
        "col4": "тел. 8(834)51-4-08-48, эл.адрес baimashkina@bk.ru, почтовый адрес: 431440, Республика Мордовия, Рузаевка, ул. Ленина, д.61;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 18,
        "col3": "Администрация городского округа Саранск",
        "col4": "тел. 8(834)2-47-38-54, эл.адрес saransk-dsp@e-mordovia.ru, 430005, Республика Мордовия, г. Саранск, ул. Советская, д. 30;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 19,
        "col3": "Администрация Старошайговского муниципального район",
        "col4": "тел. 8(834)32-2-15-72, эл.адрес: stshopeka@staroshajgovo.e-mordovia.ru, 431540, Республика Мордовия, Старошайговский район, с. Старое Шайгово, ул. Ленина, д. 11;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 20,
        "col3": "Администрация Темниковского муниципального района",
        "col4": "тел. 8(834)45-2-60-15, эл.адрес opekatmn@e-mordovia.ru, почтовый адрес: 431220, Республика Мордовия, г. Темников, ул.Кирова, д. 26;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 21,
        "col3": "Администрация Теньгушевского муниципального района",
        "col4": "тел.  8(834)46-2-90-61, эл. адрес: tengushevskoeuo@e-mordovia.ru;\n"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 22,
        "col3": "Администрация Торбеевского муниципального района",
        "col4": "тел.  8(834)56-2-00-11, эл.адрес torbeevskoeuo@e-mordovia.ru, почтовый адрес: 431030, Республика Мордовия, п. Торбеево, ул. Карла Маркса, 7;"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 23,
        "col3": "Администрация Чамзинского муниципального района",
        "col4": "тел. 8(834)37-2-12-22, эл. почта: orgotdel113@mail.ru, почтовый адрес: 431700, Республика Мордовия, Чамзинский район, р.п. Чамзинка, ул. Победы, д.1."
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 1,
        "col3": "Министерство труда и социального развития Республики Саха (Якутия)",
        "col4": "677000, г. Якутск,\nул. Петра Алексеева, 6/1,\nmintrud@sakha.gov.ru\n/8(4112)508-058"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 1,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Алагирскому району",
        "col4": "РСО-Алания, 363426, г. Алагир., ул. Комсомольская, 28, тел. 8(86731) 36405  Email:uszn-alagir@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 2,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Ардонскому  району",
        "col4": "РСО-Алания, 363330, г. Ардон, ул. Пролетарская, 71, тел. 8(86732) 33483, Email: uszn-ardon@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 3,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Дигорскому  району",
        "col4": "РСО-Алания, 363410, г. Дигора, ул. Энгельса, 47, 8(86733) 91960; Email:uszn-digora@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 4,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Ирафскому району",
        "col4": "РСО-Алания, 363500, с. Чикола, ул. Макоева, 19. 8(86734)31380 Email: uszn-iraf@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 5,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защитьы населения по Кировскому району",
        "col4": "РСО-Алания,363600,  с.Эльхотово,  ул. Зортова, 20, 8(86735)51056. Email: uszn-kirov@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 6,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Моздокскому району",
        "col4": "РСО-Алания, 363760, г. Моздок, ул. Проезд Юбилейный,6    8(86736)24696  Email: uszn-mozdok@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 7,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Правобережному район",
        "col4": "РСО-Алания, 363020, г. Беслан, ул. Плиева, 16, 8(86737)31564, Email: uszn-prav@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 8,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защитьы населения по Пригородному району",
        "col4": "РСО-Алания, 363130, с. Октябрьское, ул. Епхиева, 50, 8(86738)22202  Email: uszn-prig@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 9,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защитьы населения по Иристонскому МО г. Владикавказа",
        "col4": "РСО-Алания, 362025, г. Владикавказ, ул. Ватутина, 17  8(8672)536486   Email: uszn-iriston@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 10,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защитьы населения по Затеречному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362008, г. Владикавказ, ул. Коцоева, 17  8(8672)256298   Email: uszn-zater@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 11,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Промышленному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362002, г. Владикавказ, ул. Чкалова,4  8(8672)766474   Email: uszn-prom@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 12,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управление социальной защиты населения по Северо-Западному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362031 г. Владикавказ, ул. Леонова,4  8(8672)746131   Email: uszn-sevzap@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Татарстан",
        "col2": 1,
        "col3": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66",
        "col4": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66"
    },
    {
        "col1": "Республика Тыва",
        "col2": 1,
        "col3": "Министерство образования Республики Тыва",
        "col4": "Республика Тыва, \nг. Кызыл, ул. Ленина, 39,  \nopeka.2022@mail.ru\nраб. номер \n8 (39422) 3-00-21\n"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 1,
        "col3": "Администрация города Абакана",
        "col4": "ул. Щетинкина, д. 10, г. Абакан, 655017, тел.: 8 (3902) 223-791, e-mail: abakanadm@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 2,
        "col3": "Администрация города Абазы",
        "col4": "ул. Ленина, д. 20а, г. Абаза, 655750, тел.: 8 (39047) 2-30-61, e-mail: abm-abaza@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 3,
        "col3": "Администрация города Саяногорска",
        "col4": "микр., Советский, д. 1, г. Саяногорск, 655602, тел.: 8 (39042) 2-02-00, e-mail: sayanogorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 4,
        "col3": "Администрация города Сорска",
        "col4": "ул. Кирова, д. 3, г. Сорск, 655111, тел.: 8 (39033) 2-43-55, e-mail: aosorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 5,
        "col3": "Администрация города Черногорска",
        "col4": "ул. Советская, д. 66, г. Черногорск, 655154, тел.: 8 (39031) 2-25-72, e-mail: adm.ch.22572@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 6,
        "col3": "Администрация Алтайского района",
        "col4": "ул. Ленина, д. 74, с. Белый Яр, Алтайский район,  тел.: 8 (39041) 2-12-53, e-mail: altay@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 7,
        "col3": "Администрация Аскизского района",
        "col4": "ул. Суворова, д. 2, с. Аскиз, Аскизский район, 655700,  тел.: 8 (39045) 9-13-31, e-mail: mo-askiz@mail.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 8,
        "col3": "Администрация Бейского района",
        "col4": "ул. Площадь Советов, д. 20, с. Бея, Бейский район, 655770,  тел.: 8 (39044) 3-20-00, e-mail: mo-beya@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 9,
        "col3": "Администрация Боградского района",
        "col4": "ул. Новая, д. 10, с. Боград, Боградский район, 655340,  тел.: 8 (39034) 9-12-56, e-mail: admbograd@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 10,
        "col3": "Администрация Орджоникидзевского района",
        "col4": "ул. Кирова, д. 16, с. Копьево, Орджоникидзевский район, 655250,  тел.: 8 (39036) 2-17-04, e-mail: amo-ordgo@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 11,
        "col3": "Администрция Таштыпского района",
        "col4": "ул. Ленина, д. 35, с. Таштып, Таштыпский район, 655740,  тел.: 8 (39046) 2-14-42, e-mail: amotash@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 12,
        "col3": "Администрация Усть-Абаканского района",
        "col4": "ул. Рабочая, д. 9, с. Усть-Абакан, Усть-Абаканский район, 655100,  тел.: 8 (39032) 2-16-56, e-mail: oo_ua@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 13,
        "col3": "Администрацитя Ширинского района",
        "col4": "ул. Октябрьская, д. 79, с. Шира, Ширинский район, 655200,  тел.: 8 (39035) 9-12-10, e-mail: adminshira@r-19.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 1,
        "col3": "Администрация Азовского района (Азовский районный отдел образования)",
        "col4": "346780, Россия, Ростовская область, г. Азов, ул. Мира, д. 102, каб. 4. 8 863 42 6 60 40 aar-doc@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 2,
        "col3": "Управление образования Администрации Аксайского района",
        "col4": "346720, Ростовская область, г. Аксай, ул. Ломоносова, 1         rmk@aksay.ru                              8 (86350) 5-51-07"
    },
    {
        "col1": "Ростовская область",
        "col2": 3,
        "col3": "Администрация Багаевского района",
        "col4": "346610 Ростовская область, Багаевский район, ст.Багаевская, ул.Подройкина, д.10, тел. 8(86357) 33-2-44,  е-mail: bagadm@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 4,
        "col3": "Отдел образования Администрации Белокалитвинского района",
        "col4": "347042, Ростовская область, г. Белая Калитва, ул. Чернышевского, д. 12, belokalitotdel2012@yandex.ru, 8(86383)2-52-39"
    },
    {
        "col1": "Ростовская область",
        "col2": 5,
        "col3": "Администрация Боковского района",
        "col4": "346250 Ростовская область, Боковский район, станица Боковская, переулок Теличенко, 32/ bokovsk@donland.ru/ 8(86382) 3-14-53 "
    },
    {
        "col1": "Ростовская область",
        "col2": 6,
        "col3": "Администрация Верхнедонского района ",
        "col4": "346170, ул. Матросова, д. 12, ст-ца Казанская, Верхнедонской район, Ростовская область, kazanka@donland.ru 88636431262"
    },
    {
        "col1": "Ростовская область",
        "col2": 7,
        "col3": "Комиссии об утверждении Правил подачи и рассмотрения заявления о сокращении срока действия договора найма специализированного жилого помещения",
        "col4": "347781, Ростовская область, Веселовский район, п. Веселый, пер. Базарный, д.5 E-mail: veselovsky@donland.ru 8(86358)6-15-86"
    },
    {
        "col1": "Ростовская область",
        "col2": 8,
        "col3": "Администрация Волгодонского района",
        "col4": "347350, Ростовская область, Волгодонской район, ст. Романовская, ул. Почтовая, 13, телефон 88639470130, электронная почта: volgraion@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 9,
        "col3": "Администрация Дубовского района Ростовская область",
        "col4": "347410, Ростовская область, Дубовский район, с. Дубовское, пл. Павших Борцов,1, Adm_dub@donland.ru, 8(86377)2-02-70 доб. 129"
    },
    {
        "col1": "Ростовская область",
        "col2": 10,
        "col3": "Администрация Егорлыкского райрна Ростовской области",
        "col4": "347661, Ростовская область, Егорлыкский район, ст. Егорлыкская, ул. Мира, 90, admegorlyk@donland.ru +7(86370)21-3-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 11,
        "col3": "Администрация Заветинского района",
        "col4": "347430 Ростовская область, Заветинский район,село Заветное, ул. Ломоносова, д.24,  zavetnoe61@donland.ru, +7 (86378) 2-14-00"
    },
    {
        "col1": "Ростовская область",
        "col2": 12,
        "col3": "Администрация Зерноградского района (Комиссия по обследованию жилых помещений предоставленных по договору найма специализированного жилого помещения)",
        "col4": "347740, Ростовская область, г. Зерноград, ул. Мира, 16, zernoland@donland.ru, 886359 36-1-28 (секретарь Комиссии)"
    },
    {
        "col1": "Ростовская область",
        "col2": 13,
        "col3": "Администрация Зимовниковского района",
        "col4": "ул. Ленина, 114, п. Зимовники, Ростовская область, 347460 adm@zima.donpac.ru 8(86376)3-16-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 14,
        "col3": "Администрация Кагальницкого района",
        "col4": "347700, Ростовская обл., Кагальницкий р-он., ст. Кагальницкая, ул. Калинина, д. 101, тел.: 8(86345)96-1-40, kaglobsh@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 15,
        "col3": "Отдел образования Администрации Каменского района ",
        "col4": " Ростовская область, Каменский район, рп. Глубокий ул. Ленина, д. 38,      glubray@mail.ru, 8(86365)-95-2-54; "
    },
    {
        "col1": "Ростовская область",
        "col2": 16,
        "col3": "Администрация Кашарского района (Кашарский отдел образования)",
        "col4": "346200, Ростовская область, Кашарский район, сл. Кашары, ул. Ленина, д. 58, эл.почта: roo_kasharsky@rostobr.ru, тел: 8-863-88-21-4-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 17,
        "col3": "Администрация Константиновского района",
        "col4": "347250 Ростовская область, г. Константиновск, ул. 25 Октября 70, адрес электронной почты:  adm_konst@donland.ru. Номер телефона приемной Главы Администрации (863 93) 2-16-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства управления образования Красносулинского района ",
        "col4": "Ростовская область, \nг. Красный Сулин, \nул. Металлургов, 41 krasnyisulin.opeka@yandex.ru\n8(863)67-5-30-84"
    },
    {
        "col1": "Ростовская область",
        "col2": 19,
        "col3": "Отдел образования Администрации Куйбышевского района",
        "col4": "346940 Ростовская область, Куйбышевский район,  село Куйбышево, улица Пролетарская, 2-Б, kuibroo@donland.ru 8 (86348) 31-202"
    },
    {
        "col1": "Ростовская область",
        "col2": 20,
        "col3": "Администрация Мартыновского района Ростовской области",
        "col4": "346660, Ростовская область, Мартыновский район, сл. Большая Мартыновка, ул. Советская, дом № 61/adm@martadmin.ru/88639521550; 88639521035 "
    },
    {
        "col1": "Ростовская область",
        "col2": 21,
        "col3": "Отдел образования Администрации Матвеево-Курганского района",
        "col4": "346970, Ростовская область, Матвеево-Курганский район, п. Матвеев Курган, ул. 1-я Пятилетка, 104, roo_matveevo-kurgansky@rostobr.ru,тел. 8(86341) 20976"
    },
    {
        "col1": "Ростовская область",
        "col2": 22,
        "col3": "Администрация \nМиллеровского района",
        "col4": " Ленина ул., д. 6, г. Миллерово, \nРостовская обл., 346130\nТел.: +7 (86385) 2-68-69,\nфакс: +7 (86385) 2-68-63\nE-mail: admil@donland.ru\nhttps://millerovo.donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 23,
        "col3": "Администрация Милютинского района",
        "col4": "347120, Ростовская область, ст.Милютинская, ул.Павших Героев, 46, miladm@donland.ru,  8-863-89-2-14-00 "
    },
    {
        "col1": "Ростовская область",
        "col2": 24,
        "col3": "Комитет по управлению имуществом Администрации Морозовского района",
        "col4": "347210, Ростовская область, г.Морозовск, ул.Ленина, д.204, kuimorozovsk@donland.ru, 88638450243"
    },
    {
        "col1": "Ростовская область",
        "col2": 25,
        "col3": "Муниципальное учржедние \"Отдел образования Администрации Мясниковского района\"",
        "col4": "346800 Ростовская область, Мясниковский район,с. Чалтырь, ул. 7-я линия, 1б/muotdelobr2017@yandex.ru/88634923695"
    },
    {
        "col1": "Ростовская область",
        "col2": 26,
        "col3": "Управление образования Администрации Неклиновского района",
        "col4": "346830 Ростовская область, Неклиновский район, с. Покровское, ул. Ленина, дом 271       roo_neklinovsky@rostobr.ru         8(86347)21502"
    },
    {
        "col1": "Ростовская область",
        "col2": 27,
        "col3": "Отдел образования Администрации Обливского района",
        "col4": "347140, Ростовская область, Обливский район, ст. Обливская, ул. Ленина, д. 47, obraz@oblivka.donpac.ru, 8(86396)21-8-38"
    },
    {
        "col1": "Ростовская область",
        "col2": 28,
        "col3": "Отдел образования Администрации Октябрьского района",
        "col4": "346480 Ростовская область, Октябрьский район, р.п. Каменоломни, ул. Дзержинского, д. 78-А. Электронный адрес: opeka_okt_s@mail.ru. Тел. 8(86360) 2-27-33"
    },
    {
        "col1": "Ростовская область",
        "col2": 29,
        "col3": "Администрация Орловского района ",
        "col4": "347510, Ростовская обл,Орловсий р-н., п.Орловский, ул.Пионерская, д.75,    orlovkastroy@yandex.ru, (886375) 31-4-80"
    },
    {
        "col1": "Ростовская область",
        "col2": 30,
        "col3": "Отдел образования Администрации Песчанокопского района",
        "col4": "347570,  Ростовская область, Песчанокопский район, с.Песчанокопское, ул.Школьная, 1  stars@peschan.donpac.ru,       8 (86373) 9-19-39"
    },
    {
        "col1": "Ростовская область",
        "col2": 31,
        "col3": "Районный отдел образования Администрации Пролетарского района Рост овской области",
        "col4": "347540,Ростовская область, г.Пролетарск, ул.Пионерская, д.51. rooprol@donland.ru 88637496910"
    },
    {
        "col1": "Ростовская область",
        "col2": 32,
        "col3": "Сектор по управлению имуществом и земельным отношениям\nАдминистрации Ремонтненского района",
        "col4": "347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-0-39,kui-rem@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 33,
        "col3": "Управление образования Родионово-Несветайского района",
        "col4": "346580, Ростовская обл., Родионово-Несветайский          р-н, сл. Родионово-Несветайская, ул. Пушкинская, 34,                       эл. почта roo_uo@mail.ru\n8(86340)30-8-65\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 34,
        "col3": "Управление имущественных отношений Сальского района",
        "col4": "Ростовская область, г. Сальск, ул. Ленина, 22;                                                     uio-salsk@yandex.ru                              886372 5-12-78   "
    },
    {
        "col1": "Ростовская область",
        "col2": 35,
        "col3": "Отдел образования Администрации Семикаракорского района Ростовской области",
        "col4": "3466030, Ростовская область,  г.Семикаракорск, проспект В.А. Закруткина,79,  roo@semikar.donpac.ru, 86356 4-11-82"
    },
    {
        "col1": "Ростовская область",
        "col2": 36,
        "col3": "Администрация Советского района",
        "col4": "347180, Ростовская область, Советский район, ст. Советская, ул. Орджоникидзе, д. 14, sov_adm.ro@mail.ru,  тел. 88636323200"
    },
    {
        "col1": "Ростовская область",
        "col2": 37,
        "col3": "Муниципальное учреждение Отдел образования Администрации Тарасовского района, как уполномоченный орган опеки и попечительства",
        "col4": "346050 Ростовская область Тарасовский район п.Тарасовский ул.Ленина, 29 roo_tarasovsky@rostobr.ru 8(863 86) 32-2-70"
    },
    {
        "col1": "Ростовская область",
        "col2": 38,
        "col3": "Отдел образования\nАдминистрации\nТацинского района",
        "col4": "347060 Ростовская область\nТацинский район\nст. Тацинская,ул. Ленина, 66\nтел./факс 8-8(6397)-2-12-78\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 39,
        "col3": "Администрация Усть-Донецкого района",
        "col4": "346550, Ростовская область, Усть-Донецкий район, р.п. Усть-Донецкий, ул. Ленина, д.11, ustland@donland.ru, 8(86351)-9-11-57"
    },
    {
        "col1": "Ростовская область",
        "col2": 40,
        "col3": "Отдел образования Администрации Целинского района,  Координационный совет по социальной адаптации и сопровождению выпускников из числа детей-сирот и детей,оставшихся без попечения родителей.",
        "col4": "347760, Ростовская область, Целинский район, п.Целина, ул.Советская, 35,  адрес электронной почты coo@celina.donpac.ru,      телефон 8 (86371) 9-12-43\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 41,
        "col3": " Администрации Цимлянского района",
        "col4": "347320 Ростовская область, Цимлянский район, г. Цимлянск, ул. Ленина, 24. o4042011@yandex.ru, тел. 8(86391)2-14-44; 8(86391)5-11-42"
    },
    {
        "col1": "Ростовская область",
        "col2": 42,
        "col3": "Администрация Чертковского района",
        "col4": "346000, Ростовская область, Чертковский р-н, п.Чертково, ул.Петровского, д.115/ra-217@donland.ru/(86387)21722"
    },
    {
        "col1": "Ростовская область",
        "col2": 43,
        "col3": "Отдел имущественных и земельных отношений  Администрации Шолоховского района",
        "col4": "Ростовская облать, Шолоховский район, ст. Вешенская, ул. Шолохова, 54, slimzemot@mail.ru, 88635322196"
    },
    {
        "col1": "Ростовская область",
        "col2": 44,
        "col3": "Управление образования администрации города Азова",
        "col4": "346870, Ростовская область, город Азов, ул. Кондаурова, 53/ uo_azov@rostobr.ru/ 8(86342)6-22-14"
    },
    {
        "col1": "Ростовская область",
        "col2": 45,
        "col3": "Управление образования города Батайска",
        "col4": "346880, Ростовская область, город Батайск, ул. Куйбышева, д.184а, тел. (886354) 2-36-38, 2-36-06; эл. bataysk.opeka@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 46,
        "col3": "МКУ \"Департамент строительства\"",
        "col4": "Ленина, ул.д.95, г.Волгодонск, Ростовская область, 347360, т/факс (8639)21-20-54/ 21-24-70, адрес электронной почты: ds@vlgd61.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 47,
        "col3": "Отдел образования администрации г. Гуково",
        "col4": "347871, Ростовская область, г. Гуково, ул. Маяковского, 36, Obrazov-gukovo@donland.ru, opeka61@yandex.ru, 8(86361)5-25-34,                              8(86361)5-47-30)"
    },
    {
        "col1": "Ростовская область",
        "col2": 48,
        "col3": "Муниципальное учреждение отдел образования администрации города Донецка Ростовской области",
        "col4": "346330, Ростовская область, г.Донецк, пр-кт Мира, 39   adm-don@donland.ru  +7(86368) 2-06-25"
    },
    {
        "col1": "Ростовская область",
        "col2": 49,
        "col3": "Отдел образования Администрации города Зверево",
        "col4": "346311 Ростовская область г. Зверево, ул. Рижская, д. 5 oozverevo@yandex.ru  88635541055"
    },
    {
        "col1": "Ростовская область",
        "col2": 50,
        "col3": "Отдел образования Администрации г. Каменск-Шахтинский",
        "col4": "347800, Ростовская область, г. Каменск-Шахтинский, ул. Подтелкова 69 E-mail: opeca-kam@yandex.ru                   8 (86365) 7-61-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 51,
        "col3": "Управление образования Администрации города",
        "col4": "346400, Ростовская область, г. Новочеркасск, пр-кт Платовский, д. 59б. Эл. почта: p0school@novoch.ru, тел. 8(86352)259871, 8(86352)246046"
    },
    {
        "col1": "Ростовская область",
        "col2": 52,
        "col3": "Управление образования Администрации города Новошахтинска",
        "col4": " 346918, Ростовская область, г. Нвошахтинск, ул. Советская, 21, oo_novoshakhtinsk@rostobr.ru, 8 (86369) 2-05-68"
    },
    {
        "col1": "Ростовская область",
        "col2": 53,
        "col3": "Управление образования г. Таганрога",
        "col4": "347923, Ростовская область, г. Таганрог, пер. Красногвардейский, д. 1 / goruo@tagobr.ru / +7 (8634) 648-235"
    },
    {
        "col1": "Ростовская область",
        "col2": 54,
        "col3": "Департамент образования г.Шахты",
        "col4": "346500, Ростовская область, г.Шахты, ул.Советская, 145, priem@shakhty-edu.ru             8 (8636) 22-45-46"
    },
    {
        "col1": "Ростовская область",
        "col2": 55,
        "col3": "Администрация Ворошиловского района города Ростова-на-Дону",
        "col4": "344113, г. Ростов-на-Дону, бул. Королева, 28/5               (863) 235-73-11    adminvor@rostov-gorod.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 56,
        "col3": "Администрация Железнодорожного района города Ростова-на-Дону",
        "col4": "344004, г. Ростов – на – Дону,  пр. Стачки, 42                                   (863) 222 46 25      admingel@rostov-gorod.ru\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 57,
        "col3": "Администрация Кировского района города Ростова-на-Дону ",
        "col4": "344007, г. Ростов-на-Дону, ул. Серафимовича, д. 86            (863) 262 45 43         adminkir@rostov-gorod.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 58,
        "col3": "Администрация Лениского района города Ростова-на-Дону",
        "col4": "344002, г. Ростов-на-Дону, пер. Соборный, 36      (863)240-80-75  lenadm@rostov-gorod.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 59,
        "col3": "Администрация Октябрьского района города Ростова-на-Дону",
        "col4": "344038, г. Ростов-на-Дону, пр-кт. Ленина, д. 44           (863) 245 03 54     infokt@rostov-gorod.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 60,
        "col3": "Администрация Первомайского района города Ростова-на-Дону",
        "col4": "344701, г. Ростов-на-Дону, ул. Воровского, 48      (863) 252-00-14    adminper@rostov-gorod.ru\n"
    },
    {
        "col1": "Ростовская область",
        "col2": 61,
        "col3": "Администрация Пролетарского района города Ростова-на-Дону",
        "col4": "344019, г. Ростов-на-Дону, ул. Советская, д. 4                 (863) 251-55-20              adminpro@rostov-gorod.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 62,
        "col3": "Администрация Советского района города Ростова-на-Дону",
        "col4": " 344091   Ростов-на-Дону,   Коммунистический пр., дом 24,                                              863) 222-54-69      adminsov@rostov-gorod.ru\n    \n"
    },
    {
        "col1": "Рязанская область",
        "col2": 1,
        "col3": "Администрация Александро-Невского муниципального района Рязанской области",
        "col4": "391240, Рязанская область, р.п. Александро-Невский, ул. Советская, д. 9; aleksandro-nevskij.r@ryazan.gov.ru, +7 (491-58) 2-23-93"
    },
    {
        "col1": "Рязанская область",
        "col2": 2,
        "col3": "Администрация Ермишинского муниципального района Рязанской области",
        "col4": "391660, Рязанская область, р.п. Ермишь, пл. Ленина, д. 58, ermish@ryazan.gov.ru, +7 (491-44) 2-10-35"
    },
    {
        "col1": "Рязанская область",
        "col2": 3,
        "col3": "Администрация Захаровского муниципального района Рязанской области",
        "col4": "391740, Рязанская область, с. Захарово, ул. Центральная, д. 88, zaxarovo@ryazan.gov.ru, +7 (49153)51-3-26"
    },
    {
        "col1": "Рязанская область",
        "col2": 4,
        "col3": "Администрация Кадомского муниципального района Рязанской области",
        "col4": "391670, Рязанская область, р.п. Кадом, ул. Ленина, д. 37, kadom@ryazan.gov.ru, +7 (491-39) 5-16-90"
    },
    {
        "col1": "Рязанская область",
        "col2": 5,
        "col3": "Администрация Клепиковского муниципального района Рязанской области",
        "col4": "391030, Рязанская область, г. Спас-Клепики, пл. Ленина, д. 1, klepiki@ryazan.gov.ru, +7 (491-42) 2-62-51"
    },
    {
        "col1": "Рязанская область",
        "col2": 6,
        "col3": "Администрация Кораблинского муниципального округа Рязанской области ",
        "col4": "391200, Рязанская область, г. Кораблино, ул. Маяковского, д. 17, korablino@ryazan.gov.ru, +7 (49143) 5-04-25"
    },
    {
        "col1": "Рязанская область",
        "col2": 7,
        "col3": "Администрация Касимовского муниципального округа Рязанской области ",
        "col4": "391300, Рязанская область, г. Касимов, ул. Ленина, д. 9 А, kasimov@ryazan.gov.ru, +7 (49131) 2-24-21\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 8,
        "col3": "Администрация Милославского муниципального района Рязанской области ",
        "col4": "391770, Рязанская область, р.п. Милославское, ул. Ленина, д. 6, miloslavskoe@ryazan.gov.ru, +7 (4912) 20-28-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 9,
        "col3": "Администрация Михайловского муниципального округа Рязанской области ",
        "col4": "391710, Рязанская область, г. Михайлов, пл. Освобождения, д. 1, mixajlov@ryazan.gov.ru, +7 (49130) 2-12-32"
    },
    {
        "col1": "Рязанская область",
        "col2": 10,
        "col3": "Администрация Пителинского муниципального округа Рязанской области ",
        "col4": "391630, Рязанская область, р.п. Пителино, пл. Советская, д. 8, pitelino@ryazan.gov.ru, +7 (49145) 64141"
    },
    {
        "col1": "Рязанская область",
        "col2": 11,
        "col3": "Администрация Пронского муниципального района Рязанской области ",
        "col4": "391140, Рязанская область, р.п. Пронск, пл. Горького, д. 1, pronsk@ryazan.gov.ru, +7 (49155) 3-15-73"
    },
    {
        "col1": "Рязанская область",
        "col2": 12,
        "col3": "Администрация Путятинского муниципального округа Рязанской области ",
        "col4": "391480, Рязанская область, с. Путятино, ул. Ворошилова, д. 56, putyatino@ryazan.gov.ru, +7 (49146) 2-17-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 13,
        "col3": "Администрация Рыбновского муниципального района Рязанской области ",
        "col4": "391110, Рязанская область, г. Рыбное, пл. Ленина, д. 9, rybnoe@ryazan.gov.ru, +7(49137)50-3-42"
    },
    {
        "col1": "Рязанская область",
        "col2": 14,
        "col3": "Администрация Ряжского муниципального округа Рязанской области ",
        "col4": "391964, г. Ряжск, ул. Советская, д. 8, ryazhsk@ryazan.gov.ru, +7 (49132) 2-23-83"
    },
    {
        "col1": "Рязанская область",
        "col2": 15,
        "col3": "Администрация Рязанского муниципального района Рязанской области ",
        "col4": "390047, г. Рязань (п. Соколовка), ул. Связи, д. 14, admryazanr@ryazan.gov.ru, +7 (4912) 28-91-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 16,
        "col3": "Администрация Сапожковского муниципального района Рязанской области ",
        "col4": "391940, Рязанская область, р.п. Сапожок, ул. 50 лет Октября, д. 25, sapozhok@ryazan.gov.ru, +7(49152) 2-11-50"
    },
    {
        "col1": "Рязанская область",
        "col2": 17,
        "col3": "Администрация Сараевского муниципального района  Рязанской области  ",
        "col4": "391870, Рязанская область, р.п. Сараи, ул. Ленина, д. 157, sarai@ryazan.gov.ru, +7 (49148) 3-18-55"
    },
    {
        "col1": "Рязанская область",
        "col2": 18,
        "col3": "Администрация Сасовского муниципального округа Рязанской области ",
        "col4": "391430, Рязанская область, г. Сасово, ул. Садовая, д. 22, sasovo@ryazan.gov.ru, +7 (49133) 5-12-20, +7 (49133) 5-16-10"
    },
    {
        "col1": "Рязанская область",
        "col2": 19,
        "col3": "Администрация Скопинского муниципального района Рязанской области ",
        "col4": "391800, Рязанская область, г. Скопин, пл. Ленина, д. 22 а, skopinr@ryazan.gov.ru, +7 (49156) 2-00-45"
    },
    {
        "col1": "Рязанская область",
        "col2": 20,
        "col3": "Администрация Спасского муниципального района Рязанской области ",
        "col4": "391050, Рязанская область, г. Спасск-Рязанский, ул. Ленина, д. 48, spassk@ryazan.gov.ru, +7 (49135) 3-36-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 21,
        "col3": "Администрация Старожиловского муниципального района Рязанской области ",
        "col4": "391170, Рязанская область, р.п. Старожилово, ул. Толстого, д. 9, starozhilovo@ryazan.gov.ru, +7 (491-51) 2-22-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 22,
        "col3": "Администрация Ухоловского муниципального района Рязанской области ",
        "col4": "391920, Рязанская область, р.п. Ухолово, ул. Ленина, д. 20, ukholovo-adm@ryazan.gov.ru, +7 (4915) 45-12-94"
    },
    {
        "col1": "Рязанская область",
        "col2": 23,
        "col3": "Администрация Чучковского муниципального района Рязанской области",
        "col4": "391420, Рязанская область, р.п. Чучково, пл. Ленина, д. 1, chuchkovo@ryazan.gov.ru, +7 (491-38) 7-62-25\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 24,
        "col3": "Администрация Шацкого муниципального района Рязанской области ",
        "col4": "391550, Рязанская область, г. Шацк, ул. Красная площадь, д. 5, shack@ryazan.gov.ru, +7 (49147) 2-14-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 25,
        "col3": "Администрация Шиловского муниципального района Рязанской области ",
        "col4": "391500, Рязанская область, р.п. Шилово, ул. Советская, д. 14 А, shilovo@ryazan.gov.ru, +7 (491-36) 2-11-80"
    },
    {
        "col1": "Рязанская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Рязани",
        "col4": "390000, г. Рязань, ул. Радищева, д. 28,ryazan@ryazan.gov.ru,+7 (4912) 20-04-12"
    },
    {
        "col1": "Рязанская область",
        "col2": 27,
        "col3": "Администрация городского округа г. Скопин",
        "col4": "391800, Рязанская область, г. Скопин, ул. Ленина, д. 9, skopin.adm@yandex.ru, +7 (49156) 2-06-57"
    },
    {
        "col1": "Самарская область",
        "col2": 1,
        "col3": "Департамент управления имуществом городского округа Самара",
        "col4": "443099, г. Самара, ул. Куйбышева, д. 44, 8(846)332 24 49"
    },
    {
        "col1": "Самарская область",
        "col2": 2,
        "col3": "Администрация городского округа Тольятти",
        "col4": "г.Тольятти, ул.Белорусская, 33, каб.912, dumi@tgl.ru, 54-37-00"
    },
    {
        "col1": "Самарская область",
        "col2": 3,
        "col3": "Жилищный отдел КЖКХ Администрации г.о. Сызрань",
        "col4": "Самарская область, г. Сызрань, ул. Ульяновская, д.61,каб.2, jil_otdel@bk.ru, 8 (846 4) 98-66-53"
    },
    {
        "col1": "Самарская область",
        "col2": 4,
        "col3": "Департамент городского хозяйства администрации городского округа Новокуйбышевск",
        "col4": "г. Новокуйбышевск, ул. Миронова, д. 2, adm@nvkb.ru, 88463563386"
    },
    {
        "col1": "Самарская область",
        "col2": 5,
        "col3": "МКУ \"Комитет социального развития, семьи, опеки и попечительства администрации городского округа Чапаевск\"",
        "col4": "г. Чапаевск, ул. Куйбышева, 12А, KSMID@yandex.ru, 8(84639)24347"
    },
    {
        "col1": "Самарская область",
        "col2": 6,
        "col3": "Администрация городского округа Отрадный Самарской области",
        "col4": "г. Отрадный, ул.Отрадная, д.15, komitet_otr@mail.ru, 8(84661)21275"
    },
    {
        "col1": "Самарская область",
        "col2": 7,
        "col3": "Отдел по жилищным вопросам комитета по управлению муниципальным имуществом администрации городского округа Жигулевск",
        "col4": "445350, г. Жигулевск, ул. Пушкина, д. 17, Poliynskova.Irina@yandeх.ru; zhilotd@admzhg.ru, 8(84862)2-48-93, 2-42-31"
    },
    {
        "col1": "Самарская область",
        "col2": 8,
        "col3": "Служба по учету и распределению жилья Администрации г.о.Октябрьск",
        "col4": "г.Октябрьск, ул.Ленина, 54, oktyabrsk-go@mail.ru, 8(84646)21576"
    },
    {
        "col1": "Самарская область",
        "col2": 9,
        "col3": "Администрация городского округа Кинель Самарской области ",
        "col4": "г. Кинель, ул. Мира, д.42а, kumikinel@mail.ru, 8(84663) 6-17-78"
    },
    {
        "col1": "Самарская область",
        "col2": 10,
        "col3": "Администрация городского округа Похвистнево Самарской области",
        "col4": "446450, Самарская обл., г. Похвистнево, ул. Лермонтова, 16, pohgor@samtel.ru, 8(84656)25812 8(84656)23466 "
    },
    {
        "col1": "Самарская область",
        "col2": 11,
        "col3": "Муниципальное казенное учреждение «Комитет по управлению муниципальным имуществом Администрации муниципального района Алексеевский Самарской области» ",
        "col4": "Самарская область, Алексеевский район, с.Алексеевка, ул.Советская,7, al_kumi@mail.ru, 8(84671)22377"
    },
    {
        "col1": "Самарская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальным имуществом администрации муниципального района Безенчукский Самарской области",
        "col4": "Самарская обл, Безенчукский р-н, пгт Безенчук, ул Нефтяников, д 11, kumibez@mail.ru, 88467623308"
    },
    {
        "col1": "Самарская область",
        "col2": 13,
        "col3": "Отдел по управлению муниципальным имуществом Администрации муниципального района Богатовский",
        "col4": "с.Богатое, ул. Комсомольская, д.13, kumi.bogatoe@yandex.ru, 8(84666)2-16-65"
    },
    {
        "col1": "Самарская область",
        "col2": 14,
        "col3": "Отдел по управлению муниципальным имуществом Большеглушицкого района",
        "col4": "Самарская область, Большеглушицкий район, с. Большая Глушица, ул. Гагарина, д. 91, bg@admbg.org, 8-84673-21103"
    },
    {
        "col1": "Самарская область",
        "col2": 15,
        "col3": "Администрация Большечерниговского района Самарской области",
        "col4": "446290,Самарская область, Большечерниговский район, с. Большая Черниговка, ул. Полевая,77, adm-chernig@mail.ru, +7(84672)2-11-38"
    },
    {
        "col1": "Самарская область",
        "col2": 16,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имуществом администрации муниципального района Борский Самарской области\"",
        "col4": "Самарская область  Борский район с.Борское  ул.Октябрьская, д. 57, kumi-bor@yandex.ru, 8(84667)1862"
    },
    {
        "col1": "Самарская область",
        "col2": 17,
        "col3": "Администрация муниципального района Волжский Самарской области",
        "col4": "443045, г. Самара, ул. Дыбенко, д. 12 Б, каб. 110, vr@v-adm63.ru, 8 846 260 33 35"
    },
    {
        "col1": "Самарская область",
        "col2": 18,
        "col3": "Комитет по управлению муниципальной собственностью муниципального района Елховский Самарской области",
        "col4": "Самарская область, Елховский район, с. Елховка, ул. М.Заводского, д. 41, kums-elh@yandex.ru, 88465833637"
    },
    {
        "col1": "Самарская область",
        "col2": 19,
        "col3": "Комитет по управлению муниципальным имуществом Администрации м.р.Исаклинский",
        "col4": "с.Исаклы, ул.Куйбышевская, 75А, kumiisakly@mail.ru, (84654)2-14-12"
    },
    {
        "col1": "Самарская область",
        "col2": 20,
        "col3": "Комитет по управлению муниципальным имуществом Администрации муниципального района Камышлинский",
        "col4": "Самарская область, Камышлинский р-н, с.Камышла, ул.Победы 80, kumiasiya@mail.ru, 88466433247,     88466433683,"
    },
    {
        "col1": "Самарская область",
        "col2": 21,
        "col3": "Администрация муниципального района Кинельский Самарской области",
        "col4": "446433, Самарская область, г. Кинель, ул. Ленина, 36, radmin@kinel.ru, tishina@kinel.ru, 88466321176, 884663 21145"
    },
    {
        "col1": "Самарская область",
        "col2": 22,
        "col3": "Отдел по жилищной политике комитета по управлению имуществом Кинель-Черкасского района",
        "col4": "446350, Самарская область, Кинель-Черкасский район, с. Кинель-Черкассы, ул. Красноармейская,69., jil_otdel@bk.ru, 88466041175"
    },
    {
        "col1": "Самарская область",
        "col2": 23,
        "col3": "Администрация муниципального района Клявлинский ",
        "col4": "Самарская область, Клявлинский район, ст.Клявлино, ул.Октябрьская, 60, priemnaia2012@yandex.ru, 8(846 53)2-15-05"
    },
    {
        "col1": "Самарская область",
        "col2": 24,
        "col3": "Комитет управления муниципальным имуществом администрации муниципального района Кошкинский Самарской области",
        "col4": "Самарская обл., Кошкинский р-н, с. Кошки, ул. Советская, д. 32, kumikoshki@yandex.ru, 8(84650)2-34-52"
    },
    {
        "col1": "Самарская область",
        "col2": 25,
        "col3": "Администрация муниципального района Красноармейский Самарской области",
        "col4": "446140,Самарская область, Красноармейский район, с. Красноармейское, пл. Центральная, д. 12, KUMIKR@yandex.ru, 8(846)7521095"
    },
    {
        "col1": "Самарская область",
        "col2": 26,
        "col3": "Управление опеки и попечительства администрации м.р.Красноярский Самарской области ",
        "col4": "Самарская область, Красноярский район, с.Красный Яр, ул. Комсомольская, д.92 А, semya.kryar@yandex.ru, 8 (846 57)2-02-30"
    },
    {
        "col1": "Самарская область",
        "col2": 27,
        "col3": "Администрация муниципального района Нефтегорский",
        "col4": "Самарская обл., г. Нефтегорск, ул.Ленина, 2, neftadm@yandex.ru, 88467021543, 88467022108"
    },
    {
        "col1": "Самарская область",
        "col2": 28,
        "col3": "Администрация муниципального района Пестравский Самарской области",
        "col4": "Самарская область, Пестравский район, село Пестравка, ул. Крайнюковская, д. 84, yapestravsky@yandex.ru, 8(84674) 21233"
    },
    {
        "col1": "Самарская область",
        "col2": 29,
        "col3": "Комитет по управлению муниципальным имуществом",
        "col4": "446450, Самарская область, г. Похвистнево, ул. Ленинградская, д.9, pohr_kumi@mail.ru, 88465621248"
    },
    {
        "col1": "Самарская область",
        "col2": 30,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имущством администрации муниципального района Приволжский Самарской области\"",
        "col4": "Самарская область, Приволжский район, с. Приволжье, ул. Парковая, д. 14, mkukumi.pv@yandex.ru, 8(846 47)92273"
    },
    {
        "col1": "Самарская область",
        "col2": 31,
        "col3": "Жилищное управление администрации муниципального района Сергиевский Самарской области",
        "col4": "446540, Самарская область, Сергиевский район, с. Сергиевск, ул. Ленина, д.15А, каб. 3,4, got_adm@mail.ru, (884655)22337, (884655)21545"
    },
    {
        "col1": "Самарская область",
        "col2": 32,
        "col3": "МКУ \"Комитет по управлению муницпальным имуществом администрации муницпального района Ставролький Самарской области\"",
        "col4": "Самарская область, город Тольятти, пл.Свободы,9, stavr-ur@mail.ru, stavr-kumi@mail.ru, 8(8482)283310, 8(8482)281567"
    },
    {
        "col1": "Самарская область",
        "col2": 33,
        "col3": "МКУ \"Управление социального развития администрации Сызранского района\"",
        "col4": "Самарская обл., Сызранский район, п. Варламово, ул. Советская, д. 14, ussofis.varlamovo@yandex.ru , 8(8464)998256"
    },
    {
        "col1": "Самарская область",
        "col2": 34,
        "col3": "Муниципальное казенное учреждение Комитет по управлению муниципальным имуществом муниципального района Хворостянский Самарской области",
        "col4": "Самарская область, Хворостянский район, с. Хворостянка, пл. Плясункова, д. 10, каб. 107, hvor-kymi@yandex.ru , 8(84677)91278"
    },
    {
        "col1": "Самарская область",
        "col2": 35,
        "col3": "МКУ Комитет по управлению муниципальным имуществом администрация муниципального района Челно-Вершинский",
        "col4": "446840, Самарская область, Челно-Вершинский район, с. Челно-Вершины, ул. Советская, д. 12, kumi.chv@mail.ru, 88465121475"
    },
    {
        "col1": "Самарская область",
        "col2": 36,
        "col3": "Комитет по управлению имуществом, архитектуры, капитального строительства и жилищно-коммунального хозяйства администрации муниципального района Шенталинский",
        "col4": "446910, Самарская область, Шенталинский район, ж/д ст. Шентала, ул. Советская, д.33, ivanov@shentala.su, 8 (846)52-2-15-36"
    },
    {
        "col1": "Самарская область",
        "col2": 37,
        "col3": "Администрация муниципального района Шигонский Самарской области ",
        "col4": "Самарская область, с. Шигоны, пл. Ленина 1, adm@shigony.samregion.ru, (884648)21556"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 1,
        "col3": "Администрация Адмиралтейского района Санкт-Петербурга",
        "col4": "Измайловский пр., д. 10,\nСанкт-Петербург, 190005\nhouse2@tuadm.gov.spb.ru\n(812) 576-83-89"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 2,
        "col3": "Администрация Василеостровского\nрайона Санкт-Петербурга\n",
        "col4": "Большой пр. В.О., д. 55,      Санкт-Петербург, 199178,\ntuvo@gov.spb.ru\n(812)576-93-78\n(812)573-93-35"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 3,
        "col3": "Администрация  Выборгского района Санкт-Петербурга ",
        "col4": "пр. Большой Сампсониевский, д.88,\nСанкт-Петербург, 194100\nzhil@tuvyb.gov.spb.ru\n(812) 241-49-85,                  (812) 576-56-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 4,
        "col3": "Администрация Калининского района Санкт-Петербурга ",
        "col4": "Арсенальная наб., д.13/1, \nСанкт-Петербург, 195009\ntukalin@gov.spb.ru               (812) 417-47-46, \n(812) 417-47-47"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 5,
        "col3": "Администрация Кировского района Санкт-Петербурга\n",
        "col4": "Стачек пр., д. 18,                           Санкт-Петербург, 198095 tukir@gov.spb.ru                          bordak@tukir.gov.spb.ru\n(812) 252-30-17\n(812) 417-69-04"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 6,
        "col3": "Администрация Колпинского района Санкт-Петербурга",
        "col4": "б-р Победы, д. 1,  г. Колпино,   Санкт-Петербург, 196653\nkaa@tukolp.gov.spb.ru, zia@tukolp.gov.spb.ru, \n(812) 573-92-05,  (812) 573-92-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 7,
        "col3": "Администрация Красногвардейского района Санкт-Петербурга",
        "col4": "Среднеохтинский пр., д.50, \nСанкт-Петербург, 195027 \n tukrgv@gov.spb.ru\n(812) 576-87-50"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 8,
        "col3": "Администрация Красносельского района\nСанкт-Петербурга",
        "col4": "ул. Партизана Германа, д. 3, Санкт-Петербург, 198329\nsevastvanova@tukrsl. gov.spb.ru\n(812) 576-14-38"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 9,
        "col3": "Администрация Кронштадтского района\nСанкт-Петербурга",
        "col4": "пр. Ленина, д. 36,  г. Кронштадт,  Санкт-Петербург, 197760    Zhil1@tukrns.gov.spb.ru   zhiln@tukrns.gov.spb.ru       (812) 576-90-07   (812) 576-90-28"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 10,
        "col3": "Администрация  Курортного района  Санкт-Петербурга ",
        "col4": "пл.Свободы, д.1, г.Сестрорецк,                   Санкт-Петербург, 197706    tukur@gov.spb.ru                (812)576-81-06 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 11,
        "col3": "Администрация Московского района Санкт-Петербурга",
        "col4": "Московский пр., д.129,       Санкт-Петербург, 196006 \ndilyara@tumos.gov.spb.ru\n(812) 576-89-01"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 12,
        "col3": "Администрация Невского района Санкт-Петербурга",
        "col4": "пр. Обуховской\nОбороны, д. 163,                 Санкт-Петербург, 192131\nflat@tunev.gov.spb.ru\n(812) 417-39-62                    (812) 417-39-64"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 13,
        "col3": "Администрация Петроградского района Санкт-Петербурга, ",
        "col4": "ул. Большая Монетная, д. 17-19, Санкт-Петербург, 197101\ntupetr@gov.spb.ru \n(812) 576-50-54  \n (812) 576-50-39"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 14,
        "col3": "Администрация Петродворцового района Санкт-Петербурга",
        "col4": "ул. Калининская, д.7,          г. Петергоф,                        Санкт-Петербург, 198510 \ntuptrdv@gov.spb.ru\n(812) 576-95-61                   (812) 576-95-65 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 15,
        "col3": "Администрация Приморского района Санкт-Петербурга",
        "col4": "аллея Котельникова, д.2а,                  Санкт-Петербург, 197341  \nrozanov@tuprim.gov.spb.ru\n(812)241-56-32"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 16,
        "col3": "Администрация Пушкинского района Санкт-Петербурга, \n",
        "col4": "Октябрьский бул., д. 24,      г. Пушкин,                      Санкт-Петербург, 196601\nios@tupush.gov.spb.ru\njo@tupush.gov.spb.ru\n(812) 576-92-47  (812) 576-92-15"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 17,
        "col3": "Аадминистрация Фрунзенского района Санкт-Петербурга",
        "col4": "ул. Пражская, д. 46,            Санкт-Петербург, 192241 \nisakova@tufruns.gov.spb.ru\n(812) 576-85-53"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 18,
        "col3": "Администрация Центрального района Санкт-Петербурга",
        "col4": " Невский пр., д. 176,           Санкт-Петербург, 191167,\nmishin@tucentr.gov.spb.ru   (812) 417-45-93"
    },
    {
        "col1": "Саратовская область",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Саратовской области",
        "col4": "410012, г. Саратов, ул. Челюскинцев, 114\nminstroy@saratov.gov.ru\n8(8452)74-00-47\nКонсультационный центр для детей-сирот 8(8452)74-00-47"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Управление социальной политики Александровск-Сахалинского муниципального округа",
        "col4": "694420, Сахалинская область, г. Александровск-Сахалинский, ул. Ленина, д. 4, тел.: 8 (42434) 4-34-55, e-mail: opeka_Alex@bk.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Администрация Анивского муниципального округа ",
        "col4": "694030, Сахалинская область, Анивский район, г. Анива, ул. Калинина, д. 57, тел.: 8 (42441) 42059,   e-mail: aniva@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства администрации муниципального образования Долинский муниципальный округ",
        "col4": "694051, Сахалинская область, г. Долинск, ул. Комсомольская, д. 37, e-mail: dolinsk@sakhalin.gov.ru, тел.: 8 (42442) 2-68-22\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Отдел опеки и попечительства департамента социального развития администрации \nКорсаковского муниципального округа\n",
        "col4": "694020, Сахалинская область, г. Корсаков, ул. Советская, д. 41,  e-mail: opeka_akgo@sakhalin.gov.ru, тел.: 8 (42435) 4-05-41 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация Курильского муниципального округа",
        "col4": "694530, Сахалинская область, г. Курильск, ул. Приморское шоссе, д. 5/1,\nтел.: (42454) 42467, e-mail: kurilsk@sakhalin.gov.ru \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация Макаровского муниципального образования ",
        "col4": "694140, Сахалинская обл., г. Макаров, ул. 50 лет Октября, д. 32, тел.: 8 (42443) 9-01-48, e-mail:  makarov@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": "Отдел образования администрации Невельского муниципального округа",
        "col4": "694740, Сахалинская область, г. Невельск, ул. Советская, д. 55, тел.: 8 (42436) 6-10-18, 8 (42436) 6-02-45, e-mail: y.petrova@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Департамент социальной политики администрации муниципального образования Ногликский муниципальный округ ",
        "col4": "694450, Сахалинская область, пгт. Ноглики, ул. Советская, д. 15, e-mail: opeka@nogliki-adm.ru, тел.: 8 (42444) 9-10-58  \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Департамент социального развития администрации Охинского муниципального округа \n",
        "col4": "694490, Сахалинская область, г. Оха, ул. Ленина, д. 8, e-mail: uprobrsek@sakhalin.gov.ru, okhaopeka@mail.ru, тел.: 8 (42437) 3-06-14\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Жилищный отдел Администрации Поронайского муниципального округа ",
        "col4": "694240, Сахалинская область, г. Поронайск, ул. Октябрьская, д. 61А, e-mail: poronayisk@list.ru,  тел.: 8 (42431) 5-60-03 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Комитет по управлению муниципальной собственностью  Северо-Курильского муниципального округа",
        "col4": "694550, Сахалинская область, г. Северо-Курильск, ул. Сахалинская, д. 34 А, e-mail: kums-sk@yandex.ru, тел.: 8 (42453) 2-10-90 \n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Управление образования администрации Смирныховского муниципального округа ",
        "col4": "694350, Сахалинская область, пгт. Смирных, ул. Маяковского, д. 7, тел.: 8 (42452) 42-8-68, e-mail: opeka_smirnih@mail.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Администрация Томаринского муниципального округа\n\n",
        "col4": "694820, Сахалинская область, г. Томари, ул, Калинина, д. 49 А, e-mail: tomari@adm-tomari.ru, тел.: 8 (42446) 2-67-66\n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Управление образования Тымовского муниципального округа ",
        "col4": "694400, Сахалинская область,Тымовский район, пгт. Тымовское, ул. Парковая, д. 9, \ne-mail: tymovsk-uo@sakhalin.gov.ru, тел.: 8 (42447) 9-10-77"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Управление образования Углегорского муниципального округа \n\n",
        "col4": "694920, Сахалинская область, г. Углегорск, ул. Победы, д. 142, тел.: (42432) 44-5-16, e-mail: ugo.uougo@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства администрации Холмского муниципального округа ",
        "col4": "694620, Сахалинская область, г. Холмск, ул. Советская, д. 66, e-mail: kholmsk.oop@sakhalih.gov.ru, тел.: 8(42433)2-16-89 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Департамент по управлению муниципальной собственностью Южно-Курильского  муниципального округа ",
        "col4": "694500, Сахалинская область, пгт. Южно-Курильск, пл. Ленина, д. 1, e-mail: kums.adm@mail.ru, тел.: 8 (42424) 9-60-01\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Департамент образования администрации города Южно-Сахалинска",
        "col4": "693000, г. Южно-Сахалинск, ул. Амурская, д. 88, e-mail: yusgo.do@sakhalin.gov.ru, тел.: 8 (4242) 3-12-680"
    },
    {
        "col1": "Свердловская область",
        "col2": 1,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 1 (на территории муниципального округа муниципального образования Алапаевское Свердловской области, Муниципального округа Муниципального образования город Алапаевск Свердловской области, муниципального округа Махнёвское муниципальное образование Свердловской области) \n(далее – Управление социальной политики № 1)",
        "col4": "624600, г. Алапаевск, ул. Береговая, 44 / tusp01@egov66.ru / 8 (34346) 2-61-68"
    },
    {
        "col1": "Свердловская область",
        "col2": 2,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 2 (на территории Режевского муниципального округа Свердловской области, Артемовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 2)",
        "col4": "623750, г. Реж, ул. Бажова, 15, оф. 16 / tusp02@egov66.ru / 8 (34364) 3-52-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 3,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 3 (на территории городского округа Красноуфимск Свердловской области, Красноуфимского муниципального округа Свердловской области, Ачитского муниципального округа Свердловской области, Артинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 3)",
        "col4": "623300, г. Красноуфимск, ул. Берёзовая, 12 / tusp03@egov66.ru / 8 (34394) 5-21-84"
    },
    {
        "col1": "Свердловская область",
        "col2": 4,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 4 (на территории Нижнесергинского муниципального района Свердловской области, Бисертского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 4)",
        "col4": "623090, г. Нижние Серги, ул. Федотова, 17 / tusp04@egov66.ru / 8 (34398) 27-2-06"
    },
    {
        "col1": "Свердловская область",
        "col2": 5,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 5 (на территории муниципального округа Первоуральск Свердловской области, муниципального округ Ревда Свердловской области, Шалинского муниципального округа Свердловской области, муниципального округа Дегтярск Свердловской области, муниципального округа Староуткинск Свердловской области) \n(далее – Управление социальной политики № 5)",
        "col4": "623100, г. Первоуральск, ул. 1 Мая, 8А / tusp05@egov66.ru / 8 (34396) 4-74-34"
    },
    {
        "col1": "Свердловская область",
        "col2": 6,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 6 (на территории Городского округа «город Ирбит» Свердловской области, муниципального округа Ирбитское муниципальное образование Свердловской области, Байкаловского муниципального района Свердловской области, Слободо–Туринского муниципального района Свердловской области) \n(далее – Управление социальной политики № 6)",
        "col4": "623850, г. Ирбит, ул. Красноармейская, 15 / tusp06@egov66.ru / 8 (34355) 6-41-85"
    },
    {
        "col1": "Свердловская область",
        "col2": 7,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 7 (на территории Тавдинского муниципального округа Свердловской области, Таборинского муниципального района Свердловской области, Туринского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 7)",
        "col4": "623950, г. Тавда, ул. Ленина, 78 А / tusp07@egov66.ru / 8 (34360) 5-26-26"
    },
    {
        "col1": "Свердловская область",
        "col2": 8,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 8 (на территории Камышловского городского округа Свердловской области, Камышловского муниципального района Свердловской области, Пышминского муниципального округа Свердловской области) \n(далее – Управление социальной политики  № 8)",
        "col4": "624860, г. Камышлов, ул. Гагарина, 1-А / tusp08@egov66.ru / 8 (34375) 2-04-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 9,
        "col3": "9) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 9 (на территории Талицкого муниципального округа Свердловской области, Тугулымского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 9)",
        "col4": "623640, г. Талица, ул. Ленина, д. 71 / tusp09@egov66.ru / 8 (34371) 2-19-78"
    },
    {
        "col1": "Свердловская область",
        "col2": 10,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 10 (на территории Асбестовского муниципального округа Свердловской области, муниципального округа Заречный Свердловской области, Белоярского муниципального округа Свердловской области, городского округа Верхнее Дуброво Свердловской области, городского округа Рефтинский Свердловской области, городского округа ЗАТО Уральский Свердловской области, Малышевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 10)",
        "col4": "624272, г. Асбест, ул. Московская, 30 / tusp10@egov66.ru / 8 (34365) 2-06-18"
    },
    {
        "col1": "Свердловская область",
        "col2": 11,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 11 (на территории муниципального округа Сухой Лог Свердловской области, муниципального образования муниципального округа Богданович Свердловской области) \n(далее – Управление социальной политики № 11)",
        "col4": "624800, г. Сухой Лог, ул. Юбилейная, 15 / tusp11@egov66.ru / 8 (34373) 4-36-02"
    },
    {
        "col1": "Свердловская область",
        "col2": 12,
        "col3": "12) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 12 (на территории Каменск–Уральского городского округа Свердловской области, Каменского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 12)",
        "col4": "623406, г. Каменск-Уральский, ул. Строителей, д. 27 / tusp12@egov66.ru / 8 (34393) 5-33-31"
    },
    {
        "col1": "Свердловская область",
        "col2": 13,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 13 (на территории Невьянского муниципального округа Свердловской области, Кировградского муниципального округа Свердловской области, муниципального округа Верхний Тагил Свердловской области) (далее – Управление социальной политики № 13)",
        "col4": "624194, г. Невьянск, ул. Ленина, 20 / tusp13@egov66.ru / 8 (34356) 40-7-40"
    },
    {
        "col1": "Свердловская область",
        "col2": 14,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 14 (на территории Новолялинского муниципального округа Свердловской области, муниципального округа Верхотурский Свердловской области) (далее – Управление социальной политики № 14)",
        "col4": "624400, г. Новая Ляля, ул. Уральская, 2а / tusp14@egov66.ru / 8 (34388) 2-13-79"
    },
    {
        "col1": "Свердловская область",
        "col2": 15,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 15 (на территории муниципального округа Краснотурьинск Свердловской области, Волчанского муниципального округа Свердловской области, муниципального округа Карпинск Свердловской области) (далее – Управление социальной политики № 15)",
        "col4": "624440, г. Краснотурьинск, ул. К. Маркса, 24 / tusp15@egov66.ru / 8 (34384) 6-55-60"
    },
    {
        "col1": "Свердловская область",
        "col2": 16,
        "col3": "16) Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 16 (на территории Кушвинского муниципального округа Свердловской области, муниципального округа Красноуральск Свердловской области, Городского округа Верхняя Тура Свердловской области) \n(далее – Управление социальной политики № 16)",
        "col4": "624300, г. Кушва, ул. Красноармейская, 16 / tusp16@egov66.ru / 8 (34344) 2-57-57"
    },
    {
        "col1": "Свердловская область",
        "col2": 17,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 17 (на территории городского округа «Город Лесной» Свердловской области, Нижнетуринского муниципального округа Свердловской области, Качканарского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 17)",
        "col4": "624200, г. Лесной, ул. К. Маркса, 8 / tusp17@egov66.ru / 8 (34342) 6-87-28"
    },
    {
        "col1": "Свердловская область",
        "col2": 18,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 18 (на территории Североуральского муниципального округа Свердловской области, Ивдельского муниципального округа Свердловской области, муниципального округа Пелым Свердловской области) \n(далее – Управление социальной политики № 18)",
        "col4": "624480, г. Североуральск, ул. Молодёжная, 15 / tusp18@egov66.ru / 8 (34380) 2-79-01"
    },
    {
        "col1": "Свердловская область",
        "col2": 19,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 19 (на территории Серовского муниципального округа Свердловской области, Гаринского муниципального округа Свердловской области, Сосьвинского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 19)",
        "col4": "624992, г. Серов, ул. Победы, 32 / tusp19@egov66.ru / 8 (34385) 7-22-16"
    },
    {
        "col1": "Свердловская область",
        "col2": 20,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 20 (на территории Новоуральского городского округа Свердловской области, городского округа Верх–Нейвинский Свердловской области) \n(далее – Управление социальной политики № 20)",
        "col4": "624130, г. Новоуральск, ул. Гагарина, д. 7А / tusp20@egov66.ru / 8 (34370) 5-40-70"
    },
    {
        "col1": "Свердловская область",
        "col2": 21,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 21 (на территории муниципального округа город Нижний Тагил Свердловской области, муниципального округа Горноуральский Свердловской области) \n(далее – Управление социальной политики № 21)",
        "col4": "622034, г. Нижний Тагил, ул. Карла Маркса, 42 / tusp21@egov66.ru / 8 (3435) 41-92-61"
    },
    {
        "col1": "Свердловская область",
        "col2": 22,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 22 (на территории Верхнесалдинского муниципального округа Свердловской области, городского округа ЗАТО Свободный Свердловской области, муниципального округа Нижняя Салда Свердловской области) \n(далее – Управление социальной политики № 22)",
        "col4": "624760, г. Верхняя Салда, ул. Воронова 6/1 / tusp22@egov66.ru / 8 (34345) 5-25-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 23,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 23 (на территории Орджоникидзевского района городского округа муниципального образования «город Екатеринбург», городского округа Верхняя Пышма Свердловской области, муниципального округа Среднеуральск Свердловской области) (далее – Управление социальной политики № 23)",
        "col4": "620091, г. Екатеринбург, ул. Бабушкина, 22 / tusp23@egov66.ru / 8 (343) 349-38-22"
    },
    {
        "col1": "Свердловская область",
        "col2": 24,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 24 (на территории Кировского района городского округа муниципального образования «город Екатеринбург», Березовского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 24)",
        "col4": "620062, г. Екатеринбург, ул. Генеральская, 6 / tusp24@egov66.ru / 8 (343) 301-66-77"
    },
    {
        "col1": "Свердловская область",
        "col2": 25,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 25 (на территории Чкаловского района городского округа муниципального образования «город Екатеринбург», Сысертского муниципального округа Свердловской области, Полевского муниципального округа Свердловской области) \n(далее – Управление социальной политики № 25)",
        "col4": "620085, г. Екатеринбург, пер. Автомобильный, 3 / tusp25@egov66.ru / 8 (343) 310-28-08"
    },
    {
        "col1": "Свердловская область",
        "col2": 26,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 26 (на территории Верх-Исетского и Железнодорожного районов городского округа муниципального образования «город Екатеринбург»)\n(далее – Управление социальной политики № 26)",
        "col4": "620014, г. Екатеринбург, ул. Хомякова, 14а / tusp26@egov66.ru / 8 (343) 381-85-80"
    },
    {
        "col1": "Свердловская область",
        "col2": 27,
        "col3": "Территориальный отраслевой исполнительный орган государственной власти Свердловской области – Управление социальной политики Министерства социальной политики Свердловской области № 27 (на территории Ленинского и Октябрьского районов городского округа муниципального образования «город Екатеринбург») \n(далее – Управление социальной политики № 27)",
        "col4": "620014, г. Екатеринбург, ул. Шейнкмана, 22 / tusp27@egov66.ru / 8 (343) 227-85-86"
    },
    {
        "col1": "Севастополь",
        "col2": 1,
        "col3": "Департамент образования и науки города Севастополя",
        "col4": "г. Севастополь, ул. Щербака, 10, 299011 тел. +7 (8692) 40-41-20, эл. почта: uzn.do@gs.sev.gov.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 1,
        "col3": "Управление образования Администрнациии муниципального образования \"Сафоновский муниципальный округ\" Смоленской области",
        "col4": "215500, Смоленская область, г. Сафоново, ул. Ленина, д. 3  т. 8(48142)4-10-06"
    },
    {
        "col1": "Смоленская область",
        "col2": 2,
        "col3": "Администрация муниципального образования \"Темкинский муниципальный округ\" Смоленской области",
        "col4": "215350, Смоленская область, с.Темкино, ул.Советск4ая, д.27, Temkino_OBR@admin-smolensk.ru,               8(48136)2-14-07"
    },
    {
        "col1": "Смоленская область",
        "col2": 3,
        "col3": "Администрация муниципального образования \"Шумячский муниципальный округ\" Смоленской области",
        "col4": "216410 Смоленская область, п.Шумячи, ул.Школьна,д.1  shumichi@admin-smolensk.ru  4-12-65 "
    },
    {
        "col1": "Смоленская область",
        "col2": 4,
        "col3": "Администрация муниципального образования \"Ельнинский муниципальный округ\" Смоленской области",
        "col4": "216330 Смоленская область, г. Ельня, ул. Советская, д. 23; e-mail admin_elnia@admin-smolensk.ru; тел. (8 481 46) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 5,
        "col3": "Администрация муниципального образования \"Новодугинский мунициплаьный округ\" Смоленской области",
        "col4": "215240, Смоленская область, Новодугинский район, с. Новодугино, ул. 30 лет Победы, д. 2     amo-novodug@yandex.ru   (8 481 38)  2-20-31"
    },
    {
        "col1": "Смоленская область",
        "col2": 6,
        "col3": "Администрация муниципального образования \"Сычевский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г. Сычевка, ул. Пушкина, д.25,\nSychevka.opeka@gmail.com                                   8(48130)4-12-77\n"
    },
    {
        "col1": "Смоленская область",
        "col2": 7,
        "col3": " Администрация муниципального образования \"Велижский муниципальный округ\" Смоленской области",
        "col4": " г.Велиж, пл. Дзержинского, д.7, тел.4-15-33 эл. Почта:velizh@admin-smolensk,ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 8,
        "col3": "Администрация муниципального образования \"Ершичский муниципальный округ\" Смоленской области",
        "col4": "216580, Смоленская область, с. Ершичи, ул. Советская, д.22, тел. 8(48155) 2-16-60, эл.почта <ershadm@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 9,
        "col3": "Комитет территориального развития, строительства и жилищно-коммунального хозяйства Администрации муниципального образования \"Глинковский муниципальный округ\" Смоленской области",
        "col4": "216320, Смоленская область, Глинковский район, с. Глинка, ул. Ленина, дл. 8, glinka@admin-smolensk.ru 8(481-65) 2-13-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 10,
        "col3": "Управление опеки и попечительства Администрации города Смоленска",
        "col4": "214000, г. Смоленск, ул. Дзержинского, д. 9/ (84812)388930,(84812)383701/ uop@smoladmin.ru,  ssv@smoladmin.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 11,
        "col3": "Администрация муниципального образования \"Угранский муниципальный округ\" Смоленской области",
        "col4": "215430, Смоленская область, село Угра, ул. Ленина, д. 38 ugra@admin-smolensk.ru? 8(48137)4-12-90"
    },
    {
        "col1": "Смоленская область",
        "col2": 12,
        "col3": "Администрация МО \"Духовщинский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, город Духовщина, улица М.Горького, 23, 84816641707, duhovshina.opeka@yandex.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 13,
        "col3": "Администрация муниципального образования \"Хиславичский муниципальный округ\" Смоленской области",
        "col4": "216620, Смоленская область, п. Хиславичи, ул. Советская, д. 23, адрес электронной почты: hislav@admin-smolensk.ru, тел. 8(48140) 2-20-20"
    },
    {
        "col1": "Смоленская область",
        "col2": 14,
        "col3": "Администрация муниципального образования \"Починковский муниципальный округ\" Смоленской области",
        "col4": "216450, Смоленская область,   г. Починок, ул. Советская,        д. 1/potch@admin-smolensk.ru/  8 (48149)4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 15,
        "col3": "Администрация муниципального образования \"Гагаринский муниципальный округ\" Смоленской области",
        "col4": "215010, Смоленская область, г. Гагарин, ул. Советская, д. 8; e-mail:gagarin-komitet@mail.ru, opeka.gagarin@yandex.ru; конт. тел. 8(48135)6-40-61, 6-40-68"
    },
    {
        "col1": "Смоленская область",
        "col2": 16,
        "col3": " Администрация муниципального образования \"Ярцевский муниципальный округ\" Смоленской области",
        "col4": "8-48143-7-10-30 opeka-yarcevo@mail.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 17,
        "col3": "Администрация МО  \"Демидовский муниципальный округ \" Смоленской области\n ",
        "col4": "216240, Смоленская область, г.Демидов, ул.Коммунистическая, д.10,  https: demidov@admin-smolensk.ru.                     Т.(48147) 4-11-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 18,
        "col3": "Администрация МО «город Десногорск» Смоленской области  ",
        "col4": "г. Десногорск, 2 мкр., стр. 1/ эл. почта: kiizo@yandex.ru/ 8-48153-7-44-60, 848153-7-23-08"
    },
    {
        "col1": "Смоленская область",
        "col2": 19,
        "col3": "Администрация МО \"Монастырщинский муниципальный округ\" Смоленской области",
        "col4": "216130, Смоленская область, п. Монастырщина, ул. 1-я Краснинская, д. 14.              E-mail: monast@admin-smolensk.ru, т. 8 (48148) 4-18-44"
    },
    {
        "col1": "Смоленская область",
        "col2": 20,
        "col3": "Администрация МО \"Краснинский муниципальный округ",
        "col4": "пгт. Красный,ул. Карла-Маркса, д.16,krasniy@admin-smolensk.ru, 84814541449"
    },
    {
        "col1": "Смоленская область",
        "col2": 21,
        "col3": "Администрация МО \"Дорогобужский муниципальный округ\"",
        "col4": "г. Дорогобуж, ул. Кутузова, д.1  zemdor@mail.ru                    8 48144 41369"
    },
    {
        "col1": "Смоленская область",
        "col2": 22,
        "col3": "Администрация МО \"Вяземский муниципальный округ\" Смоленской области",
        "col4": " 215110,  Смоленская область, г. Вязьма, ул. 25 Октября, д. 11, 8 (48131) 4–11–50, office@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 23,
        "col3": "Администрации МО \"Смоленский муниципальный округ\" Смоленской области",
        "col4": "тел./факс  8 (4812) 55-53-89\nе-mail: smolray@admin-smolensk.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 24,
        "col3": "Администрация МО \"Кардымовский муниципальный округ\" Смоленской области",
        "col4": "215850, Смоленская область, пгт. Кардымово, ул. Красноармейская, д.  7/ roo@kardymovo.ru/ 8 (48167) 4-21-56"
    },
    {
        "col1": "Смоленская область",
        "col2": 25,
        "col3": "Администрация МО \"Рославльский муниципальный округ\" Смоленской области ",
        "col4": "216500, Смоленская область, г. Рославль, площадь Ленина, д. 1, 8-48134-6-84-52, administrator@roslavl.ru"
    },
    {
        "col1": "Смоленская область",
        "col2": 26,
        "col3": "Отдел по образованию Администрации МО  \"Холм-Жирковский муниципальный округ\" Смоленской области",
        "col4": "215650\nСмоленская область, п.г.т.Холм-Жирковский , ул. Нахимовская, д.9\nE-mail: holm_otdel@mail.ru\nтел./факс:  8 (48139) 2-14-67"
    },
    {
        "col1": "Смоленская область",
        "col2": 27,
        "col3": "Администрация муниципального образования \"Руднянский муниципальный округ\" Смоленской области в лице Отдела по экономике, управлению муниципальным имуществом и земельным отношениям",
        "col4": "Смоленская область, г.Рудня, ул.Киреева, д.93; rud_ekonomika@admin-smolensk.ru; Телефон: (48141) 4-25-69"
    },
    {
        "col1": "Ставропольский край",
        "col2": 1,
        "col3": "Министерство образования Ставропольского края",
        "col4": "355003, г. Ставрополь, ул. Ломоносова, д.3, info@stavminobr.ru (8652) 74-75-50"
    },
    {
        "col1": "Тамбовская область",
        "col2": 1,
        "col3": "Министерство социальной защиты и семейной политики Тамбовской области",
        "col4": "392000, Тамбовская область, г. Тамбов, ул. Московская, 27а, email:uprsoc@uszn.tambov.gov.ru, телефон: 8(4752)791602"
    },
    {
        "col1": "Тверская область",
        "col2": 1,
        "col3": "органы местного самоуправления Администрация города Твери",
        "col4": "г. Тверь, ул. Советская, д. 11  ‎+7 (4822) 36-10-76 tveradm@adm.tver.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 2,
        "col3": "Администрация Вышневолоцкого городского округа ",
        "col4": "Тверская область,г. Вышний Волочек, Казанский проспект, д. 17,    +7 (48233) 6-41-36  admvgo@v-volok.ru  "
    },
    {
        "col1": "Тверская область",
        "col2": 3,
        "col3": "Администрация города Торжка",
        "col4": "Тверская область, г. Торжок, Новгородская наб., д.1а       8(48251) 9-12-46   admtorzhok@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 4,
        "col3": "Администрация Андреапольского муниципального округа",
        "col4": "Тверская область, г. Андреаполь, пл. Ленина, д.2    +7 (48267) 3-14-51     admandreapol@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 5,
        "col3": "Администрация Бельского муниципального округа",
        "col4": "Тверская область, г. Белый, площадь Победы, д.4   8(482508) 2-25-32    belraion@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 6,
        "col3": "Администрация Весьегонского муниципального округа",
        "col4": " Тверская область,г. Весьегонск, ул. Коммунистическая, д. 16  +7 (482 64) 2-13-05"
    },
    {
        "col1": "Тверская область",
        "col2": 7,
        "col3": "Администрация Жарковского муниципального округа",
        "col4": "Тверская область, п. Жарковский, ул.Советская, д. 54  8(48273)21503 admzharki@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 8,
        "col3": "Администрация Зубцовского муниципального округа",
        "col4": "г Зубцов, пер Образцова, д. 1а  8 (48262) 2-25-51    priemnaya@adminzubcov.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 9,
        "col3": "Администрация Калязинского  муниципального округа",
        "col4": "г. Калязин, ул. Центральная, д. 1  8(48249) 2-19-61  gorod1775@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 10,
        "col3": "Администрация Кесовогорского муниципального округа",
        "col4": "Тверская область, пгт Кесова Гора, ул Московская, д. 6  +7 (48274) 2-11-01   kesovogorskiy_okrug"
    },
    {
        "col1": "Тверская область",
        "col2": 11,
        "col3": "Администрация Конаковского муниципального округа",
        "col4": "Тверская область,г. Конаково, ул. Энергетиков, дом 13.    8(48242)49-777 konadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 12,
        "col3": "Администрация Кувшиновского муниципального округа",
        "col4": " Тверская область, г. Кувшиново, ул. Советская, 33   8 (48 257) 4 41 44   admkuvshinovo@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 13,
        "col3": "Администрация Лихославльского муниципального округа",
        "col4": "Тверская область,г. Лихославль, ул. Первомайская, д. 6          8 (48261) 3-59-41      adm@lihoslavl69.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 14,
        "col3": "Администрация Молоковского муниципального округа",
        "col4": "Тверская область, пгт Молоково, ул. Ленина,д.13    8 (48275) 2-13-61     molokovskiy_reg@tverreg.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 15,
        "col3": "Администрация Оленинского муниципального округа",
        "col4": "Тверская область,Оленинский муниципальный округ, пгт. Оленино, ул. Октябрьская, д. 7     8(48258) 2-14-02, 2-13-36     oleninoadm@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 16,
        "col3": "Администрация Пеновского муниципального округа",
        "col4": "Тверская область, п. Пено, ул. 249 Стр.дивизии, д.33    8(48230) 2-39-58    penoraion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 17,
        "col3": "Администрация Селижаровского муниципального округа",
        "col4": "Тверская область, пгт Селижарово, ул. Ленина, д.12    8(48269) 225-80     selmuno@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 18,
        "col3": "Администрация Спировского муниципального округа",
        "col4": "Тверская область, п.Спирово, пл.Советская, д.5   8(48276) 2-11-39     spirovo-raion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 19,
        "col3": "Администрация Торжокского района",
        "col4": "Тверская область,г. Торжок, ул. Луначарского, д. 2   8(48251) 9-22-50   torzhokadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 20,
        "col3": "Администрация Удомельского городского округа",
        "col4": "Тверская область,г. Удомля, ул. Попова, д.22  8 (48255) 5 43 35  udom_okrug@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 21,
        "col3": "Администрация Ржевского муниципального округа",
        "col4": "Тверская область,г. Ржев, ул. Партизанская, д.33    8(48232) 2-09-15   info@городржев.рф"
    },
    {
        "col1": "Тверская область",
        "col2": 22,
        "col3": "Администрация Кимрского муниципального округа",
        "col4": "Тверская область, г. Кимры,ул. Кирова д.18   8(48236) 2-16-66    admin@adm-kimry.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 23,
        "col3": "Администрация Бежецкого муниципального округа",
        "col4": "Тверская область,г. Бежецк, Первомайский переулок, дом 21   8 (48231) 2-15 -81  admbezh@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 24,
        "col3": "Администрация Бологовского муниципального округа",
        "col4": "Тверская область,г. Бологое, ул.Кирова, 13   +7 (48238) 2-22-11     rbologoe@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 25,
        "col3": "Администрация Западнодвинского муниципального округа",
        "col4": "Тверская область,г. Западная Двина, ул. Кирова, д. 10    8(48265) 2-17-30    westerndvina.22@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 26,
        "col3": "Администрация Калининского муниципального округа",
        "col4": "г.Тверь, набережная реки Лазури, д.3  8 (4822) 45-38-39   mail@kalinin-mo.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 27,
        "col3": "Администрация Кашинского городского округа",
        "col4": " Тверская область,г. Кашин, ул. Анатолия Луначарского, дом 20  8 (48234) 2-06-68, kashin@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 28,
        "col3": "Администрация Краснохолмского муниципального округа",
        "col4": "Тверская область,г. Красный Холм, пл. Карла Маркса, д. 10,  8(48237)2 23 21  adm.krholm@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 29,
        "col3": "Администрация Лесного муниципального округа",
        "col4": "Тверская область, село Лесное, ул. Советская, д.9   8(48271)2-11-45   adminlesnoe69@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 30,
        "col3": "Администрация Максатихинского муниципального округа",
        "col4": "Тверская область,Максатихинский муниципальный округ, пгт. Максатиха, ул. Красноармейская, д. 5.   +7 (48 253) 2-13-12.   maksatiha@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 31,
        "col3": "Администрация Нелидовского городского округа",
        "col4": "Тверская обл., г. Нелидово, пл. Ленина, 3  8 (48266) 53444   gorodnelidovo@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 32,
        "col3": "Администрация Осташковского городского округа",
        "col4": " Тверская область,г.Осташков, Ленинский пр-т, д.46, помещение 1   8(48235)5-16-65  аdmostregion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 33,
        "col3": "Администрация Рамешковского муниципального округа",
        "col4": "Тверская область, пгт Рамешки, ул. Советская, д.20 8(48244)2-13-04   admrameshki@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 34,
        "col3": "Администрация Сандовского мунипального округа",
        "col4": "Тверская область, п.Сандово, ул.Советская, д.11    8 (48272) 2-11-45  sandovo@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 35,
        "col3": "Администрация Сонковского муниципального округа",
        "col4": "Тверская область, пгт. Сонково, пр-т Ленина, д. 24   84824621354   admsonkovo@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 36,
        "col3": "Администрация Старицкого муниципального округа",
        "col4": "Тверская область,г. Старица, ул. Советская, д. 6.    8(48263)21-623   starica_adm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 37,
        "col3": "Администрация Торопецкого муниципального округа",
        "col4": "Тверская область,г. Торопец, ул.Октябрьская, д. 53   8(48268)2-12-97   Toropec69adm@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 38,
        "col3": "Администрация Фировского муниципального округа",
        "col4": "Тверская область,пос. Фирово ул. Советская, д.21   8(48-239)3-19-80  glavafirovo@mail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 1,
        "col3": "Отдел опеки и попечительства Администрации Александровского района Томской области",
        "col4": "636760, Томская область, Александровский район, \nс. Александровское, ул. Ленина, д. 7\nт. 8 (382 55) 2-46-76\nAlsOpeka@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 2,
        "col3": "Асиновское городское поселение",
        "col4": "636840, Томская область, г. Асино, ул.  имени Ленина, 40/ adminpos@asino.tomsknet.ru/\n8(38241)22498,\n8(38241)21748\n"
    },
    {
        "col1": "Томская область",
        "col2": 3,
        "col3": " Большедороховское сельское поселение",
        "col4": "636803, Томская область, Асиновский район, с. Больше-Дорохово, ул. Центральная, д. 26/\nb-dorokhovo-sp@asino.gov70.ru\n/ 8(38241)47205\n"
    },
    {
        "col1": "Томская область",
        "col2": 4,
        "col3": "Батуринское сельское поселение",
        "col4": "636820 Томская область, Асиновский район, с.Батурино, ул. Клубная ,34/\nbaturino-sp@asino.gov70.ru/\n8(38241)41155\n"
    },
    {
        "col1": "Томская область",
        "col2": 5,
        "col3": "Новокусковское сельское поселение",
        "col4": "636810 Томская обл., Асиновский р-он, с.Ново-Кусково, ул.Школьная, д.55/ nkselp@mail.tomsknet.ru/\n8(38241)45045;\n8(38241) 45001\n"
    },
    {
        "col1": "Томская область",
        "col2": 6,
        "col3": "Новиковское сельское поселение",
        "col4": "636830 Томская область Асиновский район с. Новиковка ул. Советская д.14 /\nadm-novikovka@asino.gov70.ru\n/8(38241)44166; \n8(38241)44220\n"
    },
    {
        "col1": "Томская область",
        "col2": 7,
        "col3": "Новониколаевское сельское поселение",
        "col4": "636813 Томская область Асиновский район, с.Новониколаевка, ул. Школьная, д.30, / n-nikolaevka-sp@asino.gov70.ru/ 8(38)24142159; 8(38)24142117"
    },
    {
        "col1": "Томская область",
        "col2": 8,
        "col3": "Ягодное сельское поселение",
        "col4": "636806 Томская обл., Асиновский р-он, с.Ягодное, ул.Школьная, д.1 Г/ yagodnoe-sp@asino.gov70.ru/\n8 (38241)43537\n"
    },
    {
        "col1": "Томская область",
        "col2": 9,
        "col3": "Бакчарское сельское поселение",
        "col4": "636200. Томская область, Бакчарский район, с. Бакчар ул. Ленина д. 53, \n baksp@tomsk.gov.ru, 8(38249)\n22-284\n"
    },
    {
        "col1": "Томская область",
        "col2": 10,
        "col3": "Вавиловское сельское поселение",
        "col4": "636202, Томская область, Бакчарский район, с. Вавиловка, ул. Центральная д.2, vavilsp@tomsk.gov.ru, 8 (38249) 37-295"
    },
    {
        "col1": "Томская область",
        "col2": 11,
        "col3": "Парбигское сельское поселение",
        "col4": "636220,Томская область, Бакчарский район, с. Парбиг, ул. Советская д. 55, parbigsp@tomsk.gov.ru, 8 (38249) 44-384"
    },
    {
        "col1": "Томская область",
        "col2": 12,
        "col3": "Высокоярское сельское поселение",
        "col4": "636225, Томская область, Бакчарский район, с. Высокий Яр, пер. Трактовый, д. 7, vsp.06@mail.ru, 8 (38249) 38-124"
    },
    {
        "col1": "Томская область",
        "col2": 13,
        "col3": "Плотниковское сельское поселение",
        "col4": "636210, Томская область, Бакчарский район, с. Плотниково, ул. Школьная д. 4, plotsp@tomsk.gov.ru, 8 (38249) 39-436"
    },
    {
        "col1": "Томская область",
        "col2": 14,
        "col3": "Поротниковское сельское поселение",
        "col4": "636213, Томская область, Бакчарский район, с Поротниково, ул. Советская, д.19, portsp@tomsk.gov.ru, 8 (38249) 36-138"
    },
    {
        "col1": "Томская область",
        "col2": 15,
        "col3": "Управление образования Администрации Верхнекетского района (Отдел опеки и попечительства)  ",
        "col4": "636500, Томская область, Верхнекетский район, р. п.Белый Яр, ул.Советская д. 1а стр.2,\n8(38)258-256-52, 8(38)258-25652\nopeka@belyar.tomsknet.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 16,
        "col3": "Высоковского сельского поселения ",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 17,
        "col3": "Дубровское сельское поселение",
        "col4": "636852 Томская область, Зырянский район, с.Дубровка, ул. Фрунзе, д.38\n (адрес электронной почты: dubrovka@tomsk.gov.ru; тел. 8(38243) 34131)\n"
    },
    {
        "col1": "Томская область",
        "col2": 18,
        "col3": "Зырянское сельское поселение",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 19,
        "col3": "Михайловское сельское поселение",
        "col4": "636855 Томская область, Зырянский район, с.Михайловка, ул.Гончарова, д.44\n(адрес электронной почты: mihaylsp@tomsk.gov.ru; тел. 8(38243) 36222).\n"
    },
    {
        "col1": "Томская область",
        "col2": 20,
        "col3": "Чердатское сельское поселение",
        "col4": "636859 Томская область, Зырянский район, с.Чердаты, ул.Советская, 1а\n(адрес электронной почты: cherdsp@tomsk.gov.ru; тел. 8(38243) 32137)\n"
    },
    {
        "col1": "Томская область",
        "col2": 21,
        "col3": "Вертикосское сельское поселение",
        "col4": "636753, Томская область, Каргасокский район, с. Вертикос, ул. Молодежная,1/ mkuvertikos@yandex.ru/\n8(38253)  36-189;\n"
    },
    {
        "col1": "Томская область",
        "col2": 22,
        "col3": "Каргасокское сельское поселение",
        "col4": "636700, Томская область, Каргасокский район,  с. Каргасок, ул. Новая,1\nkargs@tomsk.gov.ru\n8 (38253)23 423\n"
    },
    {
        "col1": "Томская область",
        "col2": 23,
        "col3": "Нововасюганское сельское поселение",
        "col4": "636740, Томская область, Каргасокский район, с. Новый Васюган, ул. Советская,49/\nnovyjvasiugan@yandex.ru/\n 8 (38253) 29 284\n"
    },
    {
        "col1": "Томская область",
        "col2": 24,
        "col3": "Новоюгинское сельское поселение",
        "col4": "636714,, Томская область, Каргасокский район,  с. Новоюгино, ул. Цетральная, 44-2/\nansp06@mail.ru/ \n 8 (38253) 37-132\n"
    },
    {
        "col1": "Томская область",
        "col2": 25,
        "col3": "Средневаюганское сельское поселение",
        "col4": " 636733, Томская область, Каргасокский район, с. Средний Васюган, ул. Гагарина,6/\nsvasugan@mail.ru/ \n8 (38253) 25-172\n"
    },
    {
        "col1": "Томская область",
        "col2": 26,
        "col3": "Среднетымское сельское поселение",
        "col4": " 636754, Томская область, Каргасокский район, п. Молодежный, ул. Школьная, 2/ sredtympos@yandex.ru/  8 (38253) 44-146                                                  "
    },
    {
        "col1": "Томская область",
        "col2": 27,
        "col3": "Сосновское сельское поселение",
        "col4": "636712, Томская область, Каргасокский  с.Сосновка, ул. Школьная, 18/ sosnovka.a@yandex.ru/\n 8 (38253) 38-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 28,
        "col3": "Вороновское сельское поселение",
        "col4": "636171, Томская область, Кожевниковский район, с. Вороново, ул. Уткина, д. 17 \n voronovosp@mail.ru\n(838244) 31-169; (838244) 31-148\n"
    },
    {
        "col1": "Томская область",
        "col2": 29,
        "col3": "Кожевниковское сельское поселение",
        "col4": "636160, Томская область, Кожевниковский район, с. Кожевниково, ул. Гагарина, д. 24\n kozhev_posel@mail.ru\n(838244) 44-745; (838244) 21-961\n"
    },
    {
        "col1": "Томская область",
        "col2": 30,
        "col3": "Малиновское сельское поселение",
        "col4": "636176, Томская область, Кожевниковский район, с. Малиновка, ул. Школьная, д. 13 MalinovkaSP@mail.ru\n(838244) 53-146\n"
    },
    {
        "col1": "Томская область",
        "col2": 31,
        "col3": "Новопокровское сельское поселение",
        "col4": "636182, Томская область, Кожевниковский район, с. Новопокровка, ул. Садовая, д. 2а voronovosp@mail.ru\n(838244) 58-125, (838244) 58-134\n"
    },
    {
        "col1": "Томская область",
        "col2": 32,
        "col3": "Песочнодубровское сельское поселение",
        "col4": "636164, Томская область, Кожевниковский район, с. Песочнодубровка, ул. Молодежная, д. 37 \npesoch@tomsk.gov.ru\n(838244) 42-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 33,
        "col3": "Староювалинское сельское поселение",
        "col4": "636165, Томская область, Кожевниковский район, с. Старая Ювала, ул. Ульяновская, д. 3а\n yuvala@mail.ru, stuval@tomsk.gov.ru\n(838244) 41-327; (838244) 41-162\n"
    },
    {
        "col1": "Томская область",
        "col2": 34,
        "col3": "Уртамское сельское поселение",
        "col4": "636170, Томская область, Кожевниковский район, с. Уртам, ул. Фрунзе, д. 33 \nurtam@tomsk.gov.ru\n(838244) 51-351\n"
    },
    {
        "col1": "Томская область",
        "col2": 35,
        "col3": "Чилинское сельское поселение",
        "col4": "636172, Томская область, Кожевниковский район, с. Чилино, ул. Кустарная, д. 1 \nchilino@mail.ru\nchilino@kozhevnikovo.gov70.ru\nlarisa.jul2015@yandex.ru\n8.6(838244) 35-407, (838244)35-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 36,
        "col3": "Колпашевское городское поселение",
        "col4": "636460, Томская область, Колпашевский район, г. Колпашево, ул. Победы, д. 5\nkolpash@tomsk.gov.ru\n8 (38254) 5 82 62\n"
    },
    {
        "col1": "Томская область",
        "col2": 37,
        "col3": "Инкинское сельское поселение",
        "col4": "636443,Томская область, Колпашевский район,  д. Инкино, пер. Кооперативный 11\ninkino@tomsk.gov.ru\n8 (38254) 9-31-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 38,
        "col3": "Новогоренское сельское поселение",
        "col4": "636444, Томская область, Еолпашевский район, д. Новогорное, ул. Береговая 42, n-gorins@tomsk.gov.ru \n8 (38254) 9 41 36\n"
    },
    {
        "col1": "Томская область",
        "col2": 39,
        "col3": "Новоселовское сельское поселение",
        "col4": "636425, Томская область, Колпашевский район,  с. Новоселово, ул. Центральная 11/1\nn-selovs@tomsk.gov.ru\n8 (38254) 22 160\n"
    },
    {
        "col1": "Томская область",
        "col2": 40,
        "col3": "Саровское сельское поселение",
        "col4": "636431, Томская область, Колпашевский район, п. Большая Саровка, ул. Советская 35/2\nsarovka@tomsk.gov.ru\n8 (38254) 2-74-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 41,
        "col3": "Чажемтовское сельское поселение",
        "col4": "636423,Томская область, Колпашевский район,  с. Чажемто, ул. Ленина 24\nchagemto@tomsk.gov.ru, 8 (38254) 2 12 42\n"
    },
    {
        "col1": "Томская область",
        "col2": 42,
        "col3": "Володинское сельское поселение",
        "col4": "636310, Томская область, Кривошеинский  район,  с. Володино, ул. Советская, 31, volodino@tomsk.qov.ru, 8(38251) 45-433"
    },
    {
        "col1": "Томская область",
        "col2": 43,
        "col3": "Иштанское сельское поселение",
        "col4": "636312 Томская область Кривошеинский район с. Иштан, ул. Лесная 1А, ishtan00@yandex.ru, 8(38251) 43-495"
    },
    {
        "col1": "Томская область",
        "col2": 44,
        "col3": "Красноярское сельское поселение",
        "col4": "636320, Томская область, Кривошеинский район, с. Красный Яр, ул. Советская, 60, krasyar@tomsk.gov.ru, 8(38251) 31-330"
    },
    {
        "col1": "Томская область",
        "col2": 45,
        "col3": "Новокривошеинское сельское поселение",
        "col4": "636307, Томская область, Кривошеинский район, с. Новокривошеино, ул. Советская, 1, novokriv@yandex.ru, 8(38251) 47-433"
    },
    {
        "col1": "Томская область",
        "col2": 46,
        "col3": "Петровское сельское поселение",
        "col4": "636313, Томская область, Кривошеинский район, Петровка, ул. Комсомольская,12, реtrovka@tomsk.gov.ru, 8(38251) 44-416"
    },
    {
        "col1": "Томская область",
        "col2": 47,
        "col3": "Пудовское сельское поселение",
        "col4": "636316, Томская область, Кривошеинский район, с. Пудовка, ул. Центральная, 64, pudovka@tomsk.gov.ru, 8(38251) 46-431"
    },
    {
        "col1": "Томская область",
        "col2": 48,
        "col3": "Могочинское сельское поселение",
        "col4": "636340, Томская область, Молчановский район, с. Могочино, ул. Заводская, 3, +7 (38256) 3‒31‒32,  admogochino@sibmail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 49,
        "col3": "Молчановское сельское поселение",
        "col4": "636330, Томская область, Молчановский район, с. Молчаново, ул. Димитрова, 51, т. 8 (38256) 21-5-86, ml-molch@tomsk.gov.ru "
    },
    {
        "col1": "Томская область",
        "col2": 50,
        "col3": "Наргинское сельское поселение",
        "col4": "636346, Томская область, Молчановский район, с. Нарга, ул. К. Маркса, 41, adm-narga@molchanovo.gov70.ru\n8 (256) 32-4-95\n"
    },
    {
        "col1": "Томская область",
        "col2": 51,
        "col3": "Суйгинское сельское поселение",
        "col4": "636345 Томская область Молчановский район, с. Суйга, ул. Комарова, 29, suiga@molchanovo.gov70"
    },
    {
        "col1": "Томская область",
        "col2": 52,
        "col3": "Тунгусовское  сельское поселение",
        "col4": "636353, Томская область, Молчановский район, с. Тунгусово, ул. Кнакиса, 5, +7 (382-56) 35-4-37,  tungusovosp@molchanovo.gov70.ru"
    },
    {
        "col1": "Томская область",
        "col2": 53,
        "col3": "Заводское сельское поселение",
        "col4": "636608, Томская область, Парабельский район\nп. Заводской, ул. 60 лет СССР, 19/\n8(38252)3-91-66, 8(38252)3-91-34/ zavodskoe@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 54,
        "col3": "Нарымское сельское поселение",
        "col4": "636611, Томская область,  Парабельский район, \nс. Нарым, ул. Сибирская, 2/\n8(38252)3-32-31, 8(38252)3-32-32/ narim@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 55,
        "col3": "Новосельцевское сельское поселение",
        "col4": "636607, Томская область, Парабельский район,\n с. Новосельцево , ул. Шишкова, 5/ 8(382525)3-61-58, 8(38252)3-61-58/\nn-selcevo@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 56,
        "col3": "Парабельское сельское поселение",
        "col4": "636600, Томская область, Парабельский рвйон, \nс. Парабель , ул. Свердлова, 5/ 8(382525)2-19-63, 8(38252)2-13-43/\n      Sp-parab@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 57,
        "col3": "Старицинское сельское поселение",
        "col4": "636626, Томская область, Парабельский район, \nс. Старица, ул. Советская, 58 «а»/ 8-913-848-19-09/ istomina-1k@yandex.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 58,
        "col3": "Комсомольское сельское поселение",
        "col4": "636942, Томская область, Первомайский район, с. Комсомольск, ул. Железнодорожная 40/1, volkova.azlk1214@yandex.ru, 8(38245)23-249"
    },
    {
        "col1": "Томская область",
        "col2": 59,
        "col3": "Куяновское сельское поселение",
        "col4": "636953, Томская область, Первомайский район, село Куяново, ул. Центральная, 18/1, kuspmail@mail.ru, тел. 8 (38245) 33-198"
    },
    {
        "col1": "Томская область",
        "col2": 60,
        "col3": "Новомариинское сельское поселение",
        "col4": "636935 Томская обл., Первомайский район, с. Новомариинка, ул. Центральная, 42, novosp@tomsk.gov.ru, 8 (38245) 35-123"
    },
    {
        "col1": "Томская область",
        "col2": 61,
        "col3": "Первомайское сельское поселение",
        "col4": "636930, Томская область, Первомайский район, с. Первомайское, ул. Советская, д.14, pmsp@tomsk.gov.ru, 8 (38245)21-060"
    },
    {
        "col1": "Томская область",
        "col2": 62,
        "col3": "Сергеевское сельское поселение",
        "col4": "Сергеевское сельское поселение: 636941, Томская область, Первомайский район, с. Сергеево, ул. Школьная д. 3, sergeevo@tomsk.gov.ru, 8 (38245) 43-320"
    },
    {
        "col1": "Томская область",
        "col2": 63,
        "col3": "Улу-Юльское сельское поселение",
        "col4": "636948, Томская область, Первомайский район, поселок Улу-Юл, улица 50 Лет Октября, д.5 oks4na5mir@yandex.ru, тел. 8 (38245) 44-123"
    },
    {
        "col1": "Томская область",
        "col2": 64,
        "col3": "Белоярское сельское поселение",
        "col4": "636909, Томская область, Тегульдетский район, \nп. Белый Яр, ул. Центральная, 2\nТелефон: +7 (382-46) 3-41-15,\nE-mail: belyarsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 65,
        "col3": "Берегаевское сельское поселение",
        "col4": "636911, Томская область, Тегульдетский район,\nп. Берегаево, ул. Ленинская, д. 17а\nТелефон: +7 (382-46) 3-31-89,\nE-mail: beregsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 66,
        "col3": "Тегульдетское сельское поселение",
        "col4": "636900, Томская область, Тегульдетский район, \nс. Тегульдет, ул. Ленина, 156\nТелефон: +7 (382–46) 2-19-25, 2-19-13,\nE-mail: tegsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 67,
        "col3": "Черноярское сельское поселение",
        "col4": "636902, Томская область, Тегульдетский район, \nп. Черный Яр, ул. Комсомольская, 7\nТелефон: +7 (382–46) 3-11-42,\nE-mail: blacksp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 68,
        "col3": "Богашевское сельское поселение",
        "col4": "634570, Томская область, Томский район, с. Богашево, ул.Советская, 6, / \nадрес электронной\nпочты: bogashevo-sp@tomsky.gov70.ru/\nномер телефона 931-269\n"
    },
    {
        "col1": "Томская область",
        "col2": 69,
        "col3": "Воронинское сельское поселение",
        "col4": "634532, Томская область, Томский район, д. Воронино, ул. Центральная, 74/ \nадрес электронной\nпочты: voronino-sp@tomsky.gov70.ru/\nномер телефона 950-274\n"
    },
    {
        "col1": "Томская область",
        "col2": 70,
        "col3": "Заречное сельское поселение",
        "col4": "634521, Томская область, Томский район,  с.Кафтанчиково, ул.Коммунистическая, д.86 \nадрес электронной\nпочты: zarechnoe-sp@tomsky.gov70.ru /\nномер телефона 961-200\n"
    },
    {
        "col1": "Томская область",
        "col2": 71,
        "col3": "Зональненское сельское поселение",
        "col4": "634507, Томская область, Томский район, \nп.Зональная, ул.Совхозная, 10 / \nадрес электронной\nпочты: zonalniy-sp@tomsky.gov70.ru /\nномер телефона 923-969\n"
    },
    {
        "col1": "Томская область",
        "col2": 72,
        "col3": "Зоркальцевское сельское поселение",
        "col4": "634515, Томская область, Томский район, с. Зоркальцево, ул. Совхозная, 14/ \nадрес электронной\nпочты: zorkalsp@gov70.ru/\nномер телефона 915-790\n"
    },
    {
        "col1": "Томская область",
        "col2": 73,
        "col3": "Итатское сельское поселение",
        "col4": "634540, Томская область, Томский район, с. Итатка, ул. Гагарина,1 / \nадрес электронной\nпочты: itatkasp@gov70.ru /\nномер телефона 959-323\n"
    },
    {
        "col1": "Томская область",
        "col2": 74,
        "col3": "Калтайское сельское поселение",
        "col4": "634523, Томская область, Томский район, с. Курлек, ул. Трактовая, 48  / \nадрес электронной\nпочты: kaltay-sp@tomsky.gov70.ru, kaltai.adm.fin@mail.ru /\nномер телефона 968-293\n"
    },
    {
        "col1": "Томская область",
        "col2": 75,
        "col3": "Копыловское сельское поселение",
        "col4": "634537, Томская область, п. Копылово, ул. Новая, 13/\nадрес электронной\nпочты: kopylovosp@gov70.ru/\nномер телефона 989-101\n"
    },
    {
        "col1": "Томская область",
        "col2": 76,
        "col3": "Корниловское сельское поселение",
        "col4": "634538, Томская область, Томский район, с.Корнилово,  ул.Гагарина, 29А,/ \nадрес электронной\nпочты: kornilovosp@gov70.ru/\nномер телефона 963-171\n"
    },
    {
        "col1": "Томская область",
        "col2": 77,
        "col3": "Малиновское сельское поселение",
        "col4": "634580, Томская область, Томский район, с. Малиновка, ул. Чулымская, 30 / \nадрес электронной\nпочты: malinovka-sp@tomsky.gov70.ru/\nномер телефона 920-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 78,
        "col3": "Межениновское сельское поселение",
        "col4": "634520, Томская область, Томский район,  с.Межениновка, ул.Первомайская, 23, / \nадрес электронной\nпочты: mezheninsp@gov70.ru/\nномер телефона 969-725\n"
    },
    {
        "col1": "Томская область",
        "col2": 79,
        "col3": "Мирненское сельское поселение",
        "col4": "634539, Томская область, Томский район, п. Мирный, ул. Трудовая, 10 / \nадрес электронной\nпочты: mirniy-sp@tomsky.gov70.ru/\nномер телефона 955-198\n"
    },
    {
        "col1": "Томская область",
        "col2": 80,
        "col3": "Моряковское сельское поселение",
        "col4": "634516, Томская облатсь, Томский район, с. Моряковский Затон, ул. Советская, 27/ \nадрес электронной\nпочты: morzaton-sp@tomsky.gov70.ru/\nномер телефона 927-498\n"
    },
    {
        "col1": "Томская область",
        "col2": 81,
        "col3": "Наумовское сельское поселение",
        "col4": "634581, Томская область, Томский рай1он, с. Наумовка, ул. Советская, 7А/ \nадрес электронной\nпочты: naumovkasp@gov70.ru /\nномер телефона 969-339\n"
    },
    {
        "col1": "Томская область",
        "col2": 82,
        "col3": "Новорождественское сельское поселение",
        "col4": "634535, Томская область, Томский район, с.Новорождественское, ул. Советская, 62/ \nадрес электронной\nпочты: n-rozhdestven-sp@tomsky.gov70.ru/\nномер телефона 946-589\n"
    },
    {
        "col1": "Томская область",
        "col2": 83,
        "col3": "Октябрьское сельское поселение",
        "col4": "634583, Томская область, Томский район, с. Октябрьское, ул. Заводская,14/ адрес электронной\nпочты: oktyabr-sp@tomsky.gov70.ru /\nномер телефона 925-211\n"
    },
    {
        "col1": "Томская область",
        "col2": 84,
        "col3": "Рыболовское сельское поселение",
        "col4": "634518, Томская область, Томский район, с.Рыбалово, ул.Коммунистическая, 7,/ \nадрес электронной\nпочты: rybalovo-sp@tomsky.gov70.ru /\nномер телефона 919-131\n"
    },
    {
        "col1": "Томская область",
        "col2": 85,
        "col3": "Спасское сельское поселение",
        "col4": "пер.Новый, с.Вершинино, Томский район, Томская область, 634528, Томская область, Томский район, с. Вершинино, пер. Новый / \nадрес электронной\nпочты: spasskoe-sp@tomsky.gov70.ru /\nномер телефона 959-608\n"
    },
    {
        "col1": "Томская область",
        "col2": 86,
        "col3": "Турунтаевское сельское поселение",
        "col4": "634534, Томская область, Томский район, с. Турунтаево, ул. Новая, 2а / \nадрес электронной\nпочты: turuntaevo-sp@tomsky.gov70.ru /\nномер телефона 947-175\n"
    },
    {
        "col1": "Томская область",
        "col2": 87,
        "col3": "Коломинское сельское поселение",
        "col4": "636410, Томская область, Чаинский район, с. Коломинские Гривы, ул. Советская, 21/ kolomin@tomsk.gov.ru/ 8(38257) 45244"
    },
    {
        "col1": "Томская область",
        "col2": 88,
        "col3": "Подгорнское сельское поселение",
        "col4": "636400, Томская область, Чаинский район, \nс. Подгорное, ул. Ленинская, 4, стр. 1/ podgorns@tomsk.gov.ru/ 8(38257) 21102\n"
    },
    {
        "col1": "Томская область",
        "col2": 89,
        "col3": "Усть-Бакчарское сельское поселение",
        "col4": "636404, Томская область, Чаинский район, \nс. Усть-Бакчар, ул. Центральная, 17/ inna_novokreshenova@mail.ru/ \n8(38257) 35167\n"
    },
    {
        "col1": "Томская область",
        "col2": 90,
        "col3": "Чаинское сельское поселение",
        "col4": "636407,Томская область, Чаинский район, \nс. Чаинск, ул. Комсомольская, 14/ chainsksp@mail.ru/ 8(38257) 56119\n"
    },
    {
        "col1": "Томская область",
        "col2": 91,
        "col3": "Анастасьевское сельское поселение",
        "col4": "636149, Томская область. Шегарский район,\nс. Анастасьевка, пер. Школьный, д.2\nanastasevka05@mail.ru\n8(38247)3-91-37\n"
    },
    {
        "col1": "Томская область",
        "col2": 92,
        "col3": "Баткатское сельское поселение",
        "col4": "636141, Томская область, Шегарский район,\nс. Баткат, пер. Кооперативный, д.1\nadm-batkat@shegarsky.gov70.ru\n8(38247) 341-32, 8(38247)341-09\n"
    },
    {
        "col1": "Томская область",
        "col2": 93,
        "col3": "Побединское сеельское поселение ",
        "col4": "636143, п. Победа, Шегарский район,\nТомская область,\nул. Коммунистическая д.112а, помещение 2\nssamotoylov@mail.ru\npobeda@tomsk.gov.ru\n8(38247) 42-195, 8(38247) 42-264\n"
    },
    {
        "col1": "Томская область",
        "col2": 94,
        "col3": "Северное сельское поселение",
        "col4": "636156,Томская область, Шегарский район,\nс. Монастырка, ул. Советская, д.51а\nadm-severnoe@shegarsky.gov70.ru\n+7 (382-47) 2-97-84\n"
    },
    {
        "col1": "Томская область",
        "col2": 95,
        "col3": "Трубачевское сельское поселение",
        "col4": "636145, Томская область. Шегарский район,\nс. Трубачево, ул. Центральная, д.7\ntrubachevskoe@mail.ru\n8(38247) 38-116, 8(38247) 38-127\n"
    },
    {
        "col1": "Томская область",
        "col2": 96,
        "col3": "Шегарское сельское поселение",
        "col4": "636130,Томская область, Шегарский район,\nс.Мельниково, ул. Московская,д.13\nshegarka00@mail.ru\n8 (38247) 2-11-72\n8(38247) 2-37-29\n"
    },
    {
        "col1": "Томская область",
        "col2": 97,
        "col3": "Отдел опеки и попечительства Администрации города Кедрового",
        "col4": " 636615, Томская область,  г. Кедровый, 1-й мкр. ул., д.39/1,\nkedropeka@tomsk.gov.ru\n(38250)35-314\n"
    },
    {
        "col1": "Томская область",
        "col2": 98,
        "col3": "Администрация ЗАТО Северск",
        "col4": "636070, Томская область, г. Северск, пр. Коммунистический, 51\n8 (382-3) 77-23-23\nzato-seversk@gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 99,
        "col3": "Администрация городского округа Стрежевой Отдел жилищной политики",
        "col4": "636785, Томская область, г. Стрежевой, ул. Ермакова, д,46А,                                               8 (38259) 51-560,                                     Koroleva@admstrj.romsk.ru"
    },
    {
        "col1": "Томская область",
        "col2": 100,
        "col3": "Администрация Кировского района",
        "col4": "634034, г. Томск, пр. Кирова, д. 11а\nmail@aks.admin.tomsk.ru\n+7 3822 56-40-95\n+7 3822 56-43-22\n"
    },
    {
        "col1": "Томская область",
        "col2": 101,
        "col3": "Администрация Ленинского района",
        "col4": "634009, г. Томск, ул. Карла Маркса, д. 34\nalradmin@alr.admin.tomsk.ru\n+7 3822 51-71-13\n+7 3822 51-71-43\n"
    },
    {
        "col1": "Томская область",
        "col2": 102,
        "col3": "Администрация Октябрьского района",
        "col4": "634003, г. Томск, ул. Пушкина, д. 17\nalomail@alo.admin.tomsk.ru\n+7 3822 65-94-93\n+7 3822 65-94-49\n"
    },
    {
        "col1": "Томская область",
        "col2": 103,
        "col3": "Администрация Советского района",
        "col4": "634012, г. Томск, ул. Елизаровых ,59\nkans@asr.admin.tomsk.ru\n+7 3822 54-45-25\n+7 3822 54-30-54\n"
    },
    {
        "col1": "Тульская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Тульской области",
        "col4": "г. Тула, ул. Пушкинская. д. 29, тел. 8 (4872) 24-52-50, mintrud@tularegion.ru"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 1,
        "col3": "Территориальные подразделения Департамента социального развития Тюменской области",
        "col4": "Территориальные подразделения Департамента социального развития Тюменской области\nhttps://soc.admtyumen.ru/OIGV/dsrto/about/structure/soc_zash.htm "
    },
    {
        "col1": "Тюменcкая область",
        "col2": 2,
        "col3": "Департамент социального развития Тюменской области",
        "col4": "\n625048, Тюменская обл., г. Тюмень, ул. Республики, д. 83а / dsoc@72to.ru / (3452) 50-21-68"
    },
    {
        "col1": "Удмуртская Республика",
        "col2": 1,
        "col3": "Министерство социальной политики и труда Удмуртской Республики",
        "col4": "г. Ижевск, ул. Ломоносова, д. 5; +7 (3412) 22-27-30; mail@msp.udmr.ru"
    },
    {
        "col1": "Ульяновская область\n",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства и строительства Ульяновской области",
        "col4": "432017, г. Ульяновск, ул. Спасская, д.8, (8422)58-65-32 cun173@mail.ru"
    },
    {
        "col1": "Хабаровский край",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства Хабаровского края",
        "col4": "Хабаровский край, г. Хабаровск, ул. Фрунзе, д. 71, 680000. \nЭлектронная почта: office@khv.gov.ru. Тел: 8 (4212) 40-22-17, 31-29-11, 31-42-33, 30-56-49"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 1,
        "col3": "органы местного самоуправления Белоярского района",
        "col4": "628162, ул. Центральная, 9, г. Белоярский,Ханты-Мансийский автономный округ – Югра8 (34670) 2-14-90\nadmbel@admbel.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 2,
        "col3": "Орган местного самоуправления Березовского района",
        "col4": "628140, ул Астраханцева, 54, пгт. Березово, \nХанты-Мансийский автономный округ-Югра\n8 (34674) 2-44-92\nadm@berezovo.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 3,
        "col3": "Орган местного самоуправления Кондинского района",
        "col4": "628200, ул.Титова, 21, пгт.Междуреченский, \nХанты-Мансийский автономный округ - Югра,\n8 (34677) 33-540\nglava@admkonda.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 4,
        "col3": "Орган местного самоуправления \nНефтеюганского района",
        "col4": "628309, 3 микрорайон., 21, г. Нефтеюганск, \nХанты-Мансийский автономный округ-Югра \n8 (3463) 25-01-95\nadmnr@admoil.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 5,
        "col3": "Орган местного самоуправления\n Нижневартовского района",
        "col4": "628606, ул. Ленина, 6, г. Нижневартовск \nХанты-Мансийский автономный округ - Югра\n8(3466)49-86-78,\nadm@nvraion.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 6,
        "col3": "Орган местного самоуправления Октябрьского района",
        "col4": "628100, ул. Калинина, 39, пгт.Октябрьское,\nХанты-Мансийский автономный округ-Югра\n8 (34678)28-000\nadm@oktregion.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 7,
        "col3": "Орган местного самоуправления Советского района",
        "col4": "628242, ул. 50 лет Пионерии, 10, г. Советский, \nХанты-Мансийский автономный округ - Югра\n8 (34675) 5-48-00, 5-48-10\nadm@sovrnhmao.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 8,
        "col3": "Орган местного самоуправления Сургутского района",
        "col4": "628408, ул.Энгельса, 10, г. Сургут, \nХанты-Мансийский автономный округ-Югра\n8 (3462) 52-65-00\nglava@admsr.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 9,
        "col3": "Орган местного самоуправления\n Ханты-Мансийского района",
        "col4": "628002, ул. Гагарина, 214, г. Ханты-Мансийск,\nХанты-Мансийский автономный округ - Югра8 (3467) 352800\noffice@hmrn.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 10,
        "col3": "Орган местного самоуправления города Когалыма",
        "col4": "628481, ул.Дружбы Народов,7, г. Когалым, \nХанты-Мансийский автономный округ-Югра\n8 (34667) 93-522\ndelo@admkogalym.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 11,
        "col3": "Орган местного самоуправления города Лангепас",
        "col4": "628672, ул. Ленина, 35, г. Лангепас, \nХанты-Мансийский автономный округ - Югра,\n8 (34669) 5-60-57,\nаdm@admlangepas.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 12,
        "col3": "Орган местного самоуправления Нефтеюганска",
        "col4": "628309, 2 микрорайон, 25, г. Нефтеюганск,\nХанты-Мансийский автономный округ - Югра,\n8 (3463) 23-77-11\npr_glava@admugansk.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 13,
        "col3": "Орган местного самоуправления \nгорода Нижневартовска",
        "col4": "628602, Таежная, 24, г. Нижневартовск, \nХанты-Мансийский автономный округ - Югра\n8 (3466) 24-15-98, \n8 (3466) 24-24-34, \nmeria@n-vartovsk.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 14,
        "col3": "Орган местного самоуправления города Нягани",
        "col4": "628181, ул. Загородных, 7 А, , г. Нягань,\nХанты-Мансийский автономный округ-Югра8 (34672) 26-131; 26-131 \nvsdelo@admnyagan.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 15,
        "col3": "Орган местного самоуправления города Покачи",
        "col4": "628661, ул. Мира, 8/1, г.Покачи, \nХанты-Мансийский автономный округ-Югра\n8(34669)7-28-76\nadmpokachi@admpokachi.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 16,
        "col3": "Орган местного самоуправления города Пыть-Ях",
        "col4": "628380, 1 микрорайон, 18 «а», г.Пыть-Ях, \nХанты-Мансийский автономный округ8 (3463) 46-55-79\nadm@gov86.org"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 17,
        "col3": "Орган местного самоуправления города Радужный",
        "col4": "628460, 3 микрорайон, 3, г.Радужный, \nХанты-Мансийский автономный округ-Югра\n8 (34668) 25-710\nupr_del@admrad.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 18,
        "col3": "Орган местного самоуправления города Сургута",
        "col4": "628400, ул. Энгельса, 8, г. Сургут,Ханты-Мансийский автономный округ - Югра\n8 (3462) 52-21-57, 52-21-75\ngorod@admsurgut.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 19,
        "col3": "Орган местного самоуправления города Урай",
        "col4": "628285, 2 микрорайон, 60, г. Урай,\nХанты-Мансийский автономный округ - Югра \n8 (34676) 2-23-28\nadm@uray.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 20,
        "col3": "Орган местного самоуправления\n города Ханты-Мансийска",
        "col4": "628012, ул. Дзержинского, 6, г. Ханты-Мансийск \nХанты-Мансийский автономный округ - Югра8 (3467) 35-23-01 (доб. 301, 331)\npr@admhmansy.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 21,
        "col3": "Орган местного самоуправления города Югорска",
        "col4": "628260, ул. 40 лет Победы, 11, Югорск, \nХанты-Мансийский автономный округ - Югра\n8 (34675) 5-00-00\nadm@ugorsk.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 1,
        "col3": "Министерство социальных отношений Челябинской области",
        "col4": "454048, г. Челябинск, ул. Воровского, д. 30, тел. (351)232-39-28 (приемная), (351) 232-39-94, 264-07-90 (отдел организации работы по опеке и попечительству), opek@minsoc74.ru"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 1,
        "col3": "Министерство труда, занятости и социального развития Чеченской Республики",
        "col4": "ул. Деловая, 15, г. Грозный,\nЧеченская Республика, 364020\nтел: 8 (8712) 22-57-96; \nе-mail: mail@mtchr.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 1,
        "col3": "Алатырский муниципальный округ Чувашской Республики",
        "col4": "429826, Чувашская Республика, г. Алатырь, ул. Ленина, д. 29     8(83531) 2-24-08 alatr.priem@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 2,
        "col3": "Аликовский муниципальный округ Чувашской Республики",
        "col4": "429250, Чувашская Республика, Аликовский район, с. Аликово, \nул. Октябрьская, д. 21 8(83535) 22-3-82 alikov@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 3,
        "col3": "Батыревский муниципальный округ Чувашской Республики",
        "col4": "429350, Чувашская Республика, Батыревский район, с. Батырево, \nпр. Ленина, д. 5 8(83532) 6-24-20 batyr@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 4,
        "col3": "Вурнарский муниципальный округ Чувашской Республики",
        "col4": "429220, Чувашская Республика, Вурнарский район, пгт. Вурнары, \nул. Советская, д. 20 8(83537) 2-51-91 vurnar@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 5,
        "col3": "Ибресинский муниципальный округ Чувашской Республики",
        "col4": "429700, Чувашская Республика, Ибресинский район, п. Ибреси, \nул. Маресьева, д. 49  \n 8(83538) 2-12-52 ibresi@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 6,
        "col3": "Канашский муниципальный округ Чувашской Республики",
        "col4": "429330, г. Канаш, ул.30 лет Победы, д.87  8(83533) 2-24-90 kanash@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 7,
        "col3": "Козловский муниципальный округ Чувашской Республики",
        "col4": "429430, Чувашская Республика, Козловский район, г. Козловка, \nул. Ленина, д. 55  8(83534) 2-23-37 kozlov@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 8,
        "col3": "Комсомольский муниципальный округ Чувашской Республики",
        "col4": "429140, Чувашская Республика, Комсомольский район, \nс. Комсомольское, ул. Заводская, д. 57 8(83539) 5-11-43  komsml@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 9,
        "col3": "Красноармейский муниципальный округ Чувашской Республики",
        "col4": "429620, Чувашская Республика, Красноармейский район, \nс. Красноармейское, ул. Ленина, д. 35 8(83530) 2-18-41 adm.krarm@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 10,
        "col3": "Красночетайский муниципальный округ Чувашской Республики",
        "col4": "429040 Чувашская Республика, с. Красные Четаи, пл. Победы, д. 1 8(83551) 2-16-76 krchet-adm@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 11,
        "col3": "Мариинско-Посадский муниципальный округ Чувашской Республики",
        "col4": "429570, Чувашская Республика, г. Мариинский Посад,  \nул. Николаева, д. 47               8(83542) 2-14-42 marpos@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 12,
        "col3": "Моргаушский муниципальный округ Чувашской Республики",
        "col4": "429530, Чувашская Республика, Моргаушский район, \nс. Моргауши, ул. Мира, д. 6 8(83541) 62-2-39 morgau@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 13,
        "col3": "Порецкий муниципальный округ Чувашской Республики",
        "col4": "429020, Чувашская Республика, Порецкий район, с. Порецкое, \nул. Ленина, д. 3  8(83543) 2-19-53 porezk@cap.ru; porezk_org3@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 14,
        "col3": "Урмарский муниципальный округ Чувашской Республики",
        "col4": "429400, Чувашская Республика, Урмарский район, п. Урмары, \nул. Мира, д. 5  8(83544) 2-10-85 urmary@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 15,
        "col3": "Цивильский муниципальный округ Чувашской Республики",
        "col4": "429900, Чувашская Республика, Цивильский район, г. Цивильск, ул. Маяковского, \nд. 12  8(83545) 21-3-26 zivil@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 16,
        "col3": "Чебоксарский муниципальный округ Чувашской Республики",
        "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, \nул. Советская, д. 25  8(83540) 2-30-93 chebs@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 17,
        "col3": "Шемуршинский муниципальный округ Чувашской Республики",
        "col4": "429370, Чувашская Республика, Шемуршинский район, \nс. Шемурша, ул. Советская, д. 8  8(83546)-2-31-07 shemur@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 18,
        "col3": "Шумерлинский муниципальный округ Чувашской Республики",
        "col4": "429122, Чувашская Республика, \nг. Шумерля, ул. Октябрьская, \nд. 24  (83536) 2-06-29 gshum@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 19,
        "col3": "Ядринский муниципальный округ Чувашской Республики",
        "col4": "429060, Чувашская Республика, Ядринский район, г. Ядрин, \nул. 30 лет Победы, д. 1   8(83547) 22-0-36 yadrin@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 20,
        "col3": "Яльчикский муниципальный округ Чувашской Республики",
        "col4": "429380, Чувашская Республика, Яльчикский район, с. Яльчики, \nул. Иванова, д. 16   8(83549) 2-56-12 yaltch@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 21,
        "col3": "Янтиковский муниципальный округ Чувашской Республики",
        "col4": "429290 Чувашская Республика, Янтиковский район, с. Янтиково, пр. Ленина, д. 13  (83548) 2-18-60 yantik@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 22,
        "col3": "г. Новочебоксарск Чувашской Республики",
        "col4": "429951 Чувашская Республика, \nг. Новочебоксарск,  \nул. Винокурова, д.14   8(8352) 73-81-24,\n73-33-88 nowch-doc5@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 23,
        "col3": "Адмимнистрация Калининского района г.Чебоксары",
        "col4": "428022, Чувашская Республика, г.Чебоксары, ул. 50 лет Октября, \nд. 10 а  8(8352) 23-44-28, \n23-44-27 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 24,
        "col3": "Администраация Ленинского района г.Чебоксары",
        "col4": "428000, Чувашская Республика, г.Чебоксары, ул. Гагарина, д. 22 а\n8(8352) 23-44-76, \n23-44-75, 23-40-17 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 25,
        "col3": "Администрация Московского района г. Чебоксары ",
        "col4": "428017, Чувашская Республика, г. Чебоксары, пр. Московский, \nд. 33 «а»\n8(8352) 23-52-18,\n23-52-33, 23-52-15, 23-52-29  gcheb@cap.ru"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 1,
        "col3": "Администрация городского округа Анадырь",
        "col4": "Администрация городского округа Анадырь (689000, Чукотский автономный округ, г. Анадырь, ул. Рультытегина, д. 1, тел. (42722) 6-36-00, precedent@rambler.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 2,
        "col3": "Администрация Анадырского муниципального района ",
        "col4": "Администрация Анадырского муниципального района (689000, Чукотский автономный округ, г. Анадырь, ул. Южная, д. 15, тел. (42722) 6-49-01, anareg@chukotnet.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 3,
        "col3": "Администрация Билибинского муниципального района",
        "col4": "Администрация Билибинского муниципального района (689450, Чукотский автономный округ, г. Билибино, ул. Курчатова, д. 6, тел. (42738) 2-35-01, info@bilchao.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 4,
        "col3": "Администрация муниципального округа Эгвекинот ",
        "col4": "Администрация муниципального округа Эгвекинот (689202, Чукотский автономный округ, пгт Эгвекинот, ул. Ленина, д. 9, тел. (42734) 2-31-14, admin@go-egvekinot.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 5,
        "col3": "Администрация Провиденского муниципального округа",
        "col4": "Администрация Провиденского муниципального округа (689251, Чукотский автономный округ, пгт Провидения, ул. Набережная Дежнева, д. 8-а, "
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 6,
        "col3": "Администрация муниципального округа Певек",
        "col4": "Администрация муниципального округа Певек (689400, Чукотский автономный округ, г. Певек, ул. Обручева, д. 29, тел. (42737) 4-21-42, chaunadmin@mail.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 7,
        "col3": "Администрация Чукотского муниципального района ",
        "col4": "Администрация Чукотского муниципального района (689300, Чукотский автономный округ, с. Лаврентия, ул. Советская, д. 15, тел. (42736) 2-28-56, od@chukotraion.ru)."
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 1,
        "col3": "Управление делами Правительства Ямало-Ненецкого автономного округа",
        "col4": "629008, ЯНАО, г. Салехард, пр. Молодёжи, д. 9, 8 (34922) 2-24-65, ud@yanao.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 1,
        "col3": "Министерство \nимущественных отношений \nЯрославской области",
        "col4": "Советская ул., д. 69,\n г. Ярославль, 150003\nТелефон (4852) 40-07-91\ne-mail: mio@yarregion.ru"
    }
]