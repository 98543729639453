import React from "react";
import s from "./Navigator.module.css"
import { useState, useMemo } from "react";
import { CaretRightOutlined } from "@ant-design/icons"
import { navArrayFirst } from "../../assets/Navigator/navArrayFirst";
import { navArrayEighth } from "../../assets/Navigator/navArrayEighth";
import { navArraySeventh } from "../../assets/Navigator/navArraySeventh";
import { navArraySixth } from "../../assets/Navigator/navArraySixth";
import { navArrayThird } from "../../assets/Navigator/navArrayThird";
import { navArraySecond } from "../../assets/Navigator/navArraySecond";
import { navArrayFourth } from "../../assets/Navigator/navArrayFourth";
import { navArrayFifth } from "../../assets/Navigator/navArrayFifth";

const Navigator2025 = () => {
     
    const groupedData1 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArrayFirst.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData2 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArraySecond.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData3 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArrayThird.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData4 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArrayFourth.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData5 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArrayFifth.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData6 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArraySixth.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData7 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArraySeventh.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []); 
    const groupedData8 = useMemo(() => {
        const groups = new Map();
        const uniqueCol1 = [];

        navArrayEighth.forEach(item => {
            if (!groups.has(item.col1)) {
                groups.set(item.col1, []);
                uniqueCol1.push(item.col1);
            }
            groups.get(item.col1).push(item);
        });

        return uniqueCol1.map(col1 => ({
            col1,
            items: groups.get(col1)
        }));
    }, []);

    return <div>
        <div className={s.newHeader}>
            <h2>
                Перечень уполномоченных органов опеки и попечительства
            </h2>
        </div>

        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы на принятие решений о включении в список детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа, подлежащих обеспечению жилыми помещениями</summary>
            <div className={s.greyBlock}>
                {groupedData1.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы на обеспечение жилыми помещениями детей-сирот, детей, оставшихся без попечения родителей, и лиц из их числа</summary>
            <div className={s.greyBlock}>
                {groupedData2.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы на принятие решения о предоставлении выплаты на приобретение благоустроенного жилого помещения в собственность или для полного погашения кредита (займа) по договору, обязательства заемщика по которому обеспечены ипотекой, в соответствии со статьей 8.1. Федерального закона от 21.12.1996 г. № 159-ФЗ «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей»</summary>
            <div className={s.greyBlock}>
                {groupedData3.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы на принятие решения о соответствии приобретаемого получателем сертификата жилого помещения установленным санитарным и техническим правилам и нормам, иным требованиям законодательства Российской Федерации</summary>
            <div className={s.greyBlock}>
                {groupedData4.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы на принятие решения о сокращении срока действия договора найма специализированного жилого помещения, заключенного с лицами, которые ранее относились к категории детей-сирот, детей, оставшихся без попечения родителей, и лиц из числа детей-сирот</summary>
            <div className={s.greyBlock}>
                {groupedData5.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Органы, уполномоченные на выдачу заключений об отсутствии у лиц, подавших заявления о предоставлении выплаты, предусмотренной статьей 8.1. Федерального закона от 21.12.1996 г. № 159-ФЗ «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей», обстоятельств, свидетельствующих о необходимости оказания таким лицам содействия в преодолении трудной жизненной ситуации</summary>
            <div className={s.greyBlock}>
                {groupedData6.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Органы, уполномоченные на выдачу заключений об отсутствии у лиц, подавших заявления о сокращении срока действия договора найма специализированного жилого помещения, обстоятельств, свидетельствующих о необходимости оказания таким лицам содействия в преодолении трудной жизненной ситуации </summary>
            <div className={s.greyBlock}>
                {groupedData7.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>
        <details className={s.mainDropdown}>
            <summary>Уполномоченные органы для адресации межведомственного запроса о соответствии жилого помещения, приобретаемого получателем выплаты (в соответствии статьей 8.1. Федерального закона от 21.12.1996 г. № 159-ФЗ «О дополнительных гарантиях по социальной поддержке детей-сирот и детей, оставшихся без попечения родителей»), установленным санитарным и техническим правилам и нормам, иным требованиям законодательства</summary>
            <div className={s.greyBlock}>
                {groupedData8.map((group, index) => (
                    <Region key={index} group={group} />
                ))}
            </div>
        </details>

        <p></p>
    </div>
}

const Region = ({ group }) => {
    const [active, setActive] = useState(false)

    return <div style={{ paddingTop: "20px" }}>
        <div className={s.item} onClick={() => {
            active ? setActive(false) : setActive(true)
        }}>
            <strong>
                {group.col1}
            </strong>
            <CaretRightOutlined
                style={{
                    rotate: `${active ? "90deg" : "0deg"}`,
                    transition: "all 1s",
                    marginLeft: "8px",
                    fontSize: "20px"
                }} />
        </div>
        {active && (
            <div>
                {group.items.map((item, index) => (
                    <Location
                        key={index}
                        col2={item.col2}
                        col3={item.col3}
                        col4={item.col4}
                        col5={item.col5 || ''}
                        col6={item.col6 || ''}
                        col7={item.col7 || ''}
                        col8={item.col8 || ''}
                        col9={item.col9 || ''}
                    />
                ))}
            </div>
        )}
    </div>
}

const Location = ({ col2, col3, col4, col5, col6, col7, col8, col9 }) => {
    const col7Split = col7 ? col7.split(", ") : ['', '', ''];
    return <div className={s.locations}>
        <div className={s.lockationsCol}>
            {col2}
        </div>
        <div className={s.lockationsCol}>
            {col3}
        </div>
        <div className={s.lockationsCol}>
            {col4}
        </div>
        <div className={s.lockationsCol}>
            {col5}
        </div>
        <div className={s.lockationsCol}>
            {col6}
        </div>
        <div className={s.lockationsCol} style={{ wordBreak: "break-all" }}>
            {col7Split[0] && <a href={col7Split[0]}>{col7Split[0]} </a>}
            {col7Split[1] && <div><a href={col7Split[1]}>{col7Split[1]} </a></div>}
            {col7Split[2] && <div><a href={col7Split[2]}>{col7Split[2]} </a></div>}
        </div>
        <div className={s.lockationsCol}>
            {col8}
        </div>
        <div className={s.lockationsCol} style={{ wordBreak: "break-word" }}>
            {col9}
        </div>
    </div>
}

export default Navigator2025;