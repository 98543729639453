export const navArraySecond = [
    {
        "col1": "Алтайский край",
        "col2": 1,
        "col3": "Краевое государственное казенное учреждение «Региональное жилищное управление»",
        "col4": "Алтайский край, г. Барнаул, ул. Крупской, 101/1 / info@gu22.ru / 8 (800) 350 26 61, (3852) 556695"
    },
    {
        "col1": "Амурская область",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Амурской области",
        "col4": "675000 Амурская область,\nг. Благовещенск,\nул. Шимановского, 8/ grishkovaev@mszn.amurobl.ru\n+7(4162) 200-781"
    },
    {
        "col1": "Архангельская область",
        "col2": 1,
        "col3": "Департамент по вопросам семьи, опеки и попечительства городского округа «Город Архангельск»",
        "col4": "163000, г. Архангельск, пл. Ленина, д. 5 Телефон: 8 (8182) 60-74-39 , Факс: 8 (8182) 60-74-24, dzsp@arhcity.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 2,
        "col3": "Отдел опеки и попечительства администрации городского округа Архангельской области «Город Коряжма»",
        "col4": "165651, Архангельская область, \nг. Коряжма, ул. Ленина, д. 29,Телефон/факс: \n8 (818-50)3-03-08, 3-49-57, opeka1@koradm.ru\n\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 3,
        "col3": "Отдел опеки и попечительства управления по социальным вопросам администрации городского округа Архангельской области «Котлас»",
        "col4": "165300, Архангельская область, г. Котлас, пл. Советов, д. 3,Телефон: 8(81837) 2-06-39, 2-05-57, oop50481@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 4,
        "col3": "Орган опеки и попечительства Муниципального учреждения «Управление образования и социальной сферы администрации Мирного»",
        "col4": "164170, Архангельская область, г. Мирный,     ул. Ленина, д. 33, Телефон, факс: 8(81834) 5-04-20, opekamirnyi@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 5,
        "col3": "Отдел опеки и попечительства управления социальной политики администрации городского округа Архангельской области «Город Новодвинск»",
        "col4": "164900, Архангельская область, г. Новодвинск, ул. Фронтовых бригад, д. 6, корп. 1, Телефон: 8(81852) 5-12-97, а.volkova@novadmin.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 6,
        "col3": "Отдел опеки и попечительства над несовершеннолетними Управления социального развития, опеки и попечительства городского округа Архангельской области «Северодвинск»",
        "col4": "164507, Архангельская область,г. Северодвинск, ул. Ломоносова, д. 41а,Телефон: 8(8184) 56-98-16, opekadeti@adm.severodvinsk.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Вельского муниципального района",
        "col4": "165150, Архангельская область, Вельский район, г. Вельск, ул. Гагарина, д. 37, Телефон/Факс: 8(81836) 6-40-59,opeka@velskmo.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 8,
        "col3": "Отдел опеки и попечительства Управления образования администрации Верхнетоемского муниципального округа Архангельской области ",
        "col4": "165500, Архангельская область, Верхнетоемский район, с. Верхняя Тойма, ул. Кировская, д. 6, Телефон, факс: 8(81854) 3-18-37, opekavt@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 9,
        "col3": "Отдел опеки и попечительства Управления образования и культуры администрации Вилегодского муниципального округа Архангельской области",
        "col4": "165680, Архангельская область, Вилегодский район, с. Ильинско-Подомское, ул. Советская, д. 34, Телефон, факс: 8(81843) 4-14-63, vilopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 10,
        "col3": "Отдел опеки и попечительства администрации Виноградовского муниципального округа Архангельской области",
        "col4": "164570, Архангельская область, Виноградовский район,п. Березник, ул. П. Виноградова, д. 83-А, Телефон, факс: 8(81831)      2-19-33, opeka@vmoao.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 11,
        "col3": "Отдел опеки и попечительства администрации Каргопольского муниципального округа Архангельской области",
        "col4": "164110, Архангельская область, Каргопольский район, г. Каргополь, ул. Победы, д. 12, Телефон, факс: 8(81841)2-26-30,                      2-18-45, kargopeka@mail.ru\n"
    },
    {
        "col1": "Архангельская область",
        "col2": 12,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Коношский муниципальный район»",
        "col4": "164010, Архангельская область, Коношский район, п. Коноша, ул. Советская, д. 76, Телефон, факс: 8 (81858)2-21-63, 8(81858)2-37-01, opeka.konosha@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 13,
        "col3": "Отдел опеки и попечительства Управления по социальной политике администрации Котласского муниципального округа Архангельской области",
        "col4": "165300, Архангельская область,  г. Котлас, пл. Советов, д. 9, Телефон: 8(81837) 2-17-20                     Факс: 8 (81837) 2-11-51, opekakr@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Красноборского муниципального округа Архангельской области",
        "col4": "165430, Архангельская область, Красноборский район, с. Красноборск, ул. Гагарина, д. 7-А, Телефон, факс: 8(81840) 3-15-57, oopkras@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 15,
        "col3": "Территориальный отдел по опеке и попечительству администрации муниципального образования «Ленский муниципальный район»",
        "col4": "165780, Архангельская область, Ленский район, с. Яренск, ул. Братьев Покровских, д. 19, Телефон, факс: 8(81859) 5-35-71, lenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства Управления образования администрации Лешуконского муниципального округа Архангельской области",
        "col4": "164670, Архангельская область, Лешуконский район, с. Лешуконское, ул. Красных Партизан,  д. 12, Телефон, факс: 8(81833) 3-00-13, leschopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 17,
        "col3": "Отдел опеки и попечительства управления образования администрации Мезенского муниципального округа Архангельской области",
        "col4": "164750, Архангельская область, Мезенский район, г. Мезень, пр. Советский, д. 51, Телефон, факс: 8(81848) 9-16-89, mezenopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Няндомского муниципального округа Архангельской области",
        "col4": "164200, Архангельская область, Няндомский район, г. Няндома,  ул. 60 лет Октября, д. 13, Телефон, факс: 8(818-38)6-21-68, nanopeka@yandex.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 19,
        "col3": "Отдел опеки и попечительства администрации муниципального образования «Онежский муниципальный район»",
        "col4": "164840, Архангельская область, Онежский район, г. Онега, ул. Шаревского, д. 6, Телефон: 8 (81839) 7-03-68\nФакс: 8 (818-39) 7-04-21, opeka@onegaland.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 20,
        "col3": "Отдел по социальным вопросам, опеки и попечительства администрации Пинежского муниципального округа Архангельской области",
        "col4": "164600, Архангельская область, Пинежский район, с. Карпогоры, ул. Победы, д. 10-Б, Телефон, факс: 8 (81856)2-17-28, 2-13-11, pinopeka@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации Плесецкого муниципального округа Архангельской области",
        "col4": "164262, Архангельская область, Плесецкий район, п. Плесецк, ул. Ленина, д. 33, Телефон: 8 (81832)7-35-94, 7-40-83     Факс: 8 (818-32) 7-28-98, oop-amoples@plesadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 22,
        "col3": "Отдел по опеке и попечительству администрации Приморского муниципального округа Архангельской области",
        "col4": "163002, г. Архангельск, пр. Ломоносова, д. 30, Телефон, факс :8(8182) 68-01-39, opeka@primadm.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 23,
        "col3": "Отдел опеки и попечительства администрации Устьянского муниципального округа Архангельской области",
        "col4": "165210, Архангельская область, Устьянский район, рп. Октябрьский, ул. Комсомольская,  д. 7, Телефон, факс: 8 (818-55)5-11-41, ustopekun@mail.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 24,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних управления образования администрации Холмогорского муниципального округа Архангельской области",
        "col4": "164530, Архангельская область, Холмогорский район, с. Холмогоры, пл. Морозова, д. 10, Телефон: 8(818-30) 3-34-99, opeka-holmogory@rambler.ru"
    },
    {
        "col1": "Архангельская область",
        "col2": 25,
        "col3": "Отдел опеки и попечительства администрации Шенкурского муниципального округа Архангельской области",
        "col4": "165160, Архангельская область, Шенкурский район, г. Шенкурск, ул. Детгородок, д. 8, Телефон, факс: 8(818-51) 4-04-52, shenkopeka@mail.ru"
    },
    {
        "col1": "Астраханская область\n",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Астраханской области ",
        "col4": "почтовый адрес: 414000, \nг. Астрахань, \nул. Набережная 1 Мая, 96;\nадрес электронной почты: minstroy@astrobl.ru;\nТел. (8512) 99-98-40"
    },
    {
        "col1": "Белгородская область",
        "col2": 1,
        "col3": "Комитет строительства администрации Белгородского района \n",
        "col4": " 308007, Белгородская область, г.Белгород, ул. Шершнева, д. 1а,\ngilkombelrn@mail.ru,                       (4722) 26-13-46\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 2,
        "col3": "Администрация Борисовского района",
        "col4": "309340, Белгородская область, Борисовский район, п. Борисовка,            пл. Ушакова, д. 2, \nbor-gkh@mail.ru,             (47246)5-13-36  \n"
    },
    {
        "col1": "Белгородская область",
        "col2": 3,
        "col3": "Администрация Вейделевского района",
        "col4": "309720, Белгородская область, Вейделевский район, п.Вейделевка, ул. Первомайская, д. 1\noksadm@ve.belregion.ru\n(47237) 5-55-61\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 4,
        "col3": "Администрация муниципального района «Волоконовский район»",
        "col4": "309650, Белгородская область, Волоконовский район, п. Волоконовка, ул. Ленина, д.60,\nokcvol90@yandex.ru\n(47235)5-03-31\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 5,
        "col3": "Администрация Ивнянского района",
        "col4": "309110, Белгородская область, Ивнянский район, п. Ивня, ул. Ленина, д. 20, \nfilshina_ev@iv.belregion.ru,\n(47243) 5-57-08\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 6,
        "col3": "Комитет муниципальной собственности и земельных отношений администрации Корочанского района",
        "col4": "309210, Белгородская область, Корочанский район,\nг. Короча, пл. Васильева, д. 28\nimuschestvo.koradm@yandex.ru, (47231)5-55-80\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 7,
        "col3": "Межведомственная комиссия при администрации муниципального района «Красненский район» ",
        "col4": "309870, Белгородская область, Красненский район, с.Красное, ул. Подгорная, д.4, (47262) 5-27-97, 5-20-88, ovchinnikova_mr@kr.belregion.ru, uksigkh@kr.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 8,
        "col3": "Управление имущественных и земельных отношений Красногвардейского района Белгородской области",
        "col4": "Адрес: 309504, Белгородская область,  Красногвардейский район, г. Бирюч, пл. Соборная, д.1,\noizoakr@kg.belregion.ru,              (47247) 3-33-77\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 9,
        "col3": "Администрация муниципального района «Краснояружский район»",
        "col4": "309420, Белгородская область, Краснояружский район, п.Красная\nЯруга, ул. Центральная, д. 14, (47263) 46-9-14,  yaruga@ky.belregion.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 10,
        "col3": "Комитет имущественных, земельных отношений и правового обеспечения администрации Прохоровского района",
        "col4": "Белгородская область, Прохоровский район,\nп. Прохоровка, \nул. Советская, д.162,\ngil.polit@yandex.ru,           (47242) 2-15-55\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 11,
        "col3": "Муниципальное казенное учреждение «Управление строительства и ЖКХ Ракитянского района»",
        "col4": "309310, Белгородская область, Ракитянский район,\nп. Ракитное, ул, Советская, д.5, (47245) 57-3-94, uprstroit@ra.belregion.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 12,
        "col3": "Отдел имущественных правоотношений администрации Ровеньского района",
        "col4": "309740 Белгородская область, Ровеньский район, п.Ровеньки, ул. Ленина, д.50, \narovenki@ro.belregion.ru, (47238)5-66-69\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 13,
        "col3": "Управление имущественных и земельных отношений администрации Чернянского района",
        "col4": "309560, Белгородская область, Чернянский район, \nп. Чернянка, пл. Октябрьская, д.13,\nimushestvo41@ch.belregion.ru\n(47232)5-70-83\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 14,
        "col3": "Жилищная комиссия при администрации Алексеевского муниципального округа",
        "col4": "309850, Белгородская область, г. Алексеевка, Алексеевский муниципальный округ, пл. Победы, д.73, (47234) 3-17- 42, 3-40-76, fadeeva-64@mail.ru, ryabtseva_sb@al.belregion.ru\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 15,
        "col3": "Управление муниципальной\nсобственности и земельных\nресурсов администрации\nВалуйского муниципального\nокруга",
        "col4": "309996, Белгородская область, Валуйский муниципальный округ,\nг. Валуйки, пл. Красная, д. 1, munsobstv@va.belregion.ru,\n(47236) 3-14-33, 3-05-45"
    },
    {
        "col1": "Белгородская область",
        "col2": 16,
        "col3": "Управление  по строительству, транспорту, ЖКХ и ТЭК администрации Грайворонского муниципального округа",
        "col4": "309370, Белгородская область, Грайворонский район, г. Грайворон, ул. Комсомольская, д. 21,\npolina.davydenko.71@mail.ru, (47261)4-52-96\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 17,
        "col3": "Жилищная комиссия при администрации Новооскольского муниципального округа",
        "col4": "309640, Белгородская область, Новооскольский муниципальный округ, г.Новый Оскол, ул. 1 Мая, д.4, noskol@no.belregion.ru, goncharova_ms1@no.belregion.ru, (47233) 4-61-47\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 18,
        "col3": "Комиссия по жилищным вопросам при администрации Шебекинского муниципального округа",
        "col4": "309290, Белгородская область, Шебекинский муниципальный округ, г.Шебекино, пл. Центральная, д. 2,           (47248)3-29-55, Zhil_Hoz@sh.belregion.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 19,
        "col3": "МКУ \"Управление капитального строительства Яковлевского муниципального округа\"",
        "col4": "309070, Белгородская область, Яковлевский муниципальный округ, г.Строитель, ул. Ленина, д.2, oksstr31@yandex.ru  (47244) 6-94-03, "
    },
    {
        "col1": "Белгородская область",
        "col2": 20,
        "col3": "Жилищное управление администрации города Белгорода\n",
        "col4": "308007, Белгородская область, г. Белгород, ул. Некрасова, д. 9/15, (4722) 26-84-92, 26-84-95, gil_otdel@mail.ru"
    },
    {
        "col1": "Белгородская область",
        "col2": 21,
        "col3": "Управление жилищно-коммунального комплекса и систем жизнеобеспечения администрации Губкинского городского округа",
        "col4": "309186, Белгородская область, Губкинский городской округ,\nг. Губкин, ул. Победы, д. 3, uzkk@gu.belregion.ru,\n(47241) 5-29-90\n"
    },
    {
        "col1": "Белгородская область",
        "col2": 22,
        "col3": "Жилищное управление департамента жилищно-коммунального хозяйства администрации Старооскольского городского округа Белгородской области",
        "col4": "309504, Белгородская область, Старооскольский городской округ, г. Старый Оскол, ул. Комсомольская, д. 57,\n gu@so.belregion.ru, (4725) 44-52-87\n"
    },
    {
        "col1": "Брянская область",
        "col2": 1,
        "col3": "Брянская городская администрация",
        "col4": "241050, Брянская область, г. Брянск,                 пр-т Ленина, д. 35, goradm@bga32.ru,  8(4832) 66-40-44"
    },
    {
        "col1": "Брянская область",
        "col2": 2,
        "col3": "Клинцовская городская администрация",
        "col4": "243140, Брянская область, г. Клинцы,             ул. Октябрьская, д. 42, klintsi@mail.ru, 8(48336) 4-03-28"
    },
    {
        "col1": "Брянская область",
        "col2": 3,
        "col3": "Новозыбковская городская администрация",
        "col4": "243020, Брянская область, г. Новозыбков,        пл. Октябрьской Революции, д. 2, novozibkovadm@mail.ru, 8(48343)5-69-46"
    },
    {
        "col1": "Брянская область",
        "col2": 4,
        "col3": "Администрация города Сельцо ",
        "col4": "241550, Брянская область, г. Сельцо,              ул. Горького, д. 14, selco32@mail.ru, 8(4832)97-44-17"
    },
    {
        "col1": "Брянская область",
        "col2": 5,
        "col3": "Администрация Стародубского муниципального округа",
        "col4": "243240, Брянская область, г. Стародуб,           ул. Свердлова, д. 4, stadmo2008@yandex.ru, 8(48348) 2-24-22"
    },
    {
        "col1": "Брянская область",
        "col2": 6,
        "col3": "Администрация города Фокино",
        "col4": "242610, Брянская область, г. Фокино,              ул. Ленина, д. 13, g_fokino@mail.ru, 8(483333)4-79-60"
    },
    {
        "col1": "Брянская область",
        "col2": 7,
        "col3": "Администрация Брасовского муниципального района",
        "col4": "242300, Брянская область, Брасовский район,    п. Локоть, пр-т Ленина, д. 2. edds.bras@yandex.ru, 8(48354) 9-11-42"
    },
    {
        "col1": "Брянская область",
        "col2": 8,
        "col3": "Админситрация Брянского муниципального района",
        "col4": "241525, Брянская область, Брянский район,      с. Глинищево, ул. П.М. Яшенина, д. 9, admbr@mail.ru, 8(4832)94-16-70"
    },
    {
        "col1": "Брянская область",
        "col2": 9,
        "col3": "Администрация Выгоничского муниципального  района",
        "col4": "243361, Брянская область, Выгоничский район, п. Выгоничи, ул. Ленина, д. 51, adminwr@mail.ru, 8(48341)2-14-52"
    },
    {
        "col1": "Брянская область",
        "col2": 10,
        "col3": "Администрация Гордеевского муниципального  района",
        "col4": "243650, Брянская область, Гордеевский район, с. Гордеевка, ул. Победы, д. 10, admorgotdel@mail.ru, 8(48340) 2-14-46"
    },
    {
        "col1": "Брянская область",
        "col2": 11,
        "col3": "Администрация Дубровского муниципального района ",
        "col4": "242750, Брянская область, Дубровский район, п. Дубровка, ул. Победы, д. 18, dbr-orgotdel@yandex.ru, 8(48332)9-15-25"
    },
    {
        "col1": "Брянская область",
        "col2": 12,
        "col3": "Администрация Дятьковского муниципального района",
        "col4": "242600, Брянская область, Дятьковский район, г. Дятьково, д. 141-а, radmdtk@mail.ru, 8(48333) 3-22-03"
    },
    {
        "col1": "Брянская область",
        "col2": 13,
        "col3": "Администрация Жирятинского муниципального района",
        "col4": "242030, Брянская область, Жирятинский район, с. Жирятино, ул. Мира, д. 10, adm@juratino.ru, 8 (48344) 3-06-06"
    },
    {
        "col1": "Брянская область",
        "col2": 14,
        "col3": "Администрация Жуковского муниципального округа",
        "col4": "242700, Брянская область, Жуковский муниципальный округ, г. Жуковка,                 ул. Октябрьская, д. 1, adm@zh32.ru, 8 (48334) 3-26-71"
    },
    {
        "col1": "Брянская область",
        "col2": 15,
        "col3": "Администрация Злынковского муниципального района",
        "col4": "243600, Брянская область, Злынковский район, г. Злынка, пл. Свободы, д. 35, RAZLYNKA2@MAIL.RU, 8 (48358) 2-10-31"
    },
    {
        "col1": "Брянская область",
        "col2": 16,
        "col3": "Администрация Карачевского муниципального района",
        "col4": "242500, Брянская область, Карачевский район, г. Карачев, ул. Советская, д. 64, inbox@karadmin.ru, 8(48335) 2-33-91"
    },
    {
        "col1": "Брянская область",
        "col2": 17,
        "col3": "Администрация Клетнянского муниципального района",
        "col4": "242820, Брянская область, Клетнянский район, п. Клетня, ул. Ленина, д. 92, klet-2007@yandex.ru, 8(48338) 9-15-61"
    },
    {
        "col1": "Брянская область",
        "col2": 18,
        "col3": "Администрация Климовского муниципального района",
        "col4": "243040, Брянская область, Климовский район, пгт. Климово, пл. Ленина, д. 1, kladm@inbox.ru, 8 (48347) 2-13-38"
    },
    {
        "col1": "Брянская область",
        "col2": 19,
        "col3": "Администрация Клинцовского муниципального района",
        "col4": "243140, Брянская область, г. Клинцы,              ул. Октябрьская, д. 42, priymnay@mail.ru, 8(48336) 4-05-12"
    },
    {
        "col1": "Брянская область",
        "col2": 20,
        "col3": "Администрация Комаричского муниципального района",
        "col4": "242400, Брянская область, Комаричский район, п. Комаричи, ул. Советская, д. 21, adminkom@mail.ru, 8(48355) 9-15-98"
    },
    {
        "col1": "Брянская область",
        "col2": 21,
        "col3": "Администрация Красногорского муниципального района",
        "col4": "243160, Брянская область, Красногорский район, пгт. Красная Гора, ул. Первомайская,    д. 6, krgadm@yandex.ru, 8(48346) 9-17-76"
    },
    {
        "col1": "Брянская область",
        "col2": 22,
        "col3": "Администрация Мглинского муниципального района",
        "col4": "243220, Брянская область, Мглинский район,   г. Мглин, пл. Советская, д. 6, mgladm@mail.ru, 8 (48339) 2-13-45"
    },
    {
        "col1": "Брянская область",
        "col2": 23,
        "col3": "Администрация Навлинского муниципального района",
        "col4": "242130, Брянская область, Навлинский район, п. Навля, ул. Красных Партизан, д. 21, priem-nvl@yandex.ru, 8 (48342) 2-26-91"
    },
    {
        "col1": "Брянская область",
        "col2": 24,
        "col3": "Администрация Погарского муниципального района",
        "col4": "243550, Брянская область, Погарский район, пгт. Погар, ул. Ленина, д.1, admin@pogaradm.ru, 8(48342) 2-12-80"
    },
    {
        "col1": "Брянская область",
        "col2": 25,
        "col3": "Администрация Почепского муниципального района",
        "col4": "243400, Брянская область, Почепский район,    г. Почеп, пл. Октябрьская, д. 3а, orgotdel74@mail.ru, 8(48345) 3-02-52"
    },
    {
        "col1": "Брянская область",
        "col2": 26,
        "col3": "Администрация Рогнединского муниципального района",
        "col4": "242770, Брянская область, Рогнединский район, п. Рогнедино, ул. Ленина, д. 29, admrrognedino@mail.ru, 8(48331) 2-13-53"
    },
    {
        "col1": "Брянская область",
        "col2": 27,
        "col3": "Администрация Севского муниципального района",
        "col4": "242440, Брянская область, Севский район,         г. Севск, ул. Розы Люксембург, д. 50, admsevsk@mail.ru, 8(48356) 9-14-33"
    },
    {
        "col1": "Брянская область",
        "col2": 28,
        "col3": "Администрация Суземского муниципального района",
        "col4": "242190, Брянская область, Суземский район,    п. Суземка, пл. Ленина, д. 1, info@adminsuzemka.ru, 8(48353) 2-18-34"
    },
    {
        "col1": "Брянская область",
        "col2": 29,
        "col3": "Администрация Суражского муниципального района",
        "col4": "243500, Брянская область, Суражский район,   г. Сураж, ул. Ленина, д. 40, 84833021434@mail.ru, 8(48330) 2-14-34"
    },
    {
        "col1": "Брянская область",
        "col2": 30,
        "col3": "Администрация Трубчевскогоского муниципального района",
        "col4": "242221, Брянская область, Трубчевский район, г. Трубчевск, ул. Брянская, д. 59,  raisovet-trubchevsk@yandex.ru, 8(48352) 2-22-81"
    },
    {
        "col1": "Брянская область",
        "col2": 31,
        "col3": "Администрация Унечского муниципального района",
        "col4": "243300, Брянская область, Унечский район,      г. Унеча, пл. Ленина, д. 1, unradm@mail.ru, 8 (48351) 2-10-32"
    },
    {
        "col1": "Владимирская область",
        "col2": 1,
        "col3": "Администрация г. Владимира",
        "col4": "600000, г. Владимир, ул. Горького, д. 36\n8 (4922) 35-33-33, 35-41-26 citizens@vladimir-city.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 2,
        "col3": "Администрация г. Гусь-Хрустальный Владимирской области",
        "col4": "601500, Владимирская обл., г. Гусь-Хрустальный, ул.Калинина, д. 1\n8 (49241) 2-45-53\npriem@gusadmin.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 3,
        "col3": "Администрация г. Коврова Владимирской области",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Краснознаменная, д.6\n8 (49232) 3-11-35\ninfo@kovrov-gorod.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 4,
        "col3": "Администрация г. Мурома Владимирской области",
        "col4": "602267, Владимирская обл., г. Муром, пл.1100-летия, д.1\n8 (49234) 3-11-02\npost@murom.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 5,
        "col3": "Администрация ЗАТО г. Радужный Владимирской области",
        "col4": "600910, Владимирская обл., г. Радужный, кв-л 1-й, д. 55\n8 (49254) 3-28-99\nraduga_oszn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 6,
        "col3": "Администрация Александровского района",
        "col4": "601650, Владимирская обл., г. Александров, ул. Красной молодёжи, д.7\n8 (49244) 2-21-31\nalexan@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 7,
        "col3": "Администрация Вязниковского района",
        "col4": "601443, Владимирская обл., г. Вязники, ул. Комсомольская, д.1\n8 (49233) 2-51-38\nvyazn@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 8,
        "col3": "Администрация Гороховецкого района",
        "col4": "601481 Владимирская обл., г. Гороховец, ул. Ленина, д.93\n8 (49238) 2-15-65\nagr@gorohovec.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 9,
        "col3": "Администрация Гусь-Хрустального района",
        "col4": "601501, Владимирская обл., г. Гусь-Хрустальный, ул. Карла Либкнехта, д. 6\n8 (49241) 2-17-66\ngusr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 10,
        "col3": "Администрация Камешковского района",
        "col4": "601300, Владимирская обл., г. Камешково, ул. Свердлова, д. 10\n8 (49248) 2-12-07\npost@admkam.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 11,
        "col3": "Администрация Киржачского района",
        "col4": "601010, Владимирская обл., г. Киржач, ул. Серегина, д. 7\n8 (49237) 2-01-77\ninfo@kirzhach.su "
    },
    {
        "col1": "Владимирская область",
        "col2": 12,
        "col3": "Администрация Ковровского района",
        "col4": "601900, Владимирская обл., г. Ковров, ул. Дегтярёва, д. 34.\n8 (49232) 2-16-22\nkovrr@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 13,
        "col3": "Администрация Кольчугинского района",
        "col4": "601785, Владимирская область, Кольчугинский район, г. Кольчугино, пл. Ленина, д. 2.\n8 (49245) 2-39-83\nkolch@avo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 14,
        "col3": "Администрация Петушинского района",
        "col4": "601144, Владимирская обл., г. Петушки, ул. Советская площадь, дом 5.\n8 (49243) 2-20-82, 2-15-04\ninfo@petushki.info "
    },
    {
        "col1": "Владимирская область",
        "col2": 15,
        "col3": "Администрация Селивановского района",
        "col4": "602332, Владимирская обл., Селивановский район, пос. Красная Горбатка, ул. Красноармейская д.12\n8 (49236) 2-10-81\ncentr@selivanovo.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 16,
        "col3": "Администрация Судогодского района",
        "col4": "601351, Владимирская обл., г. Судогда, ул. Коммунистическая, зд. 1б\n8 (49235) 2-16-07\nadmsud@admsud.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 17,
        "col3": "Администрация Суздальского района",
        "col4": "601293, Владимирская обл., г. Суздаль, ул. Красная Площадь, д.1\n8 (49231) 2-13-45, 2-30-09\norg@suzdalregion.ru ; info@suzdalregion.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 18,
        "col3": "Администрация Юрьев-Польского района",
        "col4": "601800, Владимирская обл., г. Юрьев-Польский, ул. Шибанкова, д. 33\n8 (49246) 2-24-35\nyurier@avo.ru \n"
    },
    {
        "col1": "Владимирская область",
        "col2": 19,
        "col3": "Администрация Меленковского муниципального округа",
        "col4": "602102, Владимирская обл., Меленковский район, город Меленки, Красноармейская улица, 102.\n8 (49247) 2-20-94\nmelenky@melenky.ru "
    },
    {
        "col1": "Владимирская область",
        "col2": 20,
        "col3": "Администрация Собинского муниципального округа",
        "col4": "601204, Владимирская обл., Собинский район, г Собинка, ул. Садовая, д. 4\n8 (49242) 2-20-35\npost@sbnray.ru "
    },
    {
        "col1": "Волгоградская область",
        "col2": 1,
        "col3": "Комитет строительства Волгоградской области",
        "col4": "400066, Волгоград, ул.им.Скосырева, д.7, телефоны: (8442) 30-83-00, 30-83-57, 30-84-39, 30-84-49, oblstroy@volganet.ru"
    },
    {
        "col1": "Вологодская область",
        "col2": 1,
        "col3": "Министерство строительства Вологодской области",
        "col4": "160000, г. Вологда, ул. Герцена, д. 27, sidorova.ev@depstroy.gov35.ru, (8172) 23-00-55 (доб. 1251)"
    },
    {
        "col1": "Воронежская область",
        "col2": 1,
        "col3": "Министерство социальной защиты Воронежской области\n(принятие решения о предоставлении жилого помещения)\n",
        "col4": "394006, Воронежская обл.,\nг. Воронеж, ул. Ворошилова, д. 14 8(473)277-18-17\nsocial@govvrn.ru\n"
    },
    {
        "col1": "Воронежская область",
        "col2": 2,
        "col3": "Министерство имущественных и земельных отношений Воронежской области\n(заключение договора найма жилого помещения специализированного жилищного фонда Воронежской области)\n",
        "col4": "394018, Воронежская обл.,\nг. Воронеж, пл. Ленина, д. 12 8(473)212-73-65\nmizo@govvrn.ru\n"
    },
    {
        "col1": "Донецкая Народная Республика",
        "col2": 1,
        "col3": "-",
        "col4": "-",
    },
    {
        "col1": "Еврейская АО",
        "col2": 1,
        "col3": "Департамент строительства и жилищно-коммунального хозяйства правительства Еврейской автономной области",
        "col4": "679000, Еврейская автономная область, г. Биробиджан, проспект 60 лет СССР, д. 18,\ngrad@post.eao.ru, \n8 (42622) 2 18 45"
    },
    {
        "col1": "Забайкальский край",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты населения Забайкальского края",
        "col4": "672000, Забайкальский край, г. Чита, ул. Курнатовского, д. 7,  pochta@minsz.e-zab.ru (только для служебной корреспонденции), 8(3022) 35 50 85 приемная"
    },
    {
        "col1": "Ивановская область",
        "col2": 1,
        "col3": "Администрация города Иванова",
        "col4": "153000, г. Иваново, \nпл. Революции, д. 6, \nтел. 8 (4932) 59-46-48, office@ivgoradm.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 2,
        "col3": "Администрация городского округа Вичуга",
        "col4": "155331, Ивановская область, \nг. Вичуга, ул. 50 лет Октября, д. 15,\nтел. 8 (49354) 2-10-11, \nadminvich@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 3,
        "col3": "Администрация городского округа Кинешма",
        "col4": "155800, г. Кинешма, \nул. им. Фрунзе, д. 4\nтел. 8 (49331) 5-30-50,\nmail@admkineshma.ru, admkineshma@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 4,
        "col3": "Администрация городского округа Кохма",
        "col4": "153510, Ивановская область, \nг. Кохма, ул. Советская, д. 23,\nтел. 8 (4932) 55-20-16, \nadm@kohma37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 5,
        "col3": "Администрация городского округа Тейково",
        "col4": "155040, Ивановская область,\nг. Тейково, пл. Ленина, д. 4, \nтел. 8 (49343) 4-02-01,\nadmin_tei@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 6,
        "col3": "Администрация городского округа Шуя",
        "col4": "155900, Ивановская область, \nг. Шуя, ул. Советская, д. 48,\nтел. 8 (49351) 4-12-05, \nokrugshuya@ivreg.ru, admin@okrugshuya.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 7,
        "col3": "Администрация Верхнеландеховского муниципального района",
        "col4": "155210, Ивановская область, \nп. Верхний Ландех, \nул. Первомайская, д. 3,\nтел. 8 (49349) 2-14-22,\ninfo@vlandeh-admin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 8,
        "col3": "Администрация Вичугского муниципального района",
        "col4": "155331, Ивановская область,\nг. Вичуга, переулок Широкий, д. 4, \nтел. 8 (49354) 2-44-54,\nadmvmr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 9,
        "col3": "Администрация Гаврилово-Посадского муниципального района",
        "col4": "155000, Ивановская область, г. Гаврилов Посад, ул. Розы Люксембург, д. 3,\nтел. 8 (49355) 2-12-60,\nadm_gp37@ivreg.ru,\n"
    },
    {
        "col1": "Ивановская область",
        "col2": 10,
        "col3": "Администрация Заволжского муниципального района",
        "col4": "155410, Ивановская область, г. Заволжск, ул. Мира, д. 7,\nтел. 8 (49333) 6-00-49,\n37zavadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 11,
        "col3": "Администрация Ивановского муниципального района",
        "col4": "153008, г. Иваново, ул. Постышева, д. 46,\nтел. 8 (4932) 30-03-96,\nadm.ivrn@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 12,
        "col3": "Администрация Ильинского муниципального района",
        "col4": "155060, Ивановская область, п. Ильинское-Хованское, ул. Советская, д. 2,\nтел. 8 (49353) 2-12-04,\nilin@admilinskoe.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 13,
        "col3": "Администрация Кинешемского муниципального района",
        "col4": "155800, г. Кинешма, ул. им. Ленина, д. 12,\nтел. 8 (49331) 5-51-05,\nroot@mrkineshma.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 14,
        "col3": "Администрация Комсомольского муниципального района",
        "col4": "155150, Ивановская область, \nг. Комсомольск, ул. 50 лет ВЛКСМ, д. 2,\nтел. 8 (49352) 2-11-78,\nadmin.komsomolsk@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 15,
        "col3": "Администрация Лежневского муниципального района",
        "col4": "155120, Ивановская область, п. Лежнево, \nул. Октябрьская, д. 32, \nтел. 8 (49357) 2-12-04, \ninfo@lezhnevo.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 16,
        "col3": "Администрация Лухского муниципального района",
        "col4": "155270, п. Лух, \nул. Октябрьская, д. 4\nтел. 8 (49344) 2-13-45,\ninfo.luh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 17,
        "col3": "Администрация Палехского муниципального района",
        "col4": "155620, Ивановская область, п. Палех, \nул. Ленина, д. 1,\nтел. 8 (49334) 2-14-42,\npaleh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 18,
        "col3": "Администрация Пестяковского муниципального района",
        "col4": "155650 Ивановская область, пос. Пестяки, ул. Ленина,\n д. 4,\nтел. 8 (49346) 2-10-15,\npestyaki@pestyaki.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 19,
        "col3": "Администрация Приволжского муниципального района",
        "col4": "155550, Ивановская область, \nг. Приволжск, пер. Коминтерновский, \nд. 2,\nтел. 8 (49339) 2-19-71,\nreception@privadmin.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 20,
        "col3": "Администрация Пучежского муниципального района",
        "col4": "155360, Ивановская область, г. Пучеж, \nул. Ленина, д. 27,\nтел. 8 (49345) 2-12-01,\npuchezh@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 21,
        "col3": "Администрация Родниковского муниципального района",
        "col4": "155250, Ивановская область, г. Родники, ул. Советская, \nд. 8,\nтел. 8 (49336) 2-17-57,\npost@rodniki-37.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 22,
        "col3": "Администрация Савинского муниципального района",
        "col4": "155710, Ивановская область, п. Савино, ул. Первомайская, д. 22,\nтел. 8 (49356) 9-12-04,\nadm-savino@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 23,
        "col3": "Администрация Тейковского муниципального района",
        "col4": "155040, Ивановская область, г. Тейково, ул. Октябрьская, д. 2А,\nтел. 8 (49343) 2-26-05,\npriem.teikovo.raion@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 24,
        "col3": "Администрация Фурмановского муниципального района",
        "col4": "155520, г. Фурманов, \nул. Социалистическая, д. 15,\nтел. 8 (49341) 2-17-66,\nfurmanov@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 25,
        "col3": "Администрация Шуйского муниципального района",
        "col4": "155900, Ивановская область, г. Шуя, \nпл. Ленина, д. 7,\nтел. 8 (49351) 3-26-34,\nadm-shr@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 26,
        "col3": "Администрация Южского муниципального района",
        "col4": "155630, Ивановская область, г. Южа, \nул. Пушкина, д. 1, \nтел. 8 (49347) 2-12-04,\nyuzhaadm@ivreg.ru"
    },
    {
        "col1": "Ивановская область",
        "col2": 27,
        "col3": "Администрация Юрьевецкого муниципального района",
        "col4": "155453, Ивановская область, г. Юрьевец, ул. Советская, д. 37,\nтел. 8 (49337) 2-16-03,\nyurevets@ivreg.ru"
    },
    {
        "col1": "Иркутская область",
        "col2": 1,
        "col3": "Министерство имущественных отношений Иркутской области",
        "col4": "Почтовый адрес: 664011, Российская Федерация,\n г. Иркутск, ул. Ленина, 1А\nМесто нахождения: 664007, Российская Федерация, г. Иркутск, ул. К. Либкнехта, 47\nАдрес электронной почты:imus@govirk.ru номер телефона: (3952)259898\n"
    },
    {
        "col1": "Кабардино - Балкарская Республика",
        "col2": 1,
        "col3": "Министерство просвещения и науки Кабардино-Балкарской Республики",
        "col4": "Кабардино-Балкарская Республика, г. Нальчик,  ул. Кешокова, д. 43, 360051/minobrsc@kbr.ru, soczachita07@mail.ru/8(8662) 42-03-98"
    },
    {
        "col1": "Калининградская область",
        "col2": 1,
        "col3": "Правительство Калининградской области",
        "col4": " 236007, г. Калининград, ул. Дм. Донского, д. 1, \n pko@gov39.ru, \n8 (4012) 599-665"
    },
    {
        "col1": "Калужская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Калужской области ",
        "col4": "248016 г. Калуга, ул. Пролетарская, д. 111, mintrud@adm.kaluga.ru, 719-140, 719-176"
    },
    {
        "col1": "Камчатский край",
        "col2": 1,
        "col3": "Управление коммунального хозяйства\nи жилищного фонда администрации\nПетропавловск-Камчатского\nгородского округа",
        "col4": "683003, Камчатский край,\nг. Петропавловск-Камчатский,\nул. Ленинградская, д. 74/1\nтел.: +7 (4152) 302-520\nфакс: +7 (4152) 302-521\nЭл. почта: ukhgf@pkgo.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 2,
        "col3": "Управление имущественных отношений Администрации Елизовского муниципального района",
        "col4": "684000, Камчатский край, \n г. Елизово, ул. Ленина, д. 24,\nтел.: +7 (41531) 6-10-39\nЭл. почта: uio@elizovomr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 3,
        "col3": "Отдел по управлению муниципальным имуществом администрации Вилючинского городского округа",
        "col4": "684090, Камчатский край,\nг. Вилючинск, ул. Победы, д. 1,\nтел.: +7 (41535) 3-44-34,\nЭл. почта: avgo@viladm.ru,\numi@viladm.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 4,
        "col3": "Комитет по управлению муниципальным имущество и экономической политики Администрации Тигильского муниципального округа Камчатского края",
        "col4": "688600, Камчатский край,\nТигильский район, с. Тигиль,\nул. Партизанская, д. 17,\nтел.: +7 (41537) 2-11-92,\nЭл. почта: tigilcom@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 5,
        "col3": "Администрация Соболевского муниципального района",
        "col4": "684200 Камчатский край,\n с. Соболево, ул. Советская,23,\nтел.: +7 (41536) 3-23-01\nЭл. почта: sobolevomr@sobolevomr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 6,
        "col3": "Комитет по управлению муниципальным имуществом администрации Быстринского района",
        "col4": "684350 Камчатский край, Быстринский район с. Эссо\nул. Терешковой 1,\nтел.: +7 (41542) 2-13-14\nЭл. почта:kumi-esso@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 7,
        "col3": "Управление имущественных и земельных отношений администрации Усть-Камчатского муниципального округа",
        "col4": "684415 Камчатский край,\nп. Усть-Камчатск, Усть-Камчатский район,\nул. 60 лет Октября, 24,\nтел.: +7 (41534) 2-08-44\nЭл. почта:secretar@ustkam.iks.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 8,
        "col3": "Администрация Алеутского муниципального округа",
        "col4": "684500, Камчатский край, Алеутский район, с. Никольское, ул. 50 лет Октября, д. 13,\nтел.: +7 (41547) 2-22-92\nЭл. почта: admamrk@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 9,
        "col3": "Комитет по управлению\nмуниципальным имуществом\nАдминистрации\nУсть-Большерецкого\nмуниципального района",
        "col4": "684100, Камчатский край,\nс. Усть-Большерецк,\nул. Октябрьская, д.14,\nтел.: +7 (41532) 2-12-01\nЭл. почта: kumi@ubmr.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 10,
        "col3": "Администрация Мильковского муниципального округа Камчатского края",
        "col4": "684300, Камчатский край,\nМильковский муниципальный округ, с. Мильково,\nул. Победы, д. 8,\nтел.: +7 (41533) 2-13-53\nфакс: +7 (41533) 2-26-80\nЭл. почта: adm-milk@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 11,
        "col3": "Комитет по управлению\nимуществом администрации\nгородского округа «поселок\nПалана»",
        "col4": "688000 Камчатский край,\nТигильский район, пгт. Палана,\nул. Обухова, д. 6,\nтел.: +7 (41543) 32-220\nЭл. почта: adm@palana.org"
    },
    {
        "col1": "Камчатский край",
        "col2": 12,
        "col3": "Администрация Пенжинского муниципального района",
        "col4": "688850 Камчатский край,\nс. Каменское, Пенжинский район, ул. Ленина, д. 12,\nтел.: +7 (41546) 6-10-86\nЭл. почта: penadm@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 13,
        "col3": "Администрация Олюторского муниципального района",
        "col4": "688800 Камчатский край,\nс. Тиличики, Олюторский район, ул. Молодежная, д. 10,\nтел.: +7 (41544) 5-29-31,\nЭл. почта: oladm_zavoz@mail.ru"
    },
    {
        "col1": "Камчатский край",
        "col2": 14,
        "col3": "Администрация Карагинского муниципального района",
        "col4": "688700, Камчатский край, Карагинский район, п. Оссора,\nул. Советская, д. 37\nтел.: +7 (41545) 41-344\nЭл. почта: akmr@karaginskiy.ru"
    },
    {
        "col1": "Карачаево - Черкесская Республика",
        "col2": 1,
        "col3": "Министерство имущественных и земельных отношений Карачаево-Черкесской Республики",
        "col4": "369004, Карачаево-Черкесская Республика, город Черкесск, Кавказская ул., д. 19\n 8 878 228 17 36"
    },
    {
        "col1": "Кемеровская область",
        "col2": 1,
        "col3": "Управление по жилищной политике администрации Анжеро-Судженского городского округа",
        "col4": "652470 г. Анжеро-Судженск, ул. Ленина, д.6, 8(38453)6-21-10, skv@anzhero.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 2,
        "col3": "Отдел по учету и распределению жилой площади администрации Беловского городского округа",
        "col4": "652600 г.Белово, ул.Советская, д.21, 8(38452)2-08-08, home3@belovo42.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 3,
        "col3": "Отдел жилищных отношений  МКУ «Центр жилищных и земельно-имущественных отношений» Березовского городского округа",
        "col4": "652425 г.Берёзовский, пр. Ленина, д. 39а, 8(38445)3-02-70, ogo-42@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 4,
        "col3": "Отдел по учету и распределению жилья МКУ \"Управление муниципальным имуществом Калтанского городского округа\"",
        "col4": "652740 г. Калтан ул. Калинина, 44, 8(38472)3-40-92, kumi-kaltan@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 5,
        "col3": "Комитет по жилищным вопросам администрации г.Кемерово",
        "col4": "650991 г. Кемерово, пр. Советский, д. 54, 8(3842)58-40-76, kgv@kemerovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 6,
        "col3": "Управление городского развития  Киселевского городского округа",
        "col4": "652700 г. Киселевск, ул. Унжакова, д. 8а, 8(38464)2-20-27, ugrkgo@gmail.com"
    },
    {
        "col1": "Кемеровская область",
        "col2": 7,
        "col3": "Отдел учета, распределения и приватизации муниципального жилищного фонда муниципального казенного учреждения «Комитет по жилищным вопросам» города Междуреченска",
        "col4": "652877 г. Междуреченск, ул. Кузнецкая, д. 31, 8(38475)6-19-32, privatizaciy@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 8,
        "col3": "Отдел по жилищным вопросам администрации Мысковского городского округа",
        "col4": "652840 г. Мыски, ул. Серафимовича, д. 4, 8(38474)2-19-91, myski_gil.otdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 9,
        "col3": "Управление по учету и приватизации жилых помещений администрации города Новокузнецка",
        "col4": "654080 г. Новокузнецк, ул. Кирова, д. 71, 8(3843)52-91-34, upr_priv_priem@admnkz.info"
    },
    {
        "col1": "Кемеровская область",
        "col2": 10,
        "col3": "Отдел по учету и распределению жилья администрации Осинниковского городского округа",
        "col4": "652811 г. Осинники, ул. Советская, д. 17, 8(38471)4-39-55, admo-gil@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 11,
        "col3": "Отдел по учету, распределению и приватизации жилья администрации Прокопьевского городского округа",
        "col4": "653000 г. Прокопьевск, ул. Артема, д. 11, 8(3846)61-20-61, flt_prkp@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 12,
        "col3": "Управление жилищным коммунальным и дорожным хозяйством администрации Тайгинского городского округа",
        "col4": "652401 г.Тайга, ул. 40 лет Октября, д. 23, 8(38448)2-17-19, uzhkidh@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 13,
        "col3": "Жилищный отдел администрации города Юрги",
        "col4": "652050 г. Юрга, пр. Победы, д. 13, 8(38451)4-69-28, gilo@yurga.org"
    },
    {
        "col1": "Кемеровская область",
        "col2": 14,
        "col3": " Комитета по управлению муниципальным имуществом администрации Беловского муниципального округа",
        "col4": "652600 г. Белово, ул. Ленина, д. 10, 8(38452)2-69-80, kumi-zh@belovo.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 15,
        "col3": "Отдел по управлению жилищной сферой администрации Гурьевского муниципального округа",
        "col4": "652780 г. Гурьевск, ул. Коммунистическая, д. 21, 8(38463)5-07-65, gur_114@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 16,
        "col3": "Отдел жизнеобеспечения администрации Ижморского муниципального округа",
        "col4": "652120 пгт. Ижморский, ул. Ленинская, д. 63, 8(38459)2-33-89, adm-ijmorka-rn@ako.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 17,
        "col3": "Управление учета и распределение жилья администрации Кемеровского муниципального округа",
        "col4": "650010 г. Кемерово, ул. Совхозная,1 а, 8(3842)75-68-95, uurj_kmr@inbox.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 18,
        "col3": "Отдел по жилищным вопросам администрации Крапивинского муниципального округа",
        "col4": "652440 пгт. Крапивинский, ул. Юбилейная, д. 15, 8(38446)22-2-56, seryapina.2011@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 19,
        "col3": "Отдел по учету, распределению, приватизации и обмену жилья администрации Ленинск -Кузнецкого городского округа",
        "col4": "652500 г. Ленинск-Кузнецкий, пр.Кирова, 56, 8(38456)3-20-50, oj.lk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 20,
        "col3": "Жилищный отдел администрации Мариинского муниципального округа",
        "col4": "652150 г. Мариинск, ул. Ленина, д. 40, 8(38443)5-22-25, zhilotdel.mariinsk@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 21,
        "col3": "Жилищный сектор отдела капитального строительства администрации Новокузнецкого муниципального округа",
        "col4": "654041 г. Новокузнецк, ул. Сеченова, д. 25, 8(3843)32-08-16, zhilotdel2009@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 22,
        "col3": "Жилищный отдел администрации Прокопьевского муниципального округа",
        "col4": "653039 г.Прокопьевск, пр.Гагарина 1В, 8(3846)62-01-62, otdel@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 23,
        "col3": "Жилищный отдел управления по жизнеобеспечению и строительству администрации Промышленновского муниципального округа",
        "col4": "652380 пгт. Промышленная, ул. Коммунистическая, д. 23а, 8(38442)7-46-21, prom_zhilotdel@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 24,
        "col3": "Отдел  по учету и распределению жилья администрации Таштагольского муниципального района",
        "col4": "652992 г. Таштагол, ул. Ленина, д. 60, 8(38473)2-31-92, giltash_atr@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 25,
        "col3": "Отдел по правовым вопросам и жилищной политике администрации Тисульского муниципального округа",
        "col4": "652210 пгт. Тисуль, ул. Ленина, д. 53, 8(38447)2-32-45, jilotdel@tisul.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 26,
        "col3": "Комитет по муниципальному имуществу администрации Топкинского муниципального округа",
        "col4": "652300 г. Топки, ул. Луначарского, д. 26, 8(38454)4-66-02, o.8ulanova@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 27,
        "col3": "Жилищный отдел управления по жизнеобеспечению и территориальному развитию Тяжинского муниципального округа",
        "col4": "652240 пгт. Тяжинский, ул. Советская, д.2, 8(38449)2-75-58, vorosh-na12@mail.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 28,
        "col3": "Управление жизнеобеспечения администрации Чебулинского муниципального  округа",
        "col4": "652270 пгт.Верх-Чебула, ул. Мира, д. 16, 8(38444)2-11-77, ssw2010@rambler.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 29,
        "col3": "Жилищный отдел администрации Юргинского муниципального округа",
        "col4": "652057 г. Юрга, ул. Машиностроителей, д.37, 8(38451)4-19-92, jil@yurgregion.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 30,
        "col3": "Отдел жилищно-коммунального хозяйства и строительства администрации Яйского муниципального округа",
        "col4": "652100 пгт. Яя, ул. Советская, д. 17, 8(38441)2-11-87, Adm-gkh-yaya@yandex.ru"
    },
    {
        "col1": "Кемеровская область",
        "col2": 31,
        "col3": "Муниципальное казённое учреждение «Управление имущественных отношений администрации Яшкинского муниципального округа»",
        "col4": "652010 пгт. Яшкино, ул. Суворова, д. 8, 8(38455)2-16-77, kumi_yashkiho@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 1,
        "col3": "Администрация Арбажского муниципального округа",
        "col4": "ул. Пионерская, д. 2, пгт Арбаж, Кировская область, 612180, (83330) 2-12-32, admarbazh@mail.ru; (83330) 2-15-57, opeka.arbazh@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 2,
        "col3": "Администрации Афанасьевского муниципального округа Кировской области",
        "col4": "613060 Кировская область, Афанасьевский район, пгт Афанасьево, ул.Красных Партизан, д.5, admafan@kirovreg.ru 883331 2-19-51"
    },
    {
        "col1": "Кировская область",
        "col2": 3,
        "col3": "Администрация Белохолуницкого муниципального района",
        "col4": "613200 Кировская область г.Белая Холуница ул.Глазырина, д.6/bh_opeka@mail.ru/88336442427"
    },
    {
        "col1": "Кировская область",
        "col2": 4,
        "col3": "Администрация Богородского муниципального округа",
        "col4": "612470, Кировская область, Богородский район, пгт Богородское, ул. Советская, д. 43, 883321251, admbogo@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 5,
        "col3": "Администрация Верхнекамского муниципального округа",
        "col4": "612820 Кировская область, г. Кирс, ул. Кирова, д. 16 883339 2-33-72 imushavr@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 6,
        "col3": "Администрация Верхошижемского муниципального образования",
        "col4": "613310, Кировская область, пгт Верхошижемье, ул. Комсомольская, д. 2, 89123374172, opekashishma@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 7,
        "col3": "Администрация Вятскополянского муниципального района Кировской области",
        "col4": "612964, Кировская область, г.Вятские Поляны, ул.Гагарина, д.28, admvyatp@kirovreg.ru,89005294510"
    },
    {
        "col1": "Кировская область",
        "col2": 8,
        "col3": "Администрация муниципального образования Даровской муниципальный район Кировскойобласти",
        "col4": "612140, Кировская область, Даровской район, пгт Даровской, ул. Набережная, д. 8, 88333621906, admdaro@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 9,
        "col3": "Администрация Зуевского района Кировской области",
        "col4": "612412, Кировская область г. Зуевка, ул. Опалева, д. 65, 8 (83337) 25461, azr@zrko.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 10,
        "col3": "Администрация Кикнурского муниципального округа",
        "col4": "612300, Кировская область, Кикнурский район, пгт Кикнур, ул. Советская, д. 36, kiknur-opeka@mail.ru, (83341)5-14-79"
    },
    {
        "col1": "Кировская область",
        "col2": 11,
        "col3": "Администрация Кильмезского муниципального района",
        "col4": "613570 Кировская область, пгт Кильмезь, ул.Советская, д.79, opekakilmez2017@yandex.ru (83338)22036"
    },
    {
        "col1": "Кировская область",
        "col2": 12,
        "col3": "Администрация Кирово-Чепецкого района",
        "col4": "613040 Кировская область, ул. Перовмайская, д. 6, 8(83361)49150, mailbox@admkchr.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 13,
        "col3": "Администрация Котельничского района ",
        "col4": "612600, Кировская область, г. Котельнич, ул. Карла Маркса, д. 16, kotelnich_rayon@mail.ru 8(83342)4-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 14,
        "col3": "Администрация Куменского района",
        "col4": "613400 Кировская область, Куменский район, ул. Кирова, д.11, 8 (83343)2-12-50, adm@kumensky.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 15,
        "col3": "Администрация Лебяжского муницильного округа",
        "col4": "613500, Кировская область, Лебяжский район, пгт Лебяжье, ул.Комсомольская, д.5, leb_opeka@bk.ru, 88334420942"
    },
    {
        "col1": "Кировская область",
        "col2": 16,
        "col3": "Администрация муниципального образования Лузский муниципальный округ Кировской области",
        "col4": "613980, Кировская область, г. Луза, ул. Ленина, д. 35, admluza@kirovreg.ru, 883346-5-18-04"
    },
    {
        "col1": "Кировская область",
        "col2": 17,
        "col3": "Администрация Малмыжского района",
        "col4": "612920, Кировская область, г. Малмыж, ул. Чернышевского, д. 2а opekamalm@yandex.ru (83347) 2-28-18"
    },
    {
        "col1": "Кировская область",
        "col2": 18,
        "col3": "Администрация Мурашинского муниципального округа",
        "col4": "613711, Кировская область, г. Мураши, ул. Карла Маркса, д. 28, opeka_murashi@mail.ru, 8(83348)2-28-86"
    },
    {
        "col1": "Кировская область",
        "col2": 19,
        "col3": "Администрация Нагорского района",
        "col4": "613260, Кировская область пгт. Нагорск ул. Леушина д. 21 (83349) 2-16-70 admnago@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 20,
        "col3": "Администрация Немского муниципального округа ",
        "col4": "613470 Кировская область ул. Советская д. 36 т. 8(83350)2-22-85. opeka.nema@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 21,
        "col3": "Администрация Нолинского района",
        "col4": "613440, Кировская область, г.Нолинск, ул.Спартака, д.36, opekanolinsk@mail.ru, 88336821180"
    },
    {
        "col1": "Кировская область",
        "col2": 22,
        "col3": "Администрация Омутнинского района",
        "col4": "612740, Кировская область, г. Омутнинск, ул. Комсомольская, д. 9, admomut@kirovreg.ru, 88335221251"
    },
    {
        "col1": "Кировская область",
        "col2": 23,
        "col3": "Управление образования администрации Опаринского МО",
        "col4": "613810, Кировская область, пгт. Опарино, ул. Первомайская, 14 opeka.oparino@yandex.ru (83353) 2-11-02"
    },
    {
        "col1": "Кировская область",
        "col2": 24,
        "col3": "Администрация Орловского района",
        "col4": "612270, Кировская обл, Г.Орлов, ул. Ст.Халтурина, д. 18 orlovopeka@mail.ru (83365) 2-16-45"
    },
    {
        "col1": "Кировская область",
        "col2": 25,
        "col3": "Администрация Пижанского муниципального округа ",
        "col4": "613380, Кировская область, Пижанский район, пгт. Пижанка, ул. Труда, 25 opp4325@yandex.ru 8(83355)22172"
    },
    {
        "col1": "Кировская область",
        "col2": 26,
        "col3": "Администрация Подосиновского района Кировской области",
        "col4": "613930, Кировская область, Подосиновский район, пгт Подосиновец ул. Советская д. 77, podopeka@yandex.ru, 8(83351)2-16-54"
    },
    {
        "col1": "Кировская область",
        "col2": 27,
        "col3": "Администрация Санчурского Муниципального округа",
        "col4": "Кировская область, Санчурский район, пгт. Санчурск, ул. Р. Люксембург, д.6А, 612370 тел. 883357 2-13-21 , admsanch@kiroveg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 28,
        "col3": "Администрация Свечинского муниципального округа",
        "col4": "612040, Кировская область, пгт Свеча, ул. Октябрьская, д.20, Тел: 8(83358)2-23-35, Эл.п.: sv_opeka@mail.ru, admsvec@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 29,
        "col3": "Администрация Слободского муниципального района",
        "col4": "613150Кировская область г. Слободской ул. Советская д.86 тел 88336241252 admslob@kirovreg.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 30,
        "col3": "Администрация Советского муниципального района, ",
        "col4": "613340,Кировская область, Советский район, г. Советск, ул. Кирова,д.5 (83375)2"
    },
    {
        "col1": "Кировская область",
        "col2": 31,
        "col3": "Администрация Сунского муниципального района ",
        "col4": "612450, Кировская область, Сунский район, пгт Суна, ул.Большевиков, д.1, 8(83369)33694 sunaopeka@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 32,
        "col3": "Администрация Тужинского муниципального района",
        "col4": "612200, Кировская область, Тужинский район, пгт Тужа, ул. Горького, д. 5, ruotuzha43@yandex.ru, 8(83340)2-16-81"
    },
    {
        "col1": "Кировская область",
        "col2": 33,
        "col3": "администрация Унинского муниципального округа",
        "col4": "612540 Кировская область, Унинский район, пгт.Уни, ул.Ленина, д.17 admunin@kirovreg.ru 8833592-14- 65"
    },
    {
        "col1": "Кировская область",
        "col2": 34,
        "col3": "Администрация Уржумского муниципального района",
        "col4": "  613530, г.Уржум, ул.Рокина, д. 13, 9536724559, ruo.opeka.@ mаil.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 35,
        "col3": "Администрация Фаленского муниципального округа",
        "col4": "612500, Кировская область, Фаленский район, пгт Фаленки, ул. Свободы,д 65, 8833322-11-30 kd81123@yandex.ru, 89536922268 opekafalenki@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 36,
        "col3": "Районное управление образования администрации Шабалинского района",
        "col4": "612020, ул. Советская, д.33, пгт Ленинское, Шабалинского района, Кировской области (83345) 20158 Shabalino@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 37,
        "col3": "администрация Юрьянского района",
        "col4": "613600 Кировская область, Юрьянский район, пгт Юрья, ул. Ленина, 46, admjurja@kirovred.ru 883366 2-12-51"
    },
    {
        "col1": "Кировская область",
        "col2": 38,
        "col3": "Администрация Яранского района",
        "col4": "г.Яранск, ул.Кирова, д.10 612260, 883367-2-06-59 opeka-yaransk@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 39,
        "col3": "Администрация ЗАТО Первомайский",
        "col4": "613648,ЗАТО Первомайский ул. Волкова, 14 , admzatopervomaysk@mail.ru, 8(833266)2-42-31"
    },
    {
        "col1": "Кировская область",
        "col2": 40,
        "col3": "Администрация города Вятские Поляны",
        "col4": "612964, Кировская область, г. Вятские Поляны, ул. Гагарина 28а 8(83334) 61162 эл.почта opekavp@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 41,
        "col3": "Департамент образования администраци муниципального образования городской округ \"Город Кирово-Чепецк\" Кировской области",
        "col4": "613040, Кировская область, г. Кирово-Чепецк, ул. Ленина, д. 1б, 8 (83361)5-80-50, доб. 2034, k4-opeka@mail.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 42,
        "col3": "Администрация городского округа города Котельнича ",
        "col4": "612100, Кировская область, Котельничский район, г. Котельнич, ул. Советская, д. 83, 88334241725, admgkotel@yandex.ru"
    },
    {
        "col1": "Кировская область",
        "col2": 43,
        "col3": "Администрация города Слободского",
        "col4": "613150, г. Слободской, ул. Советская, д.86, тел. 8(8332) 25-53-49, slob-adm@rambler.ru."
    },
    {
        "col1": "Кировская область",
        "col2": 44,
        "col3": "Администрация города Кирова",
        "col4": "610000, Кировская область, г. Киров, ул. Воровского, д. 39, opeka@admkirov.ru, 8(8332)54-64-12, 54-00-42, 57-94-67, 54-09-68, 54-77-48"
    },
    {
        "col1": "Кировская область",
        "col2": 45,
        "col3": "Администрация Оричевского района",
        "col4": "612080, Кировская область, Оричевский район, пгт Оричи, ул. К. Маркса, д. 12 ( 883354)2-11-75, adm@orichi-rayon.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 1,
        "col3": "администрация Антроповского муниципального округа Костромской области",
        "col4": "157260, Костромская область, Антроповский район, п. Антропово, ул.Октябрьская, д. 12, (49430) 3-52-03, antropovo@kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 2,
        "col3": "администрация Буйского муниципального района Костромской области ",
        "col4": "157000, Костромская область, пл.Революции, 13, (49435) 4-51-40, bui@kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 3,
        "col3": "администрация Вохомского муниципального района Костромской области ",
        "col4": "157760, Костромская область, Вохомский район, п. Вохма, ул.Советская, 39, (49450) 2-13-34, vohma@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 4,
        "col3": "администрация Галичского муниципального района Костромской области ",
        "col4": "157201, Костромская область, Галичский район, г. Галич, пл.Революции, 23а, (49437) 2-21-34, galich@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 5,
        "col3": "администрация Кадыйского муниципального округа Костромской области ",
        "col4": "157980, Костромская область, Кадыйский район, п.Кадый, ул.Центральная, 3, (49442) 3-40-08, kadiy@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 6,
        "col3": "администрация Кологривского муниципального округа Костромской области ",
        "col4": "157400, Костромская область, Кологривский район, г. Кологрив, ул.Набережная р.Киченки, 13, (49443)5-27-49, kologriv@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 7,
        "col3": "администрация Костромского муниципального района Костромской области ",
        "col4": "156961, Костромская область, г. Кострома, ул. М. Новикова, 7, (4942) 55-02-02, kosrn@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 8,
        "col3": "администрация Красносельского муниципального района Костромской области ",
        "col4": "157940, Костромская область, п. Красное-на-Волге, Красная пл., 15, (49432) 2-19-46, krasnoe@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 9,
        "col3": "администрация Макарьевского муниципального округа Костромской области ",
        "col4": "157460, Костромская область, Макарьевский район, г. Макарьев, пл.Революции, 8, (49445) 55-1-31 makarev@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 10,
        "col3": "администрация Мантуровского муниципального округа Костромской области ",
        "col4": "157300, Костромская область, Мантуровский район, г. Мантурово, ул.Центральная, д.5, (49446) 2-04-50 gorod_manturovo@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 11,
        "col3": "администрация Межевского муниципального округа Костромской области  ",
        "col4": "157420, Костромская область, Межевской район, с. Георгиевское, ул. Октябрьская, 39, (49447) 5-23-19 mezha@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 12,
        "col3": "администрация города Нерехта и Нерехтского муниципального района Костромской области                        ",
        "col4": "157800, Костромская область, Нерехтский район, г. Нерехта, ул.Победы, 1,  (49431) 7-62-03 nerehta@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 13,
        "col3": "администрация Нейского муниципального округа Костромской области ",
        "col4": "157330, Костромская область, Нейский район, г. Нея, ул.Соловьева, 6, (49444)3-19-14, neya@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 14,
        "col3": "администрация Октябрьского муниципального округа Костромской области ",
        "col4": "157780, Костромская область, октябрьский район, с. Боговарово, ул.Победы, 37, (49451)2-11-86, oktyabrskiy@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 15,
        "col3": "администрация Островского муниципального округа Костромской области ",
        "col4": "157900, Костромская область, Островский район, п. Островское, ул. Советская, 56, (49438) 2-72-95, ostrovskiy@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 16,
        "col3": "администрация Павинского муниципального округа Костромской области ",
        "col4": "157650, Костромская область, Павинский район, с. Павино, ул.Октябрьская, 15, (49439) 2-11-63  pavino@kostroma.gov.ru "
    },
    {
        "col1": "Костромская область",
        "col2": 17,
        "col3": "администрация Парфеньевского муниципального округа Костромской области",
        "col4": "157270, Костромская область, Парфеньевский район, с.Парфеньево, ул.Маркова, 17,  (49440) 2-41-52, parfenyevo@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 18,
        "col3": "администрация Поназыревского муниципального округа Костромской области ",
        "col4": "157580, Костромская область, Поназыревский район, п. Поназырево, ул.Свободы, 1, (49448)2-11-67, ponazyrevo@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 19,
        "col3": "администрация Пыщугского муниципального округа Костромской области ",
        "col4": "157630, Костромская область, Пыщугский район, с. Пыщуг, ул.Чкалова, 6, (49452) 2-77-81, pyshchug@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 20,
        "col3": "администрация Солигаличского муниципального округа Костромской области ",
        "col4": "157170, Костромская область, Солигаличский район, г. Солигалич, ул.Коммунистическая, 1, (49436)5-12-74, soligalich@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 21,
        "col3": "администрация Судиславского муниципального района Костромской области ",
        "col4": "157860, Костромская область, Судиславский район, п. Судиславль, ул. Советская, 2, (49433) 9-84-76 sudislavl@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 22,
        "col3": "администрация Сусанинского муниципального района Костромской области ",
        "col4": "157080, Костромская область, Сусанинский район, ул. Ленина, 2а, (49434) 9-03-33, susanino@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 23,
        "col3": "администрация Чухломского муниципального района",
        "col4": "157130, Костромская область, Чухломский район, г. Чухлома, пл.Революции, 11,  (49441) 2-12-13, chuhloma@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 24,
        "col3": "администрация Шарьинского муниципального района Костромской области ",
        "col4": "157500, Костромская область, Шарьинский район, г. Шарья, ул. Октябрьская, 21, (49449) 5-03-50, sharya@ kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 25,
        "col3": "администрация городского округа город Кострома Костромской области ",
        "col4": "156000, Костромская область, г.Кострома, ул. Советская, 1, (4942) 31-39-32, 31-53-41, gorod_kostroma@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 26,
        "col3": "администрация городского округа город Буй Костромской области",
        "col4": "157000, Костромская область, г. Буй, пл.Революции, 12,  (49435) 4-45-03, доб.118, gorod_buy@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 27,
        "col3": "администрация городского округа город Волгореченск Костромской области",
        "col4": "156901, Костромская область, г.Волгореченск, ул. Имени 50-летия Ленинского Комсомола, 4,  (49453) 5-25-02, gorod_volgorechensk@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 28,
        "col3": "администрация городского округа город Галич Костромской области ",
        "col4": "157201, Костромская область, г. Галич, пл. Революции, 23а, (49437) 2-17-20, gorod_galich@kostroma.gov.ru"
    },
    {
        "col1": "Костромская область",
        "col2": 29,
        "col3": "администрация городского округа город Шарья Костромской области",
        "col4": "157500, Костромская область, г. Шарья, ул. Октябрьская, 21, (49449) 5-89-32, gorod_sharya@kostroma.gov.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 1,
        "col3": "Сектор по жилищным вопросам администрации муниципального образования город Армавир",
        "col4": "352900, г. Армавир, ул. К. Либкнехта, 52,  +7 (861) 372-7470, 373-27-80 zil72adm@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 2,
        "col3": "Муниципальное казенное учреждение \"Бухгалтерия жилищно-коммунального хозяйства\" муниципального образования город-курорт Геленджик",
        "col4": "353460, г. Геленджик, ул. Революционная, д. 1, 8 (861-41) 3-51-70, mkubzkh2012@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 3,
        "col3": "Управление по жилищным вопросам администрации муниципального образования город Краснодар",
        "col4": "350015, г. Краснодар, ул. Кузнечная, 6, 8 (861) 218-92-41, housing@krd.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 4,
        "col3": "Управление городского хозяйства администрации муниципального образования город Новороссийск",
        "col4": "353900, г. Новороссийск, ул. Рубина, 25, 8 (861-7) 61-18-93, ugh_novoros@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 5,
        "col3": "Департамент городского хозяйства администрации муниципального образования городской округ город-курорт Сочи Краснодарского края",
        "col4": "354000, г.-к. Сочи, Островского, 35/11, каб.5 и 12, 8 (862) 266-06-06 (доб. 1750), dgh@sochiadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 6,
        "col3": "Управление имущественных отношений администрации муниципального образования город-курорт Анапа",
        "col4": "353440, г. Анапа Пушкина, д. 28, 8 (861-33) 5-29-68                                          8 (918) 939-02-29, go@anapa.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 7,
        "col3": "Отдел по учету и работе с гражданами, нуждающимися в жилье администрации муниципального образования муниципальный округ город Горячий Ключ ",
        "col4": "353290, г. Горячий Ключ, ул. Ленина, д. 191, 8 (861-59) 3-84-49 (доб.237) \n8 (988) 522-65-33, adm342@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 8,
        "col3": "Сектор по жилищным вопросам управлениятопливно-энергетического комплекса, жилищно-коммунального хозяйства, транспорта и связи администрации муниципального образования Ленинградский район",
        "col4": "353740 ст. Ленинградская, ул. Чернышевского, д.179, 8 (861-45) 3-85-60 (добавочный 238 или 239), otdelgkh-len@mail.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 9,
        "col3": "Отдел жилищного учета управления земельных и имущественных отношений администрации муниципального образования Приморско-Ахтарский район",
        "col4": "353860 г. Приморско-Ахтарск,                               ул. 50 лет Октября, 63, 8 861-43-31-9-94, giluchet2025@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 10,
        "col3": "Квартирно-правовая служба администрации муниципального образования Туапсинский муниципальный округ",
        "col4": "352800 г. Туапсе, ул. Свободы, д. 3, 8-861-67-2-18-32, mup.kps.tr@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 11,
        "col3": "Управление муниципальной собственности администрации муниципального образования Абинский район",
        "col4": "353320, г. Абинск, ул. Кубанская, д. 18, 8(861)504-35-70, diok.ms01@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 12,
        "col3": "Отдел ЖКХ, топливно-энергетического комплекса, дорожного хозяйства и трпанспорта, промышленности и связи администрации муниципального образования Апшеронский район",
        "col4": "352690, г.Апшеронск, ул. Коммунистическая, д.17, 8(861)522-58-15, apsheronskgkh1@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 13,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Белоглинский район",
        "col4": "353000, с. Белая Глина, ул. Красная, д. 160, каб. 305, 8 (861-54) 7-29-86, oumi@belaya-glina.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 14,
        "col3": "Управление имущественных отношений администрации муниципального образования Белореченский район",
        "col4": "352630, г.Белореченск, \nул.Ленина, 66, 8 (861-55) 2-29-45, beluio2@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 15,
        "col3": "Отдел имущественных отношений администрации муниципального образования Брюховецкий район",
        "col4": "352750, ст-ца Брюховецкая ул Красная, д. 211, 8 (861-56) 3-20-56, 8 (861-56) 2-00-88, petrenko_lv@amobr.ru, rudetskaya_ag@amobr.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 16,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Выселковский район",
        "col4": "353100, ст. Выселки, \nул. Ленина, 37, 8 -861 57- 7- 42- 58, amovr05@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 17,
        "col3": "Отдел топливно-энергетического комплекса и жилищно-коммунального хозяйства управления топливно-энергетического комплекса, жилищно-коммунального хозяйства и транспорта администрация муниципального образования Гулькевичский  район",
        "col4": " 352190, г. Гулькевичи, ул. Советская, 14, 8 (861-60) 5-18-16, 8 (918) 951-26-16, otdel.zhkh@mogulk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 18,
        "col3": "Управление имущественных отношений администрации муниципального образования Динской район",
        "col4": "353204, ст. Динская, ул. Красная,53, 8 (861-62) 6-18-05, 8 (861-62) 6-11-81, dinsk_uizo@ mo.krasnodar.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 19,
        "col3": "Управление жилищно-коммунального хозяйства и капитального строительства администрации муниципального образования Ейский район",
        "col4": "353680, г. Ейск, ул. Победы, д. 108, 8(86132)2-32-08, gkh@yeiskraion.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 20,
        "col3": "Управление имущественных отношений администрации муниципального образования  Кавказский район",
        "col4": "352380, г. Кропоткин, пер. Братский/ул. Гоголя, д. 18/44, 8 (86138) 6-34-16, uio.kavkazraion@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 21,
        "col3": "Отдел по вопросам жилищно-коммунального хозяйства в управлении градостроительства и благоустройства администрации муниципального образования Калининский район  ",
        "col4": "353780 ст. Калининская, \nул. Ленина, д. 147, 8-861-63-21-4-86, zkx_kalina@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 22,
        "col3": "Управление имущественных отношений администрации муниципального образования Каневской район",
        "col4": "353730 ст. Каневская,                                                                    ул. Вокзальная, 32, 8-861-64-7-57-17 io@kanevskadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 23,
        "col3": "Управление земельных и имущественных отношений администрациимуниципального образования Кореновский район",
        "col4": "353180 г. Кореновск,                                                                    ул. Красная, 41 8-861-42-4-51-71  guseva@admkor.ru    "
    },
    {
        "col1": "Краснодарский край",
        "col2": 24,
        "col3": "Отдел имущественных отношений в управлении муниципальной собственностью администрации муниципального образования Красноармейский район ",
        "col4": "353800 ст. Полтавская ул. Просвещения , 107а, 8-861-65 -3-25-57, komitetums@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 25,
        "col3": "Отдел муниципального имущества администрации муниципального образования Крыловский район                       ",
        "col4": "352080 ст. Крыловская, ул. Орджоникидзе,43, 8-861-61 -3-20-56, omi-14@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 26,
        "col3": "Управление имущественных отношений администрации муниципального образования Крымский район",
        "col4": "353380, г.Крымск, ул. Синева, 13, 8 (861-31) 4-79-12, ms45_45@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 27,
        "col3": "Управление имущественных отношений администрации муниципального образования Курганинский район",
        "col4": " 352430, г. Курганинск, ул. Ленина, 27, 8 (861-47) 2-77-16, kurg_adm@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 28,
        "col3": "Отдел имущественных отношений в управление муниципальным имуществом администрации муниципального образования Кущевский район",
        "col4": "352030  ст-ца Кущёвская,                                   им.Б.Е.Москвича пер., д.67 8-861-68-5-44-60  imush2340@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 29,
        "col3": "Управление имущественных отношений администрации муниципального образования Лабинский район",
        "col4": "352500, г. Лабинск, ул. К.Маркса, д. 160 8-861-69-3-11-33  UIO46@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 30,
        "col3": "Управление имущественных и зенмельных отношений администрации муниципального образования Мостовский район",
        "col4": "352570, пгт Мостовской, ул. Горького , д. 140, 8-861-92-5-13 42  uizomost@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 31,
        "col3": "Управление имущественных отношений администрации муниципального образования Новокубанский район",
        "col4": "352240 г. Новокубанск,                                      ул.Первомайская, 151, 8-861-95 3-09-46, yuonr@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 32,
        "col3": "Управление имущественных и земельных отношений администрации муниципального образования Новопокровский район",
        "col4": "353020 ст. Новопокровская ул.Ленина, 101, 8-861-49 7-31-97 ms22@novopokrovskaya.com    "
    },
    {
        "col1": "Краснодарский край",
        "col2": 33,
        "col3": "Отдел опеки и попечительства в отношении несовершеннолетних администрации муниципального образования Отрадненский район",
        "col4": "352290, ст. Отрадная, ул. Первомайская, д. 20 8-861-44 3-36-98 , 8-861-44 3-43-55 detstvo-2008@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 34,
        "col3": "Управление жилищно-коммунального хозяйства администрации муниципального образования Павловский район",
        "col4": "352040 ст. Павловская, ул.Пушкина, д. 260  8-861-91 3-20-22, pavlovskaya-gkh@bk.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 35,
        "col3": "Управление имущественных отношений администрации муниципального образования Северский район",
        "col4": "353240, ст. Северская, ул. Петровского, д. 6 8-861-66 2-91-13 imushestvo@sevadm.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 36,
        "col3": "Управление по муниципальному имуществу и земельным отношениям администрации муниципального образования Славянский район",
        "col4": "353560 г. Славянск-на-Кубани ул. Красная, 22 8-861-46 4-30-02 umizo23@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 37,
        "col3": "Управление имущественных отношений   администрации муниципального образования Староминский район          ",
        "col4": " 353600 ст-ца Староминская,  Красная ул., д. 13 8-861-53 4-32-74 uio.starominska@yandex.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 38,
        "col3": "Отдел по управлению муниципальным имуществом администрации муниципального образования Тбилисский район",
        "col4": "352360 ст. Тбилисская                                              ул. Первомайская, 17  8-8615-83-19-43 otdelumi@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 39,
        "col3": "Управление имущественных и земельных отношений администрации муниципального образования Темрюкский район                                                         ",
        "col4": "353500 г.Темрюк ул.Ленина, 65 (86148) 5-28-93 imushestvo2010@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 40,
        "col3": "Отдел земельных и имущественных отношений администрации муниципального образования Тимашевский район",
        "col4": "352700, г.Тимашевск, ул.Пионерская, 90а 8(86130) 4-20-81 tim-imusch@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 41,
        "col3": "Управление муниципальных ресурсов администрации муниципального образования Тихорецкий район",
        "col4": "352120 г. Тихорецк, ул.Подвойского, 119 тел. 8-861-96 5-21-35 ms32tih@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 42,
        "col3": "Отдел по вопросам имущественных отношений и развития инвестиций администрации муниципального образования Успенский район",
        "col4": "352450 с.Успенское, ул. Калинина, 76  8-861-40 5-52-31 uspimyh@mail.ru"
    },
    {
        "col1": "Краснодарский край",
        "col2": 43,
        "col3": "Управление по вопросам земельных отношений и учета муниципальной собственности администрации муниципального образования Усть-Лабинский район",
        "col4": "352330  г.Усть-Лабинск,\nЛенина ул., д. 33 8- 861-35 5-27-51 oms.uzoms@yandex.ru "
    },
    {
        "col1": "Краснодарский край",
        "col2": 44,
        "col3": "Отдел по распоряжению муниципальным   имуществом  администрации муниципального образования  Щербиновский район",
        "col4": "353620,  ст-ца Старощербиновская, ул. Советов, 68,  8-861-51 7-76-75 upravlenie.uizo@yandex.ru"
    },
    {
        "col1": "Красноярский край",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Красноярского края",
        "col4": "660075, Красноярский край, г.Красноярск, ул.Заводская, д.14\npriem@krasminstroy.ru, 8 (391) 211-05-39, 8 (391) 211-12-55"
    },
    {
        "col1": "Курганская область",
        "col2": 1,
        "col3": "Администрация города Кургана",
        "col4": "640000, г. Курган, пл.им. В.И. Ленина,1, тел. (3522) 42-88-01; mail@kurgan-city.ru ; 45t00902@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 2,
        "col3": "Администрация города Шадринска",
        "col4": "641884, Курганская область, г. Шадринск, ул. Свердлова, д.59, тел. (35253) 63212; public@shadrinsk-city.ru; 45t02602@kurganobl.ru 26"
    },
    {
        "col1": "Курганская область",
        "col2": 3,
        "col3": "Администрация Альменевского муниципального округа Курганской области ",
        "col4": "641130, Курганская область, с. Альменево, пл. Комсомола, 1, тел. (35242) 9-87-41; 45t00102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 4,
        "col3": "Администрация Белозерского муниципального округа Курганской области ",
        "col4": "641330, Курганская область, с.Белозерское, ул. К.Маркса, д.16, тел.(35232) 2-90-70; belozeradm@mail.ru; 45t00202@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 5,
        "col3": "Администрация Варгашинского муниципального округа Курганской области",
        "col4": "641230, область Курганская, район Варгашинский, р.п.Варгаши, улица Чкалова, д. 22, тел. (35233) 2-21-55;  45t00302@kurganobl.ru  "
    },
    {
        "col1": "Курганская область",
        "col2": 6,
        "col3": "Администрация Далматовского муниципального округа Курганской области",
        "col4": "641730,  Курганская обл., Далматовский р-он, г. Далматово, ул. Советская, 187, тел. (35252) 3-81-30; radm@dalmatovo.ru; 45t00402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 7,
        "col3": "Администрация  Звериноголовского муниципального округа Курганской области",
        "col4": "641480, Курганская область, с. Звериноголовское, ул. Чапаева, 41, тел. (35240) 2-15-05; 45t00502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 8,
        "col3": "Администрация Каргапольского муниципального округа Курганской области",
        "col4": "641920, Курганская область, р.п. Каргаполье, ул. Калинина, д.35, тел. (35256) 2-16-30;  45t00602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 9,
        "col3": "Администрация Катайского района муниципального округа Курганской области",
        "col4": "641700, Курганская область, г.Катайск, ул. Ленина, д.200, тел. (35251)21545; katadmin@mail.ru ; 45t00702@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 10,
        "col3": "Администрация Кетовского муниципального округа Курганской области",
        "col4": "641310, Курганская область, с.Кетово, ул. Космонавтов, д.39, тел. (35231) 23541; AdmKetr@mail.ru; 45t00802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 11,
        "col3": "Администрация Куртамышского муниципального округа Курганской области",
        "col4": "641430, Курганская область, г. Куртамыш, ул. XХII партсъезда, д.40, тел. (35249)2-18-10; kurtamysh-gorod@yandex.ru; 45t01002@kurganobl.ru 10"
    },
    {
        "col1": "Курганская область",
        "col2": 12,
        "col3": "Администрация Лебяжьевского муниципального округа Курганской области",
        "col4": "641500, Курганская область, р.п. Лебяжье, ул. Пушкина, д.14, тел. (35237) 9-08-78; 45t01102@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 13,
        "col3": "Администрация Макушинского муниципального округа Курганской области",
        "col4": "641600, Курганская область, г. Макушино, ул. Ленина, д.85, тел. (35236) 2-06-42; 45t01202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 14,
        "col3": "Администрация Мишкинского муниципального округа Курганской области",
        "col4": "641040, Курганская область, р.п. Мишкино, ул. Ленина, д.30, тел. (35247)2-28-01; 45t01302@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 15,
        "col3": "Администрация Мокроусовского муниципального округа Курганской области",
        "col4": "641530, Курганская область, с. Мокроусово, ул. Советская, д.31, тел. (35234)9-77-41; admmokr@mail.ru; 45t01402@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 16,
        "col3": "Администрация Петуховского муниципального округа Курганской области",
        "col4": "641640, Курганская область, г. Петухово, ул. К. Маркса, 27, тел. (35235) 3-84-98; admpr@bk.ru; 45t01502@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 17,
        "col3": "Администрация Половинского муниципального округа Курганской области",
        "col4": "641780, Курганская область, с. Половинное, ул. Победы, д.9, тел.(35238) 9-15-33; 45t01602@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 18,
        "col3": "Администрация Притобольного муниципального округа Курганской области",
        "col4": "641400, Курганская область, с. Глядянское, ул.Красноармейская, д.19, (3522) 42-89-80 доб. 224;  45t01702@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 19,
        "col3": "Администрация Сафакулевского муниципального округа Курганской области",
        "col4": "641080, Курганская область, с. Сафакулево, ул. Куйбышева, д.35; тел. (35243) 2-93-71; safakulevo@bk.ru; 45t01802@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 20,
        "col3": "Администрация Целинного муниципального округа Курганской области",
        "col4": "641150, Курганская область, с. Целинное, ул. Советская, д.66, тел. (35241)2-10-43;  45t01902@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 21,
        "col3": "Администрация Частоозерского муниципального округа Курганской области ",
        "col4": "641570, Курганская область, с. Частоозерское, ул. Октябрьская, д.126, (3522) 42-84-45; 45t02002@kurganobl.ru "
    },
    {
        "col1": "Курганская область",
        "col2": 22,
        "col3": "Администрация Шадринского муниципального округа Курганской области",
        "col4": "641870, Курганская область, г. Шадринск ул. Р. Люксембург, д.10, тел. (35253) 7-62-43; 45t02102@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 23,
        "col3": "Администрация Шатровского муниципального округа Курганской области",
        "col4": "641960, Курганская область, с. Шатрово, ул. Федосеева, д.53, тел. (35257) 9-13-98; 45t02202@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 24,
        "col3": "Администрация Шумихинского муниципального округа Курганской области",
        "col4": "641100, Курганская область, г. Шумиха, ул. Кирова, д.12, тел. (35245)2-11-67; 45t02302@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 25,
        "col3": "Администрация Щучанского муниципального округа Курганской области",
        "col4": "641010 Курганская область, г. Щучье, ул. Победы, 1, тел. (35244) 3-69-67; 45t02402@kurganobl.ru"
    },
    {
        "col1": "Курганская область",
        "col2": 26,
        "col3": "Администрация Юргамышского муниципального округа Курганской области",
        "col4": "641200 Курганская область, р.п. Юргамыш, ул. Ленина, 43, тел.(35248) 9-26-91; 45t02502@kurganobl.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 1,
        "col3": "Министерство социального социального обеспечения, материнства и детства Курской области",
        "col4": "305007, г. Курск, ул. Моковская, д. 2-г, тел.: +7 (4712) 400-300 факс +7 (4712) 35-75-23 e-mail: kco@rkursk.ru; "
    },
    {
        "col1": "Курская область\n",
        "col2": 2,
        "col3": "Администрация Беловского района Курской области",
        "col4": "307910 Курская область, Беловский р-он, сл.Белая, Советская площадь, 1, тел.: 8(47149)2-11-40, e-mail: oop.belaya@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 3,
        "col3": "Администрация Большесолдатского района Курской области ",
        "col4": "305007, г. Курск, ул. Красная площадь, д.6, подъезд 3, каб. 247; тел.: 8(4712) 51-09-89, e-mail: petina6464@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 4,
        "col3": "Отдел опеки и попечительства Администрации Глушковского района Курской области \n",
        "col4": "305000 г. Курск, ул. Ендовищенская 8а, тел.:8(4712)44-62-20, e-mail:opeka-gl.307450@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 5,
        "col3": "Администрация Горшеченского района Курской области \n",
        "col4": "306800, Курская обл., Горшеченский р-н, рп. Горшечное, ул. Кирова, д. 18, тел. 8(47133)2-13-75, e-mail: oszn_gr@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 6,
        "col3": "Управление  образования, опеки и попечительства Администрации Дмитриевского района Курской области\n",
        "col4": "307500 г. Дмитриев, ул. Ленина, д. 79, тел. 8(47150) 2-23-06, e-mail: opeka_46@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 7,
        "col3": "Администрация Железногорского района Курской области \n",
        "col4": "301170, г Железногорск, ул. Ленина, д.52, каб. 124. тел., факс 8 (47148)3-50-48, e-mail: 35048@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 8,
        "col3": "Отдел образования, опеки и попечительства Администрации Золотухинского района Курской области \n",
        "col4": "306020 Золотухинский район, Курская область, Золотухинский район, п. Золотухино, ул. пер. Лесной, д. 5, тел.: 8(47151)2-13-68, e-mail:  opekazolotuhino@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 9,
        "col3": "Муниципальное образование «Касторенский муниципальный район Курской области» \n",
        "col4": "306700, Курская область, Касторенский район, п.Касторное, ул. 50 лет октября, д.6, тел.8(47157)2-19-52, e-mail: otdelpoopeke46@.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 10,
        "col3": "Администрация Конышевского района Курской области \n",
        "col4": "307620, Курская область, п. Конышевка, ул.Ленина, д.19, тел. 8(47156)2-13-55, e-mail:opeka4609@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 11,
        "col3": "Отдел опеки и попечительства управления по образованию, опеки и попечительству администрации Кореневского района \n",
        "col4": "305044, г. Курск, ул. Союзная, 29а,\nтел.: 8(4712) 34-28-98, e-mail: korenevo-opeka@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 12,
        "col3": "Администрация Курского района Курской области ",
        "col4": "305001, г. Курск, ул. Белинского, 21, тел. 8(4712)54-66-09, otdopeki.kurskrn@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 13,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 14,
        "col3": "Администрация Льговского района Курской области \n",
        "col4": "307750, Курская область, г. Льгов, ул. Красная Площадь, д. 4 б, тел.: 8(47140)2-13-31, opeka-lgov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 15,
        "col3": "Администрация Медвенского района Курской области \n",
        "col4": "307030, Курская область, пос.Медвенка, ул.Советская, д.20, тел.: 8(47146)4-19-76, e-mail: medwenka@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 16,
        "col3": "\nАдминистрация Мантуровского района Курской области \n",
        "col4": "307000, Курская область, Мантуровский район, с. Мантурово, ул. Ленина, д.13 тел.: 8(4715) 521286,\ne-mail: opeka@smanturovo.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 17,
        "col3": "Администрация Обоянского района Курской области\n \n",
        "col4": "306230 Курская область, г. Обоянь, ул. Шмидта, д.6, 8(47141)2-22–55, e-mail oboyan-opeka@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 18,
        "col3": "Администрация Октябрьского района Курской области \n",
        "col4": "307200, Курская область,Октябрьский район, п. Прямицыно, ул. Октябрьская, 134, тел.: 8(47142) 2-13-36, 2-13-07,2-11-57, 2-16-31, e-mail:opekaoct@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 19,
        "col3": "Администрация Пристенского района Курской области \n",
        "col4": "306200, Курская область, Пристенский район, п. Пристень, ул. Ленина, дом 5, тел.: 8(47134)2-10-51 факс  e-mail: opekapristen@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 20,
        "col3": "Отдел по опеке и попечительству Администрации Поныровского района Курской области \n\n",
        "col4": "306000, Курская область, Поныровский район, п. Поныри, ул. Ленина, д. 14, \nтел.: 8(47135)2-11-07, \ne-mail: ponyri.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 21,
        "col3": "Администрация Рыльского района Курской области \n",
        "col4": "307370, Курская область, г. Рыльск, ул. К. Либкнехта, д. 23А тел. 8(47152)2-19-98, e-mail: opeka.rylsk@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 22,
        "col3": "Отдел опеки и попечительства Администрации Советского района Курской области )\n",
        "col4": "306600 Курская область, Советский район, п. Кшенский, ул. Пролетарская, д.45, тел.: 8(4712) 2-22-32, факс 8(4712)2-22-32, e-mail: opeka.sovr@rkursk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 23,
        "col3": "Администрация Солнцевского района Курской области\n",
        "col4": " 306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 44, тел.: 8(47154 2-22-36, e-mail: adm4622@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 24,
        "col3": "Управление образования Администрации Суджанского района Курской области \n",
        "col4": "305004, г. Курск, ул. Садовая, д.31,3-этаж, каб.39, тел.: 8-951-339-92-30, e-mail: sudjanskruo23@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 25,
        "col3": "Отдел по опеке и попечительству Администрации Тимского района Курской области \n",
        "col4": "307060, Курская обл., Тимский район, п. Тим, ул. Кирова 51, тел.: 8(47153)2-37-39, e-mail:opeka.admtim@rkursk.ru "
    },
    {
        "col1": "Курская область\n",
        "col2": 26,
        "col3": "Отдел по опеке и попечительству Управления образования Администрации Фатежского района Курской области \n",
        "col4": "307100, Курская область, Фатежский район, г. Фатеж, ул. Урицкого, 45, тел.: 8(47144)2-13-90, e-mail:opekafafezh89@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 27,
        "col3": "Администрация Хомутовского района Курской области \n",
        "col4": "307540, Курская область, п. Хомутовка, ул. Память Ильича, д.85, тел: 8(471-37)2-15-06, e-mail: opeka.hom@bk.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 28,
        "col3": "Отдел по опеке и попечительству Администрации Черемисиновского района Курского района\n",
        "col4": "306440, п. Черемисиново, ул. Советская, д. 4, тел.: 8(47159)2-10-75, e-mail: opeka.tcheremisinovo@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 29,
        "col3": "Администрация Щигровского района Курской области \n",
        "col4": "306530 Курская область, г. Щигры, ул. Октябрьская, д. 35; тел.: 8 (47145) 4-16-48, e-mail: shigri-opeca@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 30,
        "col3": "\nКомитет социальной защиты населения города Курска \n",
        "col4": "305007, г. Курск, ул. Пигорева, д. 2/17, тел.: (4712)70-70-35, e-mail: komsoz@kurskadmin.ru (почта отдела komitetsoc_det@mail.ru)"
    },
    {
        "col1": "Курская область\n",
        "col2": 31,
        "col3": "Администрация города Железногорска \n",
        "col4": "307170 Курская область, г. Железногорск, ул. Ленина, д. 52, тел. 8(47148)7-64-37, e-mail: zhel.otdelopeki@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 32,
        "col3": "Отдел по опеке и попечительству управления образования Администрации Курчатовского района Курской области \n",
        "col4": "Курчатовский район, 307251 г. Курчатов, пр-т Коммунистический, 12, тел.: 8(47131)4-99-88, e-mail: rayadmin@mail.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 33,
        "col3": "Администрация города Льгова Курской области\n\n",
        "col4": "307750 Курская область город Льгов, ул. Красная площадь, д.13\nТел. 8(74140)2-30-13, e-mail: shigri-opeca@mail.ru, admlov@yandex.ru"
    },
    {
        "col1": "Курская область\n",
        "col2": 34,
        "col3": "Отдел по опеке и попечительству администрации города Щигры",
        "col4": " 306530, Курская область, г. Щигры, ул. Спортивная,1 8(47145)4-97-27, opeka-shigry@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 1,
        "col3": "администрация Бокситогорского муниципального района Ленинградской области",
        "col4": " адрес: 187650, Ленинградская область, г. Бокситогорск, ул. Социалистическая, д.9, телефон: 8(81366) 211-31,электронная почта: adm@boksitogorsk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 2,
        "col3": "комитет образования администрации Волосовского муниципального района Ленинградской области.",
        "col4": " Адрес: 188410, Ленинградская область, г. Волосово, ул. Краснофлотская, дом 6, телефон: 8(81373)22114, электронная почта:  volosovoopeka@inbox.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 3,
        "col3": "управление по опеке и попечительству администрации Волховского муниципального района Ленинградской области",
        "col4": " адрес: 187406 Ленинградская область, г. Волхов, пр. Державина, д. 60, телефон: 8(81363)72705, электронная почта: opekavolhov@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 4,
        "col3": "комитет по опеке и попечительству администрации Всеволожского муниципального района Ленинградской области",
        "col4": "адрес: 188640, Ленинградская область, г. Всеволожск, Всеволожский проспект д. 12, телефон:  8(81370)20020, 8(81370)31741, электронная почта: vsevopeka@mail.ru, people@vsevreg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 5,
        "col3": "управление опеки и попечительства администрации  муниципального образования \"Выборгский район\" Ленинградской области",
        "col4": "адрес:188800, Ленинградская область, \nг. Выборг, ул. Красноармейская д. 16, телефон: 8(81378)26353, \n 8(81378)20078, электронная почта: opeka.vbg@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 6,
        "col3": "администрация Гатчинского муниципального округа Ленинградской области",
        "col4": " адрес: 188300, Ленинградская область, г. Гатчина, ул. Карла Маркса, д.44, телефон: 8(81371)93100; 8 (81371) 95309, электронная почта: info@gmolo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 7,
        "col3": "отдел опеки и попечительства администрации МО Кингисеппский муниципальный район» Ленинградская область",
        "col4": " адрес: 188480, Ленинградская область, г. Кингисепп, проспект Карла Маркса, д.2а, телефон: 8(81375)4-88-40, электронная почта: adm@kingisepplo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 8,
        "col3": "администрация Киришского муниципального района Ленинградской области",
        "col4": " адрес: 187110, Ленинградская область, г. Кириши, улица Советская, дом 20, телефон: 8(81368) 609-00, электронная почта: admkir@admkir.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 9,
        "col3": "администрация Кировского муниципального района Ленинградской области",
        "col4": " адрес: 187342, Ленинградская область, Кировский район, г. Кировск, ул. Новая, д. 1, телефон: 8(813-62) 28-196, электронная почта: adm_kmr@kirovsk-reg.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 10,
        "col3": "сектор опеки и попечительства отдела образования Администрации Лодейнопольского муниципального района Ленинградской области",
        "col4": "адрес: 187700, 187700, Ленинградская область, г. Лодейное Поле, пр. Ленина, д.16, телефон: 8(81364)39511, 8(81364)39822, lodpole-opeka@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 11,
        "col3": "отдел по опеке и попечительству администрации Ломоносовского муниципального района Ленинградской области",
        "col4": " адрес: 198412, г. Ломоносов, ул. Владимирская, дом 19/15, телефон: 8(81242)30030, электронная почта: lmn-reg@lomonosovlo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 12,
        "col3": "администрация Лужского муниципального района Ленинградской области",
        "col4": " адрес: 188230, Ленинградская область, г. Луга, пр. Кирова, д. 73, телефон: 8(81372) 2-23-06, Электронная почта: admin@adm.luga.ru , admluga@yandex.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 13,
        "col3": "администрация Подпорожского муниципального района Ленинградской области",
        "col4": " адрес: 187780, Ленинградская область, г. Подпорожье, пр. Ленина, д. 3, телефон: 8(81365)59003, электронная почта: reception@podadm.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 14,
        "col3": "администрация Приозерского муниципального района Ленинградской области",
        "col4": " адрес: 188760, Ленинградская область,  г. Приозерск, ул. Ленина, д.10, телефон: 8(81379)36973, Электронная почта: info@admpriozersk.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 15,
        "col3": "администрация Сланцевского муниципального района Ленинградской области",
        "col4": " адрес: 188560, Ленинградская область, г. Сланцы, пер. Почтовый, д. 3, телефон: 8(81374)23273, Электронная почта: slanmo@slanmo.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 16,
        "col3": "администрация Сосновоборского городского округа Ленинградской области",
        "col4": " адрес: 188540, Ленинградская область, г. Сосновый Бор, ул. Ленинградская, 46, телефон: 8(81369)26222, электронная почта: admsb@sbor.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 17,
        "col3": "комитет социальной защиты населения администрации Тихвинского района  (отдел опеки и попечительства)",
        "col4": " адрес: 187553, Ленинградская область, город Тихвин, 1 микрорайон, дом 2, телефон:  8(81367) 56250, 8(81367) 70294, электронная почта opeka.tikhvin@mail.ru"
    },
    {
        "col1": "Ленинградская область",
        "col2": 18,
        "col3": "комитет образования администрации Тосненского муниципального района Ленинградской области",
        "col4": "адрес: 187000, Ленинградская область,г. Тосно, ул. Советская, д. 10а, телефон: 8(81361)28305, электронная почта Opeka1960@yandex.ru"
    },
    {
        "col1": "Липецкая область",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства Липецкой области",
        "col4": "398001, г. Липецк,         \nул. Советская, д. 3\ne-mail: mhcs@admlr.lipetsk.ru\n +7(4742) 22-20-61"
    },
    {
        "col1": "Луганская Народная Республика",
        "col2": 1,
        "col3": "-",
        "col4": "-",
    },
    {
        "col1": "Магаданская область",
        "col2": 1,
        "col3": "Департамент имущественных и жилищных отношений мэрии города Магадана",
        "col4": "г. Магадан, ул. Горького, д. 16, каб. 214\ndizho@magadangorod.ru\n62-21-08"
    },
    {
        "col1": "Магаданская область",
        "col2": 2,
        "col3": "Управление муниципальным имуществом Администрации Ольского муниципального округа Магаданской области",
        "col4": "685910, Магаданская область. Ольский район, поселок Ола, площадь Ленина дом № 4, kumi-rayon.ola@mail.ru, 8(41341)25543, 25031"
    },
    {
        "col1": "Магаданская область",
        "col2": 3,
        "col3": "Комитет управления муниципальным имуществом администрации Омсукчанского муниципального округа",
        "col4": "6868410, п. Омсукчан, ул. Ленина, д. 19, info@omsukchan-adm.ru, 8(41346)91414"
    },
    {
        "col1": "Магаданская область",
        "col2": 4,
        "col3": "Администрация Среднеканского муниципального округа Магаданской области (Управление экономики и развития)",
        "col4": "Магаданская область, Среднеканский район, поселок Сеймчан, ул. Ленина, дом 9. amosred@mail.ru    8-413-47-9-42-59 (приемная), 8-413-47-9-44-46 (экономика)"
    },
    {
        "col1": "Магаданская область",
        "col2": 5,
        "col3": "Отдел  опеки и попечительства управления образования\nадминистрации\nСеверо-Эвенского муниципального округа",
        "col4": "686430, Магаданская область, п. Эвенск, ул. Курилова, д. 16, 8 (413-48) 2-23-74, uoobrazevensk@mail.ru"
    },
    {
        "col1": "Магаданская область",
        "col2": 6,
        "col3": "Администрация Сусуманского муниципального округа Магаданской области",
        "col4": "686314, Магаданская область. Сусуманский район, г. Сусуман, д. 17 ssmadm@mail.ru, 8(41345)22025"
    },
    {
        "col1": "Магаданская область",
        "col2": 7,
        "col3": "Управления имущественных и земельных отношений администрации Тенькинского муниципального округа Магаданской области",
        "col4": "ул. Горняцкая, д. 37, п. Усть-Омчуг, Тенькинский район,\nМагаданская область, 686050,\nтел. 8(41344)2-27-37,\nE-mail: tenka-kumi@rambler.ru\nkumi@rambler.ru\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 8,
        "col3": "Комитет по управлению муниципальным имуществом Хасынского муниципального округа Магаданской облас",
        "col4": "Руководитель КУМИ Шинкарюк Елена Владимировна  \n686110 Магаданская область, Хасынский район, п. Палатка, ул. Ленина, д.76\nE-mail: kumi.palatka@mail.ru\n84134292504\n"
    },
    {
        "col1": "Магаданская область",
        "col2": 9,
        "col3": "Администрация Ягоднинского муниципального округа Магаданской области",
        "col4": "686230, поселок Ягодное, Ягоднинский район, Магаданская область, улица Спортивная, дом 6,  \nе-mail: Priemnaya_yagodnoe@49gov.ru, KushenkoAA@49gov.ru\nтел. (8 413 43) 2-35-29,  \n(8 413 43) 2-20-95\n"
    },
    {
        "col1": "Москва",
        "col2": 1,
        "col3": "Департамент городского имущества города Москвы",
        "col4": "125993, г. Москва, 1-й Красногвардейский проезд, д. 21, стр. 1, Телефон: (495) 777-7777, E-mail: dgi@mos.ru"
    },
    {
        "col1": "Московская область",
        "col2": 1,
        "col3": "-",
        "col4": "-",
    },
    {
        "col1": "Мурманская область",
        "col2": 1,
        "col3": "Администрация города Апатиты",
        "col4": "пл. Ленина., д. 1, г. Апатиты, Мурманская область, 184209, (8 81555)  6 02 36, opeka-deti@apatity-city.ru  "
    },
    {
        "col1": "Мурманская область",
        "col2": 2,
        "col3": "Администрация ЗАТО Александровск",
        "col4": "ул. Флотская, д. 9, ЗАТО Александровск, Мурманская область, 184682, (881539) 5 23 22, ZarenkovaLV@zato-a.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 3,
        "col3": "Администрация ЗАТО пос. Видяево",
        "col4": "ул. Центральная, д. 8, ЗАТО Видяево, Мурманская область, 184372, (881553) 5 66 74, opeka@zatovid.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 4,
        "col3": "Администрация ЗАТО город Заозерск",
        "col4": "ул. Генерала Чумаченко, д. 4,                   г. Заозерск, Мурманская область, 184310,\nopekazaozersk@mail.ru,\n8(81556) 3 15 12"
    },
    {
        "col1": "Мурманская область",
        "col2": 5,
        "col3": "Администрация Кандалакшского района",
        "col4": "ул. Первомайская, д. 34, г. Кандалакша, Мурманская область, 184040, (8 815 33) 9 24 00, 9 30 11 \nopeka-kanda@yandex.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 6,
        "col3": "Администрация города Кировска",
        "col4": "пр. Ленина, д. 16, г. Кировск, Мурманская область, 184250, (881531) 5 52 75, gnevysheva.nv@gov.kirovsk.ru\n "
    },
    {
        "col1": "Мурманская область",
        "col2": 7,
        "col3": "Администрация Ковдорского муниципального округа",
        "col4": "пл. Ленина., д. 1, г. Ковдор, Мурманская область, 184141, (8 81535) 5 02 42 доб. 227, i.iosifova@kovadm.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 8,
        "col3": "Администрация Кольского района",
        "col4": "Советский пр., д. 50, г. Кола, Мурманская область, 184381, (81553) 3 61 82, opeka_deti@akolr.gov-murman.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 9,
        "col3": "Администрация Ловозерского района",
        "col4": "ул. Советская, д. 10, с. Ловозеро, Мурманская область, 184592, (8 815 38) 40 111, opeca-lov@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 10,
        "col3": "Администрация города Мончегорска",
        "col4": "ул. Железнодорожников, д. 6, г. Мончегорск, Мурманская область, 184511,  (881536) 3 18 90, opd@edumonch.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 11,
        "col3": "Администрация города Мурманска ",
        "col4": "ул. Комсомольская, д. 10, г. Мурманск, 183036, (8 8152)  45-06-24, 45-61-71, kio@citymurmansk.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 12,
        "col3": "Администрация муниципального округа город Оленегорск с подведомственной территорией",
        "col4": "ул. Мира, д. 38, г. Оленегорск, Мурманская область, 184530, (8 815 52) 51 000, opeka-olen@admol.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 13,
        "col3": "Администрации ЗАТО г. Островной",
        "col4": "пл. Жертв Интервенции, д. 1,                     г. Островной, Мурманская обл. 184640,  (881558) 5 00 12, admzato@zato-ostrov.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 14,
        "col3": "Администрация Печенгского муниципального округа",
        "col4": "ул. Победы, д. 1, п.г.т. Никель, Печенгский район, Мурманская область, 184421, \nтелефон (81554) 5-19-40, 5-05-72;  obrpechenga@yandex.ru"
    },
    {
        "col1": "Мурманская область",
        "col2": 15,
        "col3": "Администрация города Полярные Зори",
        "col4": "ул. Ломоносова, д. 4, г. Полярные Зори, Мурманская область, 184230, (881532) 7 12 24, opeka@pz-city.ru "
    },
    {
        "col1": "Мурманская область",
        "col2": 16,
        "col3": "Администрация ЗАТО г. Североморск ",
        "col4": "пр. Советский, д. 50, г. Кола, Мурманская область, 184381, (8 81553)  3 61 82, 3 34 29, opeka-deti@akolr.gov-murman.ru   "
    },
    {
        "col1": "Мурманская область",
        "col2": 17,
        "col3": "Администрация Терского района",
        "col4": "пгт. Умба Терского района, Мурманская область, 184703, (8 81555)  6-71-12, ter.obrazovanie@mail.ru "
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент здравоохранения, труда и социальной защиты населения Ненецкого автономного округа",
        "col4": "ул. Смидовича, д.25, г. Нарьян-Мар, Ненецкий автономный округ, 166000; e-mail: medsoc@adm-nao.ru, тел. 8(81853) 2-13-68"
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 2,
        "col3": "Департамент строительства, жилищно-коммунального хозяйства, энергетики и транспорта Ненецкого автономного округа",
        "col4": "ул. им. А.П. Пырерко, д. 7, г. Нарьян-Мар, Ненецкий автономный округ, 166000, e-mail: naostroy@adm-nao.ru, 8(81853) 2-19-21"
    },
    {
        "col1": "Ненецкий автономный округ",
        "col2": 3,
        "col3": "Управление имущественных и земельных отношений Ненецкого автономного округа",
        "col4": "ул. им. В.И. Ленина, д. 27В, г. Нарьян-Мар, Ненецкий автономный округ, 166000, e-mail: uizo@adm-nao, 2-38-83"
    },
    {
        "col1": "Нижегородская область",
        "col2": 1,
        "col3": "Администрация Ардатовского муниципального округа",
        "col4": "Администрация Ардатовского муниципального округа  \nул. Ленина, д.28, р.п. Ардатов, 607130 arono@mts-nn.ru        8(83179)5-02-56        \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 2,
        "col3": "Администрация Балахнинского муниципального округа",
        "col4": "Администрация Балахнинского муниципального округа ул. Лесопильная, дом 24, г. Балахна, 606403, opeka@adm.bal.nnov.ru 8(83144)68299 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 3,
        "col3": "Администрация Богородского муниципального округа ",
        "col4": "Администрация Богородского муниципального округа Нижегородской области ул. Ленина, д. 206, г. Богородск, 607600, opekabog@yandex.ru 8(83170)23871        "
    },
    {
        "col1": "Нижегородская область",
        "col2": 4,
        "col3": "Администрация Большеболдинского муниципального округа ",
        "col4": "Администрация Большеболдинского муниципального округа Нижегородской области ул. Пушкинская, д.144, с. Б.Болдино, 607940, opekaboldino@yandex.ru 8(83138)2-26-21"
    },
    {
        "col1": "Нижегородская область",
        "col2": 5,
        "col3": "Администрация Большемурашкинского муниципального округа",
        "col4": "Администрация Большемурашкинского муниципального округа      ул. Свободы, д. 86, р.п. Б.Мурашкино, 606360 detimolkdn@mail.ru 8(83167)5-11-32"
    },
    {
        "col1": "Нижегородская область",
        "col2": 6,
        "col3": "Администрация городского округа г. Бор",
        "col4": "Администрация городского округа г. Бор ул. Ленина, д. 97, г. Бор, Нижегородская область, 606440, opekabor@yandex.ru 8(831)5991478"
    },
    {
        "col1": "Нижегородская область",
        "col2": 7,
        "col3": "Администрация Бутурлинского муниципального округа",
        "col4": "Администрация Бутурлинского муниципального округа ул. Ленина, д. 106, п.г.т. Бутурлино, 607440, opeka-buturlino@mail.ru 8(831)7251813"
    },
    {
        "col1": "Нижегородская область",
        "col2": 8,
        "col3": "Администрация\nВадского муниципального округа",
        "col4": "Администрация\nВадского муниципального округа ул. 1 Мая, д. 41, с. Вад, 606380, opeka-vad@yandex.ru 8(831)4043042\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 9,
        "col3": "Администрация Варнавинского муниципального округа ",
        "col4": "Администрация Варнавинского муниципального округа    пл. Советская, д.2, р.п. Варнавино, 606760, ruo_varnavino@mail.ru        8 (831) 583 52 44      "
    },
    {
        "col1": "Нижегородская область",
        "col2": 10,
        "col3": "Администрации Вачского муниципального округа",
        "col4": "Администрации Вачского муниципального округа ул.Советская, дом 26б, р.п. Вача, 606150, vachaopeka52@yandex.ru 8(831)7361977"
    },
    {
        "col1": "Нижегородская область",
        "col2": 11,
        "col3": "Администрация Ветлужского муниципального округа ",
        "col4": "Администрация Ветлужского муниципального округа Нижегородской области ул.Ленина, дом 42, г.Ветлуга, 606860, vetl-opeka@mail.ru 8(83150)235-04"
    },
    {
        "col1": "Нижегородская область",
        "col2": 12,
        "col3": "Администрация Вознесенского муниципального округа",
        "col4": "Администрация Вознесенского муниципального округа ул. Советская, д. 33, р.п. Вознесенское, 607340, paramonova-opeka@yandex.ru 8(83178)631-88 "
    },
    {
        "col1": "Нижегородская область",
        "col2": 13,
        "col3": "Администрация Володарского муниципального округа",
        "col4": "Администрация Володарского муниципального округа \nул. Клубная, дом 4, г. Володарск, 606070, opeka-volodarsk@mail.ru 8(831)3649202\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 14,
        "col3": "Администрация городского округа Воротынский",
        "col4": "Администрация городского округа Воротынский пл. Советская, дом 6, р.п. Воротынец, 606260 opeka@adm.vrt.nnov.ru 8(831)6420151"
    },
    {
        "col1": "Нижегородская область",
        "col2": 15,
        "col3": "Администрация Воскресенского муниципального округа",
        "col4": "Администрация Воскресенского муниципального округа \nул. Ленина, д.105, р.п. Воскресенское, 606730 opeka-vosk@yandex.ru 8(83163)922-85\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 16,
        "col3": "Администрация городского округа г. Выкса ",
        "col4": "Администрация городского округа г. Выкса \nг.Выкса, Красная площадь, дом 1, 607060, uo@vyksa-okrug.ru 8(831)7734244 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 17,
        "col3": "Администрация Гагинского муниципального округа ",
        "col4": "Администрация Гагинского муниципального округа \nул. Ленина, д. 44, с. Гагино, 607870, opekagag@yandex.ru 8(831)9521472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 18,
        "col3": "Администрация Городецкого муниципального округа",
        "col4": "Администрация Городецкого муниципального округа\nпл. Пролетарская, д. 30, г. Городец, 606500, gorodec_opeka@mail.ru 8(8316) 9-1766  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 19,
        "col3": "Администрация Дальнеконстантиновского муниципального округа",
        "col4": "Администрация Дальнеконстантиновского муниципального округа\nул. Советская, д. 103, р.п. Дальнее Константиново, 606310, opekadk@yandex.ru \n8(831)6852218"
    },
    {
        "col1": "Нижегородская область",
        "col2": 20,
        "col3": "Администрация Дивеевского муниципального округа ",
        "col4": "Администрация Дивеевского муниципального округа \nул.Октябрьская, д.10, с.Дивеево, 607320, roo.adm.diveevo@mail.ru 8(83134)45460\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 21,
        "col3": "Администрация Княгининского муниципального округа",
        "col4": "Администрация Княгининского муниципального округа\nул.Свободы, д.45, г. Княгинино, 606340, rukavisnikova.tatyana@mail.ru 8(831)6641046\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 22,
        "col3": "Администрация Ковернинского муниципального округа",
        "col4": "Администрация Ковернинского муниципального округа\nул. Карла Маркса, д. 4, р.п. Ковернино, 606570, roo@adm.kvr.nnov.ru 8(831)5722568\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 23,
        "col3": "Администрация Краснобаковского муниципального округа",
        "col4": "Администрация Краснобаковского муниципального округа \nул. Свободы, д. 104, р.п. Красные Баки, 606710, opeka-krbaki@mailru 8(831)5622531\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 24,
        "col3": "Администрация Краснооктябрьского муниципального округа",
        "col4": "Администрация Краснооктябрьского муниципального округа \nул. Кооперативная, дом 39, село Уразовка, 607530, obkroktyabr@mail.ru  8(831) 265 38 13 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 25,
        "col3": "Администрация Кстовского района",
        "col4": "Администрация Кстовского района\nпл. Ленина, дом 4, г. Кстово, 607650, opeka.kctadm@mail.ru 8(831)4539214\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Кулебаки",
        "col4": "Администрация городского округа г. Кулебаки\nул. Воровского, д. 43а, г. Кулебаки, 607010, opeka_kulebaki@bk.ru 8(831)7653062\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 27,
        "col3": "Администрация Лукояновского муниципального округа",
        "col4": "Администрация Лукояновского муниципального округа \nул. Коммуны, д. 38, г. Лукоянов, Нижегородской области, 607800, luk-opeka@yandex.ru 8(831)9642153"
    },
    {
        "col1": "Нижегородская область",
        "col2": 28,
        "col3": "Администрация Лысковского муниципального округа ",
        "col4": "Администрация Лысковского муниципального округа \nул. Ленина, д. 23, г. Лысково, 606210, lysopeka@mail.ru 8(831)495-38-83   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 29,
        "col3": "Администрация городского округа Навашинский",
        "col4": "Администрация городского округа Навашинский  \nул. 1 Мая, д. 6, г. Навашино, 607102, opekanavashino@yandex.ru 8(831)7557367\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 30,
        "col3": "Администрация Павловского муниципального округа ",
        "col4": "Администрация Павловского муниципального округа \nул.Ленина, д.27, г.Павлово, 606100, detiopekapavlovo@mail.ru 8(831)7121595\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 31,
        "col3": "Администрация городского округа г. Первомайск",
        "col4": "Администрация городского округа г. Первомайск\nул. Ульянова, дом 2, г. Первомайск, 607760, perv_rono@mail.ru 8(831)3921472\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 32,
        "col3": "Администрация городского округа Перевозский",
        "col4": "Администрация городского округа Перевозский\nпр. Советский, д. 8, г.Перевоз, 607400, opekapvz@yandex.ru 8(831)485-28-41   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 33,
        "col3": "Администрация Пильнинского муниципального округа ",
        "col4": "Администрация Пильнинского муниципального округа \nул. Урицкого, д. 16, р.п. Пильна, 607490, uomps@mail.ru 8(831)9251539  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 34,
        "col3": "Администрации Починковского муниципального округа",
        "col4": "Администрации Починковского муниципального округа \nпл.Ленина, д.9, с.Починки, 607910, karpaeva.lena@mail.ru 8(831)9750043\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 35,
        "col3": "Администрация городского округа Семеновский",
        "col4": "Администрация городского округа Семеновский\nул.1-е Мая, д.1, г. Семенов, 606650, kdn@semenov.nnov.ru 8(83162)5-27-97\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 36,
        "col3": "Администрация\nСергачского муниципального округа",
        "col4": "Администрация\nСергачского муниципального округа \nул. Советская, дом 30, г. Сергач, 607510, opeka-grishina@mail.ru 8(831)9152911\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 37,
        "col3": "Администрация Сеченовского муниципального округа ",
        "col4": "Администрация Сеченовского муниципального округа \nпл. Советская, д. 4, с. Сеченово, 607580,  komolova-anna@mail.ru 8(831)93-5-24-08\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 38,
        "col3": "Администрация городского округа Сокольский",
        "col4": "Администрация городского округа Сокольский\nул. Набережная, д. 9, п. Сокольское, 606670, detstvo.sokolskoe@mail.ru 8(831)372-07-73 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 39,
        "col3": "Администрация Сосновского муниципального округа",
        "col4": "Администрация Сосновского муниципального округа \nул. Ленина, д. 27, р.п. Сосновское, д.27, 606170, opekasosn@yandex.ru 8(83174) 2-63-27\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 40,
        "col3": "Администрация Спасского муниципального округа",
        "col4": "Администрация Спасского муниципального округа\nпл. Революции, д. 71, с. Спасское, 606280, spasopeka@yandex.ru 8(831)652-60-15\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 41,
        "col3": "Администрация Тонкинского муниципального округа ",
        "col4": "Администрация Тонкинского муниципального округа  \nул. Ленина, д.1, р.п. Тонкино, 606970, roo_tonkino2002@mail.ru   \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 42,
        "col3": "Администрация Тоншаевского муниципального округа",
        "col4": "Администрация Тоншаевского муниципального округа \nул. Свердлова, дом 2а, р.п. Тоншаево, 606950, ton_opeka@rambler.ru 8(831)512-22-95\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 43,
        "col3": "Администрация Уренского муниципального округа",
        "col4": "Администрация Уренского муниципального округа \nул. Советская, д. 26, г. Урень, 606800, uren.ruo@mail.ru    8(831)542-11-63\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 44,
        "col3": "Администрации городского округа г. Чкаловск",
        "col4": "Администрации городского округа             г. Чкаловск\nпл. Комсомольская, д.2,    г. Чкаловск, 606540,  \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 45,
        "col3": "Администрация\nШарангского муниципального округа",
        "col4": "Администрация\nШарангского муниципального округа \nул. Свободы, дом 2, пгт. Шаранга, 606840, roo_shar2003@mail.ru 8(831)5521763           \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 46,
        "col3": "Администрация\nШатковского муниципального округа",
        "col4": "Администрация\nШатковского муниципального округа \nул. Федеративная, д.4, р.п. Шатки, 607700, opeka0803@mail.ru 8(831)904-31-34\n \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 47,
        "col3": "Администрация городского округа г. Шахунья Нижегородской области ",
        "col4": "Администрация городского округа г. Шахунья Нижегородской области \nпл. Советская, д. 1, г. Шахунья, 606910, opekashah.52@mail.ru 8(831)5226655 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 48,
        "col3": "Администрация городского округа г. Арзамас",
        "col4": "Администрация городского округа г. Арзамас \nул. Советская, дом 10, г. Арзамас 607220, arzopeka@rambler.ru 8(831)4775738 \n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 49,
        "col3": "Администрация г. Дзержинска",
        "col4": "Администрация                  г. Дзержинска\nпл. Ленина, д. 5, г. Дзержинск, 606032, opeka-dz@mail.ru 8(831)3397212\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 50,
        "col3": "Администрации г. Саров ",
        "col4": "администрации г. Саров \nул. Гагарина, дом 6, г. Саров, 607188,nud@adm-sarov.ru 8(831)3099124\n"
    },
    {
        "col1": "Нижегородская область",
        "col2": 51,
        "col3": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)",
        "col4": "Департамент образования администрации города Нижнего Новгорода (Отдел опеки и попечительства)\nул. Большая Покровская, д.15, г. Н.Новгород, 603005, muhanova@admgor.nnov.ru8(831)435-69-83  \n"
    },
    {
        "col1": "Новгородская область",
        "col2": 1,
        "col3": "Администрация Батецкого муниципального округа (комитет образования Администрации Батецкого муниципального округа)",
        "col4": "Новгородская область, п.Батецкий, ул.Советская, д.39а, 175000; адрес электронной почты: gkomobr@mail.ru;  8(81661)22401 (доб.222)"
    },
    {
        "col1": "Новгородская область",
        "col2": 2,
        "col3": "Администрация Боровичского муниципального района (комитет жилищно-коммунального, дорожного хозяйства, строительства, транспорта и охраны окружающей среды Администрации Боровичского муниципального района)",
        "col4": "Новгородская область, г.Боровичи, ул.Коммунарная, д.48, 174411; адрес электронной почты:jil@boradmin.ru; 8(81664)91213"
    },
    {
        "col1": "Новгородская область",
        "col2": 3,
        "col3": "Администрация Валдайского муниципального района (комитет жилищно-коммунального и дорожного хозяйства Администрации  Валдайского муниципального района)",
        "col4": " Новгородская область, г.Валдай, пр.Комсомольский, д.19/21, 175400, адрес электронной почты: admin@valdayadm.ru;  8(81666)22516"
    },
    {
        "col1": "Новгородская область",
        "col2": 4,
        "col3": "Администрация Волотовского муниципального округа",
        "col4": "Новгородская область, п.Волот, ул.Комсомольская, д.38, 175100; адрес электронной почты: adm.volot@mail.ru; 8(81662)61325"
    },
    {
        "col1": "Новгородская область",
        "col2": 5,
        "col3": "Администрация  Демянского муниципального округа",
        "col4": "Новгородская область, п.Демянск, ул.Ленина, д.7, 175310, адрес электронной почты:priemnaya@dem-admin.ru; 8(81651)44012 (доб.6400)"
    },
    {
        "col1": "Новгородская область",
        "col2": 6,
        "col3": "Администрация Крестецкого муниципального округа ( комитет по имущественным и земельным отношениям Администрации Крестецкого муниципального округа)",
        "col4": "Новгородская область, п.Крестцы, Советская площадь, д.1, 175460; адрес электронной почты: kumi@adm-krestcy.ru; 8(81659)54505"
    },
    {
        "col1": "Новгородская область",
        "col2": 7,
        "col3": "Администрация Любытинского муниципального района",
        "col4": "Новгородская область, п.Любытино, ул.Советов, д.29, 174760; адрес электронной почты:opeka.lyubytino@yandex.ru; 8(81668)61359"
    },
    {
        "col1": "Новгородская область",
        "col2": 8,
        "col3": "Администрация Маловишерского муниципального района ",
        "col4": " Новгородская область, г.Малая Вишера, ул.Володарского, д.14,174260; адрес электронной почты: mv.otdeljkh@mail.ru; 8(81660)33548"
    },
    {
        "col1": "Новгородская область",
        "col2": 9,
        "col3": "Администрация Маревского муниципального округа",
        "col4": "Новгородская область, с. Марёво, ул. Советов, д. 27,175350, адрес электронной почты: marevoobr@yandex.ru; 8(81663)21450 (доб.6843)"
    },
    {
        "col1": "Новгородская область",
        "col2": 10,
        "col3": "Администрация Мошенского муниципального округа",
        "col4": "Новгородская область, Мошенской муниципальный округ, с. Мошенское, ул.Советская, д.5; адрес электронной почты:mosh-adm@yandex.ru;8(81653)61146"
    },
    {
        "col1": "Новгородская область",
        "col2": 11,
        "col3": "Администрация  Окуловского муниципального района (комитет жилищно-коммунального хозяйства и дорожной деятельности Администрации Окуловского муниципального района)",
        "col4": "Новгородская область, г.Окуловка, ул.Кирова, д.6, 174350; адрес электронной почты: 22kabokuladm@mail.ru; 8(81657)22058; 8(81657)21331"
    },
    {
        "col1": "Новгородская область",
        "col2": 12,
        "col3": "Администрация Новгородского муниципального района (комитет по управлению муниципальным имуществом Администрации Новгородского муниципального района)",
        "col4": "г.Великий Новгород, ул.Большая Московская, д.60,173004, адрес электронной почты:AdmNovRay@yandex.ru, 8(8162)943612"
    },
    {
        "col1": "Новгородская область",
        "col2": 13,
        "col3": "Администрация Парфинского муниципального района",
        "col4": "Новгородская область, Парфинский муниципальный  район, п.Парфино, ул.К.Маркса,д.60, 175130; адрес электронной почты:parvrono@yandex.ru; 8(81650)6-30-42"
    },
    {
        "col1": "Новгородская область",
        "col2": 14,
        "col3": "Администрация Пестовского муниципального района",
        "col4": "Новгородская область, Пестовский муниципальный округ, г.Пестово, ул.Советская, д.10, 174510; адрес электронной почты:admin@adm-pestovo.ru; 8(81669)57466"
    },
    {
        "col1": "Новгородская область",
        "col2": 15,
        "col3": "Администрация Поддорского муниципального района",
        "col4": "Новгородская область, Поддорский муниципальный район, с.Поддорье, ул.Полевая, д.17, 175260; адрес электронной почты:obr@admpoddore.ru, 8(81658)71692"
    },
    {
        "col1": "Новгородская область",
        "col2": 16,
        "col3": "Администрация Солецкого муниципального округа",
        "col4": "Новгородская область, г.Сольцы, пл.Победы, д.3, 175040; адрес электронной почты: soleco@adminsoltcy.ru; 8(81655)31269"
    },
    {
        "col1": "Новгородская область",
        "col2": 17,
        "col3": "Администрация Старорусского муниципального района",
        "col4": "Новгородская область, г.Старая Русса, ул.Советская Набережная, д.1,175202; адрес электронной почты: pochta@admrussa.ru; 8(81652)22335"
    },
    {
        "col1": "Новгородская область",
        "col2": 18,
        "col3": "Администрация Хвойнинского муниципального округа",
        "col4": "Новгородская область, Хвойнинский муниципальный округ, п.Хвойная, ул.Советская, д.4, 174580; адрес электронной почты: ko5317@yandex.ru, 8(81667)50395"
    },
    {
        "col1": "Новгородская область",
        "col2": 19,
        "col3": "Администрация Холмского муниципального округа",
        "col4": "Новгородская область, Холмский муниципальный округ, г.Холм, пл.Победы, д.4, к.3, 175270; адрес электронной почты:opeka53cholm2025@mail.ru? 8(81654)59162"
    },
    {
        "col1": "Новгородская область",
        "col2": 20,
        "col3": "Администрация Чудовского муниципального района ",
        "col4": "Новгородская область, г.Чудово, ул.Некрасова, д.24а, 174210, адрес электронной почты: komobr@adminchudovo.ru, 8(81665)54858"
    },
    {
        "col1": "Новгородская область",
        "col2": 21,
        "col3": "Администрация Шимского муниципального района",
        "col4": "Новгородская область, Шимский муниципальный район, п.Шимск, ул.Новгородская, д.21,174150; адрес электронной почты:isveshenie@mail.ru; 8(81656)54636"
    },
    {
        "col1": "Новгородская область",
        "col2": 22,
        "col3": "Администрация Великого Новгорода ( управление по жилищным вопросам и социальной поддержке граждан Администрации Великого Новгорода)",
        "col4": "Новгородская область, г.Великий Новгород, ул.Стратилатовская,д.3; адрес электронной почты: shse@adm.nov.ru, 8(8162)778239"
    },
    {
        "col1": "Новосибирская область",
        "col2": 1,
        "col3": "администрация Баганского района Новосибирской области отдел  строительства и дорожного комплекса ",
        "col4": "Новосибирская область, Баганский район, с. Баган ул.М. Горького д.21                                   8-383-53-21-967"
    },
    {
        "col1": "Новосибирская область",
        "col2": 2,
        "col3": "администрация Барабинского района Новосибирской области",
        "col4": "632334, Новосибирская область, г. Барабинск, ул. Ульяновская, 66; эл. почта: glv_admbar@nso.ru; тел.:  8-383-61-258-08"
    },
    {
        "col1": "Новосибирская область",
        "col2": 3,
        "col3": "администрация Болотнинского района Новосибирской области",
        "col4": "633340, Новосибирская область, Болотнинский район, город Болотное, улица Советская, 9, тел. 8-383-49-22-730, 8-383-49-22-136, bolotnoe-adm@yandex.ru, maer_en@nso.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 4,
        "col3": "Администрация Венгеровского района Новосибирской области",
        "col4": "632241, Новосибирская область, Венгеровский район, с.Венгерово, ул.Ленина, д.68/       pradmvengr@yandex.ru/           (383 69) 21-592"
    },
    {
        "col1": "Новосибирская область",
        "col2": 5,
        "col3": "Администрация Доволенского района Новосибирской области",
        "col4": "8-383-54-20-508, opeka-dovol@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 6,
        "col3": "администрация Здвинского района Новосибирской области",
        "col4": "632951, Новосибирская область, Здвинский район, с. Здвинск, ул. Мира, д.13, admn-zdv@yandex.ru,    8 383 63 21 278"
    },
    {
        "col1": "Новосибирская область",
        "col2": 7,
        "col3": "Отдел опеки и попечительства администрации Искитимского района Новосибимрской области",
        "col4": "633209, Новосибирская область, г. Искитим, ул. Пушкина, д. 57а, эл. почта : opeka_29@mail.ru? тел. 8 (38343) 4-22-60"
    },
    {
        "col1": "Новосибирская область",
        "col2": 8,
        "col3": "Администрация Карасукского муниципального округа Новосибирской области",
        "col4": "632868, НСО, г. Карасук, ул. Октябрьская, дом 39, E-mail: radm-karasuk@nso.ru. Тел. 8(383)55 33-135, 8(383)55 32-256"
    },
    {
        "col1": "Новосибирская область",
        "col2": 9,
        "col3": "Отдел имущества и земельных отношений администрации Каргатского района Новосибирской области",
        "col4": "632402 Новосибирская область, Каргатский район,город Каргат, улица Советская, дом 122 тел.83836523630, электронная почта - koef@nso.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 10,
        "col3": "Администрация Колыванского района Новосибирской области ",
        "col4": "633161, Новосибирская область, Колыванский район, р.п. Колывань, ул. Ленина, д. 79, тел. 8 383 52 51 284,        эл. почта koluvan-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 11,
        "col3": "администрация Коченевского района",
        "col4": "632640, Новосибирская область, р.п. Коченево,          ул. Октябрьская, 43/kochadm@nso.ru/            8(383) 5125406"
    },
    {
        "col1": "Новосибирская область",
        "col2": 12,
        "col3": "Администрация Кочковского района Новосибирской области",
        "col4": "632491, Новосибирская область, Кочковский район, с. Кочки, ул. Революционная, 11, admkck@mail.ru, kochkiopeka@mail.ru, 8(383)56-22-300"
    },
    {
        "col1": "Новосибирская область",
        "col2": 13,
        "col3": "администрация Краснозерского района",
        "col4": "Новосибирская область, р.п.Краснозерское, ул.Чкалова 5.632902. 83835742398"
    },
    {
        "col1": "Новосибирская область",
        "col2": 14,
        "col3": "Отдел опеки и попечительства администрации Куйбышевского муниципального района Новосибирской области",
        "col4": "632380,г. Куйбышев, ул. Куйбышева, 12             Тел.8(383 62)51-462              e-mai:      kainsk_opeka@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 15,
        "col3": "Администрация Купинского района Новосибирской области",
        "col4": "632735, Новосибирская область, Купинский район, г.Купино, ул. Железнодорожная, 42-а ( отдел опеки и попечительства) Тел: 8-383-58-20-997; opekakup@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 16,
        "col3": "Отдел опеки и попечительства  администрации Кыштовского района Новосибирской области",
        "col4": "632270 ,Новосибирская область,Кыштовский район,с.Кыштовка,ул.Ленина,д.38\\opeka-kyshtovka@yandex.ru\\83837121 174"
    },
    {
        "col1": "Новосибирская область",
        "col2": 17,
        "col3": "администрация Маслянинского муниципального округа Новосибирской области",
        "col4": "633564, Новосибирская область, Маслянинский район, р.п. Маслянино, улица Коммунистическая, дом 1а,  8-383-47-21-418   admmsl@yandex.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 18,
        "col3": "Администрация Мошковского района Новосибирской области",
        "col4": "633131, Новосибирская область, Мошковский район, р.п. Мошково, ул. Советская д. 9                                               тел. 838321266, электронная почта: oopadm@rambler.ru "
    },
    {
        "col1": "Новосибирская область",
        "col2": 19,
        "col3": "Администрация Новосибирского района Новосибирской области",
        "col4": "г.Новосибирск, ул.Фабричная, д.55, 6 этаж, оф.602, 20opeka13@mail.ru, 373-45-87"
    },
    {
        "col1": "Новосибирская область",
        "col2": 20,
        "col3": "Администрация Ордынского района Новосибирской области",
        "col4": "633261, Новосибирская область, Ордынский район, р.п. Ордынское, пр. Революции, 17 /ordopeka@mail.ru/8-383-59-23-673"
    },
    {
        "col1": "Новосибирская область",
        "col2": 21,
        "col3": "Администрация Северного района Новосибирской области",
        "col4": "632080, Новосибирская обл., Северный р-н, с. Северное, ул Ленина, д.14 тел: 8(38360)21818 sevadmn@yandex.ru  "
    },
    {
        "col1": "Новосибирская область",
        "col2": 22,
        "col3": "администрация Сузунского района Новосибирской области",
        "col4": "Новосибирская область, Сузунский район, р.п. Сузун, ул. Ленина, 51                           adm@suzunadm.ru              (38346)22550"
    },
    {
        "col1": "Новосибирская область",
        "col2": 23,
        "col3": "отдел опеки и попечительства администрации Татарского муниципального округа Новосибирской области",
        "col4": "632122, Новосибирская область, г. Татарск, ул. Ленина, 56, opekatat@mail.ru, 89383 64) 2-62-54"
    },
    {
        "col1": "Новосибирская область",
        "col2": 24,
        "col3": "администрация Тогучинского района Новосибирской области",
        "col4": "633456, Новосибирская область, г. Тогучин, ул. Садовая, д.9б/ togopeka@mail.ru/8 383 40 24886"
    },
    {
        "col1": "Новосибирская область",
        "col2": 25,
        "col3": "администрация Убинского района Новосибирской области",
        "col4": "632520 Новосибирская область, Убинский район, с. Убинское, ул. Ленина, 23 тел. 8 (38366) 21-527                             8 (38366) 21-131  ubinadm@yandex.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 26,
        "col3": "администрация Усть-Таркского района Новосибирской области",
        "col4": "632160, Новосибирская область, Усть-Таркский район, с. Усть-Тарка, ул. Иванова, 7;   ustopeka@nso.ru/83837223574 (ООиП)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 27,
        "col3": "Администрация Чановского района Новосибирской области",
        "col4": "632201, р.п.Чаны, ул.Советская, д.118\nт.21-185, факс 21-657\nchany-adm@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 28,
        "col3": "Администрация Черепановского района Новосибирской области",
        "col4": "633520, Новосибирская область, Черепановский район,г.Черепаново, ул.Партизанская, 12Email:orgotd@nso.ru, т.8(383 45)21368,                      8(383 45)22206,                          8(383 45)24538"
    },
    {
        "col1": "Новосибирская область",
        "col2": 29,
        "col3": "Администрация Чистоозерного района \nНовосибирской области ",
        "col4": "632720, Новосибирская область,\nр.п. Чистоозерное, ул. Покрышкина, 11\nopekachst@yandex.ru, i.krasovsckaja2013@yandex.ru\n8 383 68 91 735, 8 383 68 91 331   "
    },
    {
        "col1": "Новосибирская область",
        "col2": 30,
        "col3": "администрация Чулымского района",
        "col4": "632551,Новосибирская область, Чулымский район, г. Чулым, ул. Чулымская, 43 (chladm@nso.ru. Тел.83835021656)"
    },
    {
        "col1": "Новосибирская область",
        "col2": 31,
        "col3": "Админитсрация города Бердска",
        "col4": "633010, Новосибирская область, г.Бердск, ул. М.Горького, 7 oopberdsk@nso.ru  8(38341)29297"
    },
    {
        "col1": "Новосибирская область",
        "col2": 32,
        "col3": "Администрация города Искитима Новосибирской области",
        "col4": " 633209, Новосибирская область, город Искитим, ул.Пушкина, 51.\npri_iskadm@mail.ru\n83834324566"
    },
    {
        "col1": "Новосибирская область",
        "col2": 33,
        "col3": "Администрация рабочего поселка Кольцово",
        "col4": "рабочий поселок Кольцово, проспект Никольский, \n здание 1, офис 400\nНовосибирская область, 630559\nтелефон: 336-15-93, \nE-mail: ooip@kolcovo.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 34,
        "col3": "Администрация города Оби Новосибирской области",
        "col4": "633102, Новосибирская область, ул. Авиационная, д.12, т. 8(38373)56110, email: adm_nso@mail.ru"
    },
    {
        "col1": "Новосибирская область",
        "col2": 35,
        "col3": "мэрия города Новосибирска ",
        "col4": "г. Новосибирск, Красный проспект, 34 каб. 443 . Тел. 227-45-02, 227-45-44. Abatashyrova@admnsk.ru; Nfomina@admnsk.ru"
    },
    {
        "col1": "Омская область\n",
        "col2": 1,
        "col3": "Министерство строительства Омской области",
        "col4": "Некрасова ул., 3/1, \nг. Омск, 644099 \ne-mail: minstroy@omskportal.ru\nТел.: (3812)77-03-84"
    },
    {
        "col1": "Оренбургская область",
        "col2": 1,
        "col3": "министерство социального развития Оренбургской области",
        "col4": "460006, Оренбургская область, город Оренбург, Терешковой улица, 33 szn@mail.orb.ru  8(3532) 77-33-38"
    },
    {
        "col1": "Оренбургская область",
        "col2": 2,
        "col3": "Абдулинский муниципальный округ:",
        "col4": " Оренбургская область, г. Абдулино, ул. Коммунистическая, 276, 8 (35355) 2-52-47, ab@mail.orb.ru. "
    },
    {
        "col1": "Оренбургская область",
        "col2": 3,
        "col3": "город Бугуруслан: ",
        "col4": "Оренбургская обл., г. Бугуруслан, ул. Ленинградская, 47, 8 (35352) 3-33-69, bg@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 4,
        "col3": "город Бузулук: ",
        "col4": "Оренбургская область, г. Бузулук, ул.Ленина, 10, 8 (35342) 3-51-35, adm@buzuluk-town.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 5,
        "col3": " Гайский муниципальный округ: ",
        "col4": "Оренбургская область, г. Гай, ул. Ленина, 41, 8 (35362) 4-20-33. goradm@esoo.ru, gy@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 6,
        "col3": " Кувандыкский муниципальный округ",
        "col4": " Оренбургская область, г. Кувандык, ул. Оренбургская, 20, 8 (35361) 2-37-13, 2-22-20, ku@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 7,
        "col3": " город Медногорск:",
        "col4": " Оренбургская область, г. Медногорск, ул. Советская, 37, 8 (35379) 3-26-86, mo@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 8,
        "col3": "город Новотроицк: ",
        "col4": "Оренбургская область, г. Новотроицк, ул. Советская, 80, 8 (35376) 2-01-01, adm-nvk@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 9,
        "col3": " город Оренбург: ",
        "col4": "Оренбургская область, г. Оренбург, ул.Советская, 60, 8 (3532) 98-70-10, general@admin.orenburg.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 10,
        "col3": "город Орск:",
        "col4": " Оренбургская область, г. Орск, проспект Ленина, 29, 8 (3537) 25-30-90, uprava@orsk-adm.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 11,
        "col3": "Соль-Илецкий муниципальный округ",
        "col4": ": Оренбургская область, г.Соль-Илецк, ул. Карла Маркса, 6, 8 (35336) 2-32-32, si@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 12,
        "col3": "Сорочинский муниципальный округ:",
        "col4": " г. Сорочинск, ул.Советская, 1, 8 (35346) 4-11-51, admsor@esoo.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 13,
        "col3": " Ясненский муниципальный округ: ",
        "col4": "Оренбургская область, г. Ясный, ул. Ленина, 17, 8 (35368) 2-13-00, ys@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 14,
        "col3": "Адамовский район:",
        "col4": " Оренбургская область, Адамовский район, п.Адамовка, ул. Советская, 81, 8 (35365) 2-13-38, ad@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 15,
        "col3": "Акбулакский район:",
        "col4": " Оренбургская область, п. Акбулак, ул. Комсомольская, 15, 8 (35335) 2-11-40, ak@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 16,
        "col3": "Александровский район: ",
        "col4": "Оренбургская область, р-н Александровский, с. Александровка, ул. Мичурина, 49, 8 (35359) 2-11-02, al@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 17,
        "col3": "Асекеевский район: ",
        "col4": "Оренбургская область, с. Асекеево, ул. Чапаева, 28, 8 (35351) 2-00-83,  as@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 18,
        "col3": "Беляевский район:",
        "col4": " Оренбургская область, Беляевский район, с.Беляевка, ул. Советская, 46Б, 8 (35334) 2-17-25, be@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 19,
        "col3": "Бугурусланский район: ",
        "col4": "Оренбургская область, г. Бугуруслан, ул.Московская, 52а, 8 (35352) 2-39-16, bu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 20,
        "col3": "Бузулукский район: ",
        "col4": "Оренбургская область, г. Бузулук, ул. Ленина, 10, 8 (35342) 7-42-00, bz@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 21,
        "col3": "Грачевский район:",
        "col4": " Оренбургская область, с. Грачёвка, ул.Майская, 22, 8 (35344) 2-10-60, ge@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 22,
        "col3": " Домбаровский район: ",
        "col4": "Оренбургская область, Домбаровский район, п. Домбаровский, ул. 40 лет Октября, 20, 8 (35367) 2-24-52, dm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 23,
        "col3": "Илекский район: ",
        "col4": "Оренбургская область, Илекский район, с. Илек, ул. Октябрьская, 23, 8 (35337) 2-14-64, il@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 24,
        "col3": " Кваркенский район: ",
        "col4": "Оренбургская область, Кваркенский район, с. Кваркено, ул. 1-я Целинная, 18, 8 (35364) 2-17-63, kv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 25,
        "col3": " Красногвардейский район: ",
        "col4": "Оренбургская область, Красногвардейский район, с.Плешаново, ул.Мира, 5, 8 (35345) 3-14-44, 3-00-45, ko@mail.orb.ru"
    },
    {
        "col1": "Оренбургская область",
        "col2": 26,
        "col3": "Курманаевский район: ",
        "col4": "Оренбургская область, с. Курманаевка, пл. имени В. И.Ленина, 1, 8 (35341) 2-13-91, km@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 27,
        "col3": " Матвеевский район:",
        "col4": " Оренбургская обл., с. Матвеевка, ул. Комсомольская, 18, 8 (35356) 2-10-66, ma@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 28,
        "col3": "Новоорский район:",
        "col4": " Оренбургская  область, Новоорский район, п. Новоорск, ул. Рабочая, 1, 8 (35363) 7-70-01, 7-70-02, no@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 29,
        "col3": " Новосергиевский район: ",
        "col4": "Оренбургская область, п. Новосергиевка, ул. Краснопартизанская, 20, 8 (35339) 2-39-39, ns@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 30,
        "col3": " Октябрьский район: ",
        "col4": "Оренбургская область, с. Октябрьское, ул. Луначарского, 45, 8 (35330) 2-14-34, ok@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 31,
        "col3": " Оренбургский район:",
        "col4": " Оренбургская область, г. Оренбург, ул.Ст. Разина, 211, 8 (3532) 56-12-21, 77-28-91, or@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 32,
        "col3": " Первомайский район: ",
        "col4": "Оренбургская область, Первомайский район, п. Первомайский, ул. Советская, 33а, 8 (35348) 4-13-40, 4-13-42, pm@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 33,
        "col3": "Переволоцкий район:",
        "col4": " Оренбургская область, п. Переволоцкий, ул. Ленинская, 76, 8 (35338) 32-2-79, pr@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 34,
        "col3": "Пономаревский район: ",
        "col4": "Оренбургская область, Пономарёвский район, с. Пономарёвка, ул. Советская, 32, 8 (35357) 21-4-50, 21-3-34, pn@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 35,
        "col3": " Сакмарский район: ",
        "col4": "Оренбургская область, с. Сакмара, ул. Советская, 25, 8 (35331) 2-11-30, 2-17-77, sk@mail.orb.ru,"
    },
    {
        "col1": "Оренбургская область",
        "col2": 36,
        "col3": "Саракташский район:",
        "col4": " Оренбургская область, п. Саракташ, ул.Вокзальная, 12, 8 (35333) 6-02-50, sa@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 37,
        "col3": " Светлинский район:",
        "col4": " Оренбургская обл., Светлинский р- н, п. Светлый, ул. Советская, 22, 8 (35366) 2-14-55, 2-13-56, sv@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 38,
        "col3": " Северный район:",
        "col4": " Оренбургская область, с. Северное, ул. Советская, 24, 8 (35354) 2-17-75, 2-12-00, se@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 39,
        "col3": " Ташлинский район: ",
        "col4": "Оренбургская область, с. Ташла, ул. Довженко, 46, 8 (35347) 2-14-82, 2-13-09, tl@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 40,
        "col3": "Тоцкий район:",
        "col4": " Оренбургская область, с. Тоцкое, ул. Красная Площадь, 1, 8 (35349) 2-14-34, to@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 41,
        "col3": "Тюльганский район:",
        "col4": " Оренбургская область, Тюльганский район, п.Тюльган, ул. Ленина, 23, 8 (35332) 2-11-34, tu@mail.orb.ru."
    },
    {
        "col1": "Оренбургская область",
        "col2": 42,
        "col3": "Шарлыкский район:",
        "col4": " Оренбургская область, с. Шарлык, ул.Советская, 40, 8 (35358) 2-14-50, sl@mail.orb.ru."
    },
    {
        "col1": "Орловская область",
        "col2": 1,
        "col3": "Администрация города Орла",
        "col4": "302028, г. Орёл, ул. Пролетарская Гора, д. 1,                  8 (4862) 43-33-12, info@orel-adm.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 2,
        "col3": "Администрация города Ливны",
        "col4": "303850, Орловская область, г. Ливны, ул. Ленина, д. 7,                      8 (48677)-7-19-20, livny@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 3,
        "col3": "Администрация города Мценска",
        "col4": "303030, Орловская область, г. Мценск, пл.Ленина, д.1,                     8 (48646) 2-17-70, mcensk@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 4,
        "col3": "Администрация Болховского района",
        "col4": "303140, Орловская область, г. Болхов, ул. Ленина, 2а, 8 (48640) 2-43-41, bolhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 5,
        "col3": "Администрация Верховского района",
        "col4": "303720, Орловская область, п.Верховье,  ул. 7 Ноября, д. 6, 8 (48676) 2-30-40, verhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 6,
        "col3": "Администрация Глазуновского района",
        "col4": "303340, Орловская область,\n п. Глазуновка, ул. Ленина, д. 120, 8 (48675) 2-12-09, glazunr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 7,
        "col3": "Администрация Дмитровского района",
        "col4": "303240, Орловская область, г.Дмитровск, ул. Советская. 84-а,  8 (48649) 2-13-52, dmitr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 8,
        "col3": "Администрация Должанского района",
        "col4": "303760, Орловская область, пгт.Долгое, ул.Октябрьская д.6, 8 (48672) 2-11-83, dolzhr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 9,
        "col3": "Администрация Залегощенского района",
        "col4": "303560, Орловская область, пгт Залегощь, ул. М. Горького, д. 20,  8 (48648) 2-11-49, zalegr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 10,
        "col3": "Администрация Знаменского района",
        "col4": "303100, Орловская область, Знаменский район, с. Знаменское, ул. Ленина, д. 33-а, 8 (48662) 2-13-19, znamr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 11,
        "col3": "Администрация Колпнянского района",
        "col4": "303410, Орловская область, Колпнянский район, п.г.т. Колпна, ул. Пионерская, 2, 8 (48674) 2-17-71, kolpnr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 12,
        "col3": "Администрация Корсаковского района",
        "col4": "303580, Орловская область, Корсаковский район, с. Корсаково,ул. Советская, д.31, 8 (48667) 2-14-39, korsakr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 13,
        "col3": "Администрация  Краснозоренского района",
        "col4": "303650, Орловская область, Краснозоренский район, п. Красная Заря, ул. Ленина, 1,8 (48663) 2-14-91, krasnozr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 14,
        "col3": "Администрация  Кромского района",
        "col4": "303200, Орловская обл., Кромской р-н, пгт. Кромы, пл. Освобождения, д. 1,  8 (48643) 2-29-04, kromr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 15,
        "col3": "Администрация Ливенского района",
        "col4": "303857, Орловская область, г. Ливны,  ул. Курская, 14, 8 (48677) 2-16-94, livr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 16,
        "col3": "Администрация Малоархангельского района",
        "col4": "303370, Орловская область, г. Малоархангельск, ул. К.Маркса, 78, 8 (48679) 2-30-30, maloarhr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 17,
        "col3": "Администрация Мценского района",
        "col4": "303030, Орловская область\nг.Мценск пл.Ленина , д. 1, 8 (48646) 2-58-06, mcenskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 18,
        "col3": "Администрация Новодеревеньковского района",
        "col4": "303620, Орловская область, п. Хомутово, пл. Ленина, 1, 8 (48678) 2-13-50, novoderevr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 19,
        "col3": "Администрация Новосильского района",
        "col4": "303500, Орловская область, г. Новосиль, ул. Карла Маркса, д.16, 8 (48673) 2-12-52, admnovosil@yandex.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 20,
        "col3": "Администрация Орловского муниципального округа",
        "col4": "302040, г. Орёл, ул. Полярная, д. 12, 8 (4862) 41-52-67, orlr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 21,
        "col3": "Администрация Покровского района",
        "col4": "303170, Орловская область,\nпгт. Покровское, ул. 50 лет Октября, д.6, 8 (48664) 2-11-70, pokrovskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 22,
        "col3": "Администрация Свердловского района",
        "col4": "303320, Орловская область, пос. Змиёвка, ул. Ленина, д. 48, 8 (48645) 2-22-77, sverdlovskr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 23,
        "col3": "Администрация Сосковского района",
        "col4": "303980, Орловская область, Сосковский район, с. Сосково, ул. Советская, д. 29,  8 (48665) 2-11-28,  soskovr@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 24,
        "col3": "Администрация Троснянского района",
        "col4": "303450, Орловская область, Троснянский района, село Тросна, ул. Ленина, д. 4, 8 (48666) 2-15-59, trosnr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 25,
        "col3": "Администрация Урицкого района",
        "col4": "303900, Орловская область, п. Нарышкино, ул. Ленина, д. 104, 8 (48647) 2-04-42, uradmin@mail.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 26,
        "col3": "Администрация Хотынецкого  района",
        "col4": "303930, Орловская область, Хотынецкий район, пгт. Хотынец, ул. Ленина, д.40, 8 (48642)2-13-32, otynecr-adm@adm.orel.ru"
    },
    {
        "col1": "Орловская область",
        "col2": 27,
        "col3": "Администрация Шаблыкинского района",
        "col4": "303260, Орловская область, Шаблыкинский район, пгт. Шаблыкино, ул. Ленина, д. 21, 8 (48644) 2-13-79, shablr@adm.orel.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 1,
        "col3": "Администрация города Пензы",
        "col4": "город Пенза, площадь Маршала Жукова, 4. e-mail:goradm@sura.ru, 68-22-72"
    },
    {
        "col1": "Пензенская область",
        "col2": 2,
        "col3": "Администрация города Заречного Пензенской области",
        "col4": "Пензенская область, город Заречный, пр. 30-летия Победы, д. 27, e-mail: adm@zarechny.zato.ru, тел.: 8(8412) 652953"
    },
    {
        "col1": "Пензенская область",
        "col2": 3,
        "col3": "Администрация города Кузнецка",
        "col4": "Пензенская область, город Кузнецк, ул. Ленина, д.191,e-mail:kuzg_adm@sura.ru, 884157(30694)"
    },
    {
        "col1": "Пензенская область",
        "col2": 4,
        "col3": "Администрация Башмаковского района Пензенской области",
        "col4": "Пензенская область, р.п. Башмаково, ул. Советская, 17, baschm_adm@sura.ru 884143(4-13-40)"
    },
    {
        "col1": "Пензенская область",
        "col2": 5,
        "col3": "Администрация Бековского района Пензенской области",
        "col4": "Пензенская область, р.п. Беково, ул. Первомайская, д. 3а, E-mail: bekov_adm@sura.ru, тел.(8-841-41) 2-15-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 6,
        "col3": "Администрация Белинского района Пензенской области",
        "col4": "Пензенская область, г. Белинский, площадь Комсомольская, д. 19, e-mail: belinsk_adm@mail.ru,тел.: 8(84153)21240"
    },
    {
        "col1": "Пензенская область",
        "col2": 7,
        "col3": "Администрация Бессоновского района",
        "col4": "Пензенская область, Бессоновский район, с. Бессоновка, ул. Коммунистическая, 2"
    },
    {
        "col1": "Пензенская область",
        "col2": 8,
        "col3": "Администрация Вадинского района Пензенской области",
        "col4": "Пензенская область, Вадинский район, с. Вадинск, ул. Площадь Ленина, д.16, vadinsk_adm@sura.ru.8(84142)21169"
    },
    {
        "col1": "Пензенская область",
        "col2": 9,
        "col3": "Администрация Городищенского района",
        "col4": "г.Городище,ул.Комсомольская,д.40,gorodis_adm@sura.ru,88415832260"
    },
    {
        "col1": "Пензенская область",
        "col2": 10,
        "col3": "Администрация Земетчинского района Пензенской области",
        "col4": "Пензенская область, р.п. Земетчино, пл. Победы, 4, zemet_adm@sura.ru 84155(21350)"
    },
    {
        "col1": "Пензенская область",
        "col2": 11,
        "col3": "Администрация Иссинского района Пензенской области",
        "col4": "Администрация Иссинского района Пензенской области , р.п. Исса, ул Черокманова 21,issa_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 12,
        "col3": "Администрация Каменского района Пензенской области",
        "col4": "Пензенская область, Каменский район г. Каменка, ул. Суворова, 33, E-mail: kamr_adm@sura.ru, тел.: 8(84156) 2-12-40)"
    },
    {
        "col1": "Пензенская область",
        "col2": 13,
        "col3": "Администрация Камешкирского района",
        "col4": "442450 Пензенская область, Камешкирский район, с. Русский Камешкир, ул. Радищева, д. 15/ kamesh_adm@sura_ru/ 8(84145) 21478"
    },
    {
        "col1": "Пензенская область",
        "col2": 14,
        "col3": "Администрация Колышлейского района Пензенской области",
        "col4": "Пензенская область, Колышлейский район, р.п. Колышлей, ул. Московская, д.20, E-mail: kolish_adm@sura.ru, тел.: (84146) 2-13-40"
    },
    {
        "col1": "Пензенская область",
        "col2": 15,
        "col3": "Администрация Кузнецкого района Пензенской области",
        "col4": "Пензенская область, г. Кузнецк, ул. Комсомольская, д. 53, kuzr_adm@sura.ru, тел.: 8(84157)3-06-77"
    },
    {
        "col1": "Пензенская область",
        "col2": 16,
        "col3": "Администрация Лопатинского района",
        "col4": "lopatin_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 17,
        "col3": "Администрация Лунинского района Пензенской области",
        "col4": "Пензенская область Лунинский район р.п. Лунино ул. Юбилейная д. 39, (841-61)3-13-90, luninadm@yandex.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 18,
        "col3": "Администрация Малосердобинского района",
        "col4": "442800, Пензенская область, Малосердобинский район, село Малая Сердоба, Ленинская ул., д.42"
    },
    {
        "col1": "Пензенская область",
        "col2": 19,
        "col3": "Администрация Мокшанского района Пензенской области",
        "col4": "442370, Пензенская область, р.п. Мокшан, ул. Поцелуева, д. 1/mokshan_adm@sura.ru/8(84150)2-75-59"
    },
    {
        "col1": "Пензенская область",
        "col2": 20,
        "col3": "Управление социальной защиты населения и охраны труда администрации Наровчатского района",
        "col4": "с. Наровчат, ул. М.Горького 38 А, soz_narov@mail.ru, 88416321485"
    },
    {
        "col1": "Пензенская область",
        "col2": 21,
        "col3": "Администрация Неверкинского района",
        "col4": "Пензенская область Неверкинский район с. Неверкино ул. Куйбышева, 9, эл.почта: neverk_adm@sura.ru, т. 8(84164)20249"
    },
    {
        "col1": "Пензенская область",
        "col2": 22,
        "col3": "Администрация Нижнеломовского района Пензенской области",
        "col4": "Пензенская область, Нижнеломовский район, ул. Розы Люксембург, д.4, E-mail: n.lomovadm@yandex.ru, тел.: (841-54)4-47-68"
    },
    {
        "col1": "Пензенская область",
        "col2": 23,
        "col3": "Администрация Никольского района",
        "col4": "442680, Пензенская область, Никольский район, г.Никольск, ул.Московская, д.2, nikadm@nikrn.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 24,
        "col3": "Администрация Пачелмского района",
        "col4": "Пензенская область, р.п.Пачелма, ул.Драгунова, д8,pachelm_adm@sura.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 25,
        "col3": "Управление социальной защиты населения администрации Пензенского района Пензенской области",
        "col4": "440400, Пензенская область, Пензенский район, с. Кондоль, ул. Осипова, д.46, тел.:(884147)55036, uszn_kondol@mail.ru"
    },
    {
        "col1": "Пензенская область",
        "col2": 26,
        "col3": "Администрация Сердобского района Пензенской области",
        "col4": "Пензенская область, Сердобский район, г.Сердобск, ул.Ленина, д.90, E-mail: serdr_adm@sura.ru, тел.: (84167) 2-35-42"
    },
    {
        "col1": "Пензенская область",
        "col2": 27,
        "col3": "Администрация Сосновоборского района Пензенской области",
        "col4": "(84168)2-13-40, Пензенская область, р.п. Сосновоборск, ул. Ленина, д. 67, sosnov_adm@sura.ru \n"
    },
    {
        "col1": "Пензенская область",
        "col2": 28,
        "col3": "Администрация Спасского района Пензенской области",
        "col4": "Пензенская область, г.Спасск, Советская площадь, д.36 spassk-ray-adm@yandex.ru 8-84151-31231"
    },
    {
        "col1": "Пензенская область",
        "col2": 29,
        "col3": "Администрация Тамалинского района",
        "col4": "р.п.Тамала ул Советская дом 20 tamala_adm@sura.ru 88416921430"
    },
    {
        "col1": "Пензенская область",
        "col2": 30,
        "col3": "Администрация Шемышейского района Пензенской области",
        "col4": "442430 Пензенская область, Шемышейский район, р.п. Шемышейка, ул. Ленина, д.47.,т.: (8-841-59) 2-17-80. Е-mail: shem_adm@sura.ru"
    },
    {
        "col1": "Пермский край",
        "col2": 1,
        "col3": "1. администрация Александровского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 20 а, г. Александровск, Александровский МО,Пермский край, 618320 Email: administration@aleksandrovsk.permkrai.ru   тел.: 8 (342) 743 53 77"
    },
    {
        "col1": "Пермский край",
        "col2": 2,
        "col3": "2. администрация Бардымского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, с. Барда, Бардымский МО, Пермский край, 618150  Admin-barda@rambler.ru  8 (342) 922 06 10"
    },
    {
        "col1": "Пермский край",
        "col2": 3,
        "col3": "3. администрация Берёзовского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 33, с. Березовка, Берёзовский МО, Пермский край, 617565 \nadministration@berezovka.permkrai.ru \n 8 (342) 513 11 33"
    },
    {
        "col1": "Пермский край",
        "col2": 4,
        "col3": "4. администрация Большесосновского муниципального округа Пермского края;",
        "col4": "ул. Ворошилова, д. 2, с. Большая Соснова, Большесосновский МО, Пермский край, 617072  administration@bsn.permkrai.ru  тел.: 8 (342) 572 73 73"
    },
    {
        "col1": "Пермский край",
        "col2": 5,
        "col3": "5. администрация Юсьвинского муниципального округа Пермского края;",
        "col4": "ул. Красноармейская, д. 14, с. Юсьва, Юсьвинский МО, Пермский край, 619170  \nadministration@yusva.permkrai.ru\nтел.: 8 (342) 462 71 35"
    },
    {
        "col1": "Пермский край",
        "col2": 6,
        "col3": "6. администрация Юрлинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 15, с. Юрла, Юрлинский МО, Пермский край, 619200\nadministration@yurla.permkrai.ru\nтел.: 8 (342) 942 11 72"
    },
    {
        "col1": "Пермский край",
        "col2": 7,
        "col3": "7. администрация Гайнского муниципального округа Пермского края;",
        "col4": "ул. Кашина, д. 41, пос. Гайны, Гайнский МО, Пермский край, 619650\nGainy_adm@mail.ru\nтел.: 8 (342) 452 11 98"
    },
    {
        "col1": "Пермский край",
        "col2": 8,
        "col3": "8. администрация Губахинского муниципального округа Пермского края;",
        "col4": "ул. Никонова, д. 44, г. Губаха, Губахинский МО, Пермский край, 618250\nadmin-gubaha@mail.ru\n8 (342) 484 19 59, 8 (342) 484 04 80\n"
    },
    {
        "col1": "Пермский край",
        "col2": 9,
        "col3": "9. администрация Еловского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 32, с. Елово, Еловский МО, Пермский край, 618170\nadministration@elovo.permkrai.ru\n 8 (342) 963 11 07"
    },
    {
        "col1": "Пермский край",
        "col2": 10,
        "col3": "10. администрация Карагайского муниципального округа;",
        "col4": "ул. Ленина, д. 5, с. Карагай, Карагайский МО, Пермский край, 617210\nadministration@karagay.permkrai.ru\n8 (342) 973 16 35"
    },
    {
        "col1": "Пермский край",
        "col2": 11,
        "col3": "11. администрация Кишертского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 40, с. Усть-Кишерть, Кишертский МО, Пермский край, 617593\nadministration@kishert.permkrai.ru\n8 (342) 522 14 35, 8 (342) 522 14 03"
    },
    {
        "col1": "Пермский край",
        "col2": 12,
        "col3": "12. администрация Косинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Коса, Косинский МО, Пермский край, 619430\nadministration@kosa.permkrai.ru\n8 (342) 982 12 32"
    },
    {
        "col1": "Пермский край",
        "col2": 13,
        "col3": "13. администрация Кочевского муниципального округа Пермского края;",
        "col4": "ул. Калинина, д. 5, с. Кочево, Кочевский МО, Пермский край, 619317\nadmKochevo@list.ru\n8 (342) 939 12 86"
    },
    {
        "col1": "Пермский край",
        "col2": 14,
        "col3": "14. администрация Кудымкарского муниципального округа Пермского края;",
        "col4": "ул. Лихачева, д. 54, г. Кудымкар, Кудымкарский МО, Пермский край, 618994\nadministration@kudymkar.permkrai.ru\n8 (342) 604 52 43"
    },
    {
        "col1": "Пермский край",
        "col2": 15,
        "col3": "15. администрация Куединского муниципального округа Пермского края;",
        "col4": " ул. Гагарина, д. 25, пос. Куеда, Куединский МО, Пермский край, 617700\nadministration@kueda.permkrai.ru\n8 (342) 623 55 65, 8 (342) 623 55 68"
    },
    {
        "col1": "Пермский край",
        "col2": 16,
        "col3": "16. администрация Кунгурского муниципального округа Пермского края;",
        "col4": " ул. Советская, д. 26, г. Кунгур,\nКунгурский МО, Пермский край, 617470\nkungrad59@mail.ru\n8 (342) 712 42 83"
    },
    {
        "col1": "Пермский край",
        "col2": 17,
        "col3": "17. администрация Октябрьского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 57, раб.пос. Октябрьский, Октябрьский МО, Пермский край, 617860\nadministration@oktyabrskiy.permkrai.ru\n8 (342) 662 19 78, 8 (342) 662 14 75"
    },
    {
        "col1": "Пермский край",
        "col2": 18,
        "col3": "18. администрация Ординского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 12, с. Орда, Ординский МО, Пермский край, 617500\nadministration@orda.permkrai.ru\n8 (342) 582 01 49"
    },
    {
        "col1": "Пермский край",
        "col2": 19,
        "col3": "19. администрация Осинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 25, г. Оса, Осинский МО, Пермский край, 61812\nadministration@osa.permkrai.ru\n8 (342) 914 65 65"
    },
    {
        "col1": "Пермский край",
        "col2": 20,
        "col3": "20. администрация Оханского муниципального округа Пермского края;",
        "col4": "ул. Куйбышева, д. 35, г. Оханск, Оханский МО, Пермский край, 618100\nadministration@okhansk.permkrai.ru\n8 (342) 793 13 51"
    },
    {
        "col1": "Пермский край",
        "col2": 21,
        "col3": "21. администрация Очерского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 41, г. Очер, Очерский МО, Пермский край, 617140\nadministration@oсher.permkrai.ru\n8 (342) 783 10 90"
    },
    {
        "col1": "Пермский край",
        "col2": 22,
        "col3": "22. администрация Пермского муниципального округа Пермского края;",
        "col4": "ул. Верхне-Муллинская, д. 71, г. Пермь, Пермский край, 614065\nadministration@permsky.permkrai.ru\n8 (342) 296 21 51"
    },
    {
        "col1": "Пермский край",
        "col2": 23,
        "col3": "23. администрация Сивинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 66, с. Сива, Сивинский МО, Пермский край, 617240\nadministration@siva.permkrai.ru\n8 (342) 772 13 00"
    },
    {
        "col1": "Пермский край",
        "col2": 24,
        "col3": "24. администрация Уинского муниципального округа Пермского края;",
        "col4": "ул. Октябрьская, д. 1, с. Уинское,\nУинский МО, Пермский край, 617520\nEmail: administration@uinsk.permkrai.ru\nтел.: 8 (342) 592 31 97\n"
    },
    {
        "col1": "Пермский край",
        "col2": 25,
        "col3": "25. администрация Частинского муниципального округа Пермского края;",
        "col4": "ул. Ленина, д. 40, с. Частые, Частинский МО, Пермский край, 617170\nEmail: administration@chastye.permkrai.ru.\nтел.: 8 (342) 682 14 34"
    },
    {
        "col1": "Пермский край",
        "col2": 26,
        "col3": "26. администрация Чусовского муниципального округа Пермского края;",
        "col4": "ул. Сивкова, д. 8б, г. Чусовой, Чусовского МО, Пермский край, 618204\nEmail: administration@chusovoy.permkrai.ru.\nтел.: 8 (342) 563 69 10\n"
    },
    {
        "col1": "Пермский край",
        "col2": 27,
        "col3": "27. администрация Кизеловского муниципального округа Пермского края;",
        "col4": "ул. Луначарского, д. 19, г. Кизел,\nКизеловский МО, Пермский край, 618350\nEmail: administration@kizel.permkrai.ru.\nтел.: 8 (342) 554 46 52\n"
    },
    {
        "col1": "Пермский край",
        "col2": 28,
        "col3": "28. администрация Добрянского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 14, г. Добрянка,\nДобрянский МО, Пермский край, 618740\nadministration@dobryanka.permkrai.ru 8 (342) 652 93 68\n"
    },
    {
        "col1": "Пермский край",
        "col2": 29,
        "col3": "29. администрация городского округа ЗАТО «Звездный»;",
        "col4": "ул. Ленина, д. 11А, пос. Звёздный,\nПермский край, 614575\nEmail: administration@zvezdny.permkrai.ru.\nтел.: 8 (342) 297 06 37\n"
    },
    {
        "col1": "Пермский край",
        "col2": 30,
        "col3": "30. администрация Красновишерского муниципального округа Пермского края;",
        "col4": "ул. Джержинского, д. 6а, г. Красновишерск, Красновишерский МО, Пермский край, 618592\nadministration@krasnovishersk.permkrai.ru.\n8 (342) 433 03 27\n"
    },
    {
        "col1": "Пермский край",
        "col2": 31,
        "col3": "31. администрация Краснокамского муниципального округа Пермского края;",
        "col4": "пр-т Маяковского, д. 11, г. Краснокамск, Краснокамский МО, Пермский край, 617060\nEmail: administration@ krasnokamsk.permkrai.ru\nтел.: 8 (342) 734 44 54\n"
    },
    {
        "col1": "Пермский край",
        "col2": 32,
        "col3": "32. администрация Лысьвенского городского округа Пермского края;",
        "col4": "пр-т Победы, д. 38, г. Лысьва, Лысьвенский МО, Пермский край, 618900\nEmail: administration@lysva.permkrai.ru.\nтел.: 8 (342) 496 07 06\n"
    },
    {
        "col1": "Пермский край",
        "col2": 33,
        "col3": "33. администрация муниципального округа «город Березники» Пермского края;",
        "col4": "пл. Советская, д. 1, г. Березники,\nПермский край, 618417\nEmail: administration@berezniki.permkrai.ru\nТелефон: 8 (342) 426 21 15\n"
    },
    {
        "col1": "Пермский край",
        "col2": 34,
        "col3": "34. администрация Суксунского муниципального округа Пермского края;",
        "col4": "ул. К. Маркса, д. 4, пос. Суксун,\nСуксунский МО, Пермский край, 617560\nadministration@suksun.permkrai.ru.\n8 (342) 753 15 75, 8 (342) 753 12 65\n"
    },
    {
        "col1": "Пермский край",
        "col2": 35,
        "col3": "35. администрация Нытвенского муниципального округа Пермского края;",
        "col4": "ул. Либкнехта, д. 2а, г. Нытва,\nНытвенский МО, Пермский край, 617000\nadministration@nytva.permkrai.ru.\nтел.: 8 (342) 723 08 56\n"
    },
    {
        "col1": "Пермский край",
        "col2": 36,
        "col3": "36. администрация Пермского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 23, г. Пермь,\nПермский ГО, Пермский край, 614000\nEmail: main@gorodperm.ru.\nтел.: (342) 217 33 17\n"
    },
    {
        "col1": "Пермский край",
        "col2": 37,
        "col3": "37. администрация Соликамского муниципального округа Пермского края;",
        "col4": "ул. 20 лет Победы, д. 106, г. Соликамск,\nСоликамский МО, Пермский край, 618554\nEmail: administration@solikamsk.permkrai.ru.\nтел.: 8 (342) 537 03 05\n"
    },
    {
        "col1": "Пермский край",
        "col2": 38,
        "col3": "38. администрация Чайковского городского округа Пермского края;",
        "col4": "ул. Ленина, д. 37, г. Чайковский,\nЧайковский ГО, Пермский край, 617760\nadministration@chaykovsky.permkrai.ru.\nтел.: 8 (342) 413 25 07\n"
    },
    {
        "col1": "Пермский край",
        "col2": 39,
        "col3": "39. администрация Чердынского муниципального округа Пермского края;",
        "col4": "ул. Соборная, д. 18а, г. Чердынь,\nЧердынский МО, Пермский край, 618601\nadministration@cherdyn.permkrai.ru.\nтел.: 8 (342) 402 82 01\n"
    },
    {
        "col1": "Пермский край",
        "col2": 40,
        "col3": "40. администрация Чернушинского муниципального округа Пермского края;",
        "col4": "ул. Юбилейная, д. 9, г. Чернушка, Чернушинский МО, Пермский край, 617830\nadministration@chernushka.permkrai.ru.\nтел.: 8 (342) 614 43 95\n"
    },
    {
        "col1": "Пермский край",
        "col2": 41,
        "col3": "41. администрация Ильинского муниципального округа Пермского края;",
        "col4": "ул. Советская, д. 7, пос. Ильинский,\nИльинский ГО, Пермский край, 617020\nadministration@ilinsky.permkrai.ru.\nтел.: 8 (342) 769 16 08\n"
    },
    {
        "col1": "Пермский край",
        "col2": 42,
        "col3": "42. администрация Горнозаводского муниципального округа Пермского края;",
        "col4": "ул. Кирова, д. 65, г. Горнозаводск, Горнозаводский МО, Пермский край, 618820\nadministration@gornozavodsk.permkrai.ru.\nтел.: 8 (342) 694 14 55\n"
    },
    {
        "col1": "Пермский край",
        "col2": 43,
        "col3": "43. администрация Верещагинского муниципального округа Пермского края.",
        "col4": "ул. Ленина, д. 26, г. Верещагино, Верещагинский МО, Пермский край, 617120\nadministration@vereschagino.permkrai.ru.\nтел.: 8 (342) 543 33 50, 8 (342) 543 33 55\n"
    },
    {
        "col1": "Приморский край",
        "col2": 1,
        "col3": "Отдел по учету и распределению жилья управления жизнеобеспечения администрации г. Арсеньева",
        "col4": "692337, Приморский край, г. Арсеньев, ул. Ленинская, 8 (каб.213) zhilotdel@ars.town 8(42361) 3-75-538;\n(42361) 3-75-54"
    },
    {
        "col1": "Приморский край",
        "col2": 2,
        "col3": "Отдел учета и распределения жилья администрации Артемовского городского округа",
        "col4": "692760, Приморский край, г. Артём, ул. Кирова, дом 48 / zhilotdel_artem@mail.ru / тел. 8 42337 42466"
    },
    {
        "col1": "Приморский край",
        "col2": 3,
        "col3": "управление имущественных отношений администрации городского округа Большой Камень",
        "col4": "692806, Приморский край, г. Большой Камень, ул. Карла Маркса, д. 4,uio@bkadmin.ru,   8(42335)5-16-66"
    },
    {
        "col1": "Приморский край",
        "col2": 4,
        "col3": "Администрации города Владивостока  (Управление по учету и распределению жилой площади)",
        "col4": "г. Владивосток,   Океанский проспект, д. 20. (прием документов по адресу: г. Владивосток,  ул. Ильичева, д. 15, каб. 37, тел.  8(423)261-44-16,      home@vlc.ru  )"
    },
    {
        "col1": "Приморский край",
        "col2": 5,
        "col3": "Управление образования администрации Дальнегорского городского округа",
        "col4": "3-23-42 dalnegorsk-uo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 6,
        "col3": "МКУ \"Управление ЖКХ Дальнереченского городского округа\"",
        "col4": "г. Дальнереченск, ул. Победы, д.13, zizneob@dalnerokrug.ru тел.842356 25-1-82"
    },
    {
        "col1": "Приморский край",
        "col2": 7,
        "col3": "управление имущественных отношений администрации Лесозаводского муниципального округа",
        "col4": "г. Лесозаводск, ул. Будника, д. 119, каб. 301kuialgo@mo-lgo.ru  8(42355)29050"
    },
    {
        "col1": "Приморский край",
        "col2": 8,
        "col3": "Управление имуществом администрации Находкинского городского округа",
        "col4": "г. Находка, ул. Школьная, д.18, каб.314, kumi@nakhodka-city.ru, тел. 8(4236)69-21-96, 69-20-84"
    },
    {
        "col1": "Приморский край",
        "col2": 9,
        "col3": "Администрация муницапального  округа город Партизанск Приморского края",
        "col4": "692864, Приморский край,  г. Партизанск, ул. Ленинская, д. 26 \"А\", pgo@partizansk.org.ru 824236360742"
    },
    {
        "col1": "Приморский край",
        "col2": 10,
        "col3": "Администрация городского округа Спасск-Дальний                                        Управление ЖКХ ",
        "col4": "г.Спасск-Дальний ул.Борисова, д.17 gkh@spasskd.ru 84235220993"
    },
    {
        "col1": "Приморский край",
        "col2": 11,
        "col3": "Администрация Уссурийского городского округа Приморского края",
        "col4": "692519 Приморский край,г. Уссурийск, ул. Ленина,   д. 101,   тел: 8(4234)32-26-31, эл. адрес:admin1@adm-ussuriisk.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 21,
        "col3": "Отдел опеки и попечительства администрации городского округа ЗАТО Фокино",
        "col4": "692880, г. Фокино, \nул. Постникова, д. 17\nТел.: 8(42339) 2-43-55"
    },
    {
        "col1": "Приморский край",
        "col2": 13,
        "col3": "Администрация Анучинского муниципального округа",
        "col4": "692300, Приморский край, Анучинский район, с.Анучино, ул. Лазо, д.6.anuchinsky@mail.ru,   Тел.: 8(42362) 91-6-78"
    },
    {
        "col1": "Приморский край",
        "col2": 14,
        "col3": "Отдел жилищно — коммунального хозяйства администрации Дальнереченского муниципального района",
        "col4": "692132, г. Дальнереченск, ул. Ленина,90/stroitelstvo_dmr@mail.ru/   8 42(356) 25853"
    },
    {
        "col1": "Приморский край",
        "col2": 15,
        "col3": "Администрация Кавалеровского муниципального округа отдел опеки и попечительства Управления образовани",
        "col4": "Приморский край, Кавалеровский район, п. Кавалерово, ул. Арсеньева, д. 111 тел. 8(42375)9-11-10 opeka1700@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 16,
        "col3": "Администрация Кировского муниципального района",
        "col4": "692091, Приморский край, Кировский район,                   пгт. Кировский,                                    ул. Советская, д. 57, kirovsky_mr@mail.ru,                  8-42354-21391 специалист Маковеев Александр Борисович"
    },
    {
        "col1": "Приморский край",
        "col2": 17,
        "col3": "Администрация Красноармейского МО",
        "col4": "692171, Приморский край, Красноармейский район, с. Новопокровка, ул. Советская, 74, opeka19@mail.ru, 8(42359)22559"
    },
    {
        "col1": "Приморский край",
        "col2": 18,
        "col3": "Отдел опеки и попечительства администрации Лазовского муниципального округа",
        "col4": "692980 Приморский край, Лазовский район, с. Лазо, ул. Некрасовская, 31, 8(42377)20517, opekalazo@yandex.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 19,
        "col3": "Главный специалист 1 разряда по обеспечению детей-сирот, детей, оставшихся без попечения родителей лиц, из числа детей-сирот и детей, оставшихся без попечения родителей жилыми помещениями администрации Михайловского муниципального округа.",
        "col4": "Зелинская Наталья Александровна, тел.8(42346)2-58-46 ,   692651,с. Михайловка, ул.Красноармейская,д.16, каб.212,  Е-mail: mihadmsirota@mikhprim.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 20,
        "col3": "Отдел муниципального имущества администрации Надеждинского муниципального района",
        "col4": "692481, Приморский край Надеждинский район,            с. Вольно - Надеждинское   ул. Пушкина, д. 59А          тел.: 8 (42334) 2-00-54          эл. почта: umi_anmr@mail.ry"
    },
    {
        "col1": "Приморский край",
        "col2": 22,
        "col3": "Упавление ЖКХ и экономического и градостроительного развития",
        "col4": "692460 пгт Ольга, ул. Ленинская, д.8  otdel_jkh_olga@mail.ru 8 (42376)91205"
    },
    {
        "col1": "Приморский край",
        "col2": 23,
        "col3": "Администрация Партизанского муниципального округа ",
        "col4": "692962, Приморский край, Партизанский район, с.Владимиро-Александровское, ул.Комсомольская 45а, 84236521825, opeka_pmr@rambler.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 24,
        "col3": "Администрация Пограничного МО, отдел имущественных отношений и землепользования",
        "col4": "692582 п. Пограничный Приморского края, ул. Советская, 31.  84234522774. pogr_property@mail.ru"
    },
    {
        "col1": "Приморский край",
        "col2": 25,
        "col3": "Приобретение ж/п - отдел имущественных и земельных отношений администрации Пожарского муниципального округа; распределение - отдел опеки и попечительства администрации Пожарского муниципального округа",
        "col4": "692001, Приморский край, Пожарский район,               пгт Лучегорск, общественный центр, д. 1,                      umi_apmo@bk.ru                     8 (42357) 33 9 01              692001, Приморский край, Пожарский район,               пгт Лучегорск, 4 микрорайон, д. 2, каб. 518    pozopeka7000@mail.ru              8 (42357) 33 9 71"
    },
    {
        "col1": "Приморский край",
        "col2": 26,
        "col3": "Отдел по реализации отдельных государственных полномочий по обеспечению жилыми помещениями детей-сирот, детей, оставшихся без попечения родителей, лиц из числа  детей-сирот и детей, оставшихся без попечения родителей, администрации Спасского муниципального района",
        "col4": "692245 Приморский край, г. Спасск-Дальний, ул. Ленинская, 27, адрес электронной почты: spasskmr-opeka-dom@yandex.ru, тел. 8(42352) 3-45-67"
    },
    {
        "col1": "Приморский край",
        "col2": 27,
        "col3": "отдел земельных и имущественных отношений администрации Тернейского муниципального округа ",
        "col4": "отдел земельных и имущественных отношений, 692150 Приморский край Тернейский муниципальный округ пгт Терней, ул. Ивановская, д. 2 каб. 5,6; ozioatmr@yandex.ru; 8(42374)31300"
    },
    {
        "col1": "Приморский край",
        "col2": 28,
        "col3": "Администрация Ханкайского муниципального округа, Отдел социальной и молодежной политики",
        "col4": "Приморский край, Ханкайский район, с. Камень-Рыболов, ул. Кирова, д.8, 692684; ahmo@hankayski.ru, 8(42349)97293"
    },
    {
        "col1": "Приморский край",
        "col2": 29,
        "col3": "Управление жизнеобеспечение администрации Хасанского муниципального округа ПК",
        "col4": "Приморский край, Хасанский муниципальный округ, пгт Славянка, ул. Молодежная, д. 1, каб. 228.  hasan-zo@mail.ru   8(42331)48-3-57"
    },
    {
        "col1": "Приморский край",
        "col2": 30,
        "col3": "Администрация Хорольского муниципального округа Приморского края",
        "col4": "692254, Приморский край, Хорольский район,с.Хороль, ул.Ленинская, 51 / admhmr@khorol.ru/ +7 (42347) 2-24-01"
    },
    {
        "col1": "Приморский край",
        "col2": 31,
        "col3": "Администрация Черниговского МО",
        "col4": "692372, с. Черниговка, ул. Буденного, 23, opeka32@mail.ru, 8 (42351) 25-2-58"
    },
    {
        "col1": "Приморский край",
        "col2": 32,
        "col3": "Министерство труда и социальной политики Приморского края; 2. Администрация Чугуевского муниципального округа",
        "col4": "690091, г. Владивосток, ул. Пушкинская, д. 13, E-mail: sodef@primorsky.ru, Телефон: (423) 226-72-96; 2. 692623, Приморский край, Чугуевский район, с. Чугуевка, ул. 50 лет Октября, д. 193, e-mail chuguevskymo@mail.ru, телефон (42372) 22-3-03, тел./факс (42372) 22-6-01 "
    },
    {
        "col1": "Приморский край",
        "col2": 33,
        "col3": "Управление имущественных и земельных отношений Администрации Шкотовского муниципального округа",
        "col4": "г. Большой Камень, ул. Карла Маркса, 4. kymi@bk.ru. 8 42 335 5-00-30"
    },
    {
        "col1": "Приморский край",
        "col2": 34,
        "col3": "Администрация Яковлевского муниципального округа",
        "col4": "692361 Приморский край, Яковлевский район, с. Яковлевка, пер. Почтовый д. 5, tooipyakovlevka@mail.ru тел. 8(423711) 97441 "
    },
    {
        "col1": "Приморский край",
        "col2": 35,
        "col3": "Министерство имущественных и земельных и  отношений Приморского края ",
        "col4": "690033, Приморский край, г. Владивосток, ул. Бородинская, д.12 , land@primorsky.ru, тел. 8 (423) 236-21-52"
    },
    {
        "col1": "Псковская область",
        "col2": 1,
        "col3": "Комитет по строительству и жилищно-коммунальному хозяйству  Псковской области (главный распорядитель бюджетных средств, полномочия переданы органам местного самоуправления)",
        "col4": "180007, Псков, ул. Пароменская, д.21/33 (3 этаж) info@jkh.pskov.ru  (8112) 29-98-27, 29-09-47"
    },
    {
        "col1": "Псковская область",
        "col2": 2,
        "col3": "Муниципальное образование «Город Псков»",
        "col4": "180000, Псковская область, г. Псков, ул. Некрасова, д. 22 goradmin@pskovadmin.ru (8112) 29-00-00"
    },
    {
        "col1": "Псковская область",
        "col2": 3,
        "col3": "Муниципальное образование «Город Великие Луки»",
        "col4": "182113, Псковская область, г. Великие Луки, ул. Ленина д. 1\ngorduma@vluki.reg60.ru\n+7 (81153) 3-08-22"
    },
    {
        "col1": "Псковская область",
        "col2": 4,
        "col3": "Бежаницкий муниципальный округ Псковской области",
        "col4": "Псковская область, п. Бежаницы, ул.Комсомольская, д. 12\nem.ivanova@bezhanicy.reg60.ru\n(81141) 2-30-01\n"
    },
    {
        "col1": "Псковская область",
        "col2": 5,
        "col3": "Муниципальное образование «Великолукский район»",
        "col4": "Псковская область, г.Великие Луки, пр.Гагарина, д.6\nvlukirajon@reg60.ru\n8(81153)3-77-63\n"
    },
    {
        "col1": "Псковская область",
        "col2": 6,
        "col3": "Муниципальное образование «Гдовский  район»",
        "col4": "81600, Псковская область, г. Гдов, ул. К. Маркса, д. 39\ngdov@reg60.ru\n+7 (81131) 2-10-33\n"
    },
    {
        "col1": "Псковская область",
        "col2": 7,
        "col3": "Администрация Дедовичского района",
        "col4": "Псковская область, пос. Дедовичи, пл. Советов, д.6\ndedovichi@reg60.ru\n+7 (81136) 9-11-92; "
    },
    {
        "col1": "Псковская область",
        "col2": 8,
        "col3": "Муниципальное образование Дновский муниципальный округ»",
        "col4": "182670, Псковская область, г. Дно, ул. Карла Маркса д. 16\ndno@reg60.ru\n8 (81135) 25-7-50"
    },
    {
        "col1": "Псковская область",
        "col2": 9,
        "col3": "Муниципальное образование Красногородский муниципальный округ»",
        "col4": "Псковская область, г. Красногородск, ул. Советская, д. 6\nkrasnogorodsk@reg60.ru\n+7(81137)21148"
    },
    {
        "col1": "Псковская область",
        "col2": 10,
        "col3": "Администрация Куньинского района",
        "col4": "Псковская область, пгт. Кунья, ул.Советская, 27\ninfo@kunja.reg60.ru\n+7(81149)2-18-20\n"
    },
    {
        "col1": "Псковская область",
        "col2": 11,
        "col3": "Муниципальное образование Локнянский муниципальный округ",
        "col4": "182900, Псковская область, посёлок Локня, улица Первомайская, д. 31/8\nloknja@reg60.ru\n8(81139)21-0-91"
    },
    {
        "col1": "Псковская область",
        "col2": 12,
        "col3": "Невельский муниципальный округ Псковской области",
        "col4": "182500, Псковская область, г.Невель, пл.К.Маркса, д.1, каб.2\nnevel@reg60.ru\n+7 (81151) 2-31-14"
    },
    {
        "col1": "Псковская область",
        "col2": 13,
        "col3": "Новоржевский  муниципальный округ",
        "col4": "Псковская область, г. Новоржев, ул. Германа, д.55\nnovorzhev@reg60.ru\n8 (81143)21142"
    },
    {
        "col1": "Псковская область",
        "col2": 14,
        "col3": "Муниципальное образование «Новосокольнический район»",
        "col4": "182200, Псковская область, Новосокольнический район, г. Новосокольники, ул. Базарная, д. 1\nnovosokolniki@reg60.ru\n8(81144) 22-2-31\n"
    },
    {
        "col1": "Псковская область",
        "col2": 15,
        "col3": "Муниципальное образование -  Опочецкий муниципальный округ",
        "col4": "182330, Псковская область, г. Опочка, ул. Коммунальная, д.8/15\nopochka@reg60.ru\n8 (81138) 2-15-19"
    },
    {
        "col1": "Псковская область",
        "col2": 16,
        "col3": "Островский район",
        "col4": "181350, Псковская область, г.Остров, ул.Островских молодогвардейцев,1\ninfo@ostrov.reg60.ru\n+7(81152) 3-27-22"
    },
    {
        "col1": "Псковская область",
        "col2": 17,
        "col3": "Муниципальное образование «Палкинский       район»",
        "col4": "181270, Псковская область, рп.Палкино, ул.Островская, д.25\npalkino@reg60.ru\n+7(81145)21900"
    },
    {
        "col1": "Псковская область",
        "col2": 18,
        "col3": "Администрация Печорского муниципального округа",
        "col4": "Псковская область, Печорский район, г. Печоры, ул. Каштановая, д. 1\ninfo@pechory.reg60.ru\n+7 (811) 48-2-19-41"
    },
    {
        "col1": "Псковская область",
        "col2": 19,
        "col3": "Муниципальное образование «Плюсский район»",
        "col4": "181000, Псковская область р.п. Плюсса, ул. Школьная, д.1 А, \npljussa@reg60.ru\n8-81133-216-33"
    },
    {
        "col1": "Псковская область",
        "col2": 20,
        "col3": "Муниципальное образование «Порховский район»",
        "col4": "182620, Псковская область, г.Порхов, пр.Ленина, 14\nporhov@reg60.ru\n+7 (81134) 2-19-65"
    },
    {
        "col1": "Псковская область",
        "col2": 21,
        "col3": "Муниципальное образование «Псковский район»",
        "col4": "180019, Псковская область, г. Псков, ул. Олега Кошевого, 4\npskovrajon@reg60.ru\n+7 (8112) 29-31-31"
    },
    {
        "col1": "Псковская область",
        "col2": 22,
        "col3": "Муниципальное образование Пустошкинский район»",
        "col4": "182300, Псковская область, г. Пустошка, ул. Революции, д. 39\npustoshka@reg60.ru\n8(81142)2-24-20"
    },
    {
        "col1": "Псковская область",
        "col2": 23,
        "col3": "Муниципальное образование Пушкиногорский район»",
        "col4": "181370, Псковская область, Пушкиногорский район, рп. Пушкинские Горы, ул. Ленина, д. 6\npushgory@reg60.ru\n8 (81146) 2-11-01\n"
    },
    {
        "col1": "Псковская область",
        "col2": 24,
        "col3": "Пыталовский муниципальный округ",
        "col4": "Псковская область, г Пыталово, ул.Красноармейская, дом 37\npytalovo@reg60.ru\n+7 (81147) 2-31-54/2-30-71\n"
    },
    {
        "col1": "Псковская область",
        "col2": 25,
        "col3": "Администрация Себежского района",
        "col4": "182250, Псковская область, г. Себеж, ул. 7-го Ноября, 2\nsebezh@reg60.ru\n+7 (81140) 21167"
    },
    {
        "col1": "Псковская область",
        "col2": 26,
        "col3": "Муниципальное образование «Струго-Красненский муниципальный округ Псковской области»  ",
        "col4": "Псковская область, р.п. Струги Красные, ул. П.Виноградова, д.4\nstrugikrasnye@reg60.ru\n8-911-370-00-81"
    },
    {
        "col1": "Псковская область",
        "col2": 27,
        "col3": "Усвятский  муниципальный округ",
        "col4": "182570, Псковская область, Усвятский район, рп. Усвяты, ул. К. Маркса, д.20\ninfo@usvjaty.reg60.ru\n8(81150)2-17-37, приёмная 8(81150)2-17-51"
    },
    {
        "col1": "Республика Адыгея",
        "col2": 1,
        "col3": "Министерство строительства, транспорта, жилищно-коммунального и дорожного хозяйства Республики Адыгея",
        "col4": "Республика Адыгея, \nг. Майкоп, \nул. Ленина, 40, minstruy2004@inbox.ru, 8(8772)52-42-13"
    },
    {
        "col1": "Республика Алтай",
        "col2": 1,
        "col3": "Министерство труда, социального развития и занятости населения Республики Алтай",
        "col4": "Республика Алтай, г. Горно - Алтайск, ул. Северная, 10, mintrud@altaigov.ru, 8(38822)47732"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 1,
        "col3": "Администрация муниципального района Абзелиловский район Республики Башкортостан (отдел строительства, архитектуры и ЖКХ)",
        "col4": "453620, Республика Башкортостан, Абзелиловский район, с.Аскарово, ул.Ленина, д.41, adm01@bashkortostan.ru, 8(34772)2-15-10"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 2,
        "col3": "Администрация МР Альшеевский район РБ",
        "col4": "452120, Республика Башкортостан, Альшеевский район, с. Раевский, ул. Ленина, д. 113, adm02@bashkortostan.ru,8(34754)3-15-88"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 3,
        "col3": "Администрация МР Архангельский район РБ",
        "col4": "453030, Республика Башкортостан, Архангельский район, с. Архангельское, ул. Н.Крупская, д.2, тел 834774 -2 -13-76, 03.sjp@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 4,
        "col3": "Администрация МР Аскинский район Республики Башкортостан",
        "col4": "452880, Республика Башкортостан, Аскинский район, с. Аскино, ул. Советская, д.21, adm04@bashkortostan.ru +7(347)712-17-65"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 5,
        "col3": "Администрация муниципального района Аургазинский район Республики Башкортостан",
        "col4": "453480,РБ, Аургаинский район , с.Толбазы, ул.Ленина, д.84sat.alfya@yandex.ru 8(34745)2-19-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 6,
        "col3": "Администрация МР Баймакский район РБ",
        "col4": "453630, Республика Башкортостан, г.Баймак, пр.С.Юлаева,36 adm44@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 7,
        "col3": "Администрация муниципального района Бакалинский район Республики Башкортостан",
        "col4": "452650, РБ, Бакалинский район, с. Бакалы, ул. Ленина, д. 91 adm06@bashkortostan.ru тел. (34742) 3-29-42"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 8,
        "col3": "Администрация \nмуниципального района \nБалтачевский район\nРеспублики Башкортостан",
        "col4": "452980, Республика \nБашкортостан, \nс. Старобалтачево,\nул. Советская, д. 48,\nadm07@bashkortostan.ru/\n+7 (34753) 2-19-61"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 9,
        "col3": "Администрации МР Белебеевский район РБ (жилищный отдел)",
        "col4": "452000,РБ, г. Белебей, ул. Красная, д. 116, 45.kgp@bashkortostan.ru, 8 (34786) 4-14-88"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 10,
        "col3": "Администрации муниципального района Белокатайский район (Отдел опеки и попечительства)",
        "col4": "РБ, Белокатайский район, с. Новобелокатай, ул. Советская, д. 116 adm08@bashkortostan.ru 8(34750)22772"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 11,
        "col3": "Администрации муниципального района Белорецкий район (Отдел жилищной политики и муниципального контроля)",
        "col4": "РБ, г. Белорецк, ул. Ленина, д. 71, adm46@bashkortostan.ru, +7 (34792) 3-23-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 12,
        "col3": "Администрация муниципального района Бижбулякский райони(Отдел строительства архитектуры и ЖКХ)",
        "col4": "РБ, Бижбулякский район.ю с.Бижбуляк, ул.Победы, д.12,РБ, Бижбулякский район, с.Бижбуляк, ул.Победы, д.13, adm09@bashkortostan.ru, тел: 8347-43 21296, 21554"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 13,
        "col3": "Администрация МР Бирский район РБ (сектор по жилищным вопросам)",
        "col4": "РБ, г. Бирск, ул. Курбатова, д. 63, jilotdel47@mail.ru, 8(347)8444037"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 14,
        "col3": "Администрация МР (сектор по жилищным вопросам отдела градостроительства и жилищных вопросов)",
        "col4": "РБ, г. Благовещенск, ул. Седова, д. 96, zhilsektor-blag@yandex.ru, +73476622007"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 15,
        "col3": "Администрация муниципального района Благоварский район Республики Башкортостан (жилищный отдел)",
        "col4": "452740, Республика Башкортостан, Благоварский район, с.Языково, ул.Ленина, д.37, adm10@bashkortostan.ru   т. 83474722410,"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 16,
        "col3": "Администрация \nМР Буздякский \nрайон РБ",
        "col4": "452710, Республика Башкортостан, \nс.Буздяк, ул. Ленина, 4 \nadm11@bashkortostan.ru, \n+7347730404"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 17,
        "col3": "Администрация МР Бураевский район РБ",
        "col4": "452960 Республика Башкортостан с.Бураево, ул. Ленина,66 adm12@bashkortostan.ru +73475621734"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 18,
        "col3": "Администрация МР Бурзянский район РБ",
        "col4": "Администрация МР Бурзянский район РБ, 453580, Бурзянский район, с.Старосубхангулово, ул.Ленина, д.61 8(34755)35240, adm13@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 19,
        "col3": "Администрация МР Гафурийский район Республики Башкортостан (отдел капитального строительства и жизнеобеспечения)",
        "col4": "453050, Гафурийский район,\nс. Красноусольский, ул. Октябрьская, E-mail: adm14@bashkortostan.ru, тел.(34740)2-10-84"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 20,
        "col3": "Администрация МР Давлекановский район РБ",
        "col4": "Администрация МР Давлекановский район РБ, г. Давлеканово, ул. Красная Площадь, д. 9, Администрация МР Давлекановский район РБ adm49@bashkortostan.ru, 83476832525"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 21,
        "col3": "Администрация муниципального района Дуванский район Республики Башкортостан",
        "col4": "452530, Республика Башкортостан, Дуванский район, с. Месягутово, ул. И. Усова, д.6 adm15@bashkortostan.ru 83479832341"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 22,
        "col3": "Администрация муниципального района Дюртюлинский раойн (сектор по жилищным вопросам)",
        "col4": "452320, Республика Башкортостан, г.Дюртюли, ул. Чеверева, 41"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 23,
        "col3": "Администрация муниципального района Ермекеевский район Республики Башкортостан (отдел архитектуры и ЖКХ)",
        "col4": "452190, Ермекеевский район, с. Ермекеево, ул. Ленина, 15; 16.gkh@bashkortostan.ru; тел.: 83474122144"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 24,
        "col3": "Администрация муниципального района Зианчуринский район Республики Башкортостан (межведомственная комиссия)",
        "col4": "453380, Зианчуринский район, с. Исянгулово, ул. Советская, 3; adm17@bashkortostan.ru; тел.: 83478521416"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 25,
        "col3": "Администрация МР Зилаирский район РБ",
        "col4": "453680, Республика Башкортостан, Зилаирский район, с. Зилаир, ул. Ленина, 70 opekazilair@mail.ru 8(347552)2-23-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 26,
        "col3": "Администрация муниципального района Иглинский район Республики Башкортостан\n(сектор по социально-трудовым отношенниям и реализации жилищных программ)",
        "col4": "452410, Республика Башкортостан, Иглинский район, с. Иглино, ул. Свердлова, д.13 otdelais@bk.ru тел8(34795) 2-14-65"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 27,
        "col3": "Администрация муниципального района Илишевский район Республики Башкортостан",
        "col4": "452260, Республика Башкортостан, Илишевский район, с. Верхнеяркеево, ул. Красноармейская, д.35 adm20@bashkortostan.ru 83476251538"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 28,
        "col3": "Администрация муниципального района Ишимбайский район Республики Башкортостан",
        "col4": "453200, Республика Башкортостан, г. Ишимбай, пр. Лениная, д.60, adm51@bashkortostan.ru, 83479461830"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 29,
        "col3": "Администрация муниципального района Калтасинский район Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "452860, Республика Башкортостан, Калтасинский район, с. Калтасы, ул. Карла Маркса, д. 82"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 30,
        "col3": "Сектор ЖКХ Администрации МР Караидельский район РБ",
        "col4": "452360, РБ, Караидельский район, с.Караидель, ул.Ленина, д.24, gilotdel.karaidel@mail.ru, 8(34744)2-12-44"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 31,
        "col3": "Сектор по реализации жилищных программ Администрации муниципального района Кармаскалинский район Республики Башкортостан",
        "col4": "453020, Республика Башкортостан,Кармаскалинский район, с.Кармаскалы, ул.Садовая,23,23.zhilsector@bashkortostan.ru 834765 2-23-86"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 32,
        "col3": "Администрация муниципального района Кигинский район Республики Башкортостан",
        "col4": "452500, Республика Башкортостан, Кигинский район, с. Верхние Киги, ул. Салавата д. 2, adm24@bashkortostan.ru, 83474837171"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 33,
        "col3": "Администрация муниципального района Краснокамский район Республики Башкортостан",
        "col4": "452930, Республика Башкортостан, Краснокамский район, с. Николо- Березовка, ул. Карла Маркса, д.3,adm25@bashkortostan.ru,83475977600"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 34,
        "col3": "Администрация муниципального района Кугарчинский район Республики Башкортостан (отдел по вопросам жизнеобеспечения, строительству, архитектуре, промышленности, транспорту и связи)",
        "col4": "453330, Республика Башкортостан, Кугарчинский район, с.Мраково, ул. Ленина, д. 15, adm26@bashkortostan.ru, 83478921059"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 35,
        "col3": "Администрация муниципального района Кушнаренковский район Республики Башкортостан",
        "col4": "452230, РБ, Кушнаренковский район, с.Кушнаренково, ул.Октябрьская, 69, Zhilkom-kush@mail.ru, т. 8(34780) 57553"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 36,
        "col3": "Администрация МР Куюргазинский район РБ",
        "col4": "453360, Рсепублика Башкортостан, Куюргазинский район, с. Ермолаево, пр-кт Мира, д. 10, тел. 83475761150, adm28@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 37,
        "col3": "Администрация муниципального района Мелеузовский район Республики Башкортостан / отдел опеки и попечительства",
        "col4": "453850, Республика Башкортостан, г. Мелеуз, ул. Воровского, д. 11, e-mail: adm54@bashkortostan.ru, т. +7(34764) 3-14-42"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 38,
        "col3": "Администрация муниципального района Мечетлинский район Республики Башкортостан",
        "col4": "452550, Республика Башкортостан, Мечетлинский район, с. Большеустьикинское, ул. Ленина, д. 20, adm29@bashkortostan.ru 8(34770)2-02-99"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 39,
        "col3": "Администрация муниципального района Мишкинский район Республики Башкортостан",
        "col4": "452340, РБ, Мишкинский район, с.Мишкино, ул.Ленина, 89, adm30@bashkortostan.ru, т. 8(34749) 21801"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 40,
        "col3": "Администрация муниципального района Миякинский район РБ",
        "col4": "452080, Республика Башкортостан, Миякинский район, с. Киргиз-Мияки, ул. Ленина, д.26. adm31@bashkortostan.ru 8(34788)2-12-01, 2-13-78"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 41,
        "col3": "Администрация муниципального района Нуримановского района РБ",
        "col4": "452440, Республика Башкортостан, Нуримановский район, с. Красная Горка, ул. Советская, д.62,adm32@bashkortostan.ru, +7(34776)2-23-35"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 42,
        "col3": "Администрация муниципального района Салаватский район Республики Башкортостан",
        "col4": "452490, Республика Башкортостан, Салаватский район, с.Малояз, ул.Советская, д.63 adm33@bashkortostan.ru 8 (34777)20695"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 43,
        "col3": "Администрация муниципального района Стерлибашевский район РБ (отдел жизнеобеспечения АМР Стерлибашевский район РБ)",
        "col4": "453180, Стерлибашево, ул.Карла Маркса,97\nТел.2-20-31, факс 2-20-03, adm34@bashkortostan.ru\n"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 44,
        "col3": "Администрация муниципального района Стерлитамакский район Республики Башкортостан (отдел жилищной политики)",
        "col4": "453124, Республика Башкортостан, г.Стерлитамак, ул.Худайбердина, д.16 35.house@bashkortostan.ru 8 3473 25 15 95"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 45,
        "col3": "Администрация муниципального района Татышлинский район РБ (сектор по опеке и попечительству)",
        "col4": "452830, РБ Татышлинский район, с. Верхние Татышлы, ул. Ленина, д.75 орека-tatroo@yandex.ru 8(34778)2-11-33"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 46,
        "col3": "Администрация муниципального района Туймазинский район РБ (жилищный отдел АМР Туймазинский район РБ)",
        "col4": "452755, РБ, г. Туймазы, ул. Островского, д. 34, gilotdel2011@mail.ru тел.: 8(34782)7-30-14, 7-32-44"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 47,
        "col3": "Администрация муниципального района Уфимский район Республики Башкортостан",
        "col4": "г. Уфа, ул. Достоевского, 98, opeka_ur@mail.ru, (347)246-14-02"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 48,
        "col3": "Администрация муниципального района Учалинский район Республики Башкортостан (жилищный отдел)",
        "col4": "453700, Республика Башкортостан, Учалинский район, г. Учалы, ул. К. Маркса, д. 20, 61.zhilish@bashkortostan.ru, тел.: 6(34791)6-21-90"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 49,
        "col3": "Администрация муниципального района, Федоровский район Республики Башкортостан",
        "col4": "453280, Республика Башкортостан, Федоровский район, с. Федоровка, ул. Ленина, д.48, opeka.fedorovka@mail.ru, тел. 8 (34746)2-73-21"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 50,
        "col3": "Администрация муниципального района Хайбуллинский район Республики Башкортостан",
        "col4": "453800, Республика Башкортостан, Хайбуллинский район, с. Акъяр, пр. С.Юлаева, д. 45, e-mail: adm39@bashkortostan.ru, тел.: +7(34758)2-15-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 51,
        "col3": "Администрация муниципального района Чекмагушевский район Республики Башкортостан",
        "col4": "452200 Республика Башкортостан, Чекмагушевский район, с. Чекмагуш, ул.Ленина, д.55, e-mail: 40.jilotdel2@bashkortostan.ru 8(34796)3-22-17"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 52,
        "col3": "Администрация муниципального района Чишминский район РБ",
        "col4": "452170, р.п.Чишмы, ул.Кирова, д.50, тел (834797) 2-12-22, adm41@bashkortostan.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 53,
        "col3": "Администрация МР Шаранский район РБ",
        "col4": "452630, РБ, Шаранский район, с.Шаран, ул. Пролетарская. д. 1 тел 34769 2-11-30, 2-23-11"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 54,
        "col3": "Администрация муниципального района Янаульский район РБ (отдел по опеке и попечительству)",
        "col4": "452800, РБ, г.Янаул, пер.Малышева, д.4Б, ms,opeka@bk,ru тел.: 8(34760)51824"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 55,
        "col3": "Администрация ГО г.Агидель РБ",
        "col4": "г.Агидель Цветочный бульвар,д.2 43.jil@bashkortostan.ru 28215"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 56,
        "col3": "Администрация городского округа город Кумертау Республики Башкортостан (Жилищный отдел)",
        "col4": "453300, Республика Башкортостан, г.Кумертау, ул.Ленина, д.18, \nadm52@bashkortostan.ru (52.house@bashkortostan.ru), 83476143861"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 57,
        "col3": "Администрация ЗАТО Межгорье Республики Башкортостан (Отдел по учету и распределению жилья Администрации ЗАТО Межгорье Республики Башкортостан)",
        "col4": "453571, Республика Башкортостан, г. Межгорье, ул. 40 лет Победы, д. 60, adm53@bashkoptostan.ru , тел. 8(34781)2-24-77, 8(34781)2-15-00"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 58,
        "col3": "Администрация городского округа город Нефтекамск Республики Башкортостан",
        "col4": "452680, г. Нефтекамск, пр-кт. Комсомольский, д. 25, тел. 8(34783)4-92-46, adm55@bashkortostan.ru; отдел опеки и попечительства - г. Нефтекамск, ул. Ленина, д. 13 А, тел. 8(34783)4-03-48, neft_opeka@mail.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 59,
        "col3": "Управление земельно-имущественных отношений и жилищной политики адмнистрации ГО г.Октябрьский (УЗИО, жилищный отдел )",
        "col4": "452600, г. Октябрьский, ул. Чапаева, д. 13, тел. 8(34767)7-26-41, okt-komjilpol@yandex.ru"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 60,
        "col3": "Администрация городского округа город Салават Республики Башкортостан (отдел опеки и попечительства)",
        "col4": "453261, г. Салават, ул. Ленина, д. 2 ,  adm57@bashkortostan.ru/    opeka@salavat.bashkortostan.ru тел. 8(3476)32-64-74"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 61,
        "col3": "Администрация г.о.г.Сибай (Отдел закупок )",
        "col4": "453830 Республика Башкортостан,г.Сибай,ул.Ленина,д.9/1, тел(.43775) 5 28 79"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 62,
        "col3": "Администрация городского округа город Стерлитамак Республики Башкортостан",
        "col4": "453100, г.Стерлитамак, пр-кт Октября, д.32, adm59@bashkortostan.ru, 8(3473) 24-18-91"
    },
    {
        "col1": "Республика Башкортостан",
        "col2": 63,
        "col3": "Администрация городского округа город Уфа Республики Башкортостан (Управление по распределению жилья и жилищных программам Администрации городского округа город Уфа Республики Башкортостан",
        "col4": "450098, Республика Башкортостан, г. Уфа, проспект Октября, д. 120, ourz@ufacity.info, 8(347)279-03-66"
    },
    {
        "col1": "Республика Бурятия",
        "col2": 1,
        "col3": "Министерство социальной защиты населения Республики Бурятия",
        "col4": "Республика Бурятия, г. Улан-Удэ, ул. Гагарина, д. 10, 670034/  mszn@govrb.ru /83012445039"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 1,
        "col3": "Агульский район",
        "col4": "Адрес: Республика Дагестан, Агульский район, с. Тпиг;\nagul-mo@mail.ru; +7 (243) 2-21-10"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 2,
        "col3": "Акушинский район",
        "col4": " Республика Дагестан, Акушинский район, с.Акуша, ул. А.Акушинского, дом 1;\namo-akusha@mail.ru +7 (87260) 21340"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 3,
        "col3": "Ахвахский район",
        "col4": "Республика Дагестан, Ахвахский район, с. Карата.\nadm_ahvah05@mail.ru +7 (87250) 2-21-82"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 4,
        "col3": "Ахтынский район",
        "col4": "368730, Республика Дагестан, Ахтынский район, с. Ахты, ул. Гаджи Ахтынского, д. 3; ahtirayon@e-dag.ru +7 (8722) 55-75-98,  +7 (8722) 55-75-97."
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 5,
        "col3": "Бабаюртовский район",
        "col4": "368060, РД, Бабаюртовский район, с. Бабаюрт, ул. Ленина, 29;\nbabaurt-rayon@yandex.ru  8 87247 2-19-88"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 6,
        "col3": "Ботлихский район",
        "col4": "368970, Республика Дагестан, Ботлихский район, с. Ботлих\nbotlihrayon@e-dag.ru +7 (87271) 2-20-59"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 7,
        "col3": "Буйнакский район",
        "col4": "368220, г. Буйнакск, ул. Ленина, 61;\nbuynakskrayon@e-dag.ru +7 (87237) 2-93-93"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 8,
        "col3": "Гергебельский район",
        "col4": "368250, Россия, Республика Дагестан, Гергебильский район, с. Гергебиль, ул. Наиба Идриса, д. №7;\nmogergebil@yandex.ru +7 (87255) 23-2-01"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 9,
        "col3": "Гумбетовский район",
        "col4": "368930, Республика Дагестан, Гумбетовский район, с. Мехельта, ул. Центральная, д. 1; m.o.gumbet@mail.ru +7 782 722-62-30  +7 872 722-32-43"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 10,
        "col3": "Гунибский район",
        "col4": "368340, Республика Дагестан, Гунибский район, село Гуниб, пл. имени Имама Шамиля, д. 1; mo_gunib@mail.ru +7 (872) 582-22-45"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 11,
        "col3": "Дахадаевский район",
        "col4": "368570,  Республика Дагестан, Дахадаевский район, село Уркарах ул. А. Алиева, 9;\ndahadaevrayon@e-dag.ru +7 (87254) 2-45-08"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 12,
        "col3": "Дербентский район",
        "col4": "Республика Дагестан, г. Дербент, ул. Гагарина, 23; derbentrayon@e-dag.ru +7 (903) 482-99-90,  +7 (87240) 4-31-75"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 13,
        "col3": "Докузпаринский район",
        "col4": "368750, Республика Дагестан, Докузпаринский район, село Усухчай, ул. Х.Д. Заманова, д. 2; oit_dokuzpara@mail.ru +7 (8722) 55-11-31"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 14,
        "col3": "Казбековский район",
        "col4": "Республика Дагестан, Казбековский район, с. Дылым, ул. Имама Шамиля, 43; adminkazbek@mail.ru +7 (8722) 55-48-76"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 15,
        "col3": "Кайтагский район",
        "col4": "368590, Республика Дагестан, Кайтагский район, с. Маджалис, ул. Надречная, д. 11; mokaitag@yandex.ru (87238) 47-3-70"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 16,
        "col3": "Карабудахкентский район ",
        "col4": "368530, Республика Дагестан, Карабудахкентский район, с. Карабудахкент, ул. Казиева (Советская), 2; karrayon@e-dag.ru  +7 (87232) 2-23-00"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 17,
        "col3": "Каякентский район",
        "col4": "368560, Республика Дагестан, Каякентский район, c. Новокаякент, ул. Джабраиловой, 36; kkentrayon@e-dag.ru +7 872 482-13-90"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 18,
        "col3": "Кизилюртовский район",
        "col4": "368120, Республика Дагестан, г. Кизилюрт, ул. Гагарина, д. 52; kizilyurt-rn@mail.ru +7 (87234) 2-21-14 +7 (928) 047-50-50"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 19,
        "col3": "Кизлярский район",
        "col4": "368830, Республика Дагестан, город Кизляр, Советская ул., 17; kizray@mail.ru +7 (87239) 2-24-36"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 20,
        "col3": "Кумторкалинский район",
        "col4": " Республика Дагестан, Кумторкалинский район, с. Коркмаскала, ул. Ленина 13; kumtorkala_raionu@mail.ru +7 (7241)55-14-20"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 21,
        "col3": "Кулинский район",
        "col4": "368390, Республика Дагестан, Кулинский район, село Вачи; kulirayon@mail.ru +7 (7268) 55-17-70"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 22,
        "col3": "Курахский район",
        "col4": "368180, РД, Курахский район, с. Курах, ул. Назаралиева, 1а; kurah.rayon@mail.ru  +7 (8722) 55-06-53,  +7 (8262) 24-1-36"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 23,
        "col3": "Лакский район",
        "col4": "Республика Дагестан, Лакский район, село Кумух, улица Сурхайхана, дом 21;  lakrayon@e-dag.ru +7 (267)2-42-72"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 24,
        "col3": "Левашинский район",
        "col4": "Республика Дагестан, Левашинский район, село Леваши, улица Ленина, 12; mo-levrayon@mail.ru +7 (87252) 2-13-20"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 25,
        "col3": "Магарамкентский район",
        "col4": "Республика Дагестан, Магарамкентский район, село Магарамкент, улица Гагарина, 2; mkentrayon@e-dag.ru +7 (8722) 55-18-27"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 26,
        "col3": "Новолакский район",
        "col4": "368160, Республика Дагестан, Новолакский район, село Новолакское, улица Мирзоева, 1; adm_novolac@mail.ru +7 (8242) 2-15-00 "
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 27,
        "col3": "Ногайский район",
        "col4": "368850, РД, Ногайский район, село Терекли Мектеб, ул. Карла Маркса, 15; nogayrayon@e-dag.ru  +7 (928) 590-41-35"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 28,
        "col3": "Рутульский район",
        "col4": "Республика Дагестан, Рутульский район, с. Рутул, ул. Центральная, д. 593; pressa@mr-rutul.ru +7 (872) 642 35 08 7 (989) 664-29-79"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 29,
        "col3": "Сергокалинский район",
        "col4": "Республика Дагестан, Сергокалинский район, с. Сергокала, ул. 317 cтр. Дивизии д.9; sergokalarayon@e-dag.ru +7 (87230) 2-33-40"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 30,
        "col3": "Сулейман-стальский район",
        "col4": "368760, Республика Дагестан, с. Касумкент, ул. Ленина, 26; sstalskrayon@e-dag.ru +7 (87236) 3-41-76"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 31,
        "col3": "Табасаранский район",
        "col4": " 368650, Республика Дагестан, Табасаранский район, с. Хучни, ул. Османова, 32; tabasaranrayon@e-dag.ru +7 (872) 492-41-64"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 32,
        "col3": "Тарумовский район",
        "col4": "Республика Дагестан, Тарумовский район, ул. Советская, №19; tarumrayon@e-dag.ru +7 (7261) 3-10-20,  +7 (7261)3-10-12"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 33,
        "col3": "Тляратинский район",
        "col4": "368 420, Республика Дагестан, Тляратинский район, с. Тлярата;  tlyaratarayon@e-dag.ru +7 (8265) 342-34,  +7 (8265) 342-05"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 34,
        "col3": "Унцукульский район",
        "col4": "368948, Республика Дагестан, п. Шамилькала, ул. М. Дахадаева, 3; mo_uncuk_raion@mail.ru 8 (8722) 55-62-79"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 35,
        "col3": "Хасавюртовский район",
        "col4": "368040, Республика Дагестан, Хасавюртовский район, село Эндирей, ул. Махачкалинское шоссе, зд. 25А; hasavurtrayon@e-dag.ru +7 (87231) 5-20-95"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 36,
        "col3": "Хивский район",
        "col4": "Республика Дагестан, Хивский район, с. Хив, ул. Советская, 13; \nmo-khivskiy@yandex.ru +7 (244) 2-22-33"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 37,
        "col3": "Хунзахский район",
        "col4": "Республика Дагестан, Хунзахский район, с. Хунзах, ул. М. Алиханова, 39; info@khunzakh.ru +7 (8722) 255-30-06"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 38,
        "col3": "Цумадинский район",
        "col4": "368900, Республика Дагестан, Цумадинский район, с. Агвали, ул. К. Абакарова, д. 95/2; tsumadarayon@e-dag.ru +7 (87273) 2-52-39"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 39,
        "col3": "Цунтинский район ",
        "col4": "368412, Республика Дагестан, Цунтинский район, село Кидеро, улица Н. Гаджиева, дом 33, корпус а; mo_cunta@mail.ru +7 (8722) 55-06-25"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 40,
        "col3": "Чародинский район",
        "col4": "Республика Дагестан, Чародинский район, село Цуриб,ул. Им.Шамиля, 1; charodarayon@e-dag.ru +7 (8722) 55-36-27"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 41,
        "col3": "Шамильский район",
        "col4": "368430, Республика Дагестан, Шамильский район, село Хебда, ул. Имама Шамиля, дом 50; shamilrayon@e-dag.ru +7 (87259) 2-22-96"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 42,
        "col3": "Бежтинский участок",
        "col4": "368410, Республика Дагестан, Цунтинский район, село Бежта, ул. Ленина, д. 1, с. 1, к. 1; bezhtinskiy@e-dag.ru +7 (8722) 55-23-01"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 43,
        "col3": "г. Махачкала",
        "col4": "367012, пл. Ленина, 2, г. Махачкала; info@mkala.ru +7 (8722) 67-21-34"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 44,
        "col3": "г. Дербент",
        "col4": "368600, Республика Дагестан, г. Дербент, пл. Свободы, 2; derbent@e-dag.ru +7 (87240) 4-26-66"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 45,
        "col3": "г. Буйнакск",
        "col4": "368220, Республика Дагестан, г. Буйнакск, ул. Х.Мусаясула, 9; derbent@e-dag.ru +7 (87240) 4-26-66"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 46,
        "col3": "г. Хасавюрт",
        "col4": "368000, Республика Дагестан г. Хасавюрт ул. Ирчи-Казака 39; xacavurt@mail.ru +7 (8722) 700-112"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 47,
        "col3": "г. Каспийск",
        "col4": "368300, РФ, Республика Дагестан, г. Каспийск, ул. Орджоникидзе, 12; kasp.info@yandex.ru +7 (7246) 5-10-00"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 48,
        "col3": "г. Кизляр",
        "col4": "368830, Республика Дагестан, г. Кизляр, ул. Советская, д. 17; it@mo-kizlyar.ru +7 (87239) 2-24-36,  +7 (87239) 2-19-22"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 49,
        "col3": "г. Кизилюрт",
        "col4": "368120, Республика Дагестан, город Кизилюрт, ул. Гагарина, 40б; mo.gor.kiz@mail.ru (87234) 2-12-34"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 50,
        "col3": "г. Избербаш",
        "col4": "368500, г. Избербаш, проспект Ленина, д. 2; izberbash@e-dag.ru +7 (87245) 2-49-13"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 51,
        "col3": "г. Южно-Сухокумск",
        "col4": "Республика Дагестан, Южно-Сухокумск, ул. Пионерская, 13; goyuzhno-suxokumsk@mail.ru +7 (87276) 2-10-10"
    },
    {
        "col1": "Республика Дагестан\n",
        "col2": 52,
        "col3": "г. Дагестанские Огни",
        "col4": "368670, Республика Дагестан, город Дагестанские Огни, ул. Козленко, стр. 1; dagogniadmin@mail.ru  +7 (87275) 5-31-13"
    },
    {
        "col1": "Республика Ингушетия",
        "col2": 1,
        "col3": "Министерство образования и науки",
        "col4": "dopotdel@mail.ru; 8(8732) 22-17-93;    386102  Республика Ингушетия, г. Назрань,ул. Московская, 37"
    },
    {
        "col1": "Республика Калмыкия",
        "col2": 1,
        "col3": "-",
        "col4": "-",
    },
    {
        "col1": "Республика Карелия",
        "col2": 1,
        "col3": "Министерство социальной защиты Республики Карелия",
        "col4": "185910, г. Петрозаводск, \nпросп. Ленина, д. 6; \ne-mail: minsoc@soc.gov10.ru; \n(8142) 59-57-48, доб. 1001,\n (8142) 59-57-60\n"
    },
    {
        "col1": "Республика Коми",
        "col2": 1,
        "col3": "Муниципальное образование городского округа «Сыктывкар»",
        "col4": "ул. Орджоникидзе, д. 11,\n г. Сыктывкар,\n Республика Коми, 167000\nAntonovskaya-MV@sykt.rkomi.ru,\n8 (8212) 215607 "
    },
    {
        "col1": "Республика Коми",
        "col2": 2,
        "col3": "Муниципальный округ «Ухта» Республики Коми",
        "col4": "проезд Пионергорский, д. 2, г. Ухта,  Республика Коми, 169300\nkumiuht@mail.ru,\n8 (8216) 746341"
    },
    {
        "col1": "Республика Коми",
        "col2": 3,
        "col3": "Муниципальный округ «Воркута»",
        "col4": "пл. Центральная, д. 7, г. Воркута, Республика Коми, 169900\n\npolikarpova.NG@mayor.vorkuta.ru\n\n8 8215 15 56 54"
    },
    {
        "col1": "Республика Коми",
        "col2": 4,
        "col3": "Муниципальный округ «Инта» Республики Коми",
        "col4": "ул. Горького, д. 16, г. Инта,  Республика Коми, 169840, \nna.docis@inta.rkomi.ru,\n8 (82145) 62941"
    },
    {
        "col1": "Республика Коми",
        "col2": 5,
        "col3": "Муниципальное образование муниципального района «Печора»",
        "col4": "Печорский проспект, д. 46,\n г. Печора,  Республика  Коми,169600,\njilotdelpechora@mail.ru,\n               8 (82142) 73813,\n               8 (82142) 72327 "
    },
    {
        "col1": "Республика Коми",
        "col2": 6,
        "col3": "Муниципальный округ «Усинск» Республики Коми",
        "col4": "ул. Ленина, д. 13, г. Усинск, Республика Коми, 169710,\ni.s.kuzmina@usinsk.rkomi.ru,\n8 (82144) 28130 (доп. 136)\n"
    },
    {
        "col1": "Республика Коми",
        "col2": 7,
        "col3": "Муниципальный округ «Вуктыл» Республики Коми",
        "col4": " ул. Комсомольская д. 14, \nг. Вуктыл, Республика Коми, 169570,\nsocialpolitika@mail.ru,\n8 (82146) 22262 (доб.26)"
    },
    {
        "col1": "Республика Коми",
        "col2": 8,
        "col3": "Муниципальное образование муниципального района  «Сосногорск»",
        "col4": "ул. Куратова, д.2, г. Сосногорск, Республика Коми,   169500,\nkui.sosn@mail.ru,\n8 (82149) 54471"
    },
    {
        "col1": "Республика Коми",
        "col2": 9,
        "col3": "Муниципальный район Сыктывдинский Республики Коми",
        "col4": "ул. Домны Каликовой, д.62, село Выльгорт, Республика Коми,  168220, Сыктывдинский район, \ne.i.lytkina@syktyvdin.rkomi.ru,\n8 (82130) 72118"
    },
    {
        "col1": "Республика Коми",
        "col2": 10,
        "col3": "Муниципальное образование муниципального района «Сысольский»",
        "col4": "ул. Советская, д. 35, с. Визинга, Сысольский район,Республика Коми,  168100,\ngp@sysola.rkomi.ru,\n8 (82131) 91596 "
    },
    {
        "col1": "Республика Коми",
        "col2": 11,
        "col3": "Муниципальный район «Койгородский»",
        "col4": "Мира ул., д.7, с. Койгородок, Республика Коми,168170,\nremizova.valentinka@mail.ru,\n8 (82132) 91630"
    },
    {
        "col1": "Республика Коми",
        "col2": 12,
        "col3": "Муниципальный район \"Прилузский\" Республики Коми",
        "col4": "ул. Мира, д. 76,с. Объячево,Прилузский район,  Республика Коми,  168130,\nm.y.tugolukova@priluzie.rkomi.ru,\n8 (82133) 22561  "
    },
    {
        "col1": "Республика Коми",
        "col2": 13,
        "col3": "Муниципальный район «Корткеросский» Республики Коми",
        "col4": "ул. Советская, д. 225, село Корткерос, Корткеросский район, Республика Коми,   168020,\ngkhkortkeros@mail.ru,\n8(82136) 92495"
    },
    {
        "col1": "Республика Коми",
        "col2": 14,
        "col3": "Муниципальное образование муниципального района «Усть-Куломский»",
        "col4": "ул. Советская, д. 37, с. Усть-Кулом, Усть-Куломский район,Республика Коми,  168060, \nzhil.otdel@ust-kulom.rkomi.ru,\n8(82137) 94901"
    },
    {
        "col1": "Республика Коми",
        "col2": 15,
        "col3": "Муниципальное образование муниципального района «Троицко-Печорский»",
        "col4": "ул. Ленина, д. 2,  пгт Троицко-Печорск, Республика Коми, 169420,\notdel.zemel1@mail.ru,\n8 (82138) 97509 "
    },
    {
        "col1": "Республика Коми",
        "col2": 16,
        "col3": "Муниципальное образование муниципального района «Усть-Вымский»",
        "col4": "ул. Центральная, д. 112,\n  с. Айкино,Усть-Вымский р-н,   Республика Коми, 169040,                           ust-vym-adm@mail.ru\nadm.upravleniezhilishnoi@yandex.ru,\n8 (82134) 28213"
    },
    {
        "col1": "Республика Коми",
        "col2": 17,
        "col3": "Муниципальный округ «Княжпогостский»",
        "col4": "ул. Дзержинского, д.81,\n г. Емва,Республика Коми, 168200,\nuoemva@yandex.ru,\n8 (82139) 23325 "
    },
    {
        "col1": "Республика Коми",
        "col2": 18,
        "col3": "Муниципальное образование муниципального района «Удорский»",
        "col4": "ул. Центральная, д. 144, с. Кослан,Удорский район, Республика Коми,169240,\nzv@udora.rkomi.ru,\n8 (82135) 33034"
    },
    {
        "col1": "Республика Коми",
        "col2": 19,
        "col3": "Муниципальное образование муниципального района «Ижемский»",
        "col4": "ул. Советская, д. 45, с. Ижма,Ижемский район, Республика Коми,169460, \njkhiks@mail.ru,\n8 (82140) 94107 доб. 105  "
    },
    {
        "col1": "Республика Коми",
        "col2": 20,
        "col3": "Муниципальное образование муниципального района «Усть-Цилемский»",
        "col4": "ул. Новый квартал, д.11а, с. Усть-Цильма, Республика Коми, 169480,\nadmin@ust-cilma.rkomi.ru (резерв e.n.ostashova@ust-cilma.rkomi.ru) ,\n8 (82141) 91255"
    },
    {
        "col1": "Республика Крым",
        "col2": 1,
        "col3": "Министерство жилищной политики и государственного строительного надзора Республики Крым",
        "col4": "295013, Республика Крым, г. Симферополь, ул. Севастопольская, д. 45 mgsn@mgsn.rk.gov.ru\nТелефон +7(3652)60-58-99"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 1,
        "col3": "Администрация городского округа «Город Йошкар-Ола»",
        "col4": "424001, г. Йошкар-Ола, Ленинский проспект, 27 / admiola@i-ola.ru / (8362) 41-40-37"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 2,
        "col3": " Администрация городского округа \"Город Волжск\"",
        "col4": "425000, г.Волжск, Коммунистическая ул., д.1 / volzhsk.adm@mari-el.gov.ru / (83631) 6-22-58"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 3,
        "col3": "Администрация городского округа «Город Козьмодемьянск»",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / kozm.adm@mari-el.gov.ru / (83632) 7-12-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 4,
        "col3": "Администрация Волжского муниципального района",
        "col4": "425000, г. Волжск, ул. Советская, д. 29 / admrvolzsk@mail.ru / (83631) 6-35-75"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 5,
        "col3": "Администрация Горномарийского муниципального района",
        "col4": "425350, г. Козьмодемьянск, бульвар Космонавтов, д. 14 / gorn.adm@mari-el.gov.ru; adm-gorn2006@yandex.ru / (83632) 7-11-53"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 6,
        "col3": "Администрация Звениговского муниципального района",
        "col4": "425060, г. Звенигово, ул. Ленина, д. 39 / zven.adm@mari-el.gov.ru / (83645) 7-17-55"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 7,
        "col3": "Килемарский муниципальный округ Республики Марий Эл",
        "col4": "425270, Килемарский район, пгт Килемары, ул. Садовая 55 / adm-kilemary@yandex.ru / (83643) 2-14-35"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 8,
        "col3": "Администрация Куженерского муниципального района",
        "col4": "425550, п. Куженер, ул. Кирова 14 / kuzhen.adm@mari-el.gov.ru / (83637) 9-15-80"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 9,
        "col3": "Администрация Мари-Турекского муниципального района",
        "col4": "425500, п. Мари-Турек, ул.Парковая, д. 7 / mturek.adm@mari-el.gov.ru / (83634) 9-71-00"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 10,
        "col3": "Администрация Медведевского муниципального района",
        "col4": "425200, Медведевский район, пгт Медведево, Советская ул., д.20 / MED_ADM@MAIL.RU / (8362) 58-28-50"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 11,
        "col3": "Администрация Моркинского муниципального района",
        "col4": "425120, пос. Морки, ул.Советская, 14 / morki.adm@mari-el.gov.ru / (83635) 9-16-12"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 12,
        "col3": "Администрация Новоторъяльского муниципального района",
        "col4": "425430, п. Новый Торъял, ул. Культуры, д.33 / admtoryal@mail.ru / (83636) 9-15-51"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 13,
        "col3": "Администрация Оршанского муниципального района",
        "col4": "425250, пгт. Оршанка, ул. Советская, 109 / orshan.adm@mari-el.gov.ru / (83641) 2-34-41"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 14,
        "col3": "Администрация Параньгинского муниципального района",
        "col4": "425570, Параньгинский район, пгт Параньга, ул. Новая, д. 4 / paranga.adm@mari-el.gov.ru / (83639)4-72-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 15,
        "col3": "Администрация Сернурского муниципального района",
        "col4": "425450, Сернурский район, пгт. Сернур, ул. Комсомольская, д. 10 / sernur12_adm@mail.ru / (83633) 9-73-30"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 16,
        "col3": "Администрация Советского муниципального района",
        "col4": "425400, пгт. Советский, ул. Свердлова, д. 8 / sovetsk_adm@mail.ru / (83638) 9-43-15"
    },
    {
        "col1": "Республика Марий Эл",
        "col2": 17,
        "col3": "Администрация Юринского муниципального района",
        "col4": "425370, Юринский район, пгт.Юрино, ул. Красная площадь, д.23 / yurino.adm@mari-el.gov.ru / (83644) 3-22-24"
    },
    {
        "col1": "Республика Мордовия",
        "col2": 1,
        "col3": "Министерство социальной защиты, труда и занятости населения Республики Мордовия",
        "col4": "тел. 8(8342)39-31-09, эл. адрес: mtszn-opnd@e-mordovia.ru, почтовый адрес: 430027, Республика Мордовия, г. Саранск, ул. Титова, д. 133."
    },
    {
        "col1": "Республика Саха (Якутия)",
        "col2": 1,
        "col3": "Министерство строительства Республики Саха (Якутия)",
        "col4": "677000, Республика Саха (Якутия), г.Якутск, ул. Аммосова, д. 8/ minstroy@sakha.gov.ru\n/8(4112) 424412"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 1,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Алагирскому району",
        "col4": "РСО-Алания, 363426, г. Алагир., ул. Комсомольская, 28, тел. 8(86731) 36405  Email:uszn-alagir@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 2,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ардонскому  району",
        "col4": "РСО-Алания, 363330, г. Ардон, ул. Пролетарская, 71, тел. 8(86732) 33483, Email: uszn-ardon@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 3,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Дигорскому  району",
        "col4": "РСО-Алания, 363410, г. Дигора, ул. Энгельса, 47, 8(86733) 91960; Email:uszn-digora@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 4,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Ирафскому району",
        "col4": "РСО-Алания, 363500, с. Чикола, ул. Макоева, 19. 8(86734)31380 Email: uszn-iraf@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 5,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Кировскому району",
        "col4": "РСО-Алания,363600,  с.Эльхотово,  ул. Зортова, 20, 8(86735)51056. Email: uszn-kirov@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 6,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Моздокскому району",
        "col4": "РСО-Алания, 363760, г. Моздок, ул. Проезд Юбилейный,6    8(86736)24696  Email: uszn-mozdok@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 7,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Правобережному район",
        "col4": "РСО-Алания, 363020, г. Беслан, ул. Плиева, 16, 8(86737)31564, Email: uszn-prav@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 8,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Пригородному району",
        "col4": "РСО-Алания, 363130, с. Октябрьское, ул. Епхиева, 50, 8(86738)22202  Email: uszn-prig@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 9,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Иристонскому МО г. Владикавказа",
        "col4": "РСО-Алания, 362025, г. Владикавказ, ул. Ватутина, 17  8(8672)536486   Email: uszn-iriston@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 10,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Затеречному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362008, г. Владикавказ, ул. Коцоева, 17  8(8672)256298   Email: uszn-zater@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 11,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Промышленному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362002, г. Владикавказ, ул. Чкалова,4  8(8672)766474   Email: uszn-prom@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Северная Осетия - Алания",
        "col2": 12,
        "col3": "Территориальный орган Министерства труда и социальной защиты населения Республики Северная Осетия-Алания - Управлениt социальной защитьы населения по Северо-Западному  МО г. Владикавказа",
        "col4": "РСО-Алания, 362031 г. Владикавказ, ул. Леонова,4  8(8672)746131   Email: uszn-sevzap@minsotc.alania.gov.ru"
    },
    {
        "col1": "Республика Татарстан",
        "col2": 1,
        "col3": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66",
        "col4": "Министерство образования и науки Республики Татарстан, 420111, Республика Татарстан, г.Казань, ул.Кремлевская, д.9 , отдел опеки, попечительства и педагогической поддержки, Mon@tatar.ru, 8843 294-95-66"
    },
    {
        "col1": "Республика Тыва",
        "col2": 1,
        "col3": "Министерство образования Республики Тыва",
        "col4": "Республика Тыва, \nг. Кызыл, ул. Ленина, 39,  \nopeka.2022@mail.ru\nраб. номер \n8 (39422) 3-00-21"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 1,
        "col3": "Администрация города Абакана",
        "col4": "ул. Щетинкина, д. 10, г. Абакан, 655017, тел.: 8 (3902) 223-791, e-mail: abakanadm@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 2,
        "col3": "Администрация города Абазы",
        "col4": "ул. Ленина, д. 20а, г. Абаза, 655750, тел.: 8 (39047) 2-30-61, e-mail: abm-abaza@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 3,
        "col3": "Администрация города Саяногорска",
        "col4": "микр., Советский, д. 1, г. Саяногорск, 655602, тел.: 8 (39042) 2-02-00, e-mail: sayanogorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 4,
        "col3": "Администрация города Сорска",
        "col4": "ул. Кирова, д. 3, г. Сорск, 655111, тел.: 8 (39033) 2-43-55, e-mail: aosorsk@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 5,
        "col3": "Администрация города Черногорска",
        "col4": "ул. Советская, д. 66, г. Черногорск, 655154, тел.: 8 (39031) 2-25-72, e-mail: adm.ch.22572@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 6,
        "col3": "Администрация Алтайского района",
        "col4": "ул. Ленина, д. 74, с. Белый Яр, Алтайский район,  тел.: 8 (39041) 2-12-53, e-mail: altay@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 7,
        "col3": "Администрация Аскизского района",
        "col4": "ул. Суворова, д. 2, с. Аскиз, Аскизский район, 655700,  тел.: 8 (39045) 9-13-31, e-mail: mo-askiz@mail.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 8,
        "col3": "Администрация Бейского района",
        "col4": "ул. Площадь Советов, д. 20, с. Бея, Бейский район, 655770,  тел.: 8 (39044) 3-20-00, e-mail: mo-beya@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 9,
        "col3": "Администрация Боградского района",
        "col4": "ул. Новая, д. 10, с. Боград, Боградский район, 655340,  тел.: 8 (39034) 9-12-56, e-mail: admbograd@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 10,
        "col3": "Администрация Орджоникидзевского района",
        "col4": "ул. Кирова, д. 16, с. Копьево, Орджоникидзевский район, 655250,  тел.: 8 (39036) 2-17-04, e-mail: amo-ordgo@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 11,
        "col3": "Администрция Таштыпского района",
        "col4": "ул. Ленина, д. 35, с. Таштып, Таштыпский район, 655740,  тел.: 8 (39046) 2-14-42, e-mail: amotash@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 12,
        "col3": "Администрация Усть-Абаканского района",
        "col4": "ул. Рабочая, д. 9, с. Усть-Абакан, Усть-Абаканский район, 655100,  тел.: 8 (39032) 2-16-56, e-mail: oo_ua@r-19.ru"
    },
    {
        "col1": "Республика Хакасия",
        "col2": 13,
        "col3": "Администрацитя Ширинского района",
        "col4": "ул. Октябрьская, д. 79, с. Шира, Ширинский район, 655200,  тел.: 8 (39035) 9-12-10, e-mail: adminshira@r-19.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 1,
        "col3": "Администрация Азовского района (Отдел строительства)",
        "col4": "346780, Россия, Ростовская область, г. Азов, пер. Безымянный, д. 11, каб. 310, 8 863 42 63201 aar-doc@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 2,
        "col3": "Администрация Аксайского района",
        "col4": "346720, г. Аксай, ул. Карла Либкнехта, 132, socsfera@aksayland.ru, 8 (86350) 4-39-61"
    },
    {
        "col1": "Ростовская область",
        "col2": 3,
        "col3": "Администрация Багаевского района",
        "col4": "346610 Ростовская область, Багаевский район, ст.Багаевская, ул.Подройкина, д.10, тел. 8(86357) 33-2-44,  е-mail: bagadm@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 4,
        "col3": "Служба реализации жилищных программ Администрации Белокалитвинского района",
        "col4": "347042, Ростовская область, г. Белая Калитва, ул. Чернышевского, д. 8, sektor25645@mail.ru, 8(86383)2-56-45"
    },
    {
        "col1": "Ростовская область",
        "col2": 5,
        "col3": "Администрация Боковского района",
        "col4": "346250 Ростовская область, Боковский район, станица Боковская, переулок Теличенко, 32/ bokovsk@donland.ru/ 8(86382) 3-14-53 "
    },
    {
        "col1": "Ростовская область",
        "col2": 6,
        "col3": "Администрация Верхнедонского района ",
        "col4": "346170, ул. Матросова, д. 12, ст-ца Казанская, Верхнедонской район, Ростовская область, kazanka@donland.ru 88636431262"
    },
    {
        "col1": "Ростовская область",
        "col2": 7,
        "col3": "Администрация Веселовского района (сектор имущественных и земельных отношений Администрации Веселовского района)",
        "col4": "347781, Ростовская область, Веселовский район, п. Веселый, пер. Комсомольский, д.61 E-mail: irina.chernysheva.82@mail.ru 8(86358)6-10-61"
    },
    {
        "col1": "Ростовская область",
        "col2": 8,
        "col3": "Администрация Волгодонского района",
        "col4": "347350, Ростовская область, Волгодонской район, ст. Романовская, ул. Почтовая, 13, телефон 88639470130, электронная почта: volgraion@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 9,
        "col3": "Администрация Дубовского района Ростовская область",
        "col4": "347410, Ростовская область, Дубовский район, с. Дубовское, пл. Павших Борцов,1, Adm_dub@donland.ru, 8(86377)2-02-70 доб. 129"
    },
    {
        "col1": "Ростовская область",
        "col2": 10,
        "col3": "Администрация Егорлыкского райрна Ростовской области",
        "col4": "347661, Ростовская область, Егорлыкский район, ст. Егорлыкская, ул. Мира, 90, admegorlyk@donland.ru +7(86370)21-3-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 11,
        "col3": "Администрация Заветинского района",
        "col4": "347430 Ростовская область, Заветинский район,село Заветное, ул. Ломоносова, д.24,  zavetnoe61@donland.ru, +7 (86378) 2-14-00"
    },
    {
        "col1": "Ростовская область",
        "col2": 12,
        "col3": "Администрация Зерноградского района  (МКУ Зерноградского района «Управление архитектуры, строительства и муниципального хозяйства»)\n",
        "col4": "347740, Ростовская область, г. Зерноград, ул. Мира, 16, zernoland@donland.ru, 886359 41-6-68 "
    },
    {
        "col1": "Ростовская область",
        "col2": 13,
        "col3": "Администрация Зимовниковского района",
        "col4": "ул. Ленина, 114, п. Зимовники, Ростовская область, 347460 adm@zima.donpac.ru 8(86376)3-16-98"
    },
    {
        "col1": "Ростовская область",
        "col2": 14,
        "col3": "Администрация Кагальницкого района",
        "col4": "347700, Ростовская обл., Кагальницкий р-он., ст. Кагальницкая, ул. Калинина, д. 101, тел.: 8(86345)96-1-40, kaglobsh@donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 15,
        "col3": "Отдел экономического развития, инвестиций и торговли Администрации Каменского района ",
        "col4": " Ростовская область, Каменский район, рп. Глубокий пер. Чкалова, д. 22,      akr@kamensk.donpac.ru, 8(86365)-95-5-43; "
    },
    {
        "col1": "Ростовская область",
        "col2": 16,
        "col3": "Администрация Кашарского района (Отдел по вопросам муниципального хозяйтва)",
        "col4": "346200, Ростовская область, Кашарский район, сл. Кашары, ул. Ленина, д. 58, эл.почта:mhkashary@donland.ru, тел: 8-863-88-2-14-59"
    },
    {
        "col1": "Ростовская область",
        "col2": 17,
        "col3": "Администрация Константиновского района",
        "col4": "347250 Ростовская область, г. Константиновск, ул. 25 Октября 70, адрес электронной почты:  adm_konst@donland.ru. Номер телефона приемной Главы Администрации (863 93) 2-16-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 18,
        "col3": "Управление земельно-имущественных отношений и муниципального заказа Красносулинского района ",
        "col4": "Ростовская область, \nг. Красный Сулин, \nул. Победы 4 uzioimzks@yandex.ru\n 8(863)67-5-24-37"
    },
    {
        "col1": "Ростовская область",
        "col2": 19,
        "col3": "Администрация Куйбышевского района  Ростовской области",
        "col4": "346940 Ростовская область, Куйбышевский район,  село Куйбышево, улица Куйбышевская, 24, adkura@ donland.ru\nтел: 31-7-07"
    },
    {
        "col1": "Ростовская область",
        "col2": 20,
        "col3": "Администрация Мартыновского района Ростовской области",
        "col4": "346660, Ростовская область, Мартыновский район, сл. Большая Мартыновка, ул. Советская, дом № 61/adm@martadmin.ru/88639521550; 88639521035 "
    },
    {
        "col1": "Ростовская область",
        "col2": 21,
        "col3": "Администрация Матвеево-Курганского района ",
        "col4": "346970, Ростовская область, Матвеево-Курганский район, п. Матвеев Курган, ул. 1-я Пятилетка, 108, matveevkurgan@donland.ru, тел. 8(86341) 31639"
    },
    {
        "col1": "Ростовская область",
        "col2": 22,
        "col3": "Администрация \nМиллеровского района",
        "col4": "346130,   ул. Максима Горького, д. 23,                                   г. Миллерово, \nРостовская область; E-mail: roo_millerovsky@rostobr. ru; тел./факс +7 (86385) 3-80-79"
    },
    {
        "col1": "Ростовская область",
        "col2": 23,
        "col3": "Администрация Милютинского района",
        "col4": "347120, Ростовская область, ст.Милютинская, ул.Павших Героев, 46, miladm@donland.ru,  8-863-89-2-14-00 "
    },
    {
        "col1": "Ростовская область",
        "col2": 24,
        "col3": "Комитет по управлению имуществом Администрации Морозовского района",
        "col4": "347210, Ростовская область, г.Морозовск, ул.Ленина, д.204, kuimorozovsk@donland.ru, 88638450243"
    },
    {
        "col1": "Ростовская область",
        "col2": 25,
        "col3": "Администрация Мясниковского района",
        "col4": "346800 Ростовская область, Мясниковский район,с. Чалтырь, ул. Ленина, 33/org.otdel26@mail.ru/88634922502"
    },
    {
        "col1": "Ростовская область",
        "col2": 26,
        "col3": "Администрация Неклиновского района и Управление образования Администрации Неклиновского района",
        "col4": "Ростовская область, Неклиновский район, с. Покровское, пер. Парковый, 1/neklradm@yandex.ru/8(863 47) 2-12-42"
    },
    {
        "col1": "Ростовская область",
        "col2": 27,
        "col3": "Комитет по управлению имуществом Администрации Обливского района                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          ",
        "col4": "347140, Ростовская область, Обливский район,                  ст. Обливская, ул. Ленина, д. 61, imuhestvo@mail.ru,              8(86396)21-8-02                                                                                     "
    },
    {
        "col1": "Ростовская область",
        "col2": 28,
        "col3": "Сектор по организации строительства и создания условий для жилищного строительства Администрации Октябрьского района",
        "col4": "346480, Ростовская область, Октябрьский район, р.п.Каменоломни, ул. 40-лет Октября, д. 80. Электронный адрес: gilstroi_okt@mail.ru. Тел. 8 (86360) 2-01-13"
    },
    {
        "col1": "Ростовская область",
        "col2": 29,
        "col3": "Администрация Орловского района ",
        "col4": "347510, Ростовская обл,Орловсий р-н., п.Орловский, ул.Пионерская, д.75,    orlovkastroy@yandex.ru, (886375) 31-4-80"
    },
    {
        "col1": "Ростовская область",
        "col2": 30,
        "col3": "Отдел имущественных и земельных отношений  Администрации Песчанокопского района Ростовской области",
        "col4": "347570, Ростовская область, Песчанокопский район,с. Песчанокопское, ул. Суворова,  4,       oizo273@yandex.ru   +7(86373)9-11-87"
    },
    {
        "col1": "Ростовская область",
        "col2": 31,
        "col3": "Районный отдел образования Администрации Пролетарского района Ростовская область",
        "col4": "347540,Ростовская область, г.Пролетарск, ул.Пионерская, д.51. rooprol@donland.ru 88637496910"
    },
    {
        "col1": "Ростовская область",
        "col2": 32,
        "col3": "Отдел координации отраслей ЖКХ, строительства, транспорта и связи\nподбирает жилье на вторичном рынке с целью\nего дальнейшего приобретения;Сектор по управлению имуществом и земельным отношениям\nзаключает договор найма специализированного жилого помещения",
        "col4": "347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-2-66,remroo@remont.donpac.ru347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-3-64,arh_31364@mail.ru 347480,Ростовская область, Ремонтненский район, с.Ремонтное, ул.Ленинская, 69, +7(86379)31-0-39,kui-rem@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 33,
        "col3": "Отдел социального развития Администрации Родионово-Несветайского района",
        "col4": "346580, Ростовская обл., Родионово-Несветайский р-н, сл. Родионово-Несветайская, ул. Пушкинская, 34,           \nэл. почта adminrod61@donland.ru\n8(86340)31-3-20"
    },
    {
        "col1": "Ростовская область",
        "col2": 34,
        "col3": "Управление имущественных отношений Сальского района",
        "col4": "Ростовская область, г. Сальск, ул. Ленина, 22;                    uio-salsk@yandex.ru                             \n 886372 5-12-78   "
    },
    {
        "col1": "Ростовская область",
        "col2": 35,
        "col3": "Администрация Семикаракорского района Ростовской области",
        "col4": "346630, Ростовская область, г. Семикаракорск, проспект Н.С. Арабского, 18, admrn@semikar.donpac.ru, 86356 4-18-45"
    },
    {
        "col1": "Ростовская область",
        "col2": 36,
        "col3": "Администрация Советского района",
        "col4": "347180, Ростовская область, Советский район, ст. Советская, ул. Орджоникидзе, д. 14, sov_adm.ro@mail.ru,  тел. 88636323200"
    },
    {
        "col1": "Ростовская область",
        "col2": 37,
        "col3": "Cектор по организации \nкапитального строительства \nи координации работ отраслей \nпромышленности, ЖКХ, \nтранспорта и связи \nАдминистрации \nТарасовского района",
        "col4": "346050 Ростовская область\n Тарасовский район \nп. Тарасовский \nпер. Почтовый дом 5 \n 32004@mail.ru\n 8 (863 86) 32-0-04"
    },
    {
        "col1": "Ростовская область",
        "col2": 38,
        "col3": "Администрация Тацинского района Ростовкой области",
        "col4": "347060, Ростовская область, Тацинский район, станица Тацинская, ул. Ленина, д.43 +7 (86397) 2-12-97 admtacina@tacina.donpac.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 39,
        "col3": "Администрация Усть-Донецкого района",
        "col4": "346550, Ростовская область, Усть-Донецкий район, р.п. Усть-Донецкий, ул. Ленина, д.11, ustland@donland.ru, 8(86351)-9-11-57"
    },
    {
        "col1": "Ростовская область",
        "col2": 40,
        "col3": "Сектор координации работы жилищно-коммунального хозяйства, благоустройства, топливно-энергетических ресурсов и жилищной политики  Администрации Целинского района",
        "col4": "347760, Ростовская область, Целинский район, п.Целина, ул.2-я линия, 105,   адрес электронной почты celinaraion@donland.ru,       k91987@yandex.ru               тел.8 (86371) 9 19 89"
    },
    {
        "col1": "Ростовская область",
        "col2": 41,
        "col3": "Администрация Цимлянского района",
        "col4": "347320 Ростовская область, Цимлянский район, г. Цимлянск, ул. Ленина, 24. cim6141@donland.ru, тел. 8(86391)5-11-44; факс 8(86391)5-10-99"
    },
    {
        "col1": "Ростовская область",
        "col2": 42,
        "col3": "Администрация Чертковского района",
        "col4": "346000, Ростовская область, Чертковский р-н, п.Чертково, ул.Петровского, д.115/ra-217@donland.ru/(86387)21722"
    },
    {
        "col1": "Ростовская область",
        "col2": 43,
        "col3": "Администрация Шолоховского района Ростовской области ",
        "col4": "346270, Ростовская область, Шолоховский район, ст. Вешенская, ул. Шолохова, 54                           ra-211@veshki.donpac.ru             88635322196"
    },
    {
        "col1": "Ростовская область",
        "col2": 44,
        "col3": "Отдел по строительству и архитектуре Администрации города Азова",
        "col4": "Ростовская область, город Азов, ул. Московская, 19 / azovarh@yandex.ru / +7 (86342) 4-19-62"
    },
    {
        "col1": "Ростовская область",
        "col2": 45,
        "col3": "Администрация города Батайска",
        "col4": "346880, Ростовская область, город Батайск, пл. Ленина, 3,       тел. (886354) 5-60-73,                      эл. cozialka@yandex.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 46,
        "col3": "Администрация муниципального образования городской округ \"Город Волгодонск\" Ростовская область",
        "col4": "Советская ул., д.2,\nг. Волгодонск, Ростовская область,\nРоссийская Федерация, 347366\nт.(8639)22-25-23 эл.адрес: volgodonskgorod.donland.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 47,
        "col3": "Муниципальное казенное учреждение города Гуково \"Управление Капитального строительства и жилищно-коммунального хозяйства\" ",
        "col4": "347871 Ростовская область, г. Гуково, ул. Мира, д.42,     mku61@donland.ru, 8(86361)5-28-95"
    },
    {
        "col1": "Ростовская область",
        "col2": 48,
        "col3": "Администрация города Донецка",
        "col4": "346330, Ростовская область, г.Донецк, пр-кт Мира, 39   adm-don@donland.ru  +7(86368) 2-33-15"
    },
    {
        "col1": "Ростовская область",
        "col2": 49,
        "col3": "Отдел имущественных и земельных отношений Администрации города Зверево",
        "col4": "346311 Ростовская область,г. Зверево, ул. Обухова, д.14, oizogzverevo@yandex.ru 88635543309"
    },
    {
        "col1": "Ростовская область",
        "col2": 50,
        "col3": "Администрация город Каменск-Шахтинский",
        "col4": "347800, Ростовская область, г. Каменск-Шахтинский, ул. Ленина 74 E-mail: abmkam@donland.ru 8 (86365) 7-37-63"
    },
    {
        "col1": "Ростовская область",
        "col2": 51,
        "col3": "Департамент жилищно-коммуанльного хозяйства и благоустрйоства Администраии города",
        "col4": "346400, Ростовская область, г. Новочеркасск, ул. Дворцовая, д. 8.  Эл. почта: mu_dgx@mail.ru,  тел. +7 (86352)24057"
    },
    {
        "col1": "Ростовская область",
        "col2": 52,
        "col3": "Управление жилищной политики Администрации города",
        "col4": "346900, Ростовская область, г. Новошахтинск, ул. Харьковская, 58, 8 (863 69) 2 21 17, jilnov@mail.ru"
    },
    {
        "col1": "Ростовская область",
        "col2": 53,
        "col3": "отдел по жилищной политике Администрации города Таганрога",
        "col4": "347900, Ростовская область, г. Таганрог, ул. Петровская, д. 73 / zhilotdel@tagancity.ru / +7 (8634) 312-826"
    },
    {
        "col1": "Ростовская область",
        "col2": 54,
        "col3": "Управление реализации\nбюджетных программ\nв сфере жилищного строительства\nАдминистрации города Шахты\n",
        "col4": "346500, Ростовская область, г.Шахты,     пр-кт.Карла Маркса, 67,         \n urbp@shakhty-gorod.ru,          \n 8 (8636) 22-14-73"
    },
    {
        "col1": "Ростовская область",
        "col2": 55,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 56,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 57,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 58,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 59,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 60,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 61,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Ростовская область",
        "col2": 62,
        "col3": "МБУ  \"Городской центр по управлению специализированным муниципальным жилищным фондом\"",
        "col4": "344064, г. Ростов-на-Дону, ул. Борисоглебская, 12                           8(863) 201-75-97                 mburostov@mail.ru            "
    },
    {
        "col1": "Рязанская область",
        "col2": 1,
        "col3": "Администрация Александро-Невского муниципального района Рязанской области",
        "col4": "391240, Рязанская область, р.п. Александро-Невский, ул. Советская, д. 9; aleksandro-nevskij.r@ryazan.gov.ru, +7 (491-58) 2-23-93"
    },
    {
        "col1": "Рязанская область",
        "col2": 2,
        "col3": "Администрация Ермишинского муниципального района Рязанской области",
        "col4": "391660, Рязанская область, р.п. Ермишь, пл. Ленина, д. 58, ermish@ryazan.gov.ru, +7 (491-44) 2-10-35"
    },
    {
        "col1": "Рязанская область",
        "col2": 3,
        "col3": "Администрация Захаровского муниципального района Рязанской области",
        "col4": "391740, Рязанская область, с. Захарово, ул. Центральная, д. 88, zaxarovo@ryazan.gov.ru, +7 (49153)51-3-26"
    },
    {
        "col1": "Рязанская область",
        "col2": 4,
        "col3": "Администрация Кадомского муниципального района Рязанской области",
        "col4": "391670, Рязанская область, р.п. Кадом, ул. Ленина, д. 37, kadom@ryazan.gov.ru, +7 (491-39) 5-16-90"
    },
    {
        "col1": "Рязанская область",
        "col2": 5,
        "col3": "Администрация Клепиковского муниципального района Рязанской области",
        "col4": "391030, Рязанская область, г. Спас-Клепики, пл. Ленина, д. 1, klepiki@ryazan.gov.ru, +7 (491-42) 2-62-51"
    },
    {
        "col1": "Рязанская область",
        "col2": 6,
        "col3": "Администрация Кораблинского муниципального округа Рязанской области ",
        "col4": "391200, Рязанская область, г. Кораблино, ул. Маяковского, д. 17, korablino@ryazan.gov.ru, +7 (49143) 5-04-25"
    },
    {
        "col1": "Рязанская область",
        "col2": 7,
        "col3": "Администрация Касимовского муниципального округа Рязанской области ",
        "col4": "391300, Рязанская область, г. Касимов, ул. Ленина, д. 9 А, kasimov@ryazan.gov.ru, +7 (49131) 2-24-21\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 8,
        "col3": "Администрация Милославского муниципального района Рязанской области ",
        "col4": "391770, Рязанская область, р.п. Милославское, ул. Ленина, д. 6, miloslavskoe@ryazan.gov.ru, +7 (4912) 20-28-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 9,
        "col3": "Администрация Михайловского муниципального округа Рязанской области ",
        "col4": "391710, Рязанская область, г. Михайлов, пл. Освобождения, д. 1, mixajlov@ryazan.gov.ru, +7 (49130) 2-12-32"
    },
    {
        "col1": "Рязанская область",
        "col2": 10,
        "col3": "Администрация Пителинского муниципального округа Рязанской области ",
        "col4": "391630, Рязанская область, р.п. Пителино, пл. Советская, д. 8, pitelino@ryazan.gov.ru, +7 (49145) 64141"
    },
    {
        "col1": "Рязанская область",
        "col2": 11,
        "col3": "Администрация Пронского муниципального района Рязанской области ",
        "col4": "391140, Рязанская область, р.п. Пронск, пл. Горького, д. 1, pronsk@ryazan.gov.ru, +7 (49155) 3-15-73"
    },
    {
        "col1": "Рязанская область",
        "col2": 12,
        "col3": "Администрация Путятинского муниципального округа Рязанской области ",
        "col4": "391480, Рязанская область, с. Путятино, ул. Ворошилова, д. 56, putyatino@ryazan.gov.ru, +7 (49146) 2-17-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 13,
        "col3": "Администрация Рыбновского муниципального района Рязанской области ",
        "col4": "391110, Рязанская область, г. Рыбное, пл. Ленина, д. 9, rybnoe@ryazan.gov.ru, +7(49137)50-3-42"
    },
    {
        "col1": "Рязанская область",
        "col2": 14,
        "col3": "Администрация Ряжского муниципального округа Рязанской области ",
        "col4": "391964, г. Ряжск, ул. Советская, д. 8, ryazhsk@ryazan.gov.ru, +7 (49132) 2-23-83"
    },
    {
        "col1": "Рязанская область",
        "col2": 15,
        "col3": "Администрация Рязанского муниципального района Рязанской области ",
        "col4": "390047, г. Рязань (п. Соколовка), ул. Связи, д. 14, admryazanr@ryazan.gov.ru, +7 (4912) 28-91-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 16,
        "col3": "Администрация Сапожковского муниципального района Рязанской области ",
        "col4": "391940, Рязанская область, р.п. Сапожок, ул. 50 лет Октября, д. 25, sapozhok@ryazan.gov.ru, +7(49152) 2-11-50"
    },
    {
        "col1": "Рязанская область",
        "col2": 17,
        "col3": "Администрация Сараевского муниципального района  Рязанской области  ",
        "col4": "391870, Рязанская область, р.п. Сараи, ул. Ленина, д. 157, sarai@ryazan.gov.ru, +7 (49148) 3-18-55"
    },
    {
        "col1": "Рязанская область",
        "col2": 18,
        "col3": "Администрация Сасовского муниципального округа Рязанской области ",
        "col4": "391430, Рязанская область, г. Сасово, ул. Садовая, д. 22, sasovo@ryazan.gov.ru, +7 (49133) 5-12-20, +7 (49133) 5-16-10"
    },
    {
        "col1": "Рязанская область",
        "col2": 19,
        "col3": "Администрация Скопинского муниципального района Рязанской области ",
        "col4": "391800, Рязанская область, г. Скопин, пл. Ленина, д. 22 а, skopinr@ryazan.gov.ru, +7 (49156) 2-00-45"
    },
    {
        "col1": "Рязанская область",
        "col2": 20,
        "col3": "Администрация Спасского муниципального района Рязанской области ",
        "col4": "391050, Рязанская область, г. Спасск-Рязанский, ул. Ленина, д. 48, spassk@ryazan.gov.ru, +7 (49135) 3-36-22"
    },
    {
        "col1": "Рязанская область",
        "col2": 21,
        "col3": "Администрация Старожиловского муниципального района Рязанской области ",
        "col4": "391170, Рязанская область, р.п. Старожилово, ул. Толстого, д. 9, starozhilovo@ryazan.gov.ru, +7 (491-51) 2-22-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 22,
        "col3": "Администрация Ухоловского муниципального района Рязанской области ",
        "col4": "391920, Рязанская область, р.п. Ухолово, ул. Ленина, д. 20, ukholovo-adm@ryazan.gov.ru, +7 (4915) 45-12-94"
    },
    {
        "col1": "Рязанская область",
        "col2": 23,
        "col3": "Администрация Чучковского муниципального района Рязанской области",
        "col4": "391420, Рязанская область, р.п. Чучково, пл. Ленина, д. 1, chuchkovo@ryazan.gov.ru, +7 (491-38) 7-62-25\n"
    },
    {
        "col1": "Рязанская область",
        "col2": 24,
        "col3": "Администрация Шацкого муниципального района Рязанской области ",
        "col4": "391550, Рязанская область, г. Шацк, ул. Красная площадь, д. 5, shack@ryazan.gov.ru, +7 (49147) 2-14-52"
    },
    {
        "col1": "Рязанская область",
        "col2": 25,
        "col3": "Администрация Шиловского муниципального района Рязанской области ",
        "col4": "391500, Рязанская область, р.п. Шилово, ул. Советская, д. 14 А, shilovo@ryazan.gov.ru, +7 (491-36) 2-11-80"
    },
    {
        "col1": "Рязанская область",
        "col2": 26,
        "col3": "Администрация городского округа г. Рязани",
        "col4": "390000, г. Рязань, ул. Радищева, д. 28,ryazan@ryazan.gov.ru,+7 (4912) 20-04-12"
    },
    {
        "col1": "Рязанская область",
        "col2": 27,
        "col3": "Администрация городского округа г. Скопин",
        "col4": "391800, Рязанская область, г. Скопин, ул. Ленина, д. 9, skopin.adm@yandex.ru, +7 (49156) 2-06-57"
    },
    {
        "col1": "Самарская область",
        "col2": 1,
        "col3": "Департамент управления имуществом городского округа Самара;",
        "col4": "43010, г. Самара, ул. Красноармейская, д. 17/samara@dyu.su/8 846 977 77 99 (доб. 6285)"
    },
    {
        "col1": "Самарская область",
        "col2": 2,
        "col3": "Администрация городского округа Тольятти",
        "col4": "г.Тольятти, ул.Белорусская, 33, каб.910, 912, dumi@tgl.ru, 54-37-00"
    },
    {
        "col1": "Самарская область",
        "col2": 3,
        "col3": "Жилищный отдел КЖКХ Администрации г.о. Сызрань",
        "col4": "Самарская область, г. Сызрань, ул. Ульяновская, д.61,каб.2, jil_otdel@bk.ru, 8 (846 4) 98-66-53"
    },
    {
        "col1": "Самарская область",
        "col2": 4,
        "col3": "Департамент городского хозяйства администрации городского округа Новокуйбышевск",
        "col4": "г. Новокуйбышевск, ул. Миронова, д. 2, adm@nvkb.ru, 88463563386"
    },
    {
        "col1": "Самарская область",
        "col2": 5,
        "col3": "Администрация городского округа Чапаевск (жилищный  отдел)",
        "col4": "г.Чапаевск, ул. Пролетарская, 1, gilotdel@bk.ru, 8(84639)20317"
    },
    {
        "col1": "Самарская область",
        "col2": 6,
        "col3": "Администрация городского округа Отрадный Самарской области",
        "col4": "г. Отрадный, ул.Отрадная, д.15, dom@otradny.org, 8(84661)22439"
    },
    {
        "col1": "Самарская область",
        "col2": 7,
        "col3": "Отдел по жилищным вопросам комитета по управлению муниципальным имуществом администрации городского округа Жигулевск",
        "col4": "445350, г. Жигулевск, ул. Пушкина, д. 17, Poliynskova.Irina@yandeх.ru; zhilotd@admzhg.ru, 8(84862)2-48-93, 2-42-31"
    },
    {
        "col1": "Самарская область",
        "col2": 8,
        "col3": "Служба по учету и распределению жилья Администрации г.о.Октябрьск",
        "col4": "г.Октябрьск, ул.Ленина, 54, oktyabrsk-go@mail.ru, 8(84646)21576"
    },
    {
        "col1": "Самарская область",
        "col2": 9,
        "col3": "Администрация городского округа Кинель Самарской области в лице комитета по управлению муниципальным имуществом городского округа Кинель Самарской области",
        "col4": "г. Кинель, ул. Мира, д.42а, kumikinel@mail.ru, 8(84663) 6-17-78"
    },
    {
        "col1": "Самарская область",
        "col2": 10,
        "col3": "Администрация городского округа Похвистнево Самарской области",
        "col4": "446450, Самарская обл., г. Похвистнево, ул. Лермонтова, 16, pohgor@samtel.ru, 8(84656)25812 8(84656)23466 "
    },
    {
        "col1": "Самарская область",
        "col2": 11,
        "col3": "Муниципальное казенное учреждение «Комитет по управлению муниципальным имуществом Администрации муниципального района Алексеевский Самарской области» ",
        "col4": "Самарская область, Алексеевский район, с.Алексеевка, ул.Советская,7, al_kumi@mail.ru, 8(84671)22377"
    },
    {
        "col1": "Самарская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальным имуществом администрации муниципального района Безенчукский Самарской области",
        "col4": "Самарская обл, Безенчукский р-н, пгт Безенчук, ул Нефтяников, д 11, kumibez@mail.ru, 88467623308"
    },
    {
        "col1": "Самарская область",
        "col2": 13,
        "col3": "Отдел по управлению муниципальным имуществом Администрации муниципального района Богатовский",
        "col4": "с.Богатое, ул. Комсомольская, д.13, kumi.bogatoe@yandex.ru, 8(84666)2-16-65"
    },
    {
        "col1": "Самарская область",
        "col2": 14,
        "col3": "Муниципальный район Большеглушицкий Самарской области",
        "col4": "446180, Самарская область, Большеглушицкий район, с. Большая Глушица, ул Гагарина, д. 91, bg@admbg.org, 8-846-73-2-16-33"
    },
    {
        "col1": "Самарская область",
        "col2": 15,
        "col3": "Администрация Большечерниговского района Самарской области",
        "col4": "с. Большая Черниговка, ул. Полевая, д.77, kumichernig@yandex.ru, 88467221140"
    },
    {
        "col1": "Самарская область",
        "col2": 16,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имуществом администрации муниципального района Борский Самарской области\"",
        "col4": "Самарская область  Борский район с.Борское  ул.Октябрьская, д. 57, kumi-bor@yandex.ru, 8(84667)1862"
    },
    {
        "col1": "Самарская область",
        "col2": 17,
        "col3": "Администрация муниципального района Волжский Самарской области",
        "col4": "443045, г. Самара, ул. Дыбенко, д. 12 Б, каб. 110, vr@v-adm63.ru, 8 846 260 33 35"
    },
    {
        "col1": "Самарская область",
        "col2": 18,
        "col3": "Комитет по управлению муниципальной собственностью муниципального района Елховский Самарской области",
        "col4": "Самарская область, Елховский район, с. Елховка, ул. М.Заводского, д. 41, kums-elh@yandex.ru, 88465833637"
    },
    {
        "col1": "Самарская область",
        "col2": 19,
        "col3": "Комитет по управлению муниципальным имуществом Администрации м.р.Исаклинский",
        "col4": "с.Исаклы, ул.Куйбышевская, 75А, kumiisakly@mail.ru, (84654)2-14-12"
    },
    {
        "col1": "Самарская область",
        "col2": 20,
        "col3": "Комитет по управлению муниципальным имуществом Администрации муниципального района Камышлинский",
        "col4": "Самарская область, Камышлинский р-н, с.Камышла, ул.Победы 80, kumiasiya@mail.ru, 88466433247,     88466433683,"
    },
    {
        "col1": "Самарская область",
        "col2": 21,
        "col3": "Администрация муниципального района Кинельский Самарской области, Комитет по управлению муниципальным имуществом муниципального района Кинельский Самарской области",
        "col4": "446433, Самарская область, г. Кинель, ул. Ленина, 36, radmin@kinel.ru, tishina@kinel.ru, kumi@kinel.ru, 88466321176, 884663 21145, 884663 21665"
    },
    {
        "col1": "Самарская область",
        "col2": 22,
        "col3": "Отдел по жилищной политике комитета по управлению имуществом Кинель-Черкасского района",
        "col4": "446350, Самарская область, Кинель-Черкасский район, с. Кинель-Черкассы, ул. Красноармейская,69., jil_otdel@bk.ru, 88466041175"
    },
    {
        "col1": "Самарская область",
        "col2": 23,
        "col3": "МУ \"Комитет по управлению муниципальным имуществом\" муниципального района Клявлинский",
        "col4": "Самарская область, Клявлинский район, ст.Клявлино, пр.Ленина, 9, kumi.klyav@yandex.ru, 8846 53 2-11-78, 8 846 2-15-65"
    },
    {
        "col1": "Самарская область",
        "col2": 24,
        "col3": "Комитет управления муниципальным имуществом администрации муниципального района Кошкинский Самарской области",
        "col4": "Самарская обл., Кошкинский р-н, с. Кошки, ул. Советская, д. 32, kumikoshki@yandex.ru, 8(84650)2-34-52"
    },
    {
        "col1": "Самарская область",
        "col2": 25,
        "col3": "Администрация муниципального района Красноармейский Самарской области",
        "col4": "446140,Самарская область, Красноармейский район, с. Красноармейское, пл. Центральная, д. 12, KUMIKR@yandex.ru, 8(846)7521095"
    },
    {
        "col1": "Самарская область",
        "col2": 26,
        "col3": "Комитет по управлению муниципальной собственностью администрации м.р. Красноярский Самарской области",
        "col4": "Самарская область, Красноярский район, с.Красный Яр, ул. Комсомольская, д.92 А, oiokymc.kryar@mail.ru, 8 (846 57) 2-19-51"
    },
    {
        "col1": "Самарская область",
        "col2": 27,
        "col3": "Администрация муниципального района Нефтегорский",
        "col4": "Самарская обл., г. Нефтегорск, ул.Ленина, 2, neftadm@yandex.ru, 88467021543, 88467022108"
    },
    {
        "col1": "Самарская область",
        "col2": 28,
        "col3": "Администрация муниципального района Пестравский Самарской области",
        "col4": "Самарская область, Пестравский район, село Пестравка, ул. Крайнюковская, д. 84, yapestravsky@yandex.ru, 8(84674) 21233"
    },
    {
        "col1": "Самарская область",
        "col2": 29,
        "col3": "Администрация м.р. Похвистневский, Комитет по управлению муниципальным имуществом",
        "col4": "446450, Самарская область, г. Похвистнево, ул. Ленинградская, д.9, gfdtk@samtel.ru,  zilye_pohr@mail.ru, 88465621354, 88465621248"
    },
    {
        "col1": "Самарская область",
        "col2": 30,
        "col3": "Муниципальное казенное учреждение \"Комитет по управлению муниципальным имущством администрации муниципального района Приволжский Самарской области\"",
        "col4": "Самарская область, Приволжский район, с. Приволжье, ул. Парковая, д. 14, mkukumi.pv@yandex.ru, 8(846 47)92273"
    },
    {
        "col1": "Самарская область",
        "col2": 31,
        "col3": "Жилищное управление администрации муниципального района Сергиевский Самарской области",
        "col4": "446540, Самарская область, Сергиевский район, с. Сергиевск, ул. Ленина, д.15А, каб. 3,4, got_adm@mail.ru, (884655)22337, (884655)21545"
    },
    {
        "col1": "Самарская область",
        "col2": 32,
        "col3": "МКУ \"Комитет по управлению муницпальным имуществом администрации муницпального района Ставролький Самарской области\"",
        "col4": "Самарская область, город Тольятти, пл.Свободы,9, stavr-ur@mail.ru, stavr-kumi@mail.ru, 8(8482)283310, 8(8482)281567"
    },
    {
        "col1": "Самарская область",
        "col2": 33,
        "col3": "МКУ \"Управление социального развития администрации Сызранского района\"",
        "col4": "Самарская обл., Сызранский район, п. Варламово, ул. Советская, д. 14, ussofis.varlamovo@yandex.ru , 8(8464)998256"
    },
    {
        "col1": "Самарская область",
        "col2": 34,
        "col3": "Муниципальное казенное учреждение Комитет по управлению муниципальным имуществом муниципального района Хворостянский Самарской области",
        "col4": "Самарская область, Хворостянский район, с. Хворостянка, пл. Плясункова, д. 10, каб. 107, hvor-kymi@yandex.ru , 8(84677)91278"
    },
    {
        "col1": "Самарская область",
        "col2": 35,
        "col3": "МКУ Комитет по управлению муниципальным имуществом администрация муниципального района Челно-Вершинский",
        "col4": "446840, Самарская область, Челно-Вершинский район, с. Челно-Вершины, ул. Советская, д. 12, kumi.chv@mail.ru, 88465121475"
    },
    {
        "col1": "Самарская область",
        "col2": 36,
        "col3": "Комитет по управлению имуществом, архитектуры, капитального строительства и жилищно-коммунального хозяйства администрации муниципального района Шенталинский",
        "col4": "446910, Самарская область, Шенталинский район, ж/д ст. Шентала, ул. Советская, д.33, ivanov@shentala.su, 8 (846)52-2-15-36"
    },
    {
        "col1": "Самарская область",
        "col2": 37,
        "col3": "Администрация муниципального района Шигонский Самарской области ",
        "col4": "Самарская область, с. Шигоны, пл. Ленина 1, adm@shigony.samregion.ru, (884648)21556"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 1,
        "col3": "Администрация Адмиралтейского района Санкт-Петербурга",
        "col4": "Измайловский пр., д. 10,\nСанкт-Петербург, 190005\nhouse2@tuadm.gov.spb.ru\n(812) 576-83-89"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 2,
        "col3": "Администрация Василеостровского\nрайона Санкт-Петербурга\n",
        "col4": "Большой пр. В.О., д. 55,      Санкт-Петербург, 199178,\ntuvo@gov.spb.ru\n(812)576-93-78\n(812)573-93-35"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 3,
        "col3": "Администрация  Выборгского района Санкт-Петербурга ",
        "col4": "пр. Большой Сампсониевский, д.88,\nСанкт-Петербург, 194100\nzhil@tuvyb.gov.spb.ru\n(812) 241-49-85,                  (812) 576-56-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 4,
        "col3": "Администрация Калининского района Санкт-Петербурга ",
        "col4": "Арсенальная наб., д.13/1, \nСанкт-Петербург, 195009\ntukalin@gov.spb.ru               (812) 417-47-46, \n(812) 417-47-47"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 5,
        "col3": "Администрация Кировского района Санкт-Петербурга\n",
        "col4": "Стачек пр., д. 18,                           Санкт-Петербург, 198095 tukir@gov.spb.ru                          bordak@tukir.gov.spb.ru\n(812) 252-30-17\n(812) 417-69-04"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 6,
        "col3": "Администрация Колпинского района Санкт-Петербурга",
        "col4": "б-р Победы, д. 1,  г. Колпино,   Санкт-Петербург, 196653\nkaa@tukolp.gov.spb.ru, zia@tukolp.gov.spb.ru, \n(812) 573-92-05,  (812) 573-92-09"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 7,
        "col3": "Администрация Красногвардейского района Санкт-Петербурга",
        "col4": "Среднеохтинский пр., д.50, \nСанкт-Петербург, 195027 \n tukrgv@gov.spb.ru\n(812) 576-87-50"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 8,
        "col3": "Администрация Красносельского района\nСанкт-Петербурга",
        "col4": "ул. Партизана Германа, д. 3, Санкт-Петербург, 198329\nsevastvanova@tukrsl. gov.spb.ru\n(812) 576-14-38"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 9,
        "col3": "Администрация Кронштадтского района\nСанкт-Петербурга",
        "col4": "пр. Ленина, д. 36,  г. Кронштадт,  Санкт-Петербург, 197760    Zhil1@tukrns.gov.spb.ru   zhiln@tukrns.gov.spb.ru       (812) 576-90-07   (812) 576-90-28"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 10,
        "col3": "Администрация  Курортного района  Санкт-Петербурга ",
        "col4": "пл.Свободы, д.1, г.Сестрорецк,                   Санкт-Петербург, 197706    tukur@gov.spb.ru                (812)576-81-06 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 11,
        "col3": "Администрация Московского района Санкт-Петербурга",
        "col4": "Московский пр., д.129,       Санкт-Петербург, 196006 \ndilyara@tumos.gov.spb.ru\n(812) 576-89-01"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 12,
        "col3": "Администрация Невского района Санкт-Петербурга",
        "col4": "пр. Обуховской\nОбороны, д. 163,                 Санкт-Петербург, 192131\nflat@tunev.gov.spb.ru\n(812) 417-39-62                    (812) 417-39-64"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 13,
        "col3": "Администрация Петроградского района Санкт-Петербурга, ",
        "col4": "ул. Большая Монетная, д. 17-19, Санкт-Петербург, 197101\ntupetr@gov.spb.ru \n(812) 576-50-54  \n (812) 576-50-39"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 14,
        "col3": "Администрация Петродворцового района Санкт-Петербурга",
        "col4": "ул. Калининская, д.7,          г. Петергоф,                        Санкт-Петербург, 198510 \ntuptrdv@gov.spb.ru\n(812) 576-95-61                   (812) 576-95-65 "
    },
    {
        "col1": "Санкт Петербург",
        "col2": 15,
        "col3": "Администрация Приморского района Санкт-Петербурга",
        "col4": "аллея Котельникова, д.2а,                  Санкт-Петербург, 197341  \nrozanov@tuprim.gov.spb.ru\n(812)241-56-32"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 16,
        "col3": "Администрация Пушкинского района Санкт-Петербурга, \n",
        "col4": "Октябрьский бул., д. 24,      г. Пушкин,                      Санкт-Петербург, 196601\nios@tupush.gov.spb.ru\njo@tupush.gov.spb.ru\n(812) 576-92-47  (812) 576-92-15"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 17,
        "col3": "Аадминистрация Фрунзенского района Санкт-Петербурга",
        "col4": "ул. Пражская, д. 46,            Санкт-Петербург, 192241 \nisakova@tufruns.gov.spb.ru\n(812) 576-85-53"
    },
    {
        "col1": "Санкт Петербург",
        "col2": 18,
        "col3": "Администрация Центрального района Санкт-Петербурга",
        "col4": " Невский пр., д. 176,           Санкт-Петербург, 191167,\nmishin@tucentr.gov.spb.ru   (812) 417-45-93"
    },
    {
        "col1": "Саратовская область",
        "col2": 1,
        "col3": "Министерство строительства и жилищно-коммунального хозяйства Саратовской области",
        "col4": "410012, г. Саратов, ул. Челюскинцев, 114\nminstroy@saratov.gov.ru\n8(8452)74-00-47\nКонсультационный центр для детей-сирот 8(8452)74-00-47"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Комитет по управлению муниципальной собственностью Александровск-Сахалинского муниципального округа",
        "col4": "694420, Сахалинская область, г. Александровск-Сахалинский, ул. Советская, д. 7 ,тел.: 8 (42434) 4-59-13, e-mail: komitet_2003@mail.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Департамент управления муниципальным имуществом администрации Анивского муниципального округа ",
        "col4": "694030, Сахалинская область, Анивский район, г. Анива, ул. Калинина, д. 57, тел.: 8 (42441) 4-20-59,  e-mail: anivakumi@mail.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Комитет по управлению муниципальной собственностью муниципального образования Долинский муниципальный округ ",
        "col4": "694051, Сахалинская область, г. Долинск, ул. Владивостокская, д. 25, e-mail: kums_dolinsk@mail.ru, тел.: 8 (42442) 2-55-40\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Департамент имущественных отношений администрации\nКорсаковского муниципального округа\n",
        "col4": "694020, Сахалинская область,\n г. Корсаков, ул. Советская, д. 25, e-mail: dio_akgo@sakhalin.gov.ru, тел.: 8 (42435) 4-05-98\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация Курильского муниципального округа",
        "col4": "694530, Сахалинская область, г. Курильск, ул. Приморское шоссе, д. 5/1,\nтел.: (42454) 42467, e-mail: kurilsk@sakhalin.gov.ru \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация Макаровского муниципального образования ",
        "col4": "694140, Сахалинская обл., г. Макаров, ул. 50 лет Октября, д. 32, тел.: 8 (42443) 9-01-48, e-mail:  makarov@sakhalin.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": "Отдел по управлению имуществом и землепользованию администрации Невельского муниципального округа",
        "col4": "694740, Сахалинская область, г. Невельск, ул. Ленина, д. 15, тел.: 8 (42436) 6-13-09, e-mail: v.bagautdinov@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Департамент социальной политики администрации муниципального образования Ногликский муниципальный округ ",
        "col4": "694450, Сахалинская область, пгт. Ноглики, ул. Советская, д. 15, e-mail: opeka@nogliki-adm.ru, тел.: 8 (42444) 9-10-58  \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Администрация Охинского муниципального округа\n",
        "col4": "694496, Сахалинсхая область, г.Оха, ул.Ленина, д. 13,  e-mail: meriya@okha.dsc.ru, тел.: 8 (42437) 3-32-29, 8 (42437) 3-57-58\n \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Жилищный отдел Администрации Поронайского муниципального округа ",
        "col4": "694240, Сахалинская область, г. Поронайск, ул. Октябрьская, д. 61А, e-mail: poronayisk@list.ru,  тел.: 8 (42431) 5-60-03 \n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Комитет по управлению муниципальной собственностью  Северо-Курильского муниципального округа",
        "col4": "694550, Сахалинская область, г. Северо-Курильск, ул. Сахалинская, д. 34 А, e-mail: kums-sk@yandex.ru, тел.: 8 (42453) 2-10-90 \n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Комитет по управлению муниципальной собственностью Смирныховского муниципального округа ",
        "col4": "694350, Сахалинская область, пгт. Смирных, ул. Маяковского, д. 7, тел.: 8 (42452) 42-3-14, e-mail: smirnih@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Администрация Томаринского муниципального округа\n\n",
        "col4": "694820, Сахалинская область, г. Томари, ул, Калинина, д. 49 А, e-mail: tomari@adm-tomari.ru, тел.: 8 (42446) 2-67-66\n\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Администрация Тымовского муниципального округа  ",
        "col4": "694400, Сахалинская область,Тымовский район, пгт. Тымовское, ул. Кировская, д. 70, e-mail: kums-tymovsk@sakhalin.gov.ru,  тел.: 8 (42447) 9-11-01\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Управление образования Углегорского муниципального округа \n\n",
        "col4": "694920, Сахалинская область, г. Углегорск, ул. Победы, д. 142, тел.: (42432) 44-5-16, e-mail: ugo.uougo@sakhalin.gov.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Департамент по управлению муниципальным имуществом и землепользованием администрации Холмский муниципальный округ ",
        "col4": "694620, Сахалинская область, г. Холмск, ул. Пионерская, д. 14, e-mail: kholmsk.dumiz@ sakhalih.gov.ru, тел.:  8(42433)2-00-66\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Отдел образования Южно-Курильского муниципального округа",
        "col4": "694500, Сахалинская область, пгт. Южно-Курильск, пл. Ленина, д. 1, e-mail: yk.obrazov@sakhalin.gov.ru, тел.: 8 (42455) 21-074, 8 (42455) 21-345\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Департамент образования администрации города Южно-Сахалинска\n\n",
        "col4": "693000, г. Южно-Сахалинск, ул. Амурская, д. 88, e-mail: yusgo.do@sakhalin.gov.ru, тел.: 8 (4242) 3-12-680"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Министерство строительства и развития инфраструктуры Свердловской области",
        "col4": "620075, г. Екатеринбург, ул. Мамина-Сибиряка, 111 / minstroy@egov66.ru / 8 (343) 312-00-14 "
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Департамент капитального строительства города Севастополя",
        "col4": "г. Севастополь, ул. Ленина, 66, 299011 тел. +7 (8692) 55-00-76, эл. почта: dksgs@sev.gov.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 1,
        "col3": "Управление образования Администрнациии муниципального образования \"Сафоновский муниципальный округ\" Смоленской области",
        "col4": "215500, Смоленская область, г. Сафоново, ул. Ленина, д. 3  т. 8(48142)4-10-06"
    },
    {
        "col1": "Сахалинская область",
        "col2": 2,
        "col3": "Администрация муниципального образования \"Темкинский муниципальный округ\" Смоленской области",
        "col4": "215350, Смоленская область, с.Темкино, ул.Советск4ая, д.27, Temkino_OBR@admin-smolensk.ru,               8(48136)2-14-07"
    },
    {
        "col1": "Сахалинская область",
        "col2": 3,
        "col3": "Администрация муниципального образования \"Шумячский муниципальный округ\" Смоленской области",
        "col4": "216410 Смоленская область, п.Шумячи, ул.Школьна,д.1  shumichi@admin-smolensk.ru  4-12-65 "
    },
    {
        "col1": "Сахалинская область",
        "col2": 4,
        "col3": "Администрация муниципального образования \"Ельнинский муниципальный округ\" Смоленской области",
        "col4": "216330 Смоленская область, г. Ельня, ул. Советская, д. 23; e-mail admin_elnia@admin-smolensk.ru; тел. (8 481 46) 4-18-44"
    },
    {
        "col1": "Сахалинская область",
        "col2": 5,
        "col3": "Администрация муниципального образования \"Новодугинский мунициплаьный округ\" Смоленской области",
        "col4": "215240, Смоленская область, Новодугинский район, с. Новодугино, ул. 30 лет Победы, д. 2     amo-novodug@yandex.ru   (8 481 38)  2-20-31"
    },
    {
        "col1": "Сахалинская область",
        "col2": 6,
        "col3": "Администрация муниципального образования \"Сычевский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, г. Сычевка, ул. Пушкина, д.25,\nSychevka.opeka@gmail.com                                   8(48130)4-12-77\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 7,
        "col3": " Администрация муниципального образования \"Велижский муниципальный округ\" Смоленской области",
        "col4": " г.Велиж, пл. Дзержинского, д.7, тел.4-15-33 эл. Почта:velizh@admin-smolensk,ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 8,
        "col3": "Администрация муниципального образования \"Ершичский муниципальный округ\" Смоленской области",
        "col4": "216580, Смоленская область, с. Ершичи, ул. Советская, д.22, тел. 8(48155) 2-16-60, эл.почта <ershadm@yandex.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 9,
        "col3": "Комитет территориального развития, строительства и жилищно-коммунального хозяйства Администрации муниципального образования \"Глинковский муниципальный округ\" Смоленской области",
        "col4": "216320, Смоленская область, Глинковский район, с. Глинка, ул. Ленина, дл. 8, glinka@admin-smolensk.ru 8(481-65) 2-13-44"
    },
    {
        "col1": "Сахалинская область",
        "col2": 10,
        "col3": "Управление опеки и попечительства Администрации города Смоленска",
        "col4": "214000, г. Смоленск, ул. Дзержинского, д. 9/ (84812)388930,(84812)383701/ uop@smoladmin.ru,  ssv@smoladmin.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 11,
        "col3": "Администрация муниципального образования \"Угранский муниципальный округ\" Смоленской области",
        "col4": "215430, Смоленская область, село Угра, ул. Ленина, д. 38 ugra@admin-smolensk.ru? 8(48137)4-12-90"
    },
    {
        "col1": "Сахалинская область",
        "col2": 12,
        "col3": "Администрация МО \"Духовщинский муниципальный округ\" Смоленской области",
        "col4": "Смоленская область, город Духовщина, улица М.Горького, 23, 84816641707, duhovshina.opeka@yandex.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 13,
        "col3": "Администрация муниципального образования \"Хиславичский муниципальный округ\" Смоленской области",
        "col4": "216620, Смоленская область, п. Хиславичи, ул. Советская, д. 23, адрес электронной почты: hislav@admin-smolensk.ru, тел. 8(48140) 2-20-20"
    },
    {
        "col1": "Сахалинская область",
        "col2": 14,
        "col3": "Администрация муниципального образования \"Починковский муниципальный округ\" Смоленской области",
        "col4": "216450, Смоленская область,    г. Починок, ул. Советская,          д. 1/potch@admin-smolensk.ru/  8 (48149)4-11-44"
    },
    {
        "col1": "Сахалинская область",
        "col2": 15,
        "col3": "Администрация муниципального образования \"Гагаринский муниципальный округ\" Смоленской области",
        "col4": "215010, Смоленская область, г. Гагарин,  ул. Советская, д. 8; e-mail:gagarin-komitet@mail.ru, opeka.gagarin@yandex.ru; конт. тел. 8(48135)6-40-61, 6-40-68"
    },
    {
        "col1": "Сахалинская область",
        "col2": 16,
        "col3": "Администрация муниципального образования \"Ярцевский муниципальный округ\" Смоленской области",
        "col4": "8-48143-7-10-30 opeka-yarcevo@mail.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 17,
        "col3": "Администрация МО  \"Демидовский муниципальный округ \" Смоленской области\n ",
        "col4": "216240, Смоленская область, г.Демидов, ул.Коммунистическая, д.10,  https: demidov@admin-smolensk.ru.                     Т.(48147) 4-11-44"
    },
    {
        "col1": "Сахалинская область",
        "col2": 18,
        "col3": "Администрация МО «город Десногорск» Смоленской области  ",
        "col4": "г. Десногорск, 2 мкр., стр. 1/ эл. почта: kiizo@yandex.ru/ 8-48153-7-44-60, 848153-7-23-08"
    },
    {
        "col1": "Сахалинская область",
        "col2": 19,
        "col3": "Администрация МО \"Монастырщинский муниципальный округ\" Смоленской области",
        "col4": "216130, Смоленская область, п. Монастырщина, ул. 1-я Краснинская, д. 14.                 E-mail: monast@admin-smolensk.ru, т. 8 (48148) 4-18-44"
    },
    {
        "col1": "Сахалинская область",
        "col2": 20,
        "col3": "Администрация МО \"Краснинский муниципальный округ",
        "col4": "пгт. Красный,ул. Карла-Маркса, д.16,krasniy@admin-smolensk.ru, 84814541449"
    },
    {
        "col1": "Сахалинская область",
        "col2": 21,
        "col3": "Администрация МО \"Дорогобужский муниципальный округ\"",
        "col4": "г. Дорогобуж, ул. Кутузова, д.1  zemdor@mail.ru                    8 48144 41369"
    },
    {
        "col1": "Сахалинская область",
        "col2": 22,
        "col3": "Администрация МО \"Вяземский муниципальный округ\" Смоленской области",
        "col4": " 215110,  Смоленская область, г. Вязьма, ул. 25 Октября, д. 11, 8 (48131) 4–11–50, office@admin-smolensk.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 23,
        "col3": "Администрации МО \"Смоленский муниципальный округ\" Смоленской области",
        "col4": "тел./факс  8 (4812) 55-53-89\nе-mail: smolray@admin-smolensk.ru\n"
    },
    {
        "col1": "Сахалинская область",
        "col2": 24,
        "col3": "Администрация МО \"Кардымовский муниципальный округ\" Смоленской области",
        "col4": "215850, Смоленская область, пгт. Кардымово, ул. Ленина, д.  14/ kardymovo@admin-smolensk.ru/ 8 (48167) 4-11-33"
    },
    {
        "col1": "Сахалинская область",
        "col2": 25,
        "col3": "Администрация МО \"Рославльский муниципальный округ\" Смоленской области ",
        "col4": "216500, Смоленская область, г. Рославль, площадь Ленина, д. 1, 8-48134-6-84-52, administrator@roslavl.ru"
    },
    {
        "col1": "Сахалинская область",
        "col2": 26,
        "col3": "Отдел по образованию Администрации МО  \"Холм-Жирковский муниципальный округ\" Смоленской области",
        "col4": "215650\nСмоленская область, п.г.т.Холм-Жирковский , ул. Нахимовская, д.9\nE-mail: holm_otdel@mail.ru\nтел./факс:  8 (48139) 2-14-67"
    },
    {
        "col1": "Сахалинская область",
        "col2": 27,
        "col3": "Администрация муниципального образования \"Руднянский муниципальный округ в лице Отдела по экономике, управлению муниципальным имуществом и земельным отношениям",
        "col4": "Смоленская область, г.Рудня, ул.Киреева, д.93; rud_ekonomika@admin-smolensk.ru; Телефон: (48141) 4-25-69"
    },
    {
        "col1": "Ставропольский край",
        "col2": 1,
        "col3": "Министерство имущественных отношений Ставропольского края",
        "col4": "355035, г. Ставрополь, просп. Октябрьской Революции, д. 10/12, miosk@stavregion.ru (8652) 26-40-04 (8652) 74-97-78"
    },
    {
        "col1": "Тамбовская область",
        "col2": 1,
        "col3": "Министерство социальной защиты и семейной политики Тамбовской области",
        "col4": "392000, Тамбовская область, г. Тамбов, ул. Московская, 27а, email:uprsoc@uszn.tambov.gov.ru, телефон: 8(4752)791602"
    },
    {
        "col1": "Тверская область",
        "col2": 1,
        "col3": "органы местного самоуправления Администрация города Твери",
        "col4": "г. Тверь, ул. Советская, д. 11  ‎+7 (4822) 36-10-76 tveradm@adm.tver.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 2,
        "col3": "Администрация Вышневолоцкого городского округа ",
        "col4": "Тверская область,г. Вышний Волочек, Казанский проспект, д. 17,    +7 (48233) 6-41-36  admvgo@v-volok.ru  "
    },
    {
        "col1": "Тверская область",
        "col2": 3,
        "col3": "Администрация города Торжка",
        "col4": "Тверская область, г. Торжок, Новгородская наб., д.1а       8(48251) 9-12-46   admtorzhok@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 4,
        "col3": "Администрация Андреапольского муниципального округа",
        "col4": "Тверская область, г. Андреаполь, пл. Ленина, д.2    +7 (48267) 3-14-51     admandreapol@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 5,
        "col3": "Администрация Бельского муниципального округа",
        "col4": "Тверская область, г. Белый, площадь Победы, д.4   8(482508) 2-25-32    belraion@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 6,
        "col3": "Администрация Весьегонского муниципального округа",
        "col4": " Тверская область,г. Весьегонск, ул. Коммунистическая, д. 16  +7 (482 64) 2-13-05"
    },
    {
        "col1": "Тверская область",
        "col2": 7,
        "col3": "Администрация Жарковского муниципального округа",
        "col4": "Тверская область, п. Жарковский, ул.Советская, д. 54  8(48273)21503 admzharki@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 8,
        "col3": "Администрация Зубцовского муниципального округа",
        "col4": "г Зубцов, пер Образцова, д. 1а  8 (48262) 2-25-51    priemnaya@adminzubcov.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 9,
        "col3": "Администрация Калязинского  муниципального округа",
        "col4": "г. Калязин, ул. Центральная, д. 1  8(48249) 2-19-61  gorod1775@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 10,
        "col3": "Администрация Кесовогорского муниципального округа",
        "col4": "Тверская область, пгт Кесова Гора, ул Московская, д. 6  +7 (48274) 2-11-01   kesovogorskiy_okrug"
    },
    {
        "col1": "Тверская область",
        "col2": 11,
        "col3": "Администрация Конаковского муниципального округа",
        "col4": "Тверская область,г. Конаково, ул. Энергетиков, дом 13.    8(48242)49-777 konadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 12,
        "col3": "Администрация Кувшиновского муниципального округа",
        "col4": " Тверская область, г. Кувшиново, ул. Советская, 33   8 (48 257) 4 41 44   admkuvshinovo@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 13,
        "col3": "Администрация Лихославльского муниципального округа",
        "col4": "Тверская область,г. Лихославль, ул. Первомайская, д. 6          8 (48261) 3-59-41      adm@lihoslavl69.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 14,
        "col3": "Администрация Молоковского муниципального округа",
        "col4": "Тверская область, пгт Молоково, ул. Ленина,д.13    8 (48275) 2-13-61     molokovskiy_reg@tverreg.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 15,
        "col3": "Администрация Оленинского муниципального округа",
        "col4": "Тверская область,Оленинский муниципальный округ, пгт. Оленино, ул. Октябрьская, д. 7     8(48258) 2-14-02, 2-13-36     oleninoadm@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 16,
        "col3": "Администрация Пеновского муниципального округа",
        "col4": "Тверская область, п. Пено, ул. 249 Стр.дивизии, д.33    8(48230) 2-39-58    penoraion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 17,
        "col3": "Администрация Селижаровского муниципального округа",
        "col4": "Тверская область, пгт Селижарово, ул. Ленина, д.12    8(48269) 225-80     selmuno@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 18,
        "col3": "Администрация Спировского муниципального округа",
        "col4": "Тверская область, п.Спирово, пл.Советская, д.5   8(48276) 2-11-39     spirovo-raion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 19,
        "col3": "Администрация Торжокского района",
        "col4": "Тверская область,г. Торжок, ул. Луначарского, д. 2   8(48251) 9-22-50   torzhokadm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 20,
        "col3": "Администрация Удомельского городского округа",
        "col4": "Тверская область,г. Удомля, ул. Попова, д.22  8 (48255) 5 43 35  udom_okrug@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 21,
        "col3": "Администрация Ржевского муниципального округа",
        "col4": "Тверская область,г. Ржев, ул. Партизанская, д.33    8(48232) 2-09-15   info@городржев.рф"
    },
    {
        "col1": "Тверская область",
        "col2": 22,
        "col3": "Администрация Кимрского муниципального округа",
        "col4": "Тверская область, г. Кимры,ул. Кирова д.18   8(48236) 2-16-66    admin@adm-kimry.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 23,
        "col3": "Администрация Бежецкого муниципального округа",
        "col4": "Тверская область,г. Бежецк, Первомайский переулок, дом 21   8 (48231) 2-15 -81  admbezh@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 24,
        "col3": "Администрация Бологовского муниципального округа",
        "col4": "Тверская область,г. Бологое, ул.Кирова, 13   +7 (48238) 2-22-11     rbologoe@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 25,
        "col3": "Администрация Западнодвинского муниципального округа",
        "col4": "Тверская область,г. Западная Двина, ул. Кирова, д. 10    8(48265) 2-17-30    westerndvina.22@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 26,
        "col3": "Администрация Калининского муниципального округа",
        "col4": "г.Тверь, набережная реки Лазури, д.3  8 (4822) 45-38-39   mail@kalinin-mo.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 27,
        "col3": "Администрация Кашинского городского округа",
        "col4": " Тверская область,г. Кашин, ул. Анатолия Луначарского, дом 20  8 (48234) 2-06-68, kashin@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 28,
        "col3": "Администрация Краснохолмского муниципального округа",
        "col4": "Тверская область,г. Красный Холм, пл. Карла Маркса, д. 10,  8(48237)2 23 21  adm.krholm@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 29,
        "col3": "Администрация Лесного муниципального округа",
        "col4": "Тверская область, село Лесное, ул. Советская, д.9   8(48271)2-11-45   adminlesnoe69@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 30,
        "col3": "Администрация Максатихинского муниципального округа",
        "col4": "Тверская область,Максатихинский муниципальный округ, пгт. Максатиха, ул. Красноармейская, д. 5.   +7 (48 253) 2-13-12.   maksatiha@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 31,
        "col3": "Администрация Нелидовского городского округа",
        "col4": "Тверская обл., г. Нелидово, пл. Ленина, 3  8 (48266) 53444   gorodnelidovo@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 32,
        "col3": "Администрация Осташковского городского округа",
        "col4": " Тверская область,г.Осташков, Ленинский пр-т, д.46, помещение 1   8(48235)5-16-65  аdmostregion@yandex.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 33,
        "col3": "Администрация Рамешковского муниципального округа",
        "col4": "Тверская область, пгт Рамешки, ул. Советская, д.20 8(48244)2-13-04   admrameshki@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 34,
        "col3": "Администрация Сандовского мунипального округа",
        "col4": "Тверская область, п.Сандово, ул.Советская, д.11    8 (48272) 2-11-45  sandovo@tvobl.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 35,
        "col3": "Администрация Сонковского муниципального округа",
        "col4": "Тверская область, пгт. Сонково, пр-т Ленина, д. 24   84824621354   admsonkovo@bk.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 36,
        "col3": "Администрация Старицкого муниципального округа",
        "col4": "Тверская область,г. Старица, ул. Советская, д. 6.    8(48263)21-623   starica_adm@mail.ru"
    },
    {
        "col1": "Тверская область",
        "col2": 37,
        "col3": "Администрация Торопецкого муниципального округа",
        "col4": "Тверская область,г. Торопец, ул.Октябрьская, д. 53   8(48268)2-12-97   Toropec69adm@yandex.ru "
    },
    {
        "col1": "Тверская область",
        "col2": 38,
        "col3": "Администрация Фировского муниципального округа",
        "col4": "Тверская область,пос. Фирово ул. Советская, д.21   8(48-239)3-19-80  glavafirovo@mail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 1,
        "col3": "Александровское  сельское поселение",
        "col4": "636760, Томская область, Александровский район, с.Александровское, ул.Лебедева, д. 30 alsaleks@tomsk.gov.ru,              8 (38255)2-54-30"
    },
    {
        "col1": "Томская область",
        "col2": 2,
        "col3": " Лукашкин-Ярское сельское поселение",
        "col4": "636764, Томская область, Александровский район,\nс. Лукашкин Яр,\nул. Центральная, д. 27 alsluk@tomsk.gov.ru     8(38255)4-33-16, 2-42-40\n\n"
    },
    {
        "col1": "Томская область",
        "col2": 3,
        "col3": "Назинское сельское поселение",
        "col4": "636765, Томская область, Александровский район, с.Назино, пер.Центральный, д.2 /alsnaz@tomsk.gov.ru/\n8(382 55)4-21-30\n"
    },
    {
        "col1": "Томская область",
        "col2": 4,
        "col3": "Новоникольское сельское поселение",
        "col4": "636766, Томская область, Александровский район, с.Новоникольское, пер.Школьный, д. 3 alsnik@tomsk.gov.ru.\n8 (38255)4-11-24,4-11-25\n"
    },
    {
        "col1": "Томская область",
        "col2": 5,
        "col3": "Октябрьское сельское поселение",
        "col4": "636767, Томская область, Александровский район,\n с. Октябрьское, ул.Лесная, д.11 alsokt@tomsk.gov.ru.\n8-983-343-71-80\n"
    },
    {
        "col1": "Томская область",
        "col2": 6,
        "col3": "Северное сельское поселение",
        "col4": "636763, Томская область, Александровский район,\n с. Северное, ул.Дорожная, д.5 alssev@tomsk.gov.ru.\n                   8-923-441-1366\n"
    },
    {
        "col1": "Томская область",
        "col2": 7,
        "col3": "Асиновское городское поселение",
        "col4": "636840, Томская область, г. Асино, ул.  имени Ленина, 40/ adminpos@asino.tomsknet.ru/\n8(38241)22498,\n8(38241)21748\n"
    },
    {
        "col1": "Томская область",
        "col2": 8,
        "col3": " Большедороховское сельское поселение",
        "col4": "636803, Томская область, Асиновский район, с. Больше-Дорохово, ул. Центральная, д. 26/\nb-dorokhovo-sp@asino.gov70.ru\n/ 8(38241)47205\n"
    },
    {
        "col1": "Томская область",
        "col2": 9,
        "col3": "Батуринское сельское поселение",
        "col4": "636820 Томская область, Асиновский район, с.Батурино, ул. Клубная ,34/\nbaturino-sp@asino.gov70.ru/\n8(38241)41155\n"
    },
    {
        "col1": "Томская область",
        "col2": 10,
        "col3": "Новокусковское сельское поселение",
        "col4": "636810 Томская обл., Асиновский р-он, с.Ново-Кусково, ул.Школьная, д.55/ nkselp@mail.tomsknet.ru/\n8(38241)45045;\n8(38241) 45001\n"
    },
    {
        "col1": "Томская область",
        "col2": 11,
        "col3": "Новиковское сельское поселение",
        "col4": "636830 Томская область Асиновский район с. Новиковка ул. Советская д.14 /\nadm-novikovka@asino.gov70.ru\n/8(38241)44166; \n8(38241)44220\n"
    },
    {
        "col1": "Томская область",
        "col2": 12,
        "col3": "Новониколаевское сельское поселение",
        "col4": "636813 Томская область Асиновский район, с.Новониколаевка, ул. Школьная, д.30, / n-nikolaevka-sp@asino.gov70.ru/ 8(38)24142159; 8(38)24142117"
    },
    {
        "col1": "Томская область",
        "col2": 13,
        "col3": "Ягодное сельское поселение",
        "col4": "636806 Томская обл., Асиновский р-он, с.Ягодное, ул.Школьная, д.1 Г/ yagodnoe-sp@asino.gov70.ru/\n8 (38241)43537\n"
    },
    {
        "col1": "Томская область",
        "col2": 14,
        "col3": "Бакчарское сельское поселение",
        "col4": "636200. Томская область, Бакчарский район, с. Бакчар ул. Ленина д. 53, \n baksp@tomsk.gov.ru, 8(38249)\n22-284\n"
    },
    {
        "col1": "Томская область",
        "col2": 15,
        "col3": "Вавиловское сельское поселение",
        "col4": "636202, Томская область, Бакчарский район, с. Вавиловка, ул. Центральная д.2, vavilsp@tomsk.gov.ru, 8 (38249) 37-295"
    },
    {
        "col1": "Томская область",
        "col2": 16,
        "col3": "Парбигское сельское поселение",
        "col4": "636220,Томская область, Бакчарский район, с. Парбиг, ул. Советская д. 55, parbigsp@tomsk.gov.ru, 8 (38249) 44-384"
    },
    {
        "col1": "Томская область",
        "col2": 17,
        "col3": "Высокоярское сельское поселение",
        "col4": "636225, Томская область, Бакчарский район, с. Высокий Яр, пер. Трактовый, д. 7, vsp.06@mail.ru, 8 (38249) 38-124"
    },
    {
        "col1": "Томская область",
        "col2": 18,
        "col3": "Плотниковское сельское поселение",
        "col4": "636210, Томская область, Бакчарский район, с. Плотниково, ул. Школьная д. 4, plotsp@tomsk.gov.ru, 8 (38249) 39-436"
    },
    {
        "col1": "Томская область",
        "col2": 19,
        "col3": "Поротниковское сельское поселение",
        "col4": "636213, Томская область, Бакчарский район, с Поротниково, ул. Советская, д.19, portsp@tomsk.gov.ru, 8 (38249) 36-138"
    },
    {
        "col1": "Томская область",
        "col2": 20,
        "col3": "Белоярское городское поселение",
        "col4": "636500,Томская область, Верхнекетский район, р.п.Белый Яр, ул.Гагарина,47стр.1, upravdel.admbel@mail.ru тел.8-38258-2-56-82"
    },
    {
        "col1": "Томская область",
        "col2": 21,
        "col3": "Катайгинское сельское поселение",
        "col4": "636518, Томская область, Верхнекетский район, п.Катайга, ул.Кирова, д.39\"А\"  \n (38258) 3-31-38\n sakat@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 22,
        "col3": "Клюквинское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Клюквинка, ул. Центральная, д. 13\n8 (38258) 24-136\nsaklk@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 23,
        "col3": " Макзырское сельское поселение",
        "col4": "636511, Томская область, Верхнекетский район, п. Лисица, ул. Таежная, д. 16\n8 (38258) 35-148\nmsadm@seversk.tomsknet.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 24,
        "col3": "Орловское сельское поселения",
        "col4": "636513, Томская область, Верхнекетский район, п.Центральный, пер.Школьный, д.11.\n (38258) 37-2-26,  saorl@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 25,
        "col3": "Палочкинское сельское поселение",
        "col4": "636506, Томская область, Верхнекетский район, село Палочка, ул. Молодёжная, д. 26\n palsp@tomsk.gov.ru\n 8 (38 )258 -34-136\n"
    },
    {
        "col1": "Томская область",
        "col2": 26,
        "col3": "Степановское сельское поселение",
        "col4": "636516 Томская обл., Верхнекетский р-н п. Степановка, пер.Аптечный,д.4 sastp@tomsk.gov.ru/ 83825825136"
    },
    {
        "col1": "Томская область",
        "col2": 27,
        "col3": "Администрация Сайгинское сельское поселение",
        "col4": "636520 Томская обл., Верхнекетский район,  п. Сайга ул. Молодогвардейская, д. 5/ sasay@tomsk.gov.ru/ 83825836136"
    },
    {
        "col1": "Томская область",
        "col2": 28,
        "col3": "Ягоднинское сельское поселение",
        "col4": "636521 Томская обл., Верхнекетский район, п. Ягодное, Октябрьская,1 saber@tomsk.gov.ru/ 83825832280"
    },
    {
        "col1": "Томская область",
        "col2": 29,
        "col3": "Высоковского сельского поселения ",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 30,
        "col3": "Дубровское сельское поселение",
        "col4": "636852 Томская область, Зырянский район, с.Дубровка, ул. Фрунзе, д.38\n (адрес электронной почты: dubrovka@tomsk.gov.ru; тел. 8(38243) 34131)\n"
    },
    {
        "col1": "Томская область",
        "col2": 31,
        "col3": "Зырянское сельское поселение",
        "col4": "636850 Томская область, Зырянский район, с.Зырянское, ул.Советская, д.10\n(адрес электронной почты: zirselo@tomsk.gov.ru; тел. 8(38243) 38149)\n"
    },
    {
        "col1": "Томская область",
        "col2": 32,
        "col3": "Михайловское сельское поселение",
        "col4": "636855 Томская область, Зырянский район, с.Михайловка, ул.Гончарова, д.44\n(адрес электронной почты: mihaylsp@tomsk.gov.ru; тел. 8(38243) 36222).\n"
    },
    {
        "col1": "Томская область",
        "col2": 33,
        "col3": "Чердатское сельское поселение",
        "col4": "636859 Томская область, Зырянский район, с.Чердаты, ул.Советская, 1а\n(адрес электронной почты: cherdsp@tomsk.gov.ru; тел. 8(38243) 32137)\n"
    },
    {
        "col1": "Томская область",
        "col2": 34,
        "col3": "Вертикосское сельское поселение",
        "col4": "636753, Томская область, Каргасокский район, с. Вертикос, ул. Молодежная,1/ mkuvertikos@yandex.ru/\n8(38253)  36-189;\n"
    },
    {
        "col1": "Томская область",
        "col2": 35,
        "col3": "Каргасокское сельское поселение",
        "col4": "636700, Томская область, Каргасокский район,  с. Каргасок, ул. Новая,1\nkargs@tomsk.gov.ru\n8 (38253)23 423\n"
    },
    {
        "col1": "Томская область",
        "col2": 36,
        "col3": "Нововасюганское сельское поселение",
        "col4": "636740, Томская область, Каргасокский район, с. Новый Васюган, ул. Советская,49/\nnovyjvasiugan@yandex.ru/\n 8 (38253) 29 284\n"
    },
    {
        "col1": "Томская область",
        "col2": 37,
        "col3": "Новоюгинское сельское           поселение",
        "col4": "636714,, Томская область, Каргасокский район,  с. Новоюгино, ул. Цетральная, 44-2/\nansp06@mail.ru/ \n 8 (38253) 37-132\n"
    },
    {
        "col1": "Томская область",
        "col2": 38,
        "col3": "Средневаюганское сельское поселение",
        "col4": " 636733, Томская область, Каргасокский район, с. Средний Васюган, ул. Гагарина,6/\nsvasugan@mail.ru/ \n8 (38253) 25-172\n"
    },
    {
        "col1": "Томская область",
        "col2": 39,
        "col3": "Среднетымское сельское поселение",
        "col4": " 636754, Томская область, Каргасокский район, п. Молодежный, ул. Школьная, 2/ sredtympos@yandex.ru/  8 (38253) 44-146                                                  "
    },
    {
        "col1": "Томская область",
        "col2": 40,
        "col3": "Сосновское сельское поселение",
        "col4": "636712, Томская область, Каргасокский  с.Сосновка, ул. Школьная, 18/ sosnovka.a@yandex.ru/\n 8 (38253) 38-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 41,
        "col3": "Вороновское сельское поселение",
        "col4": "636171, Томская область, Кожевниковский район, с. Вороново, ул. Уткина, д. 17 \n voronovosp@mail.ru\n(838244) 31-169; (838244) 31-148\n"
    },
    {
        "col1": "Томская область",
        "col2": 42,
        "col3": "Кожевниковское сельское поселение",
        "col4": "636160, Томская область, Кожевниковский район, с. Кожевниково, ул. Гагарина, д. 24\n kozhev_posel@mail.ru\n(838244) 44-745; (838244) 21-961\n"
    },
    {
        "col1": "Томская область",
        "col2": 43,
        "col3": "Малиновское сельское поселение",
        "col4": "636176, Томская область, Кожевниковский район, с. Малиновка, ул. Школьная, д. 13 MalinovkaSP@mail.ru\n(838244) 53-146\n"
    },
    {
        "col1": "Томская область",
        "col2": 44,
        "col3": "Новопокровское сельское поселение",
        "col4": "636182, Томская область, Кожевниковский район, с. Новопокровка, ул. Садовая, д. 2а voronovosp@mail.ru\n(838244) 58-125, (838244) 58-134\n"
    },
    {
        "col1": "Томская область",
        "col2": 45,
        "col3": "Песочнодубровское сельское поселение",
        "col4": "636164, Томская область, Кожевниковский район, с. Песочнодубровка, ул. Молодежная, д. 37 \npesoch@tomsk.gov.ru\n(838244) 42-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 46,
        "col3": "Староювалинское сельское поселение",
        "col4": "636165, Томская область, Кожевниковский район, с. Старая Ювала, ул. Ульяновская, д. 3а\n yuvala@mail.ru, stuval@tomsk.gov.ru\n(838244) 41-327; (838244) 41-162\n"
    },
    {
        "col1": "Томская область",
        "col2": 47,
        "col3": "Уртамское сельское поселение",
        "col4": "636170, Томская область, Кожевниковский район, с. Уртам, ул. Фрунзе, д. 33 \nurtam@tomsk.gov.ru\n(838244) 51-351\n"
    },
    {
        "col1": "Томская область",
        "col2": 48,
        "col3": "Чилинское сельское поселение",
        "col4": "636172, Томская область, Кожевниковский район, с. Чилино, ул. Кустарная, д. 1 \nchilino@mail.ru\nchilino@kozhevnikovo.gov70.ru\nlarisa.jul2015@yandex.ru\n8.6(838244) 35-407, (838244)35-332\n"
    },
    {
        "col1": "Томская область",
        "col2": 49,
        "col3": "Колпашевское городское поселение",
        "col4": "636460, Томская область, Колпашевский район, г. Колпашево, ул. Победы, д. 5\nkolpash@tomsk.gov.ru\n8 (38254) 5 82 62\n"
    },
    {
        "col1": "Томская область",
        "col2": 50,
        "col3": "Инкинское сельское поселение",
        "col4": "636443,Томская область, Колпашевский район,  д. Инкино, пер. Кооперативный 11\ninkino@tomsk.gov.ru\n8 (38254) 9-31-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 51,
        "col3": "Новогоренское сельское поселение",
        "col4": "636444, Томская область, Еолпашевский район, д. Новогорное, ул. Береговая 42, n-gorins@tomsk.gov.ru \n8 (38254) 9 41 36\n"
    },
    {
        "col1": "Томская область",
        "col2": 52,
        "col3": "Новоселовское сельское поселение",
        "col4": "636425, Томская область, Колпашевский район,  с. Новоселово, ул. Центральная 11/1\nn-selovs@tomsk.gov.ru\n8 (38254) 22 160\n"
    },
    {
        "col1": "Томская область",
        "col2": 53,
        "col3": "Саровское сельское поселение",
        "col4": "636431, Томская область, Колпашевский район, п. Большая Саровка, ул. Советская 35/2\nsarovka@tomsk.gov.ru\n8 (38254) 2-74-36\n"
    },
    {
        "col1": "Томская область",
        "col2": 54,
        "col3": "Чажемтовское сельское поселение",
        "col4": "636423,Томская область, Колпашевский район,  с. Чажемто, ул. Ленина 24\nchagemto@tomsk.gov.ru, 8 (38254) 2 12 42\n"
    },
    {
        "col1": "Томская область",
        "col2": 55,
        "col3": "Володинское сельское поселение",
        "col4": "636310, Томская область, Кривошеинский  район,  с. Володино, ул. Советская, 31, volodino@tomsk.qov.ru, 8(38251) 45-433"
    },
    {
        "col1": "Томская область",
        "col2": 56,
        "col3": "Иштанское сельское поселение",
        "col4": "636312 Томская область Кривошеинский район с. Иштан, ул. Лесная 1А, ishtan00@yandex.ru, 8(38251) 43-495"
    },
    {
        "col1": "Томская область",
        "col2": 57,
        "col3": "Красноярское сельское поселение",
        "col4": "636320, Томская область, Кривошеинский район, с. Красный Яр, ул. Советская, 60, krasyar@tomsk.gov.ru, 8(38251) 31-330"
    },
    {
        "col1": "Томская область",
        "col2": 58,
        "col3": "Новокривошеинское сельское поселение",
        "col4": "636307, Томская область, Кривошеинский район, с. Новокривошеино, ул. Советская, 1, novokriv@yandex.ru, 8(38251) 47-433"
    },
    {
        "col1": "Томская область",
        "col2": 59,
        "col3": "Петровское сельское поселение",
        "col4": "636313, Томская область, Кривошеинский район, Петровка, ул. Комсомольская,12, реtrovka@tomsk.gov.ru, 8(38251) 44-416"
    },
    {
        "col1": "Томская область",
        "col2": 60,
        "col3": "Пудовское сельское поселение",
        "col4": "636316, Томская область, Кривошеинский район, с. Пудовка, ул. Центральная, 64, pudovka@tomsk.gov.ru, 8(38251) 46-431"
    },
    {
        "col1": "Томская область",
        "col2": 61,
        "col3": "Могочинское сельское поселение",
        "col4": "636340, Томская область, Молчановский район, с. Могочино, ул. Заводская, 3, +7 (38256) 3‒31‒32,  admogochino@sibmail.ru"
    },
    {
        "col1": "Томская область",
        "col2": 62,
        "col3": "Молчановское сельское поселение",
        "col4": "636330, Томская область, Молчановский район, с. Молчаново, ул. Димитрова, 51, т. 8 (38256) 21-5-86, ml-molch@tomsk.gov.ru "
    },
    {
        "col1": "Томская область",
        "col2": 63,
        "col3": "Наргинское сельское поселение",
        "col4": "636346, Томская область, Молчановский район, с. Нарга, ул. К. Маркса, 41, adm-narga@molchanovo.gov70.ru\n8 (256) 32-4-95\n"
    },
    {
        "col1": "Томская область",
        "col2": 64,
        "col3": "Суйгинское сельское поселение",
        "col4": "636345 Томская область Молчановский район, с. Суйга, ул. Комарова, 29, suiga@molchanovo.gov70"
    },
    {
        "col1": "Томская область",
        "col2": 65,
        "col3": "Тунгусовское  сельское поселение",
        "col4": "636353, Томская область, Молчановский район, с. Тунгусово, ул. Кнакиса, 5, +7 (382-56) 35-4-37,  tungusovosp@molchanovo.gov70.ru"
    },
    {
        "col1": "Томская область",
        "col2": 66,
        "col3": "Заводское сельское поселение",
        "col4": "636608, Томская область, Парабельский район\nп. Заводской, ул. 60 лет СССР, 19/\n8(38252)3-91-66, 8(38252)3-91-34/ zavodskoe@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 67,
        "col3": "Нарымское сельское поселение",
        "col4": "636611, Томская область,  Парабельский район, \nс. Нарым, ул. Сибирская, 2/\n8(38252)3-32-31, 8(38252)3-32-32/ narim@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 68,
        "col3": "Новосельцевское сельское поселение",
        "col4": "636607, Томская область, Парабельский район,\n с. Новосельцево , ул. Шишкова, 5/ 8(382525)3-61-58, 8(38252)3-61-58/\nn-selcevo@parabel.gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 69,
        "col3": "Парабельское сельское поселение",
        "col4": "636600, Томская область, Парабельский рвйон, \nс. Парабель , ул. Свердлова, 5/ 8(382525)2-19-63, 8(38252)2-13-43/\n      Sp-parab@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 70,
        "col3": "Старицинское сельское поселение",
        "col4": "636626, Томская область, Парабельский район, \nс. Старица, ул. Советская, 58 «а»/ 8-913-848-19-09/ istomina-1k@yandex.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 71,
        "col3": "Комсомольское сельское поселение",
        "col4": "636942, Томская область, Первомайский район, с. Комсомольск, ул. Железнодорожная 40/1, volkova.azlk1214@yandex.ru, 8(38245)23-249"
    },
    {
        "col1": "Томская область",
        "col2": 72,
        "col3": "Куяновское сельское поселение",
        "col4": "636953, Томская область, Первомайский район, село Куяново, ул. Центральная, 18/1, kuspmail@mail.ru, тел. 8 (38245) 33-198"
    },
    {
        "col1": "Томская область",
        "col2": 73,
        "col3": "Новомариинское сельское поселение",
        "col4": "636935 Томская обл., Первомайский район, с. Новомариинка, ул. Центральная, 42, novosp@tomsk.gov.ru, 8 (38245) 35-123"
    },
    {
        "col1": "Томская область",
        "col2": 74,
        "col3": "Первомайское сельское поселение",
        "col4": "636930, Томская область, Первомайский район, с. Первомайское, ул. Советская, д.14, pmsp@tomsk.gov.ru, 8 (38245)21-060"
    },
    {
        "col1": "Томская область",
        "col2": 75,
        "col3": "Сергеевское сельское поселение",
        "col4": "Сергеевское сельское поселение: 636941, Томская область, Первомайский район, с. Сергеево, ул. Школьная д. 3, sergeevo@tomsk.gov.ru, 8 (38245) 43-320"
    },
    {
        "col1": "Томская область",
        "col2": 76,
        "col3": "Улу-Юльское сельское поселение",
        "col4": "636948, Томская область, Первомайский район, поселок Улу-Юл, улица 50 Лет Октября, д.5 oks4na5mir@yandex.ru, тел. 8 (38245) 44-123"
    },
    {
        "col1": "Томская область",
        "col2": 77,
        "col3": "Белоярское сельское поселение",
        "col4": "636909, Томская область, Тегульдетский район, \nп. Белый Яр, ул. Центральная, 2\nТелефон: +7 (382-46) 3-41-15,\nE-mail: belyarsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 78,
        "col3": "Берегаевское сельское поселение",
        "col4": "636911, Томская область, Тегульдетский район,\nп. Берегаево, ул. Ленинская, д. 17а\nТелефон: +7 (382-46) 3-31-89,\nE-mail: beregsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 79,
        "col3": "Тегульдетское сельское поселение",
        "col4": "636900, Томская область, Тегульдетский район, \nс. Тегульдет, ул. Ленина, 156\nТелефон: +7 (382–46) 2-19-25, 2-19-13,\nE-mail: tegsp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 80,
        "col3": "Черноярское сельское поселение",
        "col4": "636902, Томская область, Тегульдетский район, \nп. Черный Яр, ул. Комсомольская, 7\nТелефон: +7 (382–46) 3-11-42,\nE-mail: blacksp@tomsk.gov.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 81,
        "col3": "Богашевское сельское поселение",
        "col4": "634570, Томская область, Томский район, с. Богашево, ул.Советская, 6, / \nадрес электронной\nпочты: bogashevo-sp@tomsky.gov70.ru/\nномер телефона 931-269\n"
    },
    {
        "col1": "Томская область",
        "col2": 82,
        "col3": "Воронинское сельское поселение",
        "col4": "634532, Томская область, Томский район, д. Воронино, ул. Центральная, 74/ \nадрес электронной\nпочты: voronino-sp@tomsky.gov70.ru/\nномер телефона 950-274\n"
    },
    {
        "col1": "Томская область",
        "col2": 83,
        "col3": "Заречное сельское поселение",
        "col4": "634521, Томская область, Томский район,  с.Кафтанчиково, ул.Коммунистическая, д.86 \nадрес электронной\nпочты: zarechnoe-sp@tomsky.gov70.ru /\nномер телефона 961-200\n"
    },
    {
        "col1": "Томская область",
        "col2": 84,
        "col3": "Зональненское сельское поселение",
        "col4": "634507, Томская область, Томский район, \nп.Зональная, ул.Совхозная, 10 / \nадрес электронной\nпочты: zonalniy-sp@tomsky.gov70.ru /\nномер телефона 923-969\n"
    },
    {
        "col1": "Томская область",
        "col2": 85,
        "col3": "Зоркальцевское сельское поселение",
        "col4": "634515, Томская область, Томский район, с. Зоркальцево, ул. Совхозная, 14/ \nадрес электронной\nпочты: zorkalsp@gov70.ru/\nномер телефона 915-790\n"
    },
    {
        "col1": "Томская область",
        "col2": 86,
        "col3": "Итатское сельское поселение",
        "col4": "634540, Томская область, Томский район, с. Итатка, ул. Гагарина,1 / \nадрес электронной\nпочты: itatkasp@gov70.ru /\nномер телефона 959-323\n"
    },
    {
        "col1": "Томская область",
        "col2": 87,
        "col3": "Калтайское сельское поселение",
        "col4": "634523, Томская область, Томский район, с. Курлек, ул. Трактовая, 48  / \nадрес электронной\nпочты: kaltay-sp@tomsky.gov70.ru, kaltai.adm.fin@mail.ru /\nномер телефона 968-293\n"
    },
    {
        "col1": "Томская область",
        "col2": 88,
        "col3": "Копыловское сельское поселение",
        "col4": "634537, Томская область, п. Копылово, ул. Новая, 13/\nадрес электронной\nпочты: kopylovosp@gov70.ru/\nномер телефона 989-101\n"
    },
    {
        "col1": "Томская область",
        "col2": 89,
        "col3": "Корниловское сельское поселение",
        "col4": "634538, Томская область, Томский район, с.Корнилово,  ул.Гагарина, 29А,/ \nадрес электронной\nпочты: kornilovosp@gov70.ru/\nномер телефона 963-171\n"
    },
    {
        "col1": "Томская область",
        "col2": 90,
        "col3": "Малиновское сельское поселение",
        "col4": "634580, Томская область, Томский район, с. Малиновка, ул. Чулымская, 30 / \nадрес электронной\nпочты: malinovka-sp@tomsky.gov70.ru/\nномер телефона 920-144\n"
    },
    {
        "col1": "Томская область",
        "col2": 91,
        "col3": "Межениновское сельское поселение",
        "col4": "634520, Томская область, Томский район,  с.Межениновка, ул.Первомайская, 23, / \nадрес электронной\nпочты: mezheninsp@gov70.ru/\nномер телефона 969-725\n"
    },
    {
        "col1": "Томская область",
        "col2": 92,
        "col3": "Мирненское сельское поселение",
        "col4": "634539, Томская область, Томский район, п. Мирный, ул. Трудовая, 10 / \nадрес электронной\nпочты: mirniy-sp@tomsky.gov70.ru/\nномер телефона 955-198\n"
    },
    {
        "col1": "Томская область",
        "col2": 93,
        "col3": "Моряковское сельское поселение",
        "col4": "634516, Томская облатсь, Томский район, с. Моряковский Затон, ул. Советская, 27/ \nадрес электронной\nпочты: morzaton-sp@tomsky.gov70.ru/\nномер телефона 927-498\n"
    },
    {
        "col1": "Томская область",
        "col2": 94,
        "col3": "Наумовское сельское поселение",
        "col4": "634581, Томская область, Томский рай1он, с. Наумовка, ул. Советская, 7А/ \nадрес электронной\nпочты: naumovkasp@gov70.ru /\nномер телефона 969-339\n"
    },
    {
        "col1": "Томская область",
        "col2": 95,
        "col3": "Новорождественское сельское поселение",
        "col4": "634535, Томская область, Томский район, с.Новорождественское, ул. Советская, 62/ \nадрес электронной\nпочты: n-rozhdestven-sp@tomsky.gov70.ru/\nномер телефона 946-589\n"
    },
    {
        "col1": "Томская область",
        "col2": 96,
        "col3": "Октябрьское сельское поселение",
        "col4": "634583, Томская область, Томский район, с. Октябрьское, ул. Заводская,14/ адрес электронной\nпочты: oktyabr-sp@tomsky.gov70.ru /\nномер телефона 925-211\n"
    },
    {
        "col1": "Томская область",
        "col2": 97,
        "col3": "Рыболовское сельское поселение",
        "col4": "634518, Томская область, Томский район, с.Рыбалово, ул.Коммунистическая, 7,/ \nадрес электронной\nпочты: rybalovo-sp@tomsky.gov70.ru /\nномер телефона 919-131\n"
    },
    {
        "col1": "Томская область",
        "col2": 98,
        "col3": "Спасское сельское поселение",
        "col4": "пер.Новый, с.Вершинино, Томский район, Томская область, 634528, Томская область, Томский район, с. Вершинино, пер. Новый / \nадрес электронной\nпочты: spasskoe-sp@tomsky.gov70.ru /\nномер телефона 959-608\n"
    },
    {
        "col1": "Томская область",
        "col2": 99,
        "col3": "Турунтаевское сельское поселение",
        "col4": "634534, Томская область, Томский район, с. Турунтаево, ул. Новая, 2а / \nадрес электронной\nпочты: turuntaevo-sp@tomsky.gov70.ru /\nномер телефона 947-175\n"
    },
    {
        "col1": "Томская область",
        "col2": 100,
        "col3": "Коломинское сельское поселение",
        "col4": "636410, Томская область, Чаинский район, с. Коломинские Гривы, ул. Советская, 21/ kolomin@tomsk.gov.ru/ 8(38257) 45244"
    },
    {
        "col1": "Томская область",
        "col2": 101,
        "col3": "Подгорнское сельское поселение",
        "col4": "636400, Томская область, Чаинский район, \nс. Подгорное, ул. Ленинская, 4, стр. 1/ podgorns@tomsk.gov.ru/ 8(38257) 21102\n"
    },
    {
        "col1": "Томская область",
        "col2": 102,
        "col3": "Усть-Бакчарское сельское поселение",
        "col4": "636404, Томская область, Чаинский район, \nс. Усть-Бакчар, ул. Центральная, 17/ inna_novokreshenova@mail.ru/ \n8(38257) 35167\n"
    },
    {
        "col1": "Томская область",
        "col2": 103,
        "col3": "Чаинское сельское поселение",
        "col4": "636407,Томская область, Чаинский район, \nс. Чаинск, ул. Комсомольская, 14/ chainsksp@mail.ru/ 8(38257) 56119\n"
    },
    {
        "col1": "Томская область",
        "col2": 104,
        "col3": "Анастасьевское сельское поселение",
        "col4": "636149, Томская область. Шегарский район,\nс. Анастасьевка, пер. Школьный, д.2\nanastasevka05@mail.ru\n8(38247)3-91-37\n"
    },
    {
        "col1": "Томская область",
        "col2": 105,
        "col3": "Баткатское сельское поселение",
        "col4": "636141, Томская область, Шегарский район,\nс. Баткат, пер. Кооперативный, д.1\nadm-batkat@shegarsky.gov70.ru\n8(38247) 341-32, 8(38247)341-09\n"
    },
    {
        "col1": "Томская область",
        "col2": 106,
        "col3": "Побединское сеельское поселение ",
        "col4": "636143, п. Победа, Шегарский район,\nТомская область,\nул. Коммунистическая д.112а, помещение 2\nssamotoylov@mail.ru\npobeda@tomsk.gov.ru\n8(38247) 42-195, 8(38247) 42-264\n"
    },
    {
        "col1": "Томская область",
        "col2": 107,
        "col3": "Северное сельское поселение",
        "col4": "636156,Томская область, Шегарский район,\nс. Монастырка, ул. Советская, д.51а\nadm-severnoe@shegarsky.gov70.ru\n+7 (382-47) 2-97-84\n"
    },
    {
        "col1": "Томская область",
        "col2": 108,
        "col3": "Трубачевское сельское поселение",
        "col4": "636145, Томская область. Шегарский район,\nс. Трубачево, ул. Центральная, д.7\ntrubachevskoe@mail.ru\n8(38247) 38-116, 8(38247) 38-127\n"
    },
    {
        "col1": "Томская область",
        "col2": 109,
        "col3": "Шегарское сельское поселение",
        "col4": "636130,Томская область, Шегарский район,\nс.Мельниково, ул. Московская,д.13\nshegarka00@mail.ru\n8 (38247) 2-11-72\n8(38247) 2-37-29\n"
    },
    {
        "col1": "Томская область",
        "col2": 110,
        "col3": "Отдел опеки и попечительства Администрации города Кедрового",
        "col4": " 636615, Томская область,  г. Кедровый, 1-й мкр. ул., д.39/1,\nkedropeka@tomsk.gov.ru\n(38250)35-314\n"
    },
    {
        "col1": "Томская область",
        "col2": 111,
        "col3": "Администрация ЗАТО Северск",
        "col4": "636070, Томская область, г. Северск, пр. Коммунистический, 51\n8 (382-3) 77-23-23\nzato-seversk@gov70.ru\n"
    },
    {
        "col1": "Томская область",
        "col2": 112,
        "col3": "Администрация городского округа Стрежевой Отдел жилищной политики",
        "col4": "636785, Томская область, г. Стрежевой, ул. Ермакова, д,46А,                                               \n8 (38259) 51-560,                                     Koroleva@admstrj.romsk.ru"
    },
    {
        "col1": "Томская область",
        "col2": 113,
        "col3": "Департамент Жилищной Политики администрации Города Томска ",
        "col4": "634050, г. Томск, пр-т Ленина, д. 73\nmaildzp@admin.tomsk.ru\n+7 3822 90-82-11\n+7 3822 90-82-19\n"
    },
    {
        "col1": "Тульская область",
        "col2": 1,
        "col3": "Министерство труда и социальной защиты Тульской области",
        "col4": "г. Тула, ул. Пушкинская. д. 29, тел. 8 (4872) 24-52-50, mintrud@tularegion.ru"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 1,
        "col3": "Главное управление строительства Тюменской области — в части приобретения жилых помещений с целью формирования специализированного жилищного фонда Тюменской области для детей-сирот",
        "col4": "Главное управление строительства Тюменской области\n625000, Тюменская обл., г. Тюмень, ул. Некрасова, д. 11 / gus@72to.ru /\n(3452) 49-02-00; 49-02-82"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 2,
        "col3": "Департамент имущественных отношений Тюменской области — в части управления специализированным жилищным фондом Тюменской области для детей-сирот",
        "col4": "Департамент имущественных отношений Тюменской области\n625004, Тюменская обл., г. Тюмень, ул. Сакко, д. 30, корп. 1 / dio@72to.ru / (3452) 69-01-54"
    },
    {
        "col1": "Тюменcкая область",
        "col2": 3,
        "col3": "Органы местного самоуправления — в части  заключения договора найма  специализированного жилого помещения",
        "col4": "Органы местного самоуправления\nhttps://admtyumen.ru/ogv_ru/about/cities.htm \nhttps://admtyumen.ru/ogv_ru/about/Municipal_districts.htm \nhttps://admtyumen.ru/ogv_ru/about/south_area.htm "
    },
    {
        "col1": "Удмуртская Республика",
        "col2": 1,
        "col3": "Министерство социальной политики и труда Удмуртской Республики",
        "col4": "г. Ижевск, ул. Ломоносова, д. 5; +7 (3412) 22-27-30; mail@msp.udmr.ru"
    },
    {
        "col1": "Ульяновская область\n",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства и строительства             Ульяновской области",
        "col4": "432017, г. Ульяновск, ул. Спасская, д.8, (8422) 58-42-93 cun173@mail.ru"
    },
    {
        "col1": "Хабаровский край",
        "col2": 1,
        "col3": "Министерство жилищно-коммунального хозяйства Хабаровского края",
        "col4": "Хабаровский край, г. Хабаровск, ул. Фрунзе, д. 71, 680000. \nЭлектронная почта: office@khv.gov.ru. Тел: 8 (4212) 40-22-17, 31-29-11, 31-42-33, 30-56-49"
    },
    {
        "col1": "Херсонская область",
        "col2": 1,
        "col3": "Администрация Алешкинского муниципального округа\n",
        "col4": "275100; Российская Федерация, Херсонская область, г. Алешки,              ул. Гвардейская, 24/ oleshki@khogov.ru/      +7 (990) 138-50-61\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 2,
        "col3": "Администрация Великолепетихского муниципального округа\n",
        "col4": "Херсонская область, Великолепетихский р-н,                 пгт. Великая Лепетиха,                    ул. Соборная, д. 3а/ velikolepetikhskii_mo@khogov.ru/ +7(990)153-11-98"
    },
    {
        "col1": "Херсонская область",
        "col2": 3,
        "col3": "Администрация Верхнерогачикского муниципального округа",
        "col4": "274402;Российская Федерация, Херсонская обл., пгт. Верхний Рогачик, ул. Центральная, 48/  verhniyrogachik.vga@khogov.ru/     +7(990)072–32–45    "
    },
    {
        "col1": "Херсонская область",
        "col2": 4,
        "col3": "Администрация Генического муниципального округа\n",
        "col4": "275502; Российская Федерация, Херсонская область, г. Геническ,    ул. Центральная, 5/ genichesk@khogov.ru/       +7(990)111-68-27"
    },
    {
        "col1": "Херсонская область",
        "col2": 5,
        "col3": "Администрация Голопристанского муниципального округа\n",
        "col4": "275600; Российская Федерация, Херсонская область, г. Голая Пристань, ул. 1 Мая, 41/ golapristan@khogov.ru/      +7(990)225-19-39\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 6,
        "col3": "Администрация Горностаевского муниципального округа\n",
        "col4": "274601; Российская Федерация, Херсонская область,                       пгт. Горностаевка, ул. Покровская, 145/ gornastaevskaya_vga@khogov.ru/   80 (5544) 4-11-43"
    },
    {
        "col1": "Херсонская область",
        "col2": 7,
        "col3": "Администрация Ивановского муниципального округа\n",
        "col4": "275401; Российская Федерация, Херсонская область, пгт. Ивановка, ул. Ивановская, 34а/ ivanovka@khogov.ru/      +79900137991\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 8,
        "col3": "Администрация Каланчакского муниципального округа",
        "col4": "275800; Российская Федерация, Херсонская область, пгт. Каланчак, ул. Херсонская, 1/ vga.kalanchak@khogov.ru/ +7(990)100–58–00\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 9,
        "col3": "Администрация Каховского муниципального округа\n",
        "col4": "274803; Российская Федерация, Херсонская область, г. Каховка,     ул. Большая Куликовская, 103/ ivga.kakhovka@yandex.ru/ +79900674406 "
    },
    {
        "col1": "Херсонская область",
        "col2": 10,
        "col3": "Администрация Нижнесерогозского муниципального округа\n",
        "col4": "274701; Российская Федерация, Херсонская область, пгт. Нижние Серогозы, ул. Садовая, 7/ nizhneserogozsk@khogov.ru/            +7 (990) 092-04-09\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 11,
        "col3": "Администрация Новотроицкого муниципального округа",
        "col4": "275300; Российская Федерация, Херсонская область, пгт. Новотроицкое, ул. Соборная, 73/ Novotroitsk_admin@khogov.ru/ +7(990)159–17–58\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 12,
        "col3": "Администрация Скадовского муниципального округа",
        "col4": "275700; Российская Федерация, Херсонская область, г. Скадовск, ул.Александровская, 34/ skadovsk@khogov.ru/ +7(990)104–18–19\n"
    },
    {
        "col1": "Херсонская область",
        "col2": 13,
        "col3": "Администрация Чаплынского муниципального округа",
        "col4": "275200; Российская Федерация, Херсонская область, Чаплынский район, пгт. Чаплынка, ул. Парковая, 40/ chaplvga@khogov.ru/ +7(990)111–35–94; +7(990)036–21–91\n"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 1,
        "col3": "органы местного самоуправления Белоярского района",
        "col4": "\n628162, ул. Центральная, 9, г. Белоярский,Ханты-Мансийский автономный округ – Югра8 (34670) 2-14-90\nadmbel@admbel.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 2,
        "col3": "Орган местного самоуправления Березовского района",
        "col4": "\n628140, ул Астраханцева, 54, пгт. Березово, \nХанты-Мансийский автономный округ-Югра\n8 (34674) 2-44-92\nadm@berezovo.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 3,
        "col3": "Орган местного самоуправления Кондинского района",
        "col4": "\n628200, ул.Титова, 21, пгт.Междуреченский, \nХанты-Мансийский автономный округ - Югра,\n8 (34677) 33-540\nglava@admkonda.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 4,
        "col3": "Орган местного самоуправления \nНефтеюганского района",
        "col4": "\n628309, 3 микрорайон., 21, г. Нефтеюганск, \nХанты-Мансийский автономный округ-Югра \n8 (3463) 25-01-95\nadmnr@admoil.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 5,
        "col3": "Орган местного самоуправления\n Нижневартовского района",
        "col4": "\n628606, ул. Ленина, 6, г. Нижневартовск \nХанты-Мансийский автономный округ - Югра\n8(3466)49-86-78,\nadm@nvraion.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 6,
        "col3": "Орган местного самоуправления Октябрьского района",
        "col4": "\n628100, ул. Калинина, 39, пгт.Октябрьское,\nХанты-Мансийский автономный округ-Югра\n8 (34678)28-000\nadm@oktregion.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 7,
        "col3": "Орган местного самоуправления Советского района",
        "col4": "\n628242, ул. 50 лет Пионерии, 10, г. Советский, \nХанты-Мансийский автономный округ - Югра\n8 (34675) 5-48-00, 5-48-10\nadm@sovrnhmao.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 8,
        "col3": "Орган местного самоуправления Сургутского района",
        "col4": "\n628408, ул.Энгельса, 10, г. Сургут, \nХанты-Мансийский автономный округ-Югра\n8 (3462) 52-65-00\nglava@admsr.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 9,
        "col3": "Орган местного самоуправления\n Ханты-Мансийского района",
        "col4": "\n628002, ул. Гагарина, 214, г. Ханты-Мансийск,\nХанты-Мансийский автономный округ - Югра8 (3467) 352800\noffice@hmrn.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 10,
        "col3": "Орган местного самоуправления города Когалыма",
        "col4": "\n628481, ул.Дружбы Народов,7, г. Когалым, \nХанты-Мансийский автономный округ-Югра\n8 (34667) 93-522\ndelo@admkogalym.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 11,
        "col3": "Орган местного самоуправления города Лангепас",
        "col4": "\n628672, ул. Ленина, 35, г. Лангепас, \nХанты-Мансийский автономный округ - Югра,\n8 (34669) 5-60-57,\nаdm@admlangepas.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 12,
        "col3": "Орган местного самоуправления Нефтеюганска",
        "col4": "\n628309, 2 микрорайон, 25, г. Нефтеюганск,\nХанты-Мансийский автономный округ - Югра,\n8 (3463) 23-77-11\npr_glava@admugansk.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 13,
        "col3": "Орган местного самоуправления \nгорода Нижневартовска",
        "col4": "\n628602, Таежная, 24, г. Нижневартовск, \nХанты-Мансийский автономный округ - Югра\n8 (3466) 24-15-98, \n8 (3466) 24-24-34, \nmeria@n-vartovsk.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 14,
        "col3": "Орган местного самоуправления города Нягани",
        "col4": "\n628181, ул. Загородных, 7 А, , г. Нягань,\nХанты-Мансийский автономный округ-Югра8 (34672) 26-131; 26-131 \nvsdelo@admnyagan.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 15,
        "col3": "Орган местного самоуправления города Покачи",
        "col4": "\n628661, ул. Мира, 8/1, г.Покачи, \nХанты-Мансийский автономный округ-Югра\n8(34669)7-28-76\nadmpokachi@admpokachi.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 16,
        "col3": "Орган местного самоуправления города Пыть-Ях",
        "col4": "\n628380, 1 микрорайон, 18 «а», г.Пыть-Ях, \nХанты-Мансийский автономный округ8 (3463) 46-55-79\nadm@gov86.org"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 17,
        "col3": "Орган местного самоуправления города Радужный",
        "col4": "\n628460, 3 микрорайон, 3, г.Радужный, \nХанты-Мансийский автономный округ-Югра\n8 (34668) 25-710\nupr_del@admrad.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 18,
        "col3": "Орган местного самоуправления города Сургута",
        "col4": "\n628400, ул. Энгельса, 8, г. Сургут,Ханты-Мансийский автономный округ - Югра\n8 (3462) 52-21-57, 52-21-75\ngorod@admsurgut.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 19,
        "col3": "Орган местного самоуправления города Урай",
        "col4": "\n628285, 2 микрорайон, 60, г. Урай,\nХанты-Мансийский автономный округ - Югра \n8 (34676) 2-23-28\nadm@uray.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 20,
        "col3": "Орган местного самоуправления\n города Ханты-Мансийска",
        "col4": "\n628012, ул. Дзержинского, 6, г. Ханты-Мансийск \nХанты-Мансийский автономный округ - Югра8 (3467) 35-23-01 (доб. 301, 331)\npr@admhmansy.ru"
    },
    {
        "col1": "Ханты-Мансийский автономный округ",
        "col2": 21,
        "col3": "Орган местного самоуправления города Югорска",
        "col4": "\n628260, ул. 40 лет Победы, 11, Югорск, \nХанты-Мансийский автономный округ - Югра\n8 (34675) 5-00-00\nadm@ugorsk.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 1,
        "col3": "Управление по имуществу и земельным отношениям Агаповского муниципального района",
        "col4": "457400, Челябинская область, Агаповский район, с.Агаповка, ул.Дорожная, д.32а, тел. 8(35140)2-16-99, uizo.agapovka@yandex.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 2,
        "col3": "Администрация Аргаяшского муницпрпального района Челябинской области",
        "col4": "456880, Челябинская область, Аргаяшский муниципальный район, село Аргаяш, улица 8 Марта, дом 38, argayash@gov74.ru, тел. (35131) 2-00-29\nkui@argayash.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 3,
        "col3": "Комитет по управлению имуществом администрации Ашинского муниципального района Челябинской области",
        "col4": "456010, Челябинская область, Ашинский муниципальный район, г.Аша, ул.Толстого, д. 10, 8(35159)35303, kumiamr@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 4,
        "col3": "Комитет по управлению имуществом и земельным отношениям Администрации Брединского муниципального района",
        "col4": "457310, Челябинская область, Брединский район, п. Бреды, ул. Гербанова, д. 40, 8(35141)34150, bredy_imyshestvo@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 5,
        "col3": "Управление по имущественной политике и координации деятельности в сфере государственных и муниципальных услуг администрации Варненского муниципального района ",
        "col4": "457200, Челябинская область, м. р-н Варненский, с. п. Варненское, с. Варна, ул. Советская, д. 94, офис 10 тел.+7 (35142) 2-21-40"
    },
    {
        "col1": "Челябинская область",
        "col2": 6,
        "col3": "Управление имуществом и земельными отношениями администрации Верхнеуральского муниципального района ",
        "col4": "457670, Челябинская область, Верхнеуральский район, г. Верхнеуральск, ул. Еремина, 43 verhneuralsk@qov74.ru 8(35143) 2-23-35"
    },
    {
        "col1": "Челябинская область",
        "col2": 7,
        "col3": "Управление имущественных отношений Верхнеуфалейского городского округа ",
        "col4": "Адрес: Челябинская область, г. Верхний Уфалей, ул. Ленина, д.188, \n1 этаж, каб.107\nТелефон: +7 (35164) 2-02-42\ne-mail: uio@ufaleyadmin.ru "
    },
    {
        "col1": "Челябинская область",
        "col2": 8,
        "col3": "Администрация Еманжелинского муниципального района Челябинской области",
        "col4": "456580 Челябинская область, г.Еманжелинск, ул.Мира, д. 18, 835138-2-19-28, 21928@admemr.ru "
    },
    {
        "col1": "Челябинская область",
        "col2": 9,
        "col3": "Администрация Еткульского мунципального района",
        "col4": "456560, Челябинская область,Еткульский район с.Еткуль, ул. Ленина, д.34, orgotd_etk@mail.ru omi_etk@mail.ru, 8(35145)2-27-22"
    },
    {
        "col1": "Челябинская область",
        "col2": 10,
        "col3": "ОМС \"Комитет по управлению имуществом\" Златоустовского городского округа",
        "col4": "456200 г.Златоуст, ул. Таганайская, 1, komitet.kui.zlat@yandex.ru, 8-3513-62-21-54"
    },
    {
        "col1": "Челябинская область",
        "col2": 11,
        "col3": "Отдел по имущественным и жилищным отношениям администрации Карабашского городского округа",
        "col4": "456143, Челябинская область, г. Карабаш, ул. Металлургов, д. 3, каб. 216 8(35153)2-49-44 imkarabash@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 12,
        "col3": "Администрация Карталинского муниципального района - Управление по имущественной и земельной политике Карталинского муниципального района",
        "col4": "457351, Челябинская область, город Карталы, улица Калмыкова, дом 6, kumiizrkmr@rambler.ru, 8(35133)55027"
    },
    {
        "col1": "Челябинская область",
        "col2": 13,
        "col3": "Комитет по управлению имуществом и земельным отношениям администрации Каслинского муниципального района",
        "col4": "456830, Челябинская область, город Касли улица Ленина, дом 32, kuizo.kmr@yandex.ru, 8(35149)22170"
    },
    {
        "col1": "Челябинская область",
        "col2": 14,
        "col3": "Управление коммунального хозяйства, транспрта и связи Катав-Ивановского муниципального района ",
        "col4": "456110, Челябинская область, г. Катав-Ивановск, ул. Степана Разина, дом 45, uprstroi11@mail.ru 8 35147 5-56-19"
    },
    {
        "col1": "Челябинская область",
        "col2": 15,
        "col3": "Управление по имуществу и земельным отношениям администрации Кизильского муниципального района",
        "col4": "457610, Челябинская область, Кизильский район, с. Кизильское, ул. Советская, 65 kiziluiso@mail.ru, 8(35155)3-04-36"
    },
    {
        "col1": "Челябинская область",
        "col2": 16,
        "col3": "Администрация Копейского городского округа Челябинской области",
        "col4": "456618, Челябинская область, г. Копейск, ул. Ленина, д. 52, (351)394-01-49 kopeysk@akgo74.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 17,
        "col3": "Начальнику Управления муниципального имущества и земельных отношений",
        "col4": "456550, Челябинская область, город Коркино, ул. 30 лет ВЛКСМ, 27А"
    },
    {
        "col1": "Челябинская область",
        "col2": 18,
        "col3": "Комитет по управлению имуществом и земельным отношениям администрации Красноармейского муниципального района Челябинской области",
        "col4": "456660, Челябинская область, Красноармейский район, с. Миасское, ул. Пионера, д. 39, тел. 8 (351 50)2-06-36, 2-08-80, kraskuizo@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 19,
        "col3": "Управление имущественных и земельных отношений Администрации Кунашакского муниципального района",
        "col4": "456730, Челябинская обл., Кунашакский район, с.Кунашак ул. Ленина д.103 каб.23, 83514820061@mail.ru, тел. 8(35148)2-00-61"
    },
    {
        "col1": "Челябинская область",
        "col2": 20,
        "col3": "Управление земельными и имущественными отношениями Кусинского муниципального района",
        "col4": "456940, Челябинская область, г. Куса, ул. Бубнова, д. 16, uziokusa@yandex.ru тел. 8(35154) 3-32-10, 3-01-42"
    },
    {
        "col1": "Челябинская область",
        "col2": 21,
        "col3": "Администрация Кыштымского городского округа Челябинской области",
        "col4": "456870, Челябинская область, г. Кыштым, площадь Карла Маркса, д.1, kyshtym@gov74.ru тел. 8(35151) 4-31-15, 4-05-50"
    },
    {
        "col1": "Челябинская область",
        "col2": 22,
        "col3": "Администрация Локомотивного городского округа Челябинской области",
        "col4": "457390, Челябинская область, п.Локомотивный, ул.Мира, д.60, lokomotivny.ru тел. 8(351) 5-67-75"
    },
    {
        "col1": "Челябинская область",
        "col2": 23,
        "col3": "Комитет по управлению имуществом и земельными отношениями администрации города Магнитогорска",
        "col4": "455044, Челябинская область, г. Магнитогорск, пр. Ленина, 72/kuiizo@magnitogorsk.ru/8 (3519)49-84-81"
    },
    {
        "col1": "Челябинская область",
        "col2": 24,
        "col3": "Отдел приватизации и жилищных вопросов Управления имущественных отношений Администрации миасского городского округа Челябинской области",
        "col4": "456300, Челябинская область, г.Миасс, ул.Романенко, д.50а, каб.204, valetov.so@miass.gov74.ru, тел.8(3513)26-42-62 (доб.3)"
    },
    {
        "col1": "Челябинская область",
        "col2": 25,
        "col3": "Управлениемуниципальным имуществом и земельными отношениями администрации Нагайбакского муниципального района",
        "col4": "457650, Челябинская область, гс. Фершампенуазк, ул. К.Маркса, 53 umizo,dok23@mail,ru , 8 (3519)22265"
    },
    {
        "col1": "Челябинская область",
        "col2": 26,
        "col3": "Комитет по управлению муниципальным имуществом администрации Нязепетровского муниципального округа",
        "col4": "456970, Челябинская область, г. Нязепетровск, ул. Свердлова, д. 7, пом.3 kumi@nzpr.ru 8 351-56-3-16-67"
    },
    {
        "col1": "Челябинская область",
        "col2": 27,
        "col3": "Управление имущественных отношений Администрации Озерского городского округа",
        "col4": "456780, Челябинская область, г. Озерск, ул. Блюхера, д. 2а, 8 (35130) 2-94-10, kumi@ozerskadm.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 28,
        "col3": "Управление по муниципальному имуществу, земельным отношениям и развитию сельского хозяйства администрации Октябрьского муниципального района Челябинской области",
        "col4": "457170, Челябинская область, Октябрьский район, село Октябрьское, ул.Ленина, д.36 8 (35158) 5-34-53, 5-24-46,5-20-08 umizosh@okt174.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 29,
        "col3": "Управление жилищно-коммунального хозяйства и строительства администрации Пластовского муниципального округа",
        "col4": "457020 Челябинская обл., г. Пласт, ул. Октябрьская, д.45, plast@plast.gov74.ru , тел. 8(35160) 2-29-72"
    },
    {
        "col1": "Челябинская область",
        "col2": 30,
        "col3": "Управление земельными и имущественными отношениями администрации Саткинского муниципального округа",
        "col4": "456910, Челябинская область, г. Сатка, ул. 50 лет ВЛКСМ, д.6, priemnaya@uzio-satka.ru, т. 8(35161)56916"
    },
    {
        "col1": "Челябинская область",
        "col2": 31,
        "col3": "Отдел жилья и социальных программ администрации города Снежинска Челябинской области",
        "col4": "456770, Челябинская область, г.Снежинск, ул. Свердлова, д. № 1, ozh@snzadm.ru, 8 (35146) 2-60-07"
    },
    {
        "col1": "Челябинская область",
        "col2": 32,
        "col3": "Комитет по управлению имуществом и земельным отношениям Сосновского муниицпального района",
        "col4": "456510, Челябинская область, Сосновский район, с. Долгодеревенское, пер. Школьный, 7, kuiizo@mail.ru, 83514490338"
    },
    {
        "col1": "Челябинская область",
        "col2": 33,
        "col3": "Управление социальной защиты населения администрации города Трехгорного",
        "col4": "45680, Челябинская область, город Трехгорный, ул.Карла маркса, дом 45, uszn94@minsoc74,ru, 8(351)916-09-89"
    },
    {
        "col1": "Челябинская область",
        "col2": 34,
        "col3": "Управление жилищно-коммунального хозяйства, экологии, благоустройства, транспорта и связи Администрации города Троицка Челябинской области",
        "col4": "457100, Челябинская область, г. Троицк, ул. Ленина, д. 19, u-gkh@mail.ru, 8 3516323527"
    },
    {
        "col1": "Челябинская область",
        "col2": 35,
        "col3": "Управление муниципальным имуществом и земельными отношениями администрации Троицкого муниципального района",
        "col4": "457100, Челябинская область, г. Троицк, ул. им. М. Горького, д.1 umizotmr@yandex.ru, 8(35163) 2-48-95"
    },
    {
        "col1": "Челябинская область",
        "col2": 36,
        "col3": "Отдел жилищной политики администрации Увельского муниципального района",
        "col4": "457000, Челябинская область, Увельский район, п.Увельский, ул. Советская, д.24, otdgilpolitikiuvelka@mail.ru, 8(35166)3-25-89"
    },
    {
        "col1": "Челябинская область",
        "col2": 37,
        "col3": "Муниципальное учреждение \"Комитет по управлению муниципальным имуществом Уйского муниципального района",
        "col4": "456470 Челябинская область, Уйский район, с. Уйское ул. Дорожников 24, kumiuysk@mail,ru, 83516523040"
    },
    {
        "col1": "Челябинская область",
        "col2": 38,
        "col3": "Управление имущественных и земельных отношений администрации Усть-Катавского городского округа",
        "col4": "456043, Челябинская область, г. Усть-Катав, ул. Ленина, д.47а, gaytan2006@mail.ru, 8(351)214-87-88"
    },
    {
        "col1": "Челябинская область",
        "col2": 39,
        "col3": "Управление муниципальной собственности администрации Чебаркульского городского округа",
        "col4": "456440, Челябинская область, г. Чебаркуль, ул. Ленина, д. 13 а, тел 8(351-68) 2-16-40 , ums@chebarcul.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 40,
        "col3": "Управление по земельно-имущественным отношениям администрации Чебаркульского муниципального района",
        "col4": "456440, Челябинская область, г. Чебаркуль, ул. Шоссейная,11-б Тел. +7 (35168) 2-29-32 umsr@yandex.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 41,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 43 "
    },
    {
        "col1": "Челябинская область",
        "col2": 42,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 44"
    },
    {
        "col1": "Челябинская область",
        "col2": 43,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 45"
    },
    {
        "col1": "Челябинская область",
        "col2": 44,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 46"
    },
    {
        "col1": "Челябинская область",
        "col2": 45,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 47"
    },
    {
        "col1": "Челябинская область",
        "col2": 46,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 48"
    },
    {
        "col1": "Челябинская область",
        "col2": 47,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 49"
    },
    {
        "col1": "Челябинская область",
        "col2": 48,
        "col3": "Комитет по управлению имуществом\n и земельным отношениям\n города Челябинска",
        "col4": "454113, г.Челябинск,\nул. Тимирязева, д. 36,\nэл. почта: kuizo@kuizo.ru,\n+7 (351) 265 91 50"
    },
    {
        "col1": "Челябинская область",
        "col2": 49,
        "col3": "Управление экономики, недвижимости и предпринимательства администрации Чесменского муниципального района",
        "col4": "457220 Челябинская область, Чесменский район, с. Чесма, ул. Советская, д. 47, тел 8(35169)2-15-34, эл. адрес imu_74@mail.ru"
    },
    {
        "col1": "Челябинская область",
        "col2": 50,
        "col3": "Управление по имуществу и земельным отношениям администрации Южноуральского городского округа",
        "col4": "457040, Челябинская область, г. Южноуральск, ул. Космонавтов, д. 22, эл. почта: komitet_2008@mail.ru тел: 8(35134)44764"
    },
    {
        "col1": "Чеченская Республика",
        "col2": 1,
        "col3": "Министерство строительства, жилищно-коммунального хозяйства и энергетики Чеченской Республики",
        "col4": "ул. Санкт-Петербургская, 11, г. Грозный, Чеченская Республика, 364021\nе-mail: minstroychr@msgkhchr.ru\nтел: 8 (8712) 22-48-64; 22-25-92"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 1,
        "col3": "Алатырский муниципальный округ Чувашской Республики",
        "col4": "429826, Чувашская Республика, г. Алатырь, ул. Ленина, д. 29     8(83531) 2-24-08 alatr.priem@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 2,
        "col3": "Аликовский муниципальный округ Чувашской Республики",
        "col4": "429250, Чувашская Республика, Аликовский район, с. Аликово, \nул. Октябрьская, д. 21 8(83535) 22-3-82 alikov@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 3,
        "col3": "Батыревский муниципальный округ Чувашской Республики",
        "col4": "429350, Чувашская Республика, Батыревский район, с. Батырево, \nпр. Ленина, д. 5 8(83532) 6-24-20 batyr@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 4,
        "col3": "Вурнарский муниципальный округ Чувашской Республики",
        "col4": "429220, Чувашская Республика, Вурнарский район, пгт. Вурнары, \nул. Советская, д. 20 8(83537) 2-51-91 vurnar@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 5,
        "col3": "Ибресинский муниципальный округ Чувашской Республики",
        "col4": "429700, Чувашская Республика, Ибресинский район, п. Ибреси, \nул. Маресьева, д. 49  \n 8(83538) 2-12-52 ibresi@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 6,
        "col3": "Канашский муниципальный округ Чувашской Республики",
        "col4": "429330, г. Канаш, ул.30 лет Победы, д.87  8(83533) 2-24-90 kanash@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 7,
        "col3": "Козловский муниципальный округ Чувашской Республики",
        "col4": "429430, Чувашская Республика, Козловский район, г. Козловка, \nул. Ленина, д. 55  8(83534) 2-23-37 kozlov@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 8,
        "col3": "Комсомольский муниципальный округ Чувашской Республики",
        "col4": "429140, Чувашская Республика, Комсомольский район, \nс. Комсомольское, ул. Заводская, д. 57 8(83539) 5-11-43  komsml@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 9,
        "col3": "Красноармейский муниципальный округ Чувашской Республики",
        "col4": "429620, Чувашская Республика, Красноармейский район, \nс. Красноармейское, ул. Ленина, д. 35 8(83530) 2-18-41 adm.krarm@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 10,
        "col3": "Красночетайский муниципальный округ Чувашской Республики",
        "col4": "429040 Чувашская Республика, с. Красные Четаи, пл. Победы, д. 1 8(83551) 2-16-76 krchet-adm@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 11,
        "col3": "Мариинско-Посадский муниципальный округ Чувашской Республики",
        "col4": "429570, Чувашская Республика, г. Мариинский Посад,  \nул. Николаева, д. 47               8(83542) 2-14-42 marpos@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 12,
        "col3": "Моргаушский муниципальный округ Чувашской Республики",
        "col4": "429530, Чувашская Республика, Моргаушский район, \nс. Моргауши, ул. Мира, д. 6 8(83541) 62-2-39 morgau@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 13,
        "col3": "Порецкий муниципальный округ Чувашской Республики",
        "col4": "429020, Чувашская Республика, Порецкий район, с. Порецкое, \nул. Ленина, д. 3  8(83543) 2-19-53 porezk@cap.ru; porezk_org3@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 14,
        "col3": "Урмарский муниципальный округ Чувашской Республики",
        "col4": "429400, Чувашская Республика, Урмарский район, п. Урмары, \nул. Мира, д. 5  8(83544) 2-10-85 urmary@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 15,
        "col3": "Цивильский муниципальный округ Чувашской Республики",
        "col4": "429900, Чувашская Республика, Цивильский район, г. Цивильск, ул. Маяковского, \nд. 12  8(83545) 21-3-26 zivil@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 16,
        "col3": "Чебоксарский муниципальный округ Чувашской Республики",
        "col4": "429500, Чувашская Республика, Чебоксарский район, п. Кугеси, \nул. Советская, д. 25  8(83540) 2-30-93 chebs@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 17,
        "col3": "Шемуршинский муниципальный округ Чувашской Республики",
        "col4": "429370, Чувашская Республика, Шемуршинский район, \nс. Шемурша, ул. Советская, д. 8  8(83546)-2-31-07 shemur@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 18,
        "col3": "Шумерлинский муниципальный округ Чувашской Республики",
        "col4": "429122, Чувашская Республика, \nг. Шумерля, ул. Октябрьская, \nд. 24  (83536) 2-06-29 gshum@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 19,
        "col3": "Ядринский муниципальный округ Чувашской Республики",
        "col4": "429060, Чувашская Республика, Ядринский район, г. Ядрин, \nул. 30 лет Победы, д. 1   8(83547) 22-0-36 yadrin@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 20,
        "col3": "Яльчикский муниципальный округ Чувашской Республики",
        "col4": "429380, Чувашская Республика, Яльчикский район, с. Яльчики, \nул. Иванова, д. 16   8(83549) 2-56-12 yaltch@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 21,
        "col3": "Янтиковский муниципальный округ Чувашской Республики",
        "col4": "429290 Чувашская Республика, Янтиковский район, с. Янтиково, пр. Ленина, д. 13  (83548) 2-18-60 yantik@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 22,
        "col3": "г. Новочебоксарск Чувашской Республики",
        "col4": "429951 Чувашская Республика, \nг. Новочебоксарск,  \nул. Винокурова, д.14   8(8352) 73-81-24,\n73-33-88 nowch-doc5@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 23,
        "col3": "Адмимнистрация Калининского района г.Чебоксары",
        "col4": "428022, Чувашская Республика, г.Чебоксары, ул. 50 лет Октября, д. 10 а  \n8(8352) 23-44-28, \n23-44-27 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 24,
        "col3": "Администраация Ленинского района г.Чебоксары",
        "col4": "428000, Чувашская Республика, г.Чебоксары, ул. Гагарина, д. 22 а\n8(8352) 23-44-76, \n23-44-75, 23-40-17 gcheb@cap.ru"
    },
    {
        "col1": "Чувашская Республика\n",
        "col2": 25,
        "col3": "Администрация Московского района г. Чебоксары ",
        "col4": "428017, Чувашская Республика, г. Чебоксары, пр. Московский, д. 33 «а»\n8(8352) 23-52-18,\n23-52-33, 23-52-15, 23-52-29  gcheb@cap.ru"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 1,
        "col3": "Администрация городского округа Анадырь",
        "col4": " (689000, Чукотский автономный округ, г. Анадырь, ул. Рультытегина, д. 1, тел. (42722) 6-36-00, precedent@rambler.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 2,
        "col3": "Администрация Анадырского муниципального района ",
        "col4": "(689000, Чукотский автономный округ, г. Анадырь, ул. Южная, д. 15, тел. (42722) 6-49-01, anareg@chukotnet.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 3,
        "col3": "Администрация Билибинского муниципального района",
        "col4": " (689450, Чукотский автономный округ, г. Билибино, ул. Курчатова, д. 6, тел. (42738) 2-35-01, info@bilchao.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 4,
        "col3": "Администрация муниципального округа Эгвекинот ",
        "col4": "(689202, Чукотский автономный округ, пгт Эгвекинот, ул. Ленина, д. 9, тел. (42734) 2-31-14, admin@go-egvekinot.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 5,
        "col3": "Администрация Провиденского муниципального округа",
        "col4": " (689251, Чукотский автономный округ, пгт Провидения, ул. Набережная Дежнева, д. 8-а, "
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 6,
        "col3": "Администрация муниципального округа Певек",
        "col4": " (689400, Чукотский автономный округ, г. Певек, ул. Обручева, д. 29, тел. (42737) 4-21-42, chaunadmin@mail.ru);"
    },
    {
        "col1": "Чукотский автономный округ",
        "col2": 7,
        "col3": "Администрация Чукотского муниципального района ",
        "col4": "(689300, Чукотский автономный округ, с. Лаврентия, ул. Советская, д. 15, тел. (42736) 2-28-56, od@chukotraion.ru)."
    },
    {
        "col1": "Ямало-Ненецкий автономный округ",
        "col2": 1,
        "col3": "Департамент строительства и жилищной политики Ямало-Ненецкого автономного округа",
        "col4": "629008, ЯНАО, г. Салехард, ул. Ямальская, д. 11 г, 8 (34922) 7-73-88, depstroy@yanao.ru"
    },
    {
        "col1": "Ярославская область",
        "col2": 1,
        "col3": "Министерство \nимущественных отношений \nЯрославской области",
        "col4": "Советская ул., д. 69,\n г. Ярославль, 150003\nТелефон (4852) 40-07-91\ne-mail: mio@yarregion.ru"
    }
]