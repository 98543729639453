import React from "react";
import s from "./Default.module.css"
import { useState } from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News2703252.jpg"

const News2703252 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Первый Федеральный форум нового поколения профессионалов креативных индустрий «АртМастерс»
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            <p></p>
            С 3 по 5 апреля в Москве пройдёт первый федеральный форум ArtMasters для профессионалов креативных индустрий. Это шанс для талантливых людей обменяться опытом, найти полезные контакты и зарядиться вдохновением. Вход бесплатный, регистрация уже открыта.
            <p></p>
            На форуме запланировано свыше 100 мероприятий о развитии креативной экономики. Среди 200+ спикеров — Константин Эрнст, Владимир Машков, Карен Шахназаров и другие. Также соберутся представители backstage-индустрии из 89 регионов России.
            <p></p>
            ArtMasters объединит финалистов одноимённого чемпионата и всех, кто хочет быть в центре креативных событий. Зарегистрируйтесь на сайте и получите билет на главное весеннее событие для профессионалов индустрии.
            <p></p>
            Подробнее о мероприятии: <a style={{ fontWeight: "bold" }} href="https://www.forum.artmasters.ru/">https://www.forum.artmasters.ru/</a>
            <p></p>
        </div>
        <ToNews />
    </div>
}


export default News2703252;