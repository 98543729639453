import React from "react";
import s from "./Default.module.css"
import { useEffect, useState } from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News280325.jpg"

const News280325 = ({ children, header }) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Вебинар «Применение медиативного и восстановительного подходов в работе с несовершеннолетними»
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            27 марта 2025 г. ФГБУ «Центр защиты прав и интересов детей» в рамках оказания методической поддержки при организации профилактической работы в образовательных организациях проведен вебинар «Применение медиативного и восстановительного подходов в работе с несовершеннолетними».
            <p></p>
            В ходе вебинара были представлены принципы медиативного и восстановительного подходов, медиативные технологии и программы, реализуемые службами примирения в работе с несовершеннолетними в системе образования и в деятельности комиссий по делам несовершеннолетних и защите их прав.
            <p></p>
            Также были рассмотрены возможные позиции при реагировании на деструктивное поведение и правонарушения несовершеннолетних, сферы применения медиативно-восстановительного подхода и работа территориальных служб примирения.
            <p></p>
            В вебинаре приняли участие секретари и специалисты, обеспечивающие деятельность комиссий по делам несовершеннолетних и защите их прав, руководители и заместители директоров по воспитательной работе образовательных организаций Донецкой и Луганской Народных Республик, Запорожской и Херсонской областей.
            <p></p>
            В качестве спикеров выступили аналитик Центра защиты прав и интересов детей Жодзишская Лидия Николаевна и руководитель Городской службы примирения, старший методист государственного бюджетного учреждения города Москвы «Городской психолого- педагогический центр Департамента образования и науки города Москвы», сопредседатель Экспертного совета по развитию служб медиации и (или) примирения в образовательных организациях Минпросвещения России Коновалов Антон Юрьевич.
            <p></p>

        </div>
        <ToNews />
    </div>
}


export default News280325;