export const ReestrNecomOrgMediacia = [
    [
        [
            {
                "col1": "Центральный федеральный округ",
                "col2": "Белгородская область",
                "col3": 1,
                "col4": "Центр медиации и правовых технологий юридического института ФГАОУ ВО НИУ «БелГУ»",
                "col5": "308015, Белгородская область, г. Белгород, ул. Победы, д.85, корп. 14, кабинет 51",
                "col6": "Рубанов Сергей Александрович",
                "col7": "http://uf.bsu.edu.ru/index.php/str/department/mediation",
                "col8": "8(4722) 30-14-75, 8(4722) 77-72-31 8(4722) 77-70-02",
                "col9": "cmipt@mail.ru cmipt@yandex.ru cm@bsu.edu.ru samoylenko@bsu.edu.ru, rubanov@bsu.edu.ru"
            },
        ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация \"Центр психологии и развития человека \"Сфера\"",
            "col5": "241013, Брянская обл., г. Брянск, ул. Молодой Гвардии, д. 41, кв. 3",
            "col6": "Осипова Виктория Валерьевна",
            "col7": "https://sfera4you.ru/",
            "col8": "8 (991) 305-36-19, 8(900) 363-00-36",
            "col9": "sfera.v32@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 2,
            "col4": "Автономная некоммерческая организация \"Специальный центр развития детей \"Гармония\"",
            "col5": "241050, Брянская обл., г. Брянск, ул. Октябрьская, д. 39, пом. 2",
            "col6": "Орачевский Александр Владимирович",
            "col7": " ",
            "col8": "8 (906) 503-96-03",
            "col9": "scrd@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 3,
            "col4": "Брянская региональная общественная организация оказания помощи семье и профилактики социально значимых заболеваний \"БЛАГО\"",
            "col5": "241012, Брянская обл., г. Брянск, ул. Орловская, д. 30, кв. 91",
            "col6": "Афонина Виктория Геннадьевна",
            "col7": "http://vk.com/public168036087",
            "col8": "8 (910) 331-94-33, 8 (4832) 66-59-32",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 4,
            "col4": "Автономная некоммерческая организация «Центр поддержки семьи «Традиция»",
            "col5": "241023, Брянская обл., г. Брянск, ул. Бежицкая, д. 11А, оф. 1",
            "col6": "Булгакова Татьяна Николаевна",
            "col7": "",
            "col8": "8 (900) 360-59-04",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 5,
            "col4": "Автономная некоммерческая организация социальной поддержки людей в трудных жизненных ситуациях «Перспектива»",
            "col5": "241016, Брянская обл., г. Брянск, ул. Почтовая, д. 38, оф. 33",
            "col6": "Бедуля Ирина Васильевна",
            "col7": "",
            "col8": "8 (915) 802-91-12",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 6,
            "col4": "Автономная некоммерческая организация «Центр досуга трудных детей и подростков «Благодать»",
            "col5": "243020, Брянская обл., г. Новозыбково, ул. Ломоносова, д. 20, корп. А, кв. 29",
            "col6": "Кривонос Светлана Петровна",
            "col7": "",
            "col8": "8 (960) 561-91-19",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 7,
            "col4": "Брянская региональная общественная организация помощи детям «ГОЛОС СЕРДЦА»",
            "col5": "241037, Брянская обл., г. Брянск, ул. Крахмалева, д. 29, кв. 109",
            "col6": "Малюк Сергей Васильевич",
            "col7": "",
            "col8": "8 (920) 844-33-00",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 8,
            "col4": "Брянская региональная общественная организация содействия семье «Родительское собрание»",
            "col5": "241004, Брянская обл., г. Брянск, ул. Богдана Хмельницкого, д. 81А",
            "col6": "Киселев Андрей Геннадьевич",
            "col7": "",
            "col8": "8 (953) 288-01-94",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 9,
            "col4": "Автономная некоммерческая организация  «Центр защиты детей «СОЛНЕЧНЫЙ КРУГ»",
            "col5": "241014, Брянская обл., г. Брянск, ул. Клинцовская, д. 63, кв. 2",
            "col6": "Маслова Елена Геннадьевна",
            "col7": "",
            "col8": "8 (980) 307-76-97",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 10,
            "col4": "Государственное бюджетное учреждение социального обслуживания Брянской области  «Социальный приют для детей и подростков Карачевского района»",
            "col5": "242500, Брянская область, Карачевский район, п. Согласие, д.2",
            "col6": "Емельянова Марина Вячеславовна",
            "col7": "http://prt12.uszn032.ru/",
            "col8": "8 (48335) 9 53 60",
            "col9": "kar.pr1@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Брянская область",
            "col3": 11,
            "col4": "Государственное бюджетное учреждение социального обслуживания Брянской области  «Центр социальной помощи семье и детям Трубчевского района»",
            "col5": "242220, Брянская область, г.Трубчевск, ул. Свердлова, д.71",
            "col6": "Власова Елена Васильевна",
            "col7": "http://csp10.uszn032.ru/",
            "col8": "8 (48352) 2 60 06",
            "col9": "trub_cspsd@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Владимирская область",
            "col3": 1,
            "col4": "Некоммерческое партнерство «Владимирская региональная ассоциация медиаторов»",
            "col5": "600000, Владимирская область, город Владимир, Девическая улица, д. 9, офис 108",
            "col6": "Фомина Елена Вячеславовна",
            "col7": "https://mediator33.ru",
            "col8": "8 (4922) 32-25-44, 8 (903) 645-58-83",
            "col9": "evfomina@bk.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Владимирская область",
            "col3": 2,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования г.Владимира Детский оздоровительно-образовательный (социально-педагогический) центр",
            "col5": "600031, Владимирская область, город Владимир, Юбилейная улица, 44",
            "col6": "Кислова Светлана Геннадьевна",
            "col7": "http://doospc.dop.obrazovanie33.ru/",
            "col8": "8 (4922) 77-97-06",
            "col9": "education@vladimir-city.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Воронежская область",
            "col3": 1,
            "col4": "Государственное бюджетное профессиональное учреждение Воронежской области «Борисоглебский техникум промышленных и информационных технологий»",
            "col5": "397160, Воронежская обл., г. Борисоглебск, ул. Третьяковская, д. 14",
            "col6": "Сердюк Марина Альбертовна",
            "col7": "https://бтпит.рф/",
            "col8": "8(47354) 6-05-73",
            "col9": "btpit@btpit36.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Воронежская область",
            "col3": 2,
            "col4": "Центр правовых инноваций и примирительных процедур Воронежского государственного университета",
            "col5": "394036, Воронежская обл., г. Воронеж, ул. Пушкинская, д.16, каб.237",
            "col6": "Носырева Елена Ивановна",
            "col7": "mediation.vsu.ru",
            "col8": "8 (920) 450-29-99",
            "col9": "centr.vsu@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Воронежская область",
            "col3": 3,
            "col4": "Автономная некоммерческая организация «Центр практической медиации»",
            "col5": "394043, Воронежская обл., г. Воронеж, ул. Березовая Роща, д.54/1, кв.140 ",
            "col6": "Филатова Анастасия Викторовна",
            "col7": "https://vk.com/centr_mediacii",
            "col8": "8 (906) 590-70-01",
            "col9": "A.FILATOVAMAIL@GMAIL.COM"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Воронежская область",
            "col3": 4,
            "col4": "Автономная некоммерческая организация Центр помощи разностороннего развития детей и взрослых «Пересвет»",
            "col5": "396320, Воронежская область, Новоусманский р-н, п. Шуберское, ул. Ленина, д. 41Б",
            "col6": "Коротаев Александр Викторович",
            "col7": "",
            "col8": "8 (900) 924-86-81",
            "col9": "TSENTRPERESVET@MAIL.RU"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Воронежская область",
            "col3": 5,
            "col4": "Автономная некоммерческая организация Центр помощи разностороннего развития детей и взрослых «Дающие свет»",
            "col5": "396336, Воронежская область, Новоусманский р-н, п. Отрадное, ул. Луговая, д. 67",
            "col6": "Анохина Наталья Викторовна",
            "col7": "",
            "col8": "8 (920)428-87-32",
            "col9": "9069792037@MAIL.RU"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ивановская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация по оказанию социальных услуг гражданам \"Ивановский региональный центр медиации и переговоров\"",
            "col5": "153002, Ивановская обл., г. Иваново, просп. Ленина, д. 92",
            "col6": "Гришанина Марина Александровна",
            "col7": "http://ирцм.рф/, https://touch.facebook.com/groups/2486901268006294, https://vk.com/mediatorrussia",
            "col8": "8 (910) 695-87-27, 8 (920) 362-44-62, 8 (920) 679-21-60",
            "col9": "magrish55@mail.ru, info@ирцм.рф"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Калужская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация по альтернативному уреryлированию споров и конфликгных ситуаций \"Калужский областной центр медиации\" ",
            "col5": "249034, Калужская обл., г. Обнинск, ул. Белкинская, д. 21, пом. 80 ",
            "col6": "Иконникова Юлия Игоревна",
            "col7": "https://pomirim.ru/",
            "col8": "8 (920) 888-22-27",
            "col9": "info@pomirim.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Костромская область",
            "col3": 1,
            "col4": "Областное государственное казённое учреждение «Костромской областной центр психолого-педагогической, медицинской и социальной помощи»",
            "col5": "156005, Костромская область, г. Кострома, ул. Лесная, д.25 «А»",
            "col6": "Ланская Юлия Федоровна",
            "col7": "http://www.eduportal44.ru/psy-med-clinic/default.aspx",
            "col8": "8 (4942) 31-39-36",
            "col9": "pmpk@list.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Костромская область",
            "col3": 2,
            "col4": " «Городской центр обеспечения качества образования»",
            "col5": "156007, Костромская область, г. Кострома, ул. Ленина, д.84",
            "col6": "Исмагилова Светлана Евгеньевна",
            "col7": "http://www.eduportal44.ru/kostroma_edu/gcoko/default.aspx",
            "col8": "8 (4942) 45-72-60",
            "col9": "mbucoko@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Костромская область",
            "col3": 3,
            "col4": "«Центр психолого-педагогической, медицинской и социальной помощи»  муниципального района город Нерехта и Нерехтский район Костромской области",
            "col5": "157802, Костромская область, г. Нерехта, ул. Красноармейская, д. 26",
            "col6": "Теплякова Любовь Александровна",
            "col7": "http://www.eduportal44.ru/Nerehta/centre_soprov/default.aspx",
            "col8": "8 (49451) 7-52-02",
            "col9": "aleksandroyna@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 1,
            "col4": "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Железногорский межрайонный центр социальной помощи семье и детям»   ",
            "col5": "307170, Курская область, г. Железногорск, ул. Комарова, д. 24",
            "col6": "Литвинова Татьяна Евгеньевна",
            "col7": "https://центрсоцпомощи46.рф/",
            "col8": "8 (47148) 2-50-20",
            "col9": "fecentr@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 2,
            "col4": "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Льговский межрайонный центр социальной помощи семье и детям»      ",
            "col5": "307750, Курская область, г. Льгов, ул. Кирова, д. 60",
            "col6": "Тестоедова Оксана Анатольевна",
            "col7": "http://приют-льгов.рф/",
            "col8": "8 (47140) 2-23-06",
            "col9": "lgov-priut@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 3,
            "col4": "Областное казенное учреждение социального обслуживания населения «Солнцевский межрайонный центр социальной помощи семье и детям»",
            "col5": "306120, Курская область, Солнцевский район, п. Солнцево, ул. Ленина, д. 16",
            "col6": "Коровина Надежда Васильевна",
            "col7": "http://semya-soln.ru",
            "col8": "8 (47154) 2-26-77",
            "col9": "sol_zhenter@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 4,
            "col4": "Областное казенное учреждение социального обслуживания населения «Щигровский межрайонный центр социальной помощи семье и детям»",
            "col5": "306515, Курская область, Щигровский район, Пригородненский с/с, ул. Комарова, д. 67",
            "col6": "Борзенков Сергей Иванович",
            "col7": "http://guson5.ru",
            "col8": "8 (47145) 4-30-24",
            "col9": "guson5@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 5,
            "col4": "Областное казенное учреждение социального обслуживания населения системы социального обеспечения «Курский областной социально-реабилитационный  центр для несовершеннолетних» ",
            "col5": "305044, Курская область, г. Курск, ул. Краснознаменная, д. 20",
            "col6": "Нарыкова Ирина Николаевна",
            "col7": "http://zentr-nl.ru",
            "col8": "8 (4712) 34-34-80",
            "col9": "zentr-nl@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 6,
            "col4": "Областное казенное учреждение социального обслуживания населения «Курский областной социальный приют для детей и подростков»",
            "col5": "306000, Курская область, Поныровский район, п. Поныри, ул. Октябрьская, д. 119",
            "col6": "Торубарова Валентина Алексеевна",
            "col7": "http://priyut46.ru",
            "col8": "8 (47135) 2-12-87",
            "col9": "guson.priyut@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 7,
            "col4": "Областное казенное учреждение социального обслуживания «Охочевский социальный приют для детей и подростков»",
            "col5": "306504, Курская область, Щигровский район, п. Золотая роща",
            "col6": "Курова Ольга Александровна",
            "col7": "http://ohochevskiy-priyut.ru",
            "col8": "8 (47145) 4-55-41",
            "col9": "ohochevskiy_priyut@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 8,
            "col4": "Областное казенное учреждение социального обслуживания «Черемисиновский социально-реабилитационный центр для несовершеннолетних»",
            "col5": "306440, Курская область, Черемисиновский район, п. Черемисиново, ул. Комсомольская, д. 9",
            "col6": "Золотых Валентина Васильевна",
            "col7": "http://черемисиновский-срцн.рф",
            "col8": "8 (47159) 2-16-61",
            "col9": "kursk8000@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 9,
            "col4": "Областное казенное учреждение «Центр сопровождения замещающих семей и граждан из числа детей-сирот и детей, оставшихся без попечения родителей»",
            "col5": "305044, Курская область, г. Курск, ул. Союзная, д. 29А",
            "col6": "Титова Светлана Анатольевна",
            "col7": "http://centrsopr.ru",
            "col8": "8 (4712) 34-28-98",
            "col9": "otchet20-20@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Курская область",
            "col3": 10,
            "col4": "Областное казенное учреждение социального обслуживания населения «Курский социальный профессионально-реабилитационный центр»",
            "col5": "305000, Курская область, г. Курск, ул.Дзержинского, д. 17",
            "col6": "Гончарова Елена Михайловна",
            "col7": "http://pu-internat.kursk.su",
            "col8": "8 (4712) 70-23-35",
            "col9": "pu_internat@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация дополнительного образования \"Центр социально-психологической поддержки обучающихся \"Утро\"",
            "col5": "399540, Липецкая обл., Тербунский р-н, с. Тербуны, ул. Красноармейская, д. 4",
            "col6": "Булатова Ирина Геннадиевна",
            "col7": "",
            "col8": "8 (920) 515-11-27",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 2,
            "col4": "Автономная некоммерческая организация по оказанию услуг в сфере социально-психологической помощи в адаптации граждан и содействию социальной интеракции общественных отношений \"СОЦИАЛЬНАЯ НОРМА\"",
            "col5": "398046, Липецкая обл., г. Липецк, просп. Победы, д. 106",
            "col6": "Попов Андрей Петрович",
            "col7": "",
            "col8": "8 (903) 031-03-03",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 3,
            "col4": "Автономная некоммерческая организация по альтернативному урегулированию споров и конфликтных ситуаций \"Служба медиации\"",
            "col5": "398059, Липецкая обл., г. Липецк, ул. Первомайская, д. 57",
            "col6": "Ракитин Вячеслав Иванович",
            "col7": "www.primirenie48.com",
            "col8": "8 (950) 807-21-56, 8(920) 526-99-96",
            "col9": "rvi90@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 4,
            "col4": "Липецкая региональная общественная организация по оказанию психологической помощи,социальной поддержки и оздоровлению населения \"Коллегия психологов\"",
            "col5": "398024, Липецкая обл., г. Липецк, ул. Проспект Победы, д. 20, пом. 9",
            "col6": "Мязина Светлана Евгеньевна",
            "col7": "https://psy48.ru/",
            "col8": "8 (920) 242-90-00",
            "col9": "kp@psy48.ru, psicholog48@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 5,
            "col4": "Автономная некоммерческая организация поддержки детей, оказавшихся в трудной жизненной ситуации «Мы вместе»",
            "col5": "399580 Липецкая обл. Воловский р-н с. Волово ул. Советская д. 122",
            "col6": "Афонина Ольга Павловна",
            "col7": "",
            "col8": "8 (906) 687-22-29 8(47473) 2-44-22",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 6,
            "col4": "Автономная некоммерческая организация «Липецкий центр психического развития и оздоровительных систем «МИР ДЕТЕЙ И ВЗРОСЛЫХ»",
            "col5": "398046, Липецкая область, г. Липецк, ул. Смургиса, д. 8, кв. 2",
            "col6": "Семидубер Елена Евгеньевна",
            "col7": "",
            "col8": "8 (919) 165-92-90",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 7,
            "col4": "Автономная некоммерческая организация социальной поддержки людей находящихся в трудной жизненной ситуации «Здоровая молодежь»",
            "col5": "398046, Липецкая область, г. Липецк, ул. Индустриальная, д. 87, помещение 3",
            "col6": "Пименов Александр Геннадьевич",
            "col7": "",
            "col8": "8 (915)851-84-82",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 8,
            "col4": "Липецкая областная общественная организация поддержки детей-сирот и детей, оставшихся без попечения родителей «Становление»",
            "col5": "398000, Липецкая область, г. Липецк, пр. Победы, д. 106",
            "col6": "Попов Андрей Петрович",
            "col7": "",
            "col8": "8 (903) 031-03-03",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 9,
            "col4": "Липецкое региональное отделение «Общероссийской общественно-государственной организации «Фонд защиты детей»",
            "col5": "398024, Липецкая область, г. Липецк, ул. Механизаторов, д. 9",
            "col6": "Батищев Дмитрий Игоревич",
            "col7": "",
            "col8": "8 (910)255-97-75",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 10,
            "col4": "Липецкая региональная благотворительная организация по оказанию помощи людям в трудной жизненной ситуации «Вместе делаем добро»",
            "col5": "398043, Липецкая область, г. Липецк, ул. В. Терешковой, д. 32/3, оф. 117",
            "col6": "Торопцева Надежда Владимировна",
            "col7": "",
            "col8": "8 (915)550-14-55",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Липецкая область",
            "col3": 11,
            "col4": "Автономная некоммерческая организация Центр медиации и права «Правовая миссия»",
            "col5": "398070, Липецкая область, г. Липецк, ул. Политехническая, д. 5, кв. 66",
            "col6": "Яблонская Валентина Евгеньевна",
            "col7": "",
            "col8": "8 (903) 863-40-70",
            "col9": ""
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 1,
            "col4": "Центр медиации и социально правовой помощи ГБУ Ресурсный центр «Содействие» Департамента труда и социальной защиты населения города Москвы",
            "col5": "107023, г. Москва, ул. Суворовская, д.27",
            "col6": "Грачёва Ольга Евгеньевна",
            "col7": "https://mediator.dszn.ru",
            "col8": "8 (499) 369-01-90",
            "col9": "mediarecentre@social.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 2,
            "col4": "Благотворительный фонд помощи детям \"Иван да Марья\"",
            "col5": "109377, г. Москва, ул. Новокузьминская, д. 1я, оф. 19",
            "col6": "Шадрин Александр Борисович",
            "col7": "https://иван-да-марья.рф/",
            "col8": "8 (495) 109-08-99",
            "col9": "info@bf- idm.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 3,
            "col4": "Региональная общественная организация содействия инновациям в педагогике, психологии и медиации «Нравственное поколение»",
            "col5": "105082, г. Москва, ул. Б. Почтовая, д. 36, стр. 10, оф. 103D",
            "col6": "Сурдуляк-Серебренникова Галина Вячеславовна",
            "col7": "https://www.npokoleniye.ru/",
            "col8": "8 (977) 804-93-97",
            "col9": "info@npokole niye.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 4,
            "col4": "Государственное бюджетное учреждение города Москвы \"Городской психолого-педагогический центр Департамента образования и науки города Москвы\"",
            "col5": "109443, г. Москва, Есенинский б-р, д. 12, к. 2",
            "col6": "Олтаржевская Любовь Евгеньевна",
            "col7": "www.gppc.ru",
            "col8": "8 (499) 172-10-19",
            "col9": "gppc@edu.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 5,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства Северного административного округа города Москвы",
            "col5": "125475, Москва, ул. Зеленоградская, д. 35 б",
            "col6": "Пономарева Ирина Николаевна",
            "col7": "https://dszn.ru/department/subordinate/1648",
            "col8": "8 (495) 707-70-17 ",
            "col9": "cpsid-sao@social.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 6,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства Северо-Западного административного округа города Москвы",
            "col5": "125476, г. Москва, ул. Василия Петушкова, д. 19, к. 1",
            "col6": "Ветчинникова Ирина Ивановна",
            "col7": "",
            "col8": "8 (495) 948-50-10",
            "col9": "cpsid-szao@social.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 7,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства \"Красносельский\" Центрального административного округа города Москвы",
            "col5": "107078, г. Москва, Скорняжный пер., д. 4",
            "col6": "Кабанов Владимир Львович",
            "col7": "",
            "col8": "8 (499) 975-23-33",
            "col9": "src-krasnoselskiy@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 8,
            "col4": "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям \"Измайлово\" Восточного административного округа города Москвы",
            "col5": "105037, г. Москва, Измайловский проезд, д. 4а",
            "col6": "Рыбакова Наталия Владимировна",
            "col7": "",
            "col8": "8(495)603-96-06",
            "col9": "cspsid-izmailovo@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 9,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства \"Печатники\" Юго-Восточного административного округа города Москвы",
            "col5": "109388, г. Москва, ул. Гурьянова, д. 55",
            "col6": "Иосебадзе Лиана Шалвовна",
            "col7": "",
            "col8": "8(499) 722 44 75",
            "col9": "cspsid-pechatniki@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 10,
            "col4": "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям \"Палитра\" Западного административного округа города Москвы",
            "col5": "121351, г. Москва, ул. Кунцевская, д. 17",
            "col6": "Шаравьева Галина Николаевна",
            "col7": "",
            "col8": "8(495)416 84 34",
            "col9": "cspsid-kuntsevo@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 11,
            "col4": "Государственное бюджетное учреждение города Москвы Городской ресурсный Центр поддержки семьи и детства \"Отрадное\" ДТСЗН города Москвы",
            "col5": "127273, г. Москва, ул. Декабристов, д. 22 а",
            "col6": "Гончарова Ирина Павловна",
            "col7": "",
            "col8": "8(499)907-57-90",
            "col9": "srcotradnoe@social.mos.ru "
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 12,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства \"Планета семьи\" Южного административного округа города Москвы",
            "col5": "115563, г. Москва, Борисовский проезд, д. 15, к. 3",
            "col6": "Скуратова Евгения Владимировна",
            "col7": "",
            "col8": "8 (495) 390-53-54",
            "col9": "planetas@social.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 13,
            "col4": "Государственное бюджетное учреждение города Москвы Центр поддержки семьи и детства \"Зеленоград\" Зеленоградского административного округа",
            "col5": "124617, г. Москва, Зеленоград, к. 1426",
            "col6": "Емельянов Борис Борисович",
            "col7": "",
            "col8": "8(499)717-04-95",
            "col9": "cpsid-zelenogrd@social.mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 14,
            "col4": "Государственное бюджетное учреждение города Москвы Центр социальной помощи семье и детям \"Гелиос\"",
            "col5": "117623, г. Москва, ул. Старонародная, д. 2",
            "col6": "Климкина Ольга Евгеньевна",
            "col7": "",
            "col8": "8 (495) 712-59-00",
            "col9": "cspsid-gelios@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 15,
            "col4": "Государственное бюджетное учреждение города Москвы Центр социального обслуживания \"Щербинский\" Троицкого и Новомосковского административных округов города Москвы",
            "col5": "117148, г. Москва, ул. Маршала Савицкого, д. 26, корп. 2 ",
            "col6": "Тютюкина Наталья Владимировна",
            "col7": "",
            "col8": "8 (499) 232-32-80",
            "col9": "tsco-shcherbinskiy@mos.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 16,
            "col4": "Автономная некоммерческая организация «Научно-методический центр медиации и права»",
            "col5": "123557, г.Москва, Б.Тишинский переулок, д.26, корп. 13-14, стр.1",
            "col6": " Азизова Ирина Владимировна",
            "col7": "www.mediacia.com",
            "col8": "8 (499) 253-01-30",
            "col9": "office@mediacia.com"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 17,
            "col4": "Центр правовой помощи и медиации «СТРИКС»",
            "col5": "119261 г. Москва, Ленинский проспект, 70/11, эт.1, пом. XXXIII, ком.1, 1а",
            "col6": "Смирнов Петр Станиславович",
            "col7": "https://strix-legalcentr.ru",
            "col8": "8 (910) 009-09-12",
            "col9": "info@strix-legalcentr.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 18,
            "col4": "Благотворительный фонд оказания помощи несовершеннолетним и социально незащищенным лицам «Фонд Ольги и Клавдии»",
            "col5": "121309, Москва, ул. Барклая, д.17",
            "col6": "Роженцева Татьяна Валерьевна",
            "col7": "",
            "col8": "8 (917) 56-69 8(495) 690-71-69",
            "col9": "ALIRCONNECTION@HOTMAIL.COM"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 19,
            "col4": "Автономная некоммерческая организация «Межрегиональный социально-реабилитационный центр семейного типа для несовершеннолетних и молодежи «Страна Живых»",
            "col5": "124617, Москва, г. Зеленоград, к. 1414, кв. 3",
            "col6": "Кореняк Дарья Дмитриевна",
            "col7": "https://landoflife.ru/",
            "col8": "8(495) 778-84-21, 8(903) 795-74-51, 8(901) 578-84-21",
            "col9": "dom@landoflife.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 20,
            "col4": "Автономная некоммерческая организация «Центр социально-правовой помощи несовершеннолетним «СОТИС»",
            "col5": "121165, Москва, ул. Киевская, д. 22, эт. 1, пом. 103, комн. 7А, оф. 2",
            "col6": "Румянцев Вячеслав Алексеевич",
            "col7": "",
            "col8": "8(495) 798-17-41",
            "col9": "sposotis@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 21,
            "col4": "Некоммерческая организация Благотворительный фонд помощи несовершеннолетним «Благовест»",
            "col5": "129090, Москва, ул. Щепкина, 25/20",
            "col6": "Степанов Владимир Витальевич",
            "col7": "https://ano-socfactor.ru/about/",
            "col8": "",
            "col9": ""
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 22,
            "col4": "Фонд поддержки детей, находящихся в трудной жизненной ситуации",
            "col5": "129344, г. Москва, ул. Енисейская, д. 2, стр. 1, пом. 9",
            "col6": "Гордеева Марина Владимировна",
            "col7": "https://fond-detyam.ru",
            "col8": "8 (495) 374-53-06",
            "col9": "info@fond-detyam.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 23,
            "col4": "Благотворительный фонд помощи детям-сиротам, инвалидам и людям в трудной жизненной ситуации «Река Детства»",
            "col5": "117133, Москва, ул. Академика Варги, д. 3, кв. 207",
            "col6": "Поздеева Ольга Павловна",
            "col7": "https://reka-detstva.ru/",
            "col8": "8 (985) 774-24-08",
            "col9": "info@reka-detstva.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 24,
            "col4": "Благотворительный фонд помощи детям, находящимся в трудной жизненной ситуации «Открытые Сердца»",
            "col5": "123458, Москва, ул. Таллинская, д. 32, корп. 3, кв. 256",
            "col6": "Подгорный Андрей Львович",
            "col7": "https://bf-openhearts.ru/",
            "col8": "8 (916) 015-82-01",
            "col9": "otserdca@list.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 25,
            "col4": "Благотворительный Фонд помощи людям, оказавшимся в трудной жизненной ситуации, «ТЫ ВАЖЕН»",
            "col5": "107078, Москва, Басманная Нов. ул., д. 21, стр. 1, пом. 19, эт. 1",
            "col6": "Балобанова Ксения Александровна",
            "col7": "https://vk.com/blagofond_tyvazhen",
            "col8": "8 (912) 445-33-93",
            "col9": "ti.vazhen@gmail.com"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 26,
            "col4": "Благотворительный фонд поддержки детей - сирот, детей, оставшихся без попечения родителей, семей, находящихся в трудной жизненной ситуации «Чудотворцы»",
            "col5": "129281, Москва, Енисейская ул., д. 26, кв. 7",
            "col6": "Дувакина Людмила Николаевна",
            "col7": "https://fond-chudo.ru/",
            "col8": "8 (977) 471-50-41, 8 (903) 130-51-79, 8 (915) 353-60-50",
            "col9": "alabai_ka@bk.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Москва",
            "col3": 27,
            "col4": "Национальная Ассоциация семейных медиаторов (НАСМ)",
            "col5": "119261, г. Москва, Ленинский проспект, д. 72/2, пом. 20П",
            "col6": "Бушмелева Ирина Валерьевна",
            "col7": "https://mediacia.pro, https://vk.com/mediationru ",
            "col8": "+7(985)879-90-90",
            "col9": "vash_mediator@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Московская область ",
            "col3": 1,
            "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Центр переговорных практик, медиации и психологии «ДИАЛОГ ПРО (профессионал)»",
            "col5": "141301, Московская область, г. Егорьевск, ул. Советская, д. 105/7, помещ. 1",
            "col6": "Светлова Мария Сергеевна",
            "col7": "https://dialog-professional.ru/",
            "col8": "8 (926) 693-93-83",
            "col9": ""
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Орловская область",
            "col3": 1,
            "col4": "Программа профилактики социального сиротства «Укрепление семьи» в Орловской области, детская деревня «SOS-Лаврово»",
            "col5": "302010, Орловская область, г. Орёл, ул. Комсомольская, д. 322 А",
            "col6": "Леонова Елена Олеговна",
            "col7": "https://sos-dd.ru/",
            "col8": "8 (910) 748-00-56, 8(910) 201-92-92",
            "col9": "ddsos-lavrovo@sos-dd.org, info@sos-dd.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Орловская область",
            "col3": 2,
            "col4": "Орловское региональное отделение межрегиональной общественной организации в поддержку людей с ментальной инвалидностью и психофизическими нарушениями «Равные возможности»",
            "col5": "302001, Орловская область, г. Орёл, ул. Гагарина, д. 9, пом. 3",
            "col6": "Гонтарь Андрей Геннадьевич",
            "col7": "http://rvoz-orel.ru/",
            "col8": "8(930)869-23-69, 8 (930)063-00-93",
            "col9": "rvoz-orel@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Рязанская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация альтернативного разрешения споров \"Рязанский центр медиации и права\"",
            "col5": "390005, Рязанская обл., г. Рязань, ул. 1-я Железнодорожная, д.60А, пом. Н1",
            "col6": "Гараев Самир Нариман Оглы",
            "col7": "http://mediator-rzn.ru/inner/main/, http://http.www.mediatorrzn.tilda.ws/",
            "col8": "8 (4912) 96-01-32, 8 ( 910) 96-01-32, 8 (910) 901-25-65",
            "col9": "mediator-rzn@mail.ru garsam@mail.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 1,
            "col4": "Смоленское областное государственное бюджетное учреждение «Демидовский социально-реабилитационный центр для несовершеннолетних «Исток»",
            "col5": "216240, Смоленская область, Демидовский район, дер. Титовщина, ул. Центральная, д. 21",
            "col6": "Степанова Елена Владимировна",
            "col7": "http://www.istok-67.ru",
            "col8": "8 (48147) 2-21-37",
            "col9": "dsrc-istok@ rambler.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 2,
            "col4": "Смоленское областное государственное бюджетное учреждение «Вяземский социально-реабилитационный центр для несовершеннолетних «Гармония»",
            "col5": "215110, Смоленская область, г. Вязьма, ул. 25 Октября, д. 1а",
            "col6": "Комарова Елена Евгеньевна",
            "col7": "www.centr-garmonia.ru",
            "col8": "8 (48131) 4-21-35, 4-25-01, 2-38-27",
            "col9": "centr_garmonia@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 3,
            "col4": "Смоленское областное государственное бюджетное учреждение «Рославльский социально-реабилитационный центр для несовершеннолетних «Теремок»",
            "col5": "216500, Смоленская область, г. Рославль,ул. Нахимова, д. 49а",
            "col6": "Белевитнев Сергей Иванович",
            "col7": "http://teremokroslavl.ru",
            "col8": "8 (48134) 4-19-92",
            "col9": "teremokroslavl@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 4,
            "col4": "Областное государственное  бюджетное учреждение «Смоленский социально-реабилитационный центр для несовершеннолетних «Феникс»",
            "col5": "214031, Смоленская область,г. Смоленск, ул. Рыленкова, д. 8",
            "col6": "Шахова Ирина Александровна",
            "col7": "http://www.smolfeniks.ru",
            "col8": "8 (4812) 61-08-75",
            "col9": "smolfeniks@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 5,
            "col4": "Смоленское областное государственное бюджетное учреждение «Сычевский социально-реабилитационный центр для несовершеннолетних «Дружба»",
            "col5": "215280, Смоленская область,г. Сычевка,ул. Алексеевского,д. 9",
            "col6": "Серебренникова Анна Владимировна",
            "col7": "http://drujba-sychevka.ru/",
            "col8": "8 (48130) 4-24-39",
            "col9": "drujba-sychevka@rambler.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 6,
            "col4": "Смоленское областное государственное бюджетное учреждение «Гагаринский социально-реабилитационный центр для несовершеннолетних «Яуза»",
            "col5": "215047, Смоленская область, Гагаринский район, с. Карманово,ул. Октябрьская,д. 8",
            "col6": "Горюнова Ольга Сергеевна",
            "col7": "http://centr-yauza.ru",
            "col8": "8 (48135) 7-78-59, 7-79-23",
            "col9": "gag.centr-yauza@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 7,
            "col4": "Смоленское областное государственное бюджетное учреждение «Дорогобужский социально-реабилитационный центр для несовершеннолетних «Родник»",
            "col5": "215750, Смоленская область, Дорогобужский район, п. Верхнеднепровский, ул. Молодежная,д. 17",
            "col6": "Хотькина Надежда Викторовна",
            "col7": "http://rodnikdor.ru/index.php",
            "col8": "8 (48144) 5-40-95",
            "col9": "rodnikdor@rambler.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 8,
            "col4": "Смоленское областное государственное бюджетное учреждение «Духовщинский социально-реабилитационный центр для несовершеннолетних «Ласточка»",
            "col5": "216230, Смоленская область, Духовщинский район,с. Пречистое,ул. Октябрьская,д. 16",
            "col6": "Гагаева Елена Яковлевна",
            "col7": "http://lastochka67.ru",
            "col8": "8 (48166) 2-82-59",
            "col9": "prlastocka@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 9,
            "col4": "Смоленское областное государственное бюджетное учреждение «Ярцевский социально-реабилитационный центр для несовершеннолетних «Радуга»",
            "col5": "215800, Смоленская область, г. Ярцево, ул. М. Горького,д. 12а",
            "col6": "Петрова Олеся Николаевна",
            "col7": "http://raduga-yartsevo.ru",
            "col8": "8 (48143) 7-27-57",
            "col9": "raduga-yartsevo@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 10,
            "col4": "Смоленское областное государственное бюджетное учреждение «Десногорский центр социальной помощи семье и детям «Солнышко»",
            "col5": "216401, Смоленская область,г. Десногорск, 4 микрорайон",
            "col6": "Шаповалова Людмила Михайловна",
            "col7": "http://solnyshko67.ru",
            "col8": "8 (48153)3-15-33, 7-24-96",
            "col9": "super.solnyshko1999@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 11,
            "col4": "Смоленское областное государственное бюджетное учреждение «Ново-Никольский детский дом-интернат для умственно отсталых детей»",
            "col5": "215110, Смоленская область, Вяземский р-н, с. Ново-Никольское",
            "col6": "Двораковская Марина Александровна",
            "col7": "http://novo.edusite.ru/p1aa1.html",
            "col8": "8 (4813)12-48-42",
            "col9": "novonik_det_dom@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 12,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования «Центр дополнительного образования № 1» города Смоленска",
            "col5": "214012, Смоленская область, город Смоленск, Витебское шоссе, д. 42",
            "col6": "Романов Александр Кириллович",
            "col7": "http://cdo1-smol.ru/",
            "col8": "8 (4813) 27-03-14",
            "col9": "smolcdo1@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Смоленская область",
            "col3": 13,
            "col4": "Муниципальное бюджетное учреждение «Ярцевский молодежный центр»",
            "col5": "215805,Смоленская область, г. Ярцево, ул. Автозаводская, д. 43",
            "col6": "Аверьянова Галина Геннадьевна",
            "col7": "www.molzentr215.wix.com/molj",
            "col8": "8 (48143) 3-17-99, ",
            "col9": "molzentr215@yandex.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тамбовская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация дополнительного профессионального образования \"Центр правовых примирительных процедур\"",
            "col5": "392000, Тамбовская обл, г. Тамбов, ул. Державинская, д. 16А, пом. 52",
            "col6": "Судоргина Елена Валерьевна",
            "col7": "",
            "col8": "8 (4752) 49-21-62, 8 (910) 656-81-91",
            "col9": "ucforum2009@yandex.ru cpp.mediacia@yandex.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тверская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация «Центр правовой помощи, альтернативных способов урегулирования споров и медиации»",
            "col5": "170100, Тверская обл., г. Тверь, ул. Вольного Новгорода, д. 13",
            "col6": "Шахбазян Александр Михайлович",
            "col7": "http://tversud.com",
            "col8": "8 (904) 000-16-27",
            "col9": "tversud@yandex.ru"
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 1,
            "col4": "Ассоциация дополнительного профессионального образования «Научно-исследовательский образовательный центр»",
            "col5": "300041, г. Тула, ул. Дмитрия Ульянова, д. 15",
            "col6": "Дорошенко Инна Сергеевна",
            "col7": "http://www.ass-dpo-nioc.ru",
            "col8": "8 (903) 840-75-45",
            "col9": "doroshenko_tula@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 2,
            "col4": "Ассоциация дополнительного профессионального образования «Научно-исследовательский центр медиации»",
            "col5": "300901, г. Тула, Гарнизонный проезд, д. 2 г, кв. 88",
            "col6": "Дорошенко Инна Сергеевна",
            "col7": "http://www.ass-dpo-nioc.ru",
            "col8": "8 (903) 840-75-45",
            "col9": "doroshenko_tula@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 3,
            "col4": "Национальная ассоциация профессиональных медиаторов «Паритет»",
            "col5": "117342, г. Москва, ул. Бутлерова, д. 17б, помещение 1, оф. 120",
            "col6": "Дубинина Юлия Александровна",
            "col7": "http://mediation-parity.ru",
            "col8": "8 (920) 761-35-55",
            "col9": "4334_71@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 4,
            "col4": "Муниципальное казенное учреждение «Центр обеспечения деятельности системы образования Щекинского района»",
            "col5": "301243, Тульская область, Щекинский район, г. Щекино, ул. Заводская, д. 34",
            "col6": "Гуляева Галина Алексеевна",
            "col7": "http://mmschekino.ru",
            "col8": "8 (48751) 5-63-42",
            "col9": "sh.mku.cod@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 5,
            "col4": "Муниципальное учреждение дополнительного образования «Центр сопровождения детей»",
            "col5": "301835, Тульская область, г. Богородицк, ул. Пролетарская, д. 59",
            "col6": "Николаева Ирина Сергеевна",
            "col7": "www.centr-russia71.ru",
            "col8": "8 (48761) 2-24-49",
            "col9": "mudotcsd.bogorod@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 6,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования «Центр психолого-педагогического и социального сопровождения»",
            "col5": "300002, Тульская область, г. Тула, ул. Галкина, д. 17",
            "col6": "Панферова Ольга Валерьевна",
            "col7": "https://psyhologcentr.ru/",
            "col8": "8 (4872) 473-101",
            "col9": "psyholog_centr@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 7,
            "col4": "Муниципальное учреждение «Центр обеспечения деятельности системы образования»",
            "col5": "301840, Тульская область, г. Ефремов, ул. Свердлова, д. 45",
            "col6": "Гладких Валентина Васильевна",
            "col7": "http://ddyut.ru",
            "col8": "8 (48741) 6-26-62",
            "col9": "ddt.efremov@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 8,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования муниципального образования Плавский район «Центр психолого-педагогической, медицинской и социальной помощи «Доверие»",
            "col5": "301470, Тульская область, г. Плавск, ул. Ленина, д. 4",
            "col6": "Шибаев Олег Алексеевич",
            "col7": "http://doverieplavsk.narod.ru",
            "col8": "8 (48752) 6-55-25",
            "col9": "doverieplavsk@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 9,
            "col4": "Государственное учреждение Тульской области «Областной центр социальной помощи семье и детям»",
            "col5": "300034, г. Тула, ул. Демонстрации, д. 11",
            "col6": "Шубинская Эвелина Борисовна",
            "col7": "http://www.kriziscentr71.ru",
            "col8": "8 (4872) 309-778",
            "col9": "tcson.kriziscentr@tularegion.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 10,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования «Детский оздоровительно-образовательный центр»",
            "col5": "301664, Тульская область, г. Новомосковск, ул. Первомайская, д. 21",
            "col6": "Ковтун Михаил Владимирович",
            "col7": "http://dooc-nsk.ucoz.ru/",
            "col8": "8 (48762) 2-44-40, 8 (48762) 2-43-33, 8 (950) 910-94-45",
            "col9": "dooc.nmsk@tularegio.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 11,
            "col4": "Муниципальное учреждение «Центр обеспечения деятельности системы образования» муниципальное образование Веневский район",
            "col5": "301320, Тульская область, г. Венев, ул. Володарского, д. 19",
            "col6": "Гречишкина Ирина Владимировна",
            "col7": "http://obr71.ru/",
            "col8": "8 (953) 191-60-00, 8 (48745) 2-55-21",
            "col9": "obrvenev@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 12,
            "col4": "Муниципальное казенное учреждение дополнительного образования «Дворец детского (юношеского) творчества»",
            "col5": "301840, Тульская область, г. Ефремов, ул. Свердлова, д. 45",
            "col6": "Гладких Валентина Васильевна",
            "col7": "http://ddyut.ru",
            "col8": "8 (48741) 6-26-62",
            "col9": "ddt.efremov@tularegion.org"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Тульская область",
            "col3": 13,
            "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Высшая школа медиации»",
            "col5": "300053, Тульская область, г. Тула, 1-ой проезд М. Расковой, д. 14",
            "col6": "Едунова Ольга Юрьевна",
            "col7": "",
            "col8": "8 (905) 628-88-73",
            "col9": ""
        },
    ], [
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 1,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Вертикаль»",
            "col5": "150536, Ярославская область, Ярославский район, с. Ширинье, ул. Молодежная, д. 5",
            "col6": "Киселева Ольга Маисовна",
            "col7": "http://vertical.h707.ru/",
            "col8": "8 (4852) 43-54-62",
            "col9": "centr.vertikal@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 2,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области Рыбинский социально-реабилитационный центр «Наставник»",
            "col5": "152934, Ярославская область, г. Рыбинск, ул. Пушкина, д. 61",
            "col6": "Савин Геннадий Ильич",
            "col7": "https://nastavnik-ryb.ru/",
            "col8": "8(4855) 28-25-44",
            "col9": "gisavin@yandex.ru nastavnik-ryb@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 3,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Медвежонок»",
            "col5": "150031,Ярославская область, г. Ярославль, ул. Юности, д. 18",
            "col6": "Драгушан Елена Михайловна",
            "col7": "http://yarsrc.ru/",
            "col8": "8 (4852) 51-83-09, 8 (4852) 51-98-96",
            "col9": "src-det@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 4,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области Большесельский социально-реабилитационный центр «Колосок»",
            "col5": "152360, Ярославская область, Большесельский р-н, с. Большое Село, ул. 1-ая Строителей, д. 1а",
            "col6": "Окунева Светлана Геннадьевна",
            "col7": "http://срц-колосок.рф/",
            "col8": "8 (48542) 2-11-38",
            "col9": "kolosok_bselo@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 5,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Радуга»",
            "col5": "152610, Ярославская область, г. Углич, ул. Победы, д. 14-«А»",
            "col6": "Рыбакова Юлия Викторовна",
            "col7": "http://radugauglich.ru/",
            "col8": "8 (48532) 2-34-21",
            "col9": "raduga@radugauglich.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 6,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Бригантина»",
            "col5": "152030, Ярославская область, Переславский район, с. Нагорье, ул. Адмирала Спиридова, д. 7",
            "col6": "Виноградова Алла Николаевна",
            "col7": "http://срц-бригантина.рф/",
            "col8": "8 (48535) 4-64-94",
            "col9": "srcbrigantina@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 7,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области Тутаевский социально-реабилитационный центр",
            "col5": "152300, Ярославская область, г. Тутаев, ул. Комсомольская, д. 56",
            "col6": "Балдина Евгения Николаевна",
            "col7": "http://срц-тутаевский.рф",
            "col8": "8 (48533) 2-17-43",
            "col9": "sochelp@list.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 8,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Росинка»",
            "col5": "152120, Ярославская область, Ростовский район, р.п. Ишня, ул. Комсомольская, д.11",
            "col6": "Горбунова Юлия Николаевна",
            "col7": "http://срц-росинка.рф/",
            "col8": "8 (48536) 29-4-92",
            "col9": "priutros@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 9,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Искорка»",
            "col5": "152174, Ярославская область, Борисоглебский район, с. Вощажниково, ул. Советская, д. 41",
            "col6": "Корсакова Татьяна Николаевна",
            "col7": "https://src-iskorka.ru/",
            "col8": "8 (48539) 3-36-32, 8 (48539) 3-35-68, 8 (48539) 3-36-40",
            "col9": "priut53@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 10,
            "col4": "Государственное казенное учреждение социального обслуживания Ярославской области социально-реабилитационный центр «Дом детства»",
            "col5": "152760, Ярославская область, Брейтовский р-н, с. Брейтово, ул. Солнечная, д.18",
            "col6": "Бублина Евгения Анатольевна",
            "col7": "http://domdetswa.h707.ru/",
            "col8": "8 (48545) 2-15-71, 8 (48545) 2-19-31",
            "col9": "domdetswa@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 11,
            "col4": "Автономная некоммерческая организация социально-педагогической помощи молодежи в трудных жизненных ситуациях «МОЁ БУДУЩЕЕ» ",
            "col5": "150049, Ярославская область, г. Ярославль проезд Ухтомского,д. 4, кв. 1",
            "col6": "Камкина Марина Николаевна",
            "col7": "https://www.moebudushee.com",
            "col8": "8 (961) 974-70-44, 8 (903) 638-12-17",
            "col9": "malvina6@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 12,
            "col4": "Автономная некоммерческая организация «Центр социальных проектов «Участие»",
            "col5": "150040, Ярославская область, г. Ярославль, ул. Кузнецова, д. 1, офис 102.",
            "col6": "Семенов Антон Владимирович",
            "col7": "http://аутизм.su",
            "col8": "8 (4852) 252551,",
            "col9": "e-mail: uchastie76@mail.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 13,
            "col4": "Автономная некоммерческая организация «Агентство социальной поддержки семьи и защиты семейных ценностей «Моя семья»",
            "col5": "150040, Ярославская область, г. Ярославль, пр-т Ленина, 25, офис 210;150003, г. Ярославль, ул. Фурманова, 1",
            "col6": "Рассол Юлия Александровна",
            "col7": "http://asp-moyasemya.ru ",
            "col8": " 8 (4852) 997999 ",
            "col9": "anomoysemya@yandex.ru"
        },
        {
            "col1": "Центральный федеральный округ",
            "col2": "Ярославская область",
            "col3": 14,
            "col4": "Ярославская региональная общественная организация инвалидов «Лицом к миру»",
            "col5": "150000, Ярославская область, г. Ярославль, ул. Чкалова, 21,",
            "col6": " Жужнева Наталия Леонидовна",
            "col7": "https://licomkmiru.ru",
            "col8": 79066361825,
            "col9": "licomkmiru@mail.ru nzhyzhneva@mail.ru"
        }
    ]
    ],
    [
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": " Архангельская область",
                "col3": 1,
                "col4": "Ассоциация медиаторов Архангельской области",
                "col5": "163051, Архангельская обл., г. Архангельск, ул. Тимме, д. 26",
                "col6": "Пиличева Екатерина владимировна",
                "col7": "",
                "col8": "8 (8182) 47-44-00 8 (953) 267-30-24 8 (902) 700-20-53",
                "col9": "mediator29@bk.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": " Архангельская область",
                "col3": 2,
                "col4": "Ассоциация «Архангельская региональная ассоциация медиаторов»",
                "col5": "163000, Архангельская обл., г. Архангельск, наб. Северной Двины, д. 55, блок А, офис 712",
                "col6": "Спиридонова Татьяна Александровна",
                "col7": "",
                "col8": "8 (902) 190-73-19",
                "col9": "ARH@URSMARKET.RU"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": " Архангельская область",
                "col3": 3,
                "col4": "Государственное бюджетное учреждение Архангельской области для детей, нуждающихся в психолого-педагогической и медико-социальной помощи, «Центр психолого-медико-социального сопровождения «Надежда»",
                "col5": "163069, Архангельск, ул. Попова, д.43",
                "col6": "Соколова Татьяна Аркадьевна",
                "col7": "https://nadejdaarh.ru/",
                "col8": "8(8182) 20-62-80",
                "col9": "centr_nadejda@mail.ru"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Вологодская область",
                "col3": 1,
                "col4": "Благотворительный фон «Дорога к дому»",
                "col5": "162600, Вологодская область, г. Череповец, ул. Юбилейная, д. 26",
                "col6": "Фрыгина Екатерина Николаевна",
                "col7": "https://dorogakdomu.ru",
                "col8": "8(8202) 201-465, 8(8202) 288-588, 8(8202) 201-470",
                "col9": "info@dorogakdomu.ru"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Калининградская область",
                "col3": 1,
                "col4": "Калининградская региональная детско-молодежная общественная организация \"Юная лидерская армия\"",
                "col5": "236039, Калининградская обл, г. Калининград, ул. Багратиона, д. 39, оф 10",
                "col6": "Осипенко Виктория Евгеньевна",
                "col7": "http://ngoyla39.ru",
                "col8": "8(4012) 33-70-34, + 7 906 211 70 80",
                "col9": "ylakaliningrad@yandex.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Калининградская область",
                "col3": 2,
                "col4": "Калининградская региональная молодежная общественная организация \"Сатори\"",
                "col5": "238520, Калининградская обл, Балтийский р-н, г. Балтийск, ул. Ушакова, д. 26, кв. 27 , факт. Адрес: г. Калининград, ул. Загорская, 1-5",
                "col6": "Яценко Ирина Геннадьевна",
                "col7": "https://satorinko.ru/",
                "col8": "8(906) 231-25-60",
                "col9": "satoribaltika@mail.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Калининградская область",
                "col3": 3,
                "col4": "Калининградская региональная общественная организация \"Молодёжных и студенческих инициатив\"",
                "col5": "236022, Калининградская обл, г Калининград, ул К.Маркса, д. 10, пом. 22",
                "col6": "Баканов Валерий Алексеевич",
                "col7": "",
                "col8": "8(909)776-23-26 8(952)110-36-56",
                "col9": "bakanov.valeriy@bk.ru lidiachemakovaf@gmail.com"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Калининградская область",
                "col3": 4,
                "col4": "Калининградская региональная общественная организация «Союз женщин Калининградской области»",
                "col5": "236022, Калининградская обл, г. Калининград, ул. Красная, 35, офис 1",
                "col6": "Матюнина Марина Юрьевна",
                "col7": "http://vk.com/public211468079",
                "col8": "8 (4012) 95-84-38",
                "col9": ""
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Ленинградская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация «Центр юридической помощи и медиации «Кодекс и Слово»",
                "col5": "188654, Ленинградская область, Всеволожский район, Куйвозовское сельское поселение, пос. Стеклянный, д. 35",
                "col6": "Директор Карабицкий Александр Александрович",
                "col7": "",
                "col8": "8 (2193)7-99-08",
                "col9": "info@impere.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Ленинградская область",
                "col3": 2,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Центр медиации»",
                "col5": "187320, Ленинградская область, Кировский район, Шлиссельбургское городское поселение, г. Шлиссельбург, ул. Красный тракт, д. 30А, офис 22",
                "col6": "Директор Филюта Вероника Витальевна",
                "col7": "www.193fz.ru",
                "col8": "8 (911) 928-62-40 8(921) 919-17-74",
                "col9": "9286240@mail.ru  mediatorlo@mail.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Ленинградская область",
                "col3": 3,
                "col4": "Автономная некоммерческая организация Центр научно-практической медиации «Согласие",
                "col5": "188680, Ленинградская область, Всеволожский район, село Павлов, ул. Быкова, д. 17, кв. 16",
                "col6": "Директор Лавров Василий Васильевич",
                "col7": "",
                "col8": "8 (921) 952-67-10",
                "col9": ""
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Мурманская область",
                "col3": 1,
                "col4": "Частное учреждение социального обслуживания «Социальный центр – SOS Мурманск»",
                "col5": "183025, г. Мурманск, ул. Полярные Зори, д. 31/1",
                "col6": "Кулькова Татьяна Николаевна",
                "col7": "https://sos-dd.ru/what/families/ ",
                "col8": "8 (8152) 65 54 86 ",
                "col9": "ddsos-centre-murmansk@sos-dd.org "
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Новгородская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация \"Центр экспертно-правовой помощи и разрешения конфликтов\"",
                "col5": "173526, Новгородская обл., Новгородский р-н, р.п. Панковка, ул. Промышленная, д. 13а ",
                "col6": "Шибанова Татьяна Борисова",
                "col7": " ",
                "col8": "8 (921) 201-79-00 8 (8162) 90-04-94",
                "col9": "tashibanova@yandex.ru"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Псковская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация \"Центр правового просвещения и медиации\"",
                "col5": "180000, Псковская обл., г. Псков, ул. Ленина, д. 15, кв. 2",
                "col6": "Никонов Семен Юрьевич",
                "col7": "www.csdpr.ru  ",
                "col8": "8 (911) 351-33-98  8(8112) 29-10-95",
                "col9": "CSDPR@csdpr.ru"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Карелия",
                "col3": 1,
                "col4": " Муниципальное бюджетное образовательное учреждение для детей, нуждающихся в психолого-педагогической и медико-социальной помощи Сортавальского муниципального района Республики Карелия Центр психолого-медико-социального сопровождения",
                "col5": "186790, Республика Карелия, г. Сортавала, ул. Суворова, д.2",
                "col6": "Чадранцева Софья Вениаминовна",
                "col7": "http://cpmss-sortavala.ucoz.ru/",
                "col8": "8 (81430) 4-60-12",
                "col9": "cpmsssort@yandex.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Карелия",
                "col3": 2,
                "col4": "Автономная некоммерческая организация по оказанию юридических услуг «Презумпция»",
                "col5": "185030, Республика Карелия, г. Петрозаводск, ул. Лизы Чайкиной, д. 12А, кв. 16 факт. Адрес: 185000, Республика Карелия, г. Петрозаводск, пр. Ленина, д. 22А",
                "col6": "Воронцов Сергей Викторович",
                "col7": "http://prezumpciya.su",
                "col8": "8 (900) 461-12-85",
                "col9": "prezumpciya@bk.ru "
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Карелия",
                "col3": 3,
                "col4": "Региональная общественная организация «Карельский Союз защиты детей»",
                "col5": "185035, Республика Карелия, г. Петрозаводск, пр. Ленина, д. 22А",
                "col6": "Юнилайнен Олег Викторович",
                "col7": "http://rookszd.ru ",
                "col8": "8 (8142) 76-10-85",
                "col9": "kszd1994@yandex.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Карелия",
                "col3": 4,
                "col4": "Карельский фонд развития образования",
                "col5": "185030, Республика Карелия, г. Петрозаводск, пр. А. Невского, д. 71, офис 212",
                "col6": "Сараева Екатерина   Вячеславовна",
                "col7": "http://kfro.karelia.pro",
                "col8": "8 (911) 400-53-92",
                "col9": "kedfund@gmail.com"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Карелия",
                "col3": 5,
                "col4": "Автономная некоммерческая организация «Семейный центр «Вереск»",
                "col5": "185035, Республика Карелия, г. Петрозаводск, ул. Куйбышева, д. 18А, кв. 5",
                "col6": "Сараева Екатерина   Вячеславовна",
                "col7": "http://veresk-karelia.ru",
                "col8": "8 (8142) 63-53-92",
                "col9": "boikatia@mail.ru"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 1,
                "col4": "Государственное образовательное учреждения дополнительного профессионального образования «Коми республиканский институт развития образования»",
                "col5": "167982, Республика Коми г. Сыктывкар, ул. Орджоникидзе, д. 23",
                "col6": "Китайгородская Галина Владимировна",
                "col7": "https://kriro.ru",
                "col8": "8 (8212) 28-60-11 (вн. 301)",
                "col9": "kriro@minobr.rkomi.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 2,
                "col4": "Государственное учреждение Республики Коми «Республиканский центр психолого-педагогической, медицинской и социальной помощи»",
                "col5": "167004, Республика Коми, г. Сыктывкар, ул. Пушкина, д. 89",
                "col6": "Скокова Марина Николаевна",
                "col7": "https://ppmsp.rkomi.ru/",
                "col8": "8 (8212) 301-357",
                "col9": "centerpprk@mail.ru rcpprik@minobr.rkomi.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 3,
                "col4": "Муниципальное учреждение дополнительного образования «Центр психолого-педагогической, медицинской и социальной помощи» г. Сыктывкара",
                "col5": "167031, Республика Коми, г. Сыктывкар, ул. Чкалова, д.24; 167018, Республика Коми, г.Сыктывкар, ул. Космонавтов, д.14 каб.110.",
                "col6": "Писцова Светлана Дмитриевна",
                "col7": "www.cppmisp.ucoz.com",
                "col8": "8 (8212) 24-10-82 8 (8212) 21-91-37 8 (8212) 62-94-55",
                "col9": "cpprik@bk.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 4,
                "col4": "Региональное объединение работодателей \"Коми союз промышленников и предпринимателей\" ",
                "col5": "167000, Республика Коми, г. Сыктывкар, ул. Первомайская, стр. 62, офис 32",
                "col6": "Кулаков Сергей Анатольевич",
                "col7": "https://komi.rspp.ru/",
                "col8": "8 (8212) 24-42-41 8 (8212) 30-10-20",
                "col9": "komispp@yandex.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 5,
                "col4": "Региональное отделение Общероссийской общественной организации \"Ассоциация юристов России\" в Республике Коми ",
                "col5": "167000, Республика Коми, г. Сыктывкар, ул. Интернациональная, д. 108",
                "col6": "Руководитель Аппарата - Водяникова Елена Витальевна",
                "col7": "http://old.alrf.ru/region11/",
                "col8": "8 (904) 272-59-94",
                "col9": "alrf@ya.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 6,
                "col4": "Коми региональная общественная организация \"Ассоциация детских общественных объединений Республики Коми\" ",
                "col5": "167026, Республика Коми, г. Сыктывкар, ул. Славы, д. 1, каб. 03",
                "col6": "Андреев Василий Михайлович",
                "col7": "https://vk.com/detikomi",
                "col8": "8 (8212) 66-53-46 8(912)861-47-45",
                "col9": "kroo_assoziazia@mail.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 7,
                "col4": "Автономная некоммерческая организация по развитию личностного потенциала детей, молодежи и взрослых \"Архитектор жизни",
                "col5": "167000, Республика Коми, г. Сыктывкар, ул. Советская, д. 22, кв. 17",
                "col6": "Горбунова Анжелика Викторовна",
                "col7": "",
                "col8": "8 (912) 864-12-27",
                "col9": "intelekt2@yandex.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Республика Коми",
                "col3": 8,
                "col4": "Автономная некоммерческая организация по развитию личностного потенциала детей, молодежи и взрослых «Платформа» ",
                "col5": "168060, Республика Коми, Усть-Куломский р-н, с. Усть-Кулом, ул. Нагорная, д. 22А, кв. 2",
                "col6": "Катышева Ольга Васильевна",
                "col7": "",
                "col8": "8 (904) 239-66-85",
                "col9": "KATYSHEVAOLGA9@RAMBLER.RU"
            },
        ],
        [
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 1,
                "col4": "Частное учреждение «Медиация и консультирование в конфликте» ",
                "col5": "19000, Санкт-Петербург, ул. Казанская, 45, кв. 57 Филиал: г. Тихвин, Ленинградская область, Красавское шоссе, строение б/н (территория ДСК)",
                "col6": "Арбекова Вероника Витальевна, Филюта Вероника Витальевна",
                "col7": "www.193fz.ru",
                "col8": "8 (911) 928-62-40",
                "col9": "9286240@mail.ru info@193fz.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 2,
                "col4": "Ассоциация «Лига медиаторов»",
                "col5": "191040, г. Санкт-Петербург, Невский пр., д. 81А, оф. 22Н",
                "col6": "Путиловский Антон Александрович",
                "col7": "http://arbimed.ru/",
                "col8": "8 (812) 579-27-48",
                "col9": "assistant@arbimed.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 3,
                "col4": "Служба медиации СПБ ГБУ «ГЦСП «КОНТАКТ»",
                "col5": "196070, г. Санкт-Петербург, Фрунзе ул., д.4",
                "col6": "Горюнов Павел Юрьевич",
                "col7": "https://profcenter.spb.ru/медиация/",
                "col8": "8 (812) 747-29-51 8(953) 363-70-42",
                "col9": "mediation-kontakt@mail.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 4,
                "col4": "Ассоциация «Центр развития эффективного разрешения споров, медиации и третейского разбирательства»",
                "col5": "191025, Санкт-Петербург, Невский пр., д. 53",
                "col6": "Директор Семеняко Максим Евгеньевич",
                "col7": "",
                "col8": "8 (921)934-60-18",
                "col9": ""
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 5,
                "col4": "Автономная некоммерческая организация «Центр юридической помощи и медиации «Кодекс и Слово» Санкт-Петербурга",
                "col5": "198217, Санкт-Петербург, Дачный пр., д. 17, к. 4, литера А, кв. 68",
                "col6": "Директор Соколова Оксана Васильевна",
                "col7": "",
                "col8": "8 (921) 937-99-08",
                "col9": "info@impere.ru"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 6,
                "col4": "Автономная некоммерческая организация «Центр медиации «Формула мира»",
                "col5": "196191, Санкт-Петербург, пл. Конституции, д. 7, литера А, помещ. 183-Н",
                "col6": "Директор Ена Дмитрий Викторович",
                "col7": "",
                "col8": "8 (921) 930-57-91 ",
                "col9": "enaspb8@gmail.com"
            },
            {
                "col1": " Северо-Западный федеральный округ",
                "col2": "Санкт-Петербург ",
                "col3": 7,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Медиация и право»",
                "col5": "193232, Санкт-Петербург, ул. Тельмана, д. 48, к. 4, литера А, кв. 28",
                "col6": "Директор Боер Мария Александровна",
                "col7": "",
                "col8": "8 (911)139-95-79 ",
                "col9": "7911139957@yandex.ru"
            },
        ]
    ],
    [
        [
            {
                "col1": "Южный федеральный округ",
                "col2": "Астраханская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация \"Центр психологической помощи и медиации, судебно-психологических экспертиз \"Доверие\"",
                "col5": "416370, Астраханская обл., Икрянинский р-н, с. Икряное, ул. М.Горького, д. 9",
                "col6": "Топорков Артем Иванович",
                "col7": "",
                "col8": "8 (917) 089-05-34",
                "col9": "ARTOPORKOV@YAND EX.RU"
            },
            {
                "col1": "Южный федеральный округ",
                "col2": "Астраханская область",
                "col3": 2,
                "col4": "Государственное автономное учреждение Астраханской области «Многопрофильный социальный центр «Содействие»",
                "col5": "414024, Астрахань, ул. Богдана Хмельницкого, д. 2, корп. 3",
                "col6": "Чиликова Татьяна Анатольевна",
                "col7": "https://sodeystvie.org/otdeleniya-mnogoprofilnogo-socialnogo-centra-sodeystvie.html",
                "col8": "8 (8512) 35-17-82 8 (8512) 51-02-03",
                "col9": "info@sodeystvie.org"
            },

        ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Волгоградская область ",
            "col3": 1,
            "col4": "Фонд социальной помощи «Планета Детства»",
            "col5": "400065, Волгоградская обл., г. Волгоград, ул. Луговского, д.11, кв. 56",
            "col6": "Елагина Ирина Анатольевна",
            "col7": "https://deti-aist.ru/",
            "col8": "8 (8442) 71-35-63 8 (987) 643-90-99",
            "col9": "deti-aist@yandex.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Волгоградская область ",
            "col3": 2,
            "col4": "Региональный фонд «Здоровое поколение»",
            "col5": "400048, Волгоградская обл., г. Волгоград, Шоссе Авиаторов, д. 12Д, пом. 2",
            "col6": "Яшин Александр Александрович",
            "col7": "https://fond-zp.ru/",
            "col8": "8 (8442) 50-50-23  8 (906) 166-37-80",
            "col9": "volgograd@fond-zp.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Волгоградская область ",
            "col3": 3,
            "col4": "Автономная некоммерческая организация «Центр социальной, психолого-педагогической реабилитации и абилитации «Живая помощь»",
            "col5": "400096, г. Волгоград, ул. Удмуртская, д. 95, кв. 212",
            "col6": "Зайченко Татьяна Анатольевна",
            "col7": "http://zhp34.ru/",
            "col8": "8 (8442) 59-10-09",
            "col9": "ano_gp@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Волгоградская область ",
            "col3": 4,
            "col4": "Автономная некоммерческая организация социальной поддержки семьям и детям «Теплый дом»",
            "col5": "404121, Волгоградская область, г. Волжский, ул. Машиностроителей, д. 9 «Б»",
            "col6": "Яковлева Любовь Петровна",
            "col7": "http://deti-tepldom.ru/o-nas  ",
            "col8": "8(8443) 25–17–11 8(961) 695-68-75, 8(904) 774-35-49,",
            "col9": "deti-tepldom@mail.ru"
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 1,
            "col4": "Автономная некоммерческая организация Дополнительного профессионального образования \"Центр переговоров и урегулирования споров (медиации)\"",
            "col5": "350000, Краснодарский кр., г. Краснодар, ул. Рашпилевская, д. 55, пом. 11",
            "col6": "Морозова Елена Владимировна",
            "col7": "https://mediacia-center.com/",
            "col8": "8 (918) 242-79-50",
            "col9": "elena_morozova_7@bk.ru info@mediacia-center.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 2,
            "col4": "Ассоциация \"Центр переговоров, медиации и примирения\"",
            "col5": "354053, Краснодарский кр., г. Сочи, ул. Анапская, д. 3, корп. 12, кв. 22",
            "col6": "Горшенина Екатерина Вячеславовна",
            "col7": "https://mediator123.ru/",
            "col8": "8 (989) 081-89-39",
            "col9": "mediator123@bk.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 3,
            "col4": "Ассоциация профессиональных медиаторов \"Единство\"",
            "col5": "350000, Краснодарский кр., г. Краснодар, ул. Рашпилевская, д. 55, пом. 11",
            "col6": "Морозова Елена Владимировна",
            "col7": "https://apm-edin.ru/",
            "col8": "8 (918) 242-79-50 8(918) 279-28-60",
            "col9": "elena_morozova_7@bk.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 4,
            "col4": "Международная Ассоциация Медиаторов и Адвокатов \"МедиаТ\"",
            "col5": "350051, Краснодарский кр., г. Краснодар, ул. Монтажников, д. 1/4, этаж 8, пом.11, 15/2",
            "col6": "Айрапетян Варсеник Сергеевна",
            "col7": "https://mediatedu.ru/",
            "col8": "8 (918) 477-77-01 8 (988) 247-97-57 8(985) 302-27-93",
            "col9": "mama.mediat@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 5,
            "col4": "Негосударственное аккредитованное некоммерческое частное образовательное учреждение высшего образования \"Академия маркетинга и социально-информационных технологий-ИМСИТ\"",
            "col5": "350010, Краснодарский край, г. Краснодар, Зиповская ул., д. 5",
            "col6": "Агабекян Раиса Левоновна",
            "col7": "www.imsit.ru",
            "col8": "8 (861) 278-22-70",
            "col9": "info-imsit@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Краснодарский край ",
            "col3": 6,
            "col4": "Новороссийский институт (филиал) Автономной некоммерческой организации высшего образования Московский гуманитарно-экономический университет",
            "col5": "353900, Южный федеральный округ, Краснодарский край, г. Новороссийск, ул. Коммунистическая/ Советов, 36/37.",
            "col6": "Куткович Тамара Анатольевна",
            "col7": "www.nvr-mgei.ru",
            "col8": "8 (617) 64-18-54",
            "col9": "nf_mgei@mail.ru"
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Адыгея ",
            "col3": 1,
            "col4": "Центр развития примирительных процедур (медиационный центр) при Адвокатской палате Республики Адыгея",
            "col5": "385006, Республика Адыгея, г. Майкоп, ул. Хакурате/Гагарина, д.199/",
            "col6": "Мамий Алий Салатчериевич- ",
            "col7": "http://апра01.рф",
            "col8": "8 (918) 925-33-03 8 (8772 ) 52-45-77",
            "col9": "ap_adygheya@mail.ru"
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Калмыкия",
            "col3": 1,
            "col4": "Бюджетное учреждение дополнительного образования Республики Калмыкия \"Центр психолого-педагогической, медицинской и социальной помощи\"",
            "col5": "358000, Республика Калмыкия, г.Элиста, улица Губаревича, д.7",
            "col6": "Мальмакова Татьяна Григорьевна",
            "col7": "https://ppmsp08.ru/",
            "col8": "8(960) 898-82-14 8(927) 595-35-15",
            "col9": "center200808@rambler.ru cpppso@mail.ru"
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Крым ",
            "col3": 1,
            "col4": "Автономная некоммерческая организация \"Дом молодежи\"",
            "col5": "295000, Республика Крым, г.Симферополь, пер. Совнаркомовский, д. 3",
            "col6": "Чобанян Сона Ашотовна",
            "col7": "",
            "col8": "8 (978) 871-29-64",
            "col9": ""
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Крым ",
            "col3": 2,
            "col4": "Автономная некоммерческая организация Центр социальных программ и профилактики асоциальных явлений среди несовершеннолетних и молодежи \"КОНТАКТЫ\"",
            "col5": "297511, Республика Крым, Симферопольский р-н, с. Новоандреевка, ул. Киевская, д..16А",
            "col6": "Федяй Елена Николаевна",
            "col7": "",
            "col8": "8 (978) 790-704",
            "col9": "A.N.FEDYAY@MAIL.RU"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Крым ",
            "col3": 3,
            "col4": " Ассоциация «Первый Крымский центр переговоров и разрешения конфликтов (медиации)»",
            "col5": "295013, Республика Крым, г. Симферополь, ул. Д. И. Ульянова, д.3/2 кв.30",
            "col6": "Мельник Ирина Ивановна",
            "col7": "https://crimea-mediatio.ru/",
            "col8": "8 (978) 75-35-199",
            "col9": "melnikira5@gmail.com"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Крым ",
            "col3": 4,
            "col4": "Автономная некоммерческая организация реабилитационный центр социальной помощи и поддержки лиц, оказавшихся в трудной жизненной ситуации «ФЕНИКС»",
            "col5": "295050, Республика Крым, г.о. Симферополь, ул. Хабаровская, д. 48, кв. 62",
            "col6": "Овсепян Евгений Анатольевич",
            "col7": "",
            "col8": "8 (918) 562-83-12",
            "col9": "ovsepyan1977@gmail.com"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Республика Крым ",
            "col3": 5,
            "col4": "Автономная некоммерческая организация «Общественный центр проектирования социально-культурной среды для детей, юношества, молодежи «Просветительский корпус.Век»",
            "col5": "298023, Республика Крым, г. Судак, с. Дачное, ул. Лесная, д. 1, кв. 29",
            "col6": "Данелян Ирина Михайловна",
            "col7": "",
            "col8": "8 (978) 883-10-54",
            "col9": ""
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Ростовская область",
            "col3": 1,
            "col4": "Муниципальное бюджетное учреждение   «Центр психолого-педагогической, медицинской и социальной помощи «Успех» города Новошахтинска",
            "col5": "346905, Ростовская обл., г. Новошахтинск ул. 4-я Пятилетка, 16",
            "col6": "Рекаева Наталья Юрьевна",
            "col7": "http://pmsnov.ucoz.ru/",
            "col8": "8 (86369) 3-22-55  ",
            "col9": "pms–novoshaht@mail.ru "
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Ростовская область",
            "col3": 2,
            "col4": "Муниципальное бюджетное учреждение дополнительного образования «Дом детского творчества» ",
            "col5": "346880, Ростовская обл., г. Батайск, ул. Ленина, д.3",
            "col6": "Мануйлова Светлана Викторовна",
            "col7": "http://ddt-bataysk.ru/ ",
            "col8": "8 (86354) 6-63-16",
            "col9": "ddt_bataysk@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Ростовская область",
            "col3": 3,
            "col4": "Муниципальное бюджетное учреждение Верхнедонского района «Центр психолого-педагогической, медецинской и социальной помощи»",
            "col5": "Ростовская область, Верхнедонской район, ст.Казанская, ул.Комсомольская д.18",
            "col6": "Безручко Наталья Владимировна",
            "col7": "https://t.me/mbucppmspvdon  ",
            "col8": " 8 (86364)-32-2-07",
            "col9": "bezruchkovdon@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Ростовская область",
            "col3": 4,
            "col4": "Региональная общественная организация психологов и социальных работников «Анима»",
            "col5": "346400, Ростовская область, г.Новочеркасск, ул..Дворцовая, д.12",
            "col6": "Беликова Наталья Константиновна",
            "col7": "",
            "col8": "8 (904) 347-93-69 ",
            "col9": "vip.nat60@mail.ru "
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Ростовская область",
            "col3": 5,
            "col4": "Первый Адвокатский Негосударственный Центр Бесплатной Юридической Помощи Ростовской области ",
            "col5": "3440082 г.Ростов-на-Дону, ул.Пушкинская, 1/36",
            "col6": "Писарев Владимир Николаевич",
            "col7": "",
            "col8": "8 (863236) 2-3-23",
            "col9": "pochta@zashitnik.com doktorprava@mail.ru "
        },
    ], [
        {
            "col1": "Южный федеральный округ",
            "col2": "Севастополь",
            "col3": 1,
            "col4": "Автономная некоммерческая организация \"Севастопольский центр медиации и переговоров\"",
            "col5": "299003, г. Севастополь, ул. Льва Толстого, д. 8а, кв. 12",
            "col6": "Печкин Сергей Андреевич",
            "col7": " ",
            "col8": "8 (978) 809-92-56",
            "col9": "pechkincep@mail.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Севастополь",
            "col3": 2,
            "col4": "Севастопольское региональное отделение Общероссийской общественно-государственной организации \"Фонд защиты детей\"",
            "col5": "299014, г. Севастополь, вн.тер.г. Гагаринский муниципальный округ, ул. Маршала Блюхера, дом 4А, помещ. 2",
            "col6": "Семенов Андрей Геннадиевич",
            "col7": "",
            "col8": "8 (978) 890-19-00 ",
            "col9": "semandry@bk.ru"
        },
        {
            "col1": "Южный федеральный округ",
            "col2": "Севастополь",
            "col3": 3,
            "col4": "Автономная некоммерческая организация ресурсный центр развития личности «Наследие»",
            "col5": "299040, г. Севастополь, вн.тер.г. Гагаринский муниципальный округ, ул. Хрусталева, зд. 76А",
            "col6": "Падяш Андрей Евгеньевич",
            "col7": "https://наставник-севастополя.рф/",
            "col8": "8 (978) 058-72-73 ",
            "col9": "padyash@mail.ru "
        },
    ]
    ],
    [
        [
            {
                "col1": "Северо-Кавказский федеральный округ",
                "col2": "Карачаево-Черкесская Республика ",
                "col3": 1,
                "col4": "Республиканское государственное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи»",
                "col5": "369000, Карачаево-Черкесская Республика, г. Черкесск, ул. Ставропольская, 117",
                "col6": "Хутова Асият Птуовна",
                "col7": "https://цппмсп09.рф/",
                "col8": "8 (8782) 20-36-91",
                "col9": "cppmsp-09@mail.ru"
            },
            {
                "col1": "Северо-Кавказский федеральный округ",
                "col2": "Карачаево-Черкесская Республика ",
                "col3": 2,
                "col4": "Государственное бюджетное учреждение «Центр психолого-медико-социального сопровождения»",
                "col5": "360030, КБР, г.Нальчик  ул. Тарчокова, 26",
                "col6": "Дикинова Фатима Хусейновна",
                "col7": "https://rcpmss.edu07.ru",
                "col8": "8(8662)40-82-83 8(8662)40-87-53 8(8662)40-79-02",
                "col9": "rcpmss@mail.ru"
            },
        ], [
        {
            "col1": "Северо-Кавказский федеральный округ",
            "col2": "Республика Дагестан",
            "col3": 1,
            "col4": "Центр психолого-педагогической, медицинской и социальной помощи (на базе Государственного бюджетного общеобразовательного учреждения «Республиканский центр социально-трудовой адаптации и профориентации им. У.М. Муртузалиевой») ",
            "col5": "367014, Республика Дагестан, г. Махачкала, ул. Солдатская (6-я Магистральная), № 3",
            "col6": "Рамазанова Арслана Рамазановна ",
            "col7": "https://rcstaip.dagestanschool.ru/?section_id=78",
            "col8": "8 (722) 60-14-35 ",
            "col9": "rcstaip@e-dag.ru"
        },
    ],[
        {
            "col1": "Северо-Кавказский федеральный округ",
            "col2": "Республика Ингушетия",
            "col3": 1,
            "col4": 'Автономная некоммерческая организация "Центр внесудебного урегулирования конфликтов"',
            "col5": "386100, Республика Ингушетия, г. Магас, пр. И. Зязикова, д. 8, каб. 5",
            "col6": "Точиев Заурбек Ибрагимович",
            "col7": "",
            "col8": "89280963388",
            "col9": "pero_sk@mail.ru"
        },
    ], [
        {
            "col1": "Северо-Кавказский федеральный округ",
            "col2": "Ставропольский край",
            "col3": 1,
            "col4": "Автономная некоммерческая организация ресурсно-тренинговый центр НКО \"Кампус\"",
            "col5": "355002, Ставропольский кр., г. Ставрополь, ул. Мира, д. 264 А, оф. 32",
            "col6": "Стукалов Кирилл Васильевич",
            "col7": "",
            "col8": "8 (918) 869-53-95",
            "col9": "Kampus_oosk/@mail.ru"
        },
        {
            "col1": "Северо-Кавказский федеральный округ",
            "col2": "Ставропольский край",
            "col3": 2,
            "col4": "Ставропольская краевая общественная детская организация \"Союз детей Ставрополья\"",
            "col5": "355025, Ставропольский кр., г. Ставрополь, пл.Ленина, д. 1, Дом Правительства, каб. 449",
            "col6": "Фарахманд Париса Абдул-Расуловна",
            "col7": "",
            "col8": "8 (928) 264-59-12",
            "col9": "parisa.farakhmand@gmail.com"
        },
        {
            "col1": "Северо-Кавказский федеральный округ",
            "col2": "Ставропольский край",
            "col3": 3,
            "col4": "Ставропольская краевая молодежная общественная организация \"Творческий союз \"Звездный ветер\"",
            "col5": "355017, Ставропольский кр., г. Ставрополь, ул. Мира, д. 264, корп. А, оф. 32А",
            "col6": "Скиперская Ольга Вячеславовна",
            "col7": "http://ajuk.ru/",
            "col8": "8 (918) 778-08-66 8 (905) 440-45-08",
            "col9": "adelina2010kedr@mail.ru "
        },
    ]
    ],
    [
        [
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Кировская область ",
                "col3": 1,
                "col4": "Автономная некоммерческая организация «Центр методической и психологической поддержки и наставничества «Территория понимания и саморазвития»",
                "col5": "610001, Кировская область, г. Киров, ул. Горького, д. 63 корп. 3, кв. 53",
                "col6": "Прозорова Мария Николаевна",
                "col7": "https://anotp.ru/",
                "col8": "8 (909) 135-23-70",
                "col9": "mariyaprozorova@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Кировская область ",
                "col3": 2,
                "col4": "Автономная некоммерческая организация «Кировский региональный центр медиации и права»",
                "col5": "610020, Кировская область, г. Киров, ул. Пятницкая, д. 12 корп. 1",
                "col6": "Попцов Сергей Николаевич",
                "col7": "",
                "col8": "8 (912) 364-54-76",
                "col9": ""
            },
        ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Нижегородская область",
            "col3": 1,
            "col4": "Нижегородская общественная организация «Семейный центр «ЛАДА»",
            "col5": "603157, Нижний Новгород, Березовская, 120-52",
            "col6": "Смирнова Галина Петровна",
            "col7": "http://ladadeti.ru/",
            "col8": "8 (831) 279-95-25",
            "col9": "Lada_fc@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Нижегородская область",
            "col3": 2,
            "col4": "Автономная некоммерческая организация Нижегородской области \"Социальный Центр юридической и психологической помощи «НИЖЕГОРОДКА»",
            "col5": "603000, г. Нижний Новгород, ул. Большая Покровская, дом 60, помещ. П31",
            "col6": "Благова Елена Сергеевна",
            "col7": "http://help-nizhegorodka.ru Группа VK: https://vk.com/nizhegorodkann ",
            "col8": "8(904)78-71-233 8(831) 423-78-22 8(930) 05-64-957",
            "col9": "lider.povolzhenn@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Нижегородская область",
            "col3": 3,
            "col4": "Автономная некоммерческая организация \"Центр наставничества для подростков \"Хулиганодом\"",
            "col5": "603146, г. Нижний Новгород, ул. Бекетова, дом 48, помещ. П2",
            "col6": "Ефимова Марина Александровна",
            "col7": "https://nastavnik52.ru Группа VK: https://vk.com/huliganodom?ysclid=ll4xs5hn nt228474899 ",
            "col8": "7 (952) 774-02-71  8(904) 78-71-233",
            "col9": " zhizn_bez_granits@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Нижегородская область",
            "col3": 4,
            "col4": "Нижегородская областная общественная организация \"Нижегородский Женский Кризисный Центр\"",
            "col5": "603146, г. Нижний Новгород, ул. Заярская, д. 18а, помещ. 2,10",
            "col6": "Ермолаева Анастасия Геннадьевна",
            "col7": "https://crisiscenter. ru/?ysclid=ll4wswcg30222538758 Группа VK: https://vk.com/nncrisis.center?ysclid=ll4wsx 5bxg754549646  ",
            "col8": "8 (920) 253-84-32 8(831) 413-84-32",
            "col9": " info@crisis-center.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Оренбургская область",
            "col3": 1,
            "col4": "Автономная некоммерческая организация дополнительного профессионального образования \"Институт консультирования и тренинга \"Статус\"",
            "col5": "460000, Оренбургская область, г. Оренбург, ул.Володарского, 39, офис 603",
            "col6": "Ирина Сергеевна Якиманская",
            "col7": "http://orenstatus.ru/",
            "col8": "8(3532) 977-325 8(3532) 440-142",
            "col9": "orenstatus-ofis@mail.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Пермский край ",
            "col3": 1,
            "col4": "Автономная некоммерческая организация по развитию медиации и иных примирительных процедур ",
            "col5": "614068, Пермский край, г. Пермь,ул. Екатерининская, д. 165",
            "col6": "Нигматуллин Фидарис Наилович",
            "col7": "www.Mediatio.biz",
            "col8": "8 (342) 291-95-55 8(342)236-70-70  294-04-32",
            "col9": "mediatio.biz@gmail.com"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Пермский край ",
            "col3": 2,
            "col4": "Автономная некоммерческая организация «Медиация. Переговоры. Право»",
            "col5": "614068, Пермский край, г. Пермь, ул. Окулова, д. 75, корпус 2, офис 311",
            "col6": "Лысцова Наталья Александровна",
            "col7": "http://mpp59.tilda.ws/mpp59",
            "col8": "8(932) 330-16-85",
            "col9": "lnatalia75@mail.ru mediator.mpp@gmail.com"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Пермский край ",
            "col3": 3,
            "col4": "Некоммерческая организация «Верхнекамская Ассоциация медиаторов» Центр семейной медиации \"МИРуМИР\" ",
            "col5": "618400, Пермский край, г. Березники, ул. К. Маркса, д. 53 ",
            "col6": "Чеснокова Анастасия Валерьевна",
            "col7": "https://vk.com/mediation_brz",
            "col8": "8 (3424) 290-790  8 (912) 469-03-22",
            "col9": "a_chesnokova11@mail.ru ric-konsyl@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Пермский край ",
            "col3": 4,
            "col4": "Пермская краевая общественная организация «Ассоциация медиаторов Пермского края» Краевой ресурсный центр по ПППН Пермский край",
            "col5": "614068, Пермский кр., г. Пермь, ул. Борчанинова, д. 1, кв. 75; Факт. адрес 614095, Пермский край, г.Пермь, ул. Карпинского, 14, оф.142",
            "col6": "Хавкина Анна Львовна ",
            "col7": "https://vk.com/mediation_center_of_perm_krai",
            "col8": "8 (902) 798-72-76",
            "col9": "Annakhavkina@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Пермский край ",
            "col3": 5,
            "col4": "Ассоциация Профессиональных Медиаторов",
            "col5": "614000, г. Пермь, ул. Транспортная, 19, кв. 53; Факт.адрес 614000, Пермский край, г. Пермь, ул. Газеты Звезда, 20, офис 8",
            "col6": "Яковлева Юлия Аркадьевна",
            "col7": "",
            "col8": "8 (909)113-66-66  ",
            "col9": "e-mail: ukon12@yandex.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Башкортостан ",
            "col3": 1,
            "col4": "Центр медиации принекоммерческом партнерстве «Ассоциация благотворительных организаций Республики Башкортостан»",
            "col5": "450104,Республика Башкортостан, г. Уфа, ул. Российская, д. 43/13",
            "col6": "Султанова Регина Рамзиновна",
            "col7": "http://project3174845.tilda.ws/ https://www.facebook.com/blagolubim.ural/?ref=page_internal",
            "col8": "8(987) 025-94-04 8(917) 35-04-113",
            "col9": "kurssonko20@gmail.com "
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Башкортостан ",
            "col3": 2,
            "col4": "Центр медиации при автономной некоммерческой организации дополнительного образования «ФБС»",
            "col5": "450059, Республика Башкортостан, г. Уфа, проспект Октября, д 33 к 2",
            "col6": "Десяткин Константин Васильевич",
            "col7": "http://fbs-rb.ru/",
            "col8": "8(347) 292-72-99 8(917) 430-32-33",
            "col9": "fbs@ufamail.ru  maxrex@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Башкортостан ",
            "col3": 3,
            "col4": "Центр примирительных технологий и общественного взаимодействия в Федеральном государственном бюджетном образовательном учреждении высшего образования «Башкирский государственный педагогический университет им. М.Акмуллы»",
            "col5": "450008, Республика Башкортостан, г.Уфа, ул. Октябрьской революции, 3-а",
            "col6": "Сагитов Салават Талгатович (ректор)  Беляев Максим Анатольевич (руководитель центра)",
            "col7": "https://bspu.ru/unit/288",
            "col8": "8(347) 246-88-63 8(347) 287-99-91 8(347) 246-46-75 8(347) 287-99-99",
            "col9": "ipcpp@yandex.ru   office@bspu.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Башкортостан ",
            "col3": 4,
            "col4": "Государственное бюджетное учреждение Республики Башкортостан Юго-восточный межрайонный Центр «Семья» служба семьи в Зианчуринском районе",
            "col5": "453380, Зианчуринский район, с. Исянгулово, пер. Блюхера, 7",
            "col6": "Бердигулов Фанис Фанилович",
            "col7": "http://kc58.mintrudrb.ru/",
            "col8": "8(34785) 2-10-70 8(927) 311-73-55",
            "col9": "ospsid.zian@yandex.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Марий Эл",
            "col3": 1,
            "col4": "Региональная молодежная общественная организация поддержки гражданских инициатив Республики Марий Эл \"ОПОРА\"",
            "col5": "424003, Республика Марий Эл, г. Йошкар-Ола, Ленинский пр., д. 56, кв. 65",
            "col6": "Дождиков Сергей Владимирович",
            "col7": "http://opora-russia.ru/",
            "col8": "8(8362) 31-44-10",
            "col9": "opora1@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Марий Эл",
            "col3": 2,
            "col4": "Государственное бюджетное учреждение Республики Марий Эл «Республиканский центр психолого-педагогической  и социальной помощи населению «Доверие»",
            "col5": "424033, Республика Марий Эл  г. Йошкар-Ола, ул. Эшкинина д.2 ",
            "col6": "Узикова Валентина Александровна, Ларкина Анна Николаевна",
            "col7": "https://mari-el.gov.ru/ministries/minsoc/centr_doverie/",
            "col8": "8 (8362) 21-10-40",
            "col9": "rzsppn@yandex.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Мордовия ",
            "col3": 1,
            "col4": "Ассоциация медиаторов Республики Мордовия",
            "col5": "430005, Республика Мордовия, г. Саранск, ул. Коммунистическая, д. 50",
            "col6": "Любишкина Нина Петровна",
            "col7": "http://darin-den.wix.com/mediationrm",
            "col8": "8 (8342) 31-04-24 8 (927) 180-05-05",
            "col9": "reg.pravo@mail.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Татарстан ",
            "col3": 1,
            "col4": "Некоммерческое партнерство \"Лига медиаторов Поволжья\"",
            "col5": " 420111, Республика Татарстан, г. Казань, ул. Нужина, д.2",
            "col6": "Маврин Олег Викторович",
            "col7": "http://mediators-tatarstan.ru/",
            "col8": "8 (843) 297-55-98 8 (843) 245-23-28",
            "col9": "liga_mediatorov_povolghya@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Татарстан ",
            "col3": 2,
            "col4": "Ассоциация \"Некоммерческое партнерство \"ВАШ МЕДИАТОР\"",
            "col5": "420054, Республика Татарстан, г.Казань, ул.Техническая, д.120 А, оф. 310 А",
            "col6": "Кирюхина Анна Сергеевна",
            "col7": "",
            "col8": "8 (843) 278-67-37",
            "col9": ""
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Татарстан ",
            "col3": 3,
            "col4": "Муниципальное бюджетное учреждение молодежной политики города Казани «Центр психолого-педагогической помощи  детям и молодежи «Доверие»",
            "col5": "420095, Республика Татарстан, г.Казань ул.Кулахметова, д.21",
            "col6": "Овчинникова Елена Владимировна",
            "col7": "https://vk.com/doverie_kzn",
            "col8": "8 (843) 598-33-73  8 (843) 277-00-00 Экстренная служба по телефону  8 (800) 2000-122 Единый общероссийский детский телефон доверия для детей, подростков и их родителей  8 (800) 555-22-20 Молодежный телефон доверия",
            "col9": "kcso_dover@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Республика Татарстан ",
            "col3": 4,
            "col4": "Автономная некоммерческая организация «Поволжская семейная академия «УМАЙ»",
            "col5": "420021, Республика Татарстан, г.Казань, ул. Тукая 23, оф.308 (Правление) ул. Бутлерова 30, оф.24 (Образование), г.Казань, ул. 8 Марта, д.18",
            "col6": "Леонтьева Татьяна Ивановна",
            "col7": "www.umai.su https://vk.com/academiaymai",
            "col8": "8 (843)245-33-91",
            "col9": "umai.su@yandex.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Самарская область",
            "col3": 1,
            "col4": "Частное учреждение развития восстановительного подхода «Примирение»",
            "col5": "446551, Самарская обл., м. р-н Сергиевский, с.п. Сургут, ул. Зеленая, д. 15, кв. 1.",
            "col6": "Прянишникова Татьяна Вячеславовна",
            "col7": "",
            "col8": " 8 (917) 143-03-30",
            "col9": "kurdin063@mail.ru;"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Самарская область",
            "col3": 2,
            "col4": "Общественная организация «Федерация детских организаций Самарской области»",
            "col5": "443041, г. Самара, ул. Ленинская, д. 137/44. Факт.адрес 443041, г. Самара, ул. Молодогвардейская, д. 60 ",
            "col6": "Ревзина Ксения Владимировна",
            "col7": "https://vk.com/fdo63",
            "col8": "8(846)333-46-04 8(937)794-75-04",
            "col9": "Fdo-samara@mail.ru"
        },
    ], [
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 1,
            "col4": "Государственное автономное учреждение дополнительного профессионального образования «Саратовский областной институт развития образования», региональный центр практической психологии и инклюзивного образования.",
            "col5": "410031, г. Саратов, ул. Большая Горная, д.1.",
            "col6": "Губанова Елена Владимировна",
            "col7": "https://soiro64.ru/",
            "col8": "8 (8452) 28-25-24 (доб. 108) 8 (8452) 28-23-90 8 (8452) 28-25-19 ",
            "col9": "rector@soiro.ru "
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 2,
            "col4": "Государственное автономное учреждение Саратовской области «Социально-реабилитационный центр для несовершеннолетних «Возвращение»",
            "col5": "1-е здание: 410022, г. Саратов, ул. им. Азина В.М., д. № 28«А» 2-е здание: 410022, г. Саратов, ул. Новокрекингская, д. № 18 3-е здание: 410019, г. Саратов, ул. 2-й Магнитный проезд, д. № 44«А» 4-е здание: 410033, г. Саратов, ул. 1-й проезд им. Панфилова В.И., д. № 1",
            "col6": "Кривицкая Наталия Александровна",
            "col7": "www.social.saratov.gov.ru/srcn_vozvraschenie ",
            "col8": "8 (8452) 92-94-77 8(8452) 92-96-55 ф 8(8452) 92-96-54 8(8452) 92-89-10 8(8452) 92-96-56 8(8452) 92-16-58 8(8452) 92-06-48 8(8452) 54-45-45 8(8452) 24-30-01 ",
            "col9": "vozvr@narod.ru  vozvr@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 3,
            "col4": "Государственное бюджетное учреждение Саратовской области «Социально-реабилитационный центр для  «Волжанка»",
            "col5": "412904, Саратовская область, г. Вольск, ул. Школьная, 13, 2 здание: Саратовская область, г. Вольск, ул. Дзержинского, д.20",
            "col6": "Козырькова Елена Ивановна",
            "col7": "www.social.saratov.gov.ru/volsk_srcn_volzhanka",
            "col8": "8 (84593) 6-00-60ф 8(84593)6-00-59 8(84593)6-00-37 8(84593)6-00-63 8(84593)6-00-65 8(84593)6-00-68 (845-93) 7-12-57 8(84593) 5-37-34 8(84593)7-03-18 8(84593)5-51-09",
            "col9": "voljankavl@mail.ru; voljanka2@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 4,
            "col4": "Государственное бюджетное учреждение Саратовской области «Социально-реабилитационный центр для несовершеннолетних «Надежда»",
            "col5": "413116, Саратовская область, г. Энгельс, ул. Ломоносова, 35а",
            "col6": "Чурилова Лия Жабировна",
            "col7": "www.social.saratov.gov.ru/engels_srcn_nadezhda https://nadezhda64.ru",
            "col8": "8 (8453) 74-55-11ф. 8(8453)74-55-12 8(8453) 74-12-04 ",
            "col9": "nadyasrc@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 5,
            "col4": "Государственное бюджетное учреждение Саратовской области «Балаковский центр социальной помощи семье и детям «Семья»",
            "col5": "413840, Саратовская область, г. Балаково, ул. Набережная Леонова, д. 26б 2 здание: Саратовская область, г. Балаково, ул. Заречная, 2а",
            "col6": "Ермоленко Надежда Ивановна",
            "col7": "www.social.saratov.gov.ru/balakovo_family https://semyabalakovo.ru  ",
            "col8": "8(845-3) 62-02-69ф., 8(845-3) 62-90-40, 8(845-3) 23-19-33, ф 8(845-3) 23-19-30, 8(845-3) 23-19-32",
            "col9": "semya-balakovo@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 6,
            "col4": "Государственное бюджетное учреждение Саратовской области «Балашовский центр социальной помощи семье и детям «Семья»",
            "col5": "412314, Саратовская область, г. Балашов, ул. Нефтяная, д. 44Б 2 здание: Саратовская область, г. Балашов,ул. Энтузиастов,16А",
            "col6": "Бандовская Ольга Владимировна",
            "col7": "www.social.saratov.gov.ru/balashov_family ",
            "col8": "8(84545) 5-43-20, ф. 8(84545)5-45-36, 8(84545)5-43-24, 8(84545)5-43-92, 8(84545)3-14-32, 8(84545)3-27-02, 8(84545)3-01-23",
            "col9": "semyabalashov2007@yandex.ru; 412311priyut@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 7,
            "col4": "Государственное бюджетное учреждение Саратовской области «Красноармейский центр социальной помощи семье и детям «Семья»",
            "col5": "412801, Саратовская область, г. Красноармейск, ул.Б.Хмельницкого, 2 «д» 2 здание: Саратовская область, г. Красноармейск, ул. 1 Мая, 63",
            "col6": "Матяшова Ольга Владимировна",
            "col7": "www.social.saratov.gov.ru/krarmejsk_family  ",
            "col8": "8(84550) 2-46-71, 8(84550) 2-45-86, 8(84550) 2-12-50 ",
            "col9": "krasno7ya@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 8,
            "col4": "Государственное бюджетное учреждение Саратовской области «Марксовский центр социальной помощи семье и детям «Семья»",
            "col5": "413092, Саратовская область, г. Маркс, ул. Калинина, д. 1",
            "col6": "Седелкина Татьяна Александровна",
            "col7": "www.social.saratov.gov.ru/marks_family ",
            "col8": "8(84567) 5-86-99, ф. 8(84550) 5-54-87, 8(84550) 5-11-07",
            "col9": "marksSemya@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 9,
            "col4": "Государственное бюджетное учреждение Саратовской области «Новоузенский центр социальной помощи семье и детям «Семья»",
            "col5": "413361, Саратовская область, г. Новоузенск, ул. Хайкина, д. 25",
            "col6": "Чурикова Наталия Викторовна",
            "col7": "www.social.saratov.gov.ru/novouzensk_family",
            "col8": "8(84562) 2-56-44, ф. 8(84562)2-57-42, 8(84562) 2-58-49",
            "col9": "ChurikovaNV@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 10,
            "col4": "Государственное бюджетное учреждение Саратовской области «Центр социальной помощи семье и детям г.Саратова»",
            "col5": "410010, г. Саратов, ул. Зенитная, 14; г. Саратов, 2-ой Кавказский тупик, д. 7А",
            "col6": "Седина Ольга Григорьевна",
            "col7": "www.social.saratov.gov.ru/saratov_family https://semya-saratov.ru ",
            "col8": "8(8452) 72-66-81, 8(8452) 72-66-70, 8(8452) 72-67-02, 8(8452) 72-66-83, 8(8452) 72-67-81, 8(8452) 72-66-73",
            "col9": "helpcentr64@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 11,
            "col4": "Государственное автономное учреждение Саратовской области «Энгельсский центр социальной помощи семье и детям «Семья»",
            "col5": "413105, Саратовская область, г. Энгельс, ул. Брянская, д. 8 2 здание: Саратовская область, г. Энгельс,ул. Степная, 53",
            "col6": "Андреянова Галина Ивановна",
            "col7": "www.social.saratov.gov.ru/engels_family ",
            "col8": "(845-3) 95-31-25, 77-53-01, 95-31-26, 76-47-84, 76-47-86, 77-52-62, 54-44-56",
            "col9": "semya-engels@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 12,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Аткарского района»",
            "col5": "412420, Саратовская область, г. Аткарск, ул. Советская, д. 117",
            "col6": "Будько Валентина Михайловна",
            "col7": "www.social.saratov.gov.ru/social_state_institutions/cson/atkarsk_cson/ ",
            "col8": "8(84552) 3-39-49, 8(84552) 3-35-25",
            "col9": "atkcson@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 13,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Аркадакского района»",
            "col5": "412210,Саратовская область, г. Аркадак, ул. Ленина, д. 34",
            "col6": "Артёмова Ирина Владимировна",
            "col7": "www.social.saratov.gov.ru/social_state_institutions/cson/arkadak_cson/ ",
            "col8": "8 (84542) 4-17-92, 8(84542) 4-14-48, 8(84542) 4-11-48 ",
            "col9": "cson@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 14,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Дергачевского района»",
            "col5": "413440, Саратовская область, р. п. Дергачи, ул. Советская, д. 52",
            "col6": "Сиротина Людмила Васильевна",
            "col7": "www/social.saratov.gov.ru/social_state_institutions/cson/dergachi_cson/ ",
            "col8": "8 (84563) 2-91-91, 8(84563) 2-92-18, 8(84563) 2-92-19 ",
            "col9": "dergachi.gucson@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 15,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Лысогорского района»",
            "col5": "412860, Саратовская область, р.п. Лысые Горы, ул. Железнодорожная, д. 31",
            "col6": "Репьева Ирина Васильевна",
            "col7": "www.social.saratov.gov.ru/social_state_institutions/cson/lgory_cson/ ",
            "col8": "8 (84551) 2-15-64, 8(84551) 2-12-12, 8(84551) 2-21-22 ",
            "col9": "lcorcson@mail.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 16,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Федоровского района»",
            "col5": "413410, Саратовская область, р.п. Мокроус, ул. Новая, д. 4А",
            "col6": "Бека Валерий Иванович",
            "col7": "www.social.saratov.gov.ru/social_state_institutions/cson/fedorovskij_cson/ ",
            "col8": "8 (84565) 5-08-60, 8(84565) 5-08-59, 8(84565) 5-05-63, 5-15-75 ",
            "col9": "csumokrous@yandex.ru"
        },
        {
            "col1": "Приволжский федеральный округ",
            "col2": "Саратовская область",
            "col3": 17,
            "col4": "Государственное автономное учреждение Саратовской области «Комплексный центр социального обслуживания населения Хвалынского района»",
            "col5": "412780, Саратовская область, г. Хвалынск, ул. Советская, д. 142",
            "col6": "Резвова Людмила Юрьевна",
            "col7": "www.social.saratov.gov.ru/social_state_institutions/cson/khvalinsk_cson/ ",
            "col8": "8 (84595) 2-21-90, 8(84595) 2-18-38, 8(84595) 2-15-76, 8(84595) 2-18-23 ",
            "col9": "hvalsoc@mail.ru"
        },
    ],
        [
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 1,
                "col4": "Региональная общественная организация содействия в решении социальных проблем семьи и человека Удмуртской Республики «Давайте Вместе»",
                "col5": "426054, Удмуртская Республика, г. Ижевск, ул. Школьная, д. 59, кв. 31",
                "col6": "Вахитов Дамир Ильсиярович",
                "col7": "http://davaitevmeste.tilda.ws/ https://vk.com/davaitevmeste18",
                "col8": "8 (963) 483-33-41, 8(912) 449-69-98",
                "col9": "Davaitevmeste18@mail.ru  774317@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 2,
                "col4": "Удмуртская региональная общественная организация «Центр социальных и образовательных инициатив»",
                "col5": "426060, Удмуртская Республика, г. Ижевск, ул. Халтурина, д. 113  Пушкинская улица, 266, офис 206 Корреспонденция: 426060, Удмуртская Республика., г. Ижевск, ул. Пушкинская, д.266, офис 206",
                "col6": "Пишкова Ольга Вадимовна",
                "col7": "http://www.linia.udm.net/",
                "col8": "8 (3412) 43-09-87, 8(922) 683-82-98, 8 (3412) 52-52-87",
                "col9": "udmcsei@gmail.com udmcsei@mail.ru uta-med@inbox.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 3,
                "col4": "Автономная некоммерческая организация по предоставлению услуг в сфере социального развития личности ребенка «Добрые дела»",
                "col5": "427780, Удмуртская Республика, Можгинский район, с. Пычас, ул. Кирпичная, д. 2, кв. 1",
                "col6": "Пронина Ольга Львовна",
                "col7": "",
                "col8": "8 (4832) 26-50-43, 8 (910) 734-22-69, 8(950) 157-97-25",
                "col9": "olgpro8@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 4,
                "col4": "Автономная некоммерческая организация по предоставлению услуг в сфере социального развития личности ребенка «Радужное Детство»",
                "col5": "427790, Удмуртская Республика, г. Можга, ул. Котовского, 34",
                "col6": "Копысова Светлана Леонидовна",
                "col7": "https://vk.com/club181179368",
                "col8": "8 (912) 450-60-30",
                "col9": "svetliachok.78@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 5,
                "col4": "Автономная некоммерческая организация «Центр социальной реабилитации «Судьбы»",
                "col5": "426075, Удмуртская Республика, г. Ижевск, ул. Ивана Сусанина, д. 28",
                "col6": "Гильфанов Ильшат Тахирович",
                "col7": "https://vk.com/cra_sydba_izh",
                "col8": "8 (912) 856-51-19, 8(3412) 56-17-20",
                "col9": ""
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 6,
                "col4": "Автономная некоммерческая организация досуга, просвещения, оздоровления и социальной помощи «Детская православная академия»",
                "col5": "426075, Удмуртская Республика, г. Ижевск, ул. Союзная, д. 39, кв. 21",
                "col6": "Муравьев Кирилл Васильевич",
                "col7": "https://vk.com/dpa_izh",
                "col8": "8 (996) 297-00-72",
                "col9": "anodpa@yandex.ru iercyrill@gmail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 7,
                "col4": "Автономная некоммерческая организация проведения процедуры медиации «Медиаторы 18»",
                "col5": "426060, Удмуртская Республика, г. Ижевск, ул. Халтурина, д. 113",
                "col6": "Сабиров Эдуард Раисович",
                "col7": "http://mediatoru18.ru/",
                "col8": "8 (912) 754-66-36",
                "col9": "mediatoru18@gmail.com"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 8,
                "col4": "Казенное учреждение социального обслуживания Удмуртской Республики «Республиканский социально-реабилитационный центр для несовершеннолетних»",
                "col5": "426006, Удмуртская Республика, г. Ижевск, ул. Телегина, д. 42а",
                "col6": "Корепанова Светлана Александровна",
                "col7": "https://srcn18.ru/",
                "col8": "8 (3412) 93-02-15",
                "col9": "srcn.18@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 9,
                "col4": "Бюджетное учреждение социального обслуживания Удмуртской Республики  «Республиканский центр психолого-педагогической помощи населению  «СоДействие»",
                "col5": "426039, Удмуртская Республика г. Ижевск, Воткинское шоссе, д.110а",
                "col6": "Клокова Анжелика Львовна",
                "col7": "https://sodeistvie18.my1.ru/",
                "col8": "8 (3412) 44-40-88, 8(3412) 44-16-35, 8(3412) 45-33-23",
                "col9": "sodeistvie@msp-org.udmr.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 10,
                "col4": "Автономная некоммерческая организация «Центр психоэмоционального и физического здоровья семьи «Намеда»",
                "col5": "426009, Удмуртская Республика, г. Ижевск, ул. Ленина, д. 108Б",
                "col6": "Стрекалова Светлана Евгеньевна",
                "col7": "",
                "col8": "8 (982) 792-41-90; ",
                "col9": "vesta.west@yandex.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 11,
                "col4": "Благотворительный фонд содействия защите материнства и детства «Право быть женщиной»",
                "col5": "427790, Удмуртская Республика, г. Можга, ул. Родниковая, д. 8А",
                "col6": "Галеева Елена Петровна",
                "col7": "https://vk.com/bf_pravo_byt_zhenshchinoj",
                "col8": "8 (912) 858-21-08; ",
                "col9": "galeeva200@mail.ru"
            },
            {
                "col1": "Приволжский федеральный округ",
                "col2": "Удмуртская Республика ",
                "col3": 12,
                "col4": "Автономная некоммерческая организация по поддержке материнства, отцовства и детства «Во имя семьи»",
                "col5": "426009, Удмуртская Республика, г. Ижевск, пр-д Чапаева, д. 25, кв. 6",
                "col6": "Носова Роза Юрьевна",
                "col7": "https://semyaizhevsk.tilda.ws/, https://vk.com/club219303620",
                "col8": "8 (912) 465-82-11",
                "col9": "vorroza@yandex.ru"
            },
        ]
    ],
    [
        [
            {
                "col1": "Уральский федеральный округ",
                "col2": "Курганская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация Агентство консалтинга и медиации \"СФЕРА\"",
                "col5": "641877, Курганская обл., г. Шадринск, ул. Веселая, д. 7",
                "col6": "Суровцева Наталья Николаевна",
                "col7": "",
                "col8": "8(912) 524-05-00",
                "col9": "n_surovceva@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Курганская область",
                "col3": 2,
                "col4": "Курганское региональное отделение Общероссийской общественно- государственной организации \"Фонд защиты детей\"  ",
                "col5": "640006, Курганская область, г. Курган, ул. Савельева, д. 6, помещ. 4 ",
                "col6": "Соколова Лариса Валентиновна ",
                "col7": "",
                "col8": "8 (912)571-44-27",
                "col9": "FZD45@MAIL.RU"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Курганская область",
                "col3": 3,
                "col4": "Курганская областная общественная организация \"Ассоциация родителей детей инвалидов ",
                "col5": "641871, Курганская область, г. Шадринск, ул. Красноармейская, д. 89, кв. 17",
                "col6": "Председатель Галиева Юлия Наильевна",
                "col7": "",
                "col8": "8 (912)579-02-93",
                "col9": ""
            },
        ],
        [
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 1,
                "col4": "Фонд социальной направленности медиапрограмм и проектов для детей и молодежи и иных граждан \"Возрождение\"",
                "col5": "620089, Свердловская обл., г. Екатеринбург, ул. Родонитовая, д.15, кв.306",
                "col6": "Хамитова Татьяна Михайловна",
                "col7": "www.fond.Lact.ru",
                "col8": "8(343) 221-36-92, 8(912) 240-83-74",
                "col9": "fond nvozrozdenie@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 2,
                "col4": "Некоммерческая организация \"Фонд социально-правовой помощи и медиации \"Равновесие\"",
                "col5": "620146, Свердловская обл., г. Екатеринбург, ул. Волгоградская, д. 41, кв. 199",
                "col6": "Басова Светлана Владимировна",
                "col7": "",
                "col8": "8(912) 637-83-88",
                "col9": ""
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 3,
                "col4": "Автономная некоммерческая организация по оказанию социальных услуг гражданам \"Уральский центр медиации\"",
                "col5": "620014,Свердловская обл., г. Екатеринбург, ул. Воеводина, д. 4, кв. 43",
                "col6": "Махнева Ольга Павловна",
                "col7": "https://uralmediator.ru/ https://vk.com/uralmediator",
                "col8": "8(912) 202-52-12, 8(343) 290-21-31",
                "col9": "urmedia@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 4,
                "col4": "Государственное бюджетное «Центр психолого-педагогической, медицинской  и социальной помощи «Ладо»",
                "col5": "623384, Свердловская область, г. Полевской, мкр-н. Черемушки, д. 24",
                "col6": "Пестова Ирина Васильевна",
                "col7": "http://centerlado.ru/",
                "col8": "8(34350) 5-77-87, 8(922) 100-58-82",
                "col9": "centerlado@yandex.ru ladoekb@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 5,
                "col4": "Муниципальное бюджетное учреждение Екатеринбургский центр психолого-педагогической поддержки несовершеннолет-них «Диалог» ",
                "col5": "620142, Свердловская область, Екатеринбург, ул. 8 Марта, д. 55а",
                "col6": "Соболева Юлия Валерьевна",
                "col7": "http://dialog-eduekb.ru/",
                "col8": "8(343) 251-29-04, 8(343) 251-47-51",
                "col9": "dialog-eduekb@yandex.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 6,
                "col4": "Муниципальное бюджетное учреждение Центр социально-психологической помощи детям и молодежи «Форпост»",
                "col5": "620041, Свердловская область, Екатеринбург, пер. Трамвайный, д. 5",
                "col6": "Леготин Максим Павлович",
                "col7": "https://форпост.екатеринбург.рф/",
                "col8": "8(343) 385-82-20",
                "col9": "forpost@molekb.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 7,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Уральская академия медиаций и комплексной безопасности»",
                "col5": "620043, Свердловская область, Екатеринбург, ул. Репина, д. 101, кв. 16",
                "col6": "Фирсова Раиса Овсеевна",
                "col7": "www.uramkb.ru",
                "col8": "8(343) 287-70-25, 8(343) 287-70-24, 8(912) 208-72-00",
                "col9": "uramkb@mail.ru dis.uramkb@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 8,
                "col4": "Свердловская областная общественная организация «Детский правозащитный фонд «Шанс»",
                "col5": "620034, Свердловская область, Екатеринбург, ул. Бебеля, д. 71",
                "col6": "Стребиж Оксана Юрьевна",
                "col7": "https://chance-fond.ru/",
                "col8": "8(343) 307-34-94",
                "col9": "advokatekb@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 9,
                "col4": "Межрегиональная общественная организация по содействию семьям с детьми в трудной жизненной ситуации «Аистенок» ",
                "col5": "620050, Свердловская область, г. Екатеринбург, ул. Ватутина, д. 11, кв. 52",
                "col6": "Лазарева Лариса Владимировна ",
                "col7": "https://aistenok.org/",
                "col8": "8(343) 371-02-53, 8(343) 367-47-35",
                "col9": "osipova-alla@mail.ru "
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 10,
                "col4": "Свердловская Региональная общественная организация «Кризисный центр для детей и женщин «Екатерина»",
                "col5": "620075, Свердловская область, г. Екатеринбург, ул. Бажова, д. 53, кв. 6",
                "col6": "Ермакова Людмила Михайловна",
                "col7": "http://www.kc-ekaterina.ru/ru/kontakt.php, https://vk.com/public91333889",
                "col8": "8(952) 146-22-23",
                "col9": "kc-ekaterina@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 11,
                "col4": "Муниципальное бюджетное общеобразовательное учреждение городского округа Заречный «Центр психолого-педагогической, медицинской и социальной помощи»",
                "col5": "624250, Свердловская обл., г. Заречный  ул. Островского, д. 4",
                "col6": "Логинова Надежда Александровна",
                "col7": "http://zar-centr.ru/",
                "col8": "8(34377) 72-548",
                "col9": "cpprik2010@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 12,
                "col4": "Автономная некоммерческая организация социальной, психологической, правовой поддержки семьи, детей и граждан, и реализации программ их физического развития «Территория безопасности»",
                "col5": "620146, Свердловская обл., г. Екатеринбург, ул. Амундсена, д. 58, корп. 2, кв. Екатеринбург, Чапаева, 23, офис 4",
                "col6": "Берснева (Муравьева) Анастасия Алексеевна",
                "col7": " http://безтравли.рф/ https://vk.com/safetyterritory",
                "col8": "8(912)608-57-44, 8(904) 980-40-92 ",
                "col9": "maximberenov@yandex.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 13,
                "col4": "Автономная некоммерческая организация социально-психологической помощи «Лаборатория восстановительных технологий»",
                "col5": "620042, Свердловская обл., г. Екатеринбург, ул. Уральских рабочих, д. 51, кв. 101",
                "col6": "Костарева Елена Николаевна ",
                "col7": "",
                "col8": "8 (908)901-27-07",
                "col9": ""
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Свердловская область ",
                "col3": 14,
                "col4": "Автономная некоммерческая организация по оказанию социальных услуг гражданам «Уральский центр медиации и права»",
                "col5": "620131, Свердловская обл., г. Екатеринбург, ул. Металлургов, д. 50, кв. 29",
                "col6": "Талалаева Анна Викторовна ",
                "col7": "",
                "col8": "8 (950)556-38-56 ",
                "col9": "uzmip@bk.ru"
            },
        ],
        [
            {
                "col1": "Уральский федеральный округ",
                "col2": "Тюменская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация \"Западно-Сибирский Центр медиации и права\"",
                "col5": "615062, Тюменская обл., г. Тюмень, ул. Федюнинского, д. 19, кв. 72",
                "col6": "Даровских Юрий Владимирович",
                "col7": "https://zsrcmp.ru/, https://vk.com/mediaciy, https://www.facebook.com/groups/201927190274004/",
                "col8": "8(3452) 36-03-66, 8(904) 490-67-36",
                "col9": "centrmediacii72@mail.ru"
            },
        ],
        [
            {
                "col1": "Уральский федеральный округ",
                "col2": "Ханты-Мансийский автономный округ - Югра",
                "col3": 1,
                "col4": "Автономная некоммерческая организация \"Центр медиации города Сургута и Сургутского района\"",
                "col5": "628405, Ханты-Мансийский автономный округ - Югра, г. Сургут,ул. Ленинградская д. 11, ",
                "col6": "Перлова Любовь Владимировна",
                "col7": "https://vk.com/mediation_surgut",
                "col8": "8 (922) 658-09-68, 8(922) 253-23-75",
                "col9": "lv-perl@rambler.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Ханты-Мансийский автономный округ - Югра",
                "col3": 2,
                "col4": "Союз \"Сибирский Центр медиации\"",
                "col5": "628418, Ханты-Мансийский автономный округ - Югра, г. Сургут, просп. Мира, д. 17, кв. 102, ",
                "col6": "Авдыев Марат Александрович",
                "col7": "www.emediator.ru",
                "col8": "8(982) 226-39-90",
                "col9": "marat@emediator.ru"
            },
        ],
        [
            {
                "col1": "Уральский федеральный округ",
                "col2": "Челябинская область",
                "col3": 1,
                "col4": "Ассоциация \"Лига медиаторов Южного Урала\"",
                "col5": "454007, Челябниская обл., г. Челябинск, просп. Ленина, д. 13-А, оф. 6",
                "col6": "Соболева Наталья Александровна",
                "col7": "https://mediator74.ru/ https://vk.com/liga.mediatorov",
                "col8": "8(351)949-27-16, +7(908)570-11-00, +7(951)444-74-44",
                "col9": "mediator74@bk.ru, knm0812@mail.ru"
            },
            {
                "col1": "Уральский федеральный округ",
                "col2": "Челябинская область",
                "col3": 2,
                "col4": "Муниципальное бюджетное учреждение «Центр психолого-педагогической, медицинской и социальной помощи  Калининского района г. Челябинска» (Ресурсный центр медиации)",
                "col5": "454084, Челябинская обл., г.Челябинск, ул. Калинина, д. 17  Фактический адрес Ресурсного центра медиации : 454080, Челябинская обл., г. Челябинск, ул. Сони Кривой, д.45",
                "col6": "Меркасимова Ольга Сергеевна",
                "col7": "http://rcm74.ru/",
                "col8": "8 (351) 232-05-32",
                "col9": "rcm_2020@mail.ru"
            },
        ]
    ],
    [
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 1,
                "col4": "Комплексный центр социального обслуживания населения города Барнаула",
                "col5": "656052, Алтайский край,г. Барнаул, ул. Телефонная,д. 50а",
                "col6": "Степанова Ольга Владимировна",
                "col7": "http://kcso-barnaul.ru/",
                "col8": "8(3852) 55-05-24, 8(3852) 55-70-93",
                "col9": "socgd6060@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 2,
                "col4": "Комплексный центр социального обслуживания населения города Бийска",
                "col5": "659303, Алтайский край,г. Бийск, ул. Вагонная, д. 65",
                "col6": "Арутюнян Анна Юрьевна",
                "col7": "http://soc-scentrbiysk.ru/",
                "col8": "8(3854) 40-55-33",
                "col9": "scentr@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 3,
                "col4": "Комплексный центр социального обслуживания населения Каменского района",
                "col5": "658709, Алтайский край,г. Камень-на-Оби,ул. Терешковой, д. 21а",
                "col6": "Мишустина Маргарита Станиславовна",
                "col7": "http://kcson-kamen.ru/",
                "col8": "8 (385-84) 3-52-30, 8 (385-84) 3-67-56",
                "col9": "kamen-centr2015@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 4,
                "col4": "Комплексный центр социального обслуживания населения Михайловского района",
                "col5": "658960, Алтайский край, Михайловский р-н,с. Михайловское,ул. Советская, д. 3",
                "col6": "Ковалева Лариса Анатольевна",
                "col7": "http://mhlcenter.altai.socinfo.ru/",
                "col8": "8(385-70) 2-45-92",
                "col9": "kguso@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 5,
                "col4": "Комплексный центр социального обслуживания населения города Новоалтайска",
                "col5": "658080, Алтайский край,г. Новоалтайск,ул. Строительная, д. 12а",
                "col6": "Егошина Алена Владимировна",
                "col7": "https://www.kcson-novoaltaysk.ru/",
                "col8": "8(385-32) 2-18-17",
                "col9": "novoalt-2005@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 6,
                "col4": "Комплексный центр социального обслуживания населения Павловского района",
                "col5": "659000, Алтайский край, Павловский р-н, с. Павловск, ул. Ленина, д. 9",
                "col6": "Сазонова Елена Сергеевна",
                "col7": "http://cso22.ru/",
                "col8": "8(385-81) 2-19-88",
                "col9": "cen-pavlovsk@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 7,
                "col4": "Комплексный центр социального обслуживания населения города Рубцовска",
                "col5": "658201, Алтайский край,г. Рубцовск, ул. Октябрьская,д. 131",
                "col6": "Батьканов Василий Юрьевич",
                "col7": "https://kcson-rubcovsk.altai.socinfo.ru",
                "col8": "8(385-57) 2-30-08",
                "col9": "social-care@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 8,
                "col4": "Комплексный центр социального обслуживания населения города Славгорода",
                "col5": "658820, Алтайский край,г. Славгород, ул. Луначарского, д. 126",
                "col6": "Сергеева Татьяна Дмитриевна",
                "col7": "http://центр-славгород.рф/",
                "col8": "8(38568) 5-08-72",
                "col9": "senter.pom@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 9,
                "col4": "Комплексный центр социального обслуживания населения Смоленского района",
                "col5": "659600, Алтайский край, Смоленский р-н,с. Смоленское, ул. Советская, д. 109/1Факт. адрес:с. Смоленское, ул. Титова,д. 58а",
                "col6": "Демидова Лариса Васильевна",
                "col7": "https://smlcentr.altai.socinfo.ru/",
                "col8": "8(38536) 2-27-71",
                "col9": "smlcentrsocpom@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 10,
                "col4": "Комплексный центр социального обслуживания населения Советского района",
                "col5": "659540, Алтайский край, Советский р-н, с. Советское, ул. Советская, д. 74б",
                "col6": "Кудряшова Елена Викторовна",
                "col7": "http://kcsonsovet.ru",
                "col8": "8(385-98) 2-27-50",
                "col9": "centrsocpomsov@ya.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 11,
                "col4": "Комплексный центр социального обслуживания населения Тальменского района",
                "col5": "658030, Алтайский край, Тальменский р-н,р.п. Тальменка, ул. Алтайская,д. 8б",
                "col6": "Речкунова Инга Юрьевна",
                "col7": "https://socpomoh.ru/",
                "col8": "8(385-91) 2-77-58",
                "col9": "socpomoh@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 12,
                "col4": "Комплексный центр социального обслуживания населения Шипуновского района",
                "col5": "658390, Алтайский край, Шипуновский район, с.Шипуново,пр-т Комсомольский, д. 46",
                "col6": "Иванова Ольга Николаевна",
                "col7": "http://kcson-shipunovo.altai.socinfo.ru/",
                "col8": "8(38550) 2-10-90",
                "col9": "kguso-ship@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 13,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Краевой кризисный центр для женщин»",
                "col5": "656056, Алтайский край, г. Барнаул, ул. Гоголя, 6",
                "col6": "Кузнецова Юлия Евгеньевна",
                "col7": "https://womenkrizis.ru",
                "col8": "8(3852)34-26-94",
                "col9": "womenkrizis@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 14,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Краевой кризисный центр для мужчин»",
                "col5": "656052, Алтайский край, г. Барнаул, ул. Исакова, 113-е",
                "col6": "Эдокова Лариса Александровна",
                "col7": "http://www.criscentr.ru/",
                "col8": "8(3852) 55-12-88",
                "col9": "criscentr@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 15,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Краевой социально-реабилитационный центр для несовершеннолетних «Солнышко»",
                "col5": "656010, Алтайский край, г. Барнаул, ул. Смирнова, 79 «Г»",
                "col6": "Савина Оксана Григорьевна",
                "col7": "http://center-sun.altai.socinfo.ru/",
                "col8": "8(3852) 34-03-12, 8(3852) 34-89-87, 8(3852) 34-27-74",
                "col9": "sun_center@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 16,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания«Краевой социально-реабилитационный центр для несовершеннолетних «Надежда»",
                "col5": "659305, Алтайский край, г. Бийск, пер. Мартьянова д. 53/1",
                "col6": "Воронцова Елена Викторовна",
                "col7": "https://nadezhda22.ru/",
                "col8": "8(83854) 43-53-25",
                "col9": "detskiypriut@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 17,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения города Алейска»",
                "col5": "658130, Алтайский край, г. Алейск, ул. Сердюка, 99",
                "col6": " Решетова Оксана Александровна",
                "col7": "https://kcson-aleisk.altai.socinfo.ru/",
                "col8": "8(38553) 6-62-46",
                "col9": "davydova160@rambler.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 18,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения города Заринска»",
                "col5": "659100, Алтайский край, г. Заринск, у. Горького, д. 15",
                "col6": "Пузырева Марина Владимировна",
                "col7": "https://kgbysozarinsk.altai.socinfo.ru/",
                "col8": "8(38595) 4-54-55",
                "col9": "kguso_zarinsk@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 19,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания«Комплексный центр социального обслуживания населения Локтевского района»",
                "col5": "658420, Алтайский край, Локтевский район г. Горняк, ул. Ленина, 10а",
                "col6": "Акимова Яна Аркадьевна",
                "col7": "https://kcson-lokt.altai.socinfo.ru/",
                "col8": "8(38586) 329-11",
                "col9": "lokt-zentr@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 20,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения Мамонтовского района»",
                "col5": "658560, Алтайский край, Мамонтовский район, с. Мамонтово, ул. Партизанская, 169",
                "col6": "Колесникова Татьяна Викторовна",
                "col7": "http://kc-mamontovo.altai.socinfo.ru/",
                "col8": "8(38583) 22-2-03",
                "col9": "mmt8094326@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 21,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения Немецкого национального района»",
                "col5": "658870 Алтайский край, Немецкий национальный район, с. Гальбштадт, ул. .Школьная 17 А ",
                "col6": "Шаманская Нина Васильевна",
                "col7": "https://soccentr-80.jimdofree.com/",
                "col8": "8 (385 39)  22-1-93, 8(38539) 2-23-00",
                "col9": "soccentr_80@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 22,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения Родинского района»",
                "col5": "659780, Алтайский край, Родинский район, с. Родино, ул. Ленина, 228",
                "col6": "Ткаченко Людмила Владимировна",
                "col7": "https://socialhelp.altai.socinfo.ru/",
                "col8": "8(38563) 2-34-84",
                "col9": "annenko81@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 23,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения города Рубцовска»",
                "col5": "658201, Алтайский край, г. Рубцовск, ул. Октябрьская, 131",
                "col6": "Батьканов Василий Юрьевич",
                "col7": "https://kcson-rubcovsk.altai.socinfo.ru/",
                "col8": "8(38557) 230-08, 8(38557) 2-28-72",
                "col9": "social-care@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 24,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения города Славгорода»",
                "col5": "658820, Алтайский край, г. Славгород, ул. Луначарского,126",
                "col6": "Сергеева Татьяна Дмитриевна",
                "col7": "http://центр-славгород.рф/",
                "col8": "8(38568) 5-08-72, 8(38568) 5-10-15",
                "col9": "slavgsentr@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 25,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения Троицкого района»",
                "col5": "659841, Алтайский край, Троицкий район, с. Троицкое, пер. Речной, 2",
                "col6": "Ямщикова Татьяна Георгиевна",
                "col7": "https://socpom22.ru/",
                "col8": "8(38534) 2-27-87",
                "col9": "socpom@ab.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Алтайский край ",
                "col3": 26,
                "col4": "Краевое государственное бюджетное учреждение социального обслуживания «Комплексный центр социального обслуживания населения Усть-Калманского района»",
                "col5": "658150, Алтайский край, Усть-Калманский район, с. Усть-Калманка, ул. Ленина, 37",
                "col6": "Зверева Галина Александровна",
                "col7": "http://ukalmsentr.altai.socinfo.ru/",
                "col8": "8 (38599) 22-6-02, 8 (38599) 22-3-74",
                "col9": "ukalmsentr@mail.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область",
                "col3": 1,
                "col4": "Ассоциация (некоммерческое партнерство) «Байкальская лига медиаторов»",
                "col5": "664025, Иркутская обл., г. Иркутск, ул. Степана Разина, д. 27, оф. 801/7 (офис 201.)",
                "col6": "Матвеева Анастасия Александровна",
                "col7": "http://irkmediator.ru/",
                "col8": "8 (3952) 93-05-97, 8(914) 152-96-36, 8(3952) 667-599",
                "col9": "info@irkmediator.ru mediaciafirst@yandex.ru, "
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область",
                "col3": 2,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Иркутский межрегиональный центр образовательных и медиационных технологий» ",
                "col5": "664081, Иркутская обл., г. Иркутск, ул. Пискунова, д. 160, оф. 528  (оф. 525)",
                "col6": "Кирьянова Анастасия Александровна",
                "col7": "http://mediacia-prof.com/",
                "col8": "8 (914) 933-86-05, 8(3952) 747-735, 8 (3952) 747-786",
                "col9": "irkmediator@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область",
                "col3": 3,
                "col4": "Некоммерческая организация «Иркутский молодёжный фонд правозащитников «ЮВЕНТА»",
                "col5": "664009, Иркутская область, г. Иркутск, ул. Красноярская, д. 35, кв.12",
                "col6": "Кирьянова Анастасия Александровна",
                "col7": "http://www.uventa38.ru/",
                "col8": "8 (914) 933-86-05, 8(3952) 74-77-35, 8 (3952) 74-77-86, 8 (950) 065-11-63",
                "col9": "irkmediator@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область",
                "col3": 4,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования Центр медиации, профориентации и развития творческого потенциала детей и взрослых «Точка опоры»",
                "col5": "665830, Иркутская область, город Ангарск, квартал 53, дом 4, квартира 5",
                "col6": "Иванец Юлия Александровна",
                "col7": "",
                "col8": "8 (902)510-62-41",
                "col9": ""
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область",
                "col3": 5,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования «Иркутский центр медиации Легато»",
                "col5": "664075, Иркутская область, город Иркутск, улица Байкальского, дом 200 А, квартира 57",
                "col6": "Шуруева Надежда Александровна ",
                "col7": "",
                "col8": "8(908) 656-53-54",
                "col9": ""
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Иркутская область ",
                "col3": 6,
                "col4": "Иркутская региональная общественная организация социально-психологической поддержки населения «Гармония» ",
                "col5": "665460, Иркутская область, г Усолье-Сибирское, ул Луначарского, д. 33, кв. 45",
                "col6": "Ноздрина Кристина Владимировна",
                "col7": "https://vk.com/public216380939",
                "col8": "8(950) 126-53-97",
                "col9": ""
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Кемеровская область",
                "col3": 1,
                "col4": "Государственная организация образования «Кузбасский региональный центр психолого-педагогической, медицинской и социальной помощи «Здоровье и развитие личности»",
                "col5": "650023, Кемеровская область - Кузбасс, г. Кемерово, пр-кт Ленина, д. 126а, ",
                "col6": "Свиридова Ирина Альбертовна",
                "col7": "https://kuzrc.ru/",
                "col8": "8 (3842) 77-07-67, 8 (3842) 90-06-90",
                "col9": "info@kuzrc.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Красноярский край",
                "col3": 1,
                "col4": "Красноярская региональная общественная организация Центр медиации \"ТЕРРИТОРИЯ СОГЛАСИЯ\"",
                "col5": "660049, Красноярский кр., г. Красноярск, ул. Перенсона, д. 5-А, кв. 127",
                "col6": "Дрянных Ольга Валерьевна",
                "col7": "http://sporanet24.ru/",
                "col8": "8 (902) 990-12-07",
                "col9": "ms.dryannykh@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Красноярский край",
                "col3": 2,
                "col4": "Межрегиональная общественная организация \"Центр развивающих технологий \"Сотрудничество\"",
                "col5": "660075, Красноярский кр., г. Красноярск, ул. Маерчака, д. 3, офис. 210",
                "col6": "Фомина Елена Юрьевна",
                "col7": "https://kccp.ru/",
                "col8": "8 (9391) 211-91-50, 8 (902) 991-70-16",
                "col9": "kccp@kccp.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Красноярский край",
                "col3": 3,
                "col4": "Красноярская региональная общественная организация «Кризисный центр для женщин и их семей, подвергшихся насилию «Верба»",
                "col5": "660016, Красноярский край, г. Красноярск, ул. Анатолия Гладкова, д.25, кв. 33",
                "col6": "Пальчик Наталья Борисовна",
                "col7": "https://centerverba.ru/  https://vk.com/centerverba",
                "col8": "8 (391) 231-48-47, 8 (904) 891-00-34",
                "col9": "centerverba@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Красноярский край",
                "col3": 4,
                "col4": "Автономная некоммерческая организация «Медиация. Управление конфликтом»",
                "col5": "660028, г. Красноярск, ул. Телевизорная, д. 1, оф. 567",
                "col6": "Елена Владимировна Васьковская",
                "col7": "http://netspora.ru/",
                "col8": "8 (908) 015-79-51",
                "col9": "netspora@mail.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Новосибирская область ",
                "col3": 1,
                "col4": " Союз «Сибирский центр медиации»",
                "col5": "630049, Новосибирская обл, г. Новосибирск,Красный пр-кт, д. 171/4, оф. 54",
                "col6": "Авдыев Марат Александрович",
                "col7": "https://emediator.ru/",
                "col8": "8 (383) 277-13-51, 8 (923) 236-68-83",
                "col9": "marat@edogovor.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Новосибирская область ",
                "col3": 2,
                "col4": "Автономная некоммерческая организация образования и права «Новосибирский центр медиации»",
                "col5": "630128, Новосибирская обл, г. Новосибирск, ул. Полевая, д. 11, кв. 95, Факт.адрес: Новосибирская обл, г. Новосибирск, ул Зыряновская, д.53",
                "col6": "Рубан Ольга Ивановна",
                "col7": "https://mediatsib.ru/",
                "col8": "8 (913) 924-05-05, 8 (905) 950-22-10, 8 (383) 334-33-97",
                "col9": "mediana@mediatsib.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Новосибирская область ",
                "col3": 3,
                "col4": "Некоммерческое партнерство «Профессиональная лига медиаторов»",
                "col5": "630128, Новосибирская обл, г. Новосибирск, ул. Полевая,д. 11, кв. 95 Факт.адрес: Новосибирская обл, г. Новосибирск, ул Зыряновская, д.53",
                "col6": "Рубан Ольга Ивановна",
                "col7": "www.ligamediatorov.ru",
                "col8": "8 (383) 334-33-97",
                "col9": "mediana@mediatsib.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Омская область",
                "col3": 1,
                "col4": "Омское региональное отделение Общероссийского общественного движения \"Молодежная Ассамблея народов России \"МЫ-РОССИЯНЕ\"",
                "col5": "644099, РФ, Омская область, г. Омск, ул. Спартаковская, 3, цоколь, каб.12.",
                "col6": "Хапизов Ермек Илюбаевич",
                "col7": "https://manr55.ru/",
                "col8": "8 (3812) 48-97-97, 8 (902) 677-94-97, 8 (950) 338-54-51",
                "col9": "my-rossiyane-omsk@mail.ru, omsk@my-rossiyane.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Омская область",
                "col3": 2,
                "col4": "Омская местная детская общественная организация по развитию творческой, социально-активной личности \"ОАЗИС\"",
                "col5": "644010, Омская обл., г. Омск, просп. Карла Маркса, д. 29А, кв. 87",
                "col6": "Скрипник Мария Эдуардовна",
                "col7": "http://sozvezdieomsk.ru",
                "col8": "8 (913) 616-29-97, 8 (3812) 312-563",
                "col9": "ogdoo_oazis@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Омская область",
                "col3": 3,
                "col4": "ФГБПОУ ВО «Омский государственный педагогической университет»",
                "col5": "644099, г. Омск, Набережная Тухаческого, д. 14",
                "col6": "Макарова Наталья Станиславовна  ",
                "col7": "https://omgpu.ru/",
                "col8": "8 (3812) 23-12-20",
                "col9": "mail@omgpu.ru, t.m.ovchinnikova@omgpu.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Республика Алтай",
                "col3": 1,
                "col4": "Региональный общественный благотворительный фонд защиты семьи, материнства, отцовства и детства «МАМА» Республики Алтай",
                "col5": "649002, Республика Алтай г. Горно-Алтайск, пр-т Коммунистический, 89.",
                "col6": "Тадырова Нина Ивановна",
                "col7": "https://vk.com/public155165138",
                "col8": "8 (388-22) 6-30-79",
                "col9": "tadyevam@mail.ru"
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Республика Бурятия",
                "col3": 1,
                "col4": "Муниципальное бюджетное  учреждение  дополнительного образования «Центр диагностики и консультирования»  г. Улан-Удэ",
                "col5": "670034, г.Улан-Удэ, ул. Хахалова, 8 ",
                "col6": "Жебрун Яна  Олеговна",
                "col7": "https://tsdik.buryatschool.ru/",
                "col8": "8 (3012)55-38-17",
                "col9": "psy-center@list.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Республика Бурятия",
                "col3": 2,
                "col4": "Автономная некоммерческая организация \"Бурятский республиканский центр медиации и права\"",
                "col5": "670014, Республика Бурятия, г. Улан- Удэ, кв-л 4-ый, территория ДНТ Ранет, д. 5",
                "col6": "Чиркова Лия Альбертовна",
                "col7": "",
                "col8": "8 (914)057-87-78",
                "col9": "e-mail: burmediator@mail.ru  "
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Республика Тыва",
                "col3": 1,
                "col4": "Частное учреждение дополнительного образования «Центр дополнительного образования «Логос»",
                "col5": "667000, г. Кызыл, ул. Комсомольская 13",
                "col6": "Сванес Анна Николаевна",
                "col7": "https://logosschool.wixsite.com/logos-kyzyl",
                "col8": "8 (923) 541-68-05",
                "col9": "annasvanes@yandex.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Республика Тыва",
                "col3": 2,
                "col4": "Тувинская региональная общественная организация Правовой  клуб «Фемида»  ",
                "col5": "667010, г. Кызыл, ул.  Московская, д.  106",
                "col6": "Бондаренко  Наталья  Викторовна",
                "col7": " https://vk.com/club198969620",
                "col8": "8 (923) 381-88-78",
                "col9": ""
            },
        ],
        [
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Томская область ",
                "col3": 1,
                "col4": "Благотворительный Фонд социальной поддержки и защиты прав детей, находящихся в трудной жизненной ситуации, охраны окружающей среды и защиты животных \"Меркурия\"",
                "col5": "634009,Томская обл., г. Томск, пр. Ленина, д. 126, кв.19 ",
                "col6": "Сова Станислав Олегович",
                "col7": "https://mercuriya.ru/",
                "col8": "8 (903)955-49-13, 8(952) 158-82-58",
                "col9": "bf@mercuriya.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Томская область ",
                "col3": 2,
                "col4": "Автономная некоммерческая организация Ресурсный центр «Согласие»",
                "col5": "634507, Томская обл, Томский р-н, поселок Зональная Станция, ул Изумрудная, д 2",
                "col6": "Пучкина Юлия Александровна",
                "col7": "https://www.asi.org.ru/ngoprofile/ano-rts-soglasie/, https://vk.com/soglasievtomske, https://www.soglasie-tomsk.com/",
                "col8": "8 (913) 840-08-97, 8(952)809-73-89",
                "col9": "soglasie-tomsk@mail.ru"
            },
            {
                "col1": "Сибирский федеральный округ",
                "col2": "Томская область ",
                "col3": 3,
                "col4": "Томская региональная общественная благотворительная организация \"Союз воспитанников детских домов и школ-интернатов Томской области\"",
                "col5": "634050, г.Томск, пл.Ленина, 6А, кв.8",
                "col6": "Оленева Наталья Павловна",
                "col7": "",
                "col8": "8(913)865-22-53",
                "col9": ""
            },
        ]
    ],
    [
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Амурская область",
                "col3": 1,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования образовательный центр  «ЛИДЕР»",
                "col5": "675000,Амурская обл. город Благо-вещенск, ул Б. Хмельницкого 20 ,офис 8",
                "col6": "Галоян Арпеник Ремиковна",
                "col7": "https://lider-pro.com/",
                "col8": "8 (924) 841-50-60",
                "col9": "lider_blag@mail.ru"
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Еврейская автономная область",
                "col3": 1,
                "col4": "Областное государственное бюджетное учреждение дополнительного образования   «Центр МОСТ»",
                "col5": "679016, ЕАО, г. Биробиджан, ул. Пионерская, д. 35",
                "col6": "Колобов Дмитрий Сергеевич",
                "col7": "https://molodezh79.ru/",
                "col8": "8 (42622) 6-02-11",
                "col9": "molodeg79@post.eao.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Еврейская автономная область",
                "col3": 2,
                "col4": "Областное государственное бюджетное учреждение социального обслуживания «Социально-реабилитационный центр для несовершеннолетних»",
                "col5": "679000, ЕАО, г. Биробиджан, ул. Тихонькая, д. 5",
                "col6": "Безродных Оксана Владимировна",
                "col7": "http://social.eao.ru/",
                "col8": "8 (42622) 2-61-08",
                "col9": "src@post.eao.ru"
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Забайкальский край ",
                "col3": 1,
                "col4": "Государственное учреждение «Центр психолого-педагогической помощи населению «Доверие» Забайкальского края",
                "col5": "672042, г. Чита, ул. Текстильщиков д. 1а ",
                "col6": "Кузьмина Наталья Евгеньевна",
                "col7": "https://doverie.chita.socinfo.ru/",
                "col8": "8 (83022) 28-33-57",
                "col9": "guso_doverie@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Забайкальский край ",
                "col3": 2,
                "col4": "Государственное учреждение социального обслуживания «Черновский комплексный центр социального обслуживания населения «Берегиня» Забайкальского края",
                "col5": "673030, г. Чита-30, КСК, ул.Труда. д. 7-а",
                "col6": "Кузьминова Татьяна Ивановна",
                "col7": "www.bereginya.zabguso.ru",
                "col8": "8 (83022) 25-32-11",
                "col9": "guso_bereginya@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Забайкальский край ",
                "col3": 3,
                "col4": "Государственное учреждение «Забайкальский краевой Центр психолого-педагогической, медицинской и социальной помощи «Семья»",
                "col5": "672010 г.Чита, ул.Ленина, д.27а",
                "col6": "Тонышева Наталья Викторовна",
                "col7": "www.centr-semya.ru",
                "col8": "8 (914) 438-90-50",
                "col9": "semya2003@mail.ru"
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 1,
                "col4": "Национальная Ассоциация профессиональных медиаторов «ПАРИТЕТ»",
                "col5": "117342, г. Москва, ул. Бутлерова, д. 17Б,эт/пом/оф 1/I/120",
                "col6": "Дубинина Юлия Александровна",
                "col7": "https://mediation-parity.ru",
                "col8": "8 (914) 621-83-01, 8 (924) 781-65-15",
                "col9": "kirichenko76@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 2,
                "col4": "Камчатское региональное отделение Общероссийской общественной организации «Ассоциация юристов России»",
                "col5": "683000, Камчатский край г. Петропавловск-Камчатский,ул. Советская,д. 40, оф. 1",
                "col6": "Курмаева Светлана Рашидовна",
                "col7": "http://old.alrf.ru/region41/",
                "col8": "8 (4152) 41-28-07, 8 (924) 792-59-03, 8 (962) 291-55-55",
                "col9": "alrf-kamchatka@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 3,
                "col4": "Краевое государственное автономное учреждение социальной защиты «Камчатский социально-реабилитационный центр для несовершеннолетних»",
                "col5": "684300, Камчатский край, Мильковский район,с. Мильково, ул. Советская,д. 17А",
                "col6": "Аненко Ирина Владимировна",
                "col7": "https://www.centrmilkovo.ru/",
                "col8": "8 (41533) 2-23-86 , 8 (41533) 2-13-21",
                "col9": "CentrMilkovo@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 4,
                "col4": "Автономное учреждение социальной защиты «Камчатский центр социальной помощи семье и детям»",
                "col5": "683003,Камчатский край,г. Петропавловск-Камчатский, ул. Ключевская,д. 28",
                "col6": "Денисюк Ольга Ивановна",
                "col7": "http://www.kamsoccentr.ru",
                "col8": "8 (4152) 42-75-03, 8 (4152) 42-45-49, 8 (4152) 42-78-10",
                "col9": "miloserdie92@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 5,
                "col4": "Краевое государственное автономное учреждение социальной защиты «Паланский комплексный центр социального обслуживания населения»",
                "col5": "688000, Камчатский край, пгт Палана, ул. Обухова, д. 2Б, кв.2",
                "col6": "Меньшенина Татьяна Николаевна",
                "col7": "www.detipalana.kamch.socinfo.ru",
                "col8": "8 (41543) 32-0-45, 8 (41543) 31-9-04, 8 (41543) 31-7-40",
                "col9": "social.deti-2@mail.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Камчатский край ",
                "col3": 6,
                "col4": "Краевое государственное автономное учреждение социальной зашиты «Комплексный центр социального обслуживания населения Елизовского района»",
                "col5": "684000, Камчатский край, г.Елизово, ул. Беринга, д. 6",
                "col6": "Никифорова Елена Борисовна",
                "col7": "https://ktson.ru/",
                "col8": "8 (41531) 6-41-70, 8 (41531) 7-12-55",
                "col9": "ktson.elizovo@mail.ru "
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Приморский край ",
                "col3": 1,
                "col4": "Ассоциация медиаторов и посредников АТР",
                "col5": "690091, г. Владивосток, ул. Мордовцева, д. 3, офис 1202",
                "col6": "Лыкова Наталья Андреевна",
                "col7": "https://ampatr.ru/",
                "col8": "8 (914) 791-20-06, 8 (914) 704-99-91",
                "col9": "ampatr@mail.ru"
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Республика Саха (Якутия) ",
                "col3": 1,
                "col4": "Автономная некоммерческая организация внесудебного урегулирования споров и конфликтных ситуаций \"Якутский республиканский центр медиации\"",
                "col5": "677008, Республика Саха (Якутия), г. Якутск, СОТ Мерзлотовед, д. 11",
                "col6": "Иванова Долгуяна Николаевна",
                "col7": "https://www.сахамедиация.рф/",
                "col8": "8 (914) 103-24-90",
                "col9": "yakutiancm@mail.ru"
            },
        ],
        [
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Сахалинская область ",
                "col3": 1,
                "col4": "Автономная некоммерческая организация дополнительного профессионального образования \"Центр профессионального урегулирования споров и конфликтов - \"Медиаторы Сахалина\"",
                "col5": "693005, Сахалинская обл., г. Южно-Сахалинск, ул. Достоевского, д. 7",
                "col6": "Татауров Андрей Алексеевич",
                "col7": "http://sakhmediator.ru/, https://vk.com/sakhmediator",
                "col8": "8 (963) 289-23-93,, 8 (4242)47-23-93",
                "col9": "sakhmediator@yandex.ru"
            },
            {
                "col1": "Дальневосточный федеральный округ",
                "col2": "Сахалинская область ",
                "col3": 2,
                "col4": "Сахалинское региональное отделение Общероссийской общественной организации «Ассоциация юристов России»",
                "col5": "693000, Сахалинская область, г. Южно-Сахалинск, Коммунистический проспект, дом 31-Б/1, офис 302",
                "col6": "Григорьев Георгий Иванович",
                "col7": "http://old.alrf.ru/region65/",
                "col8": "8 (4242) 32-12-21, 8(4242) 25-58-06",
                "col9": "alrf65@yandex.ru"
            }
        ]
    ]
]