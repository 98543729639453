import React from "react";
import {useState} from "react";
import s from "./Falldown.module.css"
import "./Falldown.css"
import img21 from "../../../assets/img/1.png"
import {DownOutlined} from "@ant-design/icons"
import ico1 from "../../../assets/img/link1.png"
import ico2 from "../../../assets/img/29_1-512.webp"


const Falldown = ({url, header, children, style = {}}) => {

    const [active, setActive] = useState(false)
    return <div className={s.fall}  style={{...style, zIndex: 1}}>
        <div className={s.block} onClick={() => {
            active ? setActive(false) : setActive(true)
        }} onBlur={() => {
            setActive(false)
        }}>

                <div className={s.blockHead}>
                    {header}
                </div>
                <span className={active ? s.downarrow : s.arrow}>
                    <DownOutlined
                        style={{fontSize: '28px', color: '#fff'}}
                    />
                </span>


            {/*<div className={s.ico} style={{backgroundImage: `url(${url})`, zIndex: 3, opacity: 1}}>*/}

            {/*</div>*/}
        </div>
        {active ?
        <nav className={s.active}>
            <ul>
                {children}
            </ul>
        </nav>
        : <div></div>}
    </div>


}
export const Falldown2 = ({url = null, header, children, style = {}}) => {
    const [active2, setActive2] = useState(false)
    return <div className={s.fall2} style={style}>
        <div className={s.block} onClick={() => {
            active2 ? setActive2(false) : setActive2(true)
        }} onBlur={() => {
            setActive2(false)
        }}>

                <div className={s.blockHead}>
                    {header}
                </div>
                <span className={active2 ? s.downarrow2 : s.arrow2}> <DownOutlined
                    style={{fontSize: '28px', color: '#fff'}}/>
                </span>


            {/*<div className={s.ico} style={{backgroundImage: `url(${url})`, zIndex: 3, opacity: 0.7}}>*/}

            {/*</div>*/}
        </div>
        {active2 ?
        <nav className={s.active}>
            <ul>
                {children}
            </ul>
        </nav> : <div></div>}
    </div>

}

export const FalldownDop = ({url, header, children, style = {}}) => {

    const [active, setActive] = useState(false)
    return <div className={s.fall} style={style}>
        <div className={s.block} onClick={() => {
            active ? setActive(false) : setActive(true)
        }} onBlur={() => {
            setActive(false)
        }}>
            <div style={{width: "700px", marginTop: "42px"}}>
                <div className={s.blockHead}>
                    {header}
                </div>
                <span className={active ? s.downarrow : s.arrow}> <DownOutlined
                    style={{fontSize: '28px', color: '#fff'}}/> </span></div>

            <div className={s.icoDop} style={{backgroundImage: `url(${url})`, zIndex: 3, opacity: 1}}>

            </div>
        </div>
        {active ?
            <nav className={s.active}>
                <ul>
                    {children}
                </ul>
            </nav>
            : <div></div>}
    </div>


}
export const Falldown2Mediacia = ({url = null, header, children, style = {}}) => {
    const [active2, setActive2] = useState(false)
    return <div className={s.fall2Med} style={style}>
        <div className={s.block} onClick={() => {
            active2 ? setActive2(false) : setActive2(true)
        }} onBlur={() => {
            setActive2(false)
        }}>

                <div className={s.blockHead}>
                    {header}
                </div>
                <span className={active2 ? s.downarrow : s.arrow}>
                    <DownOutlined style={{fontSize: '28px', color: '#fff'}}/>
                </span>

            {/*<div className={s.icoMediacia} style={{backgroundImage: `url(${url})`, zIndex: 3, opacity: 0.7}}>*/}

            {/*</div>*/}
        </div>
        {active2 ?
            <nav className={s.active}>
                <ul>
                    {children}
                </ul>
            </nav> : <div></div>}
    </div>

}

export const HrefLi = ({text, href = "https://fcprc.ru/mediacia/"}) => {
    return <li>
        <a href={href}>
            <div className={s.hrefblock}>
                {text}
                <img src={ico1}/>
            </div>
        </a>
    </li>
}


export const FileLi = ({text, href = "https://fcprc.ru/mediacia/"}) => {
    // Заменяем http на https в ссылке
    const secureHref = href?.replace('http://', 'https://');
    
    return <li className={s.file}>
        <a href={secureHref}>
            <div>
                <img src={ico2}/>
                {text}
            </div>
        </a>
    </li>
}

export default Falldown