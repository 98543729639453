import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News20250320.jpg"
import img1 from "../../assets/News/News20250320_1.jpg"

const News250320 = ({children, header}) => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            19 марта 2025 года состоялся семинар «Наставничество в сфере профилактики безнадзорности и правонарушений несовершеннолетних»
        </h2>
        <div>
            <img src={img1}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            <p>Мероприятие организованно Региональным ресурсным центром профилактики безнадзорности и правонарушений несовершеннолетних ГАОУ ДПО «Ленинградский областной институт развития образования» совместно с Комитетом общего и профессионального образования Ленинградской области.
            </p>
            <p>Семинар был направлен на обсуждение вопросов использования потенциала межведомственного взаимодействия и эффективных механизмов развития института наставничества в сфере профилактики безнадзорности и правонарушений несовершеннолетних.
            </p>
            <p>Участники семинара – специалисты органов и учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних Ленинградской области, председатели и члены комиссий по делам несовершеннолетних и защите их прав муниципальных образований.
            </p>
            <p>С приветственным словом к участникам семинара обратились:
                <div></div>
<b>Котова Елена Юрьевна</b>, заместитель главы администрации по социальным вопросам Тихвинского района;
<div></div>
<b>Толстова Татьяна Николаевна</b>, Уполномоченный по правам ребенка в Ленобласти;
<div></div>
<b>Артамонова Елена Раифовна</b>, начальник сектора профилактики безнадзорности и правонарушений несовершеннолетних департамента образования Ленобласти.
</p>
<p>
В пленарной сессии семинара выступила <b>Артамонова Елена Геннадьевна</b>, заместитель директора по развитию системы профилактики девиантного поведения ФГБУ «Центр защиты прав и интересов детей» с докладом на тему: «Подходы к развитию института наставничества в системе профилактики безнадзорности и правонарушений несовершеннолетних: полномочия комиссий по делам несовершеннолетних и защите их прав».

</p>
<p>
В ходе доклада, с учетом задач, поставленных на государственном уровне, в рамках реализации в 2025 году Центром защиты прав и интересов детей государственного задания Минпросвещения России, были раскрыты основы формирования устойчивости личности и благополучной траектории развития ребенка с опорой на «значимого взрослого», обозначено нормативно-правовое обеспечение системы наставничества в сфере профилактики безнадзорности и правонарушений несовершеннолетних.

</p>
<p>
Подробнее о мероприятии – на странице ГАОУ ДПО «ЛОИРО» – <a href="https://vk.com/wall-74878472_5640">https://vk.com/wall-74878472_5640</a> 

</p>
<img src={img}/>
<p>
</p>
        </div>
        <ToNews/>
    </div>
}


export default News250320;