import React from "react";
import s from "./PageFiles.module.css"
import {FileLeftLine} from "./FilesVeiw"
import {useState, useEffect} from "react";
import axios from "axios";
import {HrefsBeh1, WhiteButton} from "../PageInfo/PageInfo"
import Falldown, {Falldown2, FileLi, HrefLi} from "../FalldownList/Falldown";
import {hrefsArrFPF} from "./FilesPublicationsForms";


const IMSBezn = () => {
    const [respData, setData] = useState([])

    useEffect(() => {
        axios.get("https://fcprc.ru/api/behavior").then(
            res => {
                setData(res.data)
            })

    }, [])
    console.log(respData)
    return <>
        <div className={s.newHeader}>
            <h2>
                III. Информационно-методическое обеспечение<br/> системы профилактики безнадзорности<br/> и
                правонарушений
                несовершеннолетних
            </h2>
        </div>
        <div className={s.filesLeftPage} style={{textAlign: "left", fontSize: "15px", color: "#212529"}}>
            <div className={s.improvement} style={{width: "700px", lineHeight: "1.5"}}>
                <p>Значимость развития информационно-методического обеспечения системы профилактики безнадзорности и
                    правонарушений несовершеннолетних обусловлено необходимостью повышения эффективности и
                    результативности деятельности субъектов профилактики. В частности внедрение электронных
                    информационных систем выявления и учета детей, оказавшихся в трудной жизненной ситуации; разработка
                    и апробация моделей профилактической работы с детьми в девиантным поведением; организацию и
                    проведение научных исследований для создания современных технологий работы с подростками и детьми, с
                    социально уязвимыми группами молодежи, индивидуальной профилактики девиантного поведения, методик
                    социальной работы с семьями; изучение актуального опыта, внедрение и распространение опыта
                    профилактической работы, обновление учебного и научно-методического сопровождения деятельности по
                    профилактике асоциальных проявлений в молодежной среде; использование современных форм повышения
                    квалификации работников органов и учреждений системы профилактики безнадзорности и правонарушений
                    несовершеннолетних и др.</p>
                <p>Информационно-методическое обеспечение системы профилактики безнадзорности и правонарушений
                    несовершеннолетних должно включать:</p>
                <p>— анализ и распространение лучшей практики, технологий и методов профилактики, используемых в сфере
                    правонарушений несовершеннолетних, включая анализ и обобщение опыта формирования единого
                    межведомственного учета несовершеннолетних и (или) семей, находящихся в социально опасном положении,
                    посредством создания регионального банка данных;</p>
                <p>— просвещение родителей (законных представителей) по вопросам профилактики безнадзорности и
                    правонарушений несовершеннолетних, устранения факторов, им способствующих;
                </p>
                <p>— разработку и использование методов воздействия на несовершеннолетних, не связанных с применением
                    наказания, на основе проведения научных исследований в области психологии девиантного поведения
                    несовершеннолетних.</p>
                <p><strong>Результаты реализации Концепции 2024 год</strong></p>
                <p>
                    <a
                    href="https://fcprc.ru/media/media/behavior/Аналитические_материалы_Концепция_пункт_26_2024.pdf">
                        Аналитические материалы_пункт 26_Концепция профилактики_2024
                    </a>
                </p>
                <p>
                    <a
                    href="https://fcprc.ru/media/media/behavior/аналитические_материалы_Концепция_пункт_28__2024.pdf">
                        Аналитические материалы_пункт 28_Концепция профилактики_2024
                    </a>
                </p>
                <p>
                    <a
                    href="https://fcprc.ru/media/media/behavior/Аналитические_материалы_Концепция_пункт_29_2024.pdf">
                        Аналитические материалы_пункт 29_Концепция профилактики_2024
                    </a>
                </p>
                <p><strong>Результаты реализации Концепции 2023 год</strong></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/аналитическая_справка___пункт_26_2023.pdf">Информационно-аналитические
                    материалы
                    об обеспечении проведения совещаний, конференций, семинаров, круглых столов и организации
                    дискуссионных площадок для специалистов учреждений системы профилактики безнадзорности
                    и правонарушений несовершеннолетних по актуальным вопросам профилактики девиантного поведения
                    несовершеннолетних в 2023 году (пункт 26)
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Информационно-аналитические_материалы_по_исполнению_в_2023_году_в_субъект_yKJc7pZ.pdf">Информационно-аналитические
                    материалы по обеспечению проведения обучающих вебинаров для руководителей и специалистов
                    (психологов, социальных педагогов и воспитателей) специальных учебно-воспитательных учреждений
                    открытого и закрытого типа в субъектах Российской Федерации в 2023 году
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/аналитическая_справка_пункт_29_2023.pdf">
                    Информационно-аналитические материалы по организации мероприятий, направленных на повышение
                    профессионального уровня, приобретение новой квалификации руководителями и специалистами органов и
                    учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних, в том числе
                    обеспечение проведения межведомственных семинаров с участием профильных некоммерческих организаций
                    в субъектах Российской Федерации в 2023 году (пункт 29)
                </a></p>
                <p><strong>Результаты реализации Концепции 2022 год</strong></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya-__punkt-26.pdf">Аналитическая
                    информация по обеспечению проведения совещаний,
                    конференций, семинаров, круглых столов и организации дискуссионных площадок для специалистов
                    учреждений системы профилактики безнадзорности и правонарушений несовершеннолетних по актуальным
                    вопросам профилактики девиантного поведения несовершеннолетних (пункт 26)
                </a></p>
                <p><a
                    href="https://fcprc.ru/media/media/behavior/Informatsionno-analiticheskie-materialy-_punkt-28.pdf">
                    Информационно-аналитические материалы по обеспечению проведения обучающих вебинаров для
                    руководителей и специалистов (психологов, социальных педагогов и воспитателей) специальных
                    учебно-воспитательных учреждений открытого и закрытого типа (пункт 28)</a></p>
                <p><a href="https://fcprc.ru/media/media/behavior/Analiticheskaya-informatsiya_punkt-29.pdf">
                    Аналитическая информация по организации мероприятий, направленных на повышение профессионального
                    уровня, приобретение новой квалификации руководителями и специалистами органов и учреждений системы
                    профилактики безнадзорности и правонарушений несовершеннолетних, в том числе обеспечение проведения
                    межведомственных обучающих семинаров с участием профильных некоммерческих организаций (пункт 29)</a>
                </p>
                <p><strong>Результаты реализации Концепции 2021 год</strong></p>
                <p><a href={respData[484]?.file}>{respData[484]?.description}</a></p>
                <p><a href={respData[485]?.file}>{respData[485]?.description}</a></p>
                <p><a href={respData[486]?.file}>{respData[486]?.description}</a></p>
                <p><a href={respData[487]?.file}>{respData[487]?.description}</a></p>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArrFPF}/>
            </div>
        </div>
    </>
}
export default IMSBezn