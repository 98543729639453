import React from "react";
import s from "./Default.module.css"
import { useState } from "react";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News270325.jpg"

const News270325 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            3-4 апреля 2025 г. в г. Москве пройдет XIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            Минпросвещения России 3 – 4 апреля 2025 г. в Москве проводит ХIII Всероссийский съезд руководителей организаций для детей-сирот и детей, оставшихся без попечения родителей, и руководителей органов опеки и попечительства.
            <p></p>
            К участию в Съезде приглашены более 300 делегатов из числа руководителей (заместителей руководителей) организаций для детей-сирот и представителей  исполнительных органов субъектов Российской Федерации, осуществляющих государственное управление в сфере опеки и попечительства в отношении несовершеннолетних.
            <p></p>
            Ключевой темой Съезда станет продвижение традиционных семейных ценностей, обеспечение и защита права детей жить и воспитываться в семье.
            <p></p>
            В программе Съезда запланированы лекции по актуальным вопросам, связанным с защитой прав и обеспечением интересов детей, находящихся в организациях для детей-сирот, обсуждение и обмен опытом, в том числе по вопросам, касающимся совместной работы организаций для детей-сирот и семьи в интересах ребенка, профессиональной помощи, направленной на укрепление детско-родительских отношений, создания безопасной среды для подростков, специфики воспитательной работы в организациях для детей-сирот.
            <p></p>
            Обеспечение проведения Съезда осуществляется ФГБУ «Центр защиты прав и интересов детей».
            <p></p>

        </div>
        <ToNews />
    </div>
}


export default News270325;