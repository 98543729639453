import React from "react";
import s from "./Default.module.css"
import {useEffect, useState} from "react";
import axios from "axios";
import ToNews from "../News/ToNews";
import img from "../../assets/News/News260224.jpg"

const News260225 = ({children, header}) => {
    const [newsData, setNews] = useState([])

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Установочный семинар по вопросам внедрения межведомственного стандарта антинаркотической профилактической
            деятельности
        </h2>
        <div>
            <img src={img}/>
        </div>
        <div style={{fontFamily: "Proxima Nova Lt"}}>
            26 февраля 2025 года состоялся Установочный обучающий семинар по вопросам применения положений
            Межведомственного стандарта антинаркотической профилактической деятельности в работе образовательных
            организаций по профилактике незаконного употребления наркотических средств и психотропных веществ среди
            обучающихся.
            <p></p>
            В работе семинара приняли участие более 300 человек из 80 субъектов Российской Федерации.
            <p></p>
            С приветственным словом к участникам семинара обратилась директор Департамента государственной политики в
            сфере защиты прав детей Минпросвещения России <b>Фальковская Лариса Павловна</b>. Особое внимание было
            уделено
            проведенной межведомственной работе при подготовке Стандарта, основной целью которого является
            установление <b>единых требований</b> к осуществлению профилактической деятельности.
            <p></p>
            Вопросы внедрения межведомственного Стандарта в работу образовательных организаций раскрыла в своем
            выступлении начальник отдела профилактики девиантного поведения несовершеннолетних Центра защиты прав и
            интересов детей <b>Заева Ольга Вячеславовна</b>. Подробно были освещены особенности применения содержания Стандарта
            в работе образовательных организаций, включая преимущества его использования в профилактической работе.
            <p></p>
            В ходе семинара было отмечено, что внедрение Стандарта в работу образовательных организаций позволит
            дополнить и систематизировать содержание профилактической деятельности, повысить ее адресность. Значимым
            элементом содержания Стандарта является выделение <b>ограничений</b>, предъявляемых к содержанию
            антинаркотической
            профилактики.
            <p></p>
            <a style={{fontWeight: "bold"}} href="https://disk.yandex.ru/i/WC5il4E7d6tVBQ">
                Запись установочного вебинара-совещания
            </a>
            <p></p>
            <a style={{fontWeight: "bold"}} href="https://fcprc.ru/media/media/behavior/Внерение_Стандарта_профилатики_март_2025.pdf">
                Презентация «Внедрение межведомственного стандарта антинаркотической профилактической деятельности в работу образовательных организаций»
            </a>
            <p></p>
            <a style={{fontWeight: "bold"}} href="https://fcprc.ru/media/media/behavior/Межведомственный_стандарт_профилактики_2025.pdf">
               Межведомственный стандарт антинаркотической профилактической деятельности (утвержден решением Государственного антинаркотического комитета 19 декабря 2024 года, протокол № 55)
            </a>
            <p></p>
        </div>
        <ToNews/>
    </div>
}


export default News260225;