import React from "react";
import {useState} from "react";
import s from "./PageInfo.module.css"
import docIco from "../../../assets/img/doc.svg"
import PageImg from "../../../assets/Behavior/new_hotline3.jpg"
import {DownOutlined} from "@ant-design/icons"
import {NavLink} from "react-router-dom";
import {Formik, Field, Form} from "formik";
import ImgPopup from "../Popup/PopupImg"
import axios from "axios";


const hrefsArr1 = [
    {text: "Страница отдела медиации", to: "https://fcprc.ru/mediacia"},
    {
        text: "Реестр некоммерческих организаций, применяющих восстановительные, а также медиативные технологии",
        to: "https://fcprc.ru/mediacia/reestr-2020-tso-2/"
    },,
    {
        text: "Данные об Уполномоченных по правам ребёнка в субъектах Российской Федерации",
        to: "https://deti.gov.ru/Upolnomochennyy/subordinate?ysclid=lu99if4m95355600199"
    },


]
const Hotline3 = ({url, header, children, style = {}}) => {
    const [active, setActive] = useState(false)
    const [politics, setPolitics] = useState(false)

    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Обязательное поле';
        }
        return error;
    }

    return <>
        <div className={s.page}>
            <div className={s.content}>
                <h2 style={{fontWeight: "normal", fontSize: "30px", color: "#3a4463"}}>
                    Горячая линия по вопросам урегулирования конфликтов в школе, медиации и примирения в образовательных
                    организациях
                </h2>
                <div>
                    <img src={PageImg}/>
                </div>
                <p></p>
                <div>
                    В целях помощи в урегулировании споров, разногласий и конфликтных ситуаций
                    в образовательных организациях по поручению Министерства просвещения
                    Российской Федерации в Центре защиты прав и интересов детей открыта
                    Горячая линия по вопросам медиации и примирения в образовательных организациях
                    <p></p>
                    <span style={{color: "#3a4663", fontWeight: "600", fontFamily: "Proxima Nova Lt"}}>
Горячая линия адресована:</span>
                    <p></p>

                    <div>— специалистам органов государственной власти и местного самоуправления</div>
                    <div>— руководителям образовательных организаций</div>
                    <div>— педагогическим работникам, специалистам служб медиации и примирения</div>
                    <div>— профильным общественным организациям</div>
                    <div>— родительской общественности</div>
                    <div>— детям, подросткам и молодежи</div>
                    <div>
                        <p></p>
                        <span style={{color: "#3a4663", fontWeight: "600", fontFamily: "Proxima Nova Lt"}}>
Формат и режим работы:</span>
                    </div>
                    Получить консультацию можно в рабочие дни <span style={{
                    color: "#3a4663",
                    fontWeight: "600",
                    fontFamily: "Proxima Nova Lt"
                }}>  с 9:00 до 18:00</span> (по московскому
                    времени) по телефону:<span style={{
                    color: "#3a4663",
                    fontWeight: "600",
                    fontFamily: "Proxima Nova Lt"
                }}>  8-800-444-18-06 </span>
                    <p></p>
                    <span style={{color: "#3a4663", fontWeight: "600", fontFamily: "Proxima Nova Lt"}}>
Горячая линия по вопросам урегулирования конфликтов в школе, медиации и

примирения в образовательных организациях</span>
                    {/*<div>*/}
                    {/*    <input type="checkbox"/>*/}

                    {/*</div>*/}
                    <p></p>
                    <div className={s.hotlineHref}>
                        <input type="checkbox" onClick={() => {
                            politics ? setPolitics(false) : setPolitics(true)
                        }}/>
                        Я даю согласие согласие на обработку <a
                        href="https://fcprc.ru/media/media/behavior/20-1-29-04-2021_CIi14fS.pdf">персональных данных</a>
                    </div>
                    <br/>
                    {politics ? <div onClick={() => {
                        setActive(true)
                    }} style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "pink",
                        width: "230px",
                        marginBottom: "70px",
                        cursor: "pointer",
                        borderRadius: "10px"
                    }}>
                        Задать вопрос на горячую линию
                    </div> : <div style={{
                        color: "white",
                        padding: "15px",
                        backgroundColor: "#c97171",
                        width: "230px",
                        marginBottom: "70px",

                        borderRadius: "10px"
                    }}>
                        Задать вопрос на горячую линию
                    </div>}
                </div>

                <ImgPopup active={active} setActive={setActive}>
                    <div className={s.form}>
                        <div>
                            <h1>
                                Форма запроса на Горячую Линию
                            </h1>
                        </div>
                        <Formik
                            initialValues={{email: '',}}

                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    console.log(values)
                                    axios.post("https://fcprc.ru/api/email", {
                                        'subject': values.subject,
                                        'city': values.city,
                                        'education': values.education,
                                        'age': values.textf,
                                        'question': values.question,
                                        'category': values.dir,
                                        'name': values.name,
                                        'email': values.email,
                                        'number': values.phone,
                                        'theme': values.textQ,
                                        'question': values.textT
                                    }, {
                                        xsrfCookieName: 'csrftoken',
                                        xsrfHeaderName: 'X-CSRFTOKEN',
                                    })
                                        .then(response => response.text())


                                    setSubmitting(false);
                                    setActive(false)
                                }, 400);
                            }}
                        >
                            {({errors, touched, isSubmitting}) => (
                                <Form>
                                    <div>
                                        <div>
                                            <label fore="subject">СУБЪЕКТ РФ: *
                                            </label>
                                        </div>
                                        <Field type="subject" name="subject" placeholder="СУБЪЕКТ РФ"
                                               validate={validateEmail}/>
                                        {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="city">Город: *
                                            </label>
                                        </div>
                                        <Field type="city" name="city" placeholder="Город" validate={validateEmail}/>
                                        {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <div>

                                        <div>
                                            <div>
                                                <label fore="education">Образовательная организация
                                                </label>
                                            </div>
                                            <Field type="education" name="education"
                                                   placeholder="Образовательная организация"/>
                                            {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="name">ФИО*
                                                </label>
                                            </div>
                                            <Field type="name" name="name"
                                                   placeholder="Ваше ФИО" validate={validateEmail}/>
                                            {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="email">EMAIL *
                                                </label>
                                            </div>
                                            <Field type="email" name="email"
                                                   placeholder="E-mail для обратной связи" validate={validateEmail}/>
                                            {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>
                                            <div>
                                                <label fore="phone">Номер телефона*
                                                </label>
                                            </div>
                                            <Field type="phone" name="phone"
                                                   placeholder="Телефон для связи" validate={validateEmail}/>
                                            {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                        <div>

                                            <div>
                                                <label fore="dir">Категория гражданина</label>
                                            </div>
                                            <Field component="select" name="dir" placeholder="Выберите"
                                                   validate={validateEmail}>
                                                <option> Выберите</option>
                                                <option>Специалист органов государственной власти и местного
                                                    самоуправления
                                                </option>
                                                <option>Специалист региональных (муниципальных) КДНиЗП</option>
                                                <option>Руководитель образовательной организации</option>
                                                <option>Педагог, педагог-психолог, классный руководитель</option>
                                                <option>Родитель(законный представитель)</option>
                                                <option>Обучающийся</option>
                                                <option>Иные категории</option>
                                            </Field>
                                            {errors.name && touched.name && <div>{errors.name}</div>}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="textf">Возраст и/или учебный класс несовершеннолетнего (в
                                                случае, когда вопрос касается несовершеннолетнего) *</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textf" placeholder="..."
                                               validate={validateEmail}/>
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="textT">Тема вопроса *</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textT" placeholder="..."
                                               validate={validateEmail}/>
                                        {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <div>
                                        <div>
                                            <label fore="textQ">ЗАДАЙТЕ ВАШ ВОПРОС *</label>
                                        </div>
                                        <Field component="textarea" type="text" name="textQ" placeholder="..."
                                               validate={validateEmail}/>
                                        {errors.name && touched.name && <div>{errors.name}</div>}
                                    </div>
                                    <br/>

                                    <div className={s.send}>
                                        <button>Отправить</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </ImgPopup>
            </div>
            <div>
                <HrefsBeh1 hrefsArr={hrefsArr1}/>

            </div>
        </div>
    </>


}

export const HrefsBeh1 = ({hrefsArr, stylef = {}, to = "/"}) => {
    return <div className={s.hrefs} style={stylef}>
        <ul>
            {hrefsArr.map(el => <>
                <OneLiHrefs text={el.text} input={el.input} to={el.to}/>

            </>)}
        </ul>

    </div>
}
const OneLiHrefs = ({text, ul, input = null, to}) => {
    const [isActive, SetActive] = useState(false)

    return <>
        <li style={{position: "relative", marginRight: "20px"}}>
            <div style={{marginRight: "20px"}}>
                <img src={docIco}/>
                <a href={to}>
                    {text}
                </a>
                {input ? <span
                        style={{position: "absolute", right: "4px", padding: "3px", top: "10px"}}
                        onClick={() => {
                            isActive ? SetActive(false) : SetActive(true)
                        }} className={s.arrow}>
        {!isActive ? <DownOutlined/> : <DownOutlined style={{rotate: "180deg"}}/>} </span>
                    : <></>}
            </div>
        </li>
        {isActive ? input.map(text => <OneLiHrefsInside text={text.text} to={text.to}/>) : <></>}
    </>
}
const OneLiHrefsInside = ({text, to}) => {
    return <NavLink to={to}>
        <li>
            {text}
        </li>
    </NavLink>
}


export default Hotline3